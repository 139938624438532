export interface ComparisonOption {
    label: string
    value: string
    shortcut: string[]
}

export const comparisonDatesIndex = 3;

export const comparisonOptions: ComparisonOption[] = [
    {
        label: "Disabled",
        value: "",
        shortcut: ["X"]
    },
    {
        label: "Previous Period",
        value: "previous",
        shortcut: ["R"]
    },
    {
        label: "Year Over Year",
        value: "year",
        shortcut: ["E"]
    },
    {
        label: "Custom Period",
        value: "custom",
        shortcut: ["C"]
    }
];
