<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {name: funnel?.name})}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useI18n} from "vue-i18n";
    import {Funnel} from "@/model/Funnel";
    import {FunnelRepo} from "@/repositories/FunnelRepo";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            funnel: {type: Object as PropType<Funnel>}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    if (props.funnel) {
                        loading.value = true;
                        resetError();
                        await FunnelRepo.delete(props.funnel.id);
                        loading.value = false;
                        success(t("toast.success"));
                        emit("action");
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text": "Are you sure you want to delete the funnel with the name \"{name}\"?",
            "form.submit": "Delete",
            "toast.success": "The funnel has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text": "Bist du sicher, dass du den Funnel mit dem Namen \"{name}\" löschen möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Der Funnel wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text": "¿Está seguro de que desea eliminar el embudo con el nombre \"{name}\"?",
            "form.submit": "Eliminar",
            "toast.success": "Se ha eliminado el embudo."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text": "Êtes-vous sûr de vouloir supprimer l'entonnoir portant le nom \"{name}\" ?",
            "form.submit": "Supprimer",
            "toast.success": "L'entonnoir a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text": "Weet je zeker dat je de trechter met de naam \"{name}\" wilt verwijderen?",
            "form.submit": "Verwijderen",
            "toast.success": "De trechter is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text": "Si è sicuri di voler eliminare l'imbuto con il nome \"{name}\"?",
            "form.submit": "Elimina",
            "toast.success": "L'imbuto è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text": "Tem a certeza de que pretende eliminar o funil com o nome \"{name}\"?",
            "form.submit": "Excluir",
            "toast.success": "O funil foi eliminado."
        },
        "ja": {
            "title": "削除の確認",
            "text": "本当に\"{name}\"という名前のファネルを削除したいですか？",
            "form.submit": "削除",
            "toast.success": "ファンネルは削除された。"
        }
    }
</i18n>
