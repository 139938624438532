<template>
    <SaveView :open="saveViewModal" v-on:close="saveViewModal = false" />
    <Expand>
        <div id="filter" class="filter" v-if="fields.length">
            <i class="icon icon-search"></i>
            <div class="params">
                <FilterParam v-for="field in fields"
                    :key="field.field+field.value"
                    :field="field.field"
                    :value="field.value" />
            </div>
            <i class="icon icon-bookmark cursor-pointer" v-on:click="saveViewModal = true" v-if="loggedIn"></i>
            <i class="icon icon-close cursor-pointer" v-on:click="clear"></i>
        </div>
    </Expand>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {useSticky} from "@/components/sticky";
    import Expand from "@/components/bits/Expand.vue";
    import FilterParam from "@/components/panels/FilterParam.vue";
    import SaveView from "@/components/modal/SaveView.vue";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useFilterParamsStore} from "@/store/FilterParamsStore";
    import {useFilterStore} from "@/store/FilterStore";

    export default defineComponent({
        components: {
            Expand,
            FilterParam,
            SaveView
        },
        setup() {
            const {clear} = useFilterStore();
            const {fields} = storeToRefs(useFilterParamsStore());
            const {loggedIn} = storeToRefs(useUserStore());
            const saveViewModal = ref(false);

            return {
                ...useSticky("filter"),
                loggedIn,
                saveViewModal,
                clear,
                fields
            };
        }
    });
</script>
