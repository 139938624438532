const excludeCustomDomains = new Set<string>([
    "pirsch.io",
    "pirsch.dev",
    "localhost",
    "localhost.com",
    parseSelfHostedUrl(import.meta.env.VITE_APP_BACKEND_HOST),
    parseSelfHostedUrl(import.meta.env.VITE_APP_WEBSITE_HOST),
    parseSelfHostedUrl(import.meta.env.VITE_APP_DASHBOARD_HOST),
    import.meta.env.VITE_APP_DASHBOARD_DOMAIN,
    import.meta.env.VITE_APP_COOKIES_DOMAIN
].filter(d => d !== ""));
const customDomainRegex = new RegExp(`^.*\\.(${Array.from(excludeCustomDomains).join("|")}):?.*$`, "gm");

export function getSubdomain() {
    const host = location.host;
    const parts = host.split(".");
    return parts[0].toLowerCase();
}

export function getDomain() {
    return location.hostname;
}

export function isDashboard() {
    return location.host.toLowerCase() === `${import.meta.env.VITE_APP_DASHBOARD_SUBDOMAIN}.${import.meta.env.VITE_APP_DASHBOARD_DOMAIN}`;
}

export function isSelfHosted() {
    return import.meta.env.VITE_APP_SELF_HOSTED === "true";
}

export function isCustomDomain() {
    return !location.hostname.toLowerCase().match(customDomainRegex);
}

function parseSelfHostedUrl(url?: string) {
    try {
        if (!url) {
            return "";
        }

        return new URL(url).hostname;
    } catch {
        return "";
    }
}

export function getCookieDomain() {
    if (isCustomDomain()) {
        return undefined;
    }

    return import.meta.env.VITE_APP_COOKIES_DOMAIN;
}
