<template>
    <SelectExportCSV :open="exportModal" v-on:close="exportModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <button v-on:click="exportModal = true">{{t("export")}}</button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import SelectExportCSV from "@/components/modal/SelectExportCSV.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            SelectExportCSV
        },
        setup() {
            const exportModal = ref(false);

            return {
                ...useI18n(),
                exportModal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Data Export",
            "text": "Export your statistics to CSV.",
            "export": "Export Data to CSV"
        },
        "de": {
            "title": "Datenexport",
            "text": "Exportiere deine Statistiken als CSV.",
            "export": "Daten als CSV exportieren"
        },
        "es": {
            "title": "Exportación de datos",
            "text": "Exporta tus estadísticas a CSV.",
            "export": "Exportar datos a CSV"
        },
        "fr": {
            "title": "Exportation de données",
            "text": "Exportez vos statistiques en CSV.",
            "export": "Exporter les données en CSV"
        },
        "nl": {
            "title": "Gegevens exporteren",
            "text": "Exporteer je statistieken naar CSV.",
            "export": "Gegevens exporteren naar CSV"
        },
        "it": {
            "title": "Esportazione dati",
            "text": "Esporta le tue statistiche in CSV.",
            "export": "Esporta dati in CSV"
        },
        "pt": {
            "title": "Exportação de dados",
            "text": "Exporte suas estatísticas para CSV.",
            "export": "Exportar dados para CSV"
        },
        "ja": {
            "title": "データエクスポート",
            "text": "統計情報をCSVにエクスポートします。",
            "export": "データをCSVにエクスポート"
        }
    }
</i18n>
