import {APIError} from "@/repositories/APIError";
import {Repository} from "@/repositories/Repository";
import {Organization} from "@/model/Organization";
import {Domain} from "@/model/Domain";

export const OrganizationRepo = new class extends Repository {
    async list(): Promise<Organization[] | APIError> {
        return this.performGet<Organization[]>("/organization", null, [])
    }

    async create(name: string): Promise<Organization | APIError> {
        return this.performPost<Organization>("/organization", {name});
    }

    async update(id: string, name: string): Promise<Organization | APIError> {
        return this.performPut<Organization>("/organization", {id, name});
    }

    async delete(id: string, delete_domains: boolean): Promise<APIError | null> {
        return this.performDelete("/organization", {id, delete_domains});
    }

    async setDefaultTheme(organization_id: string, theme_id: string): Promise<APIError | null> {
        return this.performPut<null>("/organization/theme", {organization_id, theme_id});
    }

    async listDomains(id: string): Promise<Domain[] | APIError> {
        return this.performGet<Domain[]>("/organization/domain", {id}, [])
    }

    async addDomain(id: string, domain_id: string): Promise<Domain | APIError> {
        return this.performPost<Domain>("/organization/domain", {id, domain_id});
    }

    async removeDomain(id: string, domain_id: string): Promise<APIError | null> {
        return this.performDelete("/organization/domain", {id, domain_id});
    }

    async addAutoJoin(id: string, auto_join: string[]): Promise<APIError | null> {
        return this.performPost<null>("/organization/autojoin", {id, auto_join});
    }

    async removeAutoJoin(id: string, auto_join: string): Promise<APIError | null> {
        return this.performDelete("/organization/autojoin", {id, auto_join});
    }
}
