<template>
    <Modal size="m" :title="t('title')" :open="open">
        <OS component="TableEntries"
            :showTitle="false"
            :showEnlarge="false"
            :limitless="true"
            :showDetails="true"
            :visible="true" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import OS from "@/components/statistics/OS.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {entries_per_page, useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            OS
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const {filter} = storeToRefs(filterStore);
            const {loadOSVersions} = useStatisticsStore();

            watch(() => props.open, open => {
                if (open) {
                    const f = filterStore.copy(filter.value);
                    f.limit = entries_per_page;
                    loadOSVersions(f);
                }
            });

            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Operating Systems"
        },
        "de": {
            "title": "Betriebssysteme"
        },
        "es": {
            "title": "Sistemas Operativos"
        },
        "fr": {
            "title": "Systèmes d'Exploitation"
        },
        "nl": {
            "title": "Besturingssystemen"
        },
        "it": {
            "title": "Sistemi Operativi"
        },
        "pt": {
            "title": "Sistemas Operacionais"
        },
        "ja": {
            "title": "オペレーティングシステム"
        }
    }
</i18n>
