<template>
    <CreateEditFunnel :open="createEditFunnelModal"
        :funnel="funnel"
        v-on:action="addUpdateFunnel"
        v-on:close="funnel = undefined;createEditFunnelModal = false" />
    <ConfirmDeleteFunnel :open="deleteFunnelModal"
        :funnel="funnel"
        v-on:action="removeFunnel"
        v-on:close="funnel = undefined;deleteFunnelModal = false" />
    <div class="funnel-menu" v-if="list.length && !mobile">
        <input class="search" type="text" name="search" v-model="search" :placeholder="t('placeholder')" />
    </div>
    <div class="funnels" v-if="!loading && list.length">
        <FunnelCard v-for="entry in filteredList"
            :key="entry.id"
            :funnel="entry"
            v-on:edit="editFunnel"
            v-on:copy="copyFunnel"
            v-on:delete="deleteFunnel" />
        <div class="card funnel-card add-card" v-on:click="createEditFunnelModal = true" v-if="loggedIn && domain.user_role && isAdmin && domain.subscription_plus">
            <i class="icon icon-add" />
            <strong>{{t("add")}}</strong>
        </div>
    </div>
    <NoFunnels v-on:create="createEditFunnelModal = true" v-else-if="!loading && !list.length" />
    <LoadingPage :loading="loading" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, toRaw, watch} from "vue";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";
    import {useRoute} from "vue-router";
    import CreateEditFunnel from "@/components/modal/CreateEditFunnel.vue";
    import FunnelCard from "@/components/cards/FunnelCard.vue";
    import {Funnel} from "@/model/Funnel";
    import ConfirmDeleteFunnel from "@/components/modal/ConfirmDeleteFunnel.vue";
    import {useList} from "@/components/list";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {useLockStore} from "@/store/LockStore";
    import {useMobile} from "@/components/mobile";
    import NoFunnels from "@/components/funnel/NoFunnels.vue";
    import {isDashboard} from "@/util/domain";

    export default defineComponent({
        components: {
            NoFunnels,
            ConfirmDeleteFunnel,
            FunnelCard,
            CreateEditFunnel,
            LoadingPage
        },
        setup() {
            const route = useRoute();
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const lockStore = useLockStore();
            const statisticsStore = useStatisticsStore();
            const {loadFunnels} = statisticsStore;
            const {funnels} = storeToRefs(statisticsStore);
            const {filter} = storeToRefs(useFilterStore());
            const {loggedIn} = storeToRefs(useUserStore());
            const loading = computed(() => statisticsStore.isLoading("funnels"));
            const list = ref<Funnel[]>([]);
            const createEditFunnelModal = ref(false);
            const deleteFunnelModal = ref(false);
            const search = ref("");
            const funnel = ref<Funnel>();
            const filteredList = computed(() => list.value.filter(entry => entry.name.toLowerCase().includes(search.value.toLowerCase())));

            onMounted(() => {
                createEditFunnelModal.value = !!route.query.open;

                if (loggedIn && isDashboard()) {
                    lockStore.load(domain.value.id);
                }

                void loadFunnels(filter.value);
            });

            watch(domain, () => {
                if (loggedIn && isDashboard()) {
                    lockStore.load(domain.value.id);
                }

                void loadFunnels(filter.value);
            });

            watch(route, r => {
                createEditFunnelModal.value = !!r.query.open;
            });

            watch(funnels, () => {
                list.value = funnels.value;
            });

            function addUpdateFunnel(f: Funnel) {
                if (funnel.value?.id) {
                    updateEntryByID(list.value, f);
                    funnel.value = undefined;
                } else {
                    list.value.push(f);
                }

                createEditFunnelModal.value = false;
            }

            function editFunnel(f: Funnel) {
                funnel.value = f;
                createEditFunnelModal.value = true;
            }

            function copyFunnel(f: Funnel) {
                funnel.value = structuredClone(toRaw(f));
                funnel.value.id = "";
                createEditFunnelModal.value = true;
            }

            function deleteFunnel(f: Funnel) {
                funnel.value = f;
                deleteFunnelModal.value = true;
            }

            function removeFunnel() {
                if (funnel.value) {
                    removeEntryByID(list.value, funnel.value);
                    deleteFunnelModal.value = false;
                    funnel.value = undefined;
                }
            }

            return {
                ...useI18n(),
                ...useDomainStore(),
                ...useMobile(),
                loggedIn,
                loading,
                list,
                filteredList,
                createEditFunnelModal,
                deleteFunnelModal,
                search,
                funnel,
                addUpdateFunnel,
                editFunnel,
                copyFunnel,
                deleteFunnel,
                removeFunnel
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "add": "Add Funnel",
            "placeholder": "Search..."
        },
        "de": {
            "add": "Funnel hinzufügen",
            "placeholder": "Suchen..."
        },
        "es": {
            "add": "Añadir embudo",
            "placeholder": "Buscar..."
        },
        "fr": {
            "add": "Ajouter un entonnoir",
            "placeholder": "Rechercher..."
        },
        "nl": {
            "add": "Trechter toevoegen",
            "placeholder": "Zoeken..."
        },
        "it": {
            "add": "Aggiungi imbuto",
            "placeholder": "Cerca..."
        },
        "pt": {
            "add": "Adicionar funil",
            "placeholder": "Pesquisar..."
        },
        "ja": {
            "add": "ファンネル追加",
            "placeholder": "検索..."
        }
    }
</i18n>
