<template>
    <div style="display: flex; align-items: center; gap: 8px;">
        <div v-if="path">
            <img :src="path" :alt="label" style="max-width: 64px;max-height: 64px;" />
        </div>
        <FormFile :label="label" :name="name" v-model="file" :err="validationError(name)" v-on:change="change" />
        <i class="icon icon-trash clickable" v-on:click.stop.prevent="deleteImg"></i>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, PropType, ref, watch} from "vue";
    import FormFile from "@/components/form/FormFile.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {KeyValue} from "@/model/KeyValue";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        components: {
            FormFile
        },
        props: {
            name: {type: String, required: true},
            label: {type: String, required: true},
            settings: {type: Object as PropType<KeyValue>, required: true},
            settingsVariable: {type: String as PropType<ThemeSettings>, required: true},
            modelValue: {type: Object, required: true},
            err: Object as PropType<APIError>
        },
        emits: ["update:modelValue", "deleteImg"],
        setup(props, {emit}) {
            const {setError, resetError, validationError} = useError();
            const file = ref<File | undefined>(undefined);
            const path = ref("");

            onMounted(updateSettings);
            watch(() => props.settings, updateSettings);

            function updateSettings() {
                if (props.settings) {
                    path.value = props.settings[props.settingsVariable] ? `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${props.settings[props.settingsVariable]}` : "";
                }
            }

            watch(() => props.err, err => {
                if (err) {
                    setError(err);
                } else {
                    resetError();
                }
            });

            function change() {
                emit("update:modelValue", file.value);
            }

            function deleteImg() {
                if (path.value !== "") {
                    emit("deleteImg", props.name);
                }

                file.value = undefined;
                path.value = "";
                emit("update:modelValue", undefined);
            }

            return {
                validationError,
                file,
                path,
                change,
                deleteImg
            };
        }
    });
</script>
