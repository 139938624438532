<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/traffic-alerts" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <form v-on:submit.prevent>
                <FormCheckbox name="toggleWarnings" :label="t('form.enable')" :toggle="true" v-model="toggle" />
                <FormInput name="warningThreshold" :label="t('form.threshold')" type="number" v-model="threshold" :err="validationError('threshold')" />
            </form>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {APIError} from "@/repositories/APIError";
    import {useError} from "@/components/error";
    import FormInput from "@/components/form/FormInput.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormInput,
            FormCheckbox,
            Loading,
            Expand
        },
        setup() {
            const {t} = useI18n();
            const {domain} = storeToRefs(useDomainStore());
            const {success, error} = useToastStore();
            const {setError, resetError, validationError} = useError();
            const toggle = ref(domain.value.traffic_warning);
            const threshold = ref(domain.value.traffic_warning_threshold_days);

            watch(toggle, async value => {
                await DomainRepo.toggleTrafficWarningNotifications(domain.value.id);
                domain.value.traffic_warning = value;
                success(value ? t("toast.enabled") : t("toast.disabled"));
            });

            watch(threshold, async value => {
                try {
                    resetError();
                    await DomainRepo.setTrafficWarningNotificationThreshold(domain.value.id, parseInt(value as unknown as string));
                    domain.value.traffic_warning_threshold_days = value;
                    success(t("toast.success"));
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            });

            return {
                t,
                validationError,
                toggle,
                threshold
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Traffic Alerts",
            "text": "Get notified when your website hasn't received any traffic.",
            "link": "Read the Docs",
            "form.enable": "Enable Traffic Warning Notifications",
            "form.threshold": "Threshold in Days",
            "toast.success": "Threshold saved.",
            "toast.enabled": "Traffic alerts enabled.",
            "toast.disabled": "Traffic alerts disabled."
        },
        "de": {
            "title": "Traffic-Warnung",
            "text": "Erhalte Benachrichtigungen, wenn deine Webseite keinen Traffic erhält.",
            "link": "Dokumentation lesen",
            "form.enable": "Traffic-Warnungen aktivieren",
            "form.threshold": "Schwellenwert in Tagen",
            "toast.success": "Schwellenwert gespeichert.",
            "toast.enabled": "Traffic-Warnung aktiviert.",
            "toast.disabled": "Traffic-Warnung deaktiviert."
        },
        "es": {
            "title": "Alertas de Tráfico",
            "text": "Recibe notificaciones cuando tu sitio web no haya recibido tráfico.",
            "link": "Leer la documentación",
            "form.enable": "Habilitar notificaciones de advertencia de tráfico",
            "form.threshold": "Umbral en días",
            "toast.success": "Umbral guardado.",
            "toast.enabled": "Alertas de tráfico habilitadas.",
            "toast.disabled": "Alertas de tráfico deshabilitadas."
        },
        "fr": {
            "title": "Alertes de Trafic",
            "text": "Recevez des notifications lorsque votre site Web n'a reçu aucun trafic.",
            "link": "Lire la documentation",
            "form.enable": "Activer les notifications d'avertissement de trafic",
            "form.threshold": "Seuil en jours",
            "toast.success": "Seuil enregistré.",
            "toast.enabled": "Alertes de trafic activées.",
            "toast.disabled": "Alertes de trafic désactivées."
        },
        "nl": {
            "title": "Verkeerswaarschuwingen",
            "text": "Ontvang meldingen wanneer je website geen verkeer heeft ontvangen.",
            "link": "Lees de documentatie",
            "form.enable": "Verkeerswaarschuwingen inschakelen",
            "form.threshold": "Drempel in dagen",
            "toast.success": "Drempel opgeslagen.",
            "toast.enabled": "Verkeerswaarschuwingen ingeschakeld.",
            "toast.disabled": "Verkeerswaarschuwingen uitgeschakeld."
        },
        "it": {
            "title": "Avvisi di Traffico",
            "text": "Ricevi notifiche quando il tuo sito web non ha ricevuto traffico.",
            "link": "Leggi la documentazione",
            "form.enable": "Abilita notifiche di avviso di traffico",
            "form.threshold": "Soglia in giorni",
            "toast.success": "Soglia salvata.",
            "toast.enabled": "Avvisi di traffico abilitati.",
            "toast.disabled": "Avvisi di traffico disabilitati."
        },
        "pt": {
            "title": "Alertas de Tráfego",
            "text": "Receba notificações quando seu site não tiver recebido nenhum tráfego.",
            "link": "Leia a documentação",
            "form.enable": "Ativar notificações de aviso de tráfego",
            "form.threshold": "Limite em dias",
            "toast.success": "Limite salvo.",
            "toast.enabled": "Alertas de tráfego ativados.",
            "toast.disabled": "Alertas de tráfego desativados."
        },
        "ja": {
            "title": "トラフィック警告",
            "text": "あなたのウェブサイトがトラフィックを受信していないときに通知を受け取ります。",
            "link": "ドキュメントを読む",
            "form.enable": "トラフィック警告通知を有効にする",
            "form.threshold": "日数のしきい値",
            "toast.success": "しきい値が保存されました。",
            "toast.enabled": "トラフィック警告が有効になりました。",
            "toast.disabled": "トラフィック警告が無効になりました。"
        }
    }
</i18n>
