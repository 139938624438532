<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.email')" name="email" :autofocus="true" v-model="email" :err="validationError('email')" />
            <FormSubmit color="red" :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const domainStore = useDomainStore();
            const {success} = useToastStore();
            const {domain} = storeToRefs(domainStore);
            const {resetError, setError, validationError, err} = useError();
            const loading = ref(false);
            const email = ref("");

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await MemberRepo.transferOwnership(domain.value.id, email.value);
                    success(t("toast.success"));
                    email.value = "";
                    loading.value = false;
                    emit("close");
                    await domainStore.load();
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                email,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Transfer Ownership",
            "text": "Please enter the email address of the new owner. The new owner must have a Pirsch account with an active subscription. Once the transfer has been accepted, your permissions will be changed to administrator.",
            "form.email": "Email Address",
            "form.submit": "Transfer Ownership",
            "toast.success": "The new owner has been notified."
        },
        "de": {
            "title": "Eigentum übertragen",
            "text": "Bitte gib die E-Mail-Adresse des neuen Eigentümers ein. Der neue Eigentümer muss ein Pirsch-Konto mit einem aktiven Abonnement haben. Sobald die Übertragung akzeptiert wurde, werden deine Berechtigungen in Administrator geändert.",
            "form.email": "E-Mail-Adresse",
            "form.submit": "Eigentum übertragen",
            "toast.success": "Der neue Eigentümer wurde benachrichtigt."
        },
        "es": {
            "title": "Transferir propiedad",
            "text": "Por favor, ingresa la dirección de correo electrónico del nuevo propietario. El nuevo propietario debe tener una cuenta de Pirsch con una suscripción activa. Una vez que la transferencia haya sido aceptada, tus permisos se cambiarán a administrador.",
            "form.email": "Dirección de correo electrónico",
            "form.submit": "Transferir propiedad",
            "toast.success": "El nuevo propietario ha sido notificado."
        },
        "fr": {
            "title": "Transférer la propriété",
            "text": "Veuillez entrer l'adresse e-mail du nouveau propriétaire. Le nouveau propriétaire doit avoir un compte Pirsch avec un abonnement actif. Une fois le transfert accepté, vos autorisations seront modifiées en administrateur.",
            "form.email": "Adresse e-mail",
            "form.submit": "Transférer la propriété",
            "toast.success": "Le nouveau propriétaire a été notifié."
        },
        "nl": {
            "title": "Eigendom overdragen",
            "text": "Voer het e-mailadres van de nieuwe eigenaar in. De nieuwe eigenaar moet een Pirsch-account met een actief abonnement hebben. Zodra de overdracht is geaccepteerd, worden je permissies gewijzigd naar beheerder.",
            "form.email": "E-mailadres",
            "form.submit": "Eigendom overdragen",
            "toast.success": "De nieuwe eigenaar is op de hoogte gebracht."
        },
        "it": {
            "title": "Trasferire la proprietà",
            "text": "Inserisci l'indirizzo email del nuovo proprietario. Il nuovo proprietario deve avere un account Pirsch con un abbonamento attivo. Una volta accettato il trasferimento, le tue autorizzazioni saranno modificate in amministratore.",
            "form.email": "Indirizzo email",
            "form.submit": "Trasferire la proprietà",
            "toast.success": "Il nuovo proprietario è stato avvisato."
        },
        "pt": {
            "title": "Transferir propriedade",
            "text": "Por favor, insira o endereço de e-mail do novo proprietário. O novo proprietário deve ter uma conta Pirsch com uma assinatura ativa. Uma vez que a transferência tenha sido aceita, suas permissões serão alteradas para administrador.",
            "form.email": "Endereço de e-mail",
            "form.submit": "Transferir propriedade",
            "toast.success": "O novo proprietário foi notificado."
        },
        "ja": {
            "title": "所有権を移転",
            "text": "新しい所有者のメールアドレスを入力してください。新しい所有者は、アクティブなサブスクリプションを持つPirschアカウントを持っている必要があります。移転が承認されると、あなたの権限は管理者に変更されます。",
            "form.email": "メールアドレス",
            "form.submit": "所有権を移転",
            "toast.success": "新しい所有者に通知されました。"
        }
    }
</i18n>
