<template>
    <div class="overview-card add-card small" v-on:click="$emit('add')" v-if="!user.read_only">
        <i class="icon icon-add" />
        <strong>{{t("title")}}</strong>
    </div>
</template>

<script lang="ts">
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {defineComponent} from "vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        emits: ["add"],
        setup() {
            const {user} = storeToRefs(useUserStore());

            return {
                ...useI18n(),
                user,
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Add Organization"
        },
        "de": {
            "title": "Organisation hinzufügen"
        },
        "es": {
            "title": "Agregar organización"
        },
        "fr": {
            "title": "Ajouter une organisation"
        },
        "nl": {
            "title": "Organisatie toevoegen"
        },
        "it": {
            "title": "Aggiungi organizzazione"
        },
        "pt": {
            "title": "Adicionar organização"
        },
        "ja": {
            "title": "組織を追加"
        }
    }
</i18n>
