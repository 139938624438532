<template>
    <InviteMember :open="inviteModal"
        v-on:action="loadInvitations"
        v-on:close="inviteModal = false" />
    <section class="row">
        <div>
            <h3>{{t("organization.title")}}</h3>
            <small>
                {{t("organization.text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/access#organizations" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("organization.link")}}</a>
            </small>
        </div>
        <div>
            <FormSelect :label="t('organization.form.label')"
                name="organization"
                :options="organizationOptions"
                v-model="organization"
                v-on:change="setOrganization"
                v-if="hasPlus" />
            <PirschPlus>
                <strong>{{t("organization.plus")}}</strong>
            </PirschPlus>
        </div>
    </section>
    <hr />
    <section class="row">
        <div>
            <h3>{{t("member.title")}}</h3>
            <small>
                {{t("member.text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/access#private-access" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("member.link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <MemberCard v-for="m in member"
                        :key="m.id"
                        :member="m"
                        v-on:remove="removeMember" />
                </div>
            </Expand>
            <button v-on:click="inviteModal = true">
                <Loading :loading="loading" />
                <span>{{t("member.invite")}}</span>
            </button>
        </div>
    </section>
    <hr v-if="invitations.length" />
    <section class="row" v-if="invitations.length">
        <div>
            <h3>{{t("invitations.title")}}</h3>
        </div>
        <div>
            <OpenInvitationCard v-for="invitation of invitations"
                :key="invitation.id"
                :invitation="invitation"
                v-on:remove="removeInvitation" />
        </div>
    </section>
    <hr />
    <section class="row">
        <div>
            <h3>{{t("access.title")}}</h3>
            <small>
                {{t("access.text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/access#public-access" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("access.link")}}</a>
            </small>
        </div>
        <div>
            <FormCheckbox :label="t('access.form.public')" name="public" toggle v-model="isPublic" v-on:change="togglePublic" />
            <i18n-t keypath="access.form.hint" tag="small" style="display:block; margin-top: 2px;">
                <a :href="dashboardURL" target="_blank">{{dashboardURL}}</a>
            </i18n-t>
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watchEffect} from "vue";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {Member} from "@/model/Member";
    import {Invitation} from "@/model/Invitation";
    import {InvitationRepo} from "@/repositories/InvitationRepo";
    import MemberCard from "@/components/cards/MemberCard.vue";
    import InviteMember from "@/components/modal/InviteMember.vue";
    import OpenInvitationCard from "@/components/cards/OpenInvitationCard.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {useOrganizationStore} from "@/store/OrganizationStore";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {APIError} from "@/repositories/APIError";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            PirschPlus,
            FormSelect,
            InviteMember,
            MemberCard,
            OpenInvitationCard,
            FormCheckbox,
            Loading,
            Expand
        },
        setup() {
            const {t} = useI18n();
            const {hasPlus} = storeToRefs(useUserStore());
            const {removeEntryByID} = useList();
            const domainStore = useDomainStore();
            const {domain} = storeToRefs(domainStore);
            const {load} = useDomainStore();
            const {success, error} = useToastStore();
            const {organizations} = storeToRefs(useOrganizationStore());
            const {setTheme} = useThemeStore();
            const inviteModal = ref(false);
            const loading = ref(true);
            const organization = ref("");
            const member = ref<Member[]>([]);
            const invitations = ref<Invitation[]>([]);
            const isPublic = ref(false);
            const dashboardURL = computed(() => `https://${domain.value.subdomain}.${import.meta.env.VITE_APP_DASHBOARD_DOMAIN}`);
            const organizationOptions = computed(() => {
                const options = [
                    {
                        label: t("options.organizations.none"),
                        value: ""
                    }
                ];

                for (const organization of organizations.value) {
                    if (organization.role === "Admin" || organization.role === "Owner") {
                        options.push({
                            label: organization.name,
                            value: organization.id
                        });
                    }
                }

                return options;
            });

            watchEffect(async () => {
                await loadMember();
                await loadInvitations();
            });

            watchEffect(() => {
                if (domain.value) {
                    organization.value = domain.value.organization_id ?? "";
                    isPublic.value = domain.value.public;
                }
            });

            async function loadMember() {
                loading.value = true;
                member.value = await MemberRepo.list(domain.value.id, "") as Member[] || [];
                loading.value = false;
            }

            async function loadInvitations() {
                inviteModal.value = false;
                invitations.value = await InvitationRepo.list(domain.value.id) as Invitation[] || [];
            }

            async function setOrganization() {
                try {
                    if (organization.value) {
                        await OrganizationRepo.addDomain(organization.value, domain.value.id);
                    } else {
                        await OrganizationRepo.removeDomain(domain.value.organization_id ?? "", domain.value.id);
                    }

                    setTheme();
                    success(t("toast.updated"));
                    await load();
                    await loadMember();
                } catch (e) {
                    error(`${t("error.organization")}: ${(e as APIError).error.join(", ")}`);
                }
            }

            function removeMember(m: Member) {
                removeEntryByID(member.value, m);
            }

            function removeInvitation(invitation: Invitation) {
                removeEntryByID(invitations.value, invitation);
            }

            async function togglePublic() {
                await DomainRepo.togglePublic(domain.value.id);
                domain.value.public = !domain.value.public;
                success(t("toast.saved"));
            }

            return {
                t,
                hasPlus,
                organizationOptions,
                inviteModal,
                loading,
                organization,
                dashboardURL,
                member,
                invitations,
                isPublic,
                loadInvitations,
                setOrganization,
                removeMember,
                removeInvitation,
                togglePublic
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "organization.title": "Organization",
            "organization.text": "Set the organization this website belongs to.",
            "organization.link": "Read the Docs",
            "organization.form.label": "Organization",
            "organization.plus": "Managing organizations requires a Pirsch Plus subscription.",
            "member.title": "Members",
            "member.text": "Give other users access to this dashboard and choose their roles.",
            "member.link": "Read the Docs",
            "member.invite": "Invite Member",
            "invitations.title": "Open Invitations",
            "access.title": "Public Access",
            "access.text": "Give anybody access to this dashboard (read-only).",
            "access.link": "Read the Docs",
            "access.form.public": "Publish Dashboard",
            "access.form.hint": "Your dashboard will be available on: {0}",
            "options.organizations.none": "None",
            "toast.updated": "The organization has been updated.",
            "toast.saved": "Settings saved.",
            "error.organization": "Error changing organization"
        },
        "de": {
            "organization.title": "Organisation",
            "organization.text": "Lege die Organisation fest, zu der diese Webseite gehört.",
            "organization.link": "Dokumentation lesen",
            "organization.form.label": "Organisation",
            "organization.plus": "Das Verwalten von Organisationen erfordert ein Pirsch Plus-Abonnement.",
            "member.title": "Mitglieder",
            "member.text": "Gib anderen Nutzern Zugriff auf dieses Dashboard und wähle ihre Rollen.",
            "member.link": "Dokumentation lesen",
            "member.invite": "Mitglied einladen",
            "invitations.title": "Offene Einladungen",
            "access.title": "Öffentlicher Zugriff",
            "access.text": "Gib jedem Zugriff auf dieses Dashboard (nur lesbar).",
            "access.link": "Dokumentation lesen",
            "access.form.public": "Dashboard veröffentlichen",
            "access.form.hint": "Dein Dashboard wird verfügbar sein unter: {0}",
            "options.organizations.none": "Keine",
            "toast.updated": "Die Organisation wurde aktualisiert.",
            "toast.saved": "Einstellungen gespeichert.",
            "error.organization": "Fehler beim Ändern der Organisation"
        },
        "es": {
            "organization.title": "Organización",
            "organization.text": "Establece la organización a la que pertenece este sitio web.",
            "organization.link": "Leer la documentación",
            "organization.form.label": "Organización",
            "organization.plus": "La gestión de organizaciones requiere una suscripción a Pirsch Plus.",
            "member.title": "Miembros",
            "member.text": "Da acceso a otros usuarios a este panel de control y elige sus roles.",
            "member.link": "Leer la documentación",
            "member.invite": "Invitar a un miembro",
            "invitations.title": "Invitaciones abiertas",
            "access.title": "Acceso público",
            "access.text": "Da acceso a cualquiera a este panel de control (solo lectura).",
            "access.link": "Leer la documentación",
            "access.form.public": "Publicar panel de control",
            "access.form.hint": "Tu panel de control estará disponible en: {0}",
            "options.organizations.none": "Ninguna",
            "toast.updated": "La organización ha sido actualizada.",
            "toast.saved": "Ajustes guardados.",
            "error.organization": "Error al cambiar la organización"
        },
        "fr": {
            "organization.title": "Organisation",
            "organization.text": "Définissez l'organisation à laquelle appartient ce site web.",
            "organization.link": "Lire la documentation",
            "organization.form.label": "Organisation",
            "organization.plus": "La gestion des organisations nécessite un abonnement à Pirsch Plus.",
            "member.title": "Membres",
            "member.text": "Donnez accès à ce tableau de bord à d'autres utilisateurs et choisissez leurs rôles.",
            "member.link": "Lire la documentation",
            "member.invite": "Inviter un membre",
            "invitations.title": "Invitations ouvertes",
            "access.title": "Accès public",
            "access.text": "Donnez à tout le monde l'accès à ce tableau de bord (lecture seule).",
            "access.link": "Lire la documentation",
            "access.form.public": "Publier le tableau de bord",
            "access.form.hint": "Votre tableau de bord sera disponible à l'adresse : {0}",
            "options.organizations.none": "Aucune",
            "toast.updated": "L'organisation a été mise à jour.",
            "toast.saved": "Paramètres enregistrés.",
            "error.organization": "Erreur lors du changement d'organisation"
        },
        "nl": {
            "organization.title": "Organisatie",
            "organization.text": "Stel de organisatie in waartoe deze website behoort.",
            "organization.link": "Lees de documentatie",
            "organization.form.label": "Organisatie",
            "organization.plus": "Het beheren van organisaties vereist een Pirsch Plus-abonnement.",
            "member.title": "Leden",
            "member.text": "Geef andere gebruikers toegang tot dit dashboard en kies hun rollen.",
            "member.link": "Lees de documentatie",
            "member.invite": "Lid uitnodigen",
            "invitations.title": "Open uitnodigingen",
            "access.title": "Publieke toegang",
            "access.text": "Geef iedereen toegang tot dit dashboard (alleen-lezen).",
            "access.link": "Lees de documentatie",
            "access.form.public": "Dashboard publiceren",
            "access.form.hint": "Je dashboard is beschikbaar op: {0}",
            "options.organizations.none": "Geen",
            "toast.updated": "De organisatie is bijgewerkt.",
            "toast.saved": "Instellingen opgeslagen.",
            "error.organization": "Fout bij het wijzigen van de organisatie"
        },
        "it": {
            "organization.title": "Organizzazione",
            "organization.text": "Imposta l'organizzazione a cui appartiene questo sito web.",
            "organization.link": "Leggi la documentazione",
            "organization.form.label": "Organizzazione",
            "organization.plus": "La gestione delle organizzazioni richiede un abbonamento a Pirsch Plus.",
            "member.title": "Membri",
            "member.text": "Dai ad altri utenti l'accesso a questa dashboard e scegli i loro ruoli.",
            "member.link": "Leggi la documentazione",
            "member.invite": "Invita membro",
            "invitations.title": "Inviti aperti",
            "access.title": "Accesso pubblico",
            "access.text": "Dai a chiunque l'accesso a questa dashboard (solo lettura).",
            "access.link": "Leggi la documentazione",
            "access.form.public": "Pubblica dashboard",
            "access.form.hint": "La tua dashboard sarà disponibile su: {0}",
            "options.organizations.none": "Nessuna",
            "toast.updated": "L'organizzazione è stata aggiornata.",
            "toast.saved": "Impostazioni salvate.",
            "error.organization": "Errore durante la modifica dell'organizzazione"
        },
        "pt": {
            "organization.title": "Organização",
            "organization.text": "Defina a organização a que pertence este site.",
            "organization.link": "Leia a documentação",
            "organization.form.label": "Organização",
            "organization.plus": "Gerenciar organizações requer uma assinatura do Pirsch Plus.",
            "member.title": "Membros",
            "member.text": "Dê a outros usuários acesso a este painel e escolha suas funções.",
            "member.link": "Leia a documentação",
            "member.invite": "Convidar Membro",
            "invitations.title": "Convites abertos",
            "access.title": "Acesso Público",
            "access.text": "Dê a qualquer pessoa acesso a este painel (somente leitura).",
            "access.link": "Leia a documentação",
            "access.form.public": "Publicar Painel",
            "access.form.hint": "Seu painel estará disponível em: {0}",
            "options.organizations.none": "Nenhuma",
            "toast.updated": "A organização foi atualizada.",
            "toast.saved": "Configurações salvas.",
            "error.organization": "Erro ao mudar a organização"
        },
        "ja": {
            "organization.title": "組織",
            "organization.text": "このウェブサイトが所属する組織を設定します。",
            "organization.link": "ドキュメントを読む",
            "organization.form.label": "組織",
            "organization.plus": "組織の管理にはPirsch Plusサブスクリプションが必要です。",
            "member.title": "メンバー",
            "member.text": "他のユーザーにこのダッシュボードへのアクセスを許可し、役割を選択します。",
            "member.link": "ドキュメントを読む",
            "member.invite": "メンバーを招待",
            "invitations.title": "未処理の招待",
            "access.title": "公開アクセス",
            "access.text": "誰でもこのダッシュボードにアクセスできるようにします（読み取り専用）。",
            "access.link": "ドキュメントを読む",
            "access.form.public": "ダッシュボードを公開",
            "access.form.hint": "あなたのダッシュボードは次のURLで利用できます: {0}",
            "options.organizations.none": "なし",
            "toast.updated": "組織が更新されました。",
            "toast.saved": "設定が保存されました。",
            "error.organization": "組織の変更中にエラーが発生しました"
        }
    }
</i18n>
