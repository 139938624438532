import {defineStore} from "pinia";
import {ref} from "vue";

export enum OverviewType {
    PANEL = "PANEL",
    LIST = "LIST"
}

export const useOverviewStore = defineStore("overview", () => {
    const type = ref(localStorage.getItem("overview_type") as OverviewType || OverviewType.PANEL);
    const search = ref("");

    function set(t: OverviewType) {
        localStorage.setItem("overview_type", t);
        type.value = t;
    }

    function setSearch(s: string) {
        search.value = s;
    }

    return {
        type,
        search,
        set,
        setSearch
    };
});
