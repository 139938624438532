<template>
    <fieldset>
        <input :type="type"
            :class="{placeholder: placeholder !== ' '}"
            :step="step"
            :id="name"
            :name="name"
            :value="modelValue"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            v-on:input="update"
            v-on:change.stop.prevent="change"
            v-on:keyup.stop.prevent="keyup"
            :disabled="disabled"
            ref="input" />
        <label :for="name">{{label}}</label>
        <small v-if="hint" v-html="hint"></small>
        <small class="error" v-if="err">{{err}}</small>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";

    export default defineComponent({
        props: {
            label: String,
            type: {type: String, default: "text"},
            step: {type: String, default: "1"},
            name: {type: String, required: true},
            placeholder: {type: String, default: " "},
            disabled: {type: Boolean, default: false},
            autofocus: {type: Boolean, default: false},
            autocomplete: {type: String, default: ""},
            modelValue: [String, Number],
            hint: String,
            err: String
        },
        emits: ["update:modelValue", "change", "keyup"],
        setup(props, {emit}) {
            const input = ref(document.createElement("input"));

            onMounted(() => {
                if (props.autofocus) {
                    input.value.focus();
                }
            });

            function update(e: Event) {
                if (e && e.target) {
                    emit("update:modelValue", (e.target as HTMLInputElement).value);
                }
            }

            function change(e: Event) {
                if (e && e.target) {
                    emit("change", (e.target as HTMLInputElement).value);
                }
            }

            function keyup(e: Event) {
                if (e && e.target) {
                    emit("keyup", (e.target as HTMLInputElement).value);
                }
            }

            return {
                input,
                update,
                change,
                keyup
            };
        }
    });
</script>
