import {createApp} from "vue";
import {createPinia} from "pinia";
import {createI18n} from "vue-i18n";
import App from "@/App.vue";
import router from "@/router";
import TableEntries from "@/components/panels/TableEntries.vue";
import StatisticsList from "@/components/panels/StatisticsList.vue";
import EventEntry from "@/components/statistics/EventEntry.vue";
import TagEntry from "@/components/statistics/TagEntry.vue";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";
import {
    ChoroplethChart,
    ChoroplethController,
    ProjectionScale,
    ColorScale,
    GeoFeature
} from "chartjs-chart-geo";
import {FunnelChart, FunnelController, TrapezoidElement} from "chartjs-chart-funnel";
import "./registerServiceWorker";

Chart.register(
    LineController,
    BarController,
    CategoryScale,
    LineElement,
    BarElement,
    PointElement,
    LinearScale,
    Tooltip,
    Filler,
    ChoroplethChart,
    ChoroplethController,
    ProjectionScale,
    ColorScale,
    GeoFeature,
    FunnelChart,
    FunnelController,
    TrapezoidElement
);

createApp(App)
    .use(createPinia())
    .use(router)
    .use(createI18n({
        legacy: false,
        locale: "en",
        fallbackLocale: "en",
        warnHtmlMessage: false
    }))
    .directive("autofocus", {
        mounted(el, isMobile) {
            if (!isMobile.value) {
                el.focus();
            }
        }
    })
    .component("TableEntries", TableEntries)
    .component("StatisticsList", StatisticsList)
    .component("EventEntry", EventEntry)
    .component("TagEntry", TagEntry)
    .mount("#app");
