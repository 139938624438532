<template>
    <SettingsPage active="goals">
        <Goals />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Goals from "@/components/settings/Goals.vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";

    export default defineComponent({
        components: {
            SettingsPage,
            Goals
        }
    });
</script>
