<template>
    <div :class="{'list-entry': true, 'cursor-pointer': selectable}">
        <div class="label">
            <img :src="entry.icon" alt="" v-if="entry.label && entry.icon" />
            <i :class="entry.icon_class" v-else-if="entry.label && entry.icon_class" />
            <i class="icon icon-globe" v-else-if="entry.icon !== undefined || entry.icon_class !== undefined" />
            <span class="primary" :title="entry.label_2" v-if="entry.label_2">
                {{entry.label_2}}
            </span>
            <span :class="{secondary: entry.label_2}" :title="rawTitle && entry.label_raw ? entry.label_raw : (entry.label ? entry.label : unknownLabel)">
                {{entry.label || unknownLabel}}
            </span>
        </div>
        <div class="data">
            <i class="icon icon-follow clickable" v-on:click.stop="$emit('open')" v-if="newTab && entry.url"></i>
            <span :class="{percentage: true, 'no-hover': showDetails || mobile}"
                :title="t('unique_visitors')"
                v-if="entry.relative_value !== undefined">
                {{formatPercent(entry.relative_value)}}%
            </span>
            <span :class="{percentage: true, 'no-hover': showDetails || mobile}"
                :title="t('events', {count: entry.count})"
                v-if="entry.count !== undefined">
                {{formatAndShortenNumber(entry.count)}}
            </span>
            <span :title="`${formatNumber(entry.value)} ${valueTitle}`">
                {{formatAndShortenNumber(entry.value)}}
            </span>
        </div>
        <div class="bar" :style="'width:'+formatPercent(entry.relative_value, 'en-US')+'%'" v-if="showBar"></div>
        <div class="division" v-if="showBar"></div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from "vue";
    import {formatPercent, formatAndShortenNumber, formatNumber} from "@/util/format";
    import {useMobile} from "@/components/mobile";
    import {ListEntry as Entry} from "@/model/ListEntry";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            entry: {type: Object as PropType<Entry>, required: true},
            entries: {type: Number, required: true},
            newTab: {type: Boolean, default: false},
            showBar: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            unknownLabel: {type: String, default: "Unknown"},
            rawTitle: {type: Boolean, default: false},
            selectable: {type: Boolean, default: false},
            valueTitle: {type: String, default: "Unique visitors"}
        },
        emits: ["open"],
        setup() {
            return {
                ...useI18n(),
                ...useMobile(),
                formatPercent,
                formatNumber,
                formatAndShortenNumber
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "unique_visitors": "Unique Visitors Relative to All Visitors",
            "events": "{count} Total Events"
        },
        "de": {
            "unique_visitors": "Eindeutige Besucher im Verhältnis zu allen Besuchern",
            "events": "{count} GesamtEvents"
        },
        "es": {
            "unique_visitors": "Visitantes únicos en relación con todos los visitantes",
            "events": "{count} Eventos totales"
        },
        "fr": {
            "unique_visitors": "Visiteurs uniques par rapport à tous les visiteurs",
            "events": "{count} Événements au total"
        },
        "nl": {
            "unique_visitors": "Unieke bezoekers in verhouding tot alle bezoekers",
            "events": "{count} Totale evenementen"
        },
        "it": {
            "unique_visitors": "Visitatori unici rispetto a tutti i visitatori",
            "events": "{count} Eventi totali"
        },
        "pt": {
            "unique_visitors": "Visitantes únicos em relação a todos os visitantes",
            "events": "{count} Eventos totais"
        },
        "ja": {
            "unique_visitors": "全訪問者に対するユニーク訪問者",
            "events": "合計{count}イベント"
        }
    }
</i18n>
