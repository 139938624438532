<template>
    <Modal :title="t('title')" size="m" v-on:close="$emit('close')">
        <p>{{t("text_0")}}</p>
        <p>{{t("text_1")}}</p>
        <HiddenArea :title="t('website.title')">
            <template v-slot:description>{{t("website.text_0")}}</template>
            <template v-slot:default>
                <i18n-t keypath="website.text_1" tag="p">
                    <a href="https://github.com/pirsch-analytics/demo" target="_blank">GitHub</a>
                </i18n-t>
                <pre style="max-width: calc(100vw - 32px);">
                    <code v-html="websiteExample" />
                </pre>
                <a href="https://docs.pirsch.io/advanced/events" target="_blank" class="button secondary">{{t("read_docs")}}</a>
            </template>
        </HiddenArea>
        <HiddenArea :title="t('backend.title')">
            <template v-slot:description>{{t("backend.text_0")}}</template>
            <template v-slot:default>
                <i18n-t keypath="backend.text_1" tag="p">
                    <a href="https://docs.pirsch.io/api-sdks/api" target="_blank">API</a>
                    <router-link to="/settings/integration">{{t("backend.text_1.link")}}</router-link>
                </i18n-t>
                <i18n-t keypath="backend.text_2" tag="p">
                    <a href="https://github.com/pirsch-analytics/demo" target="_blank">GitHub</a>
                </i18n-t>
                <pre style="max-width: calc(100vw - 32px);">
                    <code v-html="backendExample" />
                </pre>
                <div class="button-row">
                    <button v-on:click="copySnippet(backendExample)">{{t("copy_to_clipboard")}}</button>
                    <a href="https://docs.pirsch.io/advanced/events" target="_blank" class="button secondary">{{t("read_docs")}}</a>
                </div>
            </template>
        </HiddenArea>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import {useSnippet} from "@/components/settings/snippet";
    import HiddenArea from "@/components/bits/HiddenArea.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            HiddenArea,
            Modal
        },
        emits: ["close"],
        setup() {
            const {getSnippetString, copySnippet} = useSnippet();
            const snippetString = computed(() => getSnippetString());
            const websiteExample = ref(`&lt;!-- Using HTML attributes and CSS classes. --&gt;
&lt;button pirsch-event="Button Clicked" pirsch-meta-Key="Meta Value" pirsch-duration="2"&gt;
    Click me
&lt;/button&gt;
&lt;button class="pirsch-event=Button+Clicked pirsch-meta-Key=Meta+Value pirsch-duration=3"&gt;
    Click me
&lt;/button&gt;

&lt;!-- Using JavaScript. --&gt;
&lt;button id="clickme"&gt;Click me&lt;/button&gt;
&lt;script&gt;
    document.addEventListener("DOMContentLoaded", () => {
        document.getElementById("clickme").addEventListener("click", () => pirsch("JS Button Clicked", { duration: 1, meta: { Key: "Meta Value" } }));
    });
&lt;/script&gt;`);
            const backendExample = ref(`fetch("https://api.pirsch.io/api/v1/event", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer pa_4lSpFA7aO1HMUkc..."
    },
    body: JSON.stringify({
        "event_name": "Custom Event",
        "event_duration": 42,
        "event_meta": {
            "Key": "Meta Value"
        },
        "url": "https://example.com",
        "ip": "123.45.67.890",
        "accept_language": "en-US",
        "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64..."
    })
});`);

            return {
                ...useI18n(),
                copySnippet,
                snippetString,
                websiteExample,
                backendExample
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Send Events",
            "text_0": "Custom events allow you to attach metadata and gain detailed insights into how your website or app is being used.",
            "text_1": "Choose your integration below to start tracking events. You can mix the website and server-side integrations.",
            "website.title": "Website Integration",
            "website.text_0": "Track events from your website using JavaScript, CSS classes, or HTML attributes.",
            "website.text_1": "Here are a few examples on how you can track events from your website. More examples can be found on {0}.",
            "backend.title": "Server-side Integration",
            "backend.text_0": "Track events from your server without the need for JavaScript on your website.",
            "backend.text_1": "The server-side integration utilizes the {0}. {1} on the integration settings page to start using it.",
            "backend.text_1.link": "Create a Client",
            "backend.text_2": "Here is a simple example in JavaScript create an event. More examples can be found on {0}.",
            "read_docs": "Read the Documentation",
            "copy_to_clipboard": "Copy to Clipboard"
        },
        "de": {
            "title": "Events senden",
            "text_0": "Benutzerdefinierte Events ermöglichen es dir, Metadaten anzuhängen und detaillierte Einblicke zu gewinnen, wie deine Website oder App genutzt wird.",
            "text_1": "Wähle unten deine Integration aus, um mit dem Verfolgen von Events zu beginnen. Du kannst die Webseiten- und serverseitigen Integrationen mischen.",
            "website.title": "Webseiten-Integration",
            "website.text_0": "Verfolge Events von deiner Webseite mit JavaScript, CSS-Klassen oder HTML-Attributen.",
            "website.text_1": "Hier sind einige Beispiele, wie du Events von deiner Webseite verfolgen kannst. Weitere Beispiele findest du auf {0}.",
            "backend.title": "Server-seitige Integration",
            "backend.text_0": "Verfolge Events von deinem Server, ohne JavaScript auf deiner Webseite zu benötigen.",
            "backend.text_1": "Die serverseitige Integration nutzt die {0}. {1} auf der Integrationsseite, um sie zu verwenden.",
            "backend.text_1.link": "Erstelle einen Client",
            "backend.text_2": "Hier ist ein einfaches Beispiel in JavaScript, um ein Event zu erstellen. Weitere Beispiele findest du auf {0}.",
            "read_docs": "Dokumentation lesen",
            "copy_to_clipboard": "In die Zwischenablage kopieren"
        },
        "es": {
            "title": "Enviar eventos",
            "text_0": "Los eventos personalizados te permiten adjuntar metadatos y obtener información detallada sobre cómo se utiliza tu sitio web o aplicación.",
            "text_1": "Elige tu integración a continuación para comenzar a rastrear eventos. Puedes mezclar las integraciones de sitio web y del lado del servidor.",
            "website.title": "Integración del sitio web",
            "website.text_0": "Rastrea eventos desde tu sitio web usando JavaScript, clases CSS o atributos HTML.",
            "website.text_1": "Aquí tienes algunos ejemplos sobre cómo puedes rastrear eventos desde tu sitio web. Puedes encontrar más ejemplos en {0}.",
            "backend.title": "Integración del lado del servidor",
            "backend.text_0": "Rastrea eventos desde tu servidor sin necesidad de JavaScript en tu sitio web.",
            "backend.text_1": "La integración del lado del servidor utiliza la {0}. {1} en la página de configuración de la integración para comenzar a usarla.",
            "backend.text_1.link": "Crear un cliente",
            "backend.text_2": "Aquí tienes un ejemplo sencillo en JavaScript para crear un evento. Puedes encontrar más ejemplos en {0}.",
            "read_docs": "Leer la documentación",
            "copy_to_clipboard": "Copiar al portapapeles"
        },
        "fr": {
            "title": "Envoyer des événements",
            "text_0": "Les événements personnalisés vous permettent de joindre des métadonnées et d'obtenir des informations détaillées sur la manière dont votre site Web ou votre application est utilisé.",
            "text_1": "Choisissez votre intégration ci-dessous pour commencer à suivre les événements. Vous pouvez mélanger les intégrations de site Web et de serveur.",
            "website.title": "Intégration du site Web",
            "website.text_0": "Suivez les événements de votre site Web à l'aide de JavaScript, de classes CSS ou d'attributs HTML.",
            "website.text_1": "Voici quelques exemples de la façon dont vous pouvez suivre les événements de votre site Web. Vous pouvez trouver plus d'exemples sur {0}.",
            "backend.title": "Intégration côté serveur",
            "backend.text_0": "Suivez les événements de votre serveur sans avoir besoin de JavaScript sur votre site Web.",
            "backend.text_1": "L'intégration côté serveur utilise la {0}. {1} sur la page des paramètres d'intégration pour commencer à l'utiliser.",
            "backend.text_1.link": "Créer un client",
            "backend.text_2": "Voici un exemple simple en JavaScript pour créer un événement. Vous pouvez trouver plus d'exemples sur {0}.",
            "read_docs": "Lire la documentation",
            "copy_to_clipboard": "Copier dans le presse-papiers"
        },
        "nl": {
            "title": "Verstuur gebeurtenissen",
            "text_0": "Aangepaste gebeurtenissen stellen je in staat om metadata toe te voegen en gedetailleerde inzichten te verkrijgen over hoe je website of app wordt gebruikt.",
            "text_1": "Kies hieronder je integratie om te beginnen met het volgen van gebeurtenissen. Je kunt de website- en server-side integraties mixen.",
            "website.title": "Website-integratie",
            "website.text_0": "Volg gebeurtenissen vanaf je website met behulp van JavaScript, CSS-klassen of HTML-attributen.",
            "website.text_1": "Hier zijn een paar voorbeelden van hoe je gebeurtenissen vanaf je website kunt volgen. Meer voorbeelden vind je op {0}.",
            "backend.title": "Server-side integratie",
            "backend.text_0": "Volg gebeurtenissen vanaf je server zonder dat JavaScript op je website nodig is.",
            "backend.text_1": "De server-side integratie maakt gebruik van de {0}. {1} op de instellingenpagina voor integratie om het te gaan gebruiken.",
            "backend.text_1.link": "Maak een client",
            "backend.text_2": "Hier is een eenvoudig voorbeeld in JavaScript om een gebeurtenis te maken. Meer voorbeelden vind je op {0}.",
            "read_docs": "Lees de documentatie",
            "copy_to_clipboard": "Kopieer naar klembord"
        },
        "it": {
            "title": "Invia eventi",
            "text_0": "Gli eventi personalizzati ti consentono di allegare metadati e ottenere informazioni dettagliate su come viene utilizzato il tuo sito web o la tua app.",
            "text_1": "Scegli la tua integrazione qui sotto per iniziare a tracciare gli eventi. Puoi combinare le integrazioni del sito web e lato server.",
            "website.title": "Integrazione del sito web",
            "website.text_0": "Traccia gli eventi dal tuo sito web utilizzando JavaScript, classi CSS o attributi HTML.",
            "website.text_1": "Ecco alcuni esempi su come puoi tracciare gli eventi dal tuo sito web. Puoi trovare più esempi su {0}.",
            "backend.title": "Integrazione lato server",
            "backend.text_0": "Traccia gli eventi dal tuo server senza bisogno di JavaScript sul tuo sito web.",
            "backend.text_1": "L'integrazione lato server utilizza il {0}. {1} sulla pagina delle impostazioni di integrazione per iniziare a utilizzarlo.",
            "backend.text_1.link": "Crea un client",
            "backend.text_2": "Ecco un semplice esempio in JavaScript per creare un evento. Puoi trovare più esempi su {0}.",
            "read_docs": "Leggi la documentazione",
            "copy_to_clipboard": "Copia negli appunti"
        },
        "pt": {
            "title": "Enviar eventos",
            "text_0": "Eventos personalizados permitem anexar metadados e obter informações detalhadas sobre como seu site ou aplicativo está sendo usado.",
            "text_1": "Escolha sua integração abaixo para começar a rastrear eventos. Você pode misturar as integrações de site e do lado do servidor.",
            "website.title": "Integração do site",
            "website.text_0": "Rastreie eventos do seu site usando JavaScript, classes CSS ou atributos HTML.",
            "website.text_1": "Aqui estão alguns exemplos de como você pode rastrear eventos do seu site. Mais exemplos podem ser encontrados em {0}.",
            "backend.title": "Integração do lado do servidor",
            "backend.text_0": "Rastreie eventos do seu servidor sem a necessidade de JavaScript no seu site.",
            "backend.text_1": "A integração do lado do servidor utiliza a {0}. {1} na página de configurações de integração para começar a usá-la.",
            "backend.text_1.link": "Criar um cliente",
            "backend.text_2": "Aqui está um exemplo simples em JavaScript para criar um evento. Mais exemplos podem ser encontrados em {0}.",
            "read_docs": "Leia a documentação",
            "copy_to_clipboard": "Copiar para a área de transferência"
        },
        "ja": {
            "title": "イベントを送信",
            "text_0": "カスタムイベントを使用すると、メタデータを添付して、Webサイトやアプリの使用状況に関する詳細な洞察を得ることができます。",
            "text_1": "イベントのトラッキングを開始するには、以下の統合オプションを選択してください。ウェブサイトとサーバーサイドの統合を組み合わせることができます。",
            "website.title": "ウェブサイト統合",
            "website.text_0": "JavaScript、CSSクラス、またはHTML属性を使用して、ウェブサイトからイベントをトラッキングします。",
            "website.text_1": "ウェブサイトからイベントをトラッキングする方法のいくつかの例を以下に示します。詳細な例は{0}にあります。",
            "backend.title": "サーバーサイド統合",
            "backend.text_0": "ウェブサイト上でJavaScriptを必要とせずにサーバーからイベントをトラッキングします。",
            "backend.text_1": "サーバーサイドの統合は{0}を利用します。{1}の統合設定ページで使用を開始します。",
            "backend.text_1.link": "クライアントを作成",
            "backend.text_2": "JavaScriptでイベントを作成する簡単な例を以下に示します。詳細な例は{0}にあります。",
            "read_docs": "ドキュメントを読む",
            "copy_to_clipboard": "クリップボードにコピー"
        }
    }
</i18n>
