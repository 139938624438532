<template>
    <Modal :title="t('title')" v-on:close="$emit('close')">
        <p v-if="!locked">{{t("text_0")}}</p>
        <p v-else>{{t("text_1")}}</p>
        <form v-on:submit.prevent="lockEntry">
            <FormSubmit :color="locked ? 'red' : undefined" :value="locked ? t('form.submit.unlock') : t('form.submit.lock')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {APIError} from "@/repositories/APIError";
    import {useError} from "@/components/error";
    import {LockRepo} from "@/repositories/LockRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useLockStore} from "@/store/LockStore";
    import {Lock} from "@/model/Lock";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            forDomain: {type: Boolean, default: true},
            lock: {type: String, required: true},
            locked: {type: Boolean, required: true}
        },
        emits: ["close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {set, release} = useLockStore();
            const {domain} = storeToRefs(useDomainStore());
            const {resetError} = useError();
            const {success, error} = useToastStore();
            const loading = ref(false);

            async function lockEntry() {
                try {
                    loading.value = true;
                    resetError();
                    const domainID = props.forDomain ? domain.value.id : undefined;
                    const lock = await LockRepo.toggle(domainID, props.lock) as Lock;

                    if (props.locked) {
                        release(props.lock);
                        success(t("toast.unlocked"));
                    } else {
                        set(lock);
                        success(t("toast.locked"));
                    }

                    loading.value = false;
                    emit("close");
                } catch (e) {
                    loading.value = false;
                    error(e as APIError);
                }
            }

            return {
                t,
                loading,
                lockEntry
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Lock Entry",
            "text_0": "Are you sure you would like to lock this entry? Locking it will prevent it from being modified through the API without unlocking it here first.",
            "text_1": "Are you sure you would like to unlock this entry? Unlocking it will allow it to be modified through the web interface and API.",
            "form.submit.lock": "Lock",
            "form.submit.unlock": "Unlock",
            "toast.unlocked": "Entry unlocked.",
            "toast.locked": "Entry locked."
        },
        "de": {
            "title": "Eintrag sperren",
            "text_0": "Bist du sicher, dass du diesen Eintrag sperren möchtest? Das Sperren verhindert, dass er über die API ohne vorheriges Entsperren hier geändert wird.",
            "text_1": "Bist du sicher, dass du diesen Eintrag entsperren möchtest? Das Entsperren ermöglicht es, ihn über die Web-Oberfläche und die API zu ändern.",
            "form.submit.lock": "Sperren",
            "form.submit.unlock": "Entsperren",
            "toast.unlocked": "Eintrag entsperrt.",
            "toast.locked": "Eintrag gesperrt."
        },
        "es": {
            "title": "Bloquear entrada",
            "text_0": "¿Estás seguro de que deseas bloquear esta entrada? Bloquearla evitará que se modifique a través de la API sin desbloquearla aquí primero.",
            "text_1": "¿Estás seguro de que deseas desbloquear esta entrada? Desbloquearla permitirá que se modifique a través de la interfaz web y la API.",
            "form.submit.lock": "Bloquear",
            "form.submit.unlock": "Desbloquear",
            "toast.unlocked": "Entrada desbloqueada.",
            "toast.locked": "Entrada bloqueada."
        },
        "fr": {
            "title": "Verrouiller l'entrée",
            "text_0": "Êtes-vous sûr de vouloir verrouiller cette entrée ? La verrouiller empêchera toute modification via l'API sans la déverrouiller ici en premier.",
            "text_1": "Êtes-vous sûr de vouloir déverrouiller cette entrée ? La déverrouiller permettra de la modifier via l'interface web et l'API.",
            "form.submit.lock": "Verrouiller",
            "form.submit.unlock": "Déverrouiller",
            "toast.unlocked": "Entrée déverrouillée.",
            "toast.locked": "Entrée verrouillée."
        },
        "nl": {
            "title": "Invoer vergrendelen",
            "text_0": "Weet je zeker dat je deze invoer wilt vergrendelen? Door te vergrendelen wordt voorkomen dat deze via de API wordt gewijzigd zonder deze eerst hier te ontgrendelen.",
            "text_1": "Weet je zeker dat je deze invoer wilt ontgrendelen? Ontgrendelen maakt het mogelijk om het via de webinterface en de API te wijzigen.",
            "form.submit.lock": "Vergrendelen",
            "form.submit.unlock": "Ontgrendelen",
            "toast.unlocked": "Invoer ontgrendeld.",
            "toast.locked": "Invoer vergrendeld."
        },
        "it": {
            "title": "Blocca voce",
            "text_0": "Sei sicuro di voler bloccare questa voce? Bloccarla impedirà che venga modificata tramite l'API senza prima sbloccarla qui.",
            "text_1": "Sei sicuro di voler sbloccare questa voce? Sbloccarla permetterà di modificarla tramite l'interfaccia web e l'API.",
            "form.submit.lock": "Blocca",
            "form.submit.unlock": "Sblocca",
            "toast.unlocked": "Voce sbloccata.",
            "toast.locked": "Voce bloccata."
        },
        "pt": {
            "title": "Bloquear entrada",
            "text_0": "Tem certeza de que deseja bloquear esta entrada? Bloqueá-la impedirá que seja modificada através da API sem desbloqueá-la aqui primeiro.",
            "text_1": "Tem certeza de que deseja desbloquear esta entrada? Desbloqueá-la permitirá que seja modificada através da interface web e API.",
            "form.submit.lock": "Bloquear",
            "form.submit.unlock": "Desbloquear",
            "toast.unlocked": "Entrada desbloqueada.",
            "toast.locked": "Entrada bloqueada."
        },
        "ja": {
            "title": "エントリをロック",
            "text_0": "このエントリをロックしてもよろしいですか？ ロックすると、ここでロックを解除するまでAPI経由での変更ができなくなります。",
            "text_1": "このエントリのロックを解除してもよろしいですか？ ロックを解除すると、WebインターフェイスおよびAPI経由で変更できるようになります。",
            "form.submit.lock": "ロック",
            "form.submit.unlock": "ロック解除",
            "toast.unlocked": "エントリのロックが解除されました。",
            "toast.locked": "エントリがロックされました。"
        }
    }
</i18n>
