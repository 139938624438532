<template>
    <component :is="component"
        panel="countries"
        :title="t('title')"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'countries' : 'top_countries')"
        :loadMore="loadMore(limitless ? 'countries' : 'top_countries')"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :showDetails="showDetails"
        :selectable="true"
        v-on:filter="filterByCountry"
        v-on:search="searchAndSort" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {ListEntry} from "@/model/ListEntry";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useTable} from "@/components/panels/table";
    import {Row} from "@/components/panels/Row";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, required: true}
        },
        setup: function (props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadCountries, countries} = useStatisticsStore();
            const {setField} = filterStore;
            const {searchAndSort} = useTable(loadCountries);
            const rawData = computed(() => countries(props.limitless));
            const data = ref<Row[]>([]);
            const columns = computed(() => [
                {key: "label", label: t("columns.country"), order_by: "-"},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.visitors_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_visitors"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("countries_table");
                }

                data.value = rawData.value;
            });

            watch([rawData, () => props.visible], () => {
                if (props.visible) {
                    data.value = rawData.value;
                }
            });

            function filterByCountry(entry: ListEntry) {
                setField("country", entry.filter || "null");
            }

            return {
                t,
                columns,
                isLoading,
                loadMore,
                data,
                filterByCountry,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Countries",
            "columns.country": "Country",
            "columns.visitors": "Visitors",
            "columns.visitors_relative": "Visitors %"
        },
        "de": {
            "title": "Länder",
            "columns.country": "Land",
            "columns.visitors": "Besucher",
            "columns.visitors_relative": "Besucher %"
        },
        "es": {
            "title": "Países",
            "columns.country": "País",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %"
        },
        "fr": {
            "title": "Pays",
            "columns.country": "Pays",
            "columns.visitors": "Visiteurs",
            "columns.visitors_relative": "Visiteurs %"
        },
        "nl": {
            "title": "Landen",
            "columns.country": "Land",
            "columns.visitors": "Bezoekers",
            "columns.visitors_relative": "Bezoekers %"
        },
        "it": {
            "title": "Paesi",
            "columns.country": "Paese",
            "columns.visitors": "Visitatori",
            "columns.visitors_relative": "Visitatori %"
        },
        "pt": {
            "title": "Países",
            "columns.country": "País",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %"
        },
        "ja": {
            "title": "国",
            "columns.country": "国",
            "columns.visitors": "訪問者",
            "columns.visitors_relative": "訪問者 %"
        }
    }
</i18n>
