<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <form v-on:submit.prevent>
                <FormSelect :label="t('form.language')" name="language" :options="languageOptions" v-model="language" v-on:change="saveLanguage" />
            </form>
            <form v-on:submit.prevent>
                <FormSelect :label="t('form.dark_mode')" name="darkMode" :options="darkModeOptions" v-model="darkMode" v-on:change="saveDarkMode" />
            </form>
            <form v-on:submit.prevent>
                <FormSelect :label="t('form.time_range')" name="defaultTimeRange" :options="offsets" v-model="defaultTimeRange" v-on:change="updateFilter" />
            </form>
            <FormCheckbox :label="t('form.full_width')" name="fullWidth" toggle v-model="fullWidth" v-on:change="toggleFullWidth" />
            <FormCheckbox :label="t('form.active_visitors')" name="activeVisitorsTab" toggle v-model="activeVisitorsTitle" v-on:change="toggleActiveVisitorsTitle" />
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {setFullWidth} from "@/util/style";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {timeRangeOptions} from "@/components/panels/time_range_options";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect,
            FormCheckbox
        },
        setup() {
            const {t} = useI18n();
            const userStore = useUserStore();
            const themeStore = useThemeStore();
            const {success} = useToastStore();
            const {user} = storeToRefs(userStore);
            const language = ref("");
            const darkMode = ref(""); // "true", "false", ""
            const defaultTimeRange = ref(user.value.default_time_range);
            const activeVisitorsTitle = ref(false);
            const fullWidth = ref(false);
            const languageOptions = computed(() => [
                {label: t("options.languages.default"), value: ""},
                {label: "English", value: "en"},
                {label: "Deutsch", value: "de"},
                {label: "Español", value: "es"},
                {label: "Français", value: "fr"},
                {label: "Nederlands", value: "nl"},
                {label: "Italiano", value: "it"},
                {label: "Português", value: "pt"},
                {label: "日本語", value: "ja"}
            ]);
            const darkModeOptions = computed(() => [
                {label: t("options.mode.system"), value: ""},
                {label: t("options.mode.dark"), value: "true"},
                {label: t("options.mode.light"), value: "false"}
            ]);
            const offsets = computed(() => {
                const options = timeRangeOptions.slice(2).map(e => ({...e}));

                for (let i = 0; i < options.length; i++) {
                    options[i].label = t("options.offset."+options[i].label);
                }

                return options;
            });

            onMounted(() => {
                init();
            });

            watch(user, () => {
                init();
            });

            function init() {
                language.value = user.value.language ?? "";
                darkMode.value = user.value.dark_mode != null ? user.value.dark_mode.toString() : "";
                defaultTimeRange.value = user.value.default_time_range;
                activeVisitorsTitle.value = user.value.active_visitors_title;
                fullWidth.value = user.value.dashboard_full_width;
            }

            async function saveLanguage() {
                if (user) {
                    await UserRepo.setLanguage(language.value);
                    success(t("toast.saved"));
                    location.reload();
                }
            }

            async function saveDarkMode() {
                if (user) {
                    await UserRepo.setDarkMode(darkMode.value);
                    await userStore.load();
                    success(t("toast.saved"));
                    themeStore.setDarkMode(darkMode.value);
                }
            }

            async function updateFilter() {
                if (user) {
                    await UserRepo.setFilter(defaultTimeRange.value);
                    await userStore.load();
                    success(t("toast.saved"));
                }
            }

            async function toggleActiveVisitorsTitle() {
                if (user) {
                    await UserRepo.toggleActiveVisitorsTitle();
                    await userStore.load();
                    success(t("toast.saved"));
                }
            }

            async function toggleFullWidth() {
                if (user) {
                    await UserRepo.toggleFullWidth();
                    await userStore.load();
                    setFullWidth(fullWidth.value);
                    success(t("toast.saved"));
                }
            }

            return {
                t,
                languageOptions,
                darkModeOptions,
                offsets,
                language,
                darkMode,
                defaultTimeRange,
                activeVisitorsTitle,
                fullWidth,
                saveLanguage,
                saveDarkMode,
                updateFilter,
                toggleActiveVisitorsTitle,
                toggleFullWidth
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "User Interface",
            "text": "Change the appearance of the dashboard.",
            "form.language": "Language",
            "form.dark_mode": "Light/Dark Mode",
            "form.time_range": "Default Time Range",
            "form.full_width": "Use Full Width",
            "form.active_visitors": "Show Active Visitors in Page Title",
            "options.languages.default": "Browser Language (default)",
            "options.mode.system": "System",
            "options.mode.dark": "Dark",
            "options.mode.light": "Light",
            "toast.saved": "Settings saved.",
            "options.offset.Live": "Live",
            "options.offset.Past Hour": "Past Hour",
            "options.offset.Today": "Today",
            "options.offset.Yesterday": "Yesterday",
            "options.offset.Month to Date": "Month to Date",
            "options.offset.Last Month": "Last Month",
            "options.offset.Past 7 Days": "Past 7 Days",
            "options.offset.Past 14 Days": "Past 14 Days",
            "options.offset.Past 30 Days": "Past 30 Days",
            "options.offset.Past Quarter": "Past Quarter",
            "options.offset.Past Half Year": "Past Half Year",
            "options.offset.Past Year": "Past Year",
            "options.offset.All Time": "All Time"
        },
        "de": {
            "title": "Benutzeroberfläche",
            "text": "Das Erscheinungsbild des Dashboards ändern.",
            "form.language": "Sprache",
            "form.dark_mode": "Hell-/Dunkelmodus",
            "form.time_range": "Standardzeitbereich",
            "form.full_width": "Vollbreite verwenden",
            "form.active_visitors": "Aktive Besucher im Seitentitel anzeigen",
            "options.languages.default": "Browsersprache (Standard)",
            "options.mode.system": "System",
            "options.mode.dark": "Dunkel",
            "options.mode.light": "Hell",
            "toast.saved": "Einstellungen gespeichert.",
            "options.offset.Live": "Live",
            "options.offset.Past Hour": "Letzte Stunde",
            "options.offset.Today": "Heute",
            "options.offset.Yesterday": "Gestern",
            "options.offset.Month to Date": "Monat bis heute",
            "options.offset.Last Month": "Letzter Monat",
            "options.offset.Past 7 Days": "Letzte 7 Tage",
            "options.offset.Past 14 Days": "Letzte 14 Tage",
            "options.offset.Past 30 Days": "Letzte 30 Tage",
            "options.offset.Past Quarter": "Letztes Quartal",
            "options.offset.Past Half Year": "Letztes halbes Jahr",
            "options.offset.Past Year": "Letztes Jahr",
            "options.offset.All Time": "Gesamte Zeit"
        },
        "es": {
            "title": "Interfaz de Usuario",
            "text": "Cambiar la apariencia del tablero.",
            "form.language": "Idioma",
            "form.dark_mode": "Modo Claro/Oscuro",
            "form.time_range": "Rango de Tiempo Predeterminado",
            "form.full_width": "Usar Ancho Completo",
            "form.active_visitors": "Mostrar Visitantes Activos en el Título de la Página",
            "options.languages.default": "Idioma del Navegador (predeterminado)",
            "options.mode.system": "Sistema",
            "options.mode.dark": "Oscuro",
            "options.mode.light": "Claro",
            "toast.saved": "Configuraciones guardadas.",
            "options.offset.Live": "En Vivo",
            "options.offset.Past Hour": "Última Hora",
            "options.offset.Today": "Hoy",
            "options.offset.Yesterday": "Ayer",
            "options.offset.Month to Date": "Mes hasta la Fecha",
            "options.offset.Last Month": "Mes Pasado",
            "options.offset.Past 7 Days": "Últimos 7 Días",
            "options.offset.Past 14 Days": "Últimos 14 Días",
            "options.offset.Past 30 Days": "Últimos 30 Días",
            "options.offset.Past Quarter": "Último Trimestre",
            "options.offset.Past Half Year": "Último Medio Año",
            "options.offset.Past Year": "Último Año",
            "options.offset.All Time": "Todo el Tiempo"
        },
        "fr": {
            "title": "Interface Utilisateur",
            "text": "Changer l'apparence du tableau de bord.",
            "form.language": "Langue",
            "form.dark_mode": "Mode Clair/Sombre",
            "form.time_range": "Plage de Temps par Défaut",
            "form.full_width": "Utiliser la Largeur Complète",
            "form.active_visitors": "Afficher les Visiteurs Actifs dans le Titre de la Page",
            "options.languages.default": "Langue du Navigateur (par défaut)",
            "options.mode.system": "Système",
            "options.mode.dark": "Sombre",
            "options.mode.light": "Clair",
            "toast.saved": "Paramètres enregistrés.",
            "options.offset.Live": "En Direct",
            "options.offset.Past Hour": "Dernière Heure",
            "options.offset.Today": "Aujourd'hui",
            "options.offset.Yesterday": "Hier",
            "options.offset.Month to Date": "Mois à Ce Jour",
            "options.offset.Last Month": "Mois Dernier",
            "options.offset.Past 7 Days": "7 Derniers Jours",
            "options.offset.Past 14 Days": "14 Derniers Jours",
            "options.offset.Past 30 Days": "30 Derniers Jours",
            "options.offset.Past Quarter": "Dernier Trimestre",
            "options.offset.Past Half Year": "Dernier Demi-An",
            "options.offset.Past Year": "Dernière Année",
            "options.offset.All Time": "Tout le Temps"
        },
        "nl": {
            "title": "Gebruikersinterface",
            "text": "Verander het uiterlijk van het dashboard.",
            "form.language": "Taal",
            "form.dark_mode": "Licht/Donker Modus",
            "form.time_range": "Standaardtijdbereik",
            "form.full_width": "Gebruik Volledige Breedte",
            "form.active_visitors": "Toon Actieve Bezoekers in Paginatitel",
            "options.languages.default": "Browsersprache (standaard)",
            "options.mode.system": "Systeem",
            "options.mode.dark": "Donker",
            "options.mode.light": "Licht",
            "toast.saved": "Instellingen opgeslagen.",
            "options.offset.Live": "Live",
            "options.offset.Past Hour": "Afgelopen Uur",
            "options.offset.Today": "Vandaag",
            "options.offset.Yesterday": "Gisteren",
            "options.offset.Month to Date": "Maand tot Nu",
            "options.offset.Last Month": "Vorige Maand",
            "options.offset.Past 7 Days": "Laatste 7 Dagen",
            "options.offset.Past 14 Days": "Laatste 14 Dagen",
            "options.offset.Past 30 Days": "Laatste 30 Dagen",
            "options.offset.Past Quarter": "Laatste Kwartaal",
            "options.offset.Past Half Year": "Laatste Halfjaar",
            "options.offset.Past Year": "Laatste Jaar",
            "options.offset.All Time": "Altijd"
        },
        "it": {
            "title": "Interfaccia Utente",
            "text": "Cambia l'aspetto del dashboard.",
            "form.language": "Lingua",
            "form.dark_mode": "Modalità Chiaro/Scuro",
            "form.time_range": "Intervallo di Tempo Predefinito",
            "form.full_width": "Usa Larghezza Completa",
            "form.active_visitors": "Mostra Visitatori Attivi nel Titolo della Pagina",
            "options.languages.default": "Lingua del Browser (predefinita)",
            "options.mode.system": "Sistema",
            "options.mode.dark": "Scuro",
            "options.mode.light": "Chiaro",
            "toast.saved": "Impostazioni salvate.",
            "options.offset.Live": "Live",
            "options.offset.Past Hour": "Ultima Ora",
            "options.offset.Today": "Oggi",
            "options.offset.Yesterday": "Ieri",
            "options.offset.Month to Date": "Mese fino ad Oggi",
            "options.offset.Last Month": "Mese Scorso",
            "options.offset.Past 7 Days": "Ultimi 7 Giorni",
            "options.offset.Past 14 Days": "Ultimi 14 Giorni",
            "options.offset.Past 30 Days": "Ultimi 30 Giorni",
            "options.offset.Past Quarter": "Ultimo Trimestre",
            "options.offset.Past Half Year": "Ultimo Semestre",
            "options.offset.Past Year": "Ultimo Anno",
            "options.offset.All Time": "Tutto il Tempo"
        },
        "pt": {
            "title": "Interface do Usuário",
            "text": "Alterar a aparência do painel.",
            "form.language": "Idioma",
            "form.dark_mode": "Modo Claro/Escuro",
            "form.time_range": "Intervalo de Tempo Padrão",
            "form.full_width": "Usar Largura Completa",
            "form.active_visitors": "Mostrar Visitantes Ativos no Título da Página",
            "options.languages.default": "Idioma do Navegador (padrão)",
            "options.mode.system": "Sistema",
            "options.mode.dark": "Escuro",
            "options.mode.light": "Claro",
            "toast.saved": "Configurações salvas.",
            "options.offset.Live": "Ao Vivo",
            "options.offset.Past Hour": "Última Hora",
            "options.offset.Today": "Hoje",
            "options.offset.Yesterday": "Ontem",
            "options.offset.Month to Date": "Mês até a Data",
            "options.offset.Last Month": "Mês Passado",
            "options.offset.Past 7 Days": "Últimos 7 Dias",
            "options.offset.Past 14 Days": "Últimos 14 Dias",
            "options.offset.Past 30 Days": "Últimos 30 Dias",
            "options.offset.Past Quarter": "Último Trimestre",
            "options.offset.Past Half Year": "Último Semestre",
            "options.offset.Past Year": "Último Ano",
            "options.offset.All Time": "Todo o Tempo"
        },
        "ja": {
            "title": "ユーザーインターフェース",
            "text": "ダッシュボードの外観を変更します。",
            "form.language": "言語",
            "form.dark_mode": "ライト/ダークモード",
            "form.time_range": "デフォルトの時間範囲",
            "form.full_width": "フル幅を使用",
            "form.active_visitors": "ページタイトルにアクティブ訪問者を表示",
            "options.languages.default": "ブラウザの言語 (デフォルト)",
            "options.mode.system": "システム",
            "options.mode.dark": "ダーク",
            "options.mode.light": "ライト",
            "toast.saved": "設定が保存されました。",
            "options.offset.Live": "ライブ",
            "options.offset.Past Hour": "過去1時間",
            "options.offset.Today": "今日",
            "options.offset.Yesterday": "昨日",
            "options.offset.Month to Date": "今月",
            "options.offset.Last Month": "先月",
            "options.offset.Past 7 Days": "過去7日間",
            "options.offset.Past 14 Days": "過去14日間",
            "options.offset.Past 30 Days": "過去30日間",
            "options.offset.Past Quarter": "過去四半期",
            "options.offset.Past Half Year": "過去半年",
            "options.offset.Past Year": "過去1年間",
            "options.offset.All Time": "すべての期間"
        }
    }
</i18n>
