import {computed, ComputedRef} from "vue";
import {getTimeZones} from "@vvo/tzdb";
import {SelectOption} from "@/components/form/SelectOption";

interface Timezone {
    timezones: ComputedRef<SelectOption[]>
    tz: ComputedRef<string>
}

export function useTimezone(): Timezone {
    const timezones = computed(() => {
        const tz = getTimeZones();
        const timezones = [];
        timezones.push({
            value: "UTC",
            label: "UTC"
        });

        for (let i = 0; i < tz.length; i++) {
            timezones.push({
                value: tz[i].name,
                label: `GMT${tz[i].currentTimeFormat}`
            });
        }

        return timezones;
    });
    const tz = computed(() => Intl.DateTimeFormat().resolvedOptions().timeZone);

    return {
        timezones,
        tz
    };
}
