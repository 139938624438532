<template>
    <StatisticsList :title="t('title')"
        :entries="data"
        :loading="isLoading('platform')"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :unknownLabel="t('unknown')"
        v-on:filter="filterByPlatform" />
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {ListEntry} from "@/model/ListEntry";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {Row} from "@/components/panels/Row";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const {isLoading, platform} = useStatisticsStore();
            const {setField} = filterStore;
            const data = ref<Row[]>([]);

            watch([platform, () => props.visible], () => {
                if (props.visible) {
                    data.value = platform();
                }
            });

            function filterByPlatform(entry: ListEntry) {
                setField("platform", entry.filter || "null");
            }

            return {
                ...useI18n(),
                isLoading,
                data,
                filterByPlatform
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Platforms",
            "unknown": "Unknown"
        },
        "de": {
            "title": "Plattformen",
            "unknown": "Unbekannt"
        },
        "es": {
            "title": "Plataformas",
            "unknown": "Desconocido"
        },
        "fr": {
            "title": "Plateformes",
            "unknown": "Inconnu"
        },
        "nl": {
            "title": "Platforms",
            "unknown": "Onbekend"
        },
        "it": {
            "title": "Piattaforme",
            "unknown": "Sconosciuto"
        },
        "pt": {
            "title": "Plataformas",
            "unknown": "Desconhecido"
        },
        "ja": {
            "title": "プラットフォーム",
            "unknown": "不明"
        }
    }
</i18n>
