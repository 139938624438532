<template>
    <component :is="component"
        panel="keywords"
        :title="t('title')"
        :tooltip="t('tooltip')"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'keywords' : 'top_keywords')"
        :showBar="true"
        :showEnlarge="true"
        :sortable="true" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {Row} from "@/components/panels/Row";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const {isLoading, keywords} = useStatisticsStore();
            const rawData = computed(() => keywords(props.limitless));
            const data = ref<Row[]>([]);
            const columns = computed(() => [
                {key: "label", label: t("columns.keywords"), order_by: "label"},
                {key: "value", label: t("columns.clicks"), transform: (v: number) => formatAndShortenNumber(v), order_by: "value"},
                {key: "impressions", label: t("columns.impressions"), transform: (v: number) => formatAndShortenNumber(v), order_by: "impressions"},
                {key: "relative_value", label: t("columns.ctr"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_value"},
                {key: "position", label: t("columns.position"), transform: (v: number) => formatAndShortenNumber(v), order_by: "position"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("keywords_table");
                }

                data.value = rawData.value;
            });

            watch([rawData, () => props.visible], () => {
                if (props.visible) {
                    data.value = rawData.value;
                }
            });

            return {
                t,
                columns,
                isLoading,
                data
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Keywords",
            "tooltip": "Google Search keywords users entered to find your website",
            "columns.keywords": "Keywords",
            "columns.clicks": "Clicks",
            "columns.impressions": "Impressions",
            "columns.ctr": "CTR",
            "columns.position": "Position"
        },
        "de": {
            "title": "Keywords",
            "tooltip": "Google-Suchbegriffe, die Benutzer eingegeben haben, um deine Website zu finden",
            "columns.keywords": "Keywords",
            "columns.clicks": "Klicks",
            "columns.impressions": "Impressionen",
            "columns.ctr": "CTR",
            "columns.position": "Position"
        },
        "es": {
            "title": "Palabras Clave",
            "tooltip": "Palabras clave de búsqueda de Google que los usuarios ingresaron para encontrar tu sitio web",
            "columns.keywords": "Palabras Clave",
            "columns.clicks": "Clics",
            "columns.impressions": "Impresiones",
            "columns.ctr": "CTR",
            "columns.position": "Posición"
        },
        "fr": {
            "title": "Mots-Clés",
            "tooltip": "Mots-clés de recherche Google que les utilisateurs ont entrés pour trouver votre site web",
            "columns.keywords": "Mots-Clés",
            "columns.clicks": "Clics",
            "columns.impressions": "Impressions",
            "columns.ctr": "CTR",
            "columns.position": "Position"
        },
        "nl": {
            "title": "Trefwoorden",
            "tooltip": "Google-zoekwoorden die gebruikers hebben ingevoerd om je website te vinden",
            "columns.keywords": "Trefwoorden",
            "columns.clicks": "Klikken",
            "columns.impressions": "Impressies",
            "columns.ctr": "CTR",
            "columns.position": "Positie"
        },
        "it": {
            "title": "Parole Chiave",
            "tooltip": "Parole chiave di ricerca su Google inserite dagli utenti per trovare il tuo sito web",
            "columns.keywords": "Parole Chiave",
            "columns.clicks": "Clic",
            "columns.impressions": "Impressioni",
            "columns.ctr": "CTR",
            "columns.position": "Posizione"
        },
        "pt": {
            "title": "Palavras-Chave",
            "tooltip": "Palavras-chave de pesquisa do Google que os usuários inseriram para encontrar seu site",
            "columns.keywords": "Palavras-Chave",
            "columns.clicks": "Cliques",
            "columns.impressions": "Impressões",
            "columns.ctr": "CTR",
            "columns.position": "Posição"
        },
        "ja": {
            "title": "キーワード",
            "tooltip": "ユーザーがあなたのウェブサイトを見つけるために入力したGoogle検索キーワード",
            "columns.keywords": "キーワード",
            "columns.clicks": "クリック数",
            "columns.impressions": "インプレッション",
            "columns.ctr": "CTR",
            "columns.position": "ポジション"
        }
    }
</i18n>
