<template>
    <ul v-if="errors.length > 0" style="margin-bottom: 12px;">
        <li v-for="e in errors" :key="e">
            <p class="warning">{{e}}</p>
        </li>
    </ul>
</template>

<script lang="ts">
    import {defineComponent, computed, PropType} from "vue";
    import {APIError} from "@/repositories/APIError";
    import {useI18n} from "vue-i18n";
    import {errors as errorMessages} from "@/store/errors";

    export default defineComponent({
        props: {
            err: Object as PropType<APIError>,
            validationErrors: {type: Boolean, default: false}
        },
        setup(props) {
            const {locale} = useI18n();

            const errors = computed(() => {
                const errors = [];

                if (props.err) {
                    props.err.error.forEach(e => errors.push(errorMessages[locale.value][e]));

                    if (props.validationErrors) {
                        for (const key in props.err.validation) {
                            const m = props.err.validation[key];
                            errors.push(errorMessages[locale.value][m]);
                        }
                    }
                }

                return errors;
            });

            return {
                errors
            };
        }
    });
</script>
