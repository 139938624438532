import {BaseEntity} from "@/model/BaseEntity";
import {Role} from "@/model/Role";
import {KeyValue} from "@/model/KeyValue";

export interface Domain extends BaseEntity {
    user_id: string
    organization_id?: string
    hostname: string
    subdomain: string
    identification_code: string
    public: boolean
    user_role: Role
    google_user_id: string
    google_user_email: string
    gsc_domain: string
    timezone?: string
    group_by_title: boolean
    active_visitors_seconds?: number
    new_owner: number // user ID
    disable_scripts: boolean
    statistics_start?: Date
    imported_statistics: boolean
    theme_id?: string
    theme: KeyValue
    traffic_spike_notifications: boolean
    traffic_spike_threshold: number
    traffic_warning: boolean
    traffic_warning_threshold_days: number
    settings: KeyValue
    theme_settings: KeyValue
    custom_domain?: string
    display_name?: string
    pinned: boolean
    subscription_active: boolean
    subscription_plus: boolean
}

export function defaultDomain(): Domain {
    return {
        id: "",
        mod_time: new Date,
        def_time: new Date,
        user_id: "",
        hostname: "",
        subdomain: "",
        identification_code: "",
        public: false,
        user_role: Role.Viewer,
        google_user_id: "",
        google_user_email: "",
        gsc_domain: "",
        group_by_title: false,
        new_owner: 0,
        disable_scripts: false,
        imported_statistics: false,
        theme_id: "",
        theme: {},
        traffic_spike_notifications: false,
        traffic_spike_threshold: 20,
        traffic_warning: false,
        traffic_warning_threshold_days: 3,
        settings: {},
        theme_settings: {},
        pinned: false,
        subscription_active: true,
        subscription_plus: true
    };
}
