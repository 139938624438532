<template>
    <Modal size="m" :title="t('title')" v-on:close="$emit('close')">
        <form v-on:submit.prevent="apply" style="width: 100%;">
            <strong>{{t("text")}}</strong>
            <ThemeOptions :defaults="themeDefaults" :disable-files="true" v-model="settings" />
            <FormSubmit :value="t('form.submit')" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useToastStore} from "@/store/ToastStore";
    import ThemeOptions from "@/components/themes/ThemeOptions.vue";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";
    import {themeDefaults} from "@/store/theme";

    export default defineComponent({
        components: {
            ThemeOptions,
            Modal,
            FormSubmit
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {success} = useToastStore();
            const {setTheme} = useThemeStore();
            const settings = ref({});

            function apply() {
                setTheme(undefined, settings.value);
                success(t("toast.success"));
                emit("close");
            }

            return {
                t,
                themeDefaults,
                settings,
                apply
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Theme Settings",
            "text": "The following settings are temporary and will apply until you reload the page. Uploading a logo or favicon is not supported in test mode.",
            "form.submit": "Apply",
            "toast.success": "Theme applied."
        },
        "de": {
            "title": "Thema-Einstellungen",
            "text": "Die folgenden Einstellungen sind temporär und gelten bis du die Seite neu lädst. Das Hochladen eines Logos oder Favicons wird im Testmodus nicht unterstützt.",
            "form.submit": "Anwenden",
            "toast.success": "Thema angewendet."
        },
        "es": {
            "title": "Configuración de tema",
            "text": "Los siguientes ajustes son temporales y se aplicarán hasta que recargues la página. La carga de un logotipo o favicon no está soportada en el modo de prueba.",
            "form.submit": "Aplicar",
            "toast.success": "Tema aplicado."
        },
        "fr": {
            "title": "Paramètres du thème",
            "text": "Les paramètres suivants sont temporaires et s'appliqueront jusqu'à ce que vous rechargiez la page. Le téléchargement d'un logo ou d'un favicon n'est pas pris en charge en mode test.",
            "form.submit": "Appliquer",
            "toast.success": "Thème appliqué."
        },
        "nl": {
            "title": "Thema-instellingen",
            "text": "De volgende instellingen zijn tijdelijk en zijn van toepassing totdat je de pagina opnieuw laadt. Het uploaden van een logo of favicon wordt niet ondersteund in de testmodus.",
            "form.submit": "Toepassen",
            "toast.success": "Thema toegepast."
        },
        "it": {
            "title": "Impostazioni del tema",
            "text": "Le seguenti impostazioni sono temporanee e verranno applicate fino a quando non ricaricherai la pagina. Il caricamento di un logo o favicon non è supportato in modalità di test.",
            "form.submit": "Applica",
            "toast.success": "Tema applicato."
        },
        "pt": {
            "title": "Configurações do tema",
            "text": "As configurações a seguir são temporárias e serão aplicadas até que você recarregue a página. O upload de um logotipo ou favicon não é suportado no modo de teste.",
            "form.submit": "Aplicar",
            "toast.success": "Tema aplicado."
        },
        "ja": {
            "title": "テーマ設定",
            "text": "以下の設定は一時的なものであり、ページを再読み込みするまで適用されます。ロゴやファビコンのアップロードはテストモードではサポートされていません。",
            "form.submit": "適用",
            "toast.success": "テーマが適用されました。"
        }
    }
</i18n>
