<template>
    <TableEntry :columns="columns" :entry="entry" :entries="entries" v-on:details="load()">
        <TableEntries :columns="tagColumns"
            :entries="data"
            :loading="loading"
            :sortable="true"
            :selectable="true"
            :showBar="true"
            v-on:filter="filterByTag" />
    </TableEntry>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref, watch} from "vue";
    import TableEntry from "@/components/panels/TableEntry.vue";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {useError} from "@/components/error";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import TableEntries from "@/components/panels/TableEntries.vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {APIError} from "@/repositories/APIError";
    import {ListEntry} from "@/model/ListEntry";
    import {useToastStore} from "@/store/ToastStore";
    import {removeField, setField, useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {Column} from "@/components/panels/Column";
    import {TagStats} from "@/model/TagStats";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            TableEntries,
            ListTabs,
            TableEntry
        },
        props: {
            columns: {type: Array as PropType<Column[]>, required: true},
            entry: {type: Object as PropType<ListEntry>, required: true},
            entries: {type: Number, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {error} = useToastStore();
            const {filter} = storeToRefs(filterStore);
            const {copy, setField: setFilterField} = filterStore;
            const {setError} = useError();
            const loading = ref(true);
            const tags = ref<TagStats[]>([]);
            const data = computed(() => {
                const data = [];

                for (let i = 0; i < tags.value.length; i++) {
                    data.push({
                        key: tags.value[i].value,
                        label: tags.value[i].value,
                        value: tags.value[i].visitors,
                        views: tags.value[i].views,
                        relative_value: tags.value[i].relative_visitors,
                        relative_views: tags.value[i].relative_views
                    });
                }

                return data;
            });
            const tagColumns = computed(() => [
                {key: "label", label: t("columns.value"), order_by: "value"},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.visitors_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_visitors"},
                {key: "views", label: t("columns.views"), transform: (v: number) => formatAndShortenNumber(v), order_by: "views"},
                {key: "relative_views", label: t("columns.views_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_views"}
            ]);

            watch(filter, () => load());

            async function load() {
                try {
                    loading.value = true;
                    const f = copy(filter.value);
                    removeField(f, "tag");
                    setField(f, "tag", props.entry.label);
                    f.limit = 0;
                    tags.value = await StatisticsRepo.tags(f) as TagStats[] || [];
                    loading.value = false;
                } catch (e) {
                    loading.value = false;
                    tags.value = [];
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            function filterByTag(entry: ListEntry) {
                if (!filter.value.fields?.find(value => value.field === "tag" && value.value === props.entry.label)) {
                    setFilterField("tag", props.entry.label);
                }

                setFilterField(`tag_${props.entry.label}`, entry.label);
            }

            return {
                tagColumns,
                loading,
                data,
                load,
                filterByTag
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "columns.value": "Value",
            "columns.visitors": "Visitors",
            "columns.visitors_relative": "Visitors %",
            "columns.views": "Views",
            "columns.views_relative": "Views %"
        },
        "de": {
            "columns.value": "Wert",
            "columns.visitors": "Besucher",
            "columns.visitors_relative": "Besucher %",
            "columns.views": "Ansichten",
            "columns.views_relative": "Ansichten %"
        },
        "es": {
            "columns.value": "Valor",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.views": "Vistas",
            "columns.views_relative": "Vistas %"
        },
        "fr": {
            "columns.value": "Valeur",
            "columns.visitors": "Visiteurs",
            "columns.visitors_relative": "Visiteurs %",
            "columns.views": "Vues",
            "columns.views_relative": "Vues %"
        },
        "nl": {
            "columns.value": "Waarde",
            "columns.visitors": "Bezoekers",
            "columns.visitors_relative": "Bezoekers %",
            "columns.views": "Weergaven",
            "columns.views_relative": "Weergaven %"
        },
        "it": {
            "columns.value": "Valore",
            "columns.visitors": "Visitatori",
            "columns.visitors_relative": "Visitatori %",
            "columns.views": "Visualizzazioni",
            "columns.views_relative": "Visualizzazioni %"
        },
        "pt": {
            "columns.value": "Valor",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.views": "Visualizações",
            "columns.views_relative": "Visualizações %"
        },
        "ja": {
            "columns.value": "値",
            "columns.visitors": "訪問者",
            "columns.visitors_relative": "訪問者 %",
            "columns.views": "ビュー",
            "columns.views_relative": "ビュー %"
        }
    }
</i18n>
