<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {Member} from "@/model/Member";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            member: {type: Object as PropType<Member>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await MemberRepo.delete(props.member.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Remove Member",
            "text": "Are you sure you would like to remove the member?",
            "form.submit": "Remove",
            "toast.success": "The member has been removed."
        },
        "de": {
            "title": "Mitglied entfernen",
            "text": "Bist du sicher, dass du das Mitglied entfernen möchtest?",
            "form.submit": "Entfernen",
            "toast.success": "Das Mitglied wurde entfernt."
        },
        "es": {
            "title": "Eliminar miembro",
            "text": "¿Estás seguro de que deseas eliminar al miembro?",
            "form.submit": "Eliminar",
            "toast.success": "El miembro ha sido eliminado."
        },
        "fr": {
            "title": "Supprimer le membre",
            "text": "Êtes-vous sûr de vouloir supprimer le membre ?",
            "form.submit": "Supprimer",
            "toast.success": "Le membre a été supprimé."
        },
        "nl": {
            "title": "Lid verwijderen",
            "text": "Weet je zeker dat je het lid wilt verwijderen?",
            "form.submit": "Verwijderen",
            "toast.success": "Het lid is verwijderd."
        },
        "it": {
            "title": "Rimuovi membro",
            "text": "Sei sicuro di voler rimuovere il membro?",
            "form.submit": "Rimuovi",
            "toast.success": "Il membro è stato rimosso."
        },
        "pt": {
            "title": "Remover membro",
            "text": "Tem certeza de que deseja remover o membro?",
            "form.submit": "Remover",
            "toast.success": "O membro foi removido."
        },
        "ja": {
            "title": "メンバーを削除",
            "text": "メンバーを削除してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "メンバーが削除されました。"
        }
    }
</i18n>
