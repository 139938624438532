<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <form v-on:submit.prevent>
                <FormSelect :label="t('form.timezone')" name="timezone" :options="timezones" v-model="timezone" v-on:change="updateTimezone" />
            </form>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {useTimezone} from "@/components/timezone";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect
        },
        setup() {
            const {t} = useI18n();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const {timezones} = useTimezone();
            const timezone = ref("");

            watchEffect(() => {
                if (domain.value.timezone) {
                    timezone.value = domain.value.timezone;
                }
            });

            async function updateTimezone() {
                if (domain.value) {
                    await DomainRepo.setTimezone(domain.value.id, timezone.value);
                    domain.value.timezone = timezone.value;
                    success(t("toast.success"));
                }
            }

            return {
                t,
                timezones,
                timezone,
                updateTimezone
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Timezone",
            "text": "The default time zone used when displaying data on the dashboard. The viewer's time zone configured in the browser will be used first.",
            "form.timezone": "Timezone",
            "toast.success": "Timezone saved."
        },
        "de": {
            "title": "Zeitzone",
            "text": "Die Standardzeitzone, die beim Anzeigen von Daten auf dem Dashboard verwendet wird. Die im Browser konfigurierte Zeitzone des Betrachters wird zuerst verwendet.",
            "form.timezone": "Zeitzone",
            "toast.success": "Zeitzone gespeichert."
        },
        "es": {
            "title": "Zona horaria",
            "text": "La zona horaria predeterminada utilizada al mostrar datos en el panel. Primero se utilizará la zona horaria del espectador configurada en el navegador.",
            "form.timezone": "Zona horaria",
            "toast.success": "Zona horaria guardada."
        },
        "fr": {
            "title": "Fuseau horaire",
            "text": "Le fuseau horaire par défaut utilisé lors de l'affichage des données sur le tableau de bord. Le fuseau horaire du spectateur configuré dans le navigateur sera utilisé en premier.",
            "form.timezone": "Fuseau horaire",
            "toast.success": "Fuseau horaire enregistré."
        },
        "nl": {
            "title": "Tijdzone",
            "text": "De standaardtijdzone die wordt gebruikt bij het weergeven van gegevens op het dashboard. De tijdzone van de kijker die in de browser is ingesteld, wordt eerst gebruikt.",
            "form.timezone": "Tijdzone",
            "toast.success": "Tijdzone opgeslagen."
        },
        "it": {
            "title": "Fuso orario",
            "text": "Il fuso orario predefinito utilizzato durante la visualizzazione dei dati sulla dashboard. Verrà utilizzato prima il fuso orario configurato nel browser dello spettatore.",
            "form.timezone": "Fuso orario",
            "toast.success": "Fuso orario salvato."
        },
        "pt": {
            "title": "Fuso horário",
            "text": "O fuso horário padrão usado ao exibir dados no painel. O fuso horário do visualizador configurado no navegador será usado primeiro.",
            "form.timezone": "Fuso horário",
            "toast.success": "Fuso horário salvo."
        },
        "ja": {
            "title": "タイムゾーン",
            "text": "ダッシュボードにデータを表示する際に使用されるデフォルトのタイムゾーン。ブラウザに設定されているビューアのタイムゾーンが最初に使用されます。",
            "form.timezone": "タイムゾーン",
            "toast.success": "タイムゾーンが保存されました。"
        }
    }
</i18n>
