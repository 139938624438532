<template>
    <div :class="{'overview-card small no-select': true, clickable: isAdmin}" v-on:click="open">
        <div class="headline">
            <h2>{{organization.name}}</h2>
        </div>
        <div class="tags">
            <div class="tag" v-if="isOwner">{{t("owner")}}</div>
            <div class="tag">
                {{organization.member}}
                <template v-if="organization.member > 1">{{t("members")}}</template>
                <template v-else>{{t("member")}}</template>
            </div>
            <div class="tag" v-if="organization.domains">
                {{organization.domains}}
                <template v-if="organization.domains > 1">{{t("websites")}}</template>
                <template v-else>{{t("website")}}</template>
            </div>
            <div class="tag" v-if="organization.themes">
                {{organization.themes}}
                <template v-if="organization.themes > 1">{{t("themes")}}</template>
                <template v-else>{{t("theme")}}</template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType} from "vue";
    import {useRouter} from "vue-router";
    import {Organization} from "@/model/Organization";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {OrganizationOverviewType} from "@/store/OrganizationStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            organization: {type: Object as PropType<Organization>, required: true},
            type: {type: String as PropType<OrganizationOverviewType>, required: true}
        },
        setup(props) {
            const router = useRouter();
            const {user} = storeToRefs(useUserStore());
            const isOwner = computed(() => props.organization.user_id === user.value.id);
            const isAdmin = computed(() => props.organization.role === "Owner" || props.organization.role === "Admin");

            function open() {
                if (isAdmin.value) {
                    router.push(`/organization/${props.organization.id}`);
                }
            }

            return {
                ...useI18n(),
                isOwner,
                isAdmin,
                open
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "owner": "Owner",
            "members": "Members",
            "member": "Member",
            "websites": "Websites",
            "website": "Website",
            "themes": "Themes",
            "theme": "Theme"
        },
        "de": {
            "owner": "Eigentümer",
            "members": "Mitglieder",
            "member": "Mitglied",
            "websites": "Webseiten",
            "website": "Webseite",
            "themes": "Themen",
            "theme": "Thema"
        },
        "es": {
            "owner": "Propietario",
            "members": "Miembros",
            "member": "Miembro",
            "websites": "Sitios web",
            "website": "Sitio web",
            "themes": "Temas",
            "theme": "Tema"
        },
        "fr": {
            "owner": "Propriétaire",
            "members": "Membres",
            "member": "Membre",
            "websites": "Sites Web",
            "website": "Site Web",
            "themes": "Thèmes",
            "theme": "Thème"
        },
        "nl": {
            "owner": "Eigenaar",
            "members": "Leden",
            "member": "Lid",
            "websites": "Websites",
            "website": "Website",
            "themes": "Thema's",
            "theme": "Thema"
        },
        "it": {
            "owner": "Proprietario",
            "members": "Membri",
            "member": "Membro",
            "websites": "Siti web",
            "website": "Sito web",
            "themes": "Temi",
            "theme": "Tema"
        },
        "pt": {
            "owner": "Proprietário",
            "members": "Membros",
            "member": "Membro",
            "websites": "Websites",
            "website": "Website",
            "themes": "Temas",
            "theme": "Tema"
        },
        "ja": {
            "owner": "所有者",
            "members": "メンバー",
            "member": "メンバー",
            "websites": "ウェブサイト",
            "website": "ウェブサイト",
            "themes": "テーマ",
            "theme": "テーマ"
        }
    }
</i18n>
