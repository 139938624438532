<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const domainStore = useDomainStore();
            const {domain} = storeToRefs(domainStore);
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await MemberRepo.deleteOwnershipTransfer(domain.value.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("close");
                    await domainStore.load();
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Cancel Ownership Transfer",
            "text": "You are about to cancel the ownership transfer for this website.",
            "form.submit": "Cancel Transfer",
            "toast.success": "The ownership transfer has been cancelled."
        },
        "de": {
            "title": "Eigentumsübertragung abbrechen",
            "text": "Du bist dabei, die Eigentumsübertragung für diese Webseite abzubrechen.",
            "form.submit": "Übertragung abbrechen",
            "toast.success": "Die Eigentumsübertragung wurde abgebrochen."
        },
        "es": {
            "title": "Cancelar transferencia de propiedad",
            "text": "Estás a punto de cancelar la transferencia de propiedad de este sitio web.",
            "form.submit": "Cancelar transferencia",
            "toast.success": "La transferencia de propiedad ha sido cancelada."
        },
        "fr": {
            "title": "Annuler le transfert de propriété",
            "text": "Vous êtes sur le point d'annuler le transfert de propriété de ce site web.",
            "form.submit": "Annuler le transfert",
            "toast.success": "Le transfert de propriété a été annulé."
        },
        "nl": {
            "title": "Eigendomsoverdracht annuleren",
            "text": "Je staat op het punt de eigendomsoverdracht voor deze website te annuleren.",
            "form.submit": "Overdracht annuleren",
            "toast.success": "De eigendomsoverdracht is geannuleerd."
        },
        "it": {
            "title": "Annulla trasferimento di proprietà",
            "text": "Stai per annullare il trasferimento di proprietà di questo sito web.",
            "form.submit": "Annulla trasferimento",
            "toast.success": "Il trasferimento di proprietà è stato annullato."
        },
        "pt": {
            "title": "Cancelar transferência de propriedade",
            "text": "Você está prestes a cancelar a transferência de propriedade deste site.",
            "form.submit": "Cancelar transferência",
            "toast.success": "A transferência de propriedade foi cancelada."
        },
        "ja": {
            "title": "所有権の移転をキャンセル",
            "text": "このウェブサイトの所有権の移転をキャンセルしようとしています。",
            "form.submit": "移転をキャンセル",
            "toast.success": "所有権の移転がキャンセルされました。"
        }
    }
</i18n>
