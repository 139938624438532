export function scrollIntoView(area: Element, element: Element, padding?: number) {
    if (!padding) {
        padding = 0;
    }

    const elementBox = element.getBoundingClientRect();
    const areaBox = area.getBoundingClientRect();
    const height = areaBox.height;
    const distanceTop = elementBox.top - areaBox.top;
    const distanceBottom = height - (elementBox.bottom - areaBox.top);

    if (distanceTop < padding) {
        area.scrollTop = area.scrollTop - (padding - distanceTop);
    } else if (distanceBottom < padding) {
        area.scrollTop = area.scrollTop + (padding - distanceBottom);
    }
}
