import {storeToRefs} from "pinia";
import {useUserStore} from "@/store/UserStore";
import {useRouter} from "vue-router";
import {watch} from "vue";

export function useUser() {
    const {loggedIn} = storeToRefs(useUserStore());
    const router = useRouter();

    watch(loggedIn, loggedIn => {
        if (loggedIn) {
            router.push("/");
        }
    });

    return {};
}
