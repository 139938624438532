<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {type: organization ? "organization" : "website"})}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.email')" name="email" type="email" :autofocus="true" v-model="email" v-on:keydown.enter="addEmail" />
            <i class="icon icon-add clickable" :title="t('form.add')" v-on:click="addEmail"></i>
        </div>
        <div>
            <div class="item" v-for="e in emails" :key="e">
                <p>
                    <strong>{{e}}</strong>
                </p>
                <i class="icon icon-close clickable" v-on:click="removeEmail(e)"></i>
            </div>
        </div>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" :disabled="emails.length === 0" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {Organization} from "@/model/Organization";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            organization: {type: Object as PropType<Organization>}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const email = ref("");
            const emails = ref<string[]>([]);

            function addEmail() {
                email.value = email.value.trim().toLowerCase();

                if (email.value) {
                    for (let i = 0; i < emails.value.length; i++) {
                        if (emails.value[i] === email.value) {
                            email.value = "";
                            return;
                        }
                    }

                    emails.value.push(email.value);
                    email.value = "";
                }
            }

            function removeEmail(email: string) {
                for (let i = 0; i < emails.value.length; i++) {
                    if (emails.value[i] === email) {
                        emails.value.splice(i, 1);
                        break;
                    }
                }
            }

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await MemberRepo.invite(props.organization ? "" : domain.value.id,
                        props.organization ? props.organization.id : "", emails.value);
                    success(t("toast.success"));
                    email.value = "";
                    emails.value = [];
                    loading.value = false;
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                email,
                emails,
                addEmail,
                removeEmail,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Invite Member",
            "text": "Invite new members to this {type} by entering their email address below. Press ENTER after each one to add them to the list of invitations.",
            "form.email": "Email Address",
            "form.add": "Add Email",
            "form.submit": "Send Invitations",
            "toast.success": "The members have been invited."
        },
        "de": {
            "title": "Mitglied einladen",
            "text": "Lade neue Mitglieder zu diesem {type} ein, indem du unten ihre E-Mail-Adresse eingibst. Drücke nach jeder Eingabe die EINGABETASTE, um sie zur Einladungsliste hinzuzufügen.",
            "form.email": "E-Mail-Adresse",
            "form.add": "E-Mail hinzufügen",
            "form.submit": "Einladungen senden",
            "toast.success": "Die Mitglieder wurden eingeladen."
        },
        "es": {
            "title": "Invitar a miembro",
            "text": "Invita a nuevos miembros a este {type} ingresando su dirección de correo electrónico a continuación. Presiona ENTER después de cada uno para agregarlos a la lista de invitaciones.",
            "form.email": "Dirección de correo electrónico",
            "form.add": "Agregar correo electrónico",
            "form.submit": "Enviar invitaciones",
            "toast.success": "Los miembros han sido invitados."
        },
        "fr": {
            "title": "Inviter un membre",
            "text": "Invitez de nouveaux membres à ce {type} en entrant leur adresse e-mail ci-dessous. Appuyez sur ENTRÉE après chaque adresse pour les ajouter à la liste des invitations.",
            "form.email": "Adresse e-mail",
            "form.add": "Ajouter un e-mail",
            "form.submit": "Envoyer les invitations",
            "toast.success": "Les membres ont été invités."
        },
        "nl": {
            "title": "Lid uitnodigen",
            "text": "Nodig nieuwe leden uit voor dit {type} door hun e-mailadres hieronder in te voeren. Druk na elk adres op ENTER om ze aan de uitnodigingslijst toe te voegen.",
            "form.email": "E-mailadres",
            "form.add": "E-mail toevoegen",
            "form.submit": "Uitnodigingen versturen",
            "toast.success": "De leden zijn uitgenodigd."
        },
        "it": {
            "title": "Invita membro",
            "text": "Invita nuovi membri a questo {type} inserendo il loro indirizzo email qui sotto. Premi INVIO dopo ciascuno per aggiungerli all'elenco delle inviti.",
            "form.email": "Indirizzo email",
            "form.add": "Aggiungi email",
            "form.submit": "Invia inviti",
            "toast.success": "I membri sono stati invitati."
        },
        "pt": {
            "title": "Convidar membro",
            "text": "Convide novos membros para este {type} inserindo seu endereço de e-mail abaixo. Pressione ENTER após cada um para adicioná-los à lista de convites.",
            "form.email": "Endereço de e-mail",
            "form.add": "Adicionar e-mail",
            "form.submit": "Enviar convites",
            "toast.success": "Os membros foram convidados."
        },
        "ja": {
            "title": "メンバーを招待",
            "text": "新しいメンバーをこの {type} に招待するには、以下にメールアドレスを入力してください。それぞれの入力後にENTERキーを押して、招待リストに追加します。",
            "form.email": "メールアドレス",
            "form.add": "メールを追加",
            "form.submit": "招待を送信",
            "toast.success": "メンバーが招待されました。"
        }
    }
</i18n>
