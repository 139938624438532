<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" :autofocus="true" v-model="name" :err="validationError('name')" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        emits: ["create", "close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);
            const name = ref("");

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await OrganizationRepo.create(name.value);
                    success(t("toast.success"));
                    name.value = "";
                    loading.value = false;
                    emit("create");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                name,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Create Organization",
            "form.name": "Name",
            "form.submit": "Create Organization",
            "toast.success": "The organization has been created."
        },
        "de": {
            "title": "Organisation erstellen",
            "form.name": "Name",
            "form.submit": "Organisation erstellen",
            "toast.success": "Die Organisation wurde erstellt."
        },
        "es": {
            "title": "Crear organización",
            "form.name": "Nombre",
            "form.submit": "Crear organización",
            "toast.success": "La organización ha sido creada."
        },
        "fr": {
            "title": "Créer une organisation",
            "form.name": "Nom",
            "form.submit": "Créer une organisation",
            "toast.success": "L'organisation a été créée."
        },
        "nl": {
            "title": "Organisatie creëren",
            "form.name": "Naam",
            "form.submit": "Organisatie creëren",
            "toast.success": "De organisatie is aangemaakt."
        },
        "it": {
            "title": "Crea organizzazione",
            "form.name": "Nome",
            "form.submit": "Crea organizzazione",
            "toast.success": "L'organizzazione è stata creata."
        },
        "pt": {
            "title": "Criar organização",
            "form.name": "Nome",
            "form.submit": "Criar organização",
            "toast.success": "A organização foi criada."
        },
        "ja": {
            "title": "組織を作成",
            "form.name": "名前",
            "form.submit": "組織を作成",
            "toast.success": "組織が作成されました。"
        }
    }
</i18n>
