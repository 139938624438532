<template>
    <div :class="{spinner: type === 'spinner', loader: type === 'loader', large: size === 'l'}" v-if="loading"></div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        props: {
            loading: {type: Boolean, required: true},
            type: {type: String, default: "spinner"},
            size: {type: String, default: "s"}
        }
    });
</script>
