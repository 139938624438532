<template>
    <Modal :title="webhook ? t('title.edit') : t('title.add')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.description')" name="description" :autofocus="true" v-model="description" :err="validationError('description')" />
            <FormInput :label="t('form.event_name')" name="event" v-model="event" :err="validationError('event')" />
            <FormInput :label="t('form.endpoint')" name="endpoint" :hint="t('form.endpoint.hint')" v-model="endpoint" :err="validationError('endpoint')" />
            <FormSubmit :value="webhook ? t('form.submit.save') : t('form.submit.create')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref, watchEffect} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {WebhookRepo} from "@/repositories/WebhookRepo";
    import {Webhook} from "@/model/Webhook";
    import webhooks from "@/components/settings/Webhooks.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        computed: {
            webhooks() {
                return webhooks
            }
        },
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            webhook: Object as PropType<Webhook>
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const description = ref("");
            const event = ref("");
            const endpoint = ref("");

            watchEffect(() => {
                if (props.webhook) {
                    description.value = props.webhook.description || "";
                    event.value = props.webhook.event;
                    endpoint.value = props.webhook.endpoint;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const webhook = await WebhookRepo.save({
                        id: props.webhook ? props.webhook.id : "",
                        domain_id: domain.value.id,
                        description: description.value,
                        event: event.value,
                        endpoint: endpoint.value
                    }) as Webhook;

                    if (!props.webhook) {
                        success(t("toast.created"));
                    } else {
                        success(t("toast.updated"));
                    }

                    description.value = "";
                    event.value = "";
                    endpoint.value = "";
                    loading.value = false;
                    emit("action", webhook);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                description,
                event,
                endpoint,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title.edit": "Edit Webhook",
            "title.add": "Add Webhook",
            "form.description": "Description",
            "form.event_name": "Event Name",
            "form.endpoint": "Endpoint",
            "form.endpoint.hint": "Example: https://example.com/v1/api/webhook",
            "form.submit.save": "Save",
            "form.submit.create": "Create Webhook",
            "toast.created": "The webhook has been created.",
            "toast.updated": "The webhook has been updated."
        },
        "de": {
            "title.edit": "Webhook bearbeiten",
            "title.add": "Webhook hinzufügen",
            "form.description": "Beschreibung",
            "form.event_name": "Eventname",
            "form.endpoint": "Endpunkt",
            "form.endpoint.hint": "Beispiel: https://example.com/v1/api/webhook",
            "form.submit.save": "Speichern",
            "form.submit.create": "Webhook erstellen",
            "toast.created": "Der Webhook wurde erstellt.",
            "toast.updated": "Der Webhook wurde aktualisiert."
        },
        "es": {
            "title.edit": "Editar Webhook",
            "title.add": "Agregar Webhook",
            "form.description": "Descripción",
            "form.event_name": "Nombre del evento",
            "form.endpoint": "Endpoint",
            "form.endpoint.hint": "Ejemplo: https://example.com/v1/api/webhook",
            "form.submit.save": "Guardar",
            "form.submit.create": "Crear Webhook",
            "toast.created": "El webhook ha sido creado.",
            "toast.updated": "El webhook ha sido actualizado."
        },
        "fr": {
            "title.edit": "Modifier le Webhook",
            "title.add": "Ajouter un Webhook",
            "form.description": "Description",
            "form.event_name": "Nom de l'événement",
            "form.endpoint": "Endpoint",
            "form.endpoint.hint": "Exemple : https://example.com/v1/api/webhook",
            "form.submit.save": "Enregistrer",
            "form.submit.create": "Créer un Webhook",
            "toast.created": "Le webhook a été créé.",
            "toast.updated": "Le webhook a été mis à jour."
        },
        "nl": {
            "title.edit": "Webhook bewerken",
            "title.add": "Webhook toevoegen",
            "form.description": "Beschrijving",
            "form.event_name": "Naam van het evenement",
            "form.endpoint": "Endpoint",
            "form.endpoint.hint": "Voorbeeld: https://example.com/v1/api/webhook",
            "form.submit.save": "Opslaan",
            "form.submit.create": "Webhook maken",
            "toast.created": "De webhook is aangemaakt.",
            "toast.updated": "De webhook is bijgewerkt."
        },
        "it": {
            "title.edit": "Modifica Webhook",
            "title.add": "Aggiungi Webhook",
            "form.description": "Descrizione",
            "form.event_name": "Nome dell'evento",
            "form.endpoint": "Endpoint",
            "form.endpoint.hint": "Esempio: https://example.com/v1/api/webhook",
            "form.submit.save": "Salva",
            "form.submit.create": "Crea Webhook",
            "toast.created": "Il webhook è stato creato.",
            "toast.updated": "Il webhook è stato aggiornato."
        },
        "pt": {
            "title.edit": "Editar Webhook",
            "title.add": "Adicionar Webhook",
            "form.description": "Descrição",
            "form.event_name": "Nome do evento",
            "form.endpoint": "Endpoint",
            "form.endpoint.hint": "Exemplo: https://example.com/v1/api/webhook",
            "form.submit.save": "Salvar",
            "form.submit.create": "Criar Webhook",
            "toast.created": "O webhook foi criado.",
            "toast.updated": "O webhook foi atualizado."
        },
        "ja": {
            "title.edit": "Webhookを編集",
            "title.add": "Webhookを追加",
            "form.description": "説明",
            "form.event_name": "イベント名",
            "form.endpoint": "エンドポイント",
            "form.endpoint.hint": "例: https://example.com/v1/api/webhook",
            "form.submit.save": "保存",
            "form.submit.create": "Webhookを作成",
            "toast.created": "Webhookが作成されました。",
            "toast.updated": "Webhookが更新されました。"
        }
    }
</i18n>
