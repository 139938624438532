<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.hostname')" name="hostname" :hint="t('form.hostname.hint')" :autofocus="true" v-model="hostname" :err="validationError('hostname')" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {DomainAlternative} from "@/model/DomainAlternative";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            domain: Object
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const hostname = ref("");
            let id: string;

            watchEffect(() => {
                if (props.domain) {
                    id = props.domain.id;
                    hostname.value = props.domain.hostname;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    let d = props.domain;

                    if (!d) {
                        d = await DomainRepo.createDomainAlternative(domain.value.id, hostname.value) as DomainAlternative;
                        success(t("toast.created"));
                    } else {
                        await DomainRepo.updateDomainAlternative(id, hostname.value);
                        success(t("toast.updated"));
                        d.hostname = hostname.value;
                    }

                    hostname.value = "";
                    loading.value = false;
                    emit("action", d);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                hostname,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Additional Domain",
            "form.hostname": "Hostname",
            "form.hostname.hint": "Use * as a wildcard.",
            "form.submit": "Save",
            "toast.created": "The additional domain has been created.",
            "toast.updated": "The additional domain has been updated."
        },
        "de": {
            "title": "Zusätzliche Domain",
            "form.hostname": "Hostname",
            "form.hostname.hint": "Verwende * als Platzhalter.",
            "form.submit": "Speichern",
            "toast.created": "Die zusätzliche Domain wurde erstellt.",
            "toast.updated": "Die zusätzliche Domain wurde aktualisiert."
        },
        "es": {
            "title": "Dominio adicional",
            "form.hostname": "Nombre de host",
            "form.hostname.hint": "Usa * como comodín.",
            "form.submit": "Guardar",
            "toast.created": "El dominio adicional ha sido creado.",
            "toast.updated": "El dominio adicional ha sido actualizado."
        },
        "fr": {
            "title": "Domaine supplémentaire",
            "form.hostname": "Nom d'hôte",
            "form.hostname.hint": "Utilisez * comme caractère générique.",
            "form.submit": "Enregistrer",
            "toast.created": "Le domaine supplémentaire a été créé.",
            "toast.updated": "Le domaine supplémentaire a été mis à jour."
        },
        "nl": {
            "title": "Extra domein",
            "form.hostname": "Hostnaam",
            "form.hostname.hint": "Gebruik * als wildcard.",
            "form.submit": "Opslaan",
            "toast.created": "Het extra domein is aangemaakt.",
            "toast.updated": "Het extra domein is bijgewerkt."
        },
        "it": {
            "title": "Dominio aggiuntivo",
            "form.hostname": "Nome host",
            "form.hostname.hint": "Usa * come carattere jolly.",
            "form.submit": "Salva",
            "toast.created": "Il dominio aggiuntivo è stato creato.",
            "toast.updated": "Il dominio aggiuntivo è stato aggiornato."
        },
        "pt": {
            "title": "Domínio adicional",
            "form.hostname": "Nome do host",
            "form.hostname.hint": "Use * como curinga.",
            "form.submit": "Salvar",
            "toast.created": "O domínio adicional foi criado.",
            "toast.updated": "O domínio adicional foi atualizado."
        },
        "ja": {
            "title": "追加ドメイン",
            "form.hostname": "ホスト名",
            "form.hostname.hint": "* をワイルドカードとして使用します。",
            "form.submit": "保存",
            "toast.created": "追加ドメインが作成されました。",
            "toast.updated": "追加ドメインが更新されました。"
        }
    }
</i18n>
