<template>
    <AddAutoJoin :organization="organization"
        :open="addAutoJoinModal"
        v-on:close="addAutoJoinModal = false"
        v-on:action="addHostnames" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <AutoJoinCard v-for="hostname in hostnames"
                        :key="hostname"
                        :organization="organization"
                        :hostname="hostname"
                        v-on:remove="removeHostname" />
                </div>
            </Expand>
            <button v-on:click="addAutoJoinModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, PropType, ref} from "vue";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {Organization} from "@/model/Organization";
    import AddAutoJoin from "@/components/modal/AddAutoJoin.vue";
    import AutoJoinCard from "@/components/cards/AutoJoinCard.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            AutoJoinCard,
            AddAutoJoin,
            Loading,
            Expand
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        setup(props) {
            const loading = ref(true);
            const addAutoJoinModal = ref(false);
            const hostnames = ref<string[]>([]);

            onMounted(() => {
                hostnames.value = props.organization.auto_join;
                loading.value = false;
            });

            function addHostnames(newHostnames: string[]) {
                addAutoJoinModal.value = false;

                for (let i = 0; i < newHostnames.length; i++) {
                    hostnames.value.push(newHostnames[i]);
                }
            }

            function removeHostname(report: string) {
                const index = hostnames.value.indexOf(report);

                if (index > -1) {
                    hostnames.value.splice(index, 1);
                }
            }

            return {
                ...useI18n(),
                loading,
                addAutoJoinModal,
                hostnames,
                addHostnames,
                removeHostname
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Auto Join",
            "text": "Automatically allow users to join this organization based on their email address on sign up.",
            "add": "Add Hostnames"
        },
        "de": {
            "title": "Automatisches Beitreten",
            "text": "Erlaube Benutzern automatisch, dieser Organisation beizutreten, basierend auf ihrer E-Mail-Adresse bei der Anmeldung.",
            "add": "Hostnamen hinzufügen"
        },
        "es": {
            "title": "Unirse automáticamente",
            "text": "Permitir que los usuarios se unan automáticamente a esta organización según su dirección de correo electrónico al registrarse.",
            "add": "Agregar nombres de host"
        },
        "fr": {
            "title": "Rejoindre automatiquement",
            "text": "Permettre automatiquement aux utilisateurs de rejoindre cette organisation en fonction de leur adresse e-mail lors de l'inscription.",
            "add": "Ajouter des noms d'hôte"
        },
        "nl": {
            "title": "Automatisch aansluiten",
            "text": "Sta gebruikers automatisch toe lid te worden van deze organisatie op basis van hun e-mailadres bij aanmelding.",
            "add": "Hostnamen toevoegen"
        },
        "it": {
            "title": "Unione automatica",
            "text": "Consenti automaticamente agli utenti di unirsi a questa organizzazione in base al loro indirizzo email al momento della registrazione.",
            "add": "Aggiungi nomi host"
        },
        "pt": {
            "title": "Adesão automática",
            "text": "Permitir automaticamente que os usuários se juntem a esta organização com base em seu endereço de e-mail no momento da inscrição.",
            "add": "Adicionar nomes de host"
        },
        "ja": {
            "title": "自動参加",
            "text": "登録時のメールアドレスに基づいて、ユーザーがこの組織に自動的に参加できるようにします。",
            "add": "ホスト名を追加"
        }
    }
</i18n>
