<template>
    <div class="switch">
        <i :class="{'icon icon-stats': true, active: type === ChartType.BAR}"
           :title="t('bar')"
           v-on:click="switchType(ChartType.BAR)" />
        <i :class="{'icon icon-graph': true, active: type === ChartType.LINE}"
           :title="t('line')"
           v-on:click="switchType(ChartType.LINE)" />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {ChartType, useChartStore} from "@/store/ChartStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        emits: ["switch"],
        setup(props, {emit}) {
            const chartStore = useChartStore();
            const {type} = storeToRefs(chartStore);

            function switchType(type: ChartType) {
                chartStore.set(type);
                emit("switch", type);
            }

            return {
                ...useI18n(),
                ChartType,
                type,
                switchType
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "bar": "Switch to Bar Chart",
            "line": "Switch to Line Chart"
        },
        "de": {
            "bar": "Auf Balkendiagramm wechseln",
            "line": "Auf Liniendiagramm wechseln"
        },
        "es": {
            "bar": "Cambiar a gráfico de barras",
            "line": "Cambiar a gráfico de líneas"
        },
        "fr": {
            "bar": "Passer au graphique à barres",
            "line": "Passer au graphique en lignes"
        },
        "nl": {
            "bar": "Overschakelen naar staafdiagram",
            "line": "Overschakelen naar lijndiagram"
        },
        "it": {
            "bar": "Passa al grafico a barre",
            "line": "Passa al grafico a linee"
        },
        "pt": {
            "bar": "Mudar para gráfico de barras",
            "line": "Mudar para gráfico de linhas"
        },
        "ja": {
            "bar": "棒グラフに切り替え",
            "line": "折れ線グラフに切り替え"
        }
    }
</i18n>
