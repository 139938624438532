import {isDarkMode} from "@/util/style";

export function getFavicon(domain: string): string {
    let fallback = "";

    if (isDarkMode()) {
        fallback = "&fallback=default-white.svg";
    }

    return `${import.meta.env.VITE_APP_FAVICON_URL}?url=${domain}&size=16${fallback}`;
}
