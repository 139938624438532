<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.description')" name="description" :autofocus="true" v-model="description" :err="validationError('description')" />
            <FormInput :label="t('form.valid_until')" name="valid_until" type="date" :hint="t('form.valid_until.hint')" v-model="validUntil" :err="validationError('valid_until')" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref, watchEffect} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {AccessLink} from "@/model/AccessLink";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";
    import {dateParam} from "@/util/format";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            link: Object as PropType<AccessLink>
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const description = ref("");
            const validUntil = ref("");
            let id: string;

            watchEffect(() => {
                if (props.link) {
                    id = props.link.id;
                    description.value = props.link.description;
                    validUntil.value = props.link.valid_until ? dateParam(new Date(props.link.valid_until)) : "";
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    let link = props.link;

                    if (!link) {
                        link = await DomainRepo.createAccessLink(domain.value.id,
                            description.value,
                            validUntil.value ? new Date(validUntil.value) : undefined) as AccessLink;
                        success(t("toast.created"));
                    } else {
                        await DomainRepo.updateAccessLink(id,
                            description.value,
                            validUntil.value ? new Date(validUntil.value) : undefined);
                        success(t("toast.updated"));
                        link.description = description.value;
                        link.valid_until = validUntil.value ? new Date(validUntil.value) : undefined;
                    }

                    description.value = "";
                    validUntil.value = "";
                    loading.value = false;
                    emit("action", link);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                description,
                validUntil,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Access Link",
            "form.description": "Description",
            "form.valid_until": "Expiration Date",
            "form.valid_until.hint": "Leave empty for no expiration.",
            "form.submit": "Save",
            "toast.created": "The access link has been created.",
            "toast.updated": "The access link has been updated."
        },
        "de": {
            "title": "Zugriffslink",
            "form.description": "Beschreibung",
            "form.valid_until": "Ablaufdatum",
            "form.valid_until.hint": "Leer lassen für kein Ablaufdatum.",
            "form.submit": "Speichern",
            "toast.created": "Der Zugriffslink wurde erstellt.",
            "toast.updated": "Der Zugriffslink wurde aktualisiert."
        },
        "es": {
            "title": "Enlace de acceso",
            "form.description": "Descripción",
            "form.valid_until": "Fecha de expiración",
            "form.valid_until.hint": "Dejar vacío para que no caduque.",
            "form.submit": "Guardar",
            "toast.created": "El enlace de acceso ha sido creado.",
            "toast.updated": "El enlace de acceso ha sido actualizado."
        },
        "fr": {
            "title": "Lien d'accès",
            "form.description": "Description",
            "form.valid_until": "Date d'expiration",
            "form.valid_until.hint": "Laisser vide pour ne pas expirer.",
            "form.submit": "Enregistrer",
            "toast.created": "Le lien d'accès a été créé.",
            "toast.updated": "Le lien d'accès a été mis à jour."
        },
        "nl": {
            "title": "Toegangslink",
            "form.description": "Beschrijving",
            "form.valid_until": "Vervaldatum",
            "form.valid_until.hint": "Laat leeg om niet te verlopen.",
            "form.submit": "Opslaan",
            "toast.created": "De toegangslink is aangemaakt.",
            "toast.updated": "De toegangslink is bijgewerkt."
        },
        "it": {
            "title": "Link di accesso",
            "form.description": "Descrizione",
            "form.valid_until": "Data di scadenza",
            "form.valid_until.hint": "Lasciare vuoto per non avere scadenze.",
            "form.submit": "Salva",
            "toast.created": "Il link di accesso è stato creato.",
            "toast.updated": "Il link di accesso è stato aggiornato."
        },
        "pt": {
            "title": "Link de acesso",
            "form.description": "Descrição",
            "form.valid_until": "Data de expiração",
            "form.valid_until.hint": "Deixar em branco para não expirar.",
            "form.submit": "Salvar",
            "toast.created": "O link de acesso foi criado.",
            "toast.updated": "O link de acesso foi atualizado."
        },
        "ja": {
            "title": "アクセスリンク",
            "form.description": "説明",
            "form.valid_until": "有効期限",
            "form.valid_until.hint": "有効期限がない場合は空のままにしておく。",
            "form.submit": "保存",
            "toast.created": "アクセスリンクが作成されました。",
            "toast.updated": "アクセスリンクが更新されました。"
        }
    }
</i18n>
