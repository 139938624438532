import {Domain} from "@/model/Domain";
import {getDate, getToday} from "@/util/date";
import dayjs from "dayjs";
import {Time} from "@/model/TimeRange";

export const defaultTimeRangeOptionIndex = 5;
export const defaultActiveVisitorsSeconds = 600;

export interface TimeRangeOption {
    index: number
    label: string
    value: string
    from(d: Date, dir: number, domain?: Domain): Date
    to(d: Date, dir: number): Date
    fromTime(): Time | undefined
    toTime(): Time | undefined
    start(domain: Domain): number
    shortcut: string[]
    scale?: string
    separator?: boolean
}

// also defined in set_default_time_range.go
export const timeRangeOptions: TimeRangeOption[] = [
    {
        index: 0,
        label: "Live",
        value: "live",
        from: (d, dir) => dir === 0 ? d : dayjs(d).add(dir, "days").toDate(),
        to: (d, dir) => dir === 0 ? d : dayjs(d).add(dir, "days").toDate(),
        fromTime: () => undefined,
        toTime: () => undefined,
        start: domain => domain.active_visitors_seconds || defaultActiveVisitorsSeconds,
        shortcut: ["L"]
    },
    {
        index: 1,
        label: "Past Hour",
        value: "hour",
        from: (d, dir) => dir === 0 ? d : dayjs(d).add(dir, "days").toDate(),
        to: (d, dir) => dir === 0 ? d : dayjs(d).add(dir, "days").toDate(),
        fromTime: () => {
            const time = dayjs().subtract(1, 'hour');
            return new Time(time.hour(), 0);
        },
        toTime: () => new Time(dayjs().hour(), 0),
        start: () => 0,
        shortcut: ["U"]
    },
    {
        index: 2,
        label: "Today",
        value: "today",
        from: (d, dir) => dir === 0 ? d : dayjs(d).add(dir, "days").toDate(),
        to: (d, dir) => dir === 0 ? d : dayjs(d).add(dir, "days").toDate(),
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["T"]
    },
    {
        index: 3,
        label: "Yesterday",
        value: "yesterday",
        from: (d, dir) => dir === 0 ? dayjs(d).subtract(1, "days").toDate() : dayjs(d).add(dir, "days").toDate(),
        to: (d, dir) => dir === 0 ? dayjs(d).subtract(1, "days").toDate() : dayjs(d).add(dir, "days").toDate(),
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["Y", "Z"],
        separator: true
    },
    {
        index: 4,
        label: "Month to Date",
        value: "month",
        from: (d, dir) => dir === 0 ? getDate(d.getFullYear(), d.getMonth(), 1) : getDate(d.getFullYear(), d.getMonth() + dir, 1),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            }

            let today = getToday();
            const lastDay = getDate(d.getFullYear(), d.getMonth(), 0);

            if (today.getTime() > lastDay.getTime()) {
                today = lastDay;
            }

            return getDate(d.getFullYear(), d.getMonth() + dir, today.getDate());
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["D"]
    },
    {
        index: 5,
        label: "Last Month",
        value: "last_month",
        from: (d, dir) => {
            if (dir <= 0) {
                return getDate(d.getMonth()-1 < 0 ? d.getFullYear()-1 : d.getFullYear(), d.getMonth()-1, 1);
            }

            return getDate(d.getFullYear(), d.getMonth()+1, 1);
        },
        to: (d, dir) => {
            const year = d.getFullYear();
            const month = d.getMonth();

            if (dir <= 0) {
                return getDate(month-1 < 0 ? year-1 : year, month-1, 0);
            }

            return getDate(year, month+1, 0);
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["M"],
        separator: true
    },
    {
        index: 6,
        label: "Past 7 Days",
        value: "7d",
        from: (d, dir) => dir === 0 ? dayjs(d).add(-6, "days").toDate() : dayjs(d).add(7*dir, "days").toDate(),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            } else if (dir < 0) {
                return dayjs(d).add(-1, "days").toDate();
            }

            return dayjs(d).add(13, "days").toDate();
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["W"]
    },
    {
        index: 7,
        label: "Past 14 Days",
        value: "14d",
        from: (d, dir) => dir === 0 ? dayjs(d).add(-13, "days").toDate() : dayjs(d).add(14*dir, "days").toDate(),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            } else if (dir < 0) {
                return dayjs(d).add(-1, "days").toDate();
            }

            return dayjs(d).add(27, "days").toDate();
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["B"]
    },
    {
        index: 8,
        label: "Past 30 Days",
        value: "30d",
        from: (d, dir) => dir === 0 ? dayjs(d).add(-29, "days").toDate() : dayjs(d).add(30*dir, "days").toDate(),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            } else if (dir < 0) {
                return dayjs(d).add(-1, "days").toDate();
            }

            return dayjs(d).add(59, "days").toDate();
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["O"],
        separator: true
    },
    {
        index: 9,
        label: "Past Quarter",
        value: "90d",
        from: (d, dir) => dir === 0 ? dayjs(d).add(-89, "days").toDate() : dayjs(d).add(90*dir, "days").toDate(),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            } else if (dir < 0) {
                return dayjs(d).add(-1, "days").toDate();
            }

            return dayjs(d).add(179, "days").toDate();
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["Q"],
        scale: "week"
    },
    {
        index: 10,
        label: "Past Half Year",
        value: "180d",
        from: (d, dir) => dir === 0 ? dayjs(d).add(-179, "days").toDate() : dayjs(d).add(180*dir, "days").toDate(),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            } else if (dir < 0) {
                return dayjs(d).add(-1, "days").toDate();
            }

            return dayjs(d).add(359, "days").toDate();
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["H"],
        scale: "month"
    },
    {
        index: 11,
        label: "Past Year",
        value: "365d",
        from: (d, dir) => dir === 0 ? dayjs(d).add(-364, "days").toDate() : dayjs(d).add(365*dir, "days").toDate(),
        to: (d, dir) => {
            if (dir === 0) {
                return d;
            } else if (dir < 0) {
                return dayjs(d).add(-1, "days").toDate();
            }

            return dayjs(d).add(365*2-1, "days").toDate();
        },
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["P"],
        scale: "month",
        separator: true
    },
    {
        index: 12,
        label: "All Time",
        value: "all",
        from: (d, dir: number, domain) => {
            const def = new Date(domain?.statistics_start ?? domain?.def_time ?? 0);
            return getDate(def.getFullYear(), def.getMonth(), def.getDate());
        },
        to: d => d,
        fromTime: () => undefined,
        toTime: () => undefined,
        start: () => 0,
        shortcut: ["A"],
        scale: "month",
        separator: true
    }
];
