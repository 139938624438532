<template>
    <div class="table">
        <table>
            <thead>
                <tr>
                    <td v-for="(column, index) in columns"
                        :key="column.key"
                        v-on:click="column.order_by ? sort(column.order_by, column.key) : undefined">
                        <h5 :class="{'no-select': true, clickable: column.order_by !== '-', left: column.align === 'left'}">
                            {{column.label}}
                            <div :class="{arrow: true, down: sortDirection === 'desc' || (!sortDirection && column.key === 'value'), up: sortDirection === 'asc'}"
                                v-if="sortColumn === column.order_by || sortColumn === column.key || (!sortDirection && column.key === 'value')" />
                            <div class="arrow dot" v-else-if="column.order_by !== '-'" />
                            <input type="text"
                                name="search"
                                :placeholder="t('placeholder')"
                                v-autofocus="mobile"
                                v-model="query"
                                v-on:click.stop.prevent
                                v-if="index === 0" />
                        </h5>
                    </td>
                </tr>
            </thead>
            <tbody>
                <component :is="entryComponent"
                    v-for="entry in data"
                    :key="entry.key ? entry.key : entry.label"
                    :columns="columns"
                    :entry="entry"
                    :entries="data.length"
                    :newTab="newTab"
                    :rawTitle="rawTitle"
                    :unknownLabel="unknownLabel"
                    :selectable="selectable && !disableFilter"
                    v-on:click="selectable && !disableFilter ? $emit('filter', entry) : undefined"
                    v-on:open="$emit('open', entry)" />
            </tbody>
        </table>
        <transition name="fade">
            <div class="no-data" v-if="!data.length && !loading">
                <p>{{t("no_data")}}</p>
            </div>
        </transition>
        <Expand>
            <div class="load-more no-select" v-if="loadMore">
                <span v-on:click="offset = data.length">{{t("load_more")}}</span>
            </div>
        </Expand>
        <transition name="fade">
            <Loading :loading="loading" type="loader" />
        </transition>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, PropType, ref, watch} from "vue";
    import {Row} from "@/components/panels/Row";
    import TableEntry from "@/components/panels/TableEntry.vue";
    import Loading from "@/components/bits/Loading.vue";
    import {debounce} from "@/util/debounce";
    import Expand from "@/components/bits/Expand.vue";
    import {entries_per_page} from "@/store/StatisticsStore";
    import {useMobile} from "@/components/mobile";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {ListEntry} from "@/model/ListEntry";
    import {Column} from "@/components/panels/Column";
    import {useI18n} from "vue-i18n";

    const sortDirections = new Map<string, string>();
    sortDirections.set("", "asc");
    sortDirections.set("asc", "desc");
    sortDirections.set("desc", "");

    interface SearchParams {
        offset: number
        column: string
        direction: string
        query: string
    }

    export default defineComponent({
        components: {
            TableEntry,
            Loading,
            Expand
        },
        props: {
            columns: {type: Array as PropType<Column[]>, required: true},
            tooltip: String,
            entries: {type: Array as PropType<ListEntry[]>, required: true},
            loading: {type: Boolean, default: true},
            loadMore: {type: Boolean, default: false},
            newTab: {type: Boolean, default: false},
            unknownLabel: {type: String, default: "Unknown"},
            rawTitle: {type: Boolean, default: false},
            selectable: {type: Boolean, default: false},
            sortable: {type: Boolean, default: false},
            entryComponent: {type: String, default: "TableEntry"},
            panel: String
        },
        emits: ["filter", "open", "search"],
        setup(props, {emit}) {
            const {filter, disableFilter} = storeToRefs(useFilterStore())
            const offset = ref(0);
            const sortColumn = ref("");
            const sortDirection = ref("");
            const query = ref("");
            const data = computed(() => {
                if (!props.sortable) {
                    return props.entries;
                }

                let data = props.entries.slice();

                if (query.value.trim() !== "") {
                    const q = query.value.trim().toLowerCase();
                    data = data.filter(entry => (entry.label as string).toLowerCase().includes(q));
                }

                if (sortColumn.value !== "" && sortDirection.value !== "") {
                    const dir = sortDirection.value === "asc" ? -1 : 1;

                    return data.sort((a, b) => {
                        const va = (a as unknown as Row)[sortColumn.value] || 0;
                        const vb = (b as unknown as Row)[sortColumn.value] || 0;

                        if (va < vb) {
                            return dir;
                        } else if (va > vb) {
                            return dir*-1;
                        }

                        return 0;
                    });
                }

                return data;
            });
            const updateSearch = debounce(() => {
                offset.value = 0;
                search();
            }, 300);

            onMounted(() => {
                try {
                    if (props.panel) {
                        const params = localStorage.getItem(`${props.panel}_table`);

                        if (params) {
                            const search = JSON.parse(params) as SearchParams;
                            sortColumn.value = search.column ?? "";
                            sortDirection.value = search.direction ?? "";
                            query.value = search.query ?? "";
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            });

            watch(filter, () => {
                offset.value = 0;
                search();
            });

            watch(query, updateSearch);
            watch(offset, search);

            watch([sortColumn, sortDirection], () => {
                offset.value = 0;
                search();
            });

            function search() {
                const search = {
                    offset: offset.value,
                    column: sortColumn.value,
                    direction: sortDirection.value,
                    query: query.value
                };

                if (props.panel) {
                    localStorage.setItem(`${props.panel}_table`, JSON.stringify(search));
                }

                emit("search", search);
            }

            function sort(orderBy: string, column: string) {
                if (orderBy === "-") {
                    return;
                }

                const key = column;
                column = props.sortable ? column : orderBy || column;

                if (column === sortColumn.value) {
                    if (key === "value") {
                        sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
                    } else {
                        sortDirection.value = sortDirections.get(sortDirection.value) as string;
                    }
                } else {
                    sortDirection.value = "asc";
                }

                if (sortDirection.value === "") {
                    sortColumn.value = "";
                } else {
                    sortColumn.value = column;
                }
            }

            return {
                ...useI18n(),
                ...useMobile(),
                entries_per_page,
                disableFilter,
                offset,
                sortColumn,
                sortDirection,
                query,
                data,
                sort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "placeholder": "Search...",
            "no_data": "No Data",
            "load_more": "Load More"
        },
        "de": {
            "placeholder": "Suchen...",
            "no_data": "Keine Daten",
            "load_more": "Mehr laden"
        },
        "es": {
            "placeholder": "Buscar...",
            "no_data": "Sin datos",
            "load_more": "Cargar más"
        },
        "fr": {
            "placeholder": "Rechercher...",
            "no_data": "Pas de données",
            "load_more": "Charger plus"
        },
        "nl": {
            "placeholder": "Zoeken...",
            "no_data": "Geen gegevens",
            "load_more": "Meer laden"
        },
        "it": {
            "placeholder": "Cerca...",
            "no_data": "Nessun dato",
            "load_more": "Carica di più"
        },
        "pt": {
            "placeholder": "Buscar...",
            "no_data": "Sem dados",
            "load_more": "Carregar mais"
        },
        "ja": {
            "placeholder": "検索...",
            "no_data": "データなし",
            "load_more": "もっと読み込む"
        }
    }
</i18n>
