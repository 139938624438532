<template>
    <section class="row">
        <h3>{{t("title")}}</h3>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.email')" name="email" type="email" v-model="email" :err="validationError('email')" />
            <FormInput :label="t('form.password')" name="password" type="password" v-model="password" :err="validationError('current_password')" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSubmit,
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);
            const {resetError, setError, validationError} = useError();
            const {success, error} = useToastStore();
            const loading = ref(false);
            const email = ref(user.value.email);
            const password = ref("");

            watch(user, value => {
                email.value = value.email;
                password.value = "";
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await UserRepo.changeEmail(email.value, password.value);
                    await userStore.load();
                    loading.value = false;
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                validationError,
                loading,
                email,
                password,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Email Address",
            "form.email": "Email Address",
            "form.password": "Password",
            "form.submit": "Update Email Address",
            "toast.success": "The Email address has been updated. Please check your inbox to confirm it."
        },
        "de": {
            "title": "E-Mail-Adresse",
            "form.email": "E-Mail-Adresse",
            "form.password": "Passwort",
            "form.submit": "E-Mail-Adresse aktualisieren",
            "toast.success": "Die E-Mail-Adresse wurde aktualisiert. Bitte überprüfe dein Postfach, um sie zu bestätigen."
        },
        "es": {
            "title": "Dirección de correo electrónico",
            "form.email": "Dirección de correo electrónico",
            "form.password": "Contraseña",
            "form.submit": "Actualizar dirección de correo electrónico",
            "toast.success": "La dirección de correo electrónico ha sido actualizada. Por favor, revisa tu bandeja de entrada para confirmarla."
        },
        "fr": {
            "title": "Adresse e-mail",
            "form.email": "Adresse e-mail",
            "form.password": "Mot de passe",
            "form.submit": "Mettre à jour l'adresse e-mail",
            "toast.success": "L'adresse e-mail a été mise à jour. Veuillez vérifier votre boîte de réception pour la confirmer."
        },
        "nl": {
            "title": "E-mailadres",
            "form.email": "E-mailadres",
            "form.password": "Wachtwoord",
            "form.submit": "E-mailadres bijwerken",
            "toast.success": "Het e-mailadres is bijgewerkt. Controleer je inbox om het te bevestigen."
        },
        "it": {
            "title": "Indirizzo email",
            "form.email": "Indirizzo email",
            "form.password": "Password",
            "form.submit": "Aggiorna indirizzo email",
            "toast.success": "L'indirizzo email è stato aggiornato. Controlla la tua casella di posta per confermarlo."
        },
        "pt": {
            "title": "Endereço de email",
            "form.email": "Endereço de email",
            "form.password": "Palavra-passe",
            "form.submit": "Atualizar endereço de email",
            "toast.success": "O endereço de email foi atualizado. Verifique sua caixa de entrada para confirmá-lo."
        },
        "ja": {
            "title": "メールアドレス",
            "form.email": "メールアドレス",
            "form.password": "パスワード",
            "form.submit": "メールアドレスを更新",
            "toast.success": "メールアドレスが更新されました。確認するために受信トレイをチェックしてください。"
        }
    }
</i18n>
