<template>
    <div class="headline no-select">
        <div class="tabs">
            <h2 :class="{active: tab === tabs[activeTab]}"
                v-for="(tab, index) in tabs"
                :key="tab.title"
                v-on:click="switchTab(index)">
                {{tab.title}}
            </h2>
        </div>
        <div class="icons">
            <Tooltip :content="tabs[activeTab].tooltip" v-if="tabs[activeTab].tooltip" />
            <slot name="icons"></slot>
            <i class="icon icon-enlarge clickable"
                :title="t('details')"
                v-on:click="enlarge"
                v-if="tabs[activeTab].showEnlarge" />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref, watch} from "vue";
    import Tooltip from "@/components/bits/Tooltip.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Tooltip
        },
        props: {
            tabs: {type: Array as PropType<Array<{title: string, tooltip?: string, showEnlarge?: boolean}>>, required: true}
        },
        emits: ["enlarge", "open"],
        setup(props, {emit}) {
            const activeTab = ref(0);

            watch(() => props.tabs, () => {
                if (activeTab.value > props.tabs.length-1) {
                    activeTab.value = 0;
                }
            });

            function switchTab(index: number) {
                activeTab.value = index;
                emit("open", index);
            }

            function enlarge() {
                emit("enlarge", activeTab.value);
            }

            return {
                ...useI18n(),
                activeTab,
                switchTab,
                enlarge
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "details": "View Details"
        },
        "de": {
            "details": "Details anzeigen"
        },
        "es": {
            "details": "Ver detalles"
        },
        "fr": {
            "details": "Voir les détails"
        },
        "nl": {
            "details": "Bekijk details"
        },
        "it": {
            "details": "Visualizza dettagli"
        },
        "pt": {
            "details": "Ver detalhes"
        },
        "ja": {
            "details": "詳細を見る"
        }
    }
</i18n>
