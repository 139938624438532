<template>
    <section class="form">
        <template v-if="!success">
            <h1>{{t("title")}}</h1>
            <p>{{t("text")}}</p>
            <form v-on:submit.stop.prevent="reset">
                <FormInput :label="t('form.email')" name="email" autocomplete="email" v-model="email" :err="validationError('email')" />
                <FormSubmit :value="t('form.submit')" :loading="loading" />
            </form>
            <router-link to="/login">{{t("back")}}</router-link>
        </template>
        <template v-else>
            <p>{{t("success_0", {email})}}.</p>
            <p>{{t("success_1")}}</p>
            <router-link to="/login" class="button secondary">{{t("back")}}</router-link>
        </template>
        <AppFooterSmall />
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {useUser} from "@/views/user";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {UserRepo} from "@/repositories/UserRepo";
    import AppFooterSmall from "@/components/bits/AppFooterSmall.vue";
    import {useI18n} from "vue-i18n";
    import {APIError} from "@/repositories/APIError";

    export default defineComponent({
        components: {
            AppFooterSmall,
            FormSubmit,
            FormInput
        },
        setup() {
            useUser();
            const {validationError, setError, resetError} = useError();
            const email = ref("");
            const loading = ref(false);
            const success = ref(false);

            async function reset() {
                try {
                    loading.value = true;
                    resetError();
                    await UserRepo.resetPassword(email.value);
                    success.value = true;
                    loading.value = false;
                } catch (e) {
                    success.value = false;
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                ...useI18n(),
                validationError,
                email,
                loading,
                success,
                reset
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Reset Password",
            "text": "You will receive a new password via email.",
            "form.email": "Email Address",
            "form.submit": "Send",
            "success_0": "Your password has been reset and a new password was send to {email}.",
            "success_1": "In case we could not find your account or you don't receive an email, please try again or contact us.",
            "back": "Back to Login"
        },
        "de": {
            "title": "Passwort zurücksetzen",
            "text": "Du wirst ein neues Passwort per E-Mail erhalten.",
            "form.email": "E-Mail-Adresse",
            "form.submit": "Senden",
            "success_0": "Dein Passwort wurde zurückgesetzt und ein neues Passwort wurde an {email} gesendet.",
            "success_1": "Falls wir dein Konto nicht finden konnten oder du keine E-Mail erhältst, versuche es bitte erneut oder kontaktiere uns.",
            "back": "Zurück zum Login"
        },
        "es": {
            "title": "Restablecer contraseña",
            "text": "Recibirás una nueva contraseña por correo electrónico.",
            "form.email": "Dirección de correo electrónico",
            "form.submit": "Enviar",
            "success_0": "Tu contraseña ha sido restablecida y se envió una nueva contraseña a {email}.",
            "success_1": "Si no pudimos encontrar tu cuenta o no recibes un correo electrónico, intenta nuevamente o contáctanos.",
            "back": "Volver al inicio de sesión"
        },
        "fr": {
            "title": "Réinitialiser le mot de passe",
            "text": "Vous recevrez un nouveau mot de passe par e-mail.",
            "form.email": "Adresse e-mail",
            "form.submit": "Envoyer",
            "success_0": "Votre mot de passe a été réinitialisé et un nouveau mot de passe a été envoyé à {email}.",
            "success_1": "Si nous ne trouvons pas votre compte ou si vous ne recevez pas d'e-mail, veuillez réessayer ou nous contacter.",
            "back": "Retour à la connexion"
        },
        "nl": {
            "title": "Wachtwoord resetten",
            "text": "Je ontvangt een nieuw wachtwoord via e-mail.",
            "form.email": "E-mailadres",
            "form.submit": "Verzenden",
            "success_0": "Je wachtwoord is gereset en een nieuw wachtwoord is verzonden naar {email}.",
            "success_1": "Als we je account niet konden vinden of je geen e-mail ontvangt, probeer het dan opnieuw of neem contact met ons op.",
            "back": "Terug naar inloggen"
        },
        "it": {
            "title": "Reimposta password",
            "text": "Riceverai una nuova password via email.",
            "form.email": "Indirizzo e-mail",
            "form.submit": "Invia",
            "success_0": "La tua password è stata reimpostata e una nuova password è stata inviata a {email}.",
            "success_1": "Se non riusciamo a trovare il tuo account o non ricevi un'e-mail, riprova o contattaci.",
            "back": "Torna al login"
        },
        "pt": {
            "title": "Redefinir senha",
            "text": "Você receberá uma nova senha por e-mail.",
            "form.email": "Endereço de e-mail",
            "form.submit": "Enviar",
            "success_0": "Sua senha foi redefinida e uma nova senha foi enviada para {email}.",
            "success_1": "Caso não possamos encontrar sua conta ou você não receba um e-mail, tente novamente ou entre em contato conosco.",
            "back": "Voltar ao login"
        },
        "ja": {
            "title": "パスワードのリセット",
            "text": "新しいパスワードがメールで届きます。",
            "form.email": "メールアドレス",
            "form.submit": "送信",
            "success_0": "パスワードがリセットされ、新しいパスワードが{email}に送信されました。",
            "success_1": "アカウントが見つからなかった場合やメールを受信しない場合は、再試行するかお問い合わせください。",
            "back": "ログインに戻る"
        }
    }
</i18n>
