import {APIError} from "@/repositories/APIError";
import {Repository} from "@/repositories/Repository";
import {TrafficSpike} from "@/model/TrafficSpike";

export const TrafficSpikeRepo = new class extends Repository {
    async list(domain_id: string): Promise<TrafficSpike[] | APIError> {
        return this.performGet<TrafficSpike[]>("/domain/traffic/report", {domain_id}, []);
    }

    async add(domain_id: string, emails: string[], link_to: string): Promise<TrafficSpike[] | APIError> {
        return this.performPost<TrafficSpike[]>("/domain/traffic/report", {domain_id, emails, link_to});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/domain/traffic/report", {id});
    }
}
