<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>
            <i18n-t keypath="text_0">
                <strong>{{webhook.event}}</strong>
                <strong>{{webhook.endpoint}}</strong>
                <template v-if="webhook.description"> {{t("text_1", {description: webhook.description})}}</template>
            </i18n-t>
        </p>
        <p>{{t("text_2")}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {Webhook} from "@/model/Webhook";
    import {WebhookRepo} from "@/repositories/WebhookRepo";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            webhook: {type: Object as PropType<Webhook>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await WebhookRepo.delete(props.webhook.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text_0": "You are about to delete the webhook for the event {0} with the endpoint {1}{2}.",
            "text_1": " and the description \"{description}\"",
            "text_2": "Are you sure you would like to delete the webhook?",
            "form.submit": "Delete",
            "toast.success": "The webhook has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text_0": "Du bist dabei, den Webhook für das Events {0} mit dem Endpunkt {1}{2} zu löschen.",
            "text_1": " und der Beschreibung \"{description}\"",
            "text_2": "Bist du sicher, dass du den Webhook löschen möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Der Webhook wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text_0": "Estás a punto de eliminar el webhook para el evento {0} con el endpoint {1}{2}.",
            "text_1": " y la descripción \"{description}\"",
            "text_2": "¿Estás seguro de que deseas eliminar el webhook?",
            "form.submit": "Eliminar",
            "toast.success": "El webhook ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text_0": "Vous êtes sur le point de supprimer le webhook pour l'événement {0} avec l'endpoint {1}{2}.",
            "text_1": " et la description \"{description}\"",
            "text_2": "Êtes-vous sûr de vouloir supprimer le webhook ?",
            "form.submit": "Supprimer",
            "toast.success": "Le webhook a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text_0": "Je staat op het punt de webhook voor het evenement {0} met de endpoint {1}{2} te verwijderen.",
            "text_1": " en de beschrijving \"{description}\"",
            "text_2": "Weet je zeker dat je de webhook wilt verwijderen?",
            "form.submit": "Verwijderen",
            "toast.success": "De webhook is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text_0": "Stai per eliminare il webhook per l'evento {0} con l'endpoint {1}{2}.",
            "text_1": " e la descrizione \"{description}\"",
            "text_2": "Sei sicuro di voler eliminare il webhook?",
            "form.submit": "Elimina",
            "toast.success": "Il webhook è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text_0": "Você está prestes a excluir o webhook para o evento {0} com o endpoint {1}{2}.",
            "text_1": " e a descrição \"{description}\"",
            "text_2": "Tem certeza de que deseja excluir o webhook?",
            "form.submit": "Excluir",
            "toast.success": "O webhook foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text_0": "イベント {0} のエンドポイント {1}{2} のWebhookを削除しようとしています。",
            "text_1": " と説明 \"{description}\"",
            "text_2": "Webhookを削除してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "Webhookが削除されました。"
        }
    }
</i18n>
