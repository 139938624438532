import {defineStore, storeToRefs} from "pinia";
import {onMounted, ref} from "vue";
import {ImportJob} from "@/model/ImportJob";
import {ImportRepo} from "@/repositories/ImportRepo";
import {useDomainStore} from "@/store/DomainStore";

export const useImportStore = defineStore("import", () => {
    const {domain} = storeToRefs(useDomainStore());
    const importJob = ref<ImportJob | undefined>();

    onMounted(async () => {
        importJob.value = await ImportRepo.get(domain.value.id) as ImportJob;
    });

    function set(job: ImportJob) {
        importJob.value = job;
    }

    async function cancel() {
        await ImportRepo.cancelImport(domain.value.id);
        importJob.value = undefined;
    }

    return {
        importJob,
        set,
        cancel
    };
});
