<template>
    <fieldset :class="{'no-select': true, 'toggle': toggle}">
        <input type="checkbox"
            :id="name"
            :name="name"
            :checked="modelValue"
            v-on:click="update"
            :disabled="disabled"
            ref="input" />
        <label :for="name" v-html="label" />
        <small v-if="hint" v-html="hint"></small>
        <small class="error" v-if="err">{{err}}</small>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";

    export default defineComponent({
        props: {
            label: String,
            name: {type: String, required: true},
            disabled: {type: Boolean, default: false},
            autofocus: {type: Boolean, default: false},
            toggle: {type: Boolean, default: false},
            modelValue: Boolean,
            hint: String,
            err: String
        },
        emits: ["update:modelValue"],
        setup(props, {emit}) {
            const input = ref(document.createElement("input"));

            onMounted(() => {
                if (props.autofocus) {
                    input.value.focus();
                }
            });

            function update(e: MouseEvent) {
                if (e && e.target) {
                    emit("update:modelValue", (e.target as HTMLInputElement).checked);
                }
            }

            return {
                input,
                update
            };
        }
    });
</script>
