// List of all API error messages and translations.
import {KeyValue} from "@/model/KeyValue";

export const errors: Translation = {
    en: {
        error: "An error occurred.",
        "Please select a file.": "Please select a file.",
        "The billing cycle is invalid.": "The billing cycle is invalid.",
        "The currency is invalid.": "The currency is invalid.",
        "The subscription plan is invalid.": "The subscription plan is invalid.",
        "Error creating session.": "Error creating session.",
        "The value is invalid.": "The value is invalid.",
        "User not found.": "User not found.",
        "The client type is invalid.": "The client type is invalid.",
        "The description is too long.": "The description is too long.",
        "The description is too short.": "The description is too short.",
        "One or more of the scopes are invalid.": "One or more of the scopes are invalid.",
        "The client type for the scopes is invalid.": "The client type for the scopes is invalid.",
        "Client not found.": "Client not found.",
        "Credentials invalid.": "Credentials invalid.",
        "Refresh token invalid.": "Refresh token invalid.",
        "The display name is too long (the maximum is 50 characters).": "The display name is too long (the maximum is 50 characters.",
        "The subdomain must be between 3 and 100 characters long.": "The subdomain must be between 3 and 100 characters long.",
        "The subdomain must end with a character or digit.": "The subdomain must end with a character or digit.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.",
        "The subdomain is not allowed.": "The subdomain is not allowed.",
        "The subdomain is in use.": "The subdomain is in use.",
        "You're not an administrator of this organization.": "You're not an administrator of this organization.",
        "You need an active subscription or free trial to add websites.": "You need an active subscription or free trial to add websites.",
        "The hostname is in use.": "The hostname is in use.",
        "The subdomain must begin with a character or digit.": "The subdomain must begin with a character or digit.",
        "You don't have permission to create dashboards.": "You don't have permission to create dashboards.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "You have reached the maximum number of domains. To create more, please contact our support.",
        "Organization not found.": "Organization not found.",
        "The domain is already in use.": "The domain is already in use.",
        "Custom domain not found.": "Custom domain not found.",
        "Access link not found.": "Access link not found.",
        "Domain not found.": "Domain not found.",
        "Theme not found.": "Theme not found.",
        "The time must be a value between 1 and 900 seconds.": "The time must be a value between 1 and 900 seconds.",
        "The custom domain is in use.": "The custom domain is in use.",
        "The TXT record for the domain could not be found.": "The TXT record for the domain could not be found.",
        "Error parsing metadata.": "Error parsing metadata.",
        "The timezone is invalid.": "The timezone is invalid.",
        "The threshold must be 0 or greater.": "The threshold must be 0 or greater.",
        "Invalid filter type.": "Invalid filter type.",
        "The description must be set.": "The description must be set.",
        "The filter is too long.": "The filter is too long.",
        "The filter must be set.": "The filter must be set.",
        "The filter already exists.": "The filter already exists.",
        "The IP or CIDR is invalid.": "The IP or CIDR is invalid.",
        "The hostname is invalid.": "The hostname is invalid.",
        "The country code is invalid.": "The country code is invalid.",
        "Traffic filter not found.": "Traffic filter not found.",
        "Goal not found.": "Goal not found.",
        "The name is too long.": "The name is too long.",
        "The name is too short.": "The name is too short.",
        "The name is in use.": "The name is in use.",
        "The pattern is too long.": "The pattern is too long.",
        "The pattern is in use.": "The pattern is in use.",
        "The sample is too long.": "The sample is too long.",
        "The visitor goal is invalid (must be 0 or above).": "The visitor goal is invalid (must be 0 or above.",
        "The conversion rate is invalid (must be between 0 and 100).": "The conversion rate is invalid (must be between 0 and 100.",
        "The event name must be set if no path pattern or regular expression is present.": "The event name must be set if no path pattern or regular expression is present.",
        "The event name is too long (must be between 0 and 60).": "The event name is too long (must be between 0 and 60.",
        "The event meta key must be set.": "The event meta key must be set.",
        "The event meta key is too long (must be between 0 and 60).": "The event meta key is too long (must be between 0 and 60.",
        "The event meta value must be set.": "The event meta value must be set.",
        "The event meta value is too long (must be between 0 and 100).": "The event meta value is too long (must be between 0 and 100.",
        "The custom metric key is too long (must be between 0 and 60).": "The custom metric key is too long (must be between 0 and 60.",
        "The custom metric type is invalid (must be float or integer).": "The custom metric type is invalid (must be float or integer.",
        "The pattern, regular expression, or event name must be set.": "The pattern, regular expression, or event name must be set.",
        "The total custom metric target is invalid (must be greater than 0).": "The total custom metric target is invalid (must be greater than 0.",
        "The average custom metric target is invalid (must be greater than 0).": "The average custom metric target is invalid (must be greater than 0.",
        "Custom metrics require a Pirsch Plus subscription.": "Custom metrics require a Pirsch Plus subscription.",
        "Import job not found.": "Import job not found.",
        "Error connecting user account.": "Error connecting user account.",
        "An import job for this domain already exists.": "An import job for this domain already exists.",
        "The import domain must be selected.": "The import domain must be selected.",
        "The import job is already running.": "The import job is already running.",
        "The website is not connected to Google Analytics.": "The website is not connected to Google Analytics.",
        "The website is not connected to Search Console.": "The website is not connected to Search Console.",
        "The import for this type is not supported.": "The import for this type is not supported.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "The file is too large. Please select a zip archive with a maximum size of 100 MB.",
        "The file must be of type zip.": "The file must be of type zip.",
        "Error loading zip file.": "Error loading zip file.",
        "Resource is locked.": "Resource is locked.",
        "Locks can only be set or removed through the web interface.": "Locks can only be set or removed through the web interface.",
        "The current owner for the domain could not be found.": "The current owner for the domain could not be found.",
        "Invitation not found.": "Invitation not found.",
        "Error sending invitation email.": "Error sending invitation email.",
        "Member not found.": "Member not found.",
        "You cannot remove yourself.": "You cannot remove yourself.",
        "You cannot remove an administrator. This must be done by the domain owner.": "You cannot remove an administrator. This must be done by the domain owner.",
        "The email address is invalid.": "The email address is invalid.",
        "You cannot transfer the ownership to yourself.": "You cannot transfer the ownership to yourself.",
        "Role invalid.": "Role invalid.",
        "You cannot update your own role.": "You cannot update your own role.",
        "You cannot update an administrator. This must be done by the domain owner.": "You cannot update an administrator. This must be done by the domain owner.",
        "The name can not be empty.": "The name can not be empty.",
        "The step name can not be empty.": "The step name can not be empty.",
        "The regular expression is invalid.": "The regular expression is invalid.",
        "The name is already in use.": "The name is already in use.",
        "You're not the owner of this organization.": "You're not the owner of this organization.",
        "This action requires a Pirsch Plus subscription.": "This action requires a Pirsch Plus subscription.",
        "The interval is invalid.": "The interval is invalid.",
        "The option is invalid.": "The option is invalid.",
        "Email report not found.": "Email report not found.",
        "Traffic spike report not found.": "Traffic spike report not found.",
        "There is another email report for this interval.": "There is another email report for this interval.",
        "The type must either be page view, event, session, or extended.": "The type must either be page view, event, session, or extended.",
        "The time range is invalid.": "The time range is invalid.",
        "The kind of data to delete is invalid.": "The kind of data to delete is invalid.",
        "The event name and meta key must be set.": "The event name and meta key must be set.",
        "Error reading keywords from Search Console.": "Error reading keywords from Search Console.",
        "A single tag must be set.": "A single tag must be set.",
        "A tag must be set.": "A tag must be set.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "The image is too large. Please select an image with a maximum size of 100 KB.",
        "The image must be of type svg, jpg, png, or gif.": "The image must be of type svg, jpg, png, or gif.",
        "The image must be of type ico, jpg, png, or gif.": "The image must be of type ico, jpg, png, or gif.",
        "The filename is invalid.": "The filename is invalid.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Missing required request parameters. The URL, IP address, and User-Agent must be set.",
        "The scripts are disabled for this domain. Use the API instead.": "The scripts are disabled for this domain. Use the API instead.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "Your request has been denied. You might have hit the request limit or don't have an active subscription.",
        "The request was denied due to a filtering policy.": "The request was denied due to a filtering policy.",
        "The account is not marked for deletion.": "The account is not marked for deletion.",
        "An error occurred while reversing the subscription cancellation.": "An error occurred while reversing the subscription cancellation.",
        "The confirmation code is invalid.": "The confirmation code is invalid.",
        "Please set a password.": "Please set a password.",
        "The passwords do not match.": "The passwords do not match.",
        "Please accept the terms of service and privacy policy to continue.": "Please accept the terms of service and privacy policy to continue.",
        "The registration code is invalid.": "The registration code is invalid.",
        "The password is incorrect.": "The password is incorrect.",
        "An error occurred while cancelling the subscription.": "An error occurred while cancelling the subscription.",
        "An account for this email address and password was not found.": "An account for this email address and password was not found.",
        "Too many login attempts. Please wait five minutes and try again.": "Too many login attempts. Please wait five minutes and try again.",
        "The registration email has been resend already.": "The registration email has been resend already.",
        "The language code is invalid (use ISO-6391).": "The language code is invalid (use ISO-6391.",
        "Please enter your full name.": "Please enter your full name.",
        "The name is too long (max. 100 characters).": "The name is too long (max. 100 characters.",
        "The email address is in use.": "The email address is in use.",
        "Error sending registration email.": "Error sending registration email.",
        "Your current password is incorrect.": "Your current password is incorrect.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "The image is too large. Please select an image with a maximum size of 1 MB.",
        "The image must be of type jpg, png, or gif.": "The image must be of type jpg, png, or gif.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.",
        "The pattern must start with a slash.": "The pattern must start with a slash.",
        "View not found.": "View not found.",
        "Access to view denied.": "Access to view denied.",
        "The name must be between 1 and 100 characters long.": "The name must be between 1 and 100 characters long.",
        "The comparison period is invalid.": "The comparison period is invalid.",
        "Webhook not found.": "Webhook not found.",
        "The description is too long. The maximum is 100 characters.": "The description is too long. The maximum is 100 characters.",
        "The event name is too long. The maximum is 200 characters.": "The event name is too long. The maximum is 200 characters.",
        "The event name must be set.": "The event name must be set.",
        "The endpoint is too long. The maximum is 2000 characters.": "The endpoint is too long. The maximum is 2000 characters.",
        "The endpoint must be set.": "The endpoint must be set.",
        "The endpoint is not a valid URL.": "The endpoint is not a valid URL.",
        "The endpoint must use HTTPS.": "The endpoint must use HTTPS.",
        "A webhook with this name and endpoint already exists.": "A webhook with this name and endpoint already exists.",
        "Access key invalid.": "Access key invalid.",
        "The pattern is invalid.": "The pattern is invalid.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.",
        "Too many tags.": "Too many tags.",
        "A tag key is too long. Please ensure they stay within the character limit.": "A tag key is too long. Please ensure they stay within the character limit.",
        "A tag value is too long. Please ensure they stay within the character limit.": "A tag value is too long. Please ensure they stay within the character limit.",
        "Funnel not found.": "Funnel not found.",
        "There must be at least two steps in the funnel.": "There must be at least two steps in the funnel.",
        "There must be no more than eight steps in the funnel.": "There must be no more than eight steps in the funnel.",
        "The event name must be set if meta data is used.": "The event name must be set if meta data is used.",
        "The filter can not be empty.": "The filter can not be empty.",
        "The date must be after today.": "The date must be after today."
    },
    "de": {
        "error": "Ein Fehler ist aufgetreten.",
        "Please select a file.": "Bitte wähle eine Datei aus.",
        "The billing cycle is invalid.": "Der Abrechnungszeitraum ist ungültig.",
        "The currency is invalid.": "Die Währung ist ungültig.",
        "The subscription plan is invalid.": "Der Abonnementplan ist ungültig.",
        "Error creating session.": "Fehler beim Erstellen der Sitzung.",
        "The value is invalid.": "Der Wert ist ungültig.",
        "User not found.": "Benutzer nicht gefunden.",
        "The client type is invalid.": "Der Client-Typ ist ungültig.",
        "The description is too long.": "Die Beschreibung ist zu lang.",
        "The description is too short.": "Die Beschreibung ist zu kurz.",
        "One or more of the scopes are invalid.": "Eine oder mehrere der Bereiche sind ungültig.",
        "The client type for the scopes is invalid.": "Der Client-Typ für die Bereiche ist ungültig.",
        "Client not found.": "Client nicht gefunden.",
        "Credentials invalid.": "Anmeldeinformationen ungültig.",
        "Refresh token invalid.": "Aktualisierungstoken ungültig.",
        "The display name is too long (the maximum is 50 characters).": "Der Anzeigename ist zu lang (das Maximum beträgt 50 Zeichen).",
        "The subdomain must be between 3 and 100 characters long.": "Das Subdomain muss zwischen 3 und 100 Zeichen lang sein.",
        "The subdomain must end with a character or digit.": "Das Subdomain muss mit einem Zeichen oder einer Ziffer enden.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "Das Subdomain enthält ungültige Zeichen. Bitte stelle sicher, dass du nur Zeichen, Buchstaben und Bindestriche verwendest.",
        "The subdomain is not allowed.": "Das Subdomain ist nicht erlaubt.",
        "The subdomain is in use.": "Das Subdomain ist in Gebrauch.",
        "You're not an administrator of this organization.": "Du bist kein Administrator dieser Organisation.",
        "You need an active subscription or free trial to add websites.": "Du benötigst ein aktives Abonnement oder eine kostenlose Testversion, um Websites hinzuzufügen.",
        "The hostname is in use.": "Der Hostname ist in Gebrauch.",
        "The subdomain must begin with a character or digit.": "Das Subdomain muss mit einem Zeichen oder einer Ziffer beginnen.",
        "You don't have permission to create dashboards.": "Du hast keine Berechtigung, Dashboards zu erstellen.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "Du hast die maximale Anzahl an Domains erreicht. Um mehr zu erstellen, kontaktiere bitte unseren Support.",
        "Organization not found.": "Organisation nicht gefunden.",
        "The domain is already in use.": "Die Domain ist bereits in Gebrauch.",
        "Custom domain not found.": "Benutzerdefinierte Domain nicht gefunden.",
        "Access link not found.": "Zugriffslink nicht gefunden.",
        "Domain not found.": "Domain nicht gefunden.",
        "Theme not found.": "Thema nicht gefunden.",
        "The time must be a value between 1 and 900 seconds.": "Die Zeit muss ein Wert zwischen 1 und 900 Sekunden sein.",
        "The custom domain is in use.": "Die benutzerdefinierte Domain ist in Gebrauch.",
        "The TXT record for the domain could not be found.": "Der TXT-Eintrag für die Domain konnte nicht gefunden werden.",
        "Error parsing metadata.": "Fehler beim Parsen der Metadaten.",
        "The timezone is invalid.": "Die Zeitzone ist ungültig.",
        "The threshold must be 0 or greater.": "Der Schwellenwert muss 0 oder größer sein.",
        "Invalid filter type.": "Ungültiger Filtertyp.",
        "The description must be set.": "Die Beschreibung muss festgelegt werden.",
        "The filter is too long.": "Der Filter ist zu lang.",
        "The filter must be set.": "Der Filter muss festgelegt werden.",
        "The filter already exists.": "Der Filter existiert bereits.",
        "The IP or CIDR is invalid.": "Die IP oder CIDR ist ungültig.",
        "The hostname is invalid.": "Der Hostname ist ungültig.",
        "The country code is invalid.": "Der Ländercode ist ungültig.",
        "Traffic filter not found.": "Traffic-Filter nicht gefunden.",
        "Goal not found.": "Ziel nicht gefunden.",
        "The name is too long.": "Der Name ist zu lang.",
        "The name is too short.": "Der Name ist zu kurz.",
        "The name is in use.": "Der Name ist in Gebrauch.",
        "The pattern is too long.": "Das Muster ist zu lang.",
        "The pattern is in use.": "Das Muster ist in Gebrauch.",
        "The sample is too long.": "Das Beispiel ist zu lang.",
        "The visitor goal is invalid (must be 0 or above).": "Das Besucherziel ist ungültig (muss 0 oder höher sein).",
        "The conversion rate is invalid (must be between 0 and 100).": "Die Konversionsrate ist ungültig (muss zwischen 0 und 100 liegen).",
        "The event name must be set if no path pattern or regular expression is present.": "Der Eventname muss festgelegt werden, wenn kein Pfadmuster oder regulärer Ausdruck vorhanden ist.",
        "The event name is too long (must be between 0 and 60).": "Der Eventname ist zu lang (muss zwischen 0 und 60 liegen).",
        "The event meta key must be set.": "Der Event-Meta-Schlüssel muss festgelegt werden.",
        "The event meta key is too long (must be between 0 and 60).": "Der Event-Meta-Schlüssel ist zu lang (muss zwischen 0 und 60 liegen).",
        "The event meta value must be set.": "Der Event-Meta-Wert muss festgelegt werden.",
        "The event meta value is too long (must be between 0 and 100).": "Der Event-Meta-Wert ist zu lang (muss zwischen 0 und 100 liegen).",
        "The custom metric key is too long (must be between 0 and 60).": "Der benutzerdefinierte Metrikschlüssel ist zu lang (muss zwischen 0 und 60 liegen).",
        "The custom metric type is invalid (must be float or integer).": "Der benutzerdefinierte Metriktyp ist ungültig (muss float oder integer sein).",
        "The pattern, regular expression, or event name must be set.": "Das Muster, der reguläre Ausdruck oder der Eventname muss festgelegt werden.",
        "The total custom metric target is invalid (must be greater than 0).": "Das gesamte benutzerdefinierte Metrikziel ist ungültig (muss größer als 0 sein).",
        "The average custom metric target is invalid (must be greater than 0).": "Das durchschnittliche benutzerdefinierte Metrikziel ist ungültig (muss größer als 0 sein).",
        "Custom metrics require a Pirsch Plus subscription.": "Benutzerdefinierte Metriken erfordern ein Pirsch Plus-Abonnement.",
        "Import job not found.": "Importauftrag nicht gefunden.",
        "Error connecting user account.": "Fehler beim Verbinden des Benutzerkontos.",
        "An import job for this domain already exists.": "Ein Importauftrag für diese Domain existiert bereits.",
        "The import domain must be selected.": "Die Import-Domain muss ausgewählt werden.",
        "The import job is already running.": "Der Importauftrag läuft bereits.",
        "The website is not connected to Google Analytics.": "Die Website ist nicht mit Google Analytics verbunden.",
        "The website is not connected to Search Console.": "Die Website ist nicht mit der Search Console verbunden.",
        "The import for this type is not supported.": "Der Import für diesen Typ wird nicht unterstützt.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "Die Datei ist zu groß. Bitte wähle ein Zip-Archiv mit einer maximalen Größe von 100 MB.",
        "The file must be of type zip.": "Die Datei muss vom Typ Zip sein.",
        "Error loading zip file.": "Fehler beim Laden der Zip-Datei.",
        "Resource is locked.": "Ressource ist gesperrt.",
        "Locks can only be set or removed through the web interface.": "Sperren können nur über die Web-Oberfläche gesetzt oder entfernt werden.",
        "The current owner for the domain could not be found.": "Der aktuelle Inhaber der Domain konnte nicht gefunden werden.",
        "Invitation not found.": "Einladung nicht gefunden.",
        "Error sending invitation email.": "Fehler beim Senden der Einladung.",
        "Member not found.": "Mitglied nicht gefunden.",
        "You cannot remove yourself.": "Du kannst dich nicht selbst entfernen.",
        "You cannot remove an administrator. This must be done by the domain owner.": "Du kannst keinen Administrator entfernen. Dies muss vom Domaininhaber durchgeführt werden.",
        "The email address is invalid.": "Die E-Mail-Adresse ist ungültig.",
        "You cannot transfer the ownership to yourself.": "Du kannst das Eigentum nicht auf dich selbst übertragen.",
        "Role invalid.": "Rolle ungültig.",
        "You cannot update your own role.": "Du kannst deine eigene Rolle nicht aktualisieren.",
        "You cannot update an administrator. This must be done by the domain owner.": "Du kannst keinen Administrator aktualisieren. Dies muss vom Domaininhaber durchgeführt werden.",
        "The name can not be empty.": "Der Name darf nicht leer sein.",
        "The step name can not be empty.": "Der Schrittname darf nicht leer sein.",
        "The regular expression is invalid.": "Der reguläre Ausdruck ist ungültig.",
        "The name is already in use.": "Der Name ist bereits in Gebrauch.",
        "You're not the owner of this organization.": "Du bist nicht der Inhaber dieser Organisation.",
        "This action requires a Pirsch Plus subscription.": "Diese Aktion erfordert ein Pirsch Plus-Abonnement.",
        "The interval is invalid.": "Das Intervall ist ungültig.",
        "The option is invalid.": "Die Option ist ungültig.",
        "Email report not found.": "E-Mail-Bericht nicht gefunden.",
        "Traffic spike report not found.": "Traffic-Spike-Benachrichtigung nicht gefunden.",
        "There is another email report for this interval.": "Es gibt einen weiteren E-Mail-Bericht für dieses Intervall.",
        "The type must either be page view, event, session, or extended.": "Der Typ muss entweder Seitenaufruf, Event, Sitzung oder erweitert sein.",
        "The time range is invalid.": "Der Zeitbereich ist ungültig.",
        "The kind of data to delete is invalid.": "Die Art der zu löschenden Daten ist ungültig.",
        "The event name and meta key must be set.": "Der Eventname und der Meta-Schlüssel müssen festgelegt werden.",
        "Error reading keywords from Search Console.": "Fehler beim Lesen der Keywords von der Search Console.",
        "A single tag must be set.": "Ein einzelnes Tag muss festgelegt werden.",
        "A tag must be set.": "Ein Tag muss festgelegt werden.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "Das Bild ist zu groß. Bitte wähle ein Bild mit einer maximalen Größe von 100 KB.",
        "The image must be of type svg, jpg, png, or gif.": "Das Bild muss vom Typ svg, jpg, png oder gif sein.",
        "The image must be of type ico, jpg, png, or gif.": "Das Bild muss vom Typ ico, jpg, png oder gif sein.",
        "The filename is invalid.": "Der Dateiname ist ungültig.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Erforderliche Anforderungsparameter fehlen. Stelle sicher, dass du die URL, IP und den Namen für das Event sendest, um gezählt zu werden.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Erforderliche Anforderungsparameter fehlen. Stelle sicher, dass du die URL und IP für den Treffer sendest, um gezählt zu werden.",
        "The scripts are disabled for this domain. Use the API instead.": "Die Skripte sind für diese Domain deaktiviert. Verwende stattdessen die API.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "Deine Anfrage wurde abgelehnt. Möglicherweise hast du das Anfrage-Limit erreicht oder hast kein aktives Abonnement.",
        "The request was denied due to a filtering policy.": "Die Anfrage wurde aufgrund einer Filterrichtlinie abgelehnt.",
        "The account is not marked for deletion.": "Das Konto ist nicht zur Löschung markiert.",
        "An error occurred while reversing the subscription cancellation.": "Bei der Stornierung der Kündigung des Abonnements ist ein Fehler aufgetreten.",
        "The confirmation code is invalid.": "Der Bestätigungscode ist ungültig.",
        "Please set a password.": "Bitte setze ein Passwort.",
        "The passwords do not match.": "Die Passwörter stimmen nicht überein.",
        "Please accept the terms of service and privacy policy to continue.": "Bitte akzeptiere die Nutzungsbedingungen und Datenschutzrichtlinie, um fortzufahren.",
        "The registration code is invalid.": "Der Registrierungscode ist ungültig.",
        "The password is incorrect.": "Das Passwort ist falsch.",
        "An error occurred while cancelling the subscription.": "Bei der Kündigung des Abonnements ist ein Fehler aufgetreten.",
        "An account for this email address and password was not found.": "Ein Konto für diese E-Mail-Adresse und dieses Passwort wurde nicht gefunden.",
        "Too many login attempts. Please wait five minutes and try again.": "Zu viele Anmeldeversuche. Bitte warte fünf Minuten und versuche es erneut.",
        "The registration email has been resend already.": "Die Registrierungsmail wurde bereits erneut gesendet.",
        "The language code is invalid (use ISO-6391).": "Der Sprachcode ist ungültig (verwende ISO-6391).",
        "Please enter your full name.": "Bitte gib deinen vollständigen Namen ein.",
        "The name is too long (max. 100 characters).": "Der Name ist zu lang (max. 100 Zeichen).",
        "The email address is in use.": "Die E-Mail-Adresse ist in Gebrauch.",
        "Error sending registration email.": "Fehler beim Senden der Registrierungsmail.",
        "Your current password is incorrect.": "Dein aktuelles Passwort ist falsch.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "Das Bild ist zu groß. Bitte wähle ein Bild mit einer maximalen Größe von 1 MB.",
        "The image must be of type jpg, png, or gif.": "Das Bild muss vom Typ jpg, png oder gif sein.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "Das Muster ist ungültig, es darf nur aus Buchstaben, Ziffern, Schrägstrichen, Bindestrichen und Sternchen bestehen.",
        "The pattern must start with a slash.": "Das Muster muss mit einem Schrägstrich beginnen.",
        "View not found.": "Ansicht nicht gefunden.",
        "Access to view denied.": "Zugriff auf Ansicht verweigert.",
        "The name must be between 1 and 100 characters long.": "Der Name muss zwischen 1 und 100 Zeichen lang sein.",
        "The comparison period is invalid.": "Der Vergleichszeitraum ist ungültig.",
        "Webhook not found.": "Webhook nicht gefunden.",
        "The description is too long. The maximum is 100 characters.": "Die Beschreibung ist zu lang. Das Maximum sind 100 Zeichen.",
        "The event name is too long. The maximum is 200 characters.": "Der Eventname ist zu lang. Das Maximum sind 200 Zeichen.",
        "The event name must be set.": "Der Eventname muss festgelegt werden.",
        "The endpoint is too long. The maximum is 2000 characters.": "Der Endpunkt ist zu lang. Das Maximum sind 2000 Zeichen.",
        "The endpoint must be set.": "Der Endpunkt muss festgelegt werden.",
        "The endpoint is not a valid URL.": "Der Endpunkt ist keine gültige URL.",
        "The endpoint must use HTTPS.": "Der Endpunkt muss HTTPS verwenden.",
        "A webhook with this name and endpoint already exists.": "Ein Webhook mit diesem Namen und Endpunkt existiert bereits.",
        "Access key invalid.": "Zugriffsschlüssel ungültig.",
        "The pattern is invalid.": "Das Muster ist ungültig.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "Identifikationscode für die Domain nicht gefunden. Stelle sicher, dass du Anfragen von der konfigurierten Domain oder Subdomain sendest. Identifikationscodes und Domains werden bis zu 5 Minuten zwischengespeichert.",
        "Too many tags.": "Zu viele Tags.",
        "A tag key is too long. Please ensure they stay within the character limit.": "Ein Tag-Schlüssel ist zu lang. Bitte stelle sicher, dass sie innerhalb des Zeichenlimits bleiben.",
        "A tag value is too long. Please ensure they stay within the character limit.": "Ein Tag-Wert ist zu lang. Bitte stelle sicher, dass sie innerhalb des Zeichenlimits bleiben.",
        "Funnel not found.": "Funnel nicht gefunden.",
        "There must be at least two steps in the funnel.": "Es müssen mindestens zwei Stufen im Funnel vorhanden sein.",
        "There must be no more than eight steps in the funnel.": "Der Funnel darf nicht mehr als acht Schritte enthalten.",
        "The event name must be set if meta data is used.": "Der Event Name muss gesetzt werden, wenn Metadaten verwendet werden.",
        "The filter can not be empty.": "Der Filter darf nicht leer sein.",
        "The date must be after today.": "Das Datum muss nach dem heutigen Tag liegen."
    },
    "es": {
        "error": "Ocurrió un error.",
        "Please select a file.": "Por favor, selecciona un archivo.",
        "The billing cycle is invalid.": "El ciclo de facturación es inválido.",
        "The currency is invalid.": "La moneda es inválida.",
        "The subscription plan is invalid.": "El plan de suscripción es inválido.",
        "Error creating session.": "Error al crear la sesión.",
        "The value is invalid.": "El valor es inválido.",
        "User not found.": "Usuario no encontrado.",
        "The client type is invalid.": "El tipo de cliente es inválido.",
        "The description is too long.": "La descripción es demasiado larga.",
        "The description is too short.": "La descripción es demasiado corta.",
        "One or more of the scopes are invalid.": "Uno o más de los alcances son inválidos.",
        "The client type for the scopes is invalid.": "El tipo de cliente para los alcances es inválido.",
        "Client not found.": "Cliente no encontrado.",
        "Credentials invalid.": "Credenciales inválidas.",
        "Refresh token invalid.": "Token de actualización inválido.",
        "The display name is too long (the maximum is 50 characters).": "El nombre para mostrar es demasiado largo (el máximo es 50 caracteres).",
        "The subdomain must be between 3 and 100 characters long.": "El subdominio debe tener entre 3 y 100 caracteres de longitud.",
        "The subdomain must end with a character or digit.": "El subdominio debe terminar con un carácter o dígito.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "El subdominio contiene caracteres inválidos. Asegúrate de usar solo caracteres, letras y guiones.",
        "The subdomain is not allowed.": "El subdominio no está permitido.",
        "The subdomain is in use.": "El subdominio está en uso.",
        "You're not an administrator of this organization.": "No eres administrador de esta organización.",
        "You need an active subscription or free trial to add websites.": "Necesitas una suscripción activa o una prueba gratuita para añadir sitios web.",
        "The hostname is in use.": "El nombre de host está en uso.",
        "The subdomain must begin with a character or digit.": "El subdominio debe comenzar con un carácter o dígito.",
        "You don't have permission to create dashboards.": "No tienes permiso para crear tableros.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "Has alcanzado el número máximo de dominios. Para crear más, por favor contacta a nuestro soporte.",
        "Organization not found.": "Organización no encontrada.",
        "The domain is already in use.": "El dominio ya está en uso.",
        "Custom domain not found.": "Dominio personalizado no encontrado.",
        "Access link not found.": "Enlace de acceso no encontrado.",
        "Domain not found.": "Dominio no encontrado.",
        "Theme not found.": "Tema no encontrado.",
        "The time must be a value between 1 and 900 seconds.": "El tiempo debe ser un valor entre 1 y 900 segundos.",
        "The custom domain is in use.": "El dominio personalizado está en uso.",
        "The TXT record for the domain could not be found.": "No se pudo encontrar el registro TXT para el dominio.",
        "Error parsing metadata.": "Error al analizar los metadatos.",
        "The timezone is invalid.": "La zona horaria es inválida.",
        "The threshold must be 0 or greater.": "El umbral debe ser 0 o mayor.",
        "Invalid filter type.": "Tipo de filtro inválido.",
        "The description must be set.": "La descripción debe establecerse.",
        "The filter is too long.": "El filtro es demasiado largo.",
        "The filter must be set.": "El filtro debe establecerse.",
        "The filter already exists.": "El filtro ya existe.",
        "The IP or CIDR is invalid.": "La IP o CIDR es inválida.",
        "The hostname is invalid.": "El nombre de host es inválido.",
        "The country code is invalid.": "El código de país es inválido.",
        "Traffic filter not found.": "Filtro de tráfico no encontrado.",
        "Goal not found.": "Meta no encontrada.",
        "The name is too long.": "El nombre es demasiado largo.",
        "The name is too short.": "El nombre es demasiado corto.",
        "The name is in use.": "El nombre está en uso.",
        "The pattern is too long.": "El patrón es demasiado largo.",
        "The pattern is in use.": "El patrón está en uso.",
        "The sample is too long.": "La muestra es demasiado larga.",
        "The visitor goal is invalid (must be 0 or above).": "La meta de visitantes es inválida (debe ser 0 o mayor).",
        "The conversion rate is invalid (must be between 0 and 100).": "La tasa de conversión es inválida (debe estar entre 0 y 100).",
        "The event name must be set if no path pattern or regular expression is present.": "El nombre del evento debe establecerse si no hay un patrón de ruta o una expresión regular.",
        "The event name is too long (must be between 0 and 60).": "El nombre del evento es demasiado largo (debe estar entre 0 y 60).",
        "The event meta key must be set.": "La clave meta del evento debe establecerse.",
        "The event meta key is too long (must be between 0 and 60).": "La clave meta del evento es demasiado larga (debe estar entre 0 y 60).",
        "The event meta value must be set.": "El valor meta del evento debe establecerse.",
        "The event meta value is too long (must be between 0 and 100).": "El valor meta del evento es demasiado largo (debe estar entre 0 y 100).",
        "The custom metric key is too long (must be between 0 and 60).": "La clave de métrica personalizada es demasiado larga (debe estar entre 0 y 60).",
        "The custom metric type is invalid (must be float or integer).": "El tipo de métrica personalizada es inválido (debe ser flotante o entero).",
        "The pattern, regular expression, or event name must be set.": "El patrón, la expresión regular o el nombre del evento deben establecerse.",
        "The total custom metric target is invalid (must be greater than 0).": "La meta total de métrica personalizada es inválida (debe ser mayor que 0).",
        "The average custom metric target is invalid (must be greater than 0).": "La meta promedio de métrica personalizada es inválida (debe ser mayor que 0).",
        "Custom metrics require a Pirsch Plus subscription.": "Las métricas personalizadas requieren una suscripción a Pirsch Plus.",
        "Import job not found.": "Trabajo de importación no encontrado.",
        "Error connecting user account.": "Error al conectar la cuenta del usuario.",
        "An import job for this domain already exists.": "Ya existe un trabajo de importación para este dominio.",
        "The import domain must be selected.": "El dominio de importación debe seleccionarse.",
        "The import job is already running.": "El trabajo de importación ya está en curso.",
        "The website is not connected to Google Analytics.": "El sitio web no está conectado a Google Analytics.",
        "The website is not connected to Search Console.": "El sitio web no está conectado a Search Console.",
        "The import for this type is not supported.": "La importación para este tipo no es compatible.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "El archivo es demasiado grande. Por favor, selecciona un archivo zip con un tamaño máximo de 100 MB.",
        "The file must be of type zip.": "El archivo debe ser de tipo zip.",
        "Error loading zip file.": "Error al cargar el archivo zip.",
        "Resource is locked.": "El recurso está bloqueado.",
        "Locks can only be set or removed through the web interface.": "Los bloqueos solo se pueden establecer o eliminar a través de la interfaz web.",
        "The current owner for the domain could not be found.": "No se pudo encontrar al propietario actual del dominio.",
        "Invitation not found.": "Invitación no encontrada.",
        "Error sending invitation email.": "Error al enviar el correo de invitación.",
        "Member not found.": "Miembro no encontrado.",
        "You cannot remove yourself.": "No puedes eliminarte a ti mismo.",
        "You cannot remove an administrator. This must be done by the domain owner.": "No puedes eliminar a un administrador. Esto debe hacerlo el propietario del dominio.",
        "The email address is invalid.": "La dirección de correo electrónico es inválida.",
        "You cannot transfer the ownership to yourself.": "No puedes transferir la propiedad a ti mismo.",
        "Role invalid.": "Rol inválido.",
        "You cannot update your own role.": "No puedes actualizar tu propio rol.",
        "You cannot update an administrator. This must be done by the domain owner.": "No puedes actualizar a un administrador. Esto debe hacerlo el propietario del dominio.",
        "The name can not be empty.": "El nombre no debe estar vacío.",
        "The step name can not be empty.": "El nombre del paso no puede estar vacío.",
        "The regular expression is invalid.": "La expresión regular no es válida.",
        "The name is already in use.": "El nombre ya está en uso.",
        "You're not the owner of this organization.": "No eres el propietario de esta organización.",
        "This action requires a Pirsch Plus subscription.": "Esta acción requiere una suscripción a Pirsch Plus.",
        "The interval is invalid.": "El intervalo es inválido.",
        "The option is invalid.": "La opción es inválida.",
        "Email report not found.": "Informe de correo electrónico no encontrado.",
        "Traffic spike report not found.": "Informe de picos de tráfico no encontrado.",
        "There is another email report for this interval.": "Hay otro informe de correo electrónico para este intervalo.",
        "The type must either be page view, event, session, or extended.": "El tipo debe ser vista de página, evento, sesión o extendido.",
        "The time range is invalid.": "El rango de tiempo es inválido.",
        "The kind of data to delete is invalid.": "El tipo de datos a eliminar es inválido.",
        "The event name and meta key must be set.": "El nombre del evento y la clave meta deben establecerse.",
        "Error reading keywords from Search Console.": "Error al leer las palabras clave desde Search Console.",
        "A single tag must be set.": "Se debe establecer una sola etiqueta.",
        "A tag must be set.": "Se debe establecer una etiqueta.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "La imagen es demasiado grande. Por favor, selecciona una imagen con un tamaño máximo de 100 KB.",
        "The image must be of type svg, jpg, png, or gif.": "La imagen debe ser de tipo svg, jpg, png o gif.",
        "The image must be of type ico, jpg, png, or gif.": "La imagen debe ser de tipo ico, jpg, png o gif.",
        "The filename is invalid.": "El nombre de archivo es inválido.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Faltan los parámetros de solicitud requeridos. Asegúrate de enviar la URL, IP y nombre para que el evento cuente.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Faltan parámetros de solicitud requeridos. La URL, la dirección IP y el User-Agent deben estar configurados.",
        "The scripts are disabled for this domain. Use the API instead.": "Los scripts están deshabilitados para este dominio. Usa la API en su lugar.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "Tu solicitud ha sido denegada. Podrías haber alcanzado el límite de solicitudes o no tener una suscripción activa.",
        "The request was denied due to a filtering policy.": "La solicitud fue denegada debido a una política de filtrado.",
        "The account is not marked for deletion.": "La cuenta no está marcada para eliminación.",
        "An error occurred while reversing the subscription cancellation.": "Ocurrió un error al revertir la cancelación de la suscripción.",
        "The confirmation code is invalid.": "El código de confirmación es inválido.",
        "Please set a password.": "Por favor, establece una contraseña.",
        "The passwords do not match.": "Las contraseñas no coinciden.",
        "Please accept the terms of service and privacy policy to continue.": "Por favor, acepta los términos de servicio y la política de privacidad para continuar.",
        "The registration code is invalid.": "El código de registro es inválido.",
        "The password is incorrect.": "La contraseña es incorrecta.",
        "An error occurred while cancelling the subscription.": "Ocurrió un error al cancelar la suscripción.",
        "An account for this email address and password was not found.": "No se encontró una cuenta para esta dirección de correo electrónico y contraseña.",
        "Too many login attempts. Please wait five minutes and try again.": "Demasiados intentos de inicio de sesión. Por favor, espera cinco minutos y vuelve a intentarlo.",
        "The registration email has been resend already.": "El correo electrónico de registro ya ha sido reenviado.",
        "The language code is invalid (use ISO-6391).": "El código de idioma es inválido (usa ISO-6391).",
        "Please enter your full name.": "Por favor, introduce tu nombre completo.",
        "The name is too long (max. 100 characters).": "El nombre es demasiado largo (máx. 100 caracteres).",
        "The email address is in use.": "La dirección de correo electrónico está en uso.",
        "Error sending registration email.": "Error al enviar el correo de registro.",
        "Your current password is incorrect.": "Tu contraseña actual es incorrecta.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "La imagen es demasiado grande. Por favor, selecciona una imagen con un tamaño máximo de 1 MB.",
        "The image must be of type jpg, png, or gif.": "La imagen debe ser de tipo jpg, png o gif.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "El patrón es inválido, solo debe consistir en letras, dígitos, barras, guiones y asteriscos.",
        "The pattern must start with a slash.": "El patrón debe comenzar con una barra.",
        "View not found.": "Vista no encontrada.",
        "Access to view denied.": "Acceso a la vista denegado.",
        "The name must be between 1 and 100 characters long.": "El nombre debe tener entre 1 y 100 caracteres de longitud.",
        "The comparison period is invalid.": "El período de comparación es inválido.",
        "Webhook not found.": "Webhook no encontrado.",
        "The description is too long. The maximum is 100 characters.": "La descripción es demasiado larga. El máximo es 100 caracteres.",
        "The event name is too long. The maximum is 200 characters.": "El nombre del evento es demasiado largo. El máximo es 200 caracteres.",
        "The event name must be set.": "El nombre del evento debe establecerse.",
        "The endpoint is too long. The maximum is 2000 characters.": "El punto final es demasiado largo. El máximo es 2000 caracteres.",
        "The endpoint must be set.": "El punto final debe establecerse.",
        "The endpoint is not a valid URL.": "El punto final no es una URL válida.",
        "The endpoint must use HTTPS.": "El punto final debe usar HTTPS.",
        "A webhook with this name and endpoint already exists.": "Ya existe un webhook con este nombre y punto final.",
        "Access key invalid.": "Clave de acceso inválida.",
        "The pattern is invalid.": "El patrón es inválido.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "No se encontró el código de identificación para el dominio. Asegúrate de enviar solicitudes desde el dominio o subdominio configurado. Los códigos de identificación y dominios se almacenan en caché durante hasta 5 minutos.",
        "Too many tags.": "Demasiadas etiquetas.",
        "A tag key is too long. Please ensure they stay within the character limit.": "Una clave de etiqueta es demasiado larga. Asegúrate de que se mantengan dentro del límite de caracteres.",
        "A tag value is too long. Please ensure they stay within the character limit.": "Un valor de etiqueta es demasiado largo. Asegúrate de que se mantengan dentro del límite de caracteres.",
        "Funnel not found.": "Embudo no encontrado.",
        "There must be at least two steps in the funnel.": "Debe haber al menos dos pasos en el embudo.",
        "There must be no more than eight steps in the funnel.": "No debe haber más de ocho pasos en el embudo.",
        "The event name must be set if meta data is used.": "El nombre del evento debe estar definido si se utilizan metadatos.",
        "The filter can not be empty.": "El filtro no debe estar vacío.",
        "The date must be after today.": "La fecha debe ser posterior a hoy."
    },
    "fr": {
        "error": "Une erreur est survenue.",
        "Please select a file.": "Veuillez sélectionner un fichier.",
        "The billing cycle is invalid.": "Le cycle de facturation est invalide.",
        "The currency is invalid.": "La devise est invalide.",
        "The subscription plan is invalid.": "Le plan d'abonnement est invalide.",
        "Error creating session.": "Erreur lors de la création de la session.",
        "The value is invalid.": "La valeur est invalide.",
        "User not found.": "Utilisateur non trouvé.",
        "The client type is invalid.": "Le type de client est invalide.",
        "The description is too long.": "La description est trop longue.",
        "The description is too short.": "La description est trop courte.",
        "One or more of the scopes are invalid.": "Un ou plusieurs des champs sont invalides.",
        "The client type for the scopes is invalid.": "Le type de client pour les champs est invalide.",
        "Client not found.": "Client non trouvé.",
        "Credentials invalid.": "Identifiants invalides.",
        "Refresh token invalid.": "Jeton de rafraîchissement invalide.",
        "The display name is too long (the maximum is 50 characters).": "Le nom d'affichage est trop long (le maximum est de 50 caractères).",
        "The subdomain must be between 3 and 100 characters long.": "Le sous-domaine doit comporter entre 3 et 100 caractères.",
        "The subdomain must end with a character or digit.": "Le sous-domaine doit se terminer par un caractère ou un chiffre.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "Le sous-domaine contient des caractères invalides. Veuillez vous assurer d'utiliser uniquement des caractères, des lettres et des tirets.",
        "The subdomain is not allowed.": "Le sous-domaine n'est pas autorisé.",
        "The subdomain is in use.": "Le sous-domaine est déjà utilisé.",
        "You're not an administrator of this organization.": "Vous n'êtes pas administrateur de cette organisation.",
        "You need an active subscription or free trial to add websites.": "Vous avez besoin d'un abonnement actif ou d'une période d'essai gratuite pour ajouter des sites Web.",
        "The hostname is in use.": "Le nom d'hôte est déjà utilisé.",
        "The subdomain must begin with a character or digit.": "Le sous-domaine doit commencer par un caractère ou un chiffre.",
        "You don't have permission to create dashboards.": "Vous n'avez pas l'autorisation de créer des tableaux de bord.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "Vous avez atteint le nombre maximum de domaines. Pour en créer davantage, veuillez contacter notre support.",
        "Organization not found.": "Organisation non trouvée.",
        "The domain is already in use.": "Le domaine est déjà utilisé.",
        "Custom domain not found.": "Domaine personnalisé non trouvé.",
        "Access link not found.": "Lien d'accès non trouvé.",
        "Domain not found.": "Domaine non trouvé.",
        "Theme not found.": "Thème non trouvé.",
        "The time must be a value between 1 and 900 seconds.": "Le temps doit être une valeur comprise entre 1 et 900 secondes.",
        "The custom domain is in use.": "Le domaine personnalisé est déjà utilisé.",
        "The TXT record for the domain could not be found.": "L'enregistrement TXT pour le domaine n'a pas pu être trouvé.",
        "Error parsing metadata.": "Erreur lors de l'analyse des métadonnées.",
        "The timezone is invalid.": "Le fuseau horaire est invalide.",
        "The threshold must be 0 or greater.": "Le seuil doit être de 0 ou supérieur.",
        "Invalid filter type.": "Type de filtre invalide.",
        "The description must be set.": "La description doit être définie.",
        "The filter is too long.": "Le filtre est trop long.",
        "The filter must be set.": "Le filtre doit être défini.",
        "The filter already exists.": "Le filtre existe déjà.",
        "The IP or CIDR is invalid.": "L'IP ou le CIDR est invalide.",
        "The hostname is invalid.": "Le nom d'hôte est invalide.",
        "The country code is invalid.": "Le code pays est invalide.",
        "Traffic filter not found.": "Filtre de trafic non trouvé.",
        "Goal not found.": "Objectif non trouvé.",
        "The name is too long.": "Le nom est trop long.",
        "The name is too short.": "Le nom est trop court.",
        "The name is in use.": "Le nom est déjà utilisé.",
        "The pattern is too long.": "Le modèle est trop long.",
        "The pattern is in use.": "Le modèle est déjà utilisé.",
        "The sample is too long.": "L'échantillon est trop long.",
        "The visitor goal is invalid (must be 0 or above).": "L'objectif de visiteurs est invalide (doit être de 0 ou plus).",
        "The conversion rate is invalid (must be between 0 and 100).": "Le taux de conversion est invalide (doit être compris entre 0 et 100).",
        "The event name must be set if no path pattern or regular expression is present.": "Le nom de l'événement doit être défini s'il n'y a pas de modèle de chemin ou d'expression régulière.",
        "The event name is too long (must be between 0 and 60).": "Le nom de l'événement est trop long (doit être compris entre 0 et 60).",
        "The event meta key must be set.": "La clé méta de l'événement doit être définie.",
        "The event meta key is too long (must be between 0 and 60).": "La clé méta de l'événement est trop longue (doit être comprise entre 0 et 60).",
        "The event meta value must be set.": "La valeur méta de l'événement doit être définie.",
        "The event meta value is too long (must be between 0 and 100).": "La valeur méta de l'événement est trop longue (doit être comprise entre 0 et 100).",
        "The custom metric key is too long (must be between 0 and 60).": "La clé de métrique personnalisée est trop longue (doit être comprise entre 0 et 60).",
        "The custom metric type is invalid (must be float or integer).": "Le type de métrique personnalisée est invalide (doit être flottant ou entier).",
        "The pattern, regular expression, or event name must be set.": "Le modèle, l'expression régulière ou le nom de l'événement doit être défini.",
        "The total custom metric target is invalid (must be greater than 0).": "L'objectif total de métrique personnalisée est invalide (doit être supérieur à 0).",
        "The average custom metric target is invalid (must be greater than 0).": "L'objectif moyen de métrique personnalisée est invalide (doit être supérieur à 0).",
        "Custom metrics require a Pirsch Plus subscription.": "Les métriques personnalisées nécessitent un abonnement à Pirsch Plus.",
        "Import job not found.": "Travail d'importation non trouvé.",
        "Error connecting user account.": "Erreur de connexion du compte utilisateur.",
        "An import job for this domain already exists.": "Un travail d'importation pour ce domaine existe déjà.",
        "The import domain must be selected.": "Le domaine d'importation doit être sélectionné.",
        "The import job is already running.": "Le travail d'importation est déjà en cours.",
        "The website is not connected to Google Analytics.": "Le site Web n'est pas connecté à Google Analytics.",
        "The website is not connected to Search Console.": "Le site Web n'est pas connecté à la Search Console.",
        "The import for this type is not supported.": "L'importation pour ce type n'est pas prise en charge.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "Le fichier est trop volumineux. Veuillez sélectionner une archive zip d'une taille maximale de 100 Mo.",
        "The file must be of type zip.": "Le fichier doit être de type zip.",
        "Error loading zip file.": "Erreur de chargement du fichier zip.",
        "Resource is locked.": "La ressource est verrouillée.",
        "Locks can only be set or removed through the web interface.": "Les verrous ne peuvent être définis ou supprimés que via l'interface Web.",
        "The current owner for the domain could not be found.": "Le propriétaire actuel du domaine n'a pas pu être trouvé.",
        "Invitation not found.": "Invitation non trouvée.",
        "Error sending invitation email.": "Erreur lors de l'envoi du courrier d'invitation.",
        "Member not found.": "Membre non trouvé.",
        "You cannot remove yourself.": "Vous ne pouvez pas vous supprimer.",
        "You cannot remove an administrator. This must be done by the domain owner.": "Vous ne pouvez pas supprimer un administrateur. Cela doit être fait par le propriétaire du domaine.",
        "The email address is invalid.": "L'adresse e-mail est invalide.",
        "You cannot transfer the ownership to yourself.": "Vous ne pouvez pas transférer la propriété à vous-même.",
        "Role invalid.": "Rôle invalide.",
        "You cannot update your own role.": "Vous ne pouvez pas mettre à jour votre propre rôle.",
        "You cannot update an administrator. This must be done by the domain owner.": "Vous ne pouvez pas mettre à jour un administrateur. Cela doit être fait par le propriétaire du domaine.",
        "The name can not be empty.": "Le nom ne doit pas être vide.",
        "The step name can not be empty.": "Le nom de l'étape ne peut pas être vide.",
        "The regular expression is invalid.": "L'expression régulière n'est pas valide.",
        "The name is already in use.": "Le nom est déjà utilisé.",
        "You're not the owner of this organization.": "Vous n'êtes pas le propriétaire de cette organisation.",
        "This action requires a Pirsch Plus subscription.": "Cette action nécessite un abonnement à Pirsch Plus.",
        "The interval is invalid.": "L'intervalle est invalide.",
        "The option is invalid.": "L'option est invalide.",
        "Email report not found.": "Rapport par e-mail non trouvé.",
        "Traffic spike report not found.": "Rapport de pic de trafic non trouvé.",
        "There is another email report for this interval.": "Il y a un autre rapport par e-mail pour cet intervalle.",
        "The type must either be page view, event, session, or extended.": "Le type doit être soit une vue de page, un événement, une session ou étendu.",
        "The time range is invalid.": "L'intervalle de temps est invalide.",
        "The kind of data to delete is invalid.": "Le type de données à supprimer est invalide.",
        "The event name and meta key must be set.": "Le nom de l'événement et la clé méta doivent être définis.",
        "Error reading keywords from Search Console.": "Erreur lors de la lecture des mots-clés depuis Search Console.",
        "A single tag must be set.": "Une seule balise doit être définie.",
        "A tag must be set.": "Une balise doit être définie.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "L'image est trop grande. Veuillez sélectionner une image d'une taille maximale de 100 Ko.",
        "The image must be of type svg, jpg, png, or gif.": "L'image doit être de type svg, jpg, png ou gif.",
        "The image must be of type ico, jpg, png, or gif.": "L'image doit être de type ico, jpg, png ou gif.",
        "The filename is invalid.": "Le nom de fichier est invalide.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Paramètres de requête requis manquants. Assurez-vous d'envoyer l'URL, l'IP et le nom pour que l'événement soit compté.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Paramètres de requête manquants. L'URL, l'adresse IP et User-Agent doivent être définis.",
        "The scripts are disabled for this domain. Use the API instead.": "Les scripts sont désactivés pour ce domaine. Utilisez plutôt l'API.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "Votre demande a été refusée. Vous avez peut-être atteint la limite de demandes ou vous n'avez pas d'abonnement actif.",
        "The request was denied due to a filtering policy.": "La demande a été refusée en raison d'une politique de filtrage.",
        "The account is not marked for deletion.": "Le compte n'est pas marqué pour suppression.",
        "An error occurred while reversing the subscription cancellation.": "Une erreur s'est produite lors de l'annulation de l'annulation de l'abonnement.",
        "The confirmation code is invalid.": "Le code de confirmation est invalide.",
        "Please set a password.": "Veuillez définir un mot de passe.",
        "The passwords do not match.": "Les mots de passe ne correspondent pas.",
        "Please accept the terms of service and privacy policy to continue.": "Veuillez accepter les conditions d'utilisation et la politique de confidentialité pour continuer.",
        "The registration code is invalid.": "Le code d'inscription est invalide.",
        "The password is incorrect.": "Le mot de passe est incorrect.",
        "An error occurred while cancelling the subscription.": "Une erreur s'est produite lors de l'annulation de l'abonnement.",
        "An account for this email address and password was not found.": "Aucun compte pour cette adresse e-mail et ce mot de passe n'a été trouvé.",
        "Too many login attempts. Please wait five minutes and try again.": "Trop de tentatives de connexion. Veuillez attendre cinq minutes et réessayer.",
        "The registration email has been resend already.": "L'e-mail d'inscription a déjà été renvoyé.",
        "The language code is invalid (use ISO-6391).": "Le code de langue est invalide (utilisez ISO-6391).",
        "Please enter your full name.": "Veuillez entrer votre nom complet.",
        "The name is too long (max. 100 characters).": "Le nom est trop long (max. 100 caractères).",
        "The email address is in use.": "L'adresse e-mail est déjà utilisée.",
        "Error sending registration email.": "Erreur lors de l'envoi du courrier de confirmation.",
        "Your current password is incorrect.": "Votre mot de passe actuel est incorrect.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "L'image est trop grande. Veuillez sélectionner une image d'une taille maximale de 1 Mo.",
        "The image must be of type jpg, png, or gif.": "L'image doit être de type jpg, png ou gif.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "Le modèle est invalide, il doit uniquement comporter des lettres, des chiffres, des barres obliques, des tirets et des astérisques.",
        "The pattern must start with a slash.": "Le modèle doit commencer par une barre oblique.",
        "View not found.": "Vue non trouvée.",
        "Access to view denied.": "Accès à la vue refusé.",
        "The name must be between 1 and 100 characters long.": "Le nom doit comporter entre 1 et 100 caractères.",
        "The comparison period is invalid.": "La période de comparaison est invalide.",
        "Webhook not found.": "Webhook non trouvé.",
        "The description is too long. The maximum is 100 characters.": "La description est trop longue. Le maximum est de 100 caractères.",
        "The event name is too long. The maximum is 200 characters.": "Le nom de l'événement est trop long. Le maximum est de 200 caractères.",
        "The event name must be set.": "Le nom de l'événement doit être défini.",
        "The endpoint is too long. The maximum is 2000 characters.": "Le point de terminaison est trop long. Le maximum est de 2000 caractères.",
        "The endpoint must be set.": "Le point de terminaison doit être défini.",
        "The endpoint is not a valid URL.": "Le point de terminaison n'est pas une URL valide.",
        "The endpoint must use HTTPS.": "Le point de terminaison doit utiliser HTTPS.",
        "A webhook with this name and endpoint already exists.": "Un webhook avec ce nom et ce point de terminaison existe déjà.",
        "Access key invalid.": "Clé d'accès invalide.",
        "The pattern is invalid.": "Le modèle est invalide.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "Code d'identification non trouvé pour le domaine. Assurez-vous d'envoyer des requêtes à partir du domaine ou sous-domaine configuré. Les codes d'identification et les domaines sont mis en cache jusqu'à 5 minutes.",
        "Too many tags.": "Trop de balises.",
        "A tag key is too long. Please ensure they stay within the character limit.": "Une clé de balise est trop longue. Veuillez vous assurer qu'elles restent dans la limite de caractères.",
        "A tag value is too long. Please ensure they stay within the character limit.": "Une valeur de balise est trop longue. Veuillez vous assurer qu'elles restent dans la limite de caractères.",
        "Funnel not found.": "Entonnoir introuvable.",
        "There must be at least two steps in the funnel.": "L'entonnoir doit comporter au moins deux étapes.",
        "There must be no more than eight steps in the funnel.": "L'entonnoir ne doit pas comporter plus de huit étapes.",
        "The event name must be set if meta data is used.": "Le nom de l'événement doit être défini si des métadonnées sont utilisées.",
        "The filter can not be empty.": "Le filtre ne doit pas être vide.",
        "The date must be after today.": "La date doit être postérieure à aujourd'hui."
    },
    "nl": {
        "error": "Er is een fout opgetreden.",
        "Please select a file.": "Selecteer een bestand.",
        "The billing cycle is invalid.": "De factureringscyclus is ongeldig.",
        "The currency is invalid.": "De valuta is ongeldig.",
        "The subscription plan is invalid.": "Het abonnementsplan is ongeldig.",
        "Error creating session.": "Fout bij het maken van een sessie.",
        "The value is invalid.": "De waarde is ongeldig.",
        "User not found.": "Gebruiker niet gevonden.",
        "The client type is invalid.": "Het cliënttype is ongeldig.",
        "The description is too long.": "De beschrijving is te lang.",
        "The description is too short.": "De beschrijving is te kort.",
        "One or more of the scopes are invalid.": "Een of meer van de bereiken zijn ongeldig.",
        "The client type for the scopes is invalid.": "Het cliënttype voor de bereiken is ongeldig.",
        "Client not found.": "Cliënt niet gevonden.",
        "Credentials invalid.": "Referenties ongeldig.",
        "Refresh token invalid.": "Verversingstoken ongeldig.",
        "The display name is too long (the maximum is 50 characters).": "De weergavenaam is te lang (het maximum is 50 tekens).",
        "The subdomain must be between 3 and 100 characters long.": "Het subdomein moet tussen 3 en 100 tekens lang zijn.",
        "The subdomain must end with a character or digit.": "Het subdomein moet eindigen met een teken of cijfer.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "Het subdomein bevat ongeldige tekens. Zorg ervoor dat je alleen tekens, letters en streepjes gebruikt.",
        "The subdomain is not allowed.": "Het subdomein is niet toegestaan.",
        "The subdomain is in use.": "Het subdomein is in gebruik.",
        "You're not an administrator of this organization.": "Je bent geen beheerder van deze organisatie.",
        "You need an active subscription or free trial to add websites.": "Je hebt een actief abonnement of een gratis proefperiode nodig om websites toe te voegen.",
        "The hostname is in use.": "De hostnaam is in gebruik.",
        "The subdomain must begin with a character or digit.": "Het subdomein moet beginnen met een teken of cijfer.",
        "You don't have permission to create dashboards.": "Je hebt geen toestemming om dashboards te maken.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "Je hebt het maximale aantal domeinen bereikt. Neem contact op met onze ondersteuning om er meer te maken.",
        "Organization not found.": "Organisatie niet gevonden.",
        "The domain is already in use.": "Het domein is al in gebruik.",
        "Custom domain not found.": "Aangepast domein niet gevonden.",
        "Access link not found.": "Toegangslink niet gevonden.",
        "Domain not found.": "Domein niet gevonden.",
        "Theme not found.": "Thema niet gevonden.",
        "The time must be a value between 1 and 900 seconds.": "De tijd moet een waarde tussen 1 en 900 seconden zijn.",
        "The custom domain is in use.": "Het aangepaste domein is in gebruik.",
        "The TXT record for the domain could not be found.": "Het TXT-record voor het domein kon niet worden gevonden.",
        "Error parsing metadata.": "Fout bij het ontleden van metadata.",
        "The timezone is invalid.": "De tijdzone is ongeldig.",
        "The threshold must be 0 or greater.": "De drempel moet 0 of hoger zijn.",
        "Invalid filter type.": "Ongeldig filtertype.",
        "The description must be set.": "De beschrijving moet worden ingesteld.",
        "The filter is too long.": "Het filter is te lang.",
        "The filter must be set.": "Het filter moet worden ingesteld.",
        "The filter already exists.": "Het filter bestaat al.",
        "The IP or CIDR is invalid.": "Het IP of CIDR is ongeldig.",
        "The hostname is invalid.": "De hostnaam is ongeldig.",
        "The country code is invalid.": "De landcode is ongeldig.",
        "Traffic filter not found.": "Verkeersfilter niet gevonden.",
        "Goal not found.": "Doel niet gevonden.",
        "The name is too long.": "De naam is te lang.",
        "The name is too short.": "De naam is te kort.",
        "The name is in use.": "De naam is in gebruik.",
        "The pattern is too long.": "Het patroon is te lang.",
        "The pattern is in use.": "Het patroon is in gebruik.",
        "The sample is too long.": "Het monster is te lang.",
        "The visitor goal is invalid (must be 0 or above).": "Het bezoekersdoel is ongeldig (moet 0 of hoger zijn).",
        "The conversion rate is invalid (must be between 0 and 100).": "Het conversiepercentage is ongeldig (moet tussen 0 en 100 zijn).",
        "The event name must be set if no path pattern or regular expression is present.": "De naam van het evenement moet worden ingesteld als er geen padpatroon of reguliere expressie aanwezig is.",
        "The event name is too long (must be between 0 and 60).": "De naam van het evenement is te lang (moet tussen 0 en 60 zijn).",
        "The event meta key must be set.": "De meta-sleutel van het evenement moet worden ingesteld.",
        "The event meta key is too long (must be between 0 and 60).": "De meta-sleutel van het evenement is te lang (moet tussen 0 en 60 zijn).",
        "The event meta value must be set.": "De meta-waarde van het evenement moet worden ingesteld.",
        "The event meta value is too long (must be between 0 and 100).": "De meta-waarde van het evenement is te lang (moet tussen 0 en 100 zijn).",
        "The custom metric key is too long (must be between 0 and 60).": "De aangepaste metriek sleutel is te lang (moet tussen 0 en 60 zijn).",
        "The custom metric type is invalid (must be float or integer).": "Het aangepaste metriek type is ongeldig (moet float of integer zijn).",
        "The pattern, regular expression, or event name must be set.": "Het patroon, de reguliere expressie of de naam van het evenement moet worden ingesteld.",
        "The total custom metric target is invalid (must be greater than 0).": "Het totale aangepaste metriek doel is ongeldig (moet groter zijn dan 0).",
        "The average custom metric target is invalid (must be greater than 0).": "Het gemiddelde aangepaste metriek doel is ongeldig (moet groter zijn dan 0).",
        "Custom metrics require a Pirsch Plus subscription.": "Aangepaste metrieken vereisen een Pirsch Plus abonnement.",
        "Import job not found.": "Importtaak niet gevonden.",
        "Error connecting user account.": "Fout bij het verbinden van gebruikersaccount.",
        "An import job for this domain already exists.": "Er bestaat al een importtaak voor dit domein.",
        "The import domain must be selected.": "Het importdomein moet worden geselecteerd.",
        "The import job is already running.": "De importtaak is al bezig.",
        "The website is not connected to Google Analytics.": "De website is niet verbonden met Google Analytics.",
        "The website is not connected to Search Console.": "De website is niet verbonden met Search Console.",
        "The import for this type is not supported.": "De import voor dit type wordt niet ondersteund.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "Het bestand is te groot. Selecteer een zip-archief met een maximale grootte van 100 MB.",
        "The file must be of type zip.": "Het bestand moet van het type zip zijn.",
        "Error loading zip file.": "Fout bij het laden van zip-bestand.",
        "Resource is locked.": "Bron is vergrendeld.",
        "Locks can only be set or removed through the web interface.": "Vergrendelingen kunnen alleen worden ingesteld of verwijderd via de webinterface.",
        "The current owner for the domain could not be found.": "De huidige eigenaar van het domein kon niet worden gevonden.",
        "Invitation not found.": "Uitnodiging niet gevonden.",
        "Error sending invitation email.": "Fout bij het verzenden van uitnodigingsmail.",
        "Member not found.": "Lid niet gevonden.",
        "You cannot remove yourself.": "Je kunt jezelf niet verwijderen.",
        "You cannot remove an administrator. This must be done by the domain owner.": "Je kunt geen beheerder verwijderen. Dit moet worden gedaan door de domeineigenaar.",
        "The email address is invalid.": "Het e-mailadres is ongeldig.",
        "You cannot transfer the ownership to yourself.": "Je kunt het eigendom niet aan jezelf overdragen.",
        "Role invalid.": "Rol ongeldig.",
        "You cannot update your own role.": "Je kunt je eigen rol niet bijwerken.",
        "You cannot update an administrator. This must be done by the domain owner.": "Je kunt geen beheerder bijwerken. Dit moet worden gedaan door de domeineigenaar.",
        "The name can not be empty.": "De naam mag niet leeg zijn.",
        "The step name can not be empty.": "De stapnaam kan niet leeg zijn.",
        "The regular expression is invalid.": "De reguliere expressie is ongeldig.",
        "The name is already in use.": "De naam is al in gebruik.",
        "You're not the owner of this organization.": "Je bent niet de eigenaar van deze organisatie.",
        "This action requires a Pirsch Plus subscription.": "Deze actie vereist een Pirsch Plus-abonnement.",
        "The interval is invalid.": "Het interval is ongeldig.",
        "The option is invalid.": "De optie is ongeldig.",
        "Email report not found.": "E-mailrapport niet gevonden.",
        "Traffic spike report not found.": "Verkeerspiekrapport niet gevonden.",
        "There is another email report for this interval.": "Er is een ander e-mailrapport voor dit interval.",
        "The type must either be page view, event, session, or extended.": "Het type moet paginaweergave, gebeurtenis, sessie of uitgebreid zijn.",
        "The time range is invalid.": "Het tijdsbereik is ongeldig.",
        "The kind of data to delete is invalid.": "Het soort gegevens dat moet worden verwijderd, is ongeldig.",
        "The event name and meta key must be set.": "De naam van het evenement en de meta-sleutel moeten worden ingesteld.",
        "Error reading keywords from Search Console.": "Fout bij het lezen van trefwoorden van Search Console.",
        "A single tag must be set.": "Er moet één tag worden ingesteld.",
        "A tag must be set.": "Er moet een tag worden ingesteld.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "De afbeelding is te groot. Selecteer een afbeelding met een maximale grootte van 100 KB.",
        "The image must be of type svg, jpg, png, or gif.": "De afbeelding moet van het type svg, jpg, png of gif zijn.",
        "The image must be of type ico, jpg, png, or gif.": "De afbeelding moet van het type ico, jpg, png of gif zijn.",
        "The filename is invalid.": "De bestandsnaam is ongeldig.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Ontbrekende vereiste verzoekparameters. Zorg ervoor dat je de URL, IP en naam verzendt voor het tellen van het evenement.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Vereiste verzoekparameters ontbreken. De URL, het IP-adres en de User-Agent moeten zijn ingesteld.",
        "The scripts are disabled for this domain. Use the API instead.": "De scripts zijn uitgeschakeld voor dit domein. Gebruik in plaats daarvan de API.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "Je verzoek is geweigerd. Mogelijk heb je het verzoeklimiet bereikt of heb je geen actief abonnement.",
        "The request was denied due to a filtering policy.": "Het verzoek werd geweigerd vanwege een filterbeleid.",
        "The account is not marked for deletion.": "Het account is niet gemarkeerd voor verwijdering.",
        "An error occurred while reversing the subscription cancellation.": "Er is een fout opgetreden bij het terugdraaien van de annulering van het abonnement.",
        "The confirmation code is invalid.": "De bevestigingscode is ongeldig.",
        "Please set a password.": "Stel een wachtwoord in.",
        "The passwords do not match.": "De wachtwoorden komen niet overeen.",
        "Please accept the terms of service and privacy policy to continue.": "Accepteer de servicevoorwaarden en het privacybeleid om door te gaan.",
        "The registration code is invalid.": "De registratiecode is ongeldig.",
        "The password is incorrect.": "Het wachtwoord is onjuist.",
        "An error occurred while cancelling the subscription.": "Er is een fout opgetreden bij het annuleren van het abonnement.",
        "An account for this email address and password was not found.": "Er is geen account gevonden voor dit e-mailadres en wachtwoord.",
        "Too many login attempts. Please wait five minutes and try again.": "Te veel inlogpogingen. Wacht vijf minuten en probeer het opnieuw.",
        "The registration email has been resend already.": "De registratie-e-mail is al opnieuw verzonden.",
        "The language code is invalid (use ISO-6391).": "De taalcode is ongeldig (gebruik ISO-6391).",
        "Please enter your full name.": "Vul je volledige naam in.",
        "The name is too long (max. 100 characters).": "De naam is te lang (max. 100 tekens).",
        "The email address is in use.": "Het e-mailadres is in gebruik.",
        "Error sending registration email.": "Fout bij het verzenden van de registratie-e-mail.",
        "Your current password is incorrect.": "Je huidige wachtwoord is onjuist.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "De afbeelding is te groot. Selecteer een afbeelding met een maximale grootte van 1 MB.",
        "The image must be of type jpg, png, or gif.": "De afbeelding moet van het type jpg, png of gif zijn.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "Het patroon is ongeldig, het mag alleen bestaan uit letters, cijfers, schuine strepen, streepjes en sterretjes.",
        "The pattern must start with a slash.": "Het patroon moet beginnen met een schuine streep.",
        "View not found.": "Weergave niet gevonden.",
        "Access to view denied.": "Toegang tot weergave geweigerd.",
        "The name must be between 1 and 100 characters long.": "De naam moet tussen 1 en 100 tekens lang zijn.",
        "The comparison period is invalid.": "De vergelijkingsperiode is ongeldig.",
        "Webhook not found.": "Webhook niet gevonden.",
        "The description is too long. The maximum is 100 characters.": "De beschrijving is te lang. Het maximum is 100 tekens.",
        "The event name is too long. The maximum is 200 characters.": "De naam van het evenement is te lang. Het maximum is 200 tekens.",
        "The event name must be set.": "De naam van het evenement moet worden ingesteld.",
        "The endpoint is too long. The maximum is 2000 characters.": "Het eindpunt is te lang. Het maximum is 2000 tekens.",
        "The endpoint must be set.": "Het eindpunt moet worden ingesteld.",
        "The endpoint is not a valid URL.": "Het eindpunt is geen geldige URL.",
        "The endpoint must use HTTPS.": "Het eindpunt moet HTTPS gebruiken.",
        "A webhook with this name and endpoint already exists.": "Er bestaat al een webhook met deze naam en dit eindpunt.",
        "Access key invalid.": "Toegangssleutel ongeldig.",
        "The pattern is invalid.": "Het patroon is ongeldig.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "Identificatiecode niet gevonden voor domein. Zorg ervoor dat je verzoeken verzendt vanaf het geconfigureerde domein of subdomein. Identificatiecodes en domeinen worden maximaal 5 minuten in de cache opgeslagen.",
        "Too many tags.": "Te veel tags.",
        "A tag key is too long. Please ensure they stay within the character limit.": "Een tag-sleutel is te lang. Zorg ervoor dat ze binnen de tekenlimiet blijven.",
        "A tag value is too long. Please ensure they stay within the character limit.": "Een tag-waarde is te lang. Zorg ervoor dat ze binnen de tekenlimiet blijven.",
        "Funnel not found.": "Trechter niet gevonden.",
        "There must be at least two steps in the funnel.": "Er moeten minstens twee stappen in de trechter zijn.",
        "There must be no more than eight steps in the funnel.": "Er mogen niet meer dan acht stappen in de trechter zijn.",
        "The event name must be set if meta data is used.": "De naam van de gebeurtenis moet worden ingesteld als er metagegevens worden gebruikt.",
        "The filter can not be empty.": "Het filter mag niet leeg zijn.",
        "The date must be after today.": "De datum moet na vandaag vallen."
    },
    "it": {
        "error": "Si è verificato un errore.",
        "Please select a file.": "Seleziona un file.",
        "The billing cycle is invalid.": "Il ciclo di fatturazione non è valido.",
        "The currency is invalid.": "La valuta non è valida.",
        "The subscription plan is invalid.": "Il piano di abbonamento non è valido.",
        "Error creating session.": "Errore durante la creazione della sessione.",
        "The value is invalid.": "Il valore non è valido.",
        "User not found.": "Utente non trovato.",
        "The client type is invalid.": "Il tipo di client non è valido.",
        "The description is too long.": "La descrizione è troppo lunga.",
        "The description is too short.": "La descrizione è troppo corta.",
        "One or more of the scopes are invalid.": "Uno o più ambiti non sono validi.",
        "The client type for the scopes is invalid.": "Il tipo di client per gli ambiti non è valido.",
        "Client not found.": "Client non trovato.",
        "Credentials invalid.": "Credenziali non valide.",
        "Refresh token invalid.": "Token di aggiornamento non valido.",
        "The display name is too long (the maximum is 50 characters).": "Il nome visualizzato è troppo lungo (il massimo è di 50 caratteri).",
        "The subdomain must be between 3 and 100 characters long.": "Il sottodominio deve essere lungo tra 3 e 100 caratteri.",
        "The subdomain must end with a character or digit.": "Il sottodominio deve terminare con un carattere o una cifra.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "Il sottodominio contiene caratteri non validi. Assicurati di utilizzare solo caratteri, lettere e trattini.",
        "The subdomain is not allowed.": "Il sottodominio non è consentito.",
        "The subdomain is in use.": "Il sottodominio è in uso.",
        "You're not an administrator of this organization.": "Non sei un amministratore di questa organizzazione.",
        "You need an active subscription or free trial to add websites.": "Hai bisogno di un abbonamento attivo o di una prova gratuita per aggiungere siti web.",
        "The hostname is in use.": "Il nome host è in uso.",
        "The subdomain must begin with a character or digit.": "Il sottodominio deve iniziare con un carattere o una cifra.",
        "You don't have permission to create dashboards.": "Non hai il permesso di creare dashboard.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "Hai raggiunto il numero massimo di domini. Per crearne altri, contatta il nostro supporto.",
        "Organization not found.": "Organizzazione non trovata.",
        "The domain is already in use.": "Il dominio è già in uso.",
        "Custom domain not found.": "Dominio personalizzato non trovato.",
        "Access link not found.": "Link di accesso non trovato.",
        "Domain not found.": "Dominio non trovato.",
        "Theme not found.": "Tema non trovato.",
        "The time must be a value between 1 and 900 seconds.": "Il tempo deve essere un valore compreso tra 1 e 900 secondi.",
        "The custom domain is in use.": "Il dominio personalizzato è in uso.",
        "The TXT record for the domain could not be found.": "Il record TXT per il dominio non è stato trovato.",
        "Error parsing metadata.": "Errore durante l'analisi dei metadati.",
        "The timezone is invalid.": "Il fuso orario non è valido.",
        "The threshold must be 0 or greater.": "La soglia deve essere 0 o superiore.",
        "Invalid filter type.": "Tipo di filtro non valido.",
        "The description must be set.": "La descrizione deve essere impostata.",
        "The filter is too long.": "Il filtro è troppo lungo.",
        "The filter must be set.": "Il filtro deve essere impostato.",
        "The filter already exists.": "Il filtro esiste già.",
        "The IP or CIDR is invalid.": "L'IP o il CIDR non è valido.",
        "The hostname is invalid.": "Il nome host non è valido.",
        "The country code is invalid.": "Il codice paese non è valido.",
        "Traffic filter not found.": "Filtro di traffico non trovato.",
        "Goal not found.": "Obiettivo non trovato.",
        "The name is too long.": "Il nome è troppo lungo.",
        "The name is too short.": "Il nome è troppo corto.",
        "The name is in use.": "Il nome è in uso.",
        "The pattern is too long.": "Il modello è troppo lungo.",
        "The pattern is in use.": "Il modello è in uso.",
        "The sample is too long.": "Il campione è troppo lungo.",
        "The visitor goal is invalid (must be 0 or above).": "L'obiettivo dei visitatori non è valido (deve essere 0 o superiore).",
        "The conversion rate is invalid (must be between 0 and 100).": "Il tasso di conversione non è valido (deve essere compreso tra 0 e 100).",
        "The event name must be set if no path pattern or regular expression is present.": "Il nome dell'evento deve essere impostato se non è presente un modello di percorso o un'espressione regolare.",
        "The event name is too long (must be between 0 and 60).": "Il nome dell'evento è troppo lungo (deve essere compreso tra 0 e 60).",
        "The event meta key must be set.": "La chiave meta dell'evento deve essere impostata.",
        "The event meta key is too long (must be between 0 and 60).": "La chiave meta dell'evento è troppo lunga (deve essere compresa tra 0 e 60).",
        "The event meta value must be set.": "Il valore meta dell'evento deve essere impostato.",
        "The event meta value is too long (must be between 0 and 100).": "Il valore meta dell'evento è troppo lungo (deve essere compreso tra 0 e 100).",
        "The custom metric key is too long (must be between 0 and 60).": "La chiave della metrica personalizzata è troppo lunga (deve essere compresa tra 0 e 60).",
        "The custom metric type is invalid (must be float or integer).": "Il tipo di metrica personalizzata non è valido (deve essere float o integer).",
        "The pattern, regular expression, or event name must be set.": "Il modello, l'espressione regolare o il nome dell'evento deve essere impostato.",
        "The total custom metric target is invalid (must be greater than 0).": "L'obiettivo totale della metrica personalizzata non è valido (deve essere superiore a 0).",
        "The average custom metric target is invalid (must be greater than 0).": "L'obiettivo medio della metrica personalizzata non è valido (deve essere superiore a 0).",
        "Custom metrics require a Pirsch Plus subscription.": "Le metriche personalizzate richiedono un abbonamento a Pirsch Plus.",
        "Import job not found.": "Lavoro di importazione non trovato.",
        "Error connecting user account.": "Errore durante la connessione dell'account utente.",
        "An import job for this domain already exists.": "Esiste già un lavoro di importazione per questo dominio.",
        "The import domain must be selected.": "Il dominio di importazione deve essere selezionato.",
        "The import job is already running.": "Il lavoro di importazione è già in corso.",
        "The website is not connected to Google Analytics.": "Il sito web non è collegato a Google Analytics.",
        "The website is not connected to Search Console.": "Il sito web non è collegato a Search Console.",
        "The import for this type is not supported.": "L'importazione per questo tipo non è supportata.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "Il file è troppo grande. Seleziona un archivio zip con una dimensione massima di 100 MB.",
        "The file must be of type zip.": "Il file deve essere di tipo zip.",
        "Error loading zip file.": "Errore durante il caricamento del file zip.",
        "Resource is locked.": "La risorsa è bloccata.",
        "Locks can only be set or removed through the web interface.": "I blocchi possono essere impostati o rimossi solo tramite l'interfaccia web.",
        "The current owner for the domain could not be found.": "Il proprietario attuale del dominio non è stato trovato.",
        "Invitation not found.": "Invito non trovato.",
        "Error sending invitation email.": "Errore durante l'invio della mail di invito.",
        "Member not found.": "Membro non trovato.",
        "You cannot remove yourself.": "Non puoi rimuovere te stesso.",
        "You cannot remove an administrator. This must be done by the domain owner.": "Non puoi rimuovere un amministratore. Questo deve essere fatto dal proprietario del dominio.",
        "The email address is invalid.": "L'indirizzo email non è valido.",
        "You cannot transfer the ownership to yourself.": "Non puoi trasferire la proprietà a te stesso.",
        "Role invalid.": "Ruolo non valido.",
        "You cannot update your own role.": "Non puoi aggiornare il tuo ruolo.",
        "You cannot update an administrator. This must be done by the domain owner.": "Non puoi aggiornare un amministratore. Questo deve essere fatto dal proprietario del dominio.",
        "The name can not be empty.": "Il nome non deve essere vuoto.",
        "The step name can not be empty.": "Il nome del passo non può essere vuoto.",
        "The regular expression is invalid.": "L'espressione regolare non è valida.",
        "The name is already in use.": "Il nome è già in uso.",
        "You're not the owner of this organization.": "Non sei il proprietario di questa organizzazione.",
        "This action requires a Pirsch Plus subscription.": "Questa azione richiede un abbonamento a Pirsch Plus.",
        "The interval is invalid.": "L'intervallo non è valido.",
        "The option is invalid.": "L'opzione non è valida.",
        "Email report not found.": "Rapporto email non trovato.",
        "Traffic spike report not found.": "Rapporto di picco di traffico non trovato.",
        "There is another email report for this interval.": "Esiste un altro rapporto email per questo intervallo.",
        "The type must either be page view, event, session, or extended.": "Il tipo deve essere visualizzazione di pagina, evento, sessione o esteso.",
        "The time range is invalid.": "L'intervallo di tempo non è valido.",
        "The kind of data to delete is invalid.": "Il tipo di dati da eliminare non è valido.",
        "The event name and meta key must be set.": "Il nome dell'evento e la chiave meta devono essere impostati.",
        "Error reading keywords from Search Console.": "Errore durante la lettura delle parole chiave da Search Console.",
        "A single tag must be set.": "Deve essere impostata una singola etichetta.",
        "A tag must be set.": "Deve essere impostata un'etichetta.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "L'immagine è troppo grande. Seleziona un'immagine con una dimensione massima di 100 KB.",
        "The image must be of type svg, jpg, png, or gif.": "L'immagine deve essere di tipo svg, jpg, png o gif.",
        "The image must be of type ico, jpg, png, or gif.": "L'immagine deve essere di tipo ico, jpg, png o gif.",
        "The filename is invalid.": "Il nome del file non è valido.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Parametri di richiesta richiesti mancanti. Assicurati di inviare l'URL, l'IP e il nome per contare l'evento.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Mancano i parametri di richiesta richiesti. L'URL, l'indirizzo IP e lo User-Agent devono essere impostati.",
        "The scripts are disabled for this domain. Use the API instead.": "Gli script sono disabilitati per questo dominio. Utilizza l'API invece.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "La tua richiesta è stata negata. Potresti aver raggiunto il limite di richieste o non avere un abbonamento attivo.",
        "The request was denied due to a filtering policy.": "La richiesta è stata negata a causa di una politica di filtraggio.",
        "The account is not marked for deletion.": "L'account non è contrassegnato per l'eliminazione.",
        "An error occurred while reversing the subscription cancellation.": "Si è verificato un errore durante l'annullamento della cancellazione dell'abbonamento.",
        "The confirmation code is invalid.": "Il codice di conferma non è valido.",
        "Please set a password.": "Imposta una password.",
        "The passwords do not match.": "Le password non corrispondono.",
        "Please accept the terms of service and privacy policy to continue.": "Accetta i termini di servizio e l'informativa sulla privacy per continuare.",
        "The registration code is invalid.": "Il codice di registrazione non è valido.",
        "The password is incorrect.": "La password è errata.",
        "An error occurred while cancelling the subscription.": "Si è verificato un errore durante l'annullamento dell'abbonamento.",
        "An account for this email address and password was not found.": "Non è stato trovato un account per questo indirizzo email e password.",
        "Too many login attempts. Please wait five minutes and try again.": "Troppi tentativi di accesso. Attendi cinque minuti e riprova.",
        "The registration email has been resend already.": "L'email di registrazione è già stata inviata nuovamente.",
        "The language code is invalid (use ISO-6391).": "Il codice della lingua non è valido (usa ISO-6391).",
        "Please enter your full name.": "Inserisci il tuo nome completo.",
        "The name is too long (max. 100 characters).": "Il nome è troppo lungo (max. 100 caratteri).",
        "The email address is in use.": "L'indirizzo email è in uso.",
        "Error sending registration email.": "Errore durante l'invio della mail di registrazione.",
        "Your current password is incorrect.": "La tua password attuale è errata.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "L'immagine è troppo grande. Seleziona un'immagine con una dimensione massima di 1 MB.",
        "The image must be of type jpg, png, or gif.": "L'immagine deve essere di tipo jpg, png o gif.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "Il modello non è valido, deve essere composto solo da lettere, cifre, barre, trattini e asterischi.",
        "The pattern must start with a slash.": "Il modello deve iniziare con una barra.",
        "View not found.": "Visualizzazione non trovata.",
        "Access to view denied.": "Accesso alla visualizzazione negato.",
        "The name must be between 1 and 100 characters long.": "Il nome deve essere lungo tra 1 e 100 caratteri.",
        "The comparison period is invalid.": "Il periodo di confronto non è valido.",
        "Webhook not found.": "Webhook non trovato.",
        "The description is too long. The maximum is 100 characters.": "La descrizione è troppo lunga. Il massimo è 100 caratteri.",
        "The event name is too long. The maximum is 200 characters.": "Il nome dell'evento è troppo lungo. Il massimo è 200 caratteri.",
        "The event name must be set.": "Il nome dell'evento deve essere impostato.",
        "The endpoint is too long. The maximum is 2000 characters.": "Il punto finale è troppo lungo. Il massimo è 2000 caratteri.",
        "The endpoint must be set.": "Il punto finale deve essere impostato.",
        "The endpoint is not a valid URL.": "Il punto finale non è un URL valido.",
        "The endpoint must use HTTPS.": "Il punto finale deve usare HTTPS.",
        "A webhook with this name and endpoint already exists.": "Esiste già un webhook con questo nome e punto finale.",
        "Access key invalid.": "Chiave di accesso non valida.",
        "The pattern is invalid.": "Il modello non è valido.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "Codice di identificazione non trovato per il dominio. Assicurati di inviare richieste dal dominio o sottodominio configurato. I codici di identificazione e i domini sono memorizzati nella cache fino a 5 minuti.",
        "Too many tags.": "Troppi tag.",
        "A tag key is too long. Please ensure they stay within the character limit.": "Una chiave di tag è troppo lunga. Assicurati che rimangano entro il limite di caratteri.",
        "A tag value is too long. Please ensure they stay within the character limit.": "Un valore di tag è troppo lungo. Assicurati che rimangano entro il limite di caratteri.",
        "Funnel not found.": "Imbuto non trovato.",
        "There must be at least two steps in the funnel.": "L'imbuto deve essere composto da almeno due passi.",
        "There must be no more than eight steps in the funnel.": "L'imbuto non deve contenere più di otto passi.",
        "The event name must be set if meta data is used.": "Il nome dell'evento deve essere impostato se si utilizzano i metadati.",
        "The filter can not be empty.": "Il filtro non deve essere vuoto.",
        "The date must be after today.": "La data deve essere successiva a oggi."
    },
    "pt": {
        "error": "Ocorreu um erro.",
        "Please select a file.": "Por favor, selecione um arquivo.",
        "The billing cycle is invalid.": "O ciclo de faturamento é inválido.",
        "The currency is invalid.": "A moeda é inválida.",
        "The subscription plan is invalid.": "O plano de assinatura é inválido.",
        "Error creating session.": "Erro ao criar a sessão.",
        "The value is invalid.": "O valor é inválido.",
        "User not found.": "Usuário não encontrado.",
        "The client type is invalid.": "O tipo de cliente é inválido.",
        "The description is too long.": "A descrição é muito longa.",
        "The description is too short.": "A descrição é muito curta.",
        "One or more of the scopes are invalid.": "Um ou mais dos campos são inválidos.",
        "The client type for the scopes is invalid.": "O tipo de cliente para os campos é inválido.",
        "Client not found.": "Cliente não encontrado.",
        "Credentials invalid.": "Credenciais inválidas.",
        "Refresh token invalid.": "Token de atualização inválido.",
        "The display name is too long (the maximum is 50 characters).": "O nome de exibição é muito longo (o máximo é de 50 caracteres).",
        "The subdomain must be between 3 and 100 characters long.": "O subdomínio deve ter entre 3 e 100 caracteres.",
        "The subdomain must end with a character or digit.": "O subdomínio deve terminar com um caractere ou dígito.",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "O subdomínio contém caracteres inválidos. Certifique-se de usar apenas caracteres, letras e hífens.",
        "The subdomain is not allowed.": "O subdomínio não é permitido.",
        "The subdomain is in use.": "O subdomínio está em uso.",
        "You're not an administrator of this organization.": "Você não é um administrador desta organização.",
        "You need an active subscription or free trial to add websites.": "Você precisa de uma assinatura ativa ou de um teste gratuito para adicionar sites.",
        "The hostname is in use.": "O nome do host está em uso.",
        "The subdomain must begin with a character or digit.": "O subdomínio deve começar com um caractere ou dígito.",
        "You don't have permission to create dashboards.": "Você não tem permissão para criar dashboards.",
        "You have reached the maximum number of domains. To create more, please contact our support.": "Você atingiu o número máximo de domínios. Para criar mais, entre em contato com nosso suporte.",
        "Organization not found.": "Organização não encontrada.",
        "The domain is already in use.": "O domínio já está em uso.",
        "Custom domain not found.": "Domínio personalizado não encontrado.",
        "Access link not found.": "Link de acesso não encontrado.",
        "Domain not found.": "Domínio não encontrado.",
        "Theme not found.": "Tema não encontrado.",
        "The time must be a value between 1 and 900 seconds.": "O tempo deve ser um valor entre 1 e 900 segundos.",
        "The custom domain is in use.": "O domínio personalizado está em uso.",
        "The TXT record for the domain could not be found.": "O registro TXT para o domínio não pôde ser encontrado.",
        "Error parsing metadata.": "Erro ao analisar os metadados.",
        "The timezone is invalid.": "O fuso horário é inválido.",
        "The threshold must be 0 or greater.": "O limite deve ser 0 ou maior.",
        "Invalid filter type.": "Tipo de filtro inválido.",
        "The description must be set.": "A descrição deve ser definida.",
        "The filter is too long.": "O filtro é muito longo.",
        "The filter must be set.": "O filtro deve ser definido.",
        "The filter already exists.": "O filtro já existe.",
        "The IP or CIDR is invalid.": "O IP ou CIDR é inválido.",
        "The hostname is invalid.": "O nome do host é inválido.",
        "The country code is invalid.": "O código do país é inválido.",
        "Traffic filter not found.": "Filtro de tráfego não encontrado.",
        "Goal not found.": "Objetivo não encontrado.",
        "The name is too long.": "O nome é muito longo.",
        "The name is too short.": "O nome é muito curto.",
        "The name is in use.": "O nome está em uso.",
        "The pattern is too long.": "O padrão é muito longo.",
        "The pattern is in use.": "O padrão está em uso.",
        "The sample is too long.": "A amostra é muito longa.",
        "The visitor goal is invalid (must be 0 or above).": "O objetivo de visitantes é inválido (deve ser 0 ou maior).",
        "The conversion rate is invalid (must be between 0 and 100).": "A taxa de conversão é inválida (deve estar entre 0 e 100).",
        "The event name must be set if no path pattern or regular expression is present.": "O nome do evento deve ser definido se não houver um padrão de caminho ou expressão regular.",
        "The event name is too long (must be between 0 and 60).": "O nome do evento é muito longo (deve ter entre 0 e 60).",
        "The event meta key must be set.": "A chave meta do evento deve ser definida.",
        "The event meta key is too long (must be between 0 and 60).": "A chave meta do evento é muito longa (deve ter entre 0 e 60).",
        "The event meta value must be set.": "O valor meta do evento deve ser definido.",
        "The event meta value is too long (must be between 0 and 100).": "O valor meta do evento é muito longo (deve ter entre 0 e 100).",
        "The custom metric key is too long (must be between 0 and 60).": "A chave de métrica personalizada é muito longa (deve ter entre 0 e 60).",
        "The custom metric type is invalid (must be float or integer).": "O tipo de métrica personalizada é inválido (deve ser float ou integer).",
        "The pattern, regular expression, or event name must be set.": "O padrão, a expressão regular ou o nome do evento deve ser definido.",
        "The total custom metric target is invalid (must be greater than 0).": "A meta total de métrica personalizada é inválida (deve ser maior que 0).",
        "The average custom metric target is invalid (must be greater than 0).": "A meta média de métrica personalizada é inválida (deve ser maior que 0).",
        "Custom metrics require a Pirsch Plus subscription.": "Métricas personalizadas requerem uma assinatura do Pirsch Plus.",
        "Import job not found.": "Tarefa de importação não encontrada.",
        "Error connecting user account.": "Erro ao conectar a conta do usuário.",
        "An import job for this domain already exists.": "Já existe uma tarefa de importação para este domínio.",
        "The import domain must be selected.": "O domínio de importação deve ser selecionado.",
        "The import job is already running.": "A tarefa de importação já está em execução.",
        "The website is not connected to Google Analytics.": "O site não está conectado ao Google Analytics.",
        "The website is not connected to Search Console.": "O site não está conectado ao Search Console.",
        "The import for this type is not supported.": "A importação para este tipo não é suportada.",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "O arquivo é muito grande. Selecione um arquivo zip com tamanho máximo de 100 MB.",
        "The file must be of type zip.": "O arquivo deve ser do tipo zip.",
        "Error loading zip file.": "Erro ao carregar arquivo zip.",
        "Resource is locked.": "Recurso bloqueado.",
        "Locks can only be set or removed through the web interface.": "Os bloqueios só podem ser definidos ou removidos através da interface web.",
        "The current owner for the domain could not be found.": "O proprietário atual do domínio não pôde ser encontrado.",
        "Invitation not found.": "Convite não encontrado.",
        "Error sending invitation email.": "Erro ao enviar o convite por e-mail.",
        "Member not found.": "Membro não encontrado.",
        "You cannot remove yourself.": "Você não pode se remover.",
        "You cannot remove an administrator. This must be done by the domain owner.": "Você não pode remover um administrador. Isso deve ser feito pelo proprietário do domínio.",
        "The email address is invalid.": "O endereço de e-mail é inválido.",
        "You cannot transfer the ownership to yourself.": "Você não pode transferir a propriedade para si mesmo.",
        "Role invalid.": "Função inválida.",
        "You cannot update your own role.": "Você não pode atualizar sua própria função.",
        "You cannot update an administrator. This must be done by the domain owner.": "Você não pode atualizar um administrador. Isso deve ser feito pelo proprietário do domínio.",
        "The name can not be empty.": "O nome não deve estar vazio.",
        "The step name can not be empty.": "O nome da etapa não pode estar vazio.",
        "The regular expression is invalid.": "A expressão regular é inválida.",
        "The name is already in use.": "O nome já está em uso.",
        "You're not the owner of this organization.": "Você não é o proprietário desta organização.",
        "This action requires a Pirsch Plus subscription.": "Esta ação requer uma assinatura do Pirsch Plus.",
        "The interval is invalid.": "O intervalo é inválido.",
        "The option is invalid.": "A opção é inválida.",
        "Email report not found.": "Relatório de e-mail não encontrado.",
        "Traffic spike report not found.": "Relatório de pico de tráfego não encontrado.",
        "There is another email report for this interval.": "Há outro relatório de e-mail para este intervalo.",
        "The type must either be page view, event, session, or extended.": "O tipo deve ser visualização de página, evento, sessão ou estendido.",
        "The time range is invalid.": "O intervalo de tempo é inválido.",
        "The kind of data to delete is invalid.": "O tipo de dados a serem excluídos é inválido.",
        "The event name and meta key must be set.": "O nome do evento e a chave meta devem ser definidos.",
        "Error reading keywords from Search Console.": "Erro ao ler palavras-chave do Search Console.",
        "A single tag must be set.": "Deve ser definida uma única etiqueta.",
        "A tag must be set.": "Deve ser definida uma etiqueta.",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "A imagem é muito grande. Selecione uma imagem com tamanho máximo de 100 KB.",
        "The image must be of type svg, jpg, png, or gif.": "A imagem deve ser do tipo svg, jpg, png ou gif.",
        "The image must be of type ico, jpg, png, or gif.": "A imagem deve ser do tipo ico, jpg, png ou gif.",
        "The filename is invalid.": "O nome do arquivo é inválido.",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "Faltando parâmetros de solicitação obrigatórios. Certifique-se de enviar o URL, IP e nome para que o evento seja contado.",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "Faltam os parâmetros de pedido necessários. O URL, o endereço IP e o User-Agent devem ser definidos.",
        "The scripts are disabled for this domain. Use the API instead.": "Os scripts estão desativados para este domínio. Use a API em vez disso.",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "Sua solicitação foi negada. Você pode ter atingido o limite de solicitações ou não ter uma assinatura ativa.",
        "The request was denied due to a filtering policy.": "A solicitação foi negada devido a uma política de filtragem.",
        "The account is not marked for deletion.": "A conta não está marcada para exclusão.",
        "An error occurred while reversing the subscription cancellation.": "Ocorreu um erro ao reverter o cancelamento da assinatura.",
        "The confirmation code is invalid.": "O código de confirmação é inválido.",
        "Please set a password.": "Defina uma senha.",
        "The passwords do not match.": "As senhas não coincidem.",
        "Please accept the terms of service and privacy policy to continue.": "Aceite os termos de serviço e a política de privacidade para continuar.",
        "The registration code is invalid.": "O código de registro é inválido.",
        "The password is incorrect.": "A senha está incorreta.",
        "An error occurred while cancelling the subscription.": "Ocorreu um erro ao cancelar a assinatura.",
        "An account for this email address and password was not found.": "Não foi encontrada uma conta para este endereço de e-mail e senha.",
        "Too many login attempts. Please wait five minutes and try again.": "Muitas tentativas de login. Aguarde cinco minutos e tente novamente.",
        "The registration email has been resend already.": "O e-mail de registro já foi reenviado.",
        "The language code is invalid (use ISO-6391).": "O código do idioma é inválido (use ISO-6391).",
        "Please enter your full name.": "Digite seu nome completo.",
        "The name is too long (max. 100 characters).": "O nome é muito longo (máx. 100 caracteres).",
        "The email address is in use.": "O endereço de e-mail está em uso.",
        "Error sending registration email.": "Erro ao enviar o e-mail de registro.",
        "Your current password is incorrect.": "Sua senha atual está incorreta.",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "A imagem é muito grande. Selecione uma imagem com tamanho máximo de 1 MB.",
        "The image must be of type jpg, png, or gif.": "A imagem deve ser do tipo jpg, png ou gif.",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "O padrão é inválido, deve consistir apenas em letras, dígitos, barras, hífens e asteriscos.",
        "The pattern must start with a slash.": "O padrão deve começar com uma barra.",
        "View not found.": "Visualização não encontrada.",
        "Access to view denied.": "Acesso à visualização negado.",
        "The name must be between 1 and 100 characters long.": "O nome deve ter entre 1 e 100 caracteres.",
        "The comparison period is invalid.": "O período de comparação é inválido.",
        "Webhook not found.": "Webhook não encontrado.",
        "The description is too long. The maximum is 100 characters.": "A descrição é muito longa. O máximo é 100 caracteres.",
        "The event name is too long. The maximum is 200 characters.": "O nome do evento é muito longo. O máximo é 200 caracteres.",
        "The event name must be set.": "O nome do evento deve ser definido.",
        "The endpoint is too long. The maximum is 2000 characters.": "O ponto de extremidade é muito longo. O máximo é 2000 caracteres.",
        "The endpoint must be set.": "O ponto de extremidade deve ser definido.",
        "The endpoint is not a valid URL.": "O ponto de extremidade não é uma URL válida.",
        "The endpoint must use HTTPS.": "O ponto de extremidade deve usar HTTPS.",
        "A webhook with this name and endpoint already exists.": "Já existe um webhook com este nome e ponto de extremidade.",
        "Access key invalid.": "Chave de acesso inválida.",
        "The pattern is invalid.": "O padrão é inválido.",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "Código de identificação não encontrado para o domínio. Certifique-se de enviar solicitações do domínio ou subdomínio configurado. Os códigos de identificação e os domínios são armazenados em cache por até 5 minutos.",
        "Too many tags.": "Muitas etiquetas.",
        "A tag key is too long. Please ensure they stay within the character limit.": "Uma chave de etiqueta é muito longa. Certifique-se de que permaneçam dentro do limite de caracteres.",
        "A tag value is too long. Please ensure they stay within the character limit.": "Um valor de etiqueta é muito longo. Certifique-se de que permaneçam dentro do limite de caracteres.",
        "Funnel not found.": "O funil não foi encontrado.",
        "There must be at least two steps in the funnel.": "Deve haver pelo menos duas etapas no funil.",
        "There must be no more than eight steps in the funnel.": "Não pode haver mais de oito etapas no funil.",
        "The event name must be set if meta data is used.": "O nome do evento deve ser definido se forem utilizados metadados.",
        "The filter can not be empty.": "O filtro não pode estar vazio.",
        "The date must be after today.": "A data deve ser posterior a hoje."
    },
    "ja": {
        "error": "エラーが発生しました。",
        "Please select a file.": "ファイルを選択してください。",
        "The billing cycle is invalid.": "請求サイクルが無効です。",
        "The currency is invalid.": "通貨が無効です。",
        "The subscription plan is invalid.": "サブスクリプションプランが無効です。",
        "Error creating session.": "セッションの作成中にエラーが発生しました。",
        "The value is invalid.": "値が無効です。",
        "User not found.": "ユーザーが見つかりません。",
        "The client type is invalid.": "クライアントタイプが無効です。",
        "The description is too long.": "説明が長すぎます。",
        "The description is too short.": "説明が短すぎます。",
        "One or more of the scopes are invalid.": "1つ以上のスコープが無効です。",
        "The client type for the scopes is invalid.": "スコープのクライアントタイプが無効です。",
        "Client not found.": "クライアントが見つかりません。",
        "Credentials invalid.": "資格情報が無効です。",
        "Refresh token invalid.": "更新トークンが無効です。",
        "The display name is too long (the maximum is 50 characters).": "表示名が長すぎます（最大50文字）。",
        "The subdomain must be between 3 and 100 characters long.": "サブドメインは3〜100文字でなければなりません。",
        "The subdomain must end with a character or digit.": "サブドメインは文字または数字で終わる必要があります。",
        "The subdomain contains invalid characters. Please make sure you only use characters, letters, and hyphens.": "サブドメインに無効な文字が含まれています。文字、文字、およびハイフンのみを使用してください。",
        "The subdomain is not allowed.": "サブドメインは許可されていません。",
        "The subdomain is in use.": "サブドメインは使用中です。",
        "You're not an administrator of this organization.": "あなたはこの組織の管理者ではありません。",
        "You need an active subscription or free trial to add websites.": "ウェブサイトを追加するには、アクティブなサブスクリプションまたは無料トライアルが必要です。",
        "The hostname is in use.": "ホスト名は使用中です。",
        "The subdomain must begin with a character or digit.": "サブドメインは文字または数字で始まる必要があります。",
        "You don't have permission to create dashboards.": "ダッシュボードを作成する権限がありません。",
        "You have reached the maximum number of domains. To create more, please contact our support.": "ドメインの最大数に達しました。さらに作成するには、サポートにお問い合わせください。",
        "Organization not found.": "組織が見つかりません。",
        "The domain is already in use.": "ドメインは既に使用されています。",
        "Custom domain not found.": "カスタムドメインが見つかりません。",
        "Access link not found.": "アクセスリンクが見つかりません。",
        "Domain not found.": "ドメインが見つかりません。",
        "Theme not found.": "テーマが見つかりません。",
        "The time must be a value between 1 and 900 seconds.": "時間は1〜900秒の値でなければなりません。",
        "The custom domain is in use.": "カスタムドメインは使用中です。",
        "The TXT record for the domain could not be found.": "ドメインのTXTレコードが見つかりませんでした。",
        "Error parsing metadata.": "メタデータの解析中にエラーが発生しました。",
        "The timezone is invalid.": "タイムゾーンが無効です。",
        "The threshold must be 0 or greater.": "しきい値は0以上でなければなりません。",
        "Invalid filter type.": "無効なフィルタータイプ。",
        "The description must be set.": "説明を設定する必要があります。",
        "The filter is too long.": "フィルターが長すぎます。",
        "The filter must be set.": "フィルターを設定する必要があります。",
        "The filter already exists.": "フィルターは既に存在します。",
        "The IP or CIDR is invalid.": "IPまたはCIDRが無効です。",
        "The hostname is invalid.": "ホスト名が無効です。",
        "The country code is invalid.": "国コードが無効です。",
        "Traffic filter not found.": "トラフィックフィルターが見つかりません。",
        "Goal not found.": "目標が見つかりません。",
        "The name is too long.": "名前が長すぎます。",
        "The name is too short.": "名前が短すぎます。",
        "The name is in use.": "名前は使用中です。",
        "The pattern is too long.": "パターンが長すぎます。",
        "The pattern is in use.": "パターンは使用中です。",
        "The sample is too long.": "サンプルが長すぎます。",
        "The visitor goal is invalid (must be 0 or above).": "訪問者の目標が無効です（0以上でなければなりません）。",
        "The conversion rate is invalid (must be between 0 and 100).": "コンバージョン率が無効です（0〜100の範囲でなければなりません）。",
        "The event name must be set if no path pattern or regular expression is present.": "パスパターンまたは正規表現がない場合は、イベント名を設定する必要があります。",
        "The event name is too long (must be between 0 and 60).": "イベント名が長すぎます（0〜60文字でなければなりません）。",
        "The event meta key must be set.": "イベントメタキーを設定する必要があります。",
        "The event meta key is too long (must be between 0 and 60).": "イベントメタキーが長すぎます（0〜60文字でなければなりません）。",
        "The event meta value must be set.": "イベントメタ値を設定する必要があります。",
        "The event meta value is too long (must be between 0 and 100).": "イベントメタ値が長すぎます（0〜100文字でなければなりません）。",
        "The custom metric key is too long (must be between 0 and 60).": "カスタムメトリックキーが長すぎます（0〜60文字でなければなりません）。",
        "The custom metric type is invalid (must be float or integer).": "カスタムメトリックタイプが無効です（floatまたはintegerでなければなりません）。",
        "The pattern, regular expression, or event name must be set.": "パターン、正規表現、またはイベント名を設定する必要があります。",
        "The total custom metric target is invalid (must be greater than 0).": "カスタムメトリックの合計目標が無効です（0を超える必要があります）。",
        "The average custom metric target is invalid (must be greater than 0).": "カスタムメトリックの平均目標が無効です（0を超える必要があります）。",
        "Custom metrics require a Pirsch Plus subscription.": "カスタムメトリックにはPirsch Plusのサブスクリプションが必要です。",
        "Import job not found.": "インポートジョブが見つかりません。",
        "Error connecting user account.": "ユーザーアカウントの接続中にエラーが発生しました。",
        "An import job for this domain already exists.": "このドメインには既にインポートジョブが存在します。",
        "The import domain must be selected.": "インポートドメインを選択する必要があります。",
        "The import job is already running.": "インポートジョブは既に実行中です。",
        "The website is not connected to Google Analytics.": "ウェブサイトはGoogle Analyticsに接続されていません。",
        "The website is not connected to Search Console.": "ウェブサイトはSearch Consoleに接続されていません。",
        "The import for this type is not supported.": "このタイプのインポートはサポートされていません。",
        "The file is too large. Please select a zip archive with a maximum size of 100 MB.": "ファイルが大きすぎます。最大サイズ100 MBのzipアーカイブを選択してください。",
        "The file must be of type zip.": "ファイルはzipタイプでなければなりません。",
        "Error loading zip file.": "zipファイルの読み込み中にエラーが発生しました。",
        "Resource is locked.": "リソースがロックされています。",
        "Locks can only be set or removed through the web interface.": "ロックはWebインターフェイスを通じてのみ設定または解除できます。",
        "The current owner for the domain could not be found.": "ドメインの現在の所有者が見つかりませんでした。",
        "Invitation not found.": "招待が見つかりません。",
        "Error sending invitation email.": "招待メールの送信中にエラーが発生しました。",
        "Member not found.": "メンバーが見つかりません。",
        "You cannot remove yourself.": "自分自身を削除することはできません。",
        "You cannot remove an administrator. This must be done by the domain owner.": "管理者を削除することはできません。これはドメイン所有者が行う必要があります。",
        "The email address is invalid.": "メールアドレスが無効です。",
        "You cannot transfer the ownership to yourself.": "自分自身に所有権を移すことはできません。",
        "Role invalid.": "役割が無効です。",
        "You cannot update your own role.": "自分の役割を更新することはできません。",
        "You cannot update an administrator. This must be done by the domain owner.": "管理者を更新することはできません。これはドメイン所有者が行う必要があります。",
        "The name can not be empty.": "名前を空にすることはできません。",
        "The step name can not be empty.": "ステップ名を空にすることはできない。",
        "The regular expression is invalid.": "正規表現が無効です。",
        "The name is already in use.": "名前は既に使用されています。",
        "You're not the owner of this organization.": "あなたはこの組織の所有者ではありません。",
        "This action requires a Pirsch Plus subscription.": "この操作にはPirsch Plusのサブスクリプションが必要です。",
        "The interval is invalid.": "間隔が無効です。",
        "The option is invalid.": "オプションが無効です。",
        "Email report not found.": "メールレポートが見つかりません。",
        "Traffic spike report not found.": "トラフィックスパイクレポートが見つかりません。",
        "There is another email report for this interval.": "この間隔には別のメールレポートがあります。",
        "The type must either be page view, event, session, or extended.": "タイプはページビュー、イベント、セッション、または拡張のいずれかでなければなりません。",
        "The time range is invalid.": "時間範囲が無効です。",
        "The kind of data to delete is invalid.": "削除するデータの種類が無効です。",
        "The event name and meta key must be set.": "イベント名とメタキーを設定する必要があります。",
        "Error reading keywords from Search Console.": "Search Consoleからキーワードを読み取る際にエラーが発生しました。",
        "A single tag must be set.": "単一のタグを設定する必要があります。",
        "A tag must be set.": "タグを設定する必要があります。",
        "The image is too large. Please select an image with a maximum size of 100 KB.": "画像が大きすぎます。最大サイズ100 KBの画像を選択してください。",
        "The image must be of type svg, jpg, png, or gif.": "画像はsvg、jpg、png、またはgifタイプでなければなりません。",
        "The image must be of type ico, jpg, png, or gif.": "画像はico、jpg、png、またはgifタイプでなければなりません。",
        "The filename is invalid.": "ファイル名が無効です。",
        "Missing required request parameters. Make sure you send the URL, IP and name for the event to count.": "必要なリクエストパラメータが欠落しています。イベントをカウントするためにURL、IP、および名前を送信することを確認してください。",
        "Missing required request parameters. The URL, IP address, and User-Agent must be set.": "必要なリクエスト・パラメータがありません。URL、IPアドレス、User-Agentが設定されている必要があります。",
        "The scripts are disabled for this domain. Use the API instead.": "このドメインではスクリプトが無効になっています。代わりにAPIを使用してください。",
        "Your request has been denied. You might have hit the request limit or don't have an active subscription.": "あなたのリクエストは拒否されました。リクエスト制限に達したか、アクティブなサブスクリプションがない可能性があります。",
        "The request was denied due to a filtering policy.": "フィルタリングポリシーによりリクエストが拒否されました。",
        "The account is not marked for deletion.": "アカウントは削除対象としてマークされていません。",
        "An error occurred while reversing the subscription cancellation.": "サブスクリプションのキャンセルを元に戻す際にエラーが発生しました。",
        "The confirmation code is invalid.": "確認コードが無効です。",
        "Please set a password.": "パスワードを設定してください。",
        "The passwords do not match.": "パスワードが一致しません。",
        "Please accept the terms of service and privacy policy to continue.": "続行するには、サービス利用規約およびプライバシーポリシーを受け入れてください。",
        "The registration code is invalid.": "登録コードが無効です。",
        "The password is incorrect.": "パスワードが間違っています。",
        "An error occurred while cancelling the subscription.": "サブスクリプションのキャンセル中にエラーが発生しました。",
        "An account for this email address and password was not found.": "このメールアドレスとパスワードのアカウントが見つかりませんでした。",
        "Too many login attempts. Please wait five minutes and try again.": "ログイン試行回数が多すぎます。5分待ってから再試行してください。",
        "The registration email has been resend already.": "登録メールは既に再送信されています。",
        "The language code is invalid (use ISO-6391).": "言語コードが無効です（ISO-6391を使用）。",
        "Please enter your full name.": "フルネームを入力してください。",
        "The name is too long (max. 100 characters).": "名前が長すぎます（最大100文字）。",
        "The email address is in use.": "メールアドレスが使用中です。",
        "Error sending registration email.": "登録メールの送信中にエラーが発生しました。",
        "Your current password is incorrect.": "現在のパスワードが間違っています。",
        "The image is too large. Please select an image with a maximum size of 1 MB.": "画像が大きすぎます。最大サイズ1 MBの画像を選択してください。",
        "The image must be of type jpg, png, or gif.": "画像はjpg、png、またはgifタイプでなければなりません。",
        "The pattern is invalid, it must only consist out of letters, digits, slashes, hyphens, and asterisks.": "パターンが無効です。文字、数字、スラッシュ、ハイフン、アスタリスクのみで構成する必要があります。",
        "The pattern must start with a slash.": "パターンはスラッシュで始まる必要があります。",
        "View not found.": "ビューが見つかりません。",
        "Access to view denied.": "ビューへのアクセスが拒否されました。",
        "The name must be between 1 and 100 characters long.": "名前は1〜100文字でなければなりません。",
        "The comparison period is invalid.": "比較期間が無効です。",
        "Webhook not found.": "Webhookが見つかりません。",
        "The description is too long. The maximum is 100 characters.": "説明が長すぎます。最大100文字です。",
        "The event name is too long. The maximum is 200 characters.": "イベント名が長すぎます。最大200文字です。",
        "The event name must be set.": "イベント名を設定する必要があります。",
        "The endpoint is too long. The maximum is 2000 characters.": "エンドポイントが長すぎます。最大2000文字です。",
        "The endpoint must be set.": "エンドポイントを設定する必要があります。",
        "The endpoint is not a valid URL.": "エンドポイントが有効なURLではありません。",
        "The endpoint must use HTTPS.": "エンドポイントはHTTPSを使用する必要があります。",
        "A webhook with this name and endpoint already exists.": "この名前とエンドポイントを持つWebhookは既に存在します。",
        "Access key invalid.": "アクセスキーが無効です。",
        "The pattern is invalid.": "パターンが無効です。",
        "Identification code not found for domain. Make sure you're sending requests from the configured domain or subdomain. Identification codes and domains are cached for up to 5 minutes.": "ドメインの識別コードが見つかりません。構成されたドメインまたはサブドメインからリクエストを送信していることを確認してください。識別コードとドメインは最大5分間キャッシュされます。",
        "Too many tags.": "タグが多すぎます。",
        "A tag key is too long. Please ensure they stay within the character limit.": "タグキーが長すぎます。文字数制限内に収まるようにしてください。",
        "A tag value is too long. Please ensure they stay within the character limit.": "タグ値が長すぎます。文字数制限内に収まるようにしてください。",
        "Funnel not found.": "ファネルが見つかりません。",
        "There must be at least two steps in the funnel.": "ファネルには少なくとも2つのステップが必要です。",
        "There must be no more than eight steps in the funnel.": "ファネルには8つ以上のステップがあってはならない。",
        "The event name must be set if meta data is used.": "メタデータが使用される場合、イベント名が設定されていなければならない。",
        "The filter can not be empty.": "フィルターは空であってはならない。",
        "The date must be after today.": "日付は今日以降でなければならない。"
    }
};

export interface Translation {
    [key: string]: KeyValue
}
