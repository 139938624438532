<template>
    <h1>{{t("title")}}</h1>
    <AffiliateLink />
    <hr />
    <AffiliateEarnings />
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import AffiliateLink from "@/components/affiliate/AffiliateLink.vue";
    import AffiliateEarnings from "@/components/affiliate/AffiliateEarnings.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            AffiliateEarnings,
            AffiliateLink
        },
        setup() {
            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Affiliate Program"
        },
        "de": {
            "title": "Affiliate-Programm"
        },
        "es": {
            "title": "Programa de afiliados"
        },
        "fr": {
            "title": "Programme d'affiliation"
        },
        "nl": {
            "title": "Affiliateprogramma"
        },
        "it": {
            "title": "Programma di affiliazione"
        },
        "pt": {
            "title": "Programa de Afiliados"
        },
        "ja": {
            "title": "アフィリエイトプログラム"
        }
    }
</i18n>
