<template>
    <StatisticsList :title="t('title')"
        :entries="data"
        :loading="isLoading('screens')"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :unknownLabel="t('unknown')"
        v-on:filter="filterByScreen" />
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {ListEntry} from "@/model/ListEntry";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {Row} from "@/components/panels/Row";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const {isLoading, screens} = useStatisticsStore();
            const {setField} = filterStore;
            const data = ref<Row[]>([]);

            watch([screens, () => props.visible], () => {
                if (props.visible) {
                    data.value = screens();
                }
            });

            function filterByScreen(entry: ListEntry) {
                setField("screen_class", entry.filter || "null");
            }

            return {
                ...useI18n(),
                isLoading,
                data,
                filterByScreen
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Screen Sizes",
            "unknown": "Unknown"
        },
        "de": {
            "title": "Bildschirmgrößen",
            "unknown": "Unbekannt"
        },
        "es": {
            "title": "Tamaños de Pantalla",
            "unknown": "Desconocido"
        },
        "fr": {
            "title": "Tailles d'Écran",
            "unknown": "Inconnu"
        },
        "nl": {
            "title": "Schermformaten",
            "unknown": "Onbekend"
        },
        "it": {
            "title": "Dimensioni dello Schermo",
            "unknown": "Sconosciuto"
        },
        "pt": {
            "title": "Tamanhos de Tela",
            "unknown": "Desconhecido"
        },
        "ja": {
            "title": "画面サイズ",
            "unknown": "不明"
        }
    }
</i18n>
