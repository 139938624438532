<template>
    <div :class="{'overview-card clickable no-select': true, active: domain.id === activeDomain.id}" v-on:click="switchDomain(domain)">
        <div class="headline">
            <div style="display: flex; align-items: center; gap: 16px; overflow: hidden;">
                <img class="theme-logo" :src="logo" alt="Logo" v-if="logo" />
                <h2>{{domain.display_name ?? domain.hostname}}</h2>
            </div>
            <div style="display: flex; align-items: center; gap: 6px;">
                <FavoriteDomain :domain="domain" v-on:favorite="favorite" />
                <PinnedDomain :domain="domain" v-on:pin="pin" />
                <i class="icon icon-follow clickable" :title="t('open')" v-on:click.stop="openPage(domain.hostname)" />
            </div>
        </div>
        <div class="tags">
            <div class="tag" v-if="isOwner(domain.user_role)">{{t("owner")}}</div>
            <div class="tag" v-if="!loading && stats">
                {{stats?.member}}
                <template v-if="stats?.member === 1">{{t("member")}}</template>
                <template v-else>{{t("members")}}</template>
            </div>
        </div>
        <div class="graph">
            <canvas ref="canvas"
                width="100%"
                height="100%"
                v-if="!loading"></canvas>
        </div>
        <div class="metrics" v-if="!loading && stats">
            <div class="metric">
                <h3>{{t("unique_visitors")}}</h3>
                <div>
                    <div class="sum">{{formatAndShortenNumber(stats?.visitors)}}</div>
                    <GrowthArrow :growth="stats?.visitors_growth ?? 0" />
                </div>
            </div>
            <div class="metric">
                <h3>{{t("page_views")}}</h3>
                <div>
                    <div class="sum">{{formatAndShortenNumber(stats?.views)}}</div>
                    <GrowthArrow :growth="stats?.views_growth ?? 0" />
                </div>
            </div>
        </div>
        <Loading :loading="loading" />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, PropType, ref, watch} from "vue";
    import {timeRangeOptions} from "@/components/panels/time_range_options";
    import {getToday} from "@/util/date";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {TotalVisitorsPageViewsStats} from "@/model/TotalVisitorsPageViewsStats";
    import Loading from "@/components/bits/Loading.vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import dayjs from "dayjs";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {Role} from "@/model/Role";
    import {Domain} from "@/model/Domain";
    import {useRouter} from "vue-router";
    import {OverviewType, useOverviewStore} from "@/store/OverviewStore";
    import {useThemeStore} from "@/store/ThemeStore";
    import {UserRepo} from "@/repositories/UserRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import FavoriteDomain from "@/components/domain/FavoriteDomain.vue";
    import PinnedDomain from "@/components/domain/PinnedDomain.vue";
    import {Chart} from "chart.js";
    import {getBackgroundColor, getBorderColor} from "@/components/panels/chart";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";
    import {ThemeSettings} from "@/store/theme";
    import GrowthArrow from "@/components/panels/GrowthArrow.vue";

    interface CachedTotalVisitorsPageViewsStats extends TotalVisitorsPageViewsStats {
        max_age: Date
    }

    export default defineComponent({
        components: {
            PinnedDomain,
            FavoriteDomain,
            GrowthArrow,
            Loading
        },
        props: {
            domain: {type: Object as PropType<Domain>, required: true},
            type: {type: String as PropType<OverviewType>, required: true}
        },
        setup(props) {
            const router = useRouter();
            const domainStore = useDomainStore();
            const {error} = useToastStore();
            const userStore = useUserStore();
            const {clear, clearComparison} = useFilterStore();
            const {user} = storeToRefs(userStore);
            const {domain: activeDomain} = storeToRefs(domainStore);
            const {theme, darkMode} = storeToRefs(useThemeStore());
            const {type: listType} = storeToRefs(useOverviewStore());
            const canvas = ref(document.createElement("canvas"));
            const loading = ref(true);
            const stats = ref<TotalVisitorsPageViewsStats>();
            const logo = computed(() => {
                const settings = props.domain.theme_settings ?? {};

                if (settings[ThemeSettings.logoDark] && darkMode.value) {
                    return `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${settings[ThemeSettings.logoDark]}`;
                } else if (settings[ThemeSettings.logoLight]) {
                    return `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${settings[ThemeSettings.logoLight]}`;
                }

                return undefined;
            });
            let chart: Chart | undefined;

            onMounted(async () => {
                try {
                    const key = `overview_${props.domain.id}`;
                    const cached = findCachedStatistics(key);

                    if (cached) {
                        stats.value = cached;
                        loading.value = false;
                        setTimeout(createChart, 10);
                        return;
                    }

                    stats.value = await loadStatistics();
                    loading.value = false;
                    cacheStatistics(key, stats.value);
                    setTimeout(createChart, 10);
                } catch (e) {
                    console.error(e);
                }
            });

            watch(listType, () => {
                if (chart) {
                    chart.destroy();
                    createChart();
                }
            });

            function findCachedStatistics(key: string) {
                const cached = localStorage.getItem(key);

                if (cached) {
                    const cachedStats = JSON.parse(cached) as CachedTotalVisitorsPageViewsStats;

                    if (new Date(cachedStats.max_age) > new Date()) {
                        return cachedStats;
                    }

                    localStorage.removeItem(key);
                }

                return null;
            }

            function cacheStatistics(key: string, stats: TotalVisitorsPageViewsStats | undefined) {
                if (stats) {
                    localStorage.setItem(key, JSON.stringify({
                        ...stats,
                        max_age: dayjs().add(15, "minutes").toDate()
                    }));
                }
            }

            async function loadStatistics() {
                const timeRange = timeRangeOptions.find(f => f.value === "30d");
                const today = getToday();
                return await StatisticsRepo.overview({
                    client_id: props.domain.id,
                    timeRange: {
                        from: timeRange?.from(today, 0) ?? today,
                        to: timeRange?.to(today, 0) ?? today,
                        start: 0
                    }
                }) as TotalVisitorsPageViewsStats;
            }

            function createChart() {
                if (!stats.value) {
                    return;
                }

                const data: number[] = [];

                for (let i = 0; i < stats.value.visitors_time_series.length; i++) {
                    data.push(stats.value.visitors_time_series[i].visitors);
                }

                chart = new Chart(canvas.value, {
                    type: "line",
                    data: {
                        labels: data,
                        datasets: [
                            {
                                data,
                                backgroundColor: getBackgroundColor(listType.value === "LIST" ? 48 : 96, darkMode.value, "green", false, false, theme.value),
                                borderColor: getBorderColor(darkMode.value, "green", false, theme.value),
                                fill: true
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                display: false
                            },
                            y: {
                                display: false,
                                beginAtZero: true
                            }
                        },
                        elements: {
                            line: {
                                borderWidth: 2,
                                tension: 0,
                                borderJoinStyle: "round",
                                borderCapStyle: "round"
                            },
                            point: {
                                borderWidth: 0,
                                radius: 0,
                                hoverBorderWidth: 0,
                                hoverRadius: 0
                            }
                        },
                        animation: {
                            duration: 200
                        }
                    }
                });
            }

            function isOwner(role: Role) {
                return role === Role.Owner;
            }

            function openPage(url: string): void {
                window.open("https://" + url, "_blank");
            }

            async function switchDomain(domain: Domain) {
                await router.push("/");

                if (domain.id !== activeDomain.value.id) {
                    domainStore.set(domain);
                    clear();
                    clearComparison();
                }
            }

            async function favorite(id: string) {
                try {
                    if (id === user.value.favorite_domain_id) {
                        id = "";
                    }

                    await UserRepo.setFavoriteDomain(id);
                    await userStore.load();
                } catch (e) {
                    error(e as APIError);
                }
            }

            async function pin(id: string) {
                try {
                    await UserRepo.pinDomain(id);
                    await domainStore.load();
                } catch (e) {
                    error(e as APIError);
                }
            }

            return {
                ...useI18n(),
                OverviewType,
                formatAndShortenNumber,
                formatPercent,
                activeDomain,
                listType,
                canvas,
                loading,
                stats,
                logo,
                isOwner,
                openPage,
                switchDomain,
                favorite,
                pin
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "open": "Open Website",
            "owner": "Owner",
            "member": "Member",
            "members": "Members",
            "unique_visitors": "Unique Visitors",
            "page_views": "Page Views"
        },
        "de": {
            "open": "Webseite öffnen",
            "owner": "Eigentümer",
            "member": "Mitglied",
            "members": "Mitglieder",
            "unique_visitors": "Eindeutige Besucher",
            "page_views": "Seitenaufrufe"
        },
        "es": {
            "open": "Abrir sitio web",
            "owner": "Propietario",
            "member": "Miembro",
            "members": "Miembros",
            "unique_visitors": "Visitantes únicos",
            "page_views": "Vistas de página"
        },
        "fr": {
            "open": "Ouvrir le site web",
            "owner": "Propriétaire",
            "member": "Membre",
            "members": "Membres",
            "unique_visitors": "Visiteurs uniques",
            "page_views": "Vues de page"
        },
        "nl": {
            "open": "Website openen",
            "owner": "Eigenaar",
            "member": "Lid",
            "members": "Leden",
            "unique_visitors": "Unieke bezoekers",
            "page_views": "Paginaweergaven"
        },
        "it": {
            "open": "Apri sito web",
            "owner": "Proprietario",
            "member": "Membro",
            "members": "Membri",
            "unique_visitors": "Visitatori unici",
            "page_views": "Visualizzazioni di pagina"
        },
        "pt": {
            "open": "Abrir site",
            "owner": "Proprietário",
            "member": "Membro",
            "members": "Membros",
            "unique_visitors": "Visitantes únicos",
            "page_views": "Visualizações de página"
        },
        "ja": {
            "open": "ウェブサイトを開く",
            "owner": "オーナー",
            "member": "メンバー",
            "members": "メンバー",
            "unique_visitors": "ユニークビジター",
            "page_views": "ページビュー"
        }
    }
</i18n>
