<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/billing" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <Expand>
            <div v-if="!loading">
                <h4>{{t("limit", {sum: formatNumber(sum), limit: formatNumber(limit)})}}</h4>
                <div class="usage-bar">
                    <div class="bar" :style="`width: ${usage}%`"></div>
                </div>
                <p v-if="reset">{{t("reset", {reset: day(reset)})}}</p>
                <div class="table">
                    <Expand>
                        <table v-show="showDetails">
                            <thead>
                                <tr>
                                    <th colspan="2" style="text-align: left;">
                                        <h5>{{t("table.hostname")}}</h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="domain in domains" :key="domain.domain.id">
                                    <td>
                                        <div class="cell-content">
                                            {{domain.domain.hostname ? domain.domain.hostname : t("deleted_domains")}}
                                        </div>
                                    </td>
                                    <td style="text-align: right;">
                                        <div class="cell-content">
                                            {{formatNumber(domain.page_views)}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Expand>
                </div>
                <button v-on:click="showDetails = !showDetails" v-if="domains.length">
                    {{showDetails ? t("details.hide") : t("details.show")}}
                </button>
            </div>
        </Expand>
        <div style="display: flex; justify-content: center; align-items: center;">
            <Loading :loading="loading" />
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import {Usage} from "@/model/Usage";
    import {UserRepo} from "@/repositories/UserRepo";
    import {day, formatAndShortenNumber, formatPercent} from "@/util/format";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    const plans = [
        10_000,
        100_000,
        200_000,
        500_000,
        1_000_000,
        5_000_000,
        10_000_000,
        20_000_000,
        50_000_000,
        100_000_000
    ];

    export default defineComponent({
        components: {
            Loading,
            Expand
        },
        setup() {
            const {user} = storeToRefs(useUserStore());
            const loading = ref(true);
            const domains = ref<Usage[]>([]);
            const showDetails = ref(false);
            const plan = computed(() => user.value.subscription_plan);
            const reset = computed(() => user.value.subscription_cycle);
            const limit = computed(() => {
                if (user.value.hit_limit) {
                    return user.value.hit_limit;
                }

                return plans[plan.value];
            });
            const sum = computed(() => {
                let sum = 0;

                for (let i = 0; i < domains.value.length; i++) {
                    sum += domains.value[i].page_views;
                }

                return sum;
            });
            const usage = computed(() => {
                if (!limit.value) {
                    return 0;
                }

                const usage = (sum.value/limit.value)*100;

                if (usage > 100) {
                    return 100;
                }

                return usage;
            });

            onMounted(async () => {
                domains.value = await UserRepo.getUsage() as Usage[];
                loading.value = false;
            });

            return {
                ...useI18n(),
                formatPercent,
                formatNumber: formatAndShortenNumber,
                day,
                loading,
                domains,
                showDetails,
                plan,
                reset,
                limit,
                sum,
                usage
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Usage Limit",
            "text": "The usage limit shows the number of page views, events, and 10% of the session extensions for the current billing period.",
            "link": "Read the Docs",
            "limit": "{sum} of {limit}",
            "reset": "The limit will be reset on {reset}.",
            "table.hostname": "Hostname",
            "deleted_domains": "Deleted Domains",
            "details.show": "Show Details",
            "details.hide": "Hide Details"
        },
        "de": {
            "title": "Nutzungslimit",
            "text": "Das Nutzungslimit zeigt die Anzahl der Seitenaufrufe, Events und 10% der Sitzungserweiterungen für den aktuellen Abrechnungszeitraum.",
            "link": "Dokumentation lesen",
            "limit": "{sum} von {limit}",
            "reset": "Das Limit wird am {reset} zurückgesetzt.",
            "table.hostname": "Hostname",
            "deleted_domains": "Gelöschte Domains",
            "details.show": "Details anzeigen",
            "details.hide": "Details verbergen"
        },
        "es": {
            "title": "Límite de uso",
            "text": "El límite de uso muestra el número de vistas de página, eventos y el 10% de las extensiones de sesión para el período de facturación actual.",
            "link": "Leer la documentación",
            "limit": "{sum} de {limit}",
            "reset": "El límite se restablecerá el {reset}.",
            "table.hostname": "Nombre del host",
            "deleted_domains": "Dominios eliminados",
            "details.show": "Mostrar detalles",
            "details.hide": "Ocultar detalles"
        },
        "fr": {
            "title": "Limite d'utilisation",
            "text": "La limite d'utilisation montre le nombre de vues de page, d'événements et 10% des extensions de session pour la période de facturation en cours.",
            "link": "Lire la documentation",
            "limit": "{sum} sur {limit}",
            "reset": "La limite sera réinitialisée le {reset}.",
            "table.hostname": "Nom d'hôte",
            "deleted_domains": "Domaines supprimés",
            "details.show": "Afficher les détails",
            "details.hide": "Masquer les détails"
        },
        "nl": {
            "title": "Gebruikslimiet",
            "text": "De gebruikslimiet toont het aantal paginabezoeken, gebeurtenissen en 10% van de sessieverlengingen voor de huidige factureringsperiode.",
            "link": "Lees de documentatie",
            "limit": "{sum} van {limit}",
            "reset": "Het limiet wordt gereset op {reset}.",
            "table.hostname": "Hostnaam",
            "deleted_domains": "Verwijderde domeinen",
            "details.show": "Details tonen",
            "details.hide": "Details verbergen"
        },
        "it": {
            "title": "Limite di utilizzo",
            "text": "Il limite di utilizzo mostra il numero di visualizzazioni di pagina, eventi e il 10% delle estensioni della sessione per il periodo di fatturazione corrente.",
            "link": "Leggi la documentazione",
            "limit": "{sum} di {limit}",
            "reset": "Il limite verrà ripristinato il {reset}.",
            "table.hostname": "Nome host",
            "deleted_domains": "Domini eliminati",
            "details.show": "Mostra dettagli",
            "details.hide": "Nascondi dettagli"
        },
        "pt": {
            "title": "Limite de uso",
            "text": "O limite de uso mostra o número de visualizações de página, eventos e 10% das extensões de sessão para o período de faturamento atual.",
            "link": "Leia a documentação",
            "limit": "{sum} de {limit}",
            "reset": "O limite será redefinido em {reset}.",
            "table.hostname": "Nome do host",
            "deleted_domains": "Domínios excluídos",
            "details.show": "Mostrar detalhes",
            "details.hide": "Ocultar detalhes"
        },
        "ja": {
            "title": "使用制限",
            "text": "使用制限は、現在の請求期間におけるページビュー数、イベント数、およびセッション拡張の10％を示します。",
            "link": "ドキュメントを読む",
            "limit": "{sum} の {limit}",
            "reset": "制限は{reset}にリセットされます。",
            "table.hostname": "ホスト名",
            "deleted_domains": "削除されたドメイン",
            "details.show": "詳細を表示",
            "details.hide": "詳細を非表示"
        }
    }
</i18n>
