<template>
    <SettingsPage active="reports">
        <EmailReports />
        <hr />
        <TrafficSpikeNotifications />
        <hr />
        <TrafficWarningNotifications />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import EmailReports from "@/components/settings/EmailReports.vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import TrafficSpikeNotifications from "@/components/settings/TrafficSpikeNotifications.vue";
    import TrafficWarningNotifications from "@/components/settings/TrafficWarningNotifications.vue";

    export default defineComponent({
        components: {
            TrafficWarningNotifications,
            TrafficSpikeNotifications,
            SettingsPage,
            EmailReports
        }
    });
</script>
