<template>
    <div class="steps">
        <i :class="{'icon icon-chevron-left cursor-pointer': true, 'disabled': start.getTime() <= first.getTime()}"
            :title="t('previous_period')"
            v-on:click="move(-1)" />
        <i :class="{'icon icon-chevron-right cursor-pointer': true, 'disabled': end.getTime() === today.getTime()}"
            :title="t('next_period')"
            v-on:click="move(1)" />
    </div>
    <div :class="{'action': true, 'active': timeRangeDropdown}" v-on:mousedown="toggleTimeRangeDropdown" ref="timeRangeDropdownElement">
        <i class="icon icon-calender"></i>
        <span v-if="index > -1">{{timeRangeOptions[index].label}}</span>
        <span v-else>
            {{dayShort(start)}}
            <template v-if="start.getTime() !== end.getTime()">— {{dayShort(end)}}</template>
        </span>
        <i :class="{'icon icon-expand': true, 'icon-rotate-180': timeRangeDropdown}"></i>
        <transition name="fade-down">
            <ul class="dropdown right" ref="scrollArea" v-show="timeRangeDropdown" v-on:mousedown.stop>
                <ul class="segmented-button">
                    <li :class="{'active': scale === 'Day' && days !== 0, 'disabled': days === 0}" v-on:click.stop="setScale('Day')">{{t("day")}}</li>
                    <li :class="{'active': scale === 'Week' && days !== 0, 'disabled': days < 7}" v-on:click.stop="setScale('Week')">{{t("week")}}</li>
                    <li :class="{'active': scale === 'Month' && days !== 0, 'disabled': days < 32}" v-on:click.stop="setScale('Month')">{{t("month")}}</li>
                    <li :class="{'active': scale === 'Year' && days !== 0, 'disabled': days < 366}" v-on:click.stop="setScale('Year')">{{t("year")}}</li>
                </ul>
                <hr />
                <template v-for="(o, i) in timeRangeOptions" :key="o.value">
                    <li :class="{selected: active === i}" v-on:click="setTimeRange(o.start(domain), o)">
                        <span>
                            {{o.label}}
                        </span>
                        <span class="shortcut" v-if="o.shortcut.length">
                            {{o.shortcut[0]}}
                        </span>
                    </li>
                    <hr v-if="o.separator" />
                </template>
                <FormInput :label="t('start_date')"
                    name="start"
                    type="date"
                    v-model="startDate"
                    v-on:change="setStartDate" />
                <FormInput :label="t('end_date')"
                    name="end"
                    type="date"
                    v-model="endDate"
                    v-on:change="setEndDate" />
                <div style="display: grid;grid-template-columns: 1fr 1fr;gap: 6px;" v-if="dayFilter && !disableFilter">
                    <FormInput :label="t('start_time')"
                        name="start_time"
                        type="time"
                        step="60"
                        v-model="startTime"
                        v-on:change="setStartTime" />
                    <FormInput :label="t('end_time')"
                        name="end_time"
                        type="time"
                        step="60"
                        v-model="endTime"
                        v-on:change="setEndTime" />
                </div>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, computed, watch, onUnmounted} from "vue";
    import {useDropdown} from "@/components/dropdown";
    import FormInput from "@/components/form/FormInput.vue";
    import {dateParam, dayShort, dateFromParam, timeFromParam, timeParam} from "@/util/format";
    import {debounce} from "@/util/debounce";
    import {defaultTimeRangeOptionIndex, TimeRangeOption, timeRangeOptions} from "@/components/panels/time_range_options";
    import {Filter} from "@/model/Filter";
    import {getToday} from "@/util/date";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useSelect} from "@/components/panels/select";
    import {fromMin} from "@/components/panels/date";
    import {Time} from "@/model/TimeRange";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {user} = storeToRefs(useUserStore());
            const {set, copy} = filterStore;
            const {filter, dayFilter, disableFilter} = storeToRefs(filterStore);
            const {domain} = storeToRefs(useDomainStore());
            const {dropdown: timeRangeDropdown, dropdownElement: timeRangeDropdownElement, toggleDropdown: toggleTimeRangeDropdown} = useDropdown();
            const timeRangeOptionsRef = ref(timeRangeOptions);
            const {active, scrollArea} = useSelect(timeRangeDropdown, timeRangeOptionsRef, selectTimeRange);
            const startDate = ref("");
            const endDate = ref("");
            const startTime = ref("00:00");
            const endTime = ref("00:00");
            const today = computed(() => getToday());
            const index = computed(() => filter.value && filter.value.timeRangeIndex !== undefined ? filter.value.timeRangeIndex : -1);
            const start = computed(() => filter.value && filter.value.timeRange && filter.value.timeRange.from ? filter.value.timeRange.from : today.value);
            const end = computed(() => filter.value && filter.value.timeRange && filter.value.timeRange.to ? filter.value.timeRange.to : today.value);
            const first = computed(() => timeRangeOptions[timeRangeOptions.length-1].from(start.value, 0, domain.value));
            const scale = computed(() => filter.value.scale ? filter.value.scale.charAt(0).toUpperCase()+filter.value.scale.slice(1) : "Day");
            const days = computed(() => Math.floor((end.value.getTime() - start.value.getTime()) / (1000 * 60 * 60 * 24)) || 0);
            const options = computed(() => {
                const options = timeRangeOptions.map(e => ({...e}));

                for (let i = 0; i < options.length; i++) {
                    options[i].label = t("options.period."+options[i].label);
                }

                return options;
            });
            let hidden = false;
            let hiddenTimeout: number;
            let updateRealTime: number;
            let activeTimeRange: number | undefined;
            let moveTimeRangeIndex = Number.parseInt(localStorage.getItem("time_range_step") || "-1");

            onMounted(() => {
                window.addEventListener("focus", windowFocus);
                window.addEventListener("blur", windowBlur);
                document.addEventListener("keyup", keydown);
            });

            onUnmounted(() => {
                document.removeEventListener("focus", windowFocus);
                document.removeEventListener("blur", windowBlur);
                document.removeEventListener("keyup", keydown);
            });

            watch(domain, domain => {
                const allTime = timeRangeOptions[timeRangeOptions.length-1];

                if (index.value === allTime.index) {
                    setTimeRange(allTime.start(domain), allTime, filter.value);
                }
            });

            watch(filter, filter => {
                if (filter.timeRange && filter.timeRange.start > 0) {
                    setTimeRange(filter.timeRange.start, timeRangeOptions[0], filter);
                } else if (filter.timeRange && filter.timeRange.from && filter.timeRange.to) {
                    initDates(filter.timeRange.from, filter.timeRange.to);
                    setDates(dateParam(filter.timeRange.from), dateParam(filter.timeRange.to), filter);
                } else {
                    let timeRange = timeRangeOptions[defaultTimeRangeOptionIndex];

                    if (filter.timeRangeIndex !== undefined && filter.timeRangeIndex > -1 && filter.timeRangeIndex < timeRangeOptions.length) {
                        timeRange = timeRangeOptions[filter.timeRangeIndex];
                    } else if (user.value && user.value.default_time_range) {
                        for (let i = 0; i < timeRangeOptions.length; i++) {
                            if (timeRangeOptions[i].value === user.value.default_time_range) {
                                timeRange = timeRangeOptions[i];
                                break;
                            }
                        }
                    }

                    setTimeRange(0, timeRange, filter);
                }

                if (filter.timeRange?.fromTime && filter.timeRange?.toTime) {
                    startTime.value = timeParam(filter.timeRange.fromTime);
                    endTime.value = timeParam(filter.timeRange.toTime);
                }

                active.value = index.value;
            });

            watch(index, value => {
                if (value === 0) {
                    updateRealTime = setInterval(() => {
                        const f = copy(filter.value);
                        set(f);
                    }, 30000) as unknown as number;
                } else if (updateRealTime) {
                    clearTimeout(updateRealTime);
                    updateRealTime = 0;
                }

                active.value = value;
            });

            watch([start, end], () => {
                startDate.value = dateParam(start.value);
                endDate.value = dateParam(end.value);
                activeTimeRange = undefined;
            });

            watch(days, d => {
                if (d < 7 && scale.value != "Day") {
                    setScale("Day");
                } else if (d < 28 && scale.value != "Day" && scale.value != "Week") {
                    setScale("Week");
                } else if (d < 364 && scale.value != "Day" && scale.value != "Week" && scale.value != "Month") {
                    setScale("Month");
                }
            });

            function setTimeRange(start: number, timeRange: TimeRangeOption, f?: Filter) {
                if (activeTimeRange === timeRange.index) {
                    return;
                }

                activeTimeRange = timeRange.index;
                const from = timeRange.from(today.value, 0, domain.value);
                const to = timeRange.to(today.value, 0);
                const fromTime = timeRange.fromTime();
                const toTime = timeRange.toTime();
                const newIndex = timeRange.index;
                initDates(from, to);

                if (f) {
                    f.timeRange = {from, to, fromTime, toTime, start};
                    f.timeRangeIndex = newIndex;
                    f.scale = f.scale || timeRange.scale || "day";
                    set(f);
                } else {
                    set({
                        timeRange: {from, to, fromTime, toTime, start},
                        timeRangeIndex: newIndex,
                        scale: timeRange.scale || "day"
                    });
                }

                timeRangeDropdown.value = false;
            }

            function setScale(s: string) {
                if (scale.value !== s) {
                    set({
                        scale: s.toLowerCase()
                    });
                    timeRangeDropdown.value = false;
                }
            }

            function initDates(from: Date, to: Date) {
                startDate.value = dateParam(from);
                endDate.value = dateParam(to);
            }

            const setStartDate = debounce((date: string) => {
                setDates(date, endDate.value);
                activeTimeRange = undefined;
                moveTimeRangeIndex = -1;
                localStorage.removeItem("time_range_step");
            }, 300);

            const setEndDate = debounce((date: string) => {
                setDates(startDate.value, date);
                activeTimeRange = undefined;
                moveTimeRangeIndex = -1;
                localStorage.removeItem("time_range_step");
            }, 300);

            const setStartTime = debounce((time: string) => {
                setTimes(time, endTime.value);
            }, 300);

            const setEndTime = debounce((time: string) => {
                setTimes(startTime.value, time);
            }, 300);

            function setDates(from: string, to: string, f?: Filter) {
                let fromDate = dateFromParam(from);
                let toDate = dateFromParam(to);

                if (fromDate === null || toDate === null) {
                    return;
                }

                if (fromDate > toDate) {
                    const tmp = startDate.value;
                    startDate.value = endDate.value;
                    endDate.value = tmp;

                    const tmpDate = fromDate;
                    fromDate = toDate;
                    toDate = tmpDate;
                }

                if (fromMin.isAfter(fromDate)) {
                    fromDate = fromMin.toDate();
                    startDate.value = dateParam(fromDate);
                }

                if (toDate > today.value) {
                    toDate = today.value;
                    endDate.value = dateParam(today.value);
                }

                if (f) {
                    f.timeRange = {
                        from: fromDate,
                        to: toDate,
                        fromTime: f.timeRange?.fromTime,
                        toTime: f.timeRange?.toTime,
                        start: 0
                    };
                    f.timeRangeIndex = getTimeRangeIndex(fromDate, toDate, f.timeRange.fromTime, f.timeRange.toTime);
                    set(f);
                } else {
                    set({
                        timeRange: {from: fromDate, to: toDate, start: 0},
                        timeRangeIndex: getTimeRangeIndex(fromDate, toDate)
                    });
                }
            }

            function setTimes(start: string, end: string) {
                let from = timeFromParam(start);
                let to = timeFromParam(end);

                if (from === null || to === null || from.seconds() === to.seconds()) {
                    return;
                }

                if (from.seconds() > to.seconds()) {
                    const tmp = from;
                    from = to;
                    to = tmp;
                    startTime.value = timeParam(from);
                    endTime.value = timeParam(to);
                }

                const f = copy(filter.value);

                if (f.timeRange) {
                    f.timeRange = {
                        from: f.timeRange.from,
                        to: f.timeRange.to,
                        fromTime: from,
                        toTime: to,
                        start: 0
                    };
                    set(f);
                }
            }

            function move(direction: number) {
                if (filter.value.timeRangeIndex !== undefined && filter.value.timeRangeIndex > -1) {
                    moveTimeRangeIndex = filter.value.timeRangeIndex;
                    localStorage.setItem("time_range_step", filter.value.timeRangeIndex.toString());
                }

                if (filter.value.timeRange) {
                    const start = filter.value.timeRange.from;
                    const end = filter.value.timeRange.to;

                    if (end < today.value || direction < 0) {
                        let startDate, endDate: Date;

                        if (moveTimeRangeIndex > -1) {
                            startDate = timeRangeOptions[moveTimeRangeIndex].from(start, direction, domain.value);
                            endDate = timeRangeOptions[moveTimeRangeIndex].to(start, direction);
                        } else {
                            const days = Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
                            startDate = new Date(start);
                            endDate = new Date(end);
                            startDate.setDate(startDate.getDate()+days*direction);
                            endDate.setDate(endDate.getDate()+days*direction);
                        }

                        if (startDate.getTime() < fromMin.toDate().getTime()) {
                            startDate = fromMin.toDate();
                        }

                        if (endDate.getTime() < fromMin.toDate().getTime()) {
                            endDate = fromMin.toDate();
                        }

                        initDates(startDate, endDate);
                        setDates(dateParam(startDate), dateParam(endDate));
                        activeTimeRange = undefined;
                    }
                }
            }

            function getTimeRangeIndex(from: Date, to: Date, fromTime?: Time, toTime?: Time) {
                for (let i = 0; i < timeRangeOptions.length; i++) {
                    if (timeRangeOptions[i].start(domain.value) === 0 &&
                        from.getTime() === timeRangeOptions[i].from(today.value, 0, domain.value).getTime() &&
                        to.getTime() === timeRangeOptions[i].to(today.value, 0).getTime() &&
                        fromTime?.hour === timeRangeOptions[i].fromTime()?.hour &&
                        fromTime?.minute === timeRangeOptions[i].fromTime()?.minute &&
                        toTime?.hour === timeRangeOptions[i].toTime()?.hour &&
                        toTime?.minute === timeRangeOptions[i].toTime()?.minute) {
                        return timeRangeOptions[i].index;
                    }
                }

                return -1;
            }

            const keydown = debounce((e: KeyboardEvent) => {
                if (hidden || e.target !== document.body || e.altKey || e.ctrlKey ||  e.shiftKey || e.metaKey || e.key === "Alt") {
                    return;
                }

                if (e.code === "ArrowLeft") {
                    move(-1);
                } else if (e.code === "ArrowRight") {
                    move(1);
                }

                for (let i = 0; i < timeRangeOptions.length; i++) {
                    if (timeRangeOptions[i].shortcut.includes(e.code.substring(3, 4))) {
                        setTimeRange(timeRangeOptions[i].start(domain.value), timeRangeOptions[i]);
                        break;
                    }
                }
            }, 200);

            function windowFocus() {
                hiddenTimeout = setTimeout(() => hidden = false, 500) as unknown as number;
            }

            function windowBlur() {
                clearTimeout(hiddenTimeout);
                hidden = true;
            }

            function selectTimeRange(active: number) {
                const range = timeRangeOptions[active];
                setTimeRange(range.start(domain.value), range);
                timeRangeDropdown.value = false;
            }

            return {
                ...useI18n(),
                domain,
                timeRangeDropdown,
                timeRangeDropdownElement,
                toggleTimeRangeDropdown,
                active,
                scrollArea,
                timeRangeOptions: options,
                dayFilter,
                disableFilter,
                index,
                startDate,
                endDate,
                startTime,
                endTime,
                today,
                start,
                end,
                first,
                scale,
                days,
                setTimeRange,
                setScale,
                setStartDate,
                setEndDate,
                setStartTime,
                setEndTime,
                dayShort,
                move
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "previous_period": "Previous Period",
            "next_period": "Next Period",
            "day": "Day",
            "week": "Week",
            "month": "Month",
            "year": "Year",
            "start_date": "Start Date",
            "end_date": "End Date",
            "start_time": "Start Time",
            "end_time": "End Time",
            "options.period.Live": "Live",
            "options.period.Past Hour": "Past Hour",
            "options.period.Today": "Today",
            "options.period.Yesterday": "Yesterday",
            "options.period.Month to Date": "Month to Date",
            "options.period.Last Month": "Last Month",
            "options.period.Past 7 Days": "Past 7 Days",
            "options.period.Past 14 Days": "Past 14 Days",
            "options.period.Past 30 Days": "Past 30 Days",
            "options.period.Past Quarter": "Past Quarter",
            "options.period.Past Half Year": "Past Half Year",
            "options.period.Past Year": "Past Year",
            "options.period.All Time": "All Time"
        },
        "de": {
            "previous_period": "Vorheriger Zeitraum",
            "next_period": "Nächster Zeitraum",
            "day": "Tag",
            "week": "Woche",
            "month": "Monat",
            "year": "Jahr",
            "start_date": "Startdatum",
            "end_date": "Enddatum",
            "start_time": "Startzeit",
            "end_time": "Endzeit",
            "options.period.Live": "Live",
            "options.period.Past Hour": "Letzte Stunde",
            "options.period.Today": "Heute",
            "options.period.Yesterday": "Gestern",
            "options.period.Month to Date": "Monat bis heute",
            "options.period.Last Month": "Letzter Monat",
            "options.period.Past 7 Days": "Letzte 7 Tage",
            "options.period.Past 14 Days": "Letzte 14 Tage",
            "options.period.Past 30 Days": "Letzte 30 Tage",
            "options.period.Past Quarter": "Letztes Quartal",
            "options.period.Past Half Year": "Letztes halbes Jahr",
            "options.period.Past Year": "Letztes Jahr",
            "options.period.All Time": "Gesamte Zeit"
        },
        "es": {
            "previous_period": "Período Anterior",
            "next_period": "Siguiente Período",
            "day": "Día",
            "week": "Semana",
            "month": "Mes",
            "year": "Año",
            "start_date": "Fecha de Inicio",
            "end_date": "Fecha de Finalización",
            "start_time": "Hora de Inicio",
            "end_time": "Hora de Finalización",
            "options.period.Live": "En Vivo",
            "options.period.Past Hour": "Última Hora",
            "options.period.Today": "Hoy",
            "options.period.Yesterday": "Ayer",
            "options.period.Month to Date": "Mes hasta la Fecha",
            "options.period.Last Month": "Mes Pasado",
            "options.period.Past 7 Days": "Últimos 7 Días",
            "options.period.Past 14 Days": "Últimos 14 Días",
            "options.period.Past 30 Days": "Últimos 30 Días",
            "options.period.Past Quarter": "Último Trimestre",
            "options.period.Past Half Year": "Último Medio Año",
            "options.period.Past Year": "Último Año",
            "options.period.All Time": "Todo el Tiempo"
        },
        "fr": {
            "previous_period": "Période Précédente",
            "next_period": "Période Suivante",
            "day": "Jour",
            "week": "Semaine",
            "month": "Mois",
            "year": "Année",
            "start_date": "Date de Début",
            "end_date": "Date de Fin",
            "start_time": "Heure de Début",
            "end_time": "Heure de Fin",
            "options.period.Live": "En Direct",
            "options.period.Past Hour": "Dernière Heure",
            "options.period.Today": "Aujourd'hui",
            "options.period.Yesterday": "Hier",
            "options.period.Month to Date": "Mois à Ce Jour",
            "options.period.Last Month": "Mois Dernier",
            "options.period.Past 7 Days": "7 Derniers Jours",
            "options.period.Past 14 Days": "14 Derniers Jours",
            "options.period.Past 30 Days": "30 Derniers Jours",
            "options.period.Past Quarter": "Dernier Trimestre",
            "options.period.Past Half Year": "Dernier Demi-An",
            "options.period.Past Year": "Dernière Année",
            "options.period.All Time": "Tout le Temps"
        },
        "nl": {
            "previous_period": "Vorige Periode",
            "next_period": "Volgende Periode",
            "day": "Dag",
            "week": "Week",
            "month": "Maand",
            "year": "Jaar",
            "start_date": "Startdatum",
            "end_date": "Einddatum",
            "start_time": "Starttijd",
            "end_time": "Eindtijd",
            "options.period.Live": "Live",
            "options.period.Past Hour": "Afgelopen Uur",
            "options.period.Today": "Vandaag",
            "options.period.Yesterday": "Gisteren",
            "options.period.Month to Date": "Maand tot Nu",
            "options.period.Last Month": "Vorige Maand",
            "options.period.Past 7 Days": "Laatste 7 Dagen",
            "options.period.Past 14 Days": "Laatste 14 Dagen",
            "options.period.Past 30 Days": "Laatste 30 Dagen",
            "options.period.Past Quarter": "Laatste Kwartaal",
            "options.period.Past Half Year": "Laatste Halfjaar",
            "options.period.Past Year": "Laatste Jaar",
            "options.period.All Time": "Altijd"
        },
        "it": {
            "previous_period": "Periodo Precedente",
            "next_period": "Prossimo Periodo",
            "day": "Giorno",
            "week": "Settimana",
            "month": "Mese",
            "year": "Anno",
            "start_date": "Data di Inizio",
            "end_date": "Data di Fine",
            "start_time": "Ora di Inizio",
            "end_time": "Ora di Fine",
            "options.period.Live": "Live",
            "options.period.Past Hour": "Ultima Ora",
            "options.period.Today": "Oggi",
            "options.period.Yesterday": "Ieri",
            "options.period.Month to Date": "Mese fino ad Oggi",
            "options.period.Last Month": "Mese Scorso",
            "options.period.Past 7 Days": "Ultimi 7 Giorni",
            "options.period.Past 14 Days": "Ultimi 14 Giorni",
            "options.period.Past 30 Days": "Ultimi 30 Giorni",
            "options.period.Past Quarter": "Ultimo Trimestre",
            "options.period.Past Half Year": "Ultimo Semestre",
            "options.period.Past Year": "Ultimo Anno",
            "options.period.All Time": "Tutto il Tempo"
        },
        "pt": {
            "previous_period": "Período Anterior",
            "next_period": "Próximo Período",
            "day": "Dia",
            "week": "Semana",
            "month": "Mês",
            "year": "Ano",
            "start_date": "Data de Início",
            "end_date": "Data de Término",
            "start_time": "Hora de Início",
            "end_time": "Hora de Término",
            "options.period.Live": "Ao Vivo",
            "options.period.Past Hour": "Última Hora",
            "options.period.Today": "Hoje",
            "options.period.Yesterday": "Ontem",
            "options.period.Month to Date": "Mês até a Data",
            "options.period.Last Month": "Mês Passado",
            "options.period.Past 7 Days": "Últimos 7 Dias",
            "options.period.Past 14 Days": "Últimos 14 Dias",
            "options.period.Past 30 Days": "Últimos 30 Dias",
            "options.period.Past Quarter": "Último Trimestre",
            "options.period.Past Half Year": "Último Semestre",
            "options.period.Past Year": "Último Ano",
            "options.period.All Time": "Todo o Tempo"
        },
        "ja": {
            "previous_period": "前期間",
            "next_period": "次の期間",
            "day": "日",
            "week": "週",
            "month": "月",
            "year": "年",
            "start_date": "開始日",
            "end_date": "終了日",
            "start_time": "開始時間",
            "end_time": "終了時間",
            "options.period.Live": "ライブ",
            "options.period.Past Hour": "過去1時間",
            "options.period.Today": "今日",
            "options.period.Yesterday": "昨日",
            "options.period.Month to Date": "今月",
            "options.period.Last Month": "先月",
            "options.period.Past 7 Days": "過去7日間",
            "options.period.Past 14 Days": "過去14日間",
            "options.period.Past 30 Days": "過去30日間",
            "options.period.Past Quarter": "過去四半期",
            "options.period.Past Half Year": "過去半年",
            "options.period.Past Year": "過去1年間",
            "options.period.All Time": "すべての期間"
        }
    }
</i18n>
