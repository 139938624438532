<template>
    <SetLock :forDomain="forDomain"
        :locked="locked"
        :lock="lock"
        :open="lockModal"
        v-on:close="lockModal = false" />
    <template v-if="!locked">
        <slot></slot>
    </template>
    <template v-if="isAdmin">
        <i class="icon icon-unlock clickable"
            :title="t('lock')"
            v-on:click.stop="lockModal = true"
            v-show="!locked" />
        <i class="icon icon-lock clickable"
            :title="t('unlock')"
            v-on:click.stop="lockModal = true"
            v-show="locked" />
    </template>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import SetLock from "@/components/modal/SetLock.vue";
    import {Lock} from "@/model/Lock";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useLockStore} from "@/store/LockStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {SetLock},
        props: {
            forDomain: {type: Boolean, default: true},
            lock: {type: String, required: true}
        },
        setup(props) {
            const {locks} = storeToRefs(useLockStore());
            const locked = computed(() => !!locks.value.find((lock: Lock) => lock.name === props.lock));
            const lockModal = ref(false);

            return {
                ...useI18n(),
                ...storeToRefs(useDomainStore()),
                locked,
                lockModal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "lock": "Lock",
            "unlock": "Unlock"
        },
        "de": {
            "lock": "Sperren",
            "unlock": "Entsperren"
        },
        "es": {
            "lock": "Bloquear",
            "unlock": "Desbloquear"
        },
        "fr": {
            "lock": "Verrouiller",
            "unlock": "Déverrouiller"
        },
        "nl": {
            "lock": "Vergrendelen",
            "unlock": "Ontgrendelen"
        },
        "it": {
            "lock": "Bloccare",
            "unlock": "Sbloccare"
        },
        "pt": {
            "lock": "Bloquear",
            "unlock": "Desbloquear"
        },
        "ja": {
            "lock": "ロック",
            "unlock": "アンロック"
        }
    }
</i18n>
