<template>
    <EditReport :open="editReportModal"
        v-on:close="editReportModal = false"
        v-on:action="r => {editReportModal = false;$emit('update', r)}"
        :report="report" />
    <ConfirmDeleteReport :open="deleteReportModal"
        v-on:close="deleteReportModal = false"
        v-on:action="removeReport"
        :report="report" />
    <div class="card no-select">
        <div class="text">
            <p>
                <strong :title="report.email">{{report.email}}</strong>
            </p>
            <small class="light">{{t("next", {date: day(report.next_report)})}}</small>
        </div>
        <div class="row">
            <Lock :lock="`email_report_${report.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editReportModal = true"></i>
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteReportModal = true" />
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {day} from "@/util/format";
    import ConfirmDeleteReport from "@/components/modal/ConfirmDeleteReport.vue";
    import {EmailReport} from "@/model/EmailReport";
    import EditReport from "@/components/modal/EditReport.vue";
    import Lock from "@/components/settings/Lock.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Lock,
            EditReport,
            ConfirmDeleteReport
        },
        props: {
            report: {type: Object as PropType<EmailReport>, required: true}
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const editReportModal = ref(false);
            const deleteReportModal = ref(false);

            function removeReport() {
                deleteReportModal.value = false;
                emit("remove", props.report);
            }

            return {
                ...useI18n(),
                day,
                editReportModal,
                deleteReportModal,
                removeReport
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "next": "Next report: {date}",
            "edit": "Edit",
            "delete": "Delete"
        },
        "de": {
            "next": "Nächster Bericht: {date}",
            "edit": "Bearbeiten",
            "delete": "Löschen"
        },
        "es": {
            "next": "Próximo informe: {date}",
            "edit": "Editar",
            "delete": "Eliminar"
        },
        "fr": {
            "next": "Prochain rapport : {date}",
            "edit": "Éditer",
            "delete": "Supprimer"
        },
        "nl": {
            "next": "Volgend rapport: {date}",
            "edit": "Bewerken",
            "delete": "Verwijderen"
        },
        "it": {
            "next": "Prossimo rapporto: {date}",
            "edit": "Modifica",
            "delete": "Elimina"
        },
        "pt": {
            "next": "Próximo relatório: {date}",
            "edit": "Editar",
            "delete": "Excluir"
        },
        "ja": {
            "next": "次のレポート: {date}",
            "edit": "編集",
            "delete": "削除"
        }
    }
</i18n>
