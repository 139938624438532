<template>
    <CreateEditAccessLink :open="createAccessLinkModal"
        v-on:close="createAccessLinkModal = false"
        v-on:action="addAccessLink" />
    <AccessLinkModal :open="accessLinkModal"
        v-on:close="accessLinkModal = false"
        :link="newLink" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/access#public-access" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <AccessLinkCard v-for="link in links"
                        :key="link.id"
                        :link="link"
                        v-on:update="updateAccessLink"
                        v-on:remove="removeAccessLink" />
                </div>
            </Expand>
            <button v-on:click="createAccessLinkModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useList} from "@/components/list";
    import {AccessLink} from "@/model/AccessLink";
    import AccessLinkCard from "@/components/cards/AccessLinkCard.vue";
    import CreateEditAccessLink from "@/components/modal/CreateEditAccessLink.vue";
    import AccessLinkModal from "@/components/modal/AccessLinkModal.vue";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            AccessLinkModal,
            AccessLinkCard,
            CreateEditAccessLink,
            Loading,
            Expand
        },
        setup() {
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const createAccessLinkModal = ref(false);
            const accessLinkModal = ref(false);
            const loading = ref(true);
            const links = ref<AccessLink[]>([]);
            const newLink = ref<AccessLink>();

            watchEffect(async () => {
                loading.value = true;
                links.value = await DomainRepo.listAccessLinks(domain.value.id) as AccessLink[] || [];
                loading.value = false;
            });

            function addAccessLink(link: AccessLink) {
                createAccessLinkModal.value = false;
                links.value.push(link);
                newLink.value = link;
                accessLinkModal.value = true;
            }

            function updateAccessLink(link: AccessLink) {
                updateEntryByID(links.value, link);
            }

            function removeAccessLink(link: AccessLink) {
                removeEntryByID(links.value, link);
            }

            return {
                ...useI18n(),
                createAccessLinkModal,
                accessLinkModal,
                loading,
                links,
                newLink,
                addAccessLink,
                updateAccessLink,
                removeAccessLink
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Access Links",
            "text": "Create access links to share your dashboard. Anyone with a link can see your statistics.",
            "link": "Read the Docs",
            "add": "Add Access Link"
        },
        "de": {
            "title": "Zugriffslinks",
            "text": "Erstelle Zugriffslinks, um dein Dashboard zu teilen. Jeder mit einem Link kann deine Statistiken sehen.",
            "link": "Dokumentation lesen",
            "add": "Zugriffslink hinzufügen"
        },
        "es": {
            "title": "Enlaces de acceso",
            "text": "Crea enlaces de acceso para compartir tu panel. Cualquiera con un enlace puede ver tus estadísticas.",
            "link": "Leer la documentación",
            "add": "Añadir enlace de acceso"
        },
        "fr": {
            "title": "Liens d'accès",
            "text": "Créez des liens d'accès pour partager votre tableau de bord. Toute personne ayant un lien peut voir vos statistiques.",
            "link": "Lire la documentation",
            "add": "Ajouter un lien d'accès"
        },
        "nl": {
            "title": "Toegangskoppelingen",
            "text": "Maak toegangskoppelingen om je dashboard te delen. Iedereen met een link kan je statistieken bekijken.",
            "link": "Lees de documentatie",
            "add": "Toegangskoppeling toevoegen"
        },
        "it": {
            "title": "Link di accesso",
            "text": "Crea link di accesso per condividere la tua dashboard. Chiunque con un link può vedere le tue statistiche.",
            "link": "Leggi la documentazione",
            "add": "Aggiungi link di accesso"
        },
        "pt": {
            "title": "Links de acesso",
            "text": "Crie links de acesso para compartilhar seu painel. Qualquer pessoa com um link pode ver suas estatísticas.",
            "link": "Leia a documentação",
            "add": "Adicionar link de acesso"
        },
        "ja": {
            "title": "アクセスリンク",
            "text": "ダッシュボードを共有するためのアクセスリンクを作成します。リンクを持っている人は誰でもあなたの統計を見ることができます。",
            "link": "ドキュメントを読む",
            "add": "アクセスリンクを追加"
        }
    }
</i18n>
