<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {email: report.email})}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {ReportRepo} from "@/repositories/ReportRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {EmailReport} from "@/model/EmailReport";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            report: {type: Object as PropType<EmailReport>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await ReportRepo.delete(props.report.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text": "You are about to delete the report for email address \"{email}\". Are you sure you would like to continue?",
            "form.submit": "Delete",
            "toast.success": "The report has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text": "Du bist dabei, den Bericht für die E-Mail-Adresse \"{email}\" zu löschen. Bist du sicher, dass du fortfahren möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Der Bericht wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text": "Estás a punto de eliminar el informe para la dirección de correo electrónico \"{email}\". ¿Estás seguro de que deseas continuar?",
            "form.submit": "Eliminar",
            "toast.success": "El informe ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text": "Vous êtes sur le point de supprimer le rapport pour l'adresse e-mail \"{email}\". Êtes-vous sûr de vouloir continuer ?",
            "form.submit": "Supprimer",
            "toast.success": "Le rapport a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text": "Je staat op het punt het rapport voor het e-mailadres \"{email}\" te verwijderen. Weet je zeker dat je wilt doorgaan?",
            "form.submit": "Verwijderen",
            "toast.success": "Het rapport is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text": "Stai per eliminare il rapporto per l'indirizzo email \"{email}\". Sei sicuro di voler continuare?",
            "form.submit": "Elimina",
            "toast.success": "Il rapporto è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text": "Você está prestes a excluir o relatório para o endereço de e-mail \"{email}\". Tem certeza de que deseja continuar?",
            "form.submit": "Excluir",
            "toast.success": "O relatório foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text": "メールアドレス \"{email}\" のレポートを削除しようとしています。続行してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "レポートが削除されました。"
        }
    }
</i18n>
