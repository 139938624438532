<template>
    <TransferOwnershipEmail :open="transferModal"
        v-on:close="transferModal = false" />
    <ConfirmCancelOwnershipTransfer :open="cancelTransferModal"
        v-on:close="cancelTransferModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <button class="red" v-on:click="transferModal = true" v-if="!domain.new_owner">{{t("transfer")}}</button>
            <button class="red" v-on:click="cancelTransferModal = true" v-if="domain.new_owner">{{t("cancel")}}</button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import TransferOwnershipEmail from "@/components/modal/TransferOwnershipEmail.vue";
    import ConfirmCancelOwnershipTransfer from "@/components/modal/ConfirmCancelOwnershipTransfer.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            TransferOwnershipEmail,
            ConfirmCancelOwnershipTransfer
        },
        setup: function () {
            const {domain} = storeToRefs(useDomainStore());
            const transferModal = ref(false);
            const cancelTransferModal = ref(false);

            return {
                ...useI18n(),
                domain,
                transferModal,
                cancelTransferModal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Transfer Website",
            "text": "Transfer ownership of this website to another account.",
            "transfer": "Transfer Ownership",
            "cancel": "Cancel Ownership Transfer"
        },
        "de": {
            "title": "Webseite übertragen",
            "text": "Übertrage das Eigentum dieser Webseite auf ein anderes Konto.",
            "transfer": "Eigentum übertragen",
            "cancel": "Eigentumsübertragung abbrechen"
        },
        "es": {
            "title": "Transferir sitio web",
            "text": "Transfiere la propiedad de este sitio web a otra cuenta.",
            "transfer": "Transferir propiedad",
            "cancel": "Cancelar transferencia de propiedad"
        },
        "fr": {
            "title": "Transférer le site web",
            "text": "Transférez la propriété de ce site web à un autre compte.",
            "transfer": "Transférer la propriété",
            "cancel": "Annuler le transfert de propriété"
        },
        "nl": {
            "title": "Website overdragen",
            "text": "Draag het eigendom van deze website over naar een ander account.",
            "transfer": "Eigendom overdragen",
            "cancel": "Eigendomsoverdracht annuleren"
        },
        "it": {
            "title": "Trasferisci sito web",
            "text": "Trasferisci la proprietà di questo sito web a un altro account.",
            "transfer": "Trasferisci proprietà",
            "cancel": "Annulla trasferimento di proprietà"
        },
        "pt": {
            "title": "Transferir site",
            "text": "Transfira a propriedade deste site para outra conta.",
            "transfer": "Transferir propriedade",
            "cancel": "Cancelar transferência de propriedade"
        },
        "ja": {
            "title": "ウェブサイトの移行",
            "text": "このウェブサイトの所有権を別のアカウントに移行します。",
            "transfer": "所有権を移行",
            "cancel": "所有権の移行をキャンセル"
        }
    }
</i18n>
