import {defineStore, storeToRefs} from "pinia";
import {onMounted, ref, watch} from "vue";
import {updateCache} from "@/components/panels/cache";
import {filterFields} from "@/components/panels/filter_fields";
import {useFilterStore} from "@/store/FilterStore";
import {Filter, FilterField} from "@/model/Filter";
import {useRouter} from "vue-router";

export const useFilterParamsStore = defineStore("filter_params", () => {
    const router = useRouter();
    const filterStore = useFilterStore();
    const {filter} = storeToRefs(filterStore);
    const {setField, removeField} = filterStore;
    const fields = ref<FilterField[]>([]);

    onMounted(() => {
        if (router.currentRoute.value.meta.loadStatistics) {
            updateFilter(filter.value, {});
        }
    });

    watch(filter, (f, old) => updateFilter(f, old));

    function updateFilter(f: Filter, old: Filter) {
        if (filterChanged(f.fields, old.fields)) {
            const f: FilterField[] = [];
            (filter.value.fields ?? []).forEach(field => f.push({field: field.field, value: field.value}));
            fields.value = f;
        }

        if (updateCache(old, f)) {
            filterFields.forEach((_, key) => localStorage.removeItem(`${key}_cache`));
        }
    }

    function filterChanged(newFields: FilterField[] | undefined, oldFields: FilterField[] | undefined) {
        if (newFields !== undefined && (oldFields === undefined || newFields.length !== oldFields.length)) {
            return true;
        }

        if (newFields !== undefined && oldFields !== undefined) {
            for (let i = 0; i < newFields.length; i++) {
                if (newFields[i].field !== oldFields[i].field) {
                    return true;
                }
            }
        }

        return false;
    }

    function add(field: FilterField) {
        fields.value.push(field);
    }

    function update(field: string, previousValue: string, value: string) {
        const f = fields.value.find(f => f.field === field && f.value === previousValue);

        if (f) {
            f.value = value;
            setField(field, value, previousValue);
        }
    }

    function remove(field: string, previousValue?: string) {
        fields.value = fields.value.filter(f =>  f.field !== field || f.value !== "");

        if (previousValue === undefined || previousValue !== "") {
            removeField(field, previousValue);
        }
    }

    return {
        fields,
        add,
        update,
        remove
    };
});
