<template>
    <button type="submit" :class="color" :disabled="disabled" v-on:click="click">
        <Loading :loading="loading" />
        <span>{{value}}</span>
    </button>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Loading from "@/components/bits/Loading.vue";

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            value: {type: String, required: true},
            color: String,
            disabled: {type: Boolean, default: false},
            loading: {type: Boolean, default: false}
        },
        setup(props) {
            function click(e: MouseEvent) {
                if (props.loading) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }

            return {
                click
            };
        }
    });
</script>
