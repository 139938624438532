<template>
    <div style="display: flex; justify-content: center; align-items: center; flex: 1;">
        <Loading :loading="loading" size="l" />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Loading from "@/components/bits/Loading.vue";

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            loading: {type: Boolean, required: true}
        }
    });
</script>
