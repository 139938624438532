<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {name: view?.name})}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {ViewRepo} from "@/repositories/ViewRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            view: Object
        },
        emits: ["close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {loadViews} = useFilterStore();
            const {success} = useToastStore();
            const {resetError, setError, err} = useError();
            const loading = ref(false);

            async function save() {
                try {
                    if (props.view) {
                        loading.value = true;
                        resetError();
                        await ViewRepo.delete(props.view.id);
                        await loadViews();
                        loading.value = false;
                        success(t("toast.success"));
                        emit("close");
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Delete View",
            "text": "Are you sure you would like to delete the view \"{name}\"?",
            "form.submit": "Delete",
            "toast.success": "The view has been deleted."
        },
        "de": {
            "title": "Ansicht löschen",
            "text": "Bist du sicher, dass du die Ansicht \"{name}\" löschen möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Die Ansicht wurde gelöscht."
        },
        "es": {
            "title": "Eliminar vista",
            "text": "¿Estás seguro de que deseas eliminar la vista \"{name}\"?",
            "form.submit": "Eliminar",
            "toast.success": "La vista ha sido eliminada."
        },
        "fr": {
            "title": "Supprimer la vue",
            "text": "Êtes-vous sûr de vouloir supprimer la vue \"{name}\" ?",
            "form.submit": "Supprimer",
            "toast.success": "La vue a été supprimée."
        },
        "nl": {
            "title": "Weergave verwijderen",
            "text": "Weet je zeker dat je de weergave \"{name}\" wilt verwijderen?",
            "form.submit": "Verwijderen",
            "toast.success": "De weergave is verwijderd."
        },
        "it": {
            "title": "Elimina visualizzazione",
            "text": "Sei sicuro di voler eliminare la visualizzazione \"{name}\"?",
            "form.submit": "Elimina",
            "toast.success": "La visualizzazione è stata eliminata."
        },
        "pt": {
            "title": "Excluir visualização",
            "text": "Tem certeza de que deseja excluir a visualização \"{name}\"?",
            "form.submit": "Excluir",
            "toast.success": "A visualização foi excluída."
        },
        "ja": {
            "title": "ビューを削除",
            "text": "ビュー \"{name}\" を削除してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "ビューが削除されました。"
        }
    }
</i18n>
