<template>
    <div :class="{'action': true, 'active': comparisonDropdown}" v-on:click="toggleComparisonDropdown" ref="comparisonDropdownElement">
        <i class="icon icon-compare"></i>
        <span>{{comparisonOptions[index].label}}</span>
        <i :class="{'icon icon-expand': true, 'icon-rotate-180': comparisonDropdown}"></i>
        <transition name="fade-down">
            <ul class="dropdown right" ref="scrollArea" v-show="comparisonDropdown">
                <template v-for="(o, i) in comparisonOptions" :key="o.value">
                    <li :class="{selected: active === i}" v-on:click="e => setComparison(e, i)">
                        <span>
                            {{o.label}}
                        </span>
                        <span class="shortcut" v-if="o.shortcut.length">
                            {{o.shortcut[0]}}
                        </span>
                    </li>
                </template>
                <hr />
                <FormCheckbox name="matchDay"
                    :label="t('day_of_week')"
                    v-model="matchDay"
                    :toggle="true"
                    v-on:click.stop />
                <template v-if="index === comparisonDatesIndex">
                    <hr />
                    <FormInput :label="t('start_date')"
                        name="start"
                        type="date"
                        v-model="startDate"
                        v-on:click.stop
                        v-on:change="setStartDate" />
                    <FormInput :label="t('end_date')"
                        name="end"
                        type="date"
                        v-model="endDate"
                        v-on:click.stop
                        v-on:change="setEndDate" />
                </template>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, onUnmounted, computed, watch} from "vue";
    import {useDropdown} from "@/components/dropdown";
    import FormInput from "@/components/form/FormInput.vue";
    import {debounce} from "@/util/debounce";
    import {useSelect} from "@/components/panels/select";
    import {comparisonDatesIndex, comparisonOptions} from "@/components/panels/comparison_options";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useMobile} from "@/components/mobile";
    import {storeToRefs} from "pinia";
    import {dateFromParam, dateParam} from "@/util/format";
    import {fromMin} from "@/components/panels/date";
    import {getDiffDays, getToday} from "@/util/date";
    import {Comparison} from "@/model/Comparison";
    import {useFilterStore} from "@/store/FilterStore";
    import dayjs from "dayjs";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormInput,
            FormCheckbox
        },
        setup() {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {setComparison: set} = filterStore;
            const {comparison} = storeToRefs(filterStore);
            const {filter} = storeToRefs(useFilterStore());
            const {dropdown: comparisonDropdown, dropdownElement: comparisonDropdownElement, toggleDropdown: toggleComparisonDropdown} = useDropdown();
            const comparisonOptionsRef = ref(comparisonOptions);
            const {active, scrollArea} = useSelect(comparisonDropdown, comparisonOptionsRef, selectComparison);
            const matchDay = ref(true);
            const startDate = ref("");
            const endDate = ref("");
            const today = computed(() => getToday());
            const index = computed(() => {
                if (comparison.value && comparison.value.compare) {
                    const index = comparisonOptions.findIndex(o => o.value === comparison.value.compare);

                    if (index >= 0) {
                        return index;
                    }
                }

                return 0;
            });
            const options = computed(() => {
                const options = comparisonOptions.map(e => ({...e}));

                for (let i = 0; i < options.length; i++) {
                    options[i].label = t("options.period."+options[i].label);
                }

                return options;
            });
            let hidden = false;
            let hiddenTimeout: number;

            onMounted(() => {
                updateParameter(comparison.value);
                active.value = index.value;
                window.addEventListener("focus", windowFocus);
                window.addEventListener("blur", windowBlur);
                addKeyboardShortcuts();
            });

            onUnmounted(() => {
                document.removeEventListener("focus", windowFocus);
                document.removeEventListener("blur", windowBlur);
                document.removeEventListener("keydown", keydown);
            });

            watch(comparison, updateParameter);

            watch(matchDay, value => {
                set({
                    compareWeekday: value
                });
            });

            watch(index, value => {
                active.value = value;
            });

            const setStartDate = debounce((date: string) => {
                setDates(date, endDate.value);
            }, 300);

            const setEndDate = debounce((date: string) => {
                setDates(startDate.value, date);
            }, 300);

            function setDates(from: string, to: string) {
                let fromDate = dateFromParam(from);
                let toDate = dateFromParam(to);

                if (fromDate === null || toDate === null) {
                    return;
                }

                if (filter.value.timeRange) {
                    if (fromDate >= filter.value.timeRange.from) {
                        fromDate = dayjs(filter.value.timeRange.from).add(-1, "day").toDate();
                    }

                    if (toDate >= filter.value.timeRange.from) {
                        toDate = dayjs(filter.value.timeRange.from).add(-1, "day").toDate();
                    }

                    const filterDiff = getDiffDays(filter.value.timeRange.from, filter.value.timeRange.to);
                    const diff = getDiffDays(fromDate, toDate);

                    if (diff > filterDiff) {
                        fromDate = dayjs(toDate).add(-filterDiff, "days").toDate();
                    }
                }

                if (fromDate > toDate) {
                    const tmp = startDate.value;
                    startDate.value = endDate.value;
                    endDate.value = tmp;

                    const tmpDate = fromDate;
                    fromDate = toDate;
                    toDate = tmpDate;
                }

                if (fromMin.isAfter(fromDate)) {
                    fromDate = fromMin.toDate();
                    startDate.value = dateParam(fromDate);
                }

                if (toDate > today.value) {
                    toDate = today.value;
                    endDate.value = dateParam(today.value);
                }

                set({
                    compareRange: {
                        from: fromDate,
                        to: toDate
                    }
                });
            }

            function updateParameter(c: Comparison) {
                matchDay.value = c.compareWeekday === true || c.compareWeekday === undefined;
                startDate.value = c.compareRange ? dateParam(c.compareRange.from) : "";
                endDate.value = c.compareRange ? dateParam(c.compareRange.to) : "";
            }

            function addKeyboardShortcuts() {
                document.addEventListener("keyup", keydown);
            }

            const keydown = debounce((e: KeyboardEvent) => {
                if (hidden || e.target !== document.body || e.altKey || e.ctrlKey ||  e.shiftKey || e.metaKey || e.key === "Alt") {
                    return;
                }

                for (let i = 0; i < comparisonOptions.length; i++) {
                    if (comparisonOptions[i].shortcut.includes(e.code.substring(3, 4))) {
                        set({
                            compare: comparisonOptions[i].value
                        });
                        break;
                    }
                }
            }, 200);

            function windowFocus() {
                hiddenTimeout = setTimeout(() => hidden = false, 500) as unknown as number;
            }

            function windowBlur() {
                clearTimeout(hiddenTimeout);
                hidden = true;
            }

            function setComparison(e: Event, i: number) {
                selectComparison(i, false);

                if (index.value === comparisonDatesIndex) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }

            function selectComparison(active: number, toggle: boolean = true) {
                set({
                    compare: comparisonOptions[active].value
                });

                if (toggle && index.value !== comparisonDatesIndex) {
                    toggleComparisonDropdown();
                }
            }

            return {
                t,
                ...useMobile(),
                comparisonDropdown,
                comparisonDropdownElement,
                toggleComparisonDropdown,
                active,
                scrollArea,
                comparisonDatesIndex,
                comparisonOptions: options,
                index,
                matchDay,
                startDate,
                endDate,
                setStartDate,
                setEndDate,
                setComparison
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "day_of_week": "Match Day of Week",
            "start_date": "Start Date",
            "end_date": "End Date",
            "options.period.Disabled": "Disabled",
            "options.period.Previous Period": "Previous Period",
            "options.period.Year Over Year": "Year Over Year",
            "options.period.Custom Period": "Custom Period"
        },
        "de": {
            "day_of_week": "Wochentag abgleichen",
            "start_date": "Startdatum",
            "end_date": "Enddatum",
            "options.period.Disabled": "Deaktiviert",
            "options.period.Previous Period": "Vorheriger Zeitraum",
            "options.period.Year Over Year": "Jahr über Jahr",
            "options.period.Custom Period": "Benutzerdefinierter Zeitraum"
        },
        "es": {
            "day_of_week": "Coincidir con el Día de la Semana",
            "start_date": "Fecha de Inicio",
            "end_date": "Fecha de Finalización",
            "options.period.Disabled": "Deshabilitado",
            "options.period.Previous Period": "Período Anterior",
            "options.period.Year Over Year": "Año sobre Año",
            "options.period.Custom Period": "Período Personalizado"
        },
        "fr": {
            "day_of_week": "Faire Correspondre le Jour de la Semaine",
            "start_date": "Date de Début",
            "end_date": "Date de Fin",
            "options.period.Disabled": "Désactivé",
            "options.period.Previous Period": "Période Précédente",
            "options.period.Year Over Year": "D'une Année à l'Autre",
            "options.period.Custom Period": "Période Personnalisée"
        },
        "nl": {
            "day_of_week": "Dag van de Week Overeenkomen",
            "start_date": "Startdatum",
            "end_date": "Einddatum",
            "options.period.Disabled": "Uitgeschakeld",
            "options.period.Previous Period": "Vorige Periode",
            "options.period.Year Over Year": "Jaar op Jaar",
            "options.period.Custom Period": "Aangepaste Periode"
        },
        "it": {
            "day_of_week": "Corrispondere al Giorno della Settimana",
            "start_date": "Data di Inizio",
            "end_date": "Data di Fine",
            "options.period.Disabled": "Disabilitato",
            "options.period.Previous Period": "Periodo Precedente",
            "options.period.Year Over Year": "Anno su Anno",
            "options.period.Custom Period": "Periodo Personalizzato"
        },
        "pt": {
            "day_of_week": "Coincidir com o Dia da Semana",
            "start_date": "Data de Início",
            "end_date": "Data de Término",
            "options.period.Disabled": "Desativado",
            "options.period.Previous Period": "Período Anterior",
            "options.period.Year Over Year": "Ano sobre Ano",
            "options.period.Custom Period": "Período Personalizado"
        },
        "ja": {
            "day_of_week": "曜日を一致させる",
            "start_date": "開始日",
            "end_date": "終了日",
            "options.period.Disabled": "無効",
            "options.period.Previous Period": "前期間",
            "options.period.Year Over Year": "前年同期比",
            "options.period.Custom Period": "カスタム期間"
        }
    }
</i18n>
