<template>
    <Banner color="green" v-on:dismiss="dismiss" v-if="loggedIn && show">
        {{t("text")}}
    </Banner>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, watch} from "vue";
    import Banner from "@/components/bits/Banner.vue";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Banner
        },
        setup() {
            const {loggedIn} = storeToRefs(useUserStore());
            const {created} = storeToRefs(useDomainStore());
            const show = ref(false);

            onMounted(setCreated);
            watch(created, setCreated);

            function setCreated() {
                const date = localStorage.getItem("domain_created") || "";

                if (date) {
                    const d = new Date(date);

                    if (d.getTime() > new Date().getTime()) {
                        show.value = true;
                    } else {
                        localStorage.removeItem("domain_created");
                    }
                }
            }

            function dismiss() {
                localStorage.removeItem("domain_created");
                show.value = false;
            }

            return {
                ...useI18n(),
                loggedIn,
                show,
                dismiss
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "text": "It can take a few seconds before data is collected for your website after you have integrated Pirsch."
        },
        "de": {
            "text": "Es kann einige Sekunden dauern, bis Daten für deine Website gesammelt werden, nachdem du Pirsch integriert hast."
        },
        "es": {
            "text": "Puede tardar unos segundos en recopilar datos para tu sitio web después de haber integrado Pirsch."
        },
        "fr": {
            "text": "Cela peut prendre quelques secondes avant que les données ne soient collectées pour votre site web après avoir intégré Pirsch."
        },
        "nl": {
            "text": "Het kan enkele seconden duren voordat er gegevens worden verzameld voor je website nadat je Pirsch hebt geïntegreerd."
        },
        "it": {
            "text": "Può volerci qualche secondo prima che i dati vengano raccolti per il tuo sito web dopo aver integrato Pirsch."
        },
        "pt": {
            "text": "Pode levar alguns segundos para que os dados sejam coletados para o seu site após você ter integrado o Pirsch."
        },
        "ja": {
            "text": "Pirschを統合した後、ウェブサイトのデータが収集されるまでに数秒かかることがあります。"
        }
    }
</i18n>
