<template>
    <TableEntry :columns="columns" :entry="entry" :entries="entries" v-on:details="load(0)">
        <ListTabs :tabs="tabs" v-on:open="load" v-if="tabs.length" />
        <TableEntries :columns="metadataColumns"
            :entries="data"
            :loading="loading"
            :sortable="true"
            :selectable="true"
            :showBar="true"
            v-on:filter="filterByMetadata" />
    </TableEntry>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref, watch} from "vue";
    import TableEntry from "@/components/panels/TableEntry.vue";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {useError} from "@/components/error";
    import {EventStats} from "@/model/EventStats";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import TableEntries from "@/components/panels/TableEntries.vue";
    import {formatAndShortenNumber, formatPercent, formatSeconds} from "@/util/format";
    import {APIError} from "@/repositories/APIError";
    import {ListEntry} from "@/model/ListEntry";
    import {useToastStore} from "@/store/ToastStore";
    import {setField, useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {Column} from "@/components/panels/Column";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            TableEntries,
            ListTabs,
            TableEntry
        },
        props: {
            columns: {type: Array as PropType<Column[]>, required: true},
            entry: {type: Object as PropType<ListEntry>, required: true},
            entries: {type: Number, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {error} = useToastStore();
            const {filter} = storeToRefs(filterStore);
            const {copy, setField: setFilterField} = filterStore;
            const {setError} = useError();
            const loading = ref(true);
            const metadata = ref<Map<string, EventStats[]>>(new Map);
            const activeKey = ref("");
            const tabs = computed(() => {
                const tabs = [];

                if (props.entry.meta_keys) {
                    for (let i = 0; i < props.entry.meta_keys.length; i++) {
                        tabs.push({title: props.entry.meta_keys[i]});
                    }
                }

                return tabs;
            });
            const data = computed(() => {
                const meta = metadata.value.get(activeKey.value);
                const data = [];

                if (meta) {
                    for (let i = 0; i < meta.length; i++) {
                        data.push({
                            key: meta[i].meta_value,
                            label: meta[i].meta_value,
                            count: meta[i].count,
                            value: meta[i].visitors,
                            views: meta[i].views,
                            relative_value: meta[i].cr,
                            average_duration_seconds: meta[i].average_duration_seconds,
                            meta_keys: meta[i].meta_keys,
                            filter: activeKey.value
                        });
                    }
                }

                return data;
            });
            const metadataColumns = computed(() => [
                {key: "label", label: t("columns.value"), order_by: "label"},
                {key: "count", label: t("columns.events"), transform: (v: number) => formatAndShortenNumber(v), order_by: "count"},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.cr"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_value"},
                {key: "average_duration_seconds", label: t("columns.duration"), transform: (v: number) => formatSeconds(v), order_by: "-"}
            ]);
            let activeTab = 0;

            watch(tabs, () => {
                metadata.value = new Map;
                load(activeTab);
            });

            async function load(tab: number) {
                if (tabs.value.length) {
                    if (tab > tabs.value.length-1) {
                        tab = 0;
                    }

                    activeTab = tab;
                    loading.value = true;
                    const key = tabs.value[tab].title;

                    try {
                        if (!metadata.value.has(key) && tabs.value.length) {
                            const f = copy(filter.value);
                            setField(f, "event_name", props.entry.label);
                            setField(f, "event_meta_key", key);
                            f.limit = 0;
                            metadata.value.set(key, await StatisticsRepo.eventMetadata(f) as EventStats[] || []);
                        }

                        loading.value = false;
                        activeKey.value = key;
                    } catch (e) {
                        loading.value = false;
                        metadata.value.set(key, []);
                        setError(e as APIError);
                        error(e as APIError);
                    }
                } else {
                    activeTab = 0;
                    loading.value = false;
                }
            }

            function filterByMetadata(entry: ListEntry) {
                if (!filter.value.fields?.find(value => value.field === "event" && value.value === props.entry.label)) {
                    setFilterField("event", props.entry.label);
                }

                setFilterField(`meta_${entry.filter}`, entry.label);
            }

            return {
                metadataColumns,
                loading,
                tabs,
                data,
                load,
                filterByMetadata
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "columns.value": "Value",
            "columns.events": "Events",
            "columns.visitors": "Visitors",
            "columns.cr": "CR %",
            "columns.duration": "Duration"
        },
        "de": {
            "columns.value": "Wert",
            "columns.events": "Events",
            "columns.visitors": "Besucher",
            "columns.cr": "CR %",
            "columns.duration": "Dauer"
        },
        "es": {
            "columns.value": "Valor",
            "columns.events": "Eventos",
            "columns.visitors": "Visitantes",
            "columns.cr": "CR %",
            "columns.duration": "Duración"
        },
        "fr": {
            "columns.value": "Valeur",
            "columns.events": "Événements",
            "columns.visitors": "Visiteurs",
            "columns.cr": "CR %",
            "columns.duration": "Durée"
        },
        "nl": {
            "columns.value": "Waarde",
            "columns.events": "Gebeurtenissen",
            "columns.visitors": "Bezoekers",
            "columns.cr": "CR %",
            "columns.duration": "Duur"
        },
        "it": {
            "columns.value": "Valore",
            "columns.events": "Eventi",
            "columns.visitors": "Visitatori",
            "columns.cr": "CR %",
            "columns.duration": "Durata"
        },
        "pt": {
            "columns.value": "Valor",
            "columns.events": "Eventos",
            "columns.visitors": "Visitantes",
            "columns.cr": "CR %",
            "columns.duration": "Duração"
        },
        "ja": {
            "columns.value": "値",
            "columns.events": "イベント",
            "columns.visitors": "訪問者",
            "columns.cr": "CR %",
            "columns.duration": "期間"
        }
    }
</i18n>
