import {isDashboard} from "@/util/domain";
import {User} from "@/model/User";
import {prefersDarkMode, setFullWidth} from "@/util/style";
import Cookies from "js-cookie";
import {useRouter} from "vue-router";
import {useUserStore} from "@/store/UserStore";
import {useDomainStore} from "@/store/DomainStore";
import {useStatisticsStore} from "@/store/StatisticsStore";
import {useFilterStore} from "@/store/FilterStore";
import {useOrganizationStore} from "@/store/OrganizationStore";
import {useThemeStore} from "@/store/ThemeStore";
import {useI18n} from "vue-i18n";
import {getQueryParam} from "@/util/url";

interface Init {
    init(): void
    clear(): void
}

export function useInit(): Init {
    const router = useRouter();
    const userStore = useUserStore();
    const domainStore = useDomainStore();
    const statisticsStore = useStatisticsStore();
    const filterStore = useFilterStore();
    const organizationStore = useOrganizationStore();
    const themeStore = useThemeStore();
    const {locale} = useI18n();
    let updateRealTime: number;

    async function init() {
        await router.isReady();
        const isDashboardDomain = isDashboard();
        let user: User | null = null;
        const mode = localStorage.getItem("color_mode");
        let darkMode = mode !== null ? mode === "dark" : prefersDarkMode() ? true : null;
        let fullWidth = false;

        if (Cookies.get("refresh_token") || isDashboardDomain) {
            user = await userStore.load();

            if (user) {
                darkMode = mode === null ? user.dark_mode : darkMode;
                fullWidth = user.dashboard_full_width;
                await organizationStore.load();
                await themeStore.load();
            } else if (isDashboardDomain || router.currentRoute.value.meta.requiresAuth) {
                await userStore.logout();
                return;
            }
        }

        setLanguage(user);
        themeStore.setDarkMode(darkMode !== null ? darkMode.toString() : "");
        setFullWidth(fullWidth);
        await domainStore.load();
        void filterStore.loadViews();
        filterStore.init();

        if (!updateRealTime) {
            updateRealTime = setInterval(async () => {
                await statisticsStore.loadActiveVisitors();
            }, 30000) as unknown as number;
        }
    }

    function clear() {
        if (updateRealTime) {
            clearInterval(updateRealTime);
        }
    }

    function setLanguage(user: User | null) {
        // use the browser language by default
        const parts = navigator.language.split("-");
        let language = parts[0];

        // overwrite from localStorage
        const savedLanguage = localStorage.getItem("lang");

        if (savedLanguage) {
            language = savedLanguage;
        }

        // overwrite from query parameter and set in localStorage
        const queryLanguage = getQueryParam("lang");

        if (queryLanguage) {
            language = queryLanguage.toLowerCase();
            localStorage.setItem("lang", language);
        }

        // overwrite if logged in and the user has configured a language
        if (user && user.language) {
            language = user.language;
        }

        locale.value = language.toLowerCase();
    }

    return {
        init,
        clear
    };
}
