<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {description: link.description})}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            link: {type: Object, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await DomainRepo.deleteAccessLink(props.link.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text": "Are you sure you want to delete the access link with description \"{description}\"?",
            "form.submit": "Delete",
            "toast.success": "The access link has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text": "Bist du sicher, dass du den Zugriffslink mit der Beschreibung \"{description}\" löschen möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Der Zugriffslink wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text": "¿Estás seguro de que deseas eliminar el enlace de acceso con la descripción \"{description}\"?",
            "form.submit": "Eliminar",
            "toast.success": "El enlace de acceso ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text": "Êtes-vous sûr de vouloir supprimer le lien d'accès avec la description \"{description}\" ?",
            "form.submit": "Supprimer",
            "toast.success": "Le lien d'accès a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text": "Weet je zeker dat je de toegangskoppeling met de beschrijving \"{description}\" wilt verwijderen?",
            "form.submit": "Verwijderen",
            "toast.success": "De toegangskoppeling is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text": "Sei sicuro di voler eliminare il link di accesso con la descrizione \"{description}\"?",
            "form.submit": "Elimina",
            "toast.success": "Il link di accesso è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text": "Tem certeza de que deseja excluir o link de acesso com a descrição \"{description}\"?",
            "form.submit": "Excluir",
            "toast.success": "O link de acesso foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text": "説明が「{description}」のアクセスリンクを削除してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "アクセスリンクが削除されました。"
        }
    }
</i18n>
