<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" :autofocus="true" v-model="name" :err="validationError('name')" />
            <FormSubmit :value="t('form.duplicate')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {ThemeRepo} from "@/repositories/ThemeRepo";
    import {Theme} from "@/model/Theme";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            theme: {type: Object as PropType<Theme>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);
            const name = ref(props.theme.name);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const theme = await ThemeRepo.createUpdate("", props.theme.id,
                        props.theme.organization_id ?? "", name.value, props.theme.settings) as Theme;
                    success(t("toast.success"));
                    name.value = "";
                    loading.value = false;
                    emit("action", theme);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                name,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Duplicate Theme",
            "form.name": "Name",
            "form.duplicate": "Duplicate Theme",
            "toast.success": "Theme duplicated."
        },
        "de": {
            "title": "Thema duplizieren",
            "form.name": "Name",
            "form.duplicate": "Thema duplizieren",
            "toast.success": "Thema dupliziert."
        },
        "es": {
            "title": "Duplicar tema",
            "form.name": "Nombre",
            "form.duplicate": "Duplicar tema",
            "toast.success": "Tema duplicado."
        },
        "fr": {
            "title": "Dupliquer le thème",
            "form.name": "Nom",
            "form.duplicate": "Dupliquer le thème",
            "toast.success": "Thème dupliqué."
        },
        "nl": {
            "title": "Thema dupliceren",
            "form.name": "Naam",
            "form.duplicate": "Thema dupliceren",
            "toast.success": "Thema gedupliceerd."
        },
        "it": {
            "title": "Duplica tema",
            "form.name": "Nome",
            "form.duplicate": "Duplica tema",
            "toast.success": "Tema duplicato."
        },
        "pt": {
            "title": "Duplicar tema",
            "form.name": "Nome",
            "form.duplicate": "Duplicar tema",
            "toast.success": "Tema duplicado."
        },
        "ja": {
            "title": "テーマを複製",
            "form.name": "名前",
            "form.duplicate": "テーマを複製",
            "toast.success": "テーマが複製されました。"
        }
    }
</i18n>
