<template>
    <div class="card">
        <div class="text">
            <p>
                <strong>{{invitation.domain?.hostname ? t("dashboard")+invitation.domain.hostname : t("organization")+invitation.organization?.name}}</strong>
            </p>
        </div>
        <div class="row">
            <i class="icon icon-check clickable" :title="t('accept')" v-on:click="accept" />
            <i class="icon icon-close clickable" :title="t('decline')" v-on:click="decline" />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from "vue";
    import {useError} from "@/components/error";
    import {InvitationRepo} from "@/repositories/InvitationRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {Invitation} from "@/model/Invitation";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            invitation: {type: Object as PropType<Invitation>, required: true}
        },
        emits: ["accept", "remove"],
        setup(props, {emit}) {
            const {setError} = useError();
            const {error} = useToastStore();

            async function accept() {
                try {
                    await InvitationRepo.accept(props.invitation.id);
                    emit("accept");
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            async function decline() {
                try {
                    await InvitationRepo.delete(props.invitation.id);
                    emit("remove");
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                ...useI18n(),
                accept,
                decline
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "accept": "Accept Invitation",
            "decline": "Decline Invitation",
            "dashboard": "Dashboard: ",
            "organization": "Organization: "
        },
        "de": {
            "accept": "Einladung annehmen",
            "decline": "Einladung ablehnen",
            "dashboard": "Dashboard: ",
            "organization": "Organisation: "
        },
        "es": {
            "accept": "Aceptar invitación",
            "decline": "Rechazar invitación",
            "dashboard": "Panel de control: ",
            "organization": "Organización: "
        },
        "fr": {
            "accept": "Accepter l'invitation",
            "decline": "Refuser l'invitation",
            "dashboard": "Tableau de bord: ",
            "organization": "Organisation: "
        },
        "nl": {
            "accept": "Uitnodiging accepteren",
            "decline": "Uitnodiging weigeren",
            "dashboard": "Dashboard: ",
            "organization": "Organisatie: "
        },
        "it": {
            "accept": "Accetta l'invito",
            "decline": "Rifiuta l'invito",
            "dashboard": "Cruscotto: ",
            "organization": "Organizzazione: "
        },
        "pt": {
            "accept": "Aceitar convite",
            "decline": "Recusar convite",
            "dashboard": "Painel de controle: ",
            "organization": "Organização: "
        },
        "ja": {
            "accept": "招待を受ける",
            "decline": "招待を辞退する",
            "dashboard": "ダッシュボード: ",
            "organization": "組織: "
        }
    }
</i18n>
