import {APIError} from "@/repositories/APIError";
import {Repository} from "@/repositories/Repository";
import {Funnel} from "@/model/Funnel";
import {getAccessCode} from "@/util/url";
import {FunnelFilter} from "@/model/FunnelFilter";

export interface FunnelStepData {
    name: string
    filter: FunnelFilter
}

export interface FunnelData {
    domain_id: string
    id?: string
    name: string
    steps: FunnelStepData[]
}

export const FunnelRepo = new class extends Repository {
    async list(id: string): Promise<Funnel[] | APIError> {
        return this.performGet<Funnel[]>("/funnel", {id, access: getAccessCode()}, []);
    }

    async save(data: FunnelData): Promise<Funnel | APIError> {
        return this.performPost<Funnel>("/funnel", {...data});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/funnel", {id});
    }
}
