<template>
    <Modal size="xl" :title="t('title')" :open="open">
        <template v-slot:top>
            <ChartType v-on:switch="setChartType" />
        </template>
        <Chart :title="t('title')"
            :labels="labels"
            :data="data"
            :type="chartType"
            :loading="loading"
            :growth="growth"
            :formatYAxis="formatAxis"
            :formatValue="formatValue" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, computed} from "vue";
    import {useData} from "@/components/panels/data";
    import {formatAndShortenNumber, formatNumber} from "@/util/format";
    import Modal from "@/components/modal/Modal.vue";
    import Chart from "@/components/panels/Chart.vue";
    import ChartType from "@/components/panels/ChartType.vue";
    import {useChartStore} from "@/store/ChartStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            Chart,
            ChartType
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup() {
            const statisticsStore = useStatisticsStore();
            const {dayFilter} = storeToRefs(useFilterStore());
            const chartStore = useChartStore();
            const {set: setChartType} = chartStore;
            const {type: chartType} = storeToRefs(chartStore);
            const {visitorsByHour, visitors, growth} = storeToRefs(statisticsStore);
            const data = computed(() => dayFilter.value ? visitorsByHour.value : visitors.value);
            const loading = computed(() => statisticsStore.isLoading(dayFilter.value ? "visitors_by_hour" : "visitors"));
            const sourceField = computed(() => "sessions");

            return {
                ...useI18n(),
                ...useData(data, sourceField, dayFilter),
                setChartType,
                chartType,
                formatAxis: formatAndShortenNumber,
                formatValue: formatNumber,
                growth: computed(() => growth.value?.sessions_growth || 0),
                loading
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Sessions"
        },
        "de": {
            "title": "Sitzungen"
        },
        "es": {
            "title": "Sesiones"
        },
        "fr": {
            "title": "Sessions"
        },
        "nl": {
            "title": "Sessies"
        },
        "it": {
            "title": "Sessioni"
        },
        "pt": {
            "title": "Sessões"
        },
        "ja": {
            "title": "セッション"
        }
    }
</i18n>
