import {APIError} from "@/repositories/APIError";
import {Repository} from "@/repositories/Repository";
import {Theme} from "@/model/Theme";
import {KeyValue} from "@/model/KeyValue";
import {ThemeSettings} from "@/store/theme";

export const ThemeRepo = new class extends Repository {
    async list(organization_id?: string, include_organizations = true): Promise<Theme[] | APIError> {
        return this.performGet<Theme[]>("/theme", {organization_id, include_organizations}, [], true);
    }

    async get(subdomain: string, domain: string): Promise<KeyValue | null | APIError> {
        return this.performGet<KeyValue | null>("/theme", {subdomain, domain}, null);
    }

    async createUpdate(id: string, copy_id: string, organization_id: string, name: string, settings: KeyValue, logoLight?: File, logoDark?: File, favicon?: File): Promise<Theme | APIError> {
        const form = new FormData();

        if (logoLight) {
            form.append(ThemeSettings.logoLight, logoLight);
        }

        if (logoDark) {
            form.append(ThemeSettings.logoDark, logoDark);
        }

        if (favicon) {
            form.append(ThemeSettings.favicon, favicon);
        }

        form.append("data", JSON.stringify({id, copy_id, organization_id, name, settings}));
        return this.performPost<Theme>("/theme", form);
    }

    async delete(id: string, keep_settings: boolean): Promise<APIError | null> {
        return this.performDelete("/theme", {id, keep_settings});
    }

    async deleteFile(domain_id: string, theme_id: string, file: string): Promise<APIError | null> {
        return this.performDelete("/theme/file", {domain_id, theme_id, file});
    }
}
