<template>
    <router-link to="/add" class="overview-card add-card" v-if="!user.read_only">
        <i class="icon icon-add" />
        <strong>{{t("link")}}</strong>
    </router-link>
</template>

<script lang="ts">
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {defineComponent} from "vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            const {user} = storeToRefs(useUserStore());

            return {
                ...useI18n(),
                user
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "link": "Add Website"
        },
        "de": {
            "link": "Webseite hinzufügen"
        },
        "es": {
            "link": "Añadir sitio web"
        },
        "fr": {
            "link": "Ajouter un site web"
        },
        "nl": {
            "link": "Website toevoegen"
        },
        "it": {
            "link": "Aggiungi sito web"
        },
        "pt": {
            "link": "Adicionar site"
        },
        "ja": {
            "link": "ウェブサイトを追加"
        }
    }
</i18n>
