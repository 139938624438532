<template>
    <CreateEditWebhook :open="createWebhookModal"
        v-on:close="createWebhookModal = false"
        v-on:action="addWebhook" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/webhooks" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <template v-if="hasPlus">
                <Expand>
                    <div v-if="!loading">
                        <WebhookCard v-for="webhook in webhooks"
                            :key="webhook.id"
                            :webhook="webhook"
                            v-on:update="updateWebhook"
                            v-on:remove="removeWebhook" />
                    </div>
                </Expand>
                <button v-on:click="createWebhookModal = true">
                    <Loading :loading="loading" />
                    <span>{{t("add")}}</span>
                </button>
            </template>
            <PirschPlus>
                <strong>{{t("plus")}}</strong>
            </PirschPlus>
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watchEffect} from "vue";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {Webhook} from "@/model/Webhook";
    import {WebhookRepo} from "@/repositories/WebhookRepo";
    import CreateEditWebhook from "@/components/modal/CreateEditWebhook.vue";
    import WebhookCard from "@/components/cards/WebhookCard.vue";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            PirschPlus,
            WebhookCard,
            CreateEditWebhook,
            Loading,
            Expand
        },
        setup() {
            const {hasPlus} = storeToRefs(useUserStore());
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const createWebhookModal = ref(false);
            const loading = ref(true);
            const webhooks = ref<Webhook[]>([]);
            const domainID = computed(() => domain.value.id);

            watchEffect(async () => {
                loading.value = true;
                webhooks.value = await WebhookRepo.list(domain.value.id) as Webhook[] || [];
                loading.value = false;
            });

            function addWebhook(webhook: Webhook) {
                createWebhookModal.value = false;
                webhooks.value.push(webhook);
            }

            function updateWebhook(webhook: Webhook) {
                updateEntryByID(webhooks.value, webhook);
            }

            function removeWebhook(webhook: Webhook) {
                removeEntryByID(webhooks.value, webhook);
            }

            return {
                ...useI18n(),
                hasPlus,
                createWebhookModal,
                loading,
                webhooks,
                domainID,
                addWebhook,
                updateWebhook,
                removeWebhook
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Webhooks",
            "text": "Webhooks are called when a specific event is triggered on your website. You can use it to automate processes or get notified about changes.",
            "link": "Read the Docs",
            "add": "Add Webhook",
            "plus": "Webhooks require a Pirsch Plus subscription."
        },
        "de": {
            "title": "Webhooks",
            "text": "Webhooks werden aufgerufen, wenn ein bestimmtes Event auf deiner Webseite ausgelöst wird. Du kannst sie verwenden, um Prozesse zu automatisieren oder über Änderungen benachrichtigt zu werden.",
            "link": "Dokumentation lesen",
            "add": "Webhook hinzufügen",
            "plus": "Webhooks erfordern ein Pirsch Plus-Abonnement."
        },
        "es": {
            "title": "Webhooks",
            "text": "Los webhooks se activan cuando se desencadena un evento específico en tu sitio web. Puedes usarlos para automatizar procesos o recibir notificaciones sobre cambios.",
            "link": "Leer la documentación",
            "add": "Agregar webhook",
            "plus": "Los webhooks requieren una suscripción a Pirsch Plus."
        },
        "fr": {
            "title": "Webhooks",
            "text": "Les webhooks sont appelés lorsqu'un événement spécifique est déclenché sur votre site web. Vous pouvez les utiliser pour automatiser des processus ou être notifié des changements.",
            "link": "Lire la documentation",
            "add": "Ajouter un webhook",
            "plus": "Les webhooks nécessitent un abonnement à Pirsch Plus."
        },
        "nl": {
            "title": "Webhooks",
            "text": "Webhooks worden aangeroepen wanneer een specifiek evenement op je website wordt geactiveerd. Je kunt ze gebruiken om processen te automatiseren of op de hoogte te worden gebracht van wijzigingen.",
            "link": "Lees de documentatie",
            "add": "Webhook toevoegen",
            "plus": "Webhooks vereisen een Pirsch Plus-abonnement."
        },
        "it": {
            "title": "Webhooks",
            "text": "I webhooks vengono chiamati quando viene attivato un evento specifico sul tuo sito web. Puoi usarli per automatizzare processi o ricevere notifiche sui cambiamenti.",
            "link": "Leggi la documentazione",
            "add": "Aggiungi webhook",
            "plus": "I webhooks richiedono un abbonamento a Pirsch Plus."
        },
        "pt": {
            "title": "Webhooks",
            "text": "Os webhooks são chamados quando um evento específico é acionado em seu site. Você pode usá-los para automatizar processos ou ser notificado sobre alterações.",
            "link": "Leia a documentação",
            "add": "Adicionar webhook",
            "plus": "Os webhooks requerem uma assinatura Pirsch Plus."
        },
        "ja": {
            "title": "Webhook",
            "text": "Webhookは、ウェブサイトで特定のイベントが発生したときに呼び出されます。プロセスを自動化したり、変更について通知を受け取るために使用できます。",
            "link": "ドキュメントを読む",
            "add": "Webhookを追加",
            "plus": "Webhookの利用にはPirsch Plusサブスクリプションが必要です。"
        }
    }
</i18n>
