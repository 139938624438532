<template>
    <div :class="{metric: true, active: active, green: color === 'green', yellow: color === 'yellow', red: color === 'red', clickable}">
        <div style="margin-bottom: 8px;">
            <h2>{{title}}</h2>
            <Tooltip :content="tooltip" v-if="tooltip" />
        </div>
        <div>
            <div class="sum" :title="formatNumber(sum)">{{formatValue(sum)}}</div>
            <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 4px;">
                <GrowthArrow :growth="growth" :arrowUpGreen="arrowUpGreen" :liveFilter="liveFilter" />
                <div class="previous" :title="formatNumber(previousSum)" v-if="previousSum !== undefined">{{formatValue(previousSum)}}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import {formatAndShortenNumber, formatNumber, formatPercent} from "@/util/format";
    import Tooltip from "@/components/bits/Tooltip.vue";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import GrowthArrow from "@/components/panels/GrowthArrow.vue";

    export default defineComponent({
        components: {
            GrowthArrow,
            Tooltip
        },
        props: {
            active: {type: Boolean, default: false},
            clickable: {type: Boolean, default: true},
            title: {type: String, required: true},
            tooltip: String,
            sum: {type: Number, required: true},
            previousSum: Number,
            growth: Number,
            arrowUpGreen: {type: Boolean, default: true},
            formatValue: {type: Function, default: formatAndShortenNumber}
        },
        setup(props) {
            const {filter} = storeToRefs(useFilterStore());
            const liveFilter = computed(() => filter.value.timeRangeIndex === 0);
            const color = computed(() => {
                if (props.growth === undefined) {
                    return "green";
                }

                if (props.growth === 0) {
                    return "yellow";
                }

                if (!props.arrowUpGreen) {
                    return props.growth < 0 ? "green" : "red";
                }

                return props.growth > 0 ? "green" : "red";
            });

            return {
                formatAndShortenNumber,
                formatNumber,
                formatPercent,
                liveFilter,
                color
            };
        }
    });
</script>
