<template>
    <AddOrganizationDomain :open="addModal"
        :organization="organization"
        v-on:action="addDomain"
        v-on:close="addModal = false" />
    <ConfirmRemoveDomain :open="removeModal"
        :domain="domain"
        :organization="organization"
        v-on:action="removeDomain"
        v-on:close="removeModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading && domains.length">
                    <OrganizationDomainCard v-for="d in domains"
                        :key="d.id"
                        :domain="d"
                        v-on:remove="setRemoveDomain" />
                </div>
                <p v-if="!loading && !domains.length" style="margin-bottom: 12px;">{{t("not_found")}}</p>
            </Expand>
            <button v-on:click="addModal = true" v-if="organization.can_edit">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, PropType, ref} from "vue";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {Organization} from "@/model/Organization";
    import {Domain} from "@/model/Domain";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import OrganizationDomainCard from "@/components/cards/OrganizationDomainCard.vue";
    import ConfirmRemoveDomain from "@/components/modal/ConfirmRemoveDomain.vue";
    import AddOrganizationDomain from "@/components/modal/AddOrganizationDomain.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            AddOrganizationDomain,
            ConfirmRemoveDomain,
            OrganizationDomainCard,
            Loading,
            Expand
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        setup(props) {
            const {load} = useDomainStore();
            const {removeEntryByID} = useList();
            const addModal = ref(false);
            const removeModal = ref(false);
            const loading = ref(true);
            const domains = ref<Domain[]>([]);
            const domain = ref<Domain>();

            onMounted(async () => {
                loading.value = true;
                domains.value = await OrganizationRepo.listDomains(props.organization.id) as Domain[] || [];
                loading.value = false;
            });

            function addDomain(d: Domain) {
                domains.value.push(d);
                addModal.value = false;
                void load();
            }

            function setRemoveDomain(d: Domain) {
                domain.value = d;
                removeModal.value = true;
            }

            function removeDomain() {
                if (domain.value) {
                    removeEntryByID(domains.value, domain.value as Domain);
                    domain.value = undefined;
                    removeModal.value = false;
                }
            }

            return {
                ...useI18n(),
                addModal,
                removeModal,
                loading,
                domains,
                domain,
                addDomain,
                setRemoveDomain,
                removeDomain
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Websites",
            "text": "All websites belonging to this organization.",
            "not_found": "No Websites Found",
            "add": "Add Website"
        },
        "de": {
            "title": "Webseiten",
            "text": "Alle Webseiten, die zu dieser Organisation gehören.",
            "not_found": "Keine Webseiten gefunden",
            "add": "Webseite hinzufügen"
        },
        "es": {
            "title": "Sitios web",
            "text": "Todos los sitios web que pertenecen a esta organización.",
            "not_found": "No se encontraron sitios web",
            "add": "Agregar sitio web"
        },
        "fr": {
            "title": "Sites Web",
            "text": "Tous les sites Web appartenant à cette organisation.",
            "not_found": "Aucun site Web trouvé",
            "add": "Ajouter un site Web"
        },
        "nl": {
            "title": "Websites",
            "text": "Alle websites die tot deze organisatie behoren.",
            "not_found": "Geen websites gevonden",
            "add": "Website toevoegen"
        },
        "it": {
            "title": "Siti web",
            "text": "Tutti i siti web appartenenti a questa organizzazione.",
            "not_found": "Nessun sito web trovato",
            "add": "Aggiungi sito web"
        },
        "pt": {
            "title": "Websites",
            "text": "Todos os sites pertencentes a esta organização.",
            "not_found": "Nenhum site encontrado",
            "add": "Adicionar site"
        },
        "ja": {
            "title": "ウェブサイト",
            "text": "この組織に属するすべてのウェブサイト。",
            "not_found": "ウェブサイトが見つかりません",
            "add": "ウェブサイトを追加"
        }
    }
</i18n>
