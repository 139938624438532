import {defineStore} from "pinia";
import {ref} from "vue";
import {Organization} from "@/model/Organization";
import {OrganizationRepo} from "@/repositories/OrganizationRepo";

export enum OrganizationOverviewType {
    PANEL = "PANEL",
    LIST = "LIST"
}

export const useOrganizationStore = defineStore("organization_overview", () => {
    const type = ref(localStorage.getItem("organization_overview_type") as OrganizationOverviewType || OrganizationOverviewType.PANEL);
    const organizations = ref<Organization[]>([]);
    const search = ref("");
    const loading = ref(true);

    async function load() {
        loading.value = true;
        organizations.value = await OrganizationRepo.list() as Organization[];
        loading.value = false;
    }

    function set(t: OrganizationOverviewType) {
        localStorage.setItem("organization_overview_type", t);
        type.value = t;
    }

    function setSearch(s: string) {
        search.value = s;
    }

    return {
        type,
        organizations,
        search,
        loading,
        load,
        set,
        setSearch
    };
});
