<template>
    <Modal size="xl" :title="pathFilter ? t('title.time') : t('title.duration')" :open="open">
        <template v-slot:top>
            <ChartType v-on:switch="setChartType" />
        </template>
        <Chart :title="pathFilter ? t('title.time') : t('title.duration')"
            :labels="labels"
            :data="data"
            :type="chartType"
            :loading="loading"
            :growth="growth"
            :formatYAxis="formatValue"
            :formatValue="formatValue" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, computed} from "vue";
    import {useData} from "@/components/panels/data";
    import {formatSeconds} from "@/util/format";
    import Modal from "@/components/modal/Modal.vue";
    import Chart from "@/components/panels/Chart.vue";
    import ChartType from "@/components/panels/ChartType.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {useChartStore} from "@/store/ChartStore";
    import {storeToRefs} from "pinia";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            Chart,
            ChartType
        },
        props: {
            open: {type: Boolean, default: false},
            path: String
        },
        setup() {
            const statisticsStore = useStatisticsStore();
            const chartStore = useChartStore();
            const {hasField} = useFilterStore();
            const {set: setChartType} = chartStore;
            const {type: chartType} = storeToRefs(chartStore);
            const {averageTimeSpent, growth} = storeToRefs(statisticsStore);
            const loading = computed(() => statisticsStore.isLoading("average_time_spent"));
            const pathFilter = computed(() => hasField("path"));
            const sourceField = computed(() => "average_time_spent_seconds");
            const formatTime = computed(() => false);

            return {
                ...useI18n(),
                ...useData(averageTimeSpent, sourceField, formatTime),
                setChartType,
                chartType,
                formatValue: formatSeconds,
                growth: computed(() => growth.value?.time_spent_growth || 0),
                loading,
                pathFilter
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title.time": "Time on Page",
            "title.duration": "Session Duration"
        },
        "de": {
            "title.time": "Zeit auf der Seite",
            "title.duration": "Sitzungsdauer"
        },
        "es": {
            "title.time": "Tiempo en la Página",
            "title.duration": "Duración de la Sesión"
        },
        "fr": {
            "title.time": "Temps sur la Page",
            "title.duration": "Durée de la Session"
        },
        "nl": {
            "title.time": "Tijd op Pagina",
            "title.duration": "Sessieduur"
        },
        "it": {
            "title.time": "Tempo sulla Pagina",
            "title.duration": "Durata della Sessione"
        },
        "pt": {
            "title.time": "Tempo na Página",
            "title.duration": "Duração da Sessão"
        },
        "ja": {
            "title.time": "ページの滞在時間",
            "title.duration": "セッションの期間"
        }
    }
</i18n>
