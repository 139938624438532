<template>
    <section class="invitations" v-if="invitations && invitations.length">
        <h2>{{t("title")}}</h2>
        <p>{{t("text")}}</p>
        <InvitationCard v-for="invitation in invitations"
            :key="invitation.id"
            :invitation="invitation"
            v-on:accept="acceptInvitation(invitation)"
            v-on:remove="removeInvitation(invitation)" />
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {Invitation} from "@/model/Invitation";
    import {InvitationRepo} from "@/repositories/InvitationRepo";
    import InvitationCard from "@/components/cards/InvitationCard.vue";
    import {useList} from "@/components/list";
    import {Domain} from "@/model/Domain";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useOrganizationStore} from "@/store/OrganizationStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            InvitationCard
        },
        setup() {
            const domainStore = useDomainStore();
            const organizationStore = useOrganizationStore();
            const {domains} = storeToRefs(domainStore);
            const {clear, clearComparison} = useFilterStore();
            const {removeEntryByID} = useList();
            const {loggedIn} = storeToRefs(useUserStore());
            const invitations = ref<Invitation[]>([]);

            watchEffect(async () => {
                if (loggedIn.value) {
                    invitations.value = await InvitationRepo.list() as Invitation[];
                }
            });

            async function acceptInvitation(invitation: Invitation) {
                removeEntryByID(invitations.value, invitation);
                await domainStore.load();
                await organizationStore.load();

                if (invitation.domain?.id) {
                    const domain = domains.value.find((domain: Domain) => domain.id === invitation.domain_id);
                    domainStore.set(domain);
                    clear();
                    clearComparison();
                }
            }

            function removeInvitation(invitation: Invitation) {
                removeEntryByID(invitations.value, invitation);
            }

            return {
                ...useI18n(),
                invitations,
                acceptInvitation,
                removeInvitation
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Open Invitations",
            "text": "You have been invited to join a dashboard or organization."
        },
        "de": {
            "title": "Offene Einladungen",
            "text": "Du wurdest eingeladen, einem Dashboard oder einer Organisation beizutreten."
        },
        "es": {
            "title": "Invitaciones abiertas",
            "text": "Has sido invitado a unirte a un panel de control o una organización."
        },
        "fr": {
            "title": "Invitations ouvertes",
            "text": "Vous avez été invité à rejoindre un tableau de bord ou une organisation."
        },
        "nl": {
            "title": "Open uitnodigingen",
            "text": "Je bent uitgenodigd om deel te nemen aan een dashboard of organisatie."
        },
        "it": {
            "title": "Inviti aperti",
            "text": "Sei stato invitato a unirti a un dashboard o a un'organizzazione."
        },
        "pt": {
            "title": "Convites abertos",
            "text": "Você foi convidado para se juntar a um painel ou organização."
        },
        "ja": {
            "title": "オープン招待",
            "text": "ダッシュボードまたは組織に参加するよう招待されました。"
        }
    }
</i18n>
