<template>
    <SaveFilter :open="createEditFilterModal"
        v-on:close="createEditFilterModal = false"
        v-on:action="addFilter"
        :type="type"
        :help="help"
        :example="example"
        :countryList="countryList" />
    <section class="row">
        <div>
            <h3>{{title}}</h3>
            <small>
                {{description}}
                <br />
                <a href="https://docs.pirsch.io/advanced/traffic-filter" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <TrafficFilterCard v-for="f in filter"
                        :key="f.id"
                        :filter="f"
                        :type="type"
                        :title="title"
                        :description="description"
                        :help="help"
                        :example="example"
                        :countryList="countryList"
                        v-on:update="updateFilter"
                        v-on:remove="removeFilter" />
                </div>
            </Expand>
            <button v-on:click="createEditFilterModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {TrafficFilter} from "@/model/TrafficFilter";
    import SaveFilter from "@/components/modal/SaveFilter.vue";
    import TrafficFilterCard from "@/components/cards/TrafficFilterCard.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            TrafficFilterCard,
            SaveFilter,
            Loading,
            Expand
        },
        props: {
            loading: {type: Boolean, default: true},
            filter: {type: Array as PropType<TrafficFilter[]>, required: true},
            type: {type: String, required: true},
            title: {type: String, required: true},
            description: {type: String, required: true},
            help: {type: String, required: true},
            example: String,
            countryList: Boolean
        },
        emits: ["add", "update", "remove"],
        setup(_, {emit}) {
            const createEditFilterModal = ref(false);

            function addFilter(trafficFilter: TrafficFilter) {
                createEditFilterModal.value = false;
                emit("add", trafficFilter);
            }

            function updateFilter(trafficFilter: TrafficFilter) {
                emit("update", trafficFilter);
            }

            function removeFilter(trafficFilter: TrafficFilter) {
                emit("remove", trafficFilter);
            }

            return {
                ...useI18n(),
                createEditFilterModal,
                addFilter,
                updateFilter,
                removeFilter
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "link": "Read the Docs",
            "add": "Add Filter"
        },
        "de": {
            "link": "Dokumentation lesen",
            "add": "Filter hinzufügen"
        },
        "es": {
            "link": "Leer la documentación",
            "add": "Agregar filtro"
        },
        "fr": {
            "link": "Lire la documentation",
            "add": "Ajouter un filtre"
        },
        "nl": {
            "link": "Lees de documentatie",
            "add": "Filter toevoegen"
        },
        "it": {
            "link": "Leggi la documentazione",
            "add": "Aggiungi filtro"
        },
        "pt": {
            "link": "Leia a documentação",
            "add": "Adicionar filtro"
        },
        "ja": {
            "link": "ドキュメントを読む",
            "add": "フィルターを追加"
        }
    }
</i18n>
