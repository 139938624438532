<template>
    <CreateEditWebhook :open="editWebhookModal"
        v-on:close="editWebhookModal = false"
        v-on:action="updateWebhook"
        :webhook="webhook" />
    <ConfirmDeleteWebhook :open="deleteWebhookModal"
        v-on:close="deleteWebhookModal = false"
        v-on:action="removeWebhook"
        :webhook="webhook" />
    <div class="card">
        <div class="text">
            <p>
                <strong v-if="!webhook.description">{{webhook.event}}</strong>
                <strong v-else>{{webhook.description}} ({{webhook.event}})</strong>
            </p>
            <small class="light">{{t("endpoint", {endpoint: webhook.endpoint})}}</small>
        </div>
        <div class="row">
            <Lock :lock="`webhook_${webhook.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editWebhookModal = true"></i>
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteWebhookModal = true"></i>
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Lock from "@/components/settings/Lock.vue";
    import CreateEditWebhook from "@/components/modal/CreateEditWebhook.vue";
    import {Webhook} from "@/model/Webhook";
    import ConfirmDeleteWebhook from "@/components/modal/ConfirmDeleteWebhook.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmDeleteWebhook,
            CreateEditWebhook,
            Lock
        },
        props: {
            webhook: {type: Object as PropType<Webhook>, required: true}
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const editWebhookModal = ref(false);
            const deleteWebhookModal = ref(false);

            function updateWebhook(webhook: Webhook) {
                editWebhookModal.value = false;
                emit("update", webhook);
            }

            function removeWebhook() {
                deleteWebhookModal.value = false;
                emit("remove", props.webhook);
            }

            return {
                ...useI18n(),
                editWebhookModal,
                deleteWebhookModal,
                updateWebhook,
                removeWebhook
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "endpoint": "Endpoint: {endpoint}",
            "edit": "Edit",
            "delete": "Delete"
        },
        "de": {
            "endpoint": "Endpunkt: {endpoint}",
            "edit": "Bearbeiten",
            "delete": "Löschen"
        },
        "es": {
            "endpoint": "Punto final: {endpoint}",
            "edit": "Editar",
            "delete": "Eliminar"
        },
        "fr": {
            "endpoint": "Point de terminaison : {endpoint}",
            "edit": "Éditer",
            "delete": "Supprimer"
        },
        "nl": {
            "endpoint": "Eindpunt: {endpoint}",
            "edit": "Bewerken",
            "delete": "Verwijderen"
        },
        "it": {
            "endpoint": "Endpoint: {endpoint}",
            "edit": "Modifica",
            "delete": "Elimina"
        },
        "pt": {
            "endpoint": "Endpoint: {endpoint}",
            "edit": "Editar",
            "delete": "Excluir"
        },
        "ja": {
            "endpoint": "エンドポイント: {endpoint}",
            "edit": "編集",
            "delete": "削除"
        }
    }
</i18n>
