import {ref, Ref} from "vue";

interface Dropdown {
    dropdownElement: Ref<HTMLElement>
    dropdown: Ref<boolean>
    toggleDropdown(): void
}

export function useDropdown(): Dropdown {
    const dropdownElement = ref(document.createElement("div"));
    const dropdown = ref(false);

    function toggleDropdown() {
        dropdown.value = !dropdown.value;
    }

    window.addEventListener("mousedown", e => {
        const element = dropdownElement.value;

        if (element !== null &&
            e.target instanceof HTMLElement &&
            dropdown.value &&
            e.target !== element &&
            !element.contains(e.target)) {
            dropdown.value = false;
        }
    });

    return {
        dropdownElement,
        dropdown,
        toggleDropdown
    };
}
