<template>
    <template v-if="domain.id && loaded">
        <Charts v-if="getSettings(isPrivate ? 'graphs' : 'graphs_public')" />
        <SessionList />
    </template>
    <DomainNotFound v-else-if="loaded" />
    <LoadingPage :loading="!loaded" />
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {isDashboard} from "@/util/domain";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import Charts from "@/components/statistics/Charts.vue";
    import DomainNotFound from "@/components/domain/DomainNotFound.vue";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import SessionList from "@/components/sessions/SessionList.vue";

    export default defineComponent({
        components: {
            SessionList,
            LoadingPage,
            DomainNotFound,
            Charts
        },
        setup() {
            const domainStore = useDomainStore();
            const {domain, loaded} = storeToRefs(domainStore);

            return {
                isPrivate: isDashboard(),
                domain,
                loaded,
                getSettings: domainStore.getSettings
            };
        }
    });
</script>
