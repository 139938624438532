import {defineStore} from "pinia";
import {ref} from "vue";

export enum ChartType {
    BAR = "bar",
    LINE = "line"
}

export const useChartStore = defineStore("chart", () => {
    const type = ref(localStorage.getItem("chart_type") as ChartType || ChartType.BAR);

    function set(t: ChartType) {
        localStorage.setItem("chart_type", t);
        type.value = t;
    }

    return {
        type,
        set
    };
});
