<template>
    <transition name="modal">
        <div class="modal" v-on:mousedown="$emit('close')" v-if="open">
            <div :class="{'modal-container': true, xl: size === 'xl', l: size === 'l', m: size === 'm'}" v-on:mousedown.stop>
                <div class="modal-header no-select">
                    <h2>{{title}}</h2>
                    <slot name="top"></slot>
                    <i class="icon icon-close clickable" v-on:click="$emit('close')" v-if="showClose"></i>
                </div>
                <div class="modal-content">
                    <FormError :err="err" />
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
    import {defineComponent, onMounted, onUnmounted, PropType} from "vue";
    import FormError from "@/components/form/FormError.vue";
    import {APIError} from "@/repositories/APIError";

    export default defineComponent({
        components: {
            FormError
        },
        props: {
            size: {type: String, default: "s"},
            open: {type: Boolean, default: false},
            title: {type: String, required: true},
            showClose: {type: Boolean, default: true},
            err: Object as PropType<APIError>
        },
        emits: ["close"],
        setup(_, {emit}) {
            onMounted(() => {
                window.addEventListener("keyup", closeOnESC);
            });

            onUnmounted(() => {
                window.removeEventListener("keyup", closeOnESC);
            });

            function closeOnESC(e: KeyboardEvent) {
                if (e.key === "Escape") {
                    emit("close");
                }
            }

            return {};
        }
    });
</script>
