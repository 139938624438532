<template>
    <i :class="{'icon icon-favorite clickable': true, 'color-yellow': user.favorite_domain_id === domain.id}"
       :title="user.favorite_domain_id === domain.id ? t('unset') : t('set')"
       v-on:click.stop="$emit('favorite', domain.id)">
    </i>
</template>

<script lang="ts">
    import {defineComponent, PropType} from "vue";
    import {Domain} from "@/model/Domain";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        emits: ["favorite"],
        props: {
            domain: {type: Object as PropType<Domain>, required: true}
        },
        setup() {
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);

            return {
                ...useI18n(),
                user
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "set": "Set as Favorite",
            "unset": "Unset Favorite"
        },
        "de": {
            "set": "Als Favorit festlegen",
            "unset": "Favorit aufheben"
        },
        "es": {
            "set": "Establecer como favorito",
            "unset": "Quitar favorito"
        },
        "fr": {
            "set": "Définir comme favori",
            "unset": "Annuler le favori"
        },
        "nl": {
            "set": "Als favoriet instellen",
            "unset": "Favoriet verwijderen"
        },
        "it": {
            "set": "Imposta come preferito",
            "unset": "Rimuovi dai preferiti"
        },
        "pt": {
            "set": "Definir como favorito",
            "unset": "Desmarcar favorito"
        },
        "ja": {
            "set": "お気に入りに設定",
            "unset": "お気に入りを解除"
        }
    }
</i18n>
