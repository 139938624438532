<template>
    <small class="footer">
        <template v-if="isCustomDomain">Powered by <a href="https://pirsch.io" target="_blank">Pirsch Analytics</a>.</template>
        <template v-if="!hideCopyright">
            © {{year}}
            <template v-if="copyright">{{copyright}}</template>
            <a href="https://emvi.com" target="_blank" v-else>Emvi Software GmbH</a>.
            All rights reserved.
        </template>
    </small>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import dayjs from "dayjs";
    import {isCustomDomain} from "@/util/domain";
    import {useThemeStore} from "@/store/ThemeStore";
    import {storeToRefs} from "pinia";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        setup() {
            const {theme} = storeToRefs(useThemeStore());
            const hideCopyright = computed(() => {
                const themeSettings = theme.value ?? {};
                return themeSettings[ThemeSettings.hideCopyright] === "true";
            });

            return {
                copyright: import.meta.env.VITE_APP_COPYRIGHT,
                isCustomDomain: isCustomDomain(),
                year: dayjs().year(),
                hideCopyright
            };
        }
    });
</script>
