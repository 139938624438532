<template>
    <AddTrafficSpikeNotifications :open="createNotificationModal"
        v-on:close="createNotificationModal = false"
        v-on:action="addNotification" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/traffic-spike" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <form v-on:submit.prevent>
                <FormCheckbox name="toggle" :label="t('form.enable')" :toggle="true" v-model="toggle" />
                <FormInput name="threshold" :label="t('form.threshold')" type="number" v-model="threshold" :err="validationError('threshold')" />
            </form>
            <hr />
            <Expand>
                <div v-if="!loading">
                    <TrafficSpikeNotificationCard v-for="notification in notifications"
                        :key="notification.id"
                        :notification="notification"
                        v-on:remove="removeNotification" />
                </div>
            </Expand>
            <button v-on:click="createNotificationModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watch, watchEffect} from "vue";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {TrafficSpike} from "@/model/TrafficSpike";
    import {TrafficSpikeRepo} from "@/repositories/TrafficSpikeRepo";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {APIError} from "@/repositories/APIError";
    import {useError} from "@/components/error";
    import FormInput from "@/components/form/FormInput.vue";
    import AddTrafficSpikeNotifications from "@/components/modal/AddTrafficSpikeNotifications.vue";
    import TrafficSpikeNotificationCard from "@/components/cards/TrafficSpikeNotificationCard.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            TrafficSpikeNotificationCard,
            AddTrafficSpikeNotifications,
            FormInput,
            FormCheckbox,
            Loading,
            Expand
        },
        setup() {
            const {t} = useI18n();
            const {removeEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const {success, error} = useToastStore();
            const {setError, resetError, validationError} = useError();
            const createNotificationModal = ref(false);
            const loading = ref(true);
            const notifications = ref<TrafficSpike[]>([]);
            const toggle = ref(domain.value.traffic_spike_notifications);
            const threshold = ref(domain.value.traffic_spike_threshold);

            watchEffect(async () => {
                loading.value = true;
                notifications.value = await TrafficSpikeRepo.list(domain.value.id) as TrafficSpike[] || [];
                loading.value = false;
            });

            watch(toggle, async value => {
                await DomainRepo.toggleTrafficSpikeNotifications(domain.value.id);
                domain.value.traffic_spike_notifications = value;
                success(value ? t("toast.enabled") : t("toast.disabled"));
            });

            watch(threshold, async value => {
                try {
                    resetError();
                    await DomainRepo.setTrafficSpikeNotificationThreshold(domain.value.id, parseInt(value as unknown as string));
                    domain.value.traffic_spike_threshold = value;
                    success(t("toast.success"));
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            });

            function addNotification(notification: TrafficSpike[]) {
                createNotificationModal.value = false;

                for (let i = 0; i < notification.length; i++) {
                    notifications.value.push(notification[i]);
                }
            }

            function removeNotification(notification: TrafficSpike) {
                removeEntryByID(notifications.value, notification);
            }

            return {
                t,
                validationError,
                createNotificationModal,
                loading,
                notifications,
                toggle,
                threshold,
                addNotification,
                removeNotification
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Traffic Spike Notifications",
            "text": "Get notified when your website receives an unusual amount of traffic.",
            "link": "Read the Docs",
            "form.enable": "Enable Traffic Spike Notifications",
            "form.threshold": "Unique Visitor Threshold",
            "add": "Add Notification",
            "toast.success": "Threshold saved.",
            "toast.enabled": "Traffic spike notifications enabled.",
            "toast.disabled": "Traffic spike notifications disabled."
        },
        "de": {
            "title": "Traffic-Spike-Benachrichtigungen",
            "text": "Erhalte Benachrichtigungen, wenn deine Webseite eine ungewöhnliche Menge an Traffic erhält.",
            "link": "Dokumentation lesen",
            "form.enable": "Traffic-Spike-Benachrichtigungen aktivieren",
            "form.threshold": "Schwellenwert für eindeutige Besucher",
            "add": "Benachrichtigung hinzufügen",
            "toast.success": "Schwellenwert gespeichert.",
            "toast.enabled": "Traffic-Spike-Benachrichtigungen aktiviert.",
            "toast.disabled": "Traffic-Spike-Benachrichtigungen deaktiviert."
        },
        "es": {
            "title": "Notificaciones de Picos de Tráfico",
            "text": "Recibe notificaciones cuando tu sitio web reciba una cantidad inusual de tráfico.",
            "link": "Leer la documentación",
            "form.enable": "Habilitar notificaciones de picos de tráfico",
            "form.threshold": "Umbral de visitantes únicos",
            "add": "Agregar notificación",
            "toast.success": "Umbral guardado.",
            "toast.enabled": "Notificaciones de picos de tráfico habilitadas.",
            "toast.disabled": "Notificaciones de picos de tráfico deshabilitadas."
        },
        "fr": {
            "title": "Notifications de pics de trafic",
            "text": "Recevez des notifications lorsque votre site Web reçoit une quantité inhabituelle de trafic.",
            "link": "Lire la documentation",
            "form.enable": "Activer les notifications de pics de trafic",
            "form.threshold": "Seuil de visiteurs uniques",
            "add": "Ajouter une notification",
            "toast.success": "Seuil enregistré.",
            "toast.enabled": "Notifications de pics de trafic activées.",
            "toast.disabled": "Notifications de pics de trafic désactivées."
        },
        "nl": {
            "title": "Verkeerspiekmeldingen",
            "text": "Ontvang meldingen wanneer je website een ongebruikelijke hoeveelheid verkeer ontvangt.",
            "link": "Lees de documentatie",
            "form.enable": "Verkeerspiekmeldingen inschakelen",
            "form.threshold": "Drempel voor unieke bezoekers",
            "add": "Melding toevoegen",
            "toast.success": "Drempel opgeslagen.",
            "toast.enabled": "Verkeerspiekmeldingen ingeschakeld.",
            "toast.disabled": "Verkeerspiekmeldingen uitgeschakeld."
        },
        "it": {
            "title": "Notifiche di Picchi di Traffico",
            "text": "Ricevi notifiche quando il tuo sito web riceve una quantità insolita di traffico.",
            "link": "Leggi la documentazione",
            "form.enable": "Abilita notifiche di picchi di traffico",
            "form.threshold": "Soglia di visitatori unici",
            "add": "Aggiungi notifica",
            "toast.success": "Soglia salvata.",
            "toast.enabled": "Notifiche di picchi di traffico abilitate.",
            "toast.disabled": "Notifiche di picchi di traffico disabilitate."
        },
        "pt": {
            "title": "Notificações de Picos de Tráfego",
            "text": "Receba notificações quando seu site receber uma quantidade incomum de tráfego.",
            "link": "Leia a documentação",
            "form.enable": "Ativar notificações de picos de tráfego",
            "form.threshold": "Limite de visitantes únicos",
            "add": "Adicionar notificação",
            "toast.success": "Limite salvo.",
            "toast.enabled": "Notificações de picos de tráfego ativadas.",
            "toast.disabled": "Notificações de picos de tráfego desativadas."
        },
        "ja": {
            "title": "トラフィックスパイク通知",
            "text": "あなたのウェブサイトが異常な量のトラフィックを受信したときに通知を受け取ります。",
            "link": "ドキュメントを読む",
            "form.enable": "トラフィックスパイク通知を有効にする",
            "form.threshold": "ユニークビジターのしきい値",
            "add": "通知を追加",
            "toast.success": "しきい値が保存されました。",
            "toast.enabled": "トラフィックスパイク通知が有効になりました。",
            "toast.disabled": "トラフィックスパイク通知が無効になりました。"
        }
    }
</i18n>
