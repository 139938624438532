<template>
    <SettingsPage active="integration">
        <template v-if="!domain.disable_scripts">
            <IdentificationCode />
            <hr />
        </template>
        <Domains />
        <hr />
        <Clients />
        <hr />
        <Webhooks />
        <hr />
        <DisableScripts />
        <hr />
        <SearchConsole />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import IdentificationCode from "@/components/settings/IdentificationCode.vue";
    import Clients from "@/components/settings/Clients.vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import Domains from "@/components/settings/Domains.vue";
    import DisableScripts from "@/components/settings/DisableScripts.vue";
    import SearchConsole from "@/components/settings/SearchConsole.vue";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import Webhooks from "@/components/settings/Webhooks.vue";

    export default defineComponent({
        components: {
            Webhooks,
            SearchConsole,
            DisableScripts,
            Domains,
            SettingsPage,
            IdentificationCode,
            Clients
        },
        setup() {
            const {domain} = storeToRefs(useDomainStore());

            return {
                domain
            };
        }
    });
</script>
