<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/theme" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <form v-on:submit.prevent="save" v-if="hasPlus">
            <FormSelect name="theme"
                :label="t('form.theme')"
                v-model="theme"
                :options="themeOptions"
                v-on:change="save" />
            <small style="display:block; margin-top: 2px;" v-if="themeURL">
                <router-link :to="themeURL">{{t("edit")}}</router-link>
            </small>
        </form>
        <PirschPlus>
            <strong>{{t("plus")}}</strong>
        </PirschPlus>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {KeyValue} from "@/model/KeyValue";
    import {useThemeStore} from "@/store/ThemeStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            PirschPlus,
            FormSelect
        },
        setup() {
            const {t} = useI18n();
            const themeStore = useThemeStore();
            const {hasPlus} = storeToRefs(useUserStore());
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const {themes} = storeToRefs(themeStore);
            const loading = ref(false);
            const theme = ref("");
            const themeOptions = computed(() => {
                const options = [
                    {
                        label: t("options.theme.default"),
                        value: ""
                    }
                ];

                for (const theme of themes.value) {
                    if (domain.value.organization_id === theme.organization_id) {
                        options.push({
                            label: theme.name,
                            value: theme.id
                        });
                    }
                }

                return options;
            });
            const themeURL = computed(() => {
                if (theme.value) {
                    const t = themes.value.find(t => t.id === theme.value);

                    if (t) {
                        if (t.organization_id) {
                            return `/organization/${t.organization_id}`;
                        } else {
                            return "/themes";
                        }
                    }
                }

                return "";
            });

            onMounted(async () => {
                theme.value = domain.value.theme_id ?? "";
            });

            watch(domain, domain => {
                theme.value = domain.theme_id ?? "";
            })

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    domain.value.theme_settings = await DomainRepo.setBaseTheme(domain.value.id, theme.value) as KeyValue;
                    domain.value.theme_id = theme.value;
                    loading.value = false;
                    themeStore.setTheme(domain.value);
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                hasPlus,
                validationError,
                loading,
                theme,
                themeOptions,
                themeURL,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Base Theme",
            "text": "Select a base theme for the dashboard. You can overwrite individual or all settings.",
            "link": "Read the Docs",
            "form.theme": "Theme",
            "edit": "Edit Theme",
            "plus": "Managing themes requires a Pirsch Plus subscription.",
            "options.theme.default": "Default",
            "toast.success": "Theme selection saved."
        },
        "de": {
            "title": "Basis-Thema",
            "text": "Wähle ein Basis-Thema für das Dashboard. Du kannst einzelne oder alle Einstellungen überschreiben.",
            "link": "Dokumentation lesen",
            "form.theme": "Thema",
            "edit": "Thema bearbeiten",
            "plus": "Die Verwaltung von Themen erfordert ein Pirsch Plus-Abonnement.",
            "options.theme.default": "Standard",
            "toast.success": "Thema-Auswahl gespeichert."
        },
        "es": {
            "title": "Tema Base",
            "text": "Selecciona un tema base para el tablero. Puedes sobrescribir configuraciones individuales o todas las configuraciones.",
            "link": "Leer la documentación",
            "form.theme": "Tema",
            "edit": "Editar Tema",
            "plus": "La gestión de temas requiere una suscripción a Pirsch Plus.",
            "options.theme.default": "Predeterminado",
            "toast.success": "Selección de tema guardada."
        },
        "fr": {
            "title": "Thème de base",
            "text": "Sélectionnez un thème de base pour le tableau de bord. Vous pouvez écraser des paramètres individuels ou tous les paramètres.",
            "link": "Lire la documentation",
            "form.theme": "Thème",
            "edit": "Modifier le thème",
            "plus": "La gestion des thèmes nécessite un abonnement Pirsch Plus.",
            "options.theme.default": "Défaut",
            "toast.success": "Sélection du thème enregistrée."
        },
        "nl": {
            "title": "Basisthema",
            "text": "Selecteer een basisthema voor het dashboard. Je kunt individuele of alle instellingen overschrijven.",
            "link": "Lees de documentatie",
            "form.theme": "Thema",
            "edit": "Thema bewerken",
            "plus": "Het beheren van thema's vereist een Pirsch Plus-abonnement.",
            "options.theme.default": "Standaard",
            "toast.success": "Thema selectie opgeslagen."
        },
        "it": {
            "title": "Tema di Base",
            "text": "Seleziona un tema di base per il cruscotto. Puoi sovrascrivere impostazioni individuali o tutte le impostazioni.",
            "link": "Leggi la documentazione",
            "form.theme": "Tema",
            "edit": "Modifica Tema",
            "plus": "La gestione dei temi richiede un abbonamento a Pirsch Plus.",
            "options.theme.default": "Predefinito",
            "toast.success": "Selezione del tema salvata."
        },
        "pt": {
            "title": "Tema Base",
            "text": "Selecione um tema base para o painel. Você pode sobrescrever configurações individuais ou todas as configurações.",
            "link": "Leia a documentação",
            "form.theme": "Tema",
            "edit": "Editar Tema",
            "plus": "Gerenciar temas requer uma assinatura Pirsch Plus.",
            "options.theme.default": "Padrão",
            "toast.success": "Seleção de tema salva."
        },
        "ja": {
            "title": "ベーステーマ",
            "text": "ダッシュボードのベーステーマを選択します。個々の設定またはすべての設定を上書きすることができます。",
            "link": "ドキュメントを読む",
            "form.theme": "テーマ",
            "edit": "テーマを編集",
            "plus": "テーマの管理には Pirsch Plus サブスクリプションが必要です。",
            "options.theme.default": "デフォルト",
            "toast.success": "テーマ選択が保存されました。"
        }
    }
</i18n>
