<template>
    <ConfirmDeleteOrganization :open="deleteModal"
        :organization="organization"
        v-on:close="deleteModal = false"
        v-on:action="deleteOrganization" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <button class="red" v-on:click="deleteModal = true">{{t("button")}}</button>
    </section>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import ConfirmDeleteOrganization from "@/components/modal/ConfirmDeleteOrganization.vue";
    import {Organization} from "@/model/Organization";
    import {useRouter} from "vue-router";
    import {useOrganizationStore} from "@/store/OrganizationStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmDeleteOrganization
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        setup() {
            const router = useRouter();
            const {load} = useOrganizationStore();
            const deleteModal = ref(false);

            async function deleteOrganization() {
                await load();
                await router.push("/organizations");
            }

            return {
                ...useI18n(),
                deleteModal,
                deleteOrganization
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Delete Organization",
            "text": "Delete the organization and websites belonging to it.",
            "button": "Delete Organization"
        },
        "de": {
            "title": "Organisation löschen",
            "text": "Lösche die Organisation und die dazugehörigen Webseiten.",
            "button": "Organisation löschen"
        },
        "es": {
            "title": "Eliminar organización",
            "text": "Eliminar la organización y los sitios web que le pertenecen.",
            "button": "Eliminar organización"
        },
        "fr": {
            "title": "Supprimer l'organisation",
            "text": "Supprimer l'organisation et les sites Web qui lui appartiennent.",
            "button": "Supprimer l'organisation"
        },
        "nl": {
            "title": "Organisatie verwijderen",
            "text": "Verwijder de organisatie en de bijbehorende websites.",
            "button": "Organisatie verwijderen"
        },
        "it": {
            "title": "Elimina organizzazione",
            "text": "Elimina l'organizzazione e i siti web ad essa appartenenti.",
            "button": "Elimina organizzazione"
        },
        "pt": {
            "title": "Excluir organização",
            "text": "Excluir a organização e os sites pertencentes a ela.",
            "button": "Excluir organização"
        },
        "ja": {
            "title": "組織を削除",
            "text": "組織とその所属するウェブサイトを削除します。",
            "button": "組織を削除"
        }
    }
</i18n>
