<template>
    <div class="list flex-1" v-if="!disableFilter">
        <div class="headline">
            <span>
                <h2>{{t("title")}}</h2>
            </span>
        </div>
        <div class="list-container" style="padding-top: 12px">
            <FormSelect name="stat" :label="t('options')" :options="statOptions" v-model="stat" />
            <div class="weekdays">
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>{{t("weekday.mo")}}</td>
                            <td>{{t("weekday.tu")}}</td>
                            <td>{{t("weekday.we")}}</td>
                            <td>{{t("weekday.th")}}</td>
                            <td>{{t("weekday.fr")}}</td>
                            <td>{{t("weekday.sa")}}</td>
                            <td>{{t("weekday.su")}}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="n in 24">
                            <td>
                                <div class="cell-content">
                                    {{(n-1)%3 === 1 ? formatHour(n-1) : ""}}
                                </div>
                            </td>
                            <td :title="data[n-1]" :style="{background: getBackgroundColor(transparency[n-1])}">
                            </td>
                            <td :title="data[24+n-1]" :style="{background: getBackgroundColor(transparency[24+n-1])}">
                            </td>
                            <td :title="data[24*2+n-1]" :style="{background: getBackgroundColor(transparency[24*2+n-1])}">
                            </td>
                            <td :title="data[24*3+n-1]" :style="{background: getBackgroundColor(transparency[24*3+n-1])}">
                            </td>
                            <td :title="data[24*4+n-1]" :style="{background: getBackgroundColor(transparency[24*4+n-1])}">
                            </td>
                            <td :title="data[24*5+n-1]" :style="{background: getBackgroundColor(transparency[24*5+n-1])}">
                            </td>
                            <td :title="data[24*6+n-1]" :style="{background: getBackgroundColor(transparency[24*6+n-1])}">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <transition name="fade">
            <Loading :loading="loading" type="loader" />
        </transition>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import {useI18n} from "vue-i18n";
    import Loading from "@/components/bits/Loading.vue";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {formatHour} from "@/util/format";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useThemeStore} from "@/store/ThemeStore";
    import {getBackgroundColor, rgbToRgba, selectColor} from "@/components/panels/chart";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        methods: {getBackgroundColor},
        components: {
            FormSelect,
            Loading
        },
        setup() {
            const {t} = useI18n();
            const {disableFilter} = storeToRefs(useFilterStore());
            const {isLoading, visitorsByWeekday} = useStatisticsStore();
            const {theme, darkMode} = storeToRefs(useThemeStore());
            const themeSettings = computed(() => theme.value ?? {});
            const loading = computed(() => isLoading("weekdays"));
            const dataRaw = computed(() => visitorsByWeekday());
            const stat = ref<"visitors" | "views" | "sessions" | "bounces">("visitors");
            const max = ref(0);
            const data = computed(() => {
                const data = [];
                let m = 0;

                if (dataRaw.value.length) {
                    for (let i = 0; i < dataRaw.value.length; i++) {
                        const n = dataRaw.value[i][stat.value];

                        if (n > m) {
                            m = n;
                        }

                        data.push(n);
                    }
                } else {
                    for (let i = 0; i < 168; i++) {
                        data.push(0);
                    }
                }

                max.value = m;
                return data;
            });
            const transparency = computed(() => {
                const transparency = [];

                for (let i = 0; i < data.value.length; i++) {
                    if (max.value > 0) {
                        transparency.push(data.value[i] / max.value);
                    } else {
                        transparency.push(0);
                    }
                }

                return transparency;
            });

            function getBackgroundColor(transparency: number) {
                return selectColor(rgbToRgba(themeSettings.value[ThemeSettings.lightColorGreen], transparency),
                    `rgba(110,206,157,${transparency})`,
                    rgbToRgba(themeSettings.value[ThemeSettings.darkColorGreen], transparency),
                    `rgba(110,206,157,${transparency})`,
                    darkMode.value);
            }

            return {
                statOptions: [
                    {label: t("options.visitors"), value: "visitors"},
                    {label: t("options.views"), value: "views"},
                    {label: t("options.sessions"), value: "sessions"},
                    {label: t("options.bounces"), value: "bounces"}
                ],
                formatHour,
                t,
                disableFilter,
                loading,
                data,
                transparency,
                stat,
                getBackgroundColor
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Weekdays",
            "weekday.mo": "Mo",
            "weekday.tu": "Tu",
            "weekday.we": "We",
            "weekday.th": "Th",
            "weekday.fr": "Fr",
            "weekday.sa": "Sa",
            "weekday.su": "Su",
            "options": "Metric",
            "options.visitors": "Unique Visitors",
            "options.views": "Page Views",
            "options.sessions": "Sessions",
            "options.bounces": "Bounces"
        },
        "de": {
            "title": "Wochentage",
            "weekday.mo": "Mo",
            "weekday.tu": "Di",
            "weekday.we": "Mi",
            "weekday.th": "Do",
            "weekday.fr": "Fr",
            "weekday.sa": "Sa",
            "weekday.su": "So",
            "options": "Metrik",
            "options.visitors": "Besucher",
            "options.views": "Seitenaufrufe",
            "options.sessions": "Sitzungen",
            "options.bounces": "Absprünge"
        },
        "es": {
            "title": "Días laborables",
            "weekday.mo": "Lu",
            "weekday.tu": "Ma",
            "weekday.we": "Mi",
            "weekday.th": "Ju",
            "weekday.fr": "Vi",
            "weekday.sa": "Sá",
            "weekday.su": "Do",
            "options": "Metrik",
            "options.visitors": "Visitantes",
            "options.views": "Páginas vistas",
            "options.sessions": "Reuniones",
            "options.bounces": "Salta"
        },
        "fr": {
            "title": "En semaine",
            "weekday.mo": "Lu",
            "weekday.tu": "Ma",
            "weekday.we": "Me",
            "weekday.th": "Je",
            "weekday.fr": "Ve",
            "weekday.sa": "Sa",
            "weekday.su": "Di",
            "options": "Metrik",
            "options.visitors": "Visiteur",
            "options.views": "Pages vues",
            "options.sessions": "Réunions",
            "options.bounces": "Sauts"
        },
        "nl": {
            "title": "Weekdagen",
            "weekday.mo": "Ma",
            "weekday.tu": "Di",
            "weekday.we": "Wo",
            "weekday.th": "Do",
            "weekday.fr": "Vr",
            "weekday.sa": "Za",
            "weekday.su": "Zo",
            "options": "Metrik",
            "options.visitors": "Bezoekers",
            "options.views": "Bekeken pagina's",
            "options.sessions": "Vergaderingen",
            "options.bounces": "Springt"
        },
        "it": {
            "title": "Giorni feriali",
            "weekday.mo": "Lu",
            "weekday.tu": "Ma",
            "weekday.we": "Me",
            "weekday.th": "Gi",
            "weekday.fr": "Ve",
            "weekday.sa": "Sa",
            "weekday.su": "Do",
            "options": "Metrik",
            "options.visitors": "Visitatori",
            "options.views": "Pagine viste",
            "options.sessions": "Riunioni",
            "options.bounces": "Salti"
        },
        "pt": {
            "title": "Dias úteis",
            "weekday.mo": "Se",
            "weekday.tu": "Te",
            "weekday.we": "Qua",
            "weekday.th": "Qui",
            "weekday.fr": "Se",
            "weekday.sa": "Sá",
            "weekday.su": "Do",
            "options": "Metrik",
            "options.visitors": "Visitantes",
            "options.views": "Visualizações de página",
            "options.sessions": "Reuniões",
            "options.bounces": "Saltos"
        },
        "ja": {
            "title": "平日",
            "weekday.mo": "月曜日",
            "weekday.tu": "火曜日",
            "weekday.we": "水曜日",
            "weekday.th": "木曜日",
            "weekday.fr": "金曜日",
            "weekday.sa": "土曜日",
            "weekday.su": "日曜日",
            "options": "メートル",
            "options.visitors": "訪問者",
            "options.views": "ビュー",
            "options.sessions": "セッション",
            "options.bounces": "バウンス"
        }
    }
</i18n>
