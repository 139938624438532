<template>
    <div :class="{list: showTitle, 'flex-1': !showTitle}">
        <div class="headline" v-if="showTitle || showEnlarge">
            <span>
                <h2 v-if="showTitle">{{title}}</h2>
                <Tooltip :content="tooltip" v-if="tooltip" />
            </span>
            <span class="icons">
                <slot name="icons"></slot>
                <i class="icon icon-enlarge clickable"
                    :title="t('details')"
                    v-on:click="$emit('enlarge')"
                    v-if="showEnlarge" />
            </span>
        </div>
        <div class="list-container" v-show="entries.length">
            <transition-group name="list" appear>
                <ListEntry v-for="entry in entries"
                    :key="entry.key ? entry.key : entry.label+entry.label_2"
                    :entry="entry"
                    :entries="entries.length"
                    :newTab="newTab"
                    :showBar="showBar"
                    :showDetails="showDetails"
                    :unknownLabel="unknownLabel"
                    :rawTitle="rawTitle"
                    :selectable="selectable && !disableFilter"
                    :valueTitle="valueTitle"
                    v-on:click.stop="selectable && !disableFilter ? $emit('filter', entry) : undefined"
                    v-on:open="$emit('open', entry)" />
            </transition-group>
        </div>
        <transition name="replace">
            <div class="no-data" v-if="!loading && !entries.length">
                <p>{{t("no_data")}}</p>
                <slot v-if="!entries.length"></slot>
            </div>
        </transition>
        <transition name="fade">
            <Loading :loading="loading" type="loader" />
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from "vue";
    import ListEntry from "@/components/panels/ListEntry.vue";
    import Loading from "@/components/bits/Loading.vue";
    import Tooltip from "@/components/bits/Tooltip.vue";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {ListEntry as Entry} from "@/model/ListEntry";
    import {useI18n} from "vue-i18n";
    import {types} from "sass";
    import Boolean = types.Boolean;

    export default defineComponent({
        components: {
            ListEntry,
            Loading,
            Tooltip
        },
        props: {
            title: String,
            tooltip: String,
            entries: {type: Array as PropType<Entry[]>, required: true},
            loading: {type: Boolean, default: true},
            newTab: {type: Boolean, default: false},
            showBar: {type: Boolean, default: false},
            showTitle: {type: Boolean, default: true},
            showEnlarge: {type: Boolean, default: true},
            showDetails: {type: Boolean, default: false},
            unknownLabel: {type: String, default: "Unknown"},
            rawTitle: {type: Boolean, default: false},
            selectable: {type: Boolean, default: false},
            valueTitle: String
        },
        emits: ["enlarge", "filter", "open"],
        setup() {
            return {
                ...useI18n(),
                ...storeToRefs(useFilterStore())
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "details": "View Details",
            "no_data": "No Data"
        },
        "de": {
            "details": "Details anzeigen",
            "no_data": "Keine Daten"
        },
        "es": {
            "details": "Ver detalles",
            "no_data": "Sin datos"
        },
        "fr": {
            "details": "Voir les détails",
            "no_data": "Pas de données"
        },
        "nl": {
            "details": "Bekijk details",
            "no_data": "Geen gegevens"
        },
        "it": {
            "details": "Visualizza dettagli",
            "no_data": "Nessun dato"
        },
        "pt": {
            "details": "Ver detalhes",
            "no_data": "Sem dados"
        },
        "ja": {
            "details": "詳細を見る",
            "no_data": "データなし"
        }
    }
</i18n>
