<template>
    <component :is="component"
        panel="events"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'events' : 'top_events')"
        :loadMore="loadMore(limitless ? 'events' : 'top_events')"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :visible="visible"
        :showDetails="showDetails"
        v-on:filter="filterByEvent"
        v-on:search="searchAndSort">
        <template v-slot:default>
            <button v-on:click="$emit('open')" v-if="loggedIn && domain.user_role && isAdmin">{{t("open")}}</button>
        </template>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent, formatSeconds} from "@/util/format";
    import {ListEntry} from "@/model/ListEntry";
    import {useTable} from "@/components/panels/table";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {Row} from "@/components/panels/Row";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, default: false}
        },
        emits: ["open"],
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadEvents, events} = useStatisticsStore();
            const {setField} = filterStore;
            const {searchAndSort} = useTable(loadEvents);
            const rawData = computed(() => events(props.limitless));
            const data = ref<Row[]>([]);
            const columns = computed(() => [
                {key: "label", label: t("columns.name"), order_by: "event_name"},
                {key: "count", label: t("columns.events"), transform: (v: number) => formatAndShortenNumber(v), order_by: "count"},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.cr"), transform: (v: number) => formatPercent(v)+"%", order_by: "cr"},
                {key: "average_duration_seconds", label: t("columns.duration"), transform: (v: number) => formatSeconds(v), order_by: "-"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("events_table");
                }

                data.value = rawData.value;
            });

            watch(rawData, () => {
                data.value = rawData.value;
            });

            function filterByEvent(entry: ListEntry) {
                setField("event", entry.label);
            }

            return {
                t,
                ...storeToRefs(useUserStore()),
                ...storeToRefs(useDomainStore()),
                columns,
                isLoading,
                loadMore,
                data,
                filterByEvent,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "open": "Track Events",
            "columns.name": "Name",
            "columns.events": "Events",
            "columns.visitors": "Visitors",
            "columns.cr": "CR %",
            "columns.duration": "Duration"
        },
        "de": {
            "open": "Events verfolgen",
            "columns.name": "Name",
            "columns.events": "Events",
            "columns.visitors": "Besucher",
            "columns.cr": "CR %",
            "columns.duration": "Dauer"
        },
        "es": {
            "open": "Rastrear Eventos",
            "columns.name": "Nombre",
            "columns.events": "Eventos",
            "columns.visitors": "Visitantes",
            "columns.cr": "CR %",
            "columns.duration": "Duración"
        },
        "fr": {
            "open": "Suivre les Événements",
            "columns.name": "Nom",
            "columns.events": "Événements",
            "columns.visitors": "Visiteurs",
            "columns.cr": "CR %",
            "columns.duration": "Durée"
        },
        "nl": {
            "open": "Evenementen volgen",
            "columns.name": "Naam",
            "columns.events": "Gebeurtenissen",
            "columns.visitors": "Bezoekers",
            "columns.cr": "CR %",
            "columns.duration": "Duur"
        },
        "it": {
            "open": "Traccia Eventi",
            "columns.name": "Nome",
            "columns.events": "Eventi",
            "columns.visitors": "Visitatori",
            "columns.cr": "CR %",
            "columns.duration": "Durata"
        },
        "pt": {
            "open": "Acompanhar Eventos",
            "columns.name": "Nome",
            "columns.events": "Eventos",
            "columns.visitors": "Visitantes",
            "columns.cr": "CR %",
            "columns.duration": "Duração"
        },
        "ja": {
            "open": "イベントを追跡",
            "columns.name": "名前",
            "columns.events": "イベント",
            "columns.visitors": "訪問者",
            "columns.cr": "CR %",
            "columns.duration": "期間"
        }
    }
</i18n>
