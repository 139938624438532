import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {defaultDomain, Domain} from "@/model/Domain";
import {DomainRepo} from "@/repositories/DomainRepo";
import {getDomain, getSubdomain, isCustomDomain, isDashboard} from "@/util/domain";
import {getQueryParam, setQueryParam} from "@/util/url";
import {Role} from "@/model/Role";
import {useUserStore} from "@/store/UserStore";
import {ThemeRepo} from "@/repositories/ThemeRepo";
import {KeyValue} from "@/model/KeyValue";
import {useThemeStore} from "@/store/ThemeStore";

export const useDomainStore = defineStore("domain", () => {
    const userStore = useUserStore();
    const themeStore = useThemeStore();
    const domains = ref<Domain[]>([]);
    const domain = ref<Domain>(defaultDomain());
    const loaded = ref(false);
    const active = computed(() => !!(domain.value && domain.value.id));
    const role = computed(() => domain.value && domain.value.user_role ? domain.value.user_role: "");
    const isAdmin = computed(() => role.value ? role.value === Role.Owner || role.value === Role.Admin : false);
    const isOwner = computed(() => role.value ? role.value === Role.Owner : false);
    const created = ref(false);

    watch(domain, () => {
        loaded.value = true;
    });

    function set(activeDomain?: Domain) {
        if (activeDomain) {
            domain.value = activeDomain;
            localStorage.setItem("active_domain", JSON.stringify(activeDomain));
            setQueryParam("domain", activeDomain.hostname);
        } else {
            domain.value = defaultDomain();
            localStorage.removeItem("active_domain");
            setQueryParam("domain");
        }
    }

    async function load() {
        let subdomain = getSubdomain();
        let customDomain = "";

        if (isDashboard()) {
            subdomain = "";
        } else if (isCustomDomain()) {
            subdomain = "";
            customDomain = getDomain();
        }

        const favorite = userStore.user ? userStore.user.favorite_domain_id : undefined;
        const list = await DomainRepo.list(subdomain, customDomain) as Domain[] || [];
        domains.value = list as Domain[];

        if (list.length) {
            let found = false;
            let hostname = getQueryParam("domain");

            if (!hostname) {
                const activeDomain = localStorage.getItem("active_domain");

                if (activeDomain) {
                    hostname = (JSON.parse(activeDomain) as Domain).hostname;
                }
            }

            if (!hostname && favorite) {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === favorite) {
                        set(list[i]);
                        found = true;
                        break;
                    }
                }
            }

            if (hostname) {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].hostname === hostname) {
                        set(list[i]);
                        found = true;
                        break;
                    }
                }
            }

            if (!found) {
                set(list[0]);
            }
        } else {
            set();
            let themeSettings;

            if (isCustomDomain()) {
                themeSettings = await ThemeRepo.get("", getDomain());
            } else {
                themeSettings = await ThemeRepo.get(getSubdomain(), "");
            }

            if (themeSettings) {
                themeStore.setTheme(undefined, themeSettings as KeyValue);
            }
        }

        return Promise.resolve<Domain[]>(list as Domain[]);
    }

    function getSettings(key: string): boolean {
        if (!domain.value) {
            return false;
        }

        return !domain.value.settings || !domain.value.settings[key] || domain.value.settings[key] === "true";
    }

    return {
        domains,
        domain,
        loaded,
        active,
        isAdmin,
        isOwner,
        created,
        set,
        load,
        getSettings
    };
});
