<template>
    <Collapsible :title="t('hide')"
        :titleCollapsed="t('show')"
        :open="showAdvancedOptions"
        v-on:toggle="showAdvancedOptions = !showAdvancedOptions">
        <h5 style="margin-top: 16px">{{t("script.title")}}</h5>
        <form v-on:submit.prevent>
            <FormCheckbox name="dev" :label="t('dev.label')" v-model="dev" />
            <FormInput name="rewrite" :label="t('rewrite.label')" v-model="rewrite" />
            <FormCheckbox name="disableQueryParams" :label="t('disable_query.label')" v-model="disableQueryParams" />
            <FormCheckbox name="disableReferrer" :label="t('disable_referrer.label')" v-model="disableReferrer" />
            <FormCheckbox name="disableResolution" :label="t('disable_resolution.label')" v-model="disableResolution" />
            <FormInput name="domains" :label="t('domains.label')" :hint="t('domains.hint')" v-model="domains" />
            <FormInput name="pathPrefix" :label="t('path_prefix.label')" :hint="t('path_prefix.hint')" v-model="pathPrefix" v-show="domains || pathPrefix" />
            <FormInput name="endpoint" :label="t('endpoint.label')" :hint="t('endpoint.hint')" v-model="endpoint" />
            <FormInput name="eventEndpoint" :label="t('event_endpoint.label')" :hint="t('event_endpoint.hint')" v-model="eventEndpoint" />
            <FormInput name="sessionEndpoint" :label="t('session_endpoint.label')" :hint="t('session_endpoint.hint')" v-model="sessionEndpoint" />
            <FormCheckbox name="disablePageViews" :label="t('disable_pv.label')" v-model="disablePageViews" />
            <FormCheckbox name="disableOutboundLinks" :label="t('disable_outbound.label')" v-model="disableOutboundLinks" />
            <FormCheckbox name="disableDownloads" :label="t('disable_downloads.label')" v-model="disableDownloads" />
            <FormCheckbox name="enableSession" :label="t('enable_session.label')" v-model="enableSession" />
            <FormInput name="outboundLinkEvent" :label="t('outbound_event.label')" v-model="outboundLinkEvent" />
            <FormInput name="downloadEvent" :label="t('download_event.label')" v-model="downloadEvent" />
            <FormInput name="notFoundEvent" :label="t('not_found_event.label')" v-model="notFoundEvent" />
            <FormInput name="include" :label="t('include.label')" :hint="t('include.hint')" v-model="include" />
            <FormInput name="exclude" :label="t('exclude.label')" :hint="t('exclude.hint')" v-model="exclude" />
        </form>
        <h5 style="margin: 12px 0 0">{{t("test.title")}}</h5>
        <form v-on:submit.prevent>
            <FormInput name="sample" :label="t('test.sample')" :hint="t('test.sample.hint')" v-model="sample" />
            <button v-on:click.stop.prevent="testConfiguration">{{t("test.submit")}}</button>
        </form>
        <p v-for="result in results" :key="result" style="margin-top: 12px;">{{result}}</p>
    </Collapsible>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, watch} from "vue";
    import Collapsible from "@/components/bits/Collapsible.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useSnippet} from "@/components/settings/snippet";
    import {debounce} from "@/util/debounce";
    import {SnippetRepo} from "@/repositories/SnippetRepo";
    import {Snippet} from "@/model/Snippet";
    import {useDomainStore} from "@/store/DomainStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Collapsible,
            FormCheckbox,
            FormInput
        },
        emits: ["update"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {domain, isAdmin} = storeToRefs(useDomainStore());
            const {loggedIn} = storeToRefs(useUserStore());
            const {getSnippetString} = useSnippet();
            const snippet = ref<Snippet>();
            const showAdvancedOptions = ref(false);
            const results = ref<string[]>([]);
            const dev = ref(false);
            const include = ref("");
            const exclude = ref("");
            const domains = ref("");
            const endpoint = ref("");
            const eventEndpoint = ref("");
            const sessionEndpoint = ref("");
            const disablePageViews = ref(false);
            const disableQueryParams = ref(false);
            const disableReferrer = ref(false);
            const disableResolution = ref(false);
            const disableOutboundLinks = ref(false);
            const disableDownloads = ref(false);
            const enableSession = ref(false);
            const rewrite = ref("");
            const pathPrefix = ref("");
            const outboundLinkEvent = ref("");
            const downloadEvent = ref("");
            const notFoundEvent = ref("");
            const sample = ref("");
            const save = debounce(async () => {
                snippet.value = {
                    domain_id: domain.value.id,
                    type: "extended",
                    dev: dev.value,
                    include: include.value,
                    exclude: exclude.value,
                    domains: domains.value,
                    endpoint: endpoint.value,
                    event_endpoint: eventEndpoint.value,
                    session_endpoint: sessionEndpoint.value,
                    disable_page_views: disablePageViews.value,
                    disable_query_params: disableQueryParams.value,
                    disable_referrer: disableReferrer.value,
                    disable_resolution: disableResolution.value,
                    disable_outbound_links: disableOutboundLinks.value,
                    disable_downloads: disableDownloads.value,
                    enable_session: enableSession.value,
                    rewrite: rewrite.value,
                    path_prefix: pathPrefix.value,
                    outbound_link_event: outboundLinkEvent.value,
                    download_event: downloadEvent.value,
                    not_found_event: notFoundEvent.value,
                    sample: sample.value
                } as Snippet;
                emit("update", getSnippetString(snippet.value));

                if (isAdmin.value) {
                    try {
                        await SnippetRepo.update(snippet.value as Snippet);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }, 300);

            onMounted(async () => {
                await loadSnippet();
            });

            watch(domain, loadSnippet);
            watch(dev, save);
            watch(include, save);
            watch(exclude, save);
            watch(domains, save);
            watch(endpoint, save);
            watch(eventEndpoint, save);
            watch(sessionEndpoint, save);
            watch(disablePageViews, save);
            watch(disableQueryParams, save);
            watch(disableReferrer, save);
            watch(disableResolution, save);
            watch(disableOutboundLinks, save);
            watch(disableDownloads, save);
            watch(enableSession, save);
            watch(rewrite, save);
            watch(pathPrefix, save);
            watch(outboundLinkEvent, save);
            watch(downloadEvent, save);
            watch(notFoundEvent, save);
            watch(sample, save);

            async function loadSnippet() {
                if (loggedIn.value) {
                    snippet.value = await SnippetRepo.get(domain.value.id, "extended") as Snippet;
                    emit("update", getSnippetString(snippet.value));

                    if (snippet.value) {
                        dev.value = snippet.value.dev;
                        include.value = snippet.value.include ?? "";
                        exclude.value = snippet.value.exclude ?? "";
                        domains.value = snippet.value.domains ?? "";
                        endpoint.value = snippet.value.endpoint ?? "";
                        eventEndpoint.value = snippet.value.event_endpoint ?? "";
                        sessionEndpoint.value = snippet.value.session_endpoint ?? "";
                        disablePageViews.value = snippet.value.disable_page_views;
                        disableQueryParams.value = snippet.value.disable_query_params;
                        disableReferrer.value = snippet.value.disable_referrer;
                        disableResolution.value = snippet.value.disable_resolution;
                        disableOutboundLinks.value = snippet.value.disable_outbound_links;
                        disableDownloads.value = snippet.value.disable_downloads;
                        enableSession.value = snippet.value.enable_session;
                        rewrite.value = snippet.value.rewrite ?? "";
                        pathPrefix.value = snippet.value.path_prefix ?? "";
                        outboundLinkEvent.value = snippet.value.outbound_link_event ?? "";
                        downloadEvent.value = snippet.value.download_event ?? "";
                        notFoundEvent.value = snippet.value.not_found_event ?? "";
                        sample.value = snippet.value.sample ?? "";
                    }
                }
            }

            function testConfiguration() {
                const samples = sample.value ? sample.value.split(",") : [];

                if (samples.length === 0) {
                    results.value = [t("error.sample")];
                    return;
                }

                const includePaths = include.value ? include.value.split(",") : [];
                const excludePaths = exclude.value ? exclude.value.split(",") : [];
                results.value = [];

                for (let i = 0; i < samples.length; i++) {
                    try {
                        if (includePaths.length) {
                            let include = false;

                            for (let j = 0; j < includePaths.length; j++) {
                                if (new RegExp(includePaths[j]).test(samples[i])) {
                                    include = true;
                                    break;
                                }
                            }

                            if (!include) {
                                results.value.push(t("included", {sample: samples[i]}));
                                continue;
                            }
                        }

                        let exclude = false;

                        for (let j = 0; j < excludePaths.length; j++) {
                            if (new RegExp(excludePaths[j]).test(samples[i])) {
                                exclude = true;
                                break;
                            }
                        }

                        if (exclude) {
                            results.value.push(t("excluded", {sample: samples[i]}));
                            continue;
                        }

                        results.value.push(t("page_view", {sample: samples[i]}));
                    } catch (e) {
                        results.value = [`${t("error")}: ${e}.`];
                        break;
                    }
                }
            }

            return {
                t,
                showAdvancedOptions,
                dev,
                include,
                exclude,
                domains,
                endpoint,
                eventEndpoint,
                sessionEndpoint,
                disablePageViews,
                disableQueryParams,
                disableReferrer,
                disableResolution,
                disableOutboundLinks,
                disableDownloads,
                enableSession,
                rewrite,
                pathPrefix,
                outboundLinkEvent,
                downloadEvent,
                notFoundEvent,
                sample,
                results,
                testConfiguration
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "show": "Show Advanced Options",
            "hide": "Hide Advanced Options",
            "script.title": "Script Configuration",
            "test.title": "Test Your Include/Exclude Configuration",
            "test.sample": "Sample Paths",
            "test.sample.hint": "Comma-separated. Example: /test,/home/page",
            "test.submit": "Test Configuration",
            "dev.label": "Development Mode",
            "rewrite.label": "Rewrite Hostname",
            "disable_query.label": "Disable tracking query parameters",
            "disable_referrer.label": "Disable tracking referrers",
            "disable_resolution.label": "Disable tracking resolution",
            "domains.label": "Domains",
            "domains.hint": "Send data to multiple dashboards and roll-up views. The identification code of the target site must be set. Example: rollup.website.com:code,additional.website.com:code",
            "path_prefix.label": "Path Prefix",
            "path_prefix.hint": "Set a prefix to the URL path for roll-up views. Example: /rollup,/view",
            "endpoint.label": "Page View Endpoint",
            "endpoint.hint": "Send data to a proxy. Example: https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Event Endpoint",
            "event_endpoint.hint": "Send data to a proxy. Example: https://pirsch.proxy.com/event",
            "session_endpoint.label": "Session Endpoint",
            "session_endpoint.hint": "Send data to a proxy. Example: https://pirsch.proxy.com/session",
            "disable_pv.label": "Disable tracking page views",
            "disable_outbound.label": "Disable tracking outbound links",
            "disable_downloads.label": "Disable tracking file downloads",
            "enable_session.label": "Enable extending sessions",
            "outbound_event.label": "Outbound link event name",
            "download_event.label": "File download event name",
            "not_found_event.label": "404 not found event name",
            "include.label": "Include Pages",
            "include.hint": "Regular expressions, comma-separated. Example: \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Exclude Pages",
            "exclude.hint": "Regular expressions, comma-separated. Example: \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" won't be included.",
            "excluded": "\"{sample}\" will be excluded.",
            "page_view": "\"{sample}\" will create a page view.",
            "error.sample": "Please enter at least one sample path.",
            "error": "An error occurred"
        },
        "de": {
            "show": "Erweiterte Optionen anzeigen",
            "hide": "Erweiterte Optionen ausblenden",
            "script.title": "Skript-Konfiguration",
            "test.title": "Teste deine Ein-/Ausschluss-Konfiguration",
            "test.sample": "Beispielpfade",
            "test.sample.hint": "Kommagetrennt. Beispiel: /test,/home/page",
            "test.submit": "Konfiguration testen",
            "dev.label": "Entwicklungsmodus",
            "rewrite.label": "Hostname umschreiben",
            "disable_query.label": "Verfolgung von Abfrageparametern deaktivieren",
            "disable_referrer.label": "Verfolgung von Referrern deaktivieren",
            "disable_resolution.label": "Verfolgung der Auflösung deaktivieren",
            "domains.label": "Domains",
            "domains.hint": "Sende Daten an mehrere Dashboards und Roll-up-Ansichten. Der Identifikationscode der Zielseite muss gesetzt werden. Beispiel: rollup.website.com:code,additional.website.com:code",
            "path_prefix.label": "Pfad-Präfix",
            "path_prefix.hint": "Setze ein Präfix für den URL-Pfad für Roll-up-Ansichten. Beispiel: /rollup,/view",
            "endpoint.label": "Seitenaufruf-Endpunkt",
            "endpoint.hint": "Sende Daten an einen Proxy. Beispiel: https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Event-Endpunkt",
            "event_endpoint.hint": "Sende Daten an einen Proxy. Beispiel: https://pirsch.proxy.com/event",
            "session_endpoint.label": "Sitzung-Endpunkt",
            "session_endpoint.hint": "Sende Daten an einen Proxy. Beispiel: https://pirsch.proxy.com/session",
            "disable_pv.label": "Verfolgung von Seitenaufrufen deaktivieren",
            "disable_outbound.label": "Verfolgung von ausgehenden Links deaktivieren",
            "disable_downloads.label": "Verfolgung von Dateidownloads deaktivieren",
            "enable_session.label": "Sitzungen verlängern aktivieren",
            "outbound_event.label": "Name des Events für ausgehende Links",
            "download_event.label": "Name des Events für Dateidownloads",
            "not_found_event.label": "Name des Events für 404 nicht gefunden",
            "include.label": "Seiten einbeziehen",
            "include.hint": "Reguläre Ausdrücke, kommagetrennt. Beispiel: \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Seiten ausschließen",
            "exclude.hint": "Reguläre Ausdrücke, kommagetrennt. Beispiel: \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" wird nicht eingeschlossen.",
            "excluded": "\"{sample}\" wird ausgeschlossen.",
            "page_view": "\"{sample}\" wird einen Seitenaufruf erstellen.",
            "error.sample": "Bitte gib mindestens einen Beispielpfad ein.",
            "error": "Ein Fehler ist aufgetreten"
        },
        "es": {
            "show": "Mostrar opciones avanzadas",
            "hide": "Ocultar opciones avanzadas",
            "script.title": "Configuración del script",
            "test.title": "Prueba tu configuración de inclusión/exclusión",
            "test.sample": "Rutas de ejemplo",
            "test.sample.hint": "Separado por comas. Ejemplo: /test,/home/page",
            "test.submit": "Probar configuración",
            "dev.label": "Modo de desarrollo",
            "rewrite.label": "Reescribir nombre de host",
            "disable_query.label": "Desactivar el seguimiento de parámetros de consulta",
            "disable_referrer.label": "Desactivar el seguimiento de referidos",
            "disable_resolution.label": "Desactivar el seguimiento de la resolución",
            "domains.label": "Dominios",
            "domains.hint": "Enviar datos a varios cuadros de mando y vistas desplegables. Debe establecerse el código de identificación del sitio de destino. Ejemplo: rollup.website.com:code,adicional.website.com:code",
            "path_prefix.label": "Prefijo de ruta",
            "path_prefix.hint": "Establece un prefijo en la ruta URL para las vistas acumuladas. Ejemplo: /rollup,/view",
            "endpoint.label": "Punto final de la vista de página",
            "endpoint.hint": "Enviar datos a un proxy. Ejemplo: https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Punto final del evento",
            "event_endpoint.hint": "Enviar datos a un proxy. Ejemplo: https://pirsch.proxy.com/event",
            "session_endpoint.label": "Punto final de la sesión",
            "session_endpoint.hint": "Enviar datos a un proxy. Ejemplo: https://pirsch.proxy.com/session",
            "disable_pv.label": "Desactivar el seguimiento de vistas de página",
            "disable_outbound.label": "Desactivar el seguimiento de enlaces salientes",
            "disable_downloads.label": "Desactivar el seguimiento de descargas de archivos",
            "enable_session.label": "Habilitar la extensión de sesiones",
            "outbound_event.label": "Nombre del evento de enlace saliente",
            "download_event.label": "Nombre del evento de descarga de archivos",
            "not_found_event.label": "Nombre del evento de 404 no encontrado",
            "include.label": "Incluir páginas",
            "include.hint": "Expresiones regulares, separadas por comas. Ejemplo: \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Excluir páginas",
            "exclude.hint": "Expresiones regulares, separadas por comas. Ejemplo: \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" no se incluirá.",
            "excluded": "\"{sample}\" será excluido.",
            "page_view": "\"{sample}\" creará una vista de página.",
            "error.sample": "Por favor, introduce al menos una ruta de ejemplo.",
            "error": "Ocurrió un error"
        },
        "fr": {
            "show": "Afficher les options avancées",
            "hide": "Masquer les options avancées",
            "script.title": "Configuration du script",
            "test.title": "Testez votre configuration d'inclusion/exclusion",
            "test.sample": "Chemins d'exemple",
            "test.sample.hint": "Séparé par des virgules. Exemple : /test,/home/page",
            "test.submit": "Tester la configuration",
            "dev.label": "Mode développement",
            "rewrite.label": "Réécrire le nom d'hôte",
            "disable_query.label": "Désactiver le suivi des paramètres de requête",
            "disable_referrer.label": "Désactiver le suivi des référents",
            "disable_resolution.label": "Désactiver le suivi de la résolution",
            "domains.label": "Domaines",
            "domains.hint": "Envoyer des données à plusieurs tableaux de bord et à des vues déroulantes. Le code d'identification du site cible doit être défini. Exemple : rollup.website.com:code,additional.website.com:code",
            "path_prefix.label": "Préfixe de chemin",
            "path_prefix.hint": "Définir un préfixe au chemin URL pour les vues cumulées. Exemple : /rollup,/view",
            "endpoint.label": "Point de terminaison de la vue de page",
            "endpoint.hint": "Envoyer des données à un proxy. Exemple : https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Point de terminaison de l'événement",
            "event_endpoint.hint": "Envoyer des données à un proxy. Exemple : https://pirsch.proxy.com/event",
            "session_endpoint.label": "Point de terminaison de la session",
            "session_endpoint.hint": "Envoyer des données à un proxy. Exemple : https://pirsch.proxy.com/session",
            "disable_pv.label": "Désactiver le suivi des vues de page",
            "disable_outbound.label": "Désactiver le suivi des liens sortants",
            "disable_downloads.label": "Désactiver le suivi des téléchargements de fichiers",
            "enable_session.label": "Activer la prolongation des sessions",
            "outbound_event.label": "Nom de l'événement de lien sortant",
            "download_event.label": "Nom de l'événement de téléchargement de fichier",
            "not_found_event.label": "Nom de l'événement 404 introuvable",
            "include.label": "Inclure des pages",
            "include.hint": "Expressions régulières, séparées par des virgules. Exemple : \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Exclure des pages",
            "exclude.hint": "Expressions régulières, séparées par des virgules. Exemple : \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" ne sera pas inclus.",
            "excluded": "\"{sample}\" sera exclu.",
            "page_view": "\"{sample}\" créera une vue de page.",
            "error.sample": "Veuillez entrer au moins un chemin d'exemple.",
            "error": "Une erreur s'est produite"
        },
        "nl": {
            "show": "Geavanceerde opties tonen",
            "hide": "Geavanceerde opties verbergen",
            "script.title": "Scriptconfiguratie",
            "test.title": "Test je In-/Uitsluitingsconfiguratie",
            "test.sample": "Voorbeeldpaden",
            "test.sample.hint": "Komma-gescheiden. Voorbeeld: /test,/home/page",
            "test.submit": "Configuratie testen",
            "dev.label": "Ontwikkelmodus",
            "rewrite.label": "Hostname herschrijven",
            "disable_query.label": "Het volgen van queryparameters uitschakelen",
            "disable_referrer.label": "Het volgen van verwijzers uitschakelen",
            "disable_resolution.label": "Het volgen van resolutie uitschakelen",
            "domains.label": "Domeinen",
            "domains.hint": "Verzend gegevens naar meerdere dashboards en roll-upweergaven. De identificatiecode van de doelsite moet worden ingesteld. Voorbeeld: rollup.website.com:code,additional.website.com:code",
            "path_prefix.label": "Padprefix",
            "path_prefix.hint": "Stel een prefix in voor het URL-pad voor samengestelde weergaven. Voorbeeld: /rollup,/view",
            "endpoint.label": "Paginaweergave-eindpunt",
            "endpoint.hint": "Stuur gegevens naar een proxy. Voorbeeld: https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Eindpunt van gebeurtenis",
            "event_endpoint.hint": "Stuur gegevens naar een proxy. Voorbeeld: https://pirsch.proxy.com/event",
            "session_endpoint.label": "Sessie-eindpunt",
            "session_endpoint.hint": "Stuur gegevens naar een proxy. Voorbeeld: https://pirsch.proxy.com/session",
            "disable_pv.label": "Het volgen van paginaweergaven uitschakelen",
            "disable_outbound.label": "Het volgen van uitgaande links uitschakelen",
            "disable_downloads.label": "Het volgen van bestandsdownloads uitschakelen",
            "enable_session.label": "Sessie-uitbreiding inschakelen",
            "outbound_event.label": "Naam van het uitgaande linkevenement",
            "download_event.label": "Naam van het bestand download-evenement",
            "not_found_event.label": "Naam van het 404 niet gevonden-evenement",
            "include.label": "Pagina's opnemen",
            "include.hint": "Reguliere expressies, komma-gescheiden. Voorbeeld: \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Pagina's uitsluiten",
            "exclude.hint": "Reguliere expressies, komma-gescheiden. Voorbeeld: \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" wordt niet opgenomen.",
            "excluded": "\"{sample}\" wordt uitgesloten.",
            "page_view": "\"{sample}\" zal een paginaweergave creëren.",
            "error.sample": "Voer ten minste één voorbeeldpad in.",
            "error": "Er is een fout opgetreden"
        },
        "it": {
            "show": "Mostra opzioni avanzate",
            "hide": "Nascondi opzioni avanzate",
            "script.title": "Configurazione script",
            "test.title": "Testa la tua configurazione di inclusione/esclusione",
            "test.sample": "Percorsi di esempio",
            "test.sample.hint": "Separati da virgole. Esempio: /test,/home/page",
            "test.submit": "Testa configurazione",
            "dev.label": "Modalità sviluppo",
            "rewrite.label": "Riscrivi hostname",
            "disable_query.label": "Disabilita tracciamento parametri query",
            "disable_referrer.label": "Disabilita tracciamento referrer",
            "disable_resolution.label": "Disabilita tracciamento risoluzione",
            "domains.label": "Domini",
            "domains.hint": "Inviare i dati a più dashboard e viste roll-up. È necessario impostare il codice di identificazione del sito di destinazione. Esempio: rollup.website.com:code,additional.website.com:code",
            "path_prefix.label": "Prefisso del percorso",
            "path_prefix.hint": "Imposta un prefisso al percorso URL per le viste riassuntive. Esempio: /rollup,/view",
            "endpoint.label": "Endpoint delle visualizzazioni di pagina",
            "endpoint.hint": "Invia dati a un proxy. Esempio: https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Endpoint dell'evento",
            "event_endpoint.hint": "Invia dati a un proxy. Esempio: https://pirsch.proxy.com/event",
            "session_endpoint.label": "Endpoint della sessione",
            "session_endpoint.hint": "Invia dati a un proxy. Esempio: https://pirsch.proxy.com/session",
            "disable_pv.label": "Disabilita tracciamento visualizzazioni di pagina",
            "disable_outbound.label": "Disabilita tracciamento link in uscita",
            "disable_downloads.label": "Disabilita tracciamento download di file",
            "enable_session.label": "Abilita estensione delle sessioni",
            "outbound_event.label": "Nome dell'evento del link in uscita",
            "download_event.label": "Nome dell'evento del download di file",
            "not_found_event.label": "Nome dell'evento 404 non trovato",
            "include.label": "Includi pagine",
            "include.hint": "Espressioni regolari, separati da virgole. Esempio: \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Escludi pagine",
            "exclude.hint": "Espressioni regolari, separati da virgole. Esempio: \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" non sarà incluso.",
            "excluded": "\"{sample}\" sarà escluso.",
            "page_view": "\"{sample}\" creerà una visualizzazione di pagina.",
            "error.sample": "Inserisci almeno un percorso di esempio.",
            "error": "Si è verificato un errore"
        },
        "pt": {
            "show": "Mostrar opções avançadas",
            "hide": "Ocultar opções avançadas",
            "script.title": "Configuração do script",
            "test.title": "Teste sua configuração de inclusão/exclusão",
            "test.sample": "Caminhos de exemplo",
            "test.sample.hint": "Separados por vírgula. Exemplo: /test,/home/page",
            "test.submit": "Testar configuração",
            "dev.label": "Modo de desenvolvimento",
            "rewrite.label": "Reescrever nome do host",
            "disable_query.label": "Desativar rastreamento de parâmetros de consulta",
            "disable_referrer.label": "Desativar rastreamento de referenciadores",
            "disable_resolution.label": "Desativar rastreamento de resolução",
            "domains.label": "Domínios",
            "domains.hint": "Enviar dados para vários dashboards e vistas de roll-up. O código de identificação do site de destino deve ser definido. Exemplo: rollup.website.com:código,additional.website.com:código",
            "path_prefix.label": "Prefixo do caminho",
            "path_prefix.hint": "Defina um prefixo para o caminho URL para visualizações de resumo. Exemplo: /rollup,/view",
            "endpoint.label": "Ponto de extremidade de visualização de página",
            "endpoint.hint": "Enviar dados para um proxy. Exemplo: https://pirsch.proxy.com/hit",
            "event_endpoint.label": "Ponto de extremidade do evento",
            "event_endpoint.hint": "Enviar dados para um proxy. Exemplo: https://pirsch.proxy.com/event",
            "session_endpoint.label": "Ponto de extremidade da sessão",
            "session_endpoint.hint": "Enviar dados para um proxy. Exemplo: https://pirsch.proxy.com/session",
            "disable_pv.label": "Desativar rastreamento de visualizações de página",
            "disable_outbound.label": "Desativar rastreamento de links de saída",
            "disable_downloads.label": "Desativar rastreamento de downloads de arquivos",
            "enable_session.label": "Habilitar extensão de sessões",
            "outbound_event.label": "Nome do evento de link de saída",
            "download_event.label": "Nome do evento de download de arquivos",
            "not_found_event.label": "Nome do evento 404 não encontrado",
            "include.label": "Incluir páginas",
            "include.hint": "Expressões regulares, separadas por vírgula. Exemplo: \/home,\/home\/[a-zA-Z]*",
            "exclude.label": "Excluir páginas",
            "exclude.hint": "Expressões regulares, separadas por vírgula. Exemplo: \/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" não será incluído.",
            "excluded": "\"{sample}\" será excluído.",
            "page_view": "\"{sample}\" criará uma visualização de página.",
            "error.sample": "Por favor, insira pelo menos um caminho de exemplo.",
            "error": "Ocorreu um erro"
        },
        "ja": {
            "show": "詳細オプションを表示",
            "hide": "詳細オプションを非表示",
            "script.title": "スクリプト設定",
            "test.title": "インクルード/エクスクルード設定をテスト",
            "test.sample": "サンプルパス",
            "test.sample.hint": "カンマ区切り。例：/test,/home/page",
            "test.submit": "設定をテスト",
            "dev.label": "開発モード",
            "rewrite.label": "ホスト名を書き換える",
            "disable_query.label": "クエリパラメータのトラッキングを無効化",
            "disable_referrer.label": "リファラのトラッキングを無効化",
            "disable_resolution.label": "解像度のトラッキングを無効化",
            "domains.label": "ドメイン",
            "domains.hint": "複数のダッシュボードやロールアップビューにデータを送信する。対象サイトの識別コードを設定する必要があります。例：rollup.website.com:code,additional.website.com:code",
            "path_prefix.label": "パスプレフィックス",
            "path_prefix.hint": "ロールアップビューのURLパスにプレフィックスを設定します。例：/rollup,/view",
            "endpoint.label": "ページビューのエンドポイント",
            "endpoint.hint": "プロキシにデータを送信します。例：https://pirsch.proxy.com/hit",
            "event_endpoint.label": "イベントのエンドポイント",
            "event_endpoint.hint": "プロキシにデータを送信します。例：https://pirsch.proxy.com/event",
            "session_endpoint.label": "セッションのエンドポイント",
            "session_endpoint.hint": "プロキシにデータを送信します。例：https://pirsch.proxy.com/session",
            "disable_pv.label": "ページビューのトラッキングを無効化",
            "disable_outbound.label": "外部リンクのトラッキングを無効化",
            "disable_downloads.label": "ファイルダウンロードのトラッキングを無効化",
            "enable_session.label": "セッション延長を有効化",
            "outbound_event.label": "外部リンクイベント名",
            "download_event.label": "ファイルダウンロードイベント名",
            "not_found_event.label": "404 Not Found イベント名",
            "include.label": "ページを含める",
            "include.hint": "正規表現、カンマ区切り。例：\/home,\/home\/[a-zA-Z]*",
            "exclude.label": "ページを除外",
            "exclude.hint": "正規表現、カンマ区切り。例：\/home,\/home\/[a-zA-Z]*",
            "included": "\"{sample}\" は含まれません。",
            "excluded": "\"{sample}\" は除外されます。",
            "page_view": "\"{sample}\" はページビューを作成します。",
            "error.sample": "少なくとも1つのサンプルパスを入力してください。",
            "error": "エラーが発生しました"
        }
    }
</i18n>
