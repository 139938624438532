<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <FormCheckbox :label="t('form.disable')" name="scripts" toggle v-model="disableScripts" v-on:change="toggleDisableScripts" />
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormCheckbox
        },
        setup() {
            const {t} = useI18n();
            const {domain} = storeToRefs(useDomainStore());
            const {success} = useToastStore();
            const disableScripts = ref(false);

            watchEffect(() => {
                if (domain.value) {
                    disableScripts.value = domain.value.disable_scripts;
                }
            });

            async function toggleDisableScripts() {
                await DomainRepo.toggleDisableScripts(domain.value.id);
                domain.value.disable_scripts = !domain.value.disable_scripts;
                success(t("toast.success"));
            }

            return {
                t,
                disableScripts,
                toggleDisableScripts
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Disable Script",
            "text": "Disable the option to send page views and events via the JavaScript snippet.",
            "form.disable": "Disable Script",
            "toast.success": "Settings saved."
        },
        "de": {
            "title": "Skript deaktivieren",
            "text": "Deaktiviere die Option, Seitenaufrufe und Events über das JavaScript-Snippet zu senden.",
            "form.disable": "Skript deaktivieren",
            "toast.success": "Einstellungen gespeichert."
        },
        "es": {
            "title": "Desactivar script",
            "text": "Desactiva la opción de enviar vistas de página y eventos a través del fragmento de JavaScript.",
            "form.disable": "Desactivar script",
            "toast.success": "Configuraciones guardadas."
        },
        "fr": {
            "title": "Désactiver le script",
            "text": "Désactivez l'option d'envoyer des vues de page et des événements via le script JavaScript.",
            "form.disable": "Désactiver le script",
            "toast.success": "Paramètres enregistrés."
        },
        "nl": {
            "title": "Script uitschakelen",
            "text": "Schakel de optie uit om paginabezoeken en evenementen via het JavaScript-fragment te verzenden.",
            "form.disable": "Script uitschakelen",
            "toast.success": "Instellingen opgeslagen."
        },
        "it": {
            "title": "Disabilita script",
            "text": "Disabilita l'opzione per inviare visualizzazioni di pagina ed eventi tramite lo snippet JavaScript.",
            "form.disable": "Disabilita script",
            "toast.success": "Impostazioni salvate."
        },
        "pt": {
            "title": "Desativar script",
            "text": "Desative a opção de enviar visualizações de página e eventos através do snippet JavaScript.",
            "form.disable": "Desativar script",
            "toast.success": "Configurações salvas."
        },
        "ja": {
            "title": "スクリプトを無効にする",
            "text": "JavaScriptスニペットを介してページビューとイベントを送信するオプションを無効にします。",
            "form.disable": "スクリプトを無効にする",
            "toast.success": "設定が保存されました。"
        }
    }
</i18n>
