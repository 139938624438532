<template>
    <Modal size="m" :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save" style="width: 100%;">
            <FormInput :label="t('form.name')" name="name" :autofocus="true" v-model="name" :err="validationError('name')" />
            <ThemeOptions :err="err"
                :defaults="themeDefaults"
                v-model="settings"
                v-on:logo-light="updateLogoLight"
                v-on:logo-dark="updateLogoDark"
                v-on:favicon="updateFavicon"
                v-on:delete-img="deleteImg" />
            <FormError :err="err" :validationErrors="true" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref, watchEffect} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {ThemeRepo} from "@/repositories/ThemeRepo";
    import {Theme} from "@/model/Theme";
    import {useDomainStore} from "@/store/DomainStore";
    import ThemeOptions from "@/components/themes/ThemeOptions.vue";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";
    import FormError from "@/components/form/FormError.vue";
    import {themeDefaults, ThemeSettings} from "@/store/theme";

    export default defineComponent({
        components: {
            FormError,
            ThemeOptions,
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            theme: Object as PropType<Theme>,
            organization: {type: String, default: ""}
        },
        emits: ["action", "update", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {load, setTheme} = useThemeStore();
            const {resetError, setError, validationError, err} = useError();
            const {success, error} = useToastStore();
            const domainStore = useDomainStore();
            const loading = ref(false);
            const name = ref("");
            const settings = ref({});
            const logoLight = ref<File | undefined>(undefined);
            const logoDark = ref<File | undefined>(undefined);
            const favicon = ref<File | undefined>(undefined);

            watchEffect(() => {
                if (props.theme) {
                    name.value = props.theme.name;
                    settings.value = props.theme.settings ?? {};
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    const logoLightFile = logoLight.value ? logoLight.value : undefined;
                    const logoDarkFile = logoDark.value ? logoDark.value : undefined;
                    const faviconFile = favicon.value ? favicon.value : undefined;
                    resetError();
                    const theme = await ThemeRepo.createUpdate(props.theme ? props.theme.id : "", "",
                        props.organization, name.value, settings.value, logoLightFile, logoDarkFile, faviconFile) as Theme;
                    success(t("toast.saved"));
                    name.value = "";
                    settings.value = {};
                    loading.value = false;
                    await load();
                    await updateThemeIfRequired();
                    emit("action", theme);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            function updateLogoLight(file: File) {
                logoLight.value = file;
            }

            function updateLogoDark(file: File) {
                logoDark.value = file;
            }

            function updateFavicon(file: File) {
                favicon.value = file;
            }

            async function deleteImg(name: string) {
                try {
                    const theme = props.theme;
                    let file = "";

                    if (name === "logo_light_file") {
                        file = ThemeSettings.logoLight;
                        delete theme?.settings[ThemeSettings.logoLight];
                    } else if (name === "logo_dark_file") {
                        file = ThemeSettings.logoDark;
                        delete theme?.settings[ThemeSettings.logoDark];
                    } else if (name === "favicon_file") {
                        file = ThemeSettings.favicon;
                        delete theme?.settings[ThemeSettings.favicon];
                    }

                    await ThemeRepo.deleteFile("", props.theme ? props.theme.id : "", file);
                    success(t("toast.image_deleted"));
                    await updateThemeIfRequired();

                    if (theme) {
                        emit("update", theme);
                    }
                } catch (e) {
                    error(e as APIError);
                }
            }

            async function updateThemeIfRequired() {
                if (props.theme && domainStore.domain.theme_id === props.theme.id) {
                    await domainStore.load();
                    setTheme(domainStore.domain);
                }
            }

            return {
                ...useI18n(),
                themeDefaults,
                validationError,
                err,
                loading,
                name,
                settings,
                logoLight,
                logoDark,
                favicon,
                save,
                updateLogoLight,
                updateLogoDark,
                updateFavicon,
                deleteImg
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Theme Settings",
            "form.name": "Name",
            "form.submit": "Save",
            "toast.saved": "Theme saved.",
            "toast.image_deleted": "Image deleted."
        },
        "de": {
            "title": "Thema-Einstellungen",
            "form.name": "Name",
            "form.submit": "Speichern",
            "toast.saved": "Thema gespeichert.",
            "toast.image_deleted": "Bild gelöscht."
        },
        "es": {
            "title": "Configuración de tema",
            "form.name": "Nombre",
            "form.submit": "Guardar",
            "toast.saved": "Tema guardado.",
            "toast.image_deleted": "Imagen eliminada."
        },
        "fr": {
            "title": "Paramètres du thème",
            "form.name": "Nom",
            "form.submit": "Enregistrer",
            "toast.saved": "Thème enregistré.",
            "toast.image_deleted": "Image supprimée."
        },
        "nl": {
            "title": "Thema-instellingen",
            "form.name": "Naam",
            "form.submit": "Opslaan",
            "toast.saved": "Thema opgeslagen.",
            "toast.image_deleted": "Afbeelding verwijderd."
        },
        "it": {
            "title": "Impostazioni del tema",
            "form.name": "Nome",
            "form.submit": "Salva",
            "toast.saved": "Tema salvato.",
            "toast.image_deleted": "Immagine eliminata."
        },
        "pt": {
            "title": "Configurações do tema",
            "form.name": "Nome",
            "form.submit": "Salvar",
            "toast.saved": "Tema salvo.",
            "toast.image_deleted": "Imagem excluída."
        },
        "ja": {
            "title": "テーマ設定",
            "form.name": "名前",
            "form.submit": "保存",
            "toast.saved": "テーマが保存されました。",
            "toast.image_deleted": "画像が削除されました。"
        }
    }
</i18n>
