<template>
    <input class="search" type="text" name="search" v-model="search" :placeholder="t('placeholder')" />
    <div class="switch">
        <i :class="{'icon icon-overview': true, active: type === OverviewType.PANEL}" :title="t('panels')" v-on:click="switchType(OverviewType.PANEL)" />
        <i :class="{'icon icon-list': true, active: type === OverviewType.LIST}" :title="t('list')" v-on:click="switchType(OverviewType.LIST)" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {storeToRefs} from "pinia";
    import {useOverviewStore, OverviewType} from "@/store/OverviewStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        emits: ["switch"],
        setup(_, {emit}) {
            const overviewStore = useOverviewStore();
            const {type} = storeToRefs(overviewStore);
            const search = ref("");

            watch(search, value => {
                overviewStore.setSearch(value);
            });

            function switchType(type: OverviewType) {
                overviewStore.set(type);
                emit("switch", type);
            }

            return {
                ...useI18n(),
                OverviewType,
                type,
                search,
                switchType
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "placeholder": "Search...",
            "panels": "Panels",
            "list": "List"
        },
        "de": {
            "placeholder": "Suchen...",
            "panels": "Panels",
            "list": "Liste"
        },
        "es": {
            "placeholder": "Buscar...",
            "panels": "Paneles",
            "list": "Lista"
        },
        "fr": {
            "placeholder": "Rechercher...",
            "panels": "Panneaux",
            "list": "Liste"
        },
        "nl": {
            "placeholder": "Zoeken...",
            "panels": "Panelen",
            "list": "Lijst"
        },
        "it": {
            "placeholder": "Cerca...",
            "panels": "Pannelli",
            "list": "Elenco"
        },
        "pt": {
            "placeholder": "Pesquisar...",
            "panels": "Painéis",
            "list": "Lista"
        },
        "ja": {
            "placeholder": "検索...",
            "panels": "パネル",
            "list": "リスト"
        }
    }
</i18n>
