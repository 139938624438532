<template>
    <section class="form">
        <template v-if="success">
            <h1>{{t("success.title")}}</h1>
            <p>{{t("success.text")}}</p>
        </template>
        <template v-else-if="err && err.error.length > 0">
            <h1>{{t("error.title")}}</h1>
            <p>{{t("error.text")}}</p>
        </template>
        <router-link to="/" class="button secondary" v-if="loggedIn">{{t("to_dashboard")}}</router-link>
        <router-link to="/login" class="button secondary" v-else>{{t("to_login")}}</router-link>
        <AppFooterSmall />
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import {useRoute} from "vue-router";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import AppFooterSmall from "@/components/bits/AppFooterSmall.vue";
    import {useI18n} from "vue-i18n";
    import {APIError} from "@/repositories/APIError";

    export default defineComponent({
        components: {AppFooterSmall},
        setup() {
            const route = useRoute();
            const {setError, err} = useError();
            const {loggedIn} = storeToRefs(useUserStore());
            const success = ref(false);

            onMounted(async () => {
                try {
                    const code = route.query.code || "";
                    await UserRepo.confirmEmail(code as string);
                    success.value = true;
                } catch (e) {
                    setError(e as APIError);
                }
            });

            return {
                ...useI18n(),
                err,
                loggedIn,
                success
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "success.title": "Success",
            "success.text": "Your new email address has been confirmed.",
            "error.title": "Error",
            "error.text": "The confirmation code was not found.",
            "to_dashboard": "To the Dashboard",
            "to_login": "To the Login"
        },
        "de": {
            "success.title": "Erfolg",
            "success.text": "Deine neue E-Mail-Adresse wurde bestätigt.",
            "error.title": "Fehler",
            "error.text": "Der Bestätigungscode wurde nicht gefunden.",
            "to_dashboard": "Zum Dashboard",
            "to_login": "Zum Login"
        },
        "es": {
            "success.title": "Éxito",
            "success.text": "Tu nueva dirección de correo electrónico ha sido confirmada.",
            "error.title": "Error",
            "error.text": "El código de confirmación no fue encontrado.",
            "to_dashboard": "Al Panel de control",
            "to_login": "Al Inicio de sesión"
        },
        "fr": {
            "success.title": "Succès",
            "success.text": "Votre nouvelle adresse e-mail a été confirmée.",
            "error.title": "Erreur",
            "error.text": "Le code de confirmation n'a pas été trouvé.",
            "to_dashboard": "Au Tableau de bord",
            "to_login": "À la Connexion"
        },
        "nl": {
            "success.title": "Succes",
            "success.text": "Je nieuwe e-mailadres is bevestigd.",
            "error.title": "Fout",
            "error.text": "De bevestigingscode is niet gevonden.",
            "to_dashboard": "Naar het Dashboard",
            "to_login": "Naar de Login"
        },
        "it": {
            "success.title": "Successo",
            "success.text": "Il tuo nuovo indirizzo email è stato confermato.",
            "error.title": "Errore",
            "error.text": "Il codice di conferma non è stato trovato.",
            "to_dashboard": "Al Dashboard",
            "to_login": "Al Login"
        },
        "pt": {
            "success.title": "Sucesso",
            "success.text": "Seu novo endereço de e-mail foi confirmado.",
            "error.title": "Erro",
            "error.text": "O código de confirmação não foi encontrado.",
            "to_dashboard": "Para o Painel",
            "to_login": "Para o Login"
        },
        "ja": {
            "success.title": "成功",
            "success.text": "新しいメールアドレスが確認されました。",
            "error.title": "エラー",
            "error.text": "確認コードが見つかりませんでした。",
            "to_dashboard": "ダッシュボードへ",
            "to_login": "ログインへ"
        }
    }
</i18n>
