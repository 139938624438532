<template>
    <SettingsPage active="general">
        <Subdomain />
        <hr />
        <template v-if="showCustomDomain">
            <CustomDomain />
            <hr />
        </template>
        <ActiveVisitorsSettings />
        <hr />
        <DomainTimezone />
        <hr />
        <GroupByTitle />
        <hr />
        <DisplayName />
        <hr />
        <DashboardSettings />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Subdomain from "@/components/settings/Subdomain.vue";
    import DomainTimezone from "@/components/settings/DomainTimezone.vue";
    import GroupByTitle from "@/components/settings/GroupByTitle.vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import ActiveVisitorsSettings from "@/components/settings/ActiveVisitorsSettings.vue";
    import DashboardSettings from "@/components/settings/DashboardSettings.vue";
    import CustomDomain from "@/components/settings/CustomDomain.vue";
    import DisplayName from "@/components/settings/DisplayName.vue";

    export default defineComponent({
        components: {
            DisplayName,
            CustomDomain,
            DashboardSettings,
            ActiveVisitorsSettings,
            SettingsPage,
            Subdomain,
            DomainTimezone,
            GroupByTitle
        },
        setup() {
            return {
                showCustomDomain: import.meta.env.VITE_APP_SHOW_CUSTOM_DOMAIN === "true"
            };
        }
    });
</script>
