<template>
    <Modal :title="t('title')" v-on:close="$emit('close')">
        <p v-if="id">{{t("text_0")}}</p>
        <p v-else>{{t("text_1")}}</p>
        <div class="input-with-icon" v-if="id">
            <FormInput :label="t('form.client_id')" name="clientID" v-model="id" disabled />
            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyClipboard(id)"></i>
        </div>
        <div class="input-with-icon">
            <FormInput :label="t('form.client_secret')" name="clientSecret" v-model="secret" disabled />
            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyClipboard(secret)"></i>
        </div>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import copy from "copy-to-clipboard";
    import {useToastStore} from "@/store/ToastStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput
        },
        props: {
            client: Object
        },
        emits: ["close"],
        setup(props) {
            const {t} = useI18n();
            const {success} = useToastStore();
            const id = ref("");
            const secret = ref("");

            watch(() => props.client, value => {
                if (value) {
                    id.value = value.client_id;
                    secret.value = value.client_secret;
                }
            });

            function copyClipboard(value: string) {
                copy(value);
                success(t("toast.clipboard"));
            }

            return {
                t,
                id,
                secret,
                copyClipboard
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Client Secret",
            "text_0": "This is the client ID and secret. Please save the secret at a secure place, as it won't be shown again.",
            "text_1": "This is the client secret which should be treated as a password. Please save it at a secure place, as it won't be shown again.",
            "form.client_id": "Client ID",
            "form.client_secret": "Client secret",
            "copy_to_clipboard": "Copy to Clipboard",
            "toast.clipboard": "Copied to clipboard."
        },
        "de": {
            "title": "Client-Geheimnis",
            "text_0": "Dies sind die Client-ID und das Geheimnis. Bitte speichere das Geheimnis an einem sicheren Ort, da es nicht erneut angezeigt wird.",
            "text_1": "Dies ist das Client-Geheimnis, das wie ein Passwort behandelt werden sollte. Bitte speichere es an einem sicheren Ort, da es nicht erneut angezeigt wird.",
            "form.client_id": "Client-ID",
            "form.client_secret": "Client-Geheimnis",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "toast.clipboard": "In die Zwischenablage kopiert."
        },
        "es": {
            "title": "Secreto del cliente",
            "text_0": "Este es el ID de cliente y el secreto. Guarda el secreto en un lugar seguro, ya que no se volverá a mostrar.",
            "text_1": "Este es el secreto del cliente que debe tratarse como una contraseña. Guárdalo en un lugar seguro, ya que no se volverá a mostrar.",
            "form.client_id": "ID de cliente",
            "form.client_secret": "Secreto del cliente",
            "copy_to_clipboard": "Copiar al portapapeles",
            "toast.clipboard": "Copiado al portapapeles."
        },
        "fr": {
            "title": "Secret client",
            "text_0": "Voici l'ID client et le secret. Veuillez enregistrer le secret dans un endroit sûr, car il ne sera plus affiché.",
            "text_1": "Ceci est le secret client qui doit être traité comme un mot de passe. Veuillez l'enregistrer dans un endroit sûr, car il ne sera plus affiché.",
            "form.client_id": "ID client",
            "form.client_secret": "Secret client",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "toast.clipboard": "Copié dans le presse-papiers."
        },
        "nl": {
            "title": "Clientgeheim",
            "text_0": "Dit is de client-ID en het geheim. Sla het geheim op een veilige plaats op, aangezien het niet opnieuw wordt weergegeven.",
            "text_1": "Dit is het clientgeheim dat als een wachtwoord moet worden behandeld. Sla het op een veilige plaats op, aangezien het niet opnieuw wordt weergegeven.",
            "form.client_id": "Client-ID",
            "form.client_secret": "Clientgeheim",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "toast.clipboard": "Gekopieerd naar klembord."
        },
        "it": {
            "title": "Segreto del cliente",
            "text_0": "Questo è l'ID cliente e il segreto. Salva il segreto in un luogo sicuro, poiché non verrà più mostrato.",
            "text_1": "Questo è il segreto del cliente che deve essere trattato come una password. Salvalo in un luogo sicuro, poiché non verrà più mostrato.",
            "form.client_id": "ID cliente",
            "form.client_secret": "Segreto del cliente",
            "copy_to_clipboard": "Copia negli appunti",
            "toast.clipboard": "Copiato negli appunti."
        },
        "pt": {
            "title": "Segredo do cliente",
            "text_0": "Este é o ID do cliente e o segredo. Guarde o segredo em um lugar seguro, pois ele não será exibido novamente.",
            "text_1": "Este é o segredo do cliente que deve ser tratado como uma senha. Guarde-o em um lugar seguro, pois ele não será exibido novamente.",
            "form.client_id": "ID do cliente",
            "form.client_secret": "Segredo do cliente",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "toast.clipboard": "Copiado para a área de transferência."
        },
        "ja": {
            "title": "クライアントシークレット",
            "text_0": "これはクライアントIDとシークレットです。シークレットは再表示されないため、安全な場所に保存してください。",
            "text_1": "これはパスワードとして扱われるべきクライアントシークレットです。再表示されないため、安全な場所に保存してください。",
            "form.client_id": "クライアントID",
            "form.client_secret": "クライアントシークレット",
            "copy_to_clipboard": "クリップボードにコピー",
            "toast.clipboard": "クリップボードにコピーしました。"
        }
    }
</i18n>
