<template>
    <Modal :title="t('title')" size="xl" :open="open" v-on:close="$emit('close')">
        <CountryMap :load="open" v-on:select="filterByCountry" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import CountryMap from "@/components/statistics/CountryMap.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            CountryMap,
            Modal
        },
        props: {
            open: {type: Boolean, required: true}
        },
        emits: ["close"],
        setup() {
            const filterStore = useFilterStore();
            const {setField} = filterStore;

            function filterByCountry(code: string) {
                setField("country", code);
            }

            return {
                ...useI18n(),
                filterByCountry
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Map"
        },
        "de": {
            "title": "Karte"
        },
        "es": {
            "title": "Mapa"
        },
        "fr": {
            "title": "Carte"
        },
        "nl": {
            "title": "Kaart"
        },
        "it": {
            "title": "Mappa"
        },
        "pt": {
            "title": "Mapa"
        },
        "ja": {
            "title": "地図"
        }
    }
</i18n>
