<template>
    <h1>{{t("title")}}</h1>
    <template v-if="showCheckout">
        <Subscription />
        <hr />
    </template>
    <UsageLimit />
    <hr />
    <WarningLimit />
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import UsageLimit from "@/components/billing/UsageLimit.vue";
    import WarningLimit from "@/components/billing/WarningLimit.vue";
    import Subscription from "@/components/billing/Subscription.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            UsageLimit,
            WarningLimit,
            Subscription
        },
        setup() {
            return {
                ...useI18n(),
                showCheckout: import.meta.env.VITE_APP_SHOW_CHECKOUT
            }
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Billing"
        },
        "de": {
            "title": "Abrechnung"
        },
        "es": {
            "title": "Facturación"
        },
        "fr": {
            "title": "Facturation"
        },
        "nl": {
            "title": "Facturatie"
        },
        "it": {
            "title": "Fatturazione"
        },
        "pt": {
            "title": "Faturamento"
        },
        "ja": {
            "title": "請求"
        }
    }
</i18n>
