<template>
    <div class="card session-card">
        <div class="card-content cursor-pointer no-select" v-on:click="toggle">
            <div class="card-info">
                <div class="left">
                    <div class="icons">
                        <i :class="flag" :title="country" v-if="flag" />
                        <img :src="osIcon" alt="" :title="session.os" v-if="osIcon" />
                        <img :src="browserIcon" alt="" :title="session.browser" v-if="browserIcon" />
                    </div>
                    <h4>{{id}}</h4>
                </div>
                <div class="right">
                    <p>
                        {{session.entry_path}} {{session.entry_title}}
                        &rArr;
                        {{session.exit_path}} {{session.exit_title}}
                    </p>
                    <p>{{t("page_views", {count: session.page_views})}}</p>
                    <p>{{dayTimeShort(session.time)}}</p>
                    <p style="min-width: 20px">{{formatSeconds(session.duration_seconds)}}<template v-if="session.duration_seconds === 0">s</template></p>
                </div>
            </div>
            <i :class="{'icon icon-chevron clickable': true, 'icon-rotate-180': details}"></i>
        </div>
        <Expand>
            <div class="card-details" style="position: relative;" v-show="details">
                <div class="left" v-if="!loading">
                    <SessionStep v-for="step in steps"
                        :step="step"
                        :no="step.no"
                        :key="step.no" />
                </div>
                <div style="display: flex; justify-content: center; align-items: center;" v-if="loading">
                    <Loading :loading="loading" />
                </div>
                <div class="right">
                    <div class="row">
                        <div>
                            <h5>{{t("title.date_time")}}</h5>
                            <p><strong>{{dayTimeShort(session.start)}}</strong></p>
                        </div>
                        <div>
                            <h5>{{t("title.session_duration")}}</h5>
                            <p><strong>{{formatSeconds(session.duration_seconds)}}<template v-if="session.duration_seconds === 0">s</template></strong></p>
                        </div>
                    </div>
                    <div class="row" v-if="referrer || session.utm_source || session.utm_medium || session.utm_campaign || session.utm_content || session.utm_term">
                        <div v-if="referrer">
                            <h5>{{t("referrer")}}</h5>
                            <p><strong>{{referrer}}</strong></p>
                        </div>
                        <div v-if="session.utm_source">
                            <h5>{{t("utm_source")}}</h5>
                            <p><strong>{{session.utm_source}}</strong></p>
                        </div>
                        <div v-if="session.utm_medium">
                            <h5>{{t("utm_medium")}}</h5>
                            <p><strong>{{session.utm_medium}}</strong></p>
                        </div>
                        <div v-if="session.utm_campaign">
                            <h5>{{t("utm_campaign")}}</h5>
                            <p><strong>{{session.utm_campaign}}</strong></p>
                        </div>
                        <div v-if="session.utm_content">
                            <h5>{{t("utm_content")}}</h5>
                            <p><strong>{{session.utm_content}}</strong></p>
                        </div>
                        <div v-if="session.utm_term">
                            <h5>{{t("utm_term")}}</h5>
                            <p><strong>{{session.utm_term}}</strong></p>
                        </div>
                    </div>
                    <div class="row" v-if="country || session.region || session.city || session.language">
                        <div v-if="country">
                            <h5>{{t("country")}}</h5>
                            <p><strong>{{country}}</strong></p>
                        </div>
                        <div v-if="session.city">
                            <h5>{{t("city")}}</h5>
                            <p><strong>{{session.city}}</strong></p>
                        </div>
                        <div v-if="session.region">
                            <h5>{{t("region")}}</h5>
                            <p><strong>{{session.region}}</strong></p>
                        </div>
                        <div v-if="session.language">
                            <h5>{{t("language")}}</h5>
                            <p><strong>{{language}}</strong></p>
                        </div>
                    </div>
                    <div class="row" v-if="session.os || session.browser || session.screen_class">
                        <div v-if="session.os">
                            <h5>{{t("os")}}</h5>
                            <p><strong>{{session.os}}</strong></p>
                        </div>
                        <div v-if="session.browser">
                            <h5>{{t("browser")}}</h5>
                            <p><strong>{{session.browser}}</strong></p>
                        </div>
                        <div v-if="session.screen_class">
                            <h5>{{t("screen")}}</h5>
                            <p><strong>{{session.screen_class}}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </Expand>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref} from "vue";
    import {Session} from "@/model/Session";
    import {dayTimeShort, formatSeconds} from "@/util/format";
    import {getCountry, getCountryCode, selectBrowserIcon, selectOSIcon, trimProtocol} from "@/store/StatisticsStore";
    import {hasFlag} from "country-flag-icons";
    import ISO6391 from "iso-639-1";
    import {getFavicon} from "@/util/favicon";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {SessionStep as SessionStepType} from "@/model/SessionStep";
    import Loading from "@/components/bits/Loading.vue";
    import SessionStep from "@/components/sessions/SessionStep.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            SessionStep,
            Loading,
            Expand
        },
        props: {
            session: {type: Object as PropType<Session>, required: true}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const {copy} = filterStore;
            const {filter} = storeToRefs(filterStore);
            const details = ref(false);
            const loading = ref(true);
            const steps = ref<SessionStepType[]>([]);
            const id = computed(() => {
                const b64 = btoa(props.session.visitor_id.toString()+props.session.session_id.toString());
                return b64.substring(2, 4)+b64.substring(6, 8)+b64.substring(14, 16)+b64.substring(18, 20);
            });
            const country = computed(() => getCountry(getCountryCode(props.session.country_code)));
            const countryName = computed(() => country.value?.country ?? "");
            const flag = computed(() => country.value && hasFlag(country.value.alpha2) ? `flag:${country.value.alpha2}` : "");
            const osIcon = computed(() => selectOSIcon(props.session.os));
            const browserIcon = computed(() => selectBrowserIcon(props.session.browser));
            const referrer = computed(() => trimProtocol(props.session.referrer));
            const referrerIcon = computed(() => props.session.referrer_icon ? props.session.referrer_icon : getFavicon(props.session.referrer));
            const language = computed(() => ISO6391.getName(props.session.language) ?? "");

            async function toggle() {
                details.value = !details.value;

                if (loading.value) {
                    const f = copy(filter.value);
                    f.visitor_id = props.session.visitor_id;
                    f.session_id = props.session.session_id;
                    const s = await StatisticsRepo.session(f) as SessionStepType[] || [];
                    let pvNo = 1;
                    let eventNo = 1;

                    for (let i = 0; i < s.length; i++) {
                        if (s[i].page_view) {
                            s[i].no = pvNo;
                            pvNo++;
                            eventNo = 1;
                        } else {
                            if (eventNo < 26) {
                                s[i].no = String.fromCharCode(eventNo + 64);
                            } else {
                                s[i].no = eventNo;
                            }

                            eventNo++;
                        }
                    }

                    steps.value = s;
                    loading.value = false;
                }
            }

            return {
                ...useI18n(),
                dayTimeShort,
                formatSeconds,
                details,
                loading,
                steps,
                id,
                country: countryName,
                flag,
                osIcon,
                browserIcon,
                referrer,
                referrerIcon,
                language,
                toggle
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "page_views": "1 Page View | {count} Page Views",
            "title.date_time": "Date and Time",
            "title.session_duration": "Session Duration",
            "referrer": "Referrer",
            "utm_source": "UTM Source",
            "utm_medium": "UTM Medium",
            "utm_campaign": "UTM Campaign",
            "utm_content": "UTM Content",
            "utm_term": "UTM Term",
            "country": "Country",
            "city": "City",
            "region": "Region",
            "language": "Language",
            "os": "OS",
            "browser": "Browser",
            "screen": "Screen"
        },
        "de": {
            "page_views": "1 Seitenaufruf | {count} Seitenaufrufe",
            "title.date_time": "Datum und Uhrzeit",
            "title.session_duration": "Sitzungsdauer",
            "referrer": "Referrer",
            "utm_source": "UTM-Quelle",
            "utm_medium": "UTM-Medium",
            "utm_campaign": "UTM-Kampagne",
            "utm_content": "UTM-Inhalt",
            "utm_term": "UTM-Begriff",
            "country": "Land",
            "city": "Stadt",
            "region": "Region",
            "language": "Sprache",
            "os": "Betriebssystem",
            "browser": "Browser",
            "screen": "Bildschirm"
        },
        "es": {
            "page_views": "1 vista de página | {count} vistas de página",
            "title.date_time": "Fecha y hora",
            "title.session_duration": "Duración de la sesión",
            "referrer": "Referente",
            "utm_source": "Fuente UTM",
            "utm_medium": "Medio UTM",
            "utm_campaign": "Campaña UTM",
            "utm_content": "Contenido UTM",
            "utm_term": "Término UTM",
            "country": "País",
            "city": "Ciudad",
            "region": "Región",
            "language": "Idioma",
            "os": "Sistema operativo",
            "browser": "Navegador",
            "screen": "Pantalla"
        },
        "fr": {
            "page_views": "1 vue de page | {count} vues de page",
            "title.date_time": "Date et heure",
            "title.session_duration": "Durée de la session",
            "referrer": "Référent",
            "utm_source": "Source UTM",
            "utm_medium": "Moyen UTM",
            "utm_campaign": "Campagne UTM",
            "utm_content": "Contenu UTM",
            "utm_term": "Terme UTM",
            "country": "Pays",
            "city": "Ville",
            "region": "Région",
            "language": "Langue",
            "os": "Système d'exploitation",
            "browser": "Navigateur",
            "screen": "Écran"
        },
        "nl": {
            "page_views": "1 paginaweergave | {count} paginaweergaven",
            "title.date_time": "Datum en tijd",
            "title.session_duration": "Sessie duur",
            "referrer": "Referrer",
            "utm_source": "UTM-bron",
            "utm_medium": "UTM-medium",
            "utm_campaign": "UTM-campagne",
            "utm_content": "UTM-inhoud",
            "utm_term": "UTM-term",
            "country": "Land",
            "city": "Stad",
            "region": "Regio",
            "language": "Taal",
            "os": "Besturingssysteem",
            "browser": "Browser",
            "screen": "Scherm"
        },
        "it": {
            "page_views": "1 visualizzazione di pagina | {count} visualizzazioni di pagina",
            "title.date_time": "Data e ora",
            "title.session_duration": "Durata della sessione",
            "referrer": "Referrer",
            "utm_source": "Sorgente UTM",
            "utm_medium": "Mezzo UTM",
            "utm_campaign": "Campagna UTM",
            "utm_content": "Contenuto UTM",
            "utm_term": "Termine UTM",
            "country": "Paese",
            "city": "Città",
            "region": "Regione",
            "language": "Lingua",
            "os": "Sistema operativo",
            "browser": "Browser",
            "screen": "Schermo"
        },
        "pt": {
            "page_views": "1 visualização de página | {count} visualizações de página",
            "title.date_time": "Data e Hora",
            "title.session_duration": "Duração da Sessão",
            "referrer": "Referência",
            "utm_source": "Fonte UTM",
            "utm_medium": "Meio UTM",
            "utm_campaign": "Campanha UTM",
            "utm_content": "Conteúdo UTM",
            "utm_term": "Termo UTM",
            "country": "País",
            "city": "Cidade",
            "region": "Região",
            "language": "Idioma",
            "os": "Sistema Operacional",
            "browser": "Navegador",
            "screen": "Tela"
        },
        "ja": {
            "page_views": "1 ページビュー | {count} ページビュー",
            "title.date_time": "日時",
            "title.session_duration": "セッションの長さ",
            "referrer": "リファラー",
            "utm_source": "UTM ソース",
            "utm_medium": "UTM メディア",
            "utm_campaign": "UTM キャンペーン",
            "utm_content": "UTM コンテンツ",
            "utm_term": "UTM ターム",
            "country": "国",
            "city": "都市",
            "region": "地域",
            "language": "言語",
            "os": "OS",
            "browser": "ブラウザ",
            "screen": "画面"
        }
    }
</i18n>
