<template>
    <SettingsPage active="filter">
        <TrafficFilter :loading="loading"
            :filter="ipFilter"
            type="ip"
            :title="t('ip.title')"
            :description="t('ip.description')"
            :help="t('ip.help')"
            :example="t('ip.example')"
            v-on:add="addFilter"
            v-on:update="updateFilter"
            v-on:remove="removeFilter" />
        <hr />
        <TrafficFilter :loading="loading"
            :filter="pageFilter"
            type="page"
            :title="t('page.title')"
            :description="t('page.description')"
            :help="t('page.help')"
            :example="t('page.example')"
            v-on:add="addFilter"
            v-on:update="updateFilter"
            v-on:remove="removeFilter" />
        <hr />
        <TrafficFilter :loading="loading"
            :filter="countryFilter"
            type="country"
            :title="t('country.title')"
            :description="t('country.description')"
            :help="t('country.help')"
            :countryList="true"
            v-on:add="addFilter"
            v-on:update="updateFilter"
            v-on:remove="removeFilter" />
        <hr />
        <TrafficFilter :loading="loading"
            :filter="hostnameFilter"
            type="hostname"
            :title="t('hostname.title')"
            :description="t('hostname.description')"
            :help="t('hostname.help')"
            :example="t('hostname.example')"
            v-on:add="addFilter"
            v-on:update="updateFilter"
            v-on:remove="removeFilter" />
    </SettingsPage>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watchEffect} from "vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import TrafficFilter from "@/components/settings/TrafficFilter.vue";
    import {TrafficFilterRepo} from "@/repositories/TrafficFilterRepo";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {TrafficFilter as TrafficFilterType} from "@/model/TrafficFilter";
    import {useList} from "@/components/list";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            TrafficFilter,
            SettingsPage
        },
        setup() {
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(true);
            const filter = ref<TrafficFilterType[]>([]);
            const ipFilter = computed(() => filter.value.filter(f => f.type === "ip"));
            const hostnameFilter = computed(() => filter.value.filter(f => f.type === "hostname"));
            const pageFilter = computed(() => filter.value.filter(f => f.type === "page"));
            const countryFilter = computed(() => filter.value.filter(f => f.type === "country"));

            watchEffect(async () => {
                if (domain.value.id) {
                    loading.value = true;
                    filter.value = await TrafficFilterRepo.list(domain.value.id) as TrafficFilterType[] || [];
                    loading.value = false;
                }
            });

            function addFilter(trafficFilter: TrafficFilterType) {
                filter.value.push(trafficFilter);
            }

            function updateFilter(trafficFilter: TrafficFilterType) {
                updateEntryByID(filter.value, trafficFilter);
            }

            function removeFilter(trafficFilter: TrafficFilterType) {
                removeEntryByID(filter.value, trafficFilter);
            }

            return {
                ...useI18n(),
                loading,
                ipFilter,
                hostnameFilter,
                pageFilter,
                countryFilter,
                addFilter,
                updateFilter,
                removeFilter
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "ip.title": "IP Address Filter",
            "ip.description": "Filter traffic by IP address or CIDR.",
            "ip.help": "Filter traffic based on the IP address or CIDR. CIDR allows you to exclude entire networks.",
            "ip.example": "IP address or CIDR, e.g. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Hostname Whitelist",
            "hostname.description": "Allow traffic from specific hostnames only. All sources are accepted if the list is empty.",
            "hostname.help": "Allow traffic only from specific hostnames. When you add one or more hostnames, the whitelist is enabled.",
            "hostname.example": "The hostname of your domain supporting wildcards, e.g. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Page Filter",
            "page.description": "Filter traffic by page or group of pages.",
            "page.help": "Filter traffic based on path. Wildcards are supported so you can group pages.",
            "page.example": "The path of the pages to exclude supporting wildcards, e.g. /home, /home/*, /blog-**. Double asterisks match slashes, single asterisks don't.",
            "country.title": "Country Filter",
            "country.description": "Filter traffic by country.",
            "country.help": "Filter traffic by the country of origin."
        },
        "de": {
            "ip.title": "IP-Adressfilter",
            "ip.description": "Traffic nach IP-Adresse oder CIDR filtern.",
            "ip.help": "Filtere den Traffic basierend auf der IP-Adresse oder CIDR. CIDR ermöglicht es Ihnen, ganze Netzwerke auszuschließen.",
            "ip.example": "IP-Adresse oder CIDR, z.B. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Hostname-Whitelist",
            "hostname.description": "Erlaube nur Traffic von bestimmten Hostnamen. Alle Quellen werden akzeptiert, wenn die Liste leer ist.",
            "hostname.help": "Erlaube nur Traffic von bestimmten Hostnamen. Wenn du einen oder mehrere Hostnamen hinzufügst, wird die Whitelist aktiviert.",
            "hostname.example": "Der Hostname Ihrer Domain unterstützt Platzhalter, z.B. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Seitenfilter",
            "page.description": "Traffic nach Seite oder Seitengruppe filtern.",
            "page.help": "Filtere den Traffic basierend auf dem Pfad. Platzhalter werden unterstützt, sodass du Seiten gruppieren kannst.",
            "page.example": "Der Pfad der auszuschließenden Seiten mit Platzhaltern, z. B. /home, /home/*, /blog-**. Doppelte Sternchen passen zu Schrägstrichen, einfache Sternchen nicht.",
            "country.title": "Länderfilter",
            "country.description": "Traffic nach Land filtern.",
            "country.help": "Filtere den Traffic nach dem Herkunftsland."
        },
        "es": {
            "ip.title": "Filtro de Dirección IP",
            "ip.description": "Filtrar tráfico por dirección IP o CIDR.",
            "ip.help": "Filtra el tráfico basado en la dirección IP o CIDR. CIDR te permite excluir redes enteras.",
            "ip.example": "Dirección IP o CIDR, p.ej. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Lista Blanca de Nombres de Host",
            "hostname.description": "Permitir tráfico solo de nombres de host específicos. Todas las fuentes son aceptadas si la lista está vacía.",
            "hostname.help": "Permitir tráfico solo de nombres de host específicos. Cuando agregas uno o más nombres de host, la lista blanca está habilitada.",
            "hostname.example": "El nombre de host de tu dominio que soporta comodines, p.ej. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Filtro de Página",
            "page.description": "Filtrar tráfico por página o grupo de páginas.",
            "page.help": "Filtra el tráfico basado en la ruta. Se soportan comodines para que puedas agrupar páginas.",
            "page.example": "La ruta de las páginas a excluir con comodines, por ejemplo /home, /home/*, /blog-**. Los asteriscos dobles coinciden con las barras, los simples no.",
            "country.title": "Filtro de País",
            "country.description": "Filtrar tráfico por país.",
            "country.help": "Filtra el tráfico por el país de origen."
        },
        "fr": {
            "ip.title": "Filtre d'Adresse IP",
            "ip.description": "Filtrer le trafic par adresse IP ou CIDR.",
            "ip.help": "Filtrer le trafic en fonction de l'adresse IP ou du CIDR. CIDR vous permet d'exclure des réseaux entiers.",
            "ip.example": "Adresse IP ou CIDR, par ex. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Liste Blanche des Noms d'Hôte",
            "hostname.description": "Autoriser le trafic uniquement à partir de noms d'hôte spécifiques. Toutes les sources sont acceptées si la liste est vide.",
            "hostname.help": "Autoriser le trafic uniquement à partir de noms d'hôte spécifiques. Lorsque vous ajoutez un ou plusieurs noms d'hôte, la liste blanche est activée.",
            "hostname.example": "Le nom d'hôte de votre domaine supportant les caractères génériques, par ex. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Filtre de Page",
            "page.description": "Filtrer le trafic par page ou groupe de pages.",
            "page.help": "Filtrer le trafic en fonction du chemin. Les caractères génériques sont pris en charge, vous pouvez donc regrouper les pages.",
            "page.example": "Le chemin des pages à exclure en utilisant des caractères génériques, par exemple /home, /home/*, /blog-**. Les doubles astérisques correspondent aux barres obliques, les simples astérisques non.",
            "country.title": "Filtre de Pays",
            "country.description": "Filtrer le trafic par pays.",
            "country.help": "Filtrer le trafic par pays d'origine."
        },
        "nl": {
            "ip.title": "IP-adresfilter",
            "ip.description": "Verkeer filteren op IP-adres of CIDR.",
            "ip.help": "Filter verkeer op basis van het IP-adres of CIDR. CIDR stelt je in staat om hele netwerken uit te sluiten.",
            "ip.example": "IP-adres of CIDR, bijv. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Hostname-whitelist",
            "hostname.description": "Alleen verkeer van specifieke hostnamen toestaan. Alle bronnen worden geaccepteerd als de lijst leeg is.",
            "hostname.help": "Sta alleen verkeer van specifieke hostnamen toe. Wanneer je een of meer hostnamen toevoegt, wordt de whitelist ingeschakeld.",
            "hostname.example": "De hostname van je domein dat wildcards ondersteunt, bijv. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Paginafilter",
            "page.description": "Verkeer filteren op pagina of groep pagina's.",
            "page.help": "Filter verkeer op basis van pad. Wildcards worden ondersteund, zodat je pagina's kunt groeperen.",
            "page.example": "Het pad van de pagina's die moeten worden uitgesloten met jokertekens, bijvoorbeeld /home, /home/*, /blog-**. Dubbele sterretjes komen overeen met schuine strepen, enkele sterretjes niet.",
            "country.title": "Landenfilter",
            "country.description": "Verkeer filteren op land.",
            "country.help": "Filter verkeer op het land van herkomst."
        },
        "it": {
            "ip.title": "Filtro Indirizzo IP",
            "ip.description": "Filtra il traffico per indirizzo IP o CIDR.",
            "ip.help": "Filtra il traffico in base all'indirizzo IP o CIDR. CIDR ti consente di escludere intere reti.",
            "ip.example": "Indirizzo IP o CIDR, ad es. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Whitelist del Nome Host",
            "hostname.description": "Consenti traffico solo da nomi host specifici. Tutte le fonti sono accettate se l'elenco è vuoto.",
            "hostname.help": "Consenti traffico solo da nomi host specifici. Quando aggiungi uno o più nomi host, la whitelist è abilitata.",
            "hostname.example": "Il nome host del tuo dominio che supporta i caratteri jolly, ad es. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Filtro Pagina",
            "page.description": "Filtra il traffico per pagina o gruppo di pagine.",
            "page.help": "Filtra il traffico in base al percorso. Sono supportati i caratteri jolly in modo da poter raggruppare le pagine.",
            "page.example": "Il percorso delle pagine da escludere con i caratteri jolly, ad esempio /home, /home/*, /blog-**. Gli asterischi doppi corrispondono agli slash, quelli singoli no.",
            "country.title": "Filtro Paese",
            "country.description": "Filtra il traffico per paese.",
            "country.help": "Filtra il traffico per paese di origine."
        },
        "pt": {
            "ip.title": "Filtro de Endereço IP",
            "ip.description": "Filtrar tráfego por endereço IP ou CIDR.",
            "ip.help": "Filtre o tráfego com base no endereço IP ou CIDR. CIDR permite que você exclua redes inteiras.",
            "ip.example": "Endereço IP ou CIDR, por ex. 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "Lista Branca de Nomes de Host",
            "hostname.description": "Permitir tráfego apenas de nomes de host específicos. Todas as fontes são aceitas se a lista estiver vazia.",
            "hostname.help": "Permitir tráfego apenas de nomes de host específicos. Quando você adiciona um ou mais nomes de host, a lista branca é ativada.",
            "hostname.example": "O nome do host do seu domínio suportando curingas, por ex. example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "Filtro de Página",
            "page.description": "Filtrar tráfego por página ou grupo de páginas.",
            "page.help": "Filtre o tráfego com base no caminho. Os curingas são suportados para que você possa agrupar páginas.",
            "page.example": "O caminho das páginas a excluir suportando wildcards, por exemplo, /home, /home/*, /blog-**. Os asteriscos duplos correspondem a barras, os asteriscos simples não.",
            "country.title": "Filtro de País",
            "country.description": "Filtrar tráfego por país.",
            "country.help": "Filtre o tráfego pelo país de origem."
        },
        "ja": {
            "ip.title": "IPアドレスフィルター",
            "ip.description": "IPアドレスまたはCIDRでトラフィックをフィルタリングします。",
            "ip.help": "IPアドレスまたはCIDRに基づいてトラフィックをフィルタリングします。CIDRを使用すると、ネットワーク全体を除外できます。",
            "ip.example": "IPアドレスまたはCIDR、例: 89.58.72.204, 2a11:fb80:0:ae02::742e, 89.58.0.0/16.",
            "hostname.title": "ホスト名ホワイトリスト",
            "hostname.description": "特定のホスト名からのトラフィックのみを許可します。リストが空の場合、すべてのソースが受け入れられます。",
            "hostname.help": "特定のホスト名からのトラフィックのみを許可します。1つ以上のホスト名を追加すると、ホワイトリストが有効になります。",
            "hostname.example": "ワイルドカードをサポートするドメインのホスト名、例: example.com, sub.example.com, *.example.com, *example.com.",
            "page.title": "ページフィルター",
            "page.description": "ページまたはページグループでトラフィックをフィルタリングします。",
            "page.help": "パスに基づいてトラフィックをフィルタリングします。ワイルドカードがサポートされているので、ページをグループ化できます。",
            "page.example": "例：/home, /home/*, /blog-**など。ダブル・アスタリスクはスラッシュにマッチし、シングル・アスタリスクはマッチしない。",
            "country.title": "国フィルター",
            "country.description": "国別にトラフィックをフィルタリングします。",
            "country.help": "出身国によってトラフィックをフィルタリングします。"
        }
    }
</i18n>
