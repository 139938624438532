<template>
    <h1>{{t("title")}}</h1>
    <UserThemes />
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import UserThemes from "@/components/themes/UserThemes.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            UserThemes
        },
        setup() {
            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Themes"
        },
        "de": {
            "title": "Themen"
        },
        "es": {
            "title": "Temas"
        },
        "fr": {
            "title": "Thèmes"
        },
        "nl": {
            "title": "Thema's"
        },
        "it": {
            "title": "Temi"
        },
        "pt": {
            "title": "Temas"
        },
        "ja": {
            "title": "テーマ"
        }
    }
</i18n>
