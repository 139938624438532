<template>
    <template v-if="domain.id && loaded">
        <PirschPlus>
            <strong>{{t("plus")}}</strong>
        </PirschPlus>
        <FunnelList />
    </template>
    <DomainNotFound v-else-if="loaded" />
    <LoadingPage :loading="!loaded" />
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import DomainNotFound from "@/components/domain/DomainNotFound.vue";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";
    import Loading from "@/components/bits/Loading.vue";
    import CreateEditFunnel from "@/components/modal/CreateEditFunnel.vue";
    import FunnelList from "@/components/funnel/FunnelList.vue";

    export default defineComponent({
        components: {
            FunnelList,
            CreateEditFunnel,
            Loading,
            PirschPlus,
            LoadingPage,
            DomainNotFound
        },
        setup() {
            const {domain, loaded} = storeToRefs(useDomainStore());
            const {user} = storeToRefs(useUserStore());

            return {
                ...useI18n(),
                domain,
                loaded,
                user
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "plus": "Funnels require a Pirsch Plus subscription."
        },
        "de": {
            "plus": "Funnel erfordern ein Pirsch Plus-Abonnement."
        },
        "es": {
            "plus": "Los embudos requieren una suscripción a Pirsch Plus."
        },
        "fr": {
            "plus": "Les entonnoirs nécessitent un abonnement à Pirsch Plus."
        },
        "nl": {
            "plus": "Voor trechters is een Pirsch Plus-abonnement vereist."
        },
        "it": {
            "plus": "Gli imbuti richiedono un abbonamento a Pirsch Plus."
        },
        "pt": {
            "plus": "Os funis requerem uma subscrição do Pirsch Plus."
        },
        "ja": {
            "plus": "ファンネルにはPirsch Plusのサブスクリプションが必要です。"
        }
    }
</i18n>
