<template>
    <div class="language-switch" v-if="loaded && !loggedIn && show && !hide">
        <i class="icon icon-globe"></i>
        <select name="language" v-model="language">
            <option v-for="lang in languageOptions" :value="lang.value">
                {{lang.label}}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import {isDashboard} from "@/util/domain";
    import {useThemeStore} from "@/store/ThemeStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";
    import {useUserStore} from "@/store/UserStore";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        setup() {
            const {locale} = useI18n();
            const {loggedIn} = storeToRefs(useUserStore());
            const themeStore = useThemeStore();
            const {theme, loaded} = storeToRefs(themeStore);
            const language = ref("en");
            const hide = computed(() => {
                const themeSettings = theme.value ?? {};
                return themeSettings[ThemeSettings.hideLanguageSwitch] === "true";
            });

            watch(locale, locale => {
                language.value = locale;
            });

            watch(language, language => {
                if (language !== locale.value) {
                    locale.value = language;
                    localStorage.setItem("lang", language);
                    location.reload();
                }
            });

            return {
                languageOptions: [
                    {label: "English", value: "en"},
                    {label: "Deutsch", value: "de"},
                    {label: "Español", value: "sp"},
                    {label: "Français", value: "fr"},
                    {label: "Nederlands", value: "nl"},
                    {label: "Italiano", value: "it"},
                    {label: "Português", value: "pt"},
                    {label: "日本語", value: "ja"}
                ],
                show: !isDashboard(),
                loggedIn,
                loaded,
                hide,
                language
            };
        }
    });
</script>
