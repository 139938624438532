<template>
    <section class="form">
        <h1>{{t("title")}}</h1>
        <p>{{t("text")}}</p>
        <form v-on:submit.stop.prevent="signIn">
            <FormInput :label="t('form.email')" name="email" autocomplete="email" v-model="email" :err="validationError('email')" />
            <FormInput :label="t('form.password')" type="password" name="password" autocomplete="password" v-model="password" />
            <FormCheckbox :label="t('form.keep_logged_in')" name="stayLoggedIn" v-model="stayLoggedIn" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
        <router-link to="/password">{{t("forgot_password")}}</router-link>
        <AppFooterSmall />
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {RefreshTokenResponse, UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useRouter} from "vue-router";
    import {useInit} from "@/init";
    import {useUser} from "@/views/user";
    import {useLogin} from "@/views/login";
    import AppFooterSmall from "@/components/bits/AppFooterSmall.vue";
    import {useI18n} from "vue-i18n";
    import {APIError} from "@/repositories/APIError";

    export default defineComponent({
        components: {
            AppFooterSmall,
            FormCheckbox,
            FormSubmit,
            FormInput
        },
        setup() {
            useUser();
            const {init} = useInit();
            const router = useRouter();
            const {validationError, setError, resetError} = useError();
            const {login} = useLogin();
            const email = ref("");
            const password = ref("");
            const stayLoggedIn = ref(false);
            const loading = ref(false);

            async function signIn() {
                try {
                    loading.value = true;
                    resetError();
                    const data = await UserRepo.login(email.value, password.value, stayLoggedIn.value) as RefreshTokenResponse;
                    await login(data.access_token, data.refresh_token, stayLoggedIn.value);
                    email.value = "";
                    password.value = "";
                    stayLoggedIn.value = false;
                    await init();
                    loading.value = false;

                    if (data.reset_password) {
                        await router.push("/reset-password");
                    } else {
                        await router.push("/");
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                ...useI18n(),
                validationError,
                email,
                password,
                stayLoggedIn,
                loading,
                signIn
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Log in to Pirsch",
            "text": "Enter your credentials to continue.",
            "form.email": "Email Address",
            "form.password": "Password",
            "form.keep_logged_in": "Keep Me Logged In",
            "form.submit": "Log In",
            "forgot_password": "Forgot your password?"
        },
        "de": {
            "title": "Bei Pirsch anmelden",
            "text": "Gib deine Anmeldedaten ein, um fortzufahren.",
            "form.email": "E-Mail-Adresse",
            "form.password": "Passwort",
            "form.keep_logged_in": "Angemeldet bleiben",
            "form.submit": "Anmelden",
            "forgot_password": "Passwort vergessen?"
        },
        "es": {
            "title": "Iniciar sesión en Pirsch",
            "text": "Introduce tus credenciales para continuar.",
            "form.email": "Dirección de correo electrónico",
            "form.password": "Contraseña",
            "form.keep_logged_in": "Mantenerme conectado",
            "form.submit": "Iniciar sesión",
            "forgot_password": "¿Olvidaste tu contraseña?"
        },
        "fr": {
            "title": "Se connecter à Pirsch",
            "text": "Entrez vos identifiants pour continuer.",
            "form.email": "Adresse e-mail",
            "form.password": "Mot de passe",
            "form.keep_logged_in": "Rester connecté",
            "form.submit": "Se connecter",
            "forgot_password": "Mot de passe oublié?"
        },
        "nl": {
            "title": "Inloggen bij Pirsch",
            "text": "Voer je inloggegevens in om door te gaan.",
            "form.email": "E-mailadres",
            "form.password": "Wachtwoord",
            "form.keep_logged_in": "Hou me ingelogd",
            "form.submit": "Inloggen",
            "forgot_password": "Wachtwoord vergeten?"
        },
        "it": {
            "title": "Accedi a Pirsch",
            "text": "Inserisci le tue credenziali per continuare.",
            "form.email": "Indirizzo e-mail",
            "form.password": "Password",
            "form.keep_logged_in": "Mantienimi connesso",
            "form.submit": "Accedi",
            "forgot_password": "Hai dimenticato la password?"
        },
        "pt": {
            "title": "Entrar no Pirsch",
            "text": "Insira suas credenciais para continuar.",
            "form.email": "Endereço de e-mail",
            "form.password": "Senha",
            "form.keep_logged_in": "Manter-me conectado",
            "form.submit": "Entrar",
            "forgot_password": "Esqueceu sua senha?"
        },
        "ja": {
            "title": "Pirschにログイン",
            "text": "続行するには、資格情報を入力してください。",
            "form.email": "メールアドレス",
            "form.password": "パスワード",
            "form.keep_logged_in": "ログイン状態を保持する",
            "form.submit": "ログイン",
            "forgot_password": "パスワードをお忘れですか？"
        }
    }
</i18n>
