<template>
    <div class="box">
        <div class="box-title no-select" v-on:click="isOpen = !isOpen">
            <div class="box-text">
                <h4>{{title}}</h4>
                <small>
                    <slot name="description"></slot>
                </small>
            </div>
            <i :class="{'icon icon-chevron clickable': true, 'icon-rotate-180': isOpen}" />
        </div>
        <Expand>
            <div class="box-content" v-show="isOpen">
                <hr />
                <slot></slot>
            </div>
        </Expand>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Expand from "@/components/bits/Expand.vue";

    export default defineComponent({
        components: {
            Expand
        },
        props: {
            title: {type: String, required: true}
        },
        setup() {
            const isOpen = ref(false);

            return {
                isOpen
            };
        }
    });
</script>
