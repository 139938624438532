<template>
    <ConfirmAccountDeletion :open="confirmDeletion"
        v-on:close="confirmDeletion = false" />
    <CancelAccountDeletion :open="cancelDeletion"
        v-on:close="cancelDeletion = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
        </div>
        <div v-if="!user.delete_on">
            <p v-if="selfHosted">{{t("confirm.text_0")}}</p>
            <p v-else>{{t("confirm.text_1")}}</p>
            <button class="red" style="margin-top: 12px;" v-on:click="confirmDeletion = true">{{t("confirm.button")}}</button>
        </div>
        <div v-else>
            <p class="warning">{{t("cancel.text")}}</p>
            <button style="margin-top: 12px;" v-on:click="cancelDeletion = true" v-if="user.delete_on">{{t("cancel.button")}}</button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import ConfirmAccountDeletion from "@/components/modal/ConfirmAccountDeletion.vue";
    import CancelAccountDeletion from "@/components/modal/CancelAccountDeletion.vue";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {isSelfHosted} from "@/util/domain";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmAccountDeletion,
            CancelAccountDeletion
        },
        setup() {
            const confirmDeletion = ref(false);
            const cancelDeletion = ref(false);

            return {
                ...useI18n(),
                ...storeToRefs(useUserStore()),
                selfHosted: isSelfHosted(),
                confirmDeletion,
                cancelDeletion
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Delete Your Account",
            "confirm.text_0": "Deleting your account will mark your account for deletion. You have one week to cancel the deletion, once your account has been deleted, we cannot restore any data.",
            "confirm.text_1": "Deleting your account will cancel your subscription immediately and mark your account for deletion. You have one week to cancel the deletion and should you decide to do so, you need to manually reactivate your subscription. Once your account has been deleted, we cannot restore any data.",
            "confirm.button": "Delete Your Account",
            "cancel.text": "Your account has been marked for deletion and will be deleted within one week. You can cancel the deletion by clicking the button below or by logging in again.",
            "cancel.button": "Cancel Deletion"
        },
        "de": {
            "title": "Dein Konto löschen",
            "confirm.text_0": "Das Löschen deines Kontos markiert es zur Löschung. Du hast eine Woche Zeit, um die Löschung abzubrechen. Sobald dein Konto gelöscht wurde, können wir keine Daten wiederherstellen.",
            "confirm.text_1": "Das Löschen deines Kontos kündigt sofort dein Abonnement und markiert dein Konto zur Löschung. Du hast eine Woche Zeit, um die Löschung abzubrechen, und wenn du dich entscheidest, dies zu tun, musst du dein Abonnement manuell reaktivieren. Sobald dein Konto gelöscht wurde, können wir keine Daten wiederherstellen.",
            "confirm.button": "Dein Konto löschen",
            "cancel.text": "Dein Konto wurde zur Löschung markiert und wird innerhalb einer Woche gelöscht. Du kannst die Löschung abbrechen, indem du auf den untenstehenden Button klickst oder dich erneut anmeldest.",
            "cancel.button": "Löschung abbrechen"
        },
        "es": {
            "title": "Eliminar tu cuenta",
            "confirm.text_0": "Eliminar tu cuenta marcará tu cuenta para eliminación. Tienes una semana para cancelar la eliminación. Una vez que tu cuenta haya sido eliminada, no podemos restaurar ningún dato.",
            "confirm.text_1": "Eliminar tu cuenta cancelará tu suscripción de inmediato y marcará tu cuenta para eliminación. Tienes una semana para cancelar la eliminación y, si decides hacerlo, debes reactivar manualmente tu suscripción. Una vez que tu cuenta haya sido eliminada, no podemos restaurar ningún dato.",
            "confirm.button": "Eliminar tu cuenta",
            "cancel.text": "Tu cuenta ha sido marcada para eliminación y será eliminada dentro de una semana. Puedes cancelar la eliminación haciendo clic en el botón de abajo o iniciando sesión nuevamente.",
            "cancel.button": "Cancelar eliminación"
        },
        "fr": {
            "title": "Supprimer votre compte",
            "confirm.text_0": "La suppression de votre compte marquera votre compte pour suppression. Vous avez une semaine pour annuler la suppression. Une fois votre compte supprimé, nous ne pouvons pas restaurer les données.",
            "confirm.text_1": "La suppression de votre compte annulera immédiatement votre abonnement et marquera votre compte pour suppression. Vous avez une semaine pour annuler la suppression et si vous décidez de le faire, vous devez réactiver manuellement votre abonnement. Une fois votre compte supprimé, nous ne pouvons pas restaurer les données.",
            "confirm.button": "Supprimer votre compte",
            "cancel.text": "Votre compte a été marqué pour suppression et sera supprimé dans une semaine. Vous pouvez annuler la suppression en cliquant sur le bouton ci-dessous ou en vous reconnectant.",
            "cancel.button": "Annuler la suppression"
        },
        "nl": {
            "title": "Je account verwijderen",
            "confirm.text_0": "Het verwijderen van je account markeert je account voor verwijdering. Je hebt een week om de verwijdering te annuleren. Zodra je account is verwijderd, kunnen we geen gegevens herstellen.",
            "confirm.text_1": "Het verwijderen van je account annuleert onmiddellijk je abonnement en markeert je account voor verwijdering. Je hebt een week om de verwijdering te annuleren en als je besluit dit te doen, moet je je abonnement handmatig opnieuw activeren. Zodra je account is verwijderd, kunnen we geen gegevens herstellen.",
            "confirm.button": "Verwijder je account",
            "cancel.text": "Je account is gemarkeerd voor verwijdering en wordt binnen een week verwijderd. Je kunt de verwijdering annuleren door op de onderstaande knop te klikken of opnieuw in te loggen.",
            "cancel.button": "Annuleer verwijdering"
        },
        "it": {
            "title": "Elimina il tuo account",
            "confirm.text_0": "Eliminare il tuo account segnerà il tuo account per l'eliminazione. Hai una settimana per annullare l'eliminazione. Una volta che il tuo account è stato eliminato, non possiamo ripristinare alcun dato.",
            "confirm.text_1": "Eliminare il tuo account annullerà immediatamente il tuo abbonamento e segnerà il tuo account per l'eliminazione. Hai una settimana per annullare l'eliminazione e se decidi di farlo, devi riattivare manualmente il tuo abbonamento. Una volta che il tuo account è stato eliminato, non possiamo ripristinare alcun dato.",
            "confirm.button": "Elimina il tuo account",
            "cancel.text": "Il tuo account è stato segnato per l'eliminazione e verrà eliminato entro una settimana. Puoi annullare l'eliminazione facendo clic sul pulsante qui sotto o accedendo nuovamente.",
            "cancel.button": "Annulla eliminazione"
        },
        "pt": {
            "title": "Excluir sua conta",
            "confirm.text_0": "Excluir sua conta marcará sua conta para exclusão. Você tem uma semana para cancelar a exclusão. Uma vez que sua conta tenha sido excluída, não podemos restaurar nenhum dado.",
            "confirm.text_1": "Excluir sua conta cancelará imediatamente sua assinatura e marcará sua conta para exclusão. Você tem uma semana para cancelar a exclusão e, se decidir fazê-lo, precisará reativar manualmente sua assinatura. Uma vez que sua conta tenha sido excluída, não podemos restaurar nenhum dado.",
            "confirm.button": "Excluir sua conta",
            "cancel.text": "Sua conta foi marcada para exclusão e será excluída dentro de uma semana. Você pode cancelar a exclusão clicando no botão abaixo ou fazendo login novamente.",
            "cancel.button": "Cancelar exclusão"
        },
        "ja": {
            "title": "アカウントを削除する",
            "confirm.text_0": "アカウントを削除すると、アカウントは削除の対象としてマークされます。削除をキャンセルするには1週間の猶予があります。アカウントが削除された場合、データを復元することはできません。",
            "confirm.text_1": "アカウントを削除すると、サブスクリプションは即座にキャンセルされ、アカウントは削除の対象としてマークされます。削除をキャンセルするには1週間の猶予があり、キャンセルを決定した場合はサブスクリプションを手動で再アクティブ化する必要があります。アカウントが削除された場合、データを復元することはできません。",
            "confirm.button": "アカウントを削除する",
            "cancel.text": "アカウントは削除の対象としてマークされており、1週間以内に削除されます。下のボタンをクリックするか、再度ログインすることで削除をキャンセルできます。",
            "cancel.button": "削除をキャンセル"
        }
    }
</i18n>
