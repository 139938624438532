<template>
    <div class="theme-switch" v-if="loaded && show && !hide">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linejoin="round"><path d="M12 17a5 5 0 1 0 0-10 5 5 0 1 0 0 10z"/><path d="M5.636 5.636l.707.707M3 12h1m2.343 5.657l-.707.707M12 20v1m5.657-3.343l.707.707M20 12h1m-2.636-6.364l-.707.707M12 3v1" stroke-linecap="round"/></svg>
        <fieldset class="toggle" v-on:click="switchColor">
            <input type="checkbox"
                name="theme-switch"
                v-model="darkMode" />
            <label for="theme-switch" />
        </fieldset>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path d="M12.515 14.242C9.517 11.161 9.017 6.498 11.183 3a8.4 8.4 0 0 0-4.497 2.498 9.2 9.2 0 0 0 0 12.991 9.2 9.2 0 0 0 12.991 0c.5-.5.999-1.082 1.332-1.666-2.998.5-6.162-.333-8.494-2.581z" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/></svg>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import {isDashboard} from "@/util/domain";
    import {useThemeStore} from "@/store/ThemeStore";
    import {storeToRefs} from "pinia";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        setup() {
            const themeStore = useThemeStore();
            const {theme, darkMode, loaded} = storeToRefs(themeStore);
            const hide = computed(() => {
                const themeSettings = theme.value ?? {};
                return themeSettings[ThemeSettings.hideDarkModeToggle] === "true";
            });

            function switchColor() {
                localStorage.setItem("color_mode", themeStore.darkMode ? "light" : "dark");
                themeStore.setDarkMode(themeStore.darkMode ? "false" : "true");
            }

            return {
                show: !isDashboard(),
                darkMode,
                loaded,
                hide,
                switchColor
            };
        }
    });
</script>
