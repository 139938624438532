<template>
    <Modal size="m" :title="t('title')" :open="open">
        <NewsEntry v-for="(n, index) in news"
            :key="n.news"
            :news="n"
            :index="index"
            :length="news.length" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import NewsEntry from "@/components/news/NewsEntry.vue";
    import {day} from "@/util/format";
    import {News} from "@/model/News";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            NewsEntry
        },
        props: {
            news: {type: Array as PropType<Array<News>>, required: true},
            open: {type: Boolean, default: false}
        },
        setup() {
            const toggle = ref(false);

            return {
                ...useI18n(),
                day,
                toggle
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "News"
        },
        "de": {
            "title": "Neuigkeiten"
        },
        "es": {
            "title": "Noticias"
        },
        "fr": {
            "title": "Actualités"
        },
        "nl": {
            "title": "Nieuws"
        },
        "it": {
            "title": "Notizie"
        },
        "pt": {
            "title": "Notícias"
        },
        "ja": {
            "title": "ニュース"
        }
    }
</i18n>
