<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" :autofocus="true" v-model="name" :err="validationError('name')" />
            <h4>{{t("title.page")}}</h4>
            <FormInput :label="t('form.pattern')" :hint="t('form.pattern.hint')" name="pattern" v-model="pattern" :err="validationError('pattern')" v-show="!showRegex" />
            <Collapsible :title="t('advanced.hide')"
                :titleCollapsed="t('advanced.show')"
                :open="showRegex"
                v-on:toggle="toggleShowRegex">
                <FormInput :label="t('form.regex')"
                    name="regex"
                    v-model="regex"
                    :hint="match === true ? t('form.regex.valid') : match === false ? t('form.regex.invalid') : '<a href=\'https://github.com/google/re2/wiki/Syntax\' target=\'_blank\'>Google RE2 syntax</a>'"
                    :err="validationError('regex') ? validationError('regex') : regexErr" />
                <FormInput :label="t('form.sample')" name="sample" v-model="sample" :err="validationError('sample')" />
                <button v-on:click.stop.prevent="testRegex">{{t("form.sample.test")}}</button>
            </Collapsible>
            <h4>{{t("title.event")}}</h4>
            <FormInput :label="t('form.event')" name="eventName" v-model="eventName" :err="validationError('event_name')" />
            <div class="grid">
                <FormInput :label="t('form.event_meta_key')" name="eventMetaKey" :hint="t('form.event_meta_key.hint')" v-model="eventMetaKey" :err="validationError('event_meta_key')" />
                <FormInput :label="t('form.event_meta_value')" name="eventMetaValue" :hint="t('form.event_meta_value.hint')" v-model="eventMetaValue" :err="validationError('event_meta_value')" />
            </div>
            <div class="grid">
                <FormInput :label="t('form.metric_meta_key')" name="customMetricKey" :hint="t('form.metric_meta_key.hint')" :disabled="!hasPlus" v-model="customMetricKey" :err="validationError('custom_metric_key')" />
                <FormSelect :label="t('form.metric_type')" name="customMetricType" :hint="t('form.metric_type.hint')" :options="customMetricTypeOptions" :disabled="!hasPlus" v-model="customMetricType" :err="validationError('custom_metric_type')" />
            </div>
            <PirschPlus :small="true">{{t("plus")}}</PirschPlus>
            <h4>{{t("title.goal")}}</h4>
            <small>{{t("target")}}</small>
            <FormInput :label="t('form.visitors')" name="visitors" type="number" step="1" v-model="visitors" :err="validationError('visitors')" />
            <FormInput :label="t('form.cr')" name="cr" type="number" step="0.01" v-model="cr" :err="validationError('cr')" />
            <FormInput :label="t('form.total')" name="customMetricTotal" type="number" step="1" v-model="customMetricTotal" :err="validationError('custom_metric_total')" />
            <FormInput :label="t('form.avg')" name="customMetricAvg" type="number" step="0.01" v-model="customMetricAvg" :err="validationError('custom_metric_avg')" />
            <FormCheckbox :label="t('form.reached')" name="emailReached" v-model="emailReached" v-show="visitors > 0 || cr > 0" />
            <FormCheckbox :label="t('form.delete')" name="deleteReached" v-model="deleteReached" v-show="emailReached" />
            <FormSubmit :value="goal ? t('form.submit.save') : t('form.submit.create')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, Ref, ref, watch, watchEffect} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useError} from "@/components/error";
    import {ConversionGoalRepo, RegexValidationResponse} from "@/repositories/ConversionGoalRepo";
    import Collapsible from "@/components/bits/Collapsible.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {ConversionGoal} from "@/model/ConversionGoal";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {CustomMetricType} from "@/model/Filter";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            PirschPlus,
            Modal,
            FormSubmit,
            FormInput,
            FormCheckbox,
            FormSelect,
            Collapsible
        },
        props: {
            goal: Object as PropType<ConversionGoal>
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const {hasPlus} = storeToRefs(useUserStore());
            const customMetricTypeOptions = computed(() => [
                {label: t("options.custom_metric.integer"), value: "integer"},
                {label: t("options.custom_metric.decimal"), value: "float"}
            ]);
            const loading = ref(false);
            const name = ref("");
            const pattern = ref("");
            const regex = ref("");
            const sample = ref("");
            const match: Ref<boolean | null> = ref(null);
            const regexErr = ref("");
            const eventName = ref("");
            const eventMetaKey = ref("");
            const eventMetaValue = ref("");
            const customMetricKey = ref("");
            const customMetricType = ref<CustomMetricType>("");
            const visitors = ref(0);
            const cr = ref(0);
            const customMetricTotal = ref("");
            const customMetricAvg = ref("");
            const deleteReached = ref(false);
            const emailReached = ref(false);
            const showRegex = ref(false);
            let id: string;

            watchEffect(() => {
                if (props.goal) {
                    id = props.goal.id;
                    name.value = props.goal.name;
                    pattern.value = props.goal.path_pattern && props.goal.path_pattern !== props.goal.pattern ? props.goal.path_pattern : "";
                    regex.value = props.goal.pattern || "";
                    sample.value = props.goal.sample ? props.goal.sample : "";
                    eventName.value = props.goal.event_name || "";
                    eventMetaKey.value = props.goal.event_meta_key || "";
                    eventMetaValue.value = props.goal.event_meta_value || "";
                    customMetricKey.value = props.goal.custom_metric_key || "";
                    customMetricType.value = props.goal.custom_metric_type === "integer" || props.goal.custom_metric_type === "float" ? props.goal.custom_metric_type : "";
                    visitors.value = props.goal.visitor_goal ?? 0;
                    cr.value = props.goal.cr_goal ?? 0;
                    customMetricTotal.value = props.goal.custom_metric_total_goal?.toString() || "";
                    customMetricAvg.value = props.goal.custom_metric_avg_goal?.toString() || "";
                    deleteReached.value = props.goal.delete_reached;
                    emailReached.value = props.goal.email_reached;
                    showRegex.value = props.goal.path_pattern === props.goal.pattern;
                }
            });

            watch([visitors, cr], () => {
                try {
                    if (!visitors.value && !cr.value) {
                        emailReached.value = false;
                    }
                } catch {
                    emailReached.value = false;
                }
            })

            watch(emailReached, value => {
                if (!value) {
                    deleteReached.value = false;
                }
            });

            watch(regex, () => {
                match.value = null;
                regexErr.value = "";
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const goal = await ConversionGoalRepo.createUpdate(id,
                        domain.value.id,
                        name.value,
                        pattern.value,
                        showRegex.value ? regex.value : "",
                        sample.value,
                        eventName.value,
                        eventMetaKey.value,
                        eventMetaValue.value,
                        customMetricKey.value,
                        customMetricType.value,
                        parseInt(visitors.value as unknown as string),
                        parseInt(cr.value as unknown as string),
                        parseFloat(customMetricTotal.value),
                        parseFloat(customMetricAvg.value),
                        deleteReached.value,
                        emailReached.value);
                    success(t("toast.success"));
                    name.value = "";
                    pattern.value = "";
                    regex.value = "";
                    sample.value = "";
                    eventName.value = "";
                    eventMetaKey.value = "";
                    eventMetaValue.value = "";
                    customMetricKey.value = "";
                    customMetricType.value = "";
                    visitors.value = 0;
                    cr.value = 0;
                    deleteReached.value = false;
                    emailReached.value = false;
                    loading.value = false;
                    emit("action", goal);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            async function testRegex() {
                try {
                    const result = await ConversionGoalRepo.testRegex(regex.value, sample.value) as RegexValidationResponse;
                    match.value = result.match;
                } catch (e) {
                    regexErr.value = (e as APIError).validation.regex;
                }
            }

            function toggleShowRegex(open: boolean) {
                showRegex.value = open;
            }

            return {
                t,
                customMetricTypeOptions,
                validationError,
                err,
                hasPlus,
                loading,
                name,
                pattern,
                regex,
                sample,
                match,
                regexErr,
                eventName,
                eventMetaKey,
                eventMetaValue,
                customMetricKey,
                customMetricType,
                visitors,
                cr,
                customMetricTotal,
                customMetricAvg,
                deleteReached,
                emailReached,
                showRegex,
                save,
                testRegex,
                toggleShowRegex
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Conversion Goal",
            "title.page": "Page",
            "title.event": "Event and Custom Metric",
            "title.goal": "Goal",
            "advanced.show": "Show Advanced Options (Regular Expression)",
            "advanced.hide": "Hide Advanced Options (Regular Expression)",
            "form.name": "Name",
            "form.pattern": "Path pattern",
            "form.pattern.hint": "* for a single directory, ** for multiple. Example: /my/*/path/**",
            "form.regex": "Regular Expression",
            "form.regex.valid": "The regular expression is valid and matches the sample input.",
            "form.regex.invalid": "The regular expression is valid but does not match the sample input.",
            "form.sample": "Sample Input",
            "form.sample.test": "Test Regular Expression",
            "form.event": "Event name",
            "form.event_meta_key": "Event Meta Key",
            "form.event_meta_key.hint": "Specifies the event metadata key to filter for, e.g. \"currency\".",
            "form.event_meta_value": "Event Meta Value",
            "form.event_meta_value.hint": "Specifies the event metadata value to filter for, e.g. \"USD\".",
            "form.metric_meta_key": "Custom Metric Key",
            "form.metric_meta_key.hint": "Specifies the event metadata key to aggregate, e.g. \"amount\".",
            "form.metric_type": "Custom Metric Type",
            "form.metric_type.hint": "Specifies how to interpret the metadata value, e.g. decimal for \"42.99\" and integer for \"42\".",
            "form.visitors": "Visitor target (optional)",
            "form.cr": "CR target % (optional)",
            "form.total": "Custom metric total target (optional)",
            "form.avg": "Custom metric average target (optional)",
            "form.reached": "Send an email when the goal is reached",
            "form.delete": "Delete the goal after it is reached",
            "form.submit.save": "Save",
            "form.submit.create": "Create Goal",
            "target": "Set a target to receive a notification when the goal is reached.",
            "plus": "Custom metrics require a Pirsch Plus subscription.",
            "options.custom_metric.integer": "Integer",
            "options.custom_metric.decimal": "Decimal",
            "toast.success": "The goal has been created."
        },
        "de": {
            "title": "Konversionsziel",
            "title.page": "Seite",
            "title.event": "Event und benutzerdefinierte Metrik",
            "title.goal": "Ziel",
            "advanced.show": "Erweiterte Optionen anzeigen (Regulärer Ausdruck)",
            "advanced.hide": "Erweiterte Optionen ausblenden (Regulärer Ausdruck)",
            "form.name": "Name",
            "form.pattern": "Pfadmuster",
            "form.pattern.hint": "* für ein einzelnes Verzeichnis, ** für mehrere. Beispiel: /mein/*/pfad/**",
            "form.regex": "Regulärer Ausdruck",
            "form.regex.valid": "Der reguläre Ausdruck ist gültig und passt zum Beispielinput.",
            "form.regex.invalid": "Der reguläre Ausdruck ist gültig, passt jedoch nicht zum Beispielinput.",
            "form.sample": "Beispielinput",
            "form.sample.test": "Regulären Ausdruck testen",
            "form.event": "Eventname",
            "form.event_meta_key": "Event-Meta-Schlüssel",
            "form.event_meta_key.hint": "Gibt den Metadatenschlüssel des Events an, nach dem gefiltert werden soll, z. B. \"currency\".",
            "form.event_meta_value": "Event-Meta-Wert",
            "form.event_meta_value.hint": "Gibt den Metadatenwert des Events an, nach dem gefiltert werden soll, z. B. \"USD\".",
            "form.metric_meta_key": "Benutzerdefinierter Metrikschlüssel",
            "form.metric_meta_key.hint": "Gibt den Metadatenschlüssel des Events an, der aggregiert werden soll, z. B. \"amount\".",
            "form.metric_type": "Benutzerdefinierter Metriktyp",
            "form.metric_type.hint": "Gibt an, wie der Metadatenwert interpretiert werden soll, z. B. Dezimal für \"42.99\" und Ganzzahl für \"42\".",
            "form.visitors": "Besucherziel (optional)",
            "form.cr": "CR-Ziel % (optional)",
            "form.total": "Gesamtziel der benutzerdefinierten Metrik (optional)",
            "form.avg": "Durchschnittsziel der benutzerdefinierten Metrik (optional)",
            "form.reached": "Eine E-Mail senden, wenn das Ziel erreicht ist",
            "form.delete": "Das Ziel löschen, nachdem es erreicht wurde",
            "form.submit.save": "Speichern",
            "form.submit.create": "Ziel erstellen",
            "target": "Lege ein Ziel fest, um eine Benachrichtigung zu erhalten, wenn das Ziel erreicht ist.",
            "plus": "Benutzerdefinierte Metriken erfordern ein Pirsch Plus-Abonnement.",
            "options.custom_metric.integer": "Ganzzahl",
            "options.custom_metric.decimal": "Dezimal",
            "toast.success": "Das Ziel wurde erstellt."
        },
        "es": {
            "title": "Meta de conversión",
            "title.page": "Página",
            "title.event": "Evento y métrica personalizada",
            "title.goal": "Meta",
            "advanced.show": "Mostrar opciones avanzadas (Expresión regular)",
            "advanced.hide": "Ocultar opciones avanzadas (Expresión regular)",
            "form.name": "Nombre",
            "form.pattern": "Patrón de ruta",
            "form.pattern.hint": "* para un solo directorio, ** para múltiples. Ejemplo: /mi/*/ruta/**",
            "form.regex": "Expresión regular",
            "form.regex.valid": "La expresión regular es válida y coincide con la entrada de muestra.",
            "form.regex.invalid": "La expresión regular es válida pero no coincide con la entrada de muestra.",
            "form.sample": "Entrada de muestra",
            "form.sample.test": "Probar expresión regular",
            "form.event": "Nombre del evento",
            "form.event_meta_key": "Clave de metadatos del evento",
            "form.event_meta_key.hint": "Especifica la clave de metadatos del evento para filtrar, por ejemplo, \"currency\".",
            "form.event_meta_value": "Valor de metadatos del evento",
            "form.event_meta_value.hint": "Especifica el valor de metadatos del evento para filtrar, por ejemplo, \"USD\".",
            "form.metric_meta_key": "Clave de métrica personalizada",
            "form.metric_meta_key.hint": "Especifica la clave de metadatos del evento para agregar, por ejemplo, \"amount\".",
            "form.metric_type": "Tipo de métrica personalizada",
            "form.metric_type.hint": "Especifica cómo interpretar el valor de los metadatos, por ejemplo, decimal para \"42.99\" e entero para \"42\".",
            "form.visitors": "Objetivo de visitantes (opcional)",
            "form.cr": "Objetivo de CR % (opcional)",
            "form.total": "Objetivo total de métrica personalizada (opcional)",
            "form.avg": "Objetivo promedio de métrica personalizada (opcional)",
            "form.reached": "Enviar un correo electrónico cuando se alcance la meta",
            "form.delete": "Eliminar la meta después de alcanzarla",
            "form.submit.save": "Guardar",
            "form.submit.create": "Crear meta",
            "target": "Establece un objetivo para recibir una notificación cuando se alcance la meta.",
            "plus": "Las métricas personalizadas requieren una suscripción a Pirsch Plus.",
            "options.custom_metric.integer": "Entero",
            "options.custom_metric.decimal": "Decimal",
            "toast.success": "La meta ha sido creada."
        },
        "fr": {
            "title": "Objectif de conversion",
            "title.page": "Page",
            "title.event": "Événement et métrique personnalisée",
            "title.goal": "Objectif",
            "advanced.show": "Afficher les options avancées (Expression régulière)",
            "advanced.hide": "Masquer les options avancées (Expression régulière)",
            "form.name": "Nom",
            "form.pattern": "Modèle de chemin",
            "form.pattern.hint": "* pour un seul répertoire, ** pour plusieurs. Exemple: /mon/*/chemin/**",
            "form.regex": "Expression régulière",
            "form.regex.valid": "L'expression régulière est valide et correspond à l'entrée de l'exemple.",
            "form.regex.invalid": "L'expression régulière est valide mais ne correspond pas à l'entrée de l'exemple.",
            "form.sample": "Entrée de l'exemple",
            "form.sample.test": "Tester l'expression régulière",
            "form.event": "Nom de l'événement",
            "form.event_meta_key": "Clé de méta-données de l'événement",
            "form.event_meta_key.hint": "Spécifie la clé de méta-données de l'événement à filtrer, par exemple, \"currency\".",
            "form.event_meta_value": "Valeur de méta-données de l'événement",
            "form.event_meta_value.hint": "Spécifie la valeur de méta-données de l'événement à filtrer, par exemple, \"USD\".",
            "form.metric_meta_key": "Clé de métrique personnalisée",
            "form.metric_meta_key.hint": "Spécifie la clé de méta-données de l'événement à agréger, par exemple, \"amount\".",
            "form.metric_type": "Type de métrique personnalisée",
            "form.metric_type.hint": "Spécifie comment interpréter la valeur des méta-données, par exemple, décimal pour \"42.99\" et entier pour \"42\".",
            "form.visitors": "Cible de visiteurs (optionnel)",
            "form.cr": "Cible de CR % (optionnel)",
            "form.total": "Cible totale de métrique personnalisée (optionnel)",
            "form.avg": "Cible moyenne de métrique personnalisée (optionnel)",
            "form.reached": "Envoyer un e-mail lorsque l'objectif est atteint",
            "form.delete": "Supprimer l'objectif après qu'il soit atteint",
            "form.submit.save": "Enregistrer",
            "form.submit.create": "Créer un objectif",
            "target": "Définissez un objectif pour recevoir une notification lorsque l'objectif est atteint.",
            "plus": "Les métriques personnalisées nécessitent un abonnement à Pirsch Plus.",
            "options.custom_metric.integer": "Entier",
            "options.custom_metric.decimal": "Décimal",
            "toast.success": "L'objectif a été créé."
        },
        "nl": {
            "title": "Conversiedoel",
            "title.page": "Pagina",
            "title.event": "Evenement en aangepaste metriek",
            "title.goal": "Doel",
            "advanced.show": "Geavanceerde opties weergeven (Reguliere expressie)",
            "advanced.hide": "Geavanceerde opties verbergen (Reguliere expressie)",
            "form.name": "Naam",
            "form.pattern": "Padpatroon",
            "form.pattern.hint": "* voor een enkele directory, ** voor meerdere. Voorbeeld: /mijn/*/pad/**",
            "form.regex": "Reguliere expressie",
            "form.regex.valid": "De reguliere expressie is geldig en komt overeen met de voorbeeldinvoer.",
            "form.regex.invalid": "De reguliere expressie is geldig, maar komt niet overeen met de voorbeeldinvoer.",
            "form.sample": "Voorbeeldinvoer",
            "form.sample.test": "Reguliere expressie testen",
            "form.event": "Naam van het evenement",
            "form.event_meta_key": "Meta-sleutel van het evenement",
            "form.event_meta_key.hint": "Specificeert de meta-sleutel van het evenement om te filteren, bijvoorbeeld \"currency\".",
            "form.event_meta_value": "Meta-waarde van het evenement",
            "form.event_meta_value.hint": "Specificeert de meta-waarde van het evenement om te filteren, bijvoorbeeld \"USD\".",
            "form.metric_meta_key": "Aangepaste metriek sleutel",
            "form.metric_meta_key.hint": "Specificeert de meta-sleutel van het evenement om te aggregeren, bijvoorbeeld \"amount\".",
            "form.metric_type": "Type aangepaste metriek",
            "form.metric_type.hint": "Specificeert hoe de meta-waarde moet worden geïnterpreteerd, bijvoorbeeld decimaal voor \"42.99\" en geheel getal voor \"42\".",
            "form.visitors": "Bezoekersdoel (optioneel)",
            "form.cr": "CR-doel % (optioneel)",
            "form.total": "Totaal doel van aangepaste metriek (optioneel)",
            "form.avg": "Gemiddeld doel van aangepaste metriek (optioneel)",
            "form.reached": "Een e-mail sturen wanneer het doel is bereikt",
            "form.delete": "Het doel verwijderen nadat het is bereikt",
            "form.submit.save": "Opslaan",
            "form.submit.create": "Doel maken",
            "target": "Stel een doel in om een melding te ontvangen wanneer het doel is bereikt.",
            "plus": "Aangepaste metrieken vereisen een Pirsch Plus-abonnement.",
            "options.custom_metric.integer": "Geheel getal",
            "options.custom_metric.decimal": "Decimaal",
            "toast.success": "Het doel is gemaakt."
        },
        "it": {
            "title": "Obiettivo di conversione",
            "title.page": "Pagina",
            "title.event": "Evento e metrica personalizzata",
            "title.goal": "Obiettivo",
            "advanced.show": "Mostra opzioni avanzate (Espressione regolare)",
            "advanced.hide": "Nascondi opzioni avanzate (Espressione regolare)",
            "form.name": "Nome",
            "form.pattern": "Modello di percorso",
            "form.pattern.hint": "* per una singola directory, ** per più. Esempio: /mio/*/percorso/**",
            "form.regex": "Espressione regolare",
            "form.regex.valid": "L'espressione regolare è valida e corrisponde all'input di esempio.",
            "form.regex.invalid": "L'espressione regolare è valida ma non corrisponde all'input di esempio.",
            "form.sample": "Input di esempio",
            "form.sample.test": "Testa espressione regolare",
            "form.event": "Nome evento",
            "form.event_meta_key": "Chiave metadati evento",
            "form.event_meta_key.hint": "Specifica la chiave metadati dell'evento da filtrare, ad esempio \"currency\".",
            "form.event_meta_value": "Valore metadati evento",
            "form.event_meta_value.hint": "Specifica il valore metadati dell'evento da filtrare, ad esempio \"USD\".",
            "form.metric_meta_key": "Chiave metrica personalizzata",
            "form.metric_meta_key.hint": "Specifica la chiave metadati dell'evento da aggregare, ad esempio \"amount\".",
            "form.metric_type": "Tipo di metrica personalizzata",
            "form.metric_type.hint": "Specifica come interpretare il valore dei metadati, ad esempio decimale per \"42.99\" e intero per \"42\".",
            "form.visitors": "Obiettivo visitatori (opzionale)",
            "form.cr": "Obiettivo CR % (opzionale)",
            "form.total": "Obiettivo totale metrica personalizzata (opzionale)",
            "form.avg": "Obiettivo medio metrica personalizzata (opzionale)",
            "form.reached": "Invia un'email quando l'obiettivo è raggiunto",
            "form.delete": "Elimina l'obiettivo dopo che è stato raggiunto",
            "form.submit.save": "Salva",
            "form.submit.create": "Crea obiettivo",
            "target": "Imposta un obiettivo per ricevere una notifica quando l'obiettivo è raggiunto.",
            "plus": "Le metriche personalizzate richiedono un abbonamento a Pirsch Plus.",
            "options.custom_metric.integer": "Intero",
            "options.custom_metric.decimal": "Decimale",
            "toast.success": "L'obiettivo è stato creato."
        },
        "pt": {
            "title": "Meta de conversão",
            "title.page": "Página",
            "title.event": "Evento e métrica personalizada",
            "title.goal": "Meta",
            "advanced.show": "Mostrar opções avançadas (Expressão regular)",
            "advanced.hide": "Ocultar opções avançadas (Expressão regular)",
            "form.name": "Nome",
            "form.pattern": "Padrão de caminho",
            "form.pattern.hint": "* para um único diretório, ** para múltiplos. Exemplo: /meu/*/caminho/**",
            "form.regex": "Expressão regular",
            "form.regex.valid": "A expressão regular é válida e corresponde à entrada de exemplo.",
            "form.regex.invalid": "A expressão regular é válida, mas não corresponde à entrada de exemplo.",
            "form.sample": "Entrada de exemplo",
            "form.sample.test": "Testar expressão regular",
            "form.event": "Nome do evento",
            "form.event_meta_key": "Chave de metadados do evento",
            "form.event_meta_key.hint": "Especifica a chave de metadados do evento para filtrar, por exemplo, \"currency\".",
            "form.event_meta_value": "Valor de metadados do evento",
            "form.event_meta_value.hint": "Especifica o valor de metadados do evento para filtrar, por exemplo, \"USD\".",
            "form.metric_meta_key": "Chave de métrica personalizada",
            "form.metric_meta_key.hint": "Especifica a chave de metadados do evento para agregar, por exemplo, \"amount\".",
            "form.metric_type": "Tipo de métrica personalizada",
            "form.metric_type.hint": "Especifica como interpretar o valor de metadados, por exemplo, decimal para \"42.99\" e inteiro para \"42\".",
            "form.visitors": "Meta de visitantes (opcional)",
            "form.cr": "Meta de CR % (opcional)",
            "form.total": "Meta total de métrica personalizada (opcional)",
            "form.avg": "Meta média de métrica personalizada (opcional)",
            "form.reached": "Enviar um e-mail quando a meta for alcançada",
            "form.delete": "Excluir a meta após ser alcançada",
            "form.submit.save": "Salvar",
            "form.submit.create": "Criar meta",
            "target": "Defina uma meta para receber uma notificação quando a meta for alcançada.",
            "plus": "Métricas personalizadas requerem uma assinatura do Pirsch Plus.",
            "options.custom_metric.integer": "Inteiro",
            "options.custom_metric.decimal": "Decimal",
            "toast.success": "A meta foi criada."
        },
        "ja": {
            "title": "コンバージョン目標",
            "title.page": "ページ",
            "title.event": "イベントとカスタムメトリック",
            "title.goal": "目標",
            "advanced.show": "高度なオプションを表示（正規表現）",
            "advanced.hide": "高度なオプションを非表示（正規表現）",
            "form.name": "名前",
            "form.pattern": "パスパターン",
            "form.pattern.hint": "* は単一のディレクトリ用、** は複数用。例： /my/*/path/**",
            "form.regex": "正規表現",
            "form.regex.valid": "正規表現は有効で、サンプル入力に一致します。",
            "form.regex.invalid": "正規表現は有効ですが、サンプル入力に一致しません。",
            "form.sample": "サンプル入力",
            "form.sample.test": "正規表現をテスト",
            "form.event": "イベント名",
            "form.event_meta_key": "イベントメタキー",
            "form.event_meta_key.hint": "フィルタリングするイベントメタデータキーを指定します。例： \"currency\"。",
            "form.event_meta_value": "イベントメタ値",
            "form.event_meta_value.hint": "フィルタリングするイベントメタデータ値を指定します。例： \"USD\"。",
            "form.metric_meta_key": "カスタムメトリックキー",
            "form.metric_meta_key.hint": "集計するイベントメタデータキーを指定します。例： \"amount\"。",
            "form.metric_type": "カスタムメトリックタイプ",
            "form.metric_type.hint": "メタデータ値の解釈方法を指定します。例： \"42.99\" の場合は小数、\"42\" の場合は整数。",
            "form.visitors": "訪問者ターゲット（オプション）",
            "form.cr": "CRターゲット %（オプション）",
            "form.total": "カスタムメトリックの総目標（オプション）",
            "form.avg": "カスタムメトリックの平均目標（オプション）",
            "form.reached": "目標が達成されたらメールを送信",
            "form.delete": "目標達成後に削除",
            "form.submit.save": "保存",
            "form.submit.create": "目標を作成",
            "target": "目標が達成されたときに通知を受け取るためにターゲットを設定します。",
            "plus": "カスタムメトリックはPirsch Plusサブスクリプションが必要です。",
            "options.custom_metric.integer": "整数",
            "options.custom_metric.decimal": "小数",
            "toast.success": "目標が作成されました。"
        }
    }
</i18n>
