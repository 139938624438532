import {ref} from "vue";
import {Search, useFilterStore} from "@/store/FilterStore";
import {Filter} from "@/model/Filter";
import {entries_per_page} from "@/store/StatisticsStore";

export function useTable(load: (filter: Filter, top: boolean) => void, setFilter?: (filter: Filter) => void) {
    const filterStore = useFilterStore();
    const offset = ref(0);
    const sortColumn = ref("");
    const sortDirection = ref("");
    const query = ref("");

    function searchAndSort(search: Search) {
        offset.value = search.offset;
        sortColumn.value = search.column;
        sortDirection.value = search.direction;
        query.value = search.query;
        const f = filterStore.copy(filterStore.filter);
        f.offset = offset.value;
        f.limit = entries_per_page;
        f.search = query.value;
        f.sort = sortColumn.value;
        f.direction = sortDirection.value;

        if (setFilter) {
            setFilter(f);
        }

        load(f, false);
    }

    return {
        offset,
        sortColumn,
        sortDirection,
        query,
        searchAndSort
    };
}
