<template>
    <Modal :title="t('title')" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.link')" name="url" v-model="url" disabled />
            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyClipboard(url)"></i>
        </div>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import copy from "copy-to-clipboard";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput
        },
        props: {
            link: Object
        },
        emits: ["close"],
        setup(props) {
            const {t} = useI18n();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const url = computed(() => props.link ? `https://${domain.value.subdomain}.${import.meta.env.VITE_APP_DASHBOARD_DOMAIN}/?access=${props.link.code}` : "");

            function copyClipboard(value: string) {
                copy(value);
                success(t("toast.clipboard"));
            }

            return {
                t,
                url,
                copyClipboard
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Access Link",
            "text": "Copy the link and send it to someone to give access to your dashboard.",
            "form.link": "Link",
            "copy_to_clipboard": "Copy to Clipboard",
            "toast.clipboard": "Copied to clipboard."
        },
        "de": {
            "title": "Zugriffslink",
            "text": "Kopiere den Link und sende ihn an jemanden, um Zugang zu deinem Dashboard zu gewähren.",
            "form.link": "Link",
            "copy_to_clipboard": "In Zwischenablage kopieren",
            "toast.clipboard": "In Zwischenablage kopiert."
        },
        "es": {
            "title": "Enlace de acceso",
            "text": "Copia el enlace y envíalo a alguien para dar acceso a tu panel de control.",
            "form.link": "Enlace",
            "copy_to_clipboard": "Copiar al portapapeles",
            "toast.clipboard": "Copiado al portapapeles."
        },
        "fr": {
            "title": "Lien d'accès",
            "text": "Copiez le lien et envoyez-le à quelqu'un pour donner accès à votre tableau de bord.",
            "form.link": "Lien",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "toast.clipboard": "Copié dans le presse-papiers."
        },
        "nl": {
            "title": "Toegangslink",
            "text": "Kopieer de link en stuur deze naar iemand om toegang te geven tot je dashboard.",
            "form.link": "Link",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "toast.clipboard": "Gekopieerd naar klembord."
        },
        "it": {
            "title": "Link di accesso",
            "text": "Copia il link e invialo a qualcuno per dare accesso al tuo cruscotto.",
            "form.link": "Link",
            "copy_to_clipboard": "Copia negli appunti",
            "toast.clipboard": "Copiato negli appunti."
        },
        "pt": {
            "title": "Link de acesso",
            "text": "Copie o link e envie para alguém para dar acesso ao seu painel de controle.",
            "form.link": "Link",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "toast.clipboard": "Copiado para a área de transferência."
        },
        "ja": {
            "title": "アクセスリンク",
            "text": "リンクをコピーして、誰かにダッシュボードへのアクセスを提供してください。",
            "form.link": "リンク",
            "copy_to_clipboard": "クリップボードにコピー",
            "toast.clipboard": "クリップボードにコピーしました。"
        }
    }
</i18n>
