<template>
    <component :is="component"
        panel="entry_pages"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'entry_pages' : 'top_entry_pages')"
        :loadMore="loadMore(limitless ? 'entry_pages' : 'top_entry_pages')"
        :newTab="true"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :showDetails="showDetails"
        :valueTitle="t('title')"
        v-on:filter="filterByEntryPage"
        v-on:open="openPage"
        v-on:search="searchAndSort" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent, formatSeconds} from "@/util/format";
    import {openPage} from "@/components/statistics/page";
    import {ListEntry} from "@/model/ListEntry";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useTable} from "@/components/panels/table";
    import {Row} from "@/components/panels/Row";
    import {Filter} from "@/model/Filter";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadEntryPages, entryPages} = useStatisticsStore();
            const {setField} = filterStore;
            const {searchAndSort} = useTable(loadEntryPages, (f: Filter) => f.include_avg_time_on_page = true);
            const rawData = computed(() => entryPages(props.limitless));
            const data = ref<Row[]>([]);
            const columns = computed(() => [
                {key: "label", label: t("columns.path"), order_by: "entry_path"},
                {key: "value", label: t("columns.entries"), transform: (v: number) => formatAndShortenNumber(v), order_by: "entries"},
                {key: "visitors", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.entry_rate"), transform: (v: number) => formatPercent(v)+"%", order_by: "entry_rate"},
                {key: "average_time_on_page_seconds", label: t("columns.time"), transform: (v: number) => formatSeconds(v), order_by: "-"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("entry_pages_table");
                }
            });

            watch([rawData, () => props.visible], () => {
                if (props.visible) {
                    data.value = rawData.value;
                }
            });

            function filterByEntryPage(entry: ListEntry) {
                setField("entry_path", entry.label || "null");
            }

            return {
                t,
                columns,
                isLoading,
                loadMore,
                data,
                filterByEntryPage,
                openPage,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Entries",
            "columns.path": "Path",
            "columns.entries": "Entries",
            "columns.visitors": "Visitors",
            "columns.entry_rate": "Entry Rate",
            "columns.time": "Time on Page"
        },
        "de": {
            "title": "Einstiege",
            "columns.path": "Pfad",
            "columns.entries": "Einstiege",
            "columns.visitors": "Besucher",
            "columns.entry_rate": "Einstiegsrate",
            "columns.time": "Zeit auf der Seite"
        },
        "es": {
            "title": "Entradas",
            "columns.path": "Ruta",
            "columns.entries": "Entradas",
            "columns.visitors": "Visitantes",
            "columns.entry_rate": "Tasa de Entrada",
            "columns.time": "Tiempo en la Página"
        },
        "fr": {
            "title": "Entrées",
            "columns.path": "Chemin",
            "columns.entries": "Entrées",
            "columns.visitors": "Visiteurs",
            "columns.entry_rate": "Taux d'Entrée",
            "columns.time": "Temps sur la Page"
        },
        "nl": {
            "title": "Ingangen",
            "columns.path": "Pad",
            "columns.entries": "Ingangen",
            "columns.visitors": "Bezoekers",
            "columns.entry_rate": "Ingangspercentage",
            "columns.time": "Tijd op Pagina"
        },
        "it": {
            "title": "Ingressi",
            "columns.path": "Percorso",
            "columns.entries": "Ingressi",
            "columns.visitors": "Visitatori",
            "columns.entry_rate": "Tasso di Ingresso",
            "columns.time": "Tempo sulla Pagina"
        },
        "pt": {
            "title": "Entradas",
            "columns.path": "Caminho",
            "columns.entries": "Entradas",
            "columns.visitors": "Visitantes",
            "columns.entry_rate": "Taxa de Entrada",
            "columns.time": "Tempo na Página"
        },
        "ja": {
            "title": "エントリー",
            "columns.path": "パス",
            "columns.entries": "エントリー",
            "columns.visitors": "訪問者",
            "columns.entry_rate": "エントリー率",
            "columns.time": "ページの滞在時間"
        }
    }
</i18n>
