<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
            </small>
        </div>
        <div>
            <form v-on:submit.prevent="save">
                <FormInput :label="t('form.name')" name="name" v-model="name" :err="validationError('display_name')" />
                <FormSubmit :value="t('form.submit')" :loading="loading" />
            </form>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSubmit,
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const domainStore = useDomainStore();
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(domainStore);
            const loading = ref(false);
            const name = ref("");

            watchEffect(() => {
                name.value = domain.value.display_name ?? "";
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await DomainRepo.updateDisplayName(domain.value.id, name.value);
                    await domainStore.load();
                    loading.value = false;
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                validationError,
                loading,
                name,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Display Name",
            "text": "Specify a name to display on the dashboard instead of the hostname.",
            "form.name": "Name",
            "form.submit": "Save",
            "toast.success": "Display name saved."
        },
        "de": {
            "title": "Anzeigename",
            "text": "Gib einen Namen an, der auf dem Dashboard anstelle des Hostnamens angezeigt wird.",
            "form.name": "Name",
            "form.submit": "Speichern",
            "toast.success": "Anzeigename gespeichert."
        },
        "es": {
            "title": "Nombre para mostrar",
            "text": "Especifica un nombre para mostrar en el panel en lugar del nombre de host.",
            "form.name": "Nombre",
            "form.submit": "Guardar",
            "toast.success": "Nombre para mostrar guardado."
        },
        "fr": {
            "title": "Nom d'affichage",
            "text": "Spécifiez un nom à afficher sur le tableau de bord à la place du nom d'hôte.",
            "form.name": "Nom",
            "form.submit": "Enregistrer",
            "toast.success": "Nom d'affichage enregistré."
        },
        "nl": {
            "title": "Weergavenaam",
            "text": "Specificeer een naam om op het dashboard weer te geven in plaats van de hostnaam.",
            "form.name": "Naam",
            "form.submit": "Opslaan",
            "toast.success": "Weergavenaam opgeslagen."
        },
        "it": {
            "title": "Nome visualizzato",
            "text": "Specifica un nome da visualizzare sulla dashboard al posto del nome host.",
            "form.name": "Nome",
            "form.submit": "Salva",
            "toast.success": "Nome visualizzato salvato."
        },
        "pt": {
            "title": "Nome de exibição",
            "text": "Especifique um nome para exibir no painel em vez do nome do host.",
            "form.name": "Nome",
            "form.submit": "Salvar",
            "toast.success": "Nome de exibição salvo."
        },
        "ja": {
            "title": "表示名",
            "text": "ホスト名の代わりにダッシュボードに表示する名前を指定します。",
            "form.name": "名前",
            "form.submit": "保存",
            "toast.success": "表示名が保存されました。"
        }
    }
</i18n>
