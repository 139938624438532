<template>
    <i class="icon icon-sync clickable" :title="t('refresh')" v-on:click="refresh"></i>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            const {filter} = storeToRefs(useFilterStore());
            const {loadAll, loadKeywords} = useStatisticsStore();

            function refresh() {
                loadAll();
                loadKeywords(filter.value);
            }

            return {
                ...useI18n(),
                refresh
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "refresh": "Refresh"
        },
        "de": {
            "refresh": "Aktualisieren"
        },
        "es": {
            "refresh": "Actualizar"
        },
        "fr": {
            "refresh": "Rafraîchir"
        },
        "nl": {
            "refresh": "Vernieuwen"
        },
        "it": {
            "refresh": "Ricarica"
        },
        "pt": {
            "refresh": "Atualizar"
        },
        "ja": {
            "refresh": "リフレッシュ"
        }
    }
</i18n>
