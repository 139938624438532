<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <FormCheckbox :label="t('form.group')" name="groupByTitle" toggle v-model="groupByTitle" v-on:change="toggleGroupByTitle" />
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormCheckbox
        },
        setup() {
            const {t} = useI18n();
            const {domain} = storeToRefs(useDomainStore());
            const {success} = useToastStore();
            const groupByTitle = ref(false);

            watchEffect(() => {
                if (domain.value) {
                    groupByTitle.value = domain.value.group_by_title;
                }
            });

            async function toggleGroupByTitle() {
                await DomainRepo.toggleGroupByTitle(domain.value.id);
                domain.value.group_by_title = !domain.value.group_by_title;
                success(t("toast.success"));
            }

            return {
                t,
                groupByTitle,
                toggleGroupByTitle
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Page Title Collection",
            "text": "Enable collecting page titles and grouping pages by title instead of the path. This is useful for dynamically generated pages.",
            "form.group": "Collect and Group Pages by Title",
            "toast.success": "Settings saved."
        },
        "de": {
            "title": "Seitentitelsammlung",
            "text": "Aktiviere das Sammeln von Seitentiteln und das Gruppieren von Seiten nach Titel statt nach Pfad. Dies ist nützlich für dynamisch generierte Seiten.",
            "form.group": "Seiten nach Titel sammeln und gruppieren",
            "toast.success": "Einstellungen gespeichert."
        },
        "es": {
            "title": "Colección de títulos de páginas",
            "text": "Habilita la recopilación de títulos de páginas y la agrupación de páginas por título en lugar de por ruta. Esto es útil para páginas generadas dinámicamente.",
            "form.group": "Recopilar y agrupar páginas por título",
            "toast.success": "Configuraciones guardadas."
        },
        "fr": {
            "title": "Collecte de titres de page",
            "text": "Activez la collecte des titres de page et le regroupement des pages par titre au lieu du chemin. Ceci est utile pour les pages générées dynamiquement.",
            "form.group": "Collecter et regrouper les pages par titre",
            "toast.success": "Paramètres enregistrés."
        },
        "nl": {
            "title": "Paginatitelverzameling",
            "text": "Schakel het verzamelen van paginatitels en het groeperen van pagina's op titel in plaats van pad in. Dit is handig voor dynamisch gegenereerde pagina's.",
            "form.group": "Pagina's verzamelen en groeperen op titel",
            "toast.success": "Instellingen opgeslagen."
        },
        "it": {
            "title": "Raccolta di titoli di pagina",
            "text": "Abilita la raccolta dei titoli delle pagine e il raggruppamento delle pagine per titolo invece che per percorso. Questo è utile per le pagine generate dinamicamente.",
            "form.group": "Raccogli e raggruppa le pagine per titolo",
            "toast.success": "Impostazioni salvate."
        },
        "pt": {
            "title": "Coleta de títulos de páginas",
            "text": "Habilite a coleta de títulos de páginas e a agrupação de páginas por título em vez de caminho. Isso é útil para páginas geradas dinamicamente.",
            "form.group": "Coletar e agrupar páginas por título",
            "toast.success": "Configurações salvas."
        },
        "ja": {
            "title": "ページタイトルの収集",
            "text": "ページタイトルを収集し、パスの代わりにタイトルでページをグループ化することを有効にします。これは動的に生成されたページに役立ちます。",
            "form.group": "タイトルでページを収集およびグループ化",
            "toast.success": "設定が保存されました。"
        }
    }
</i18n>
