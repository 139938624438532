<template>
    <SelectDeleteData :open="deleteDataModal" v-on:close="deleteDataModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <button class="red" v-on:click="deleteDataModal = true">{{t("delete")}}</button>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import SelectDeleteData from "@/components/modal/SelectDeleteData.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            SelectDeleteData
        },
        setup() {
            const deleteDataModal = ref(false);

            return {
                ...useI18n(),
                deleteDataModal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Delete Data",
            "text": "Delete all or parts of your statistics for this website.",
            "delete": "Delete Data"
        },
        "de": {
            "title": "Daten löschen",
            "text": "Lösche alle oder Teile deiner Statistiken für diese Webseite.",
            "delete": "Daten löschen"
        },
        "es": {
            "title": "Eliminar datos",
            "text": "Elimina todos o parte de tus estadísticas para este sitio web.",
            "delete": "Eliminar datos"
        },
        "fr": {
            "title": "Supprimer les données",
            "text": "Supprimez tout ou partie de vos statistiques pour ce site Web.",
            "delete": "Supprimer les données"
        },
        "nl": {
            "title": "Gegevens verwijderen",
            "text": "Verwijder alle of delen van je statistieken voor deze website.",
            "delete": "Gegevens verwijderen"
        },
        "it": {
            "title": "Elimina dati",
            "text": "Elimina tutti o parte delle tue statistiche per questo sito web.",
            "delete": "Elimina dati"
        },
        "pt": {
            "title": "Excluir dados",
            "text": "Exclua todas ou partes das suas estatísticas para este site.",
            "delete": "Excluir dados"
        },
        "ja": {
            "title": "データを削除",
            "text": "このウェブサイトの統計のすべてまたは一部を削除します。",
            "delete": "データを削除"
        }
    }
</i18n>
