<template>
    <CreateEditTheme :open="editThemeModal"
        :theme="theme"
        v-on:close="editThemeModal = false"
        v-on:action="updateTheme" />
    <ConfirmDeleteTheme :open="deleteThemeModal"
        :theme="theme"
        v-on:close="deleteThemeModal = false"
        v-on:action="removeTheme" />
    <CopyTheme :open="copyThemeModal"
        :theme="theme"
        v-on:close="copyThemeModal = false"
        v-on:action="addTheme" />
    <div class="card">
        <div class="text">
            <strong>
                <span style="display: inline-block; vertical-align: middle;">{{theme.name}}</span>
                <i :class="{favorite: true, active: theme.is_default}" :title="t('favorite')" v-on:click.stop="setDefault" v-if="organization">
                    <svg v-if="theme.is_default" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><g clip-path="url(#A)"><path d="M8.9.9l1.927 3.911 4.32.629a1 1 0 0 1 .806.68 1 1 0 0 1-.253 1.025l-3.125 3.043.737 4.3a1 1 0 0 1-.398.978 1 1 0 0 1-1.053.076L8 13.513l-3.863 2.031a1 1 0 0 1-1.053-.076 1 1 0 0 1-.398-.978l.737-4.3L.3 7.145a1 1 0 0 1-.253-1.026 1 1 0 0 1 .809-.68l4.317-.627L7.1.9A1.04 1.04 0 0 1 8 .382 1.04 1.04 0 0 1 8.9.9z" /></g></svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><g clip-path="url(#A)"><path fill-rule="evenodd" d="M9.498 6.638L8 3.599 6.502 6.64l-3.355.487 2.425 2.365L5 12.831l3-1.577 2.999 1.576-.572-3.34 2.426-2.362-3.354-.488zm6.202.506l-3.125 3.043.737 4.3a1 1 0 0 1-.398.978 1 1 0 0 1-1.053.076L8 13.513l-3.863 2.031a1 1 0 0 1-1.053-.076 1 1 0 0 1-.398-.978l.737-4.3L.3 7.145a1 1 0 0 1-.253-1.026 1 1 0 0 1 .809-.68l4.317-.627L7.1.9A1.04 1.04 0 0 1 8 .382 1.04 1.04 0 0 1 8.9.9l1.927 3.911 4.32.629a1 1 0 0 1 .806.68 1 1 0 0 1-.253 1.025z" /></g></svg>
                </i>
            </strong>
        </div>
        <div class="row">
            <Lock :lock="`theme_${theme.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editThemeModal = true" v-if="canEdit" />
                <i class="icon icon-copy clickable" :title="t('duplicate')" v-on:click="copyThemeModal = true" v-if="canEdit" />
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteThemeModal = true" />
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import CreateEditTheme from "@/components/modal/CreateEditTheme.vue";
    import {Theme} from "@/model/Theme";
    import ConfirmDeleteTheme from "@/components/modal/ConfirmDeleteTheme.vue";
    import CopyTheme from "@/components/modal/CopyTheme.vue";
    import {Organization} from "@/model/Organization";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {useToastStore} from "@/store/ToastStore";
    import Lock from "@/components/settings/Lock.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Lock,
            ConfirmDeleteTheme,
            CreateEditTheme,
            CopyTheme
        },
        props: {
            theme: {type: Object as PropType<Theme>, required: true},
            organization: Object as PropType<Organization>,
            canEdit: {type: Boolean, default: false}
        },
        emits: ["add", "update", "remove", "default"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {success} = useToastStore();
            const editThemeModal = ref(false);
            const deleteThemeModal = ref(false);
            const copyThemeModal = ref(false);

            function addTheme(theme: Theme) {
                copyThemeModal.value = false;
                emit("add", theme);
            }

            function updateTheme(theme: Theme) {
                editThemeModal.value = false;
                emit("update", theme);
            }

            function removeTheme() {
                deleteThemeModal.value = false;
                emit("remove", props.theme);
            }

            async function setDefault() {
                if (props.organization) {
                    if (props.theme.is_default) {
                        await OrganizationRepo.setDefaultTheme(props.organization.id, "");
                        emit("default", "");
                    } else {
                        await OrganizationRepo.setDefaultTheme(props.organization.id, props.theme.id);
                        emit("default", props.theme.id);
                    }

                    success(t("toast.success"));
                }
            }

            return {
                t,
                editThemeModal,
                deleteThemeModal,
                copyThemeModal,
                addTheme,
                updateTheme,
                removeTheme,
                setDefault
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "favorite": "Set as Favorite",
            "edit": "Edit",
            "duplicate": "Duplicate Theme",
            "delete": "Delete",
            "toast.success": "Default theme updated."
        },
        "de": {
            "favorite": "Als Favorit festlegen",
            "edit": "Bearbeiten",
            "duplicate": "Thema duplizieren",
            "delete": "Löschen",
            "toast.success": "Standardthema aktualisiert."
        },
        "es": {
            "favorite": "Establecer como favorito",
            "edit": "Editar",
            "duplicate": "Duplicar tema",
            "delete": "Eliminar",
            "toast.success": "Tema predeterminado actualizado."
        },
        "fr": {
            "favorite": "Définir comme favori",
            "edit": "Éditer",
            "duplicate": "Dupliquer le thème",
            "delete": "Supprimer",
            "toast.success": "Thème par défaut mis à jour."
        },
        "nl": {
            "favorite": "Als favoriet instellen",
            "edit": "Bewerken",
            "duplicate": "Thema dupliceren",
            "delete": "Verwijderen",
            "toast.success": "Standaardthema bijgewerkt."
        },
        "it": {
            "favorite": "Imposta come preferito",
            "edit": "Modifica",
            "duplicate": "Duplica tema",
            "delete": "Elimina",
            "toast.success": "Tema predefinito aggiornato."
        },
        "pt": {
            "favorite": "Definir como favorito",
            "edit": "Editar",
            "duplicate": "Duplicar tema",
            "delete": "Excluir",
            "toast.success": "Tema padrão atualizado."
        },
        "ja": {
            "favorite": "お気に入りに設定",
            "edit": "編集",
            "duplicate": "テーマを複製",
            "delete": "削除",
            "toast.success": "デフォルトのテーマが更新されました。"
        }
    }
</i18n>
