<template>
    <div :class="{overview: true, 'overview-list': type === OverviewType.LIST}">
        <DomainPanel v-for="domain of list"
            :key="domain.id"
            :domain="domain"
            :type="type" />
        <AddDomainPanel />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, onUnmounted, ref} from "vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import DomainPanel from "@/components/domain/DomainPanel.vue";
    import AddDomainPanel from "@/components/domain/AddDomainPanel.vue";
    import {OverviewType, useOverviewStore} from "@/store/OverviewStore";

    const scrollDistanceBottom = 100;

    export default defineComponent({
        components: {
            AddDomainPanel,
            DomainPanel
        },
        setup() {
            const {type, search} = storeToRefs(useOverviewStore());
            const {domains} = storeToRefs(useDomainStore());
            const limit = ref(18);
            const list = computed(() => {
                const s = search.value.toLowerCase();

                if (!s) {
                    return domains.value.slice(0, limit.value);
                }

                return domains.value.filter(d => d.hostname.toLowerCase().includes(s)).slice(0, limit.value);
            });

            onMounted(() => {
                window.addEventListener("scroll", scroll);
            });

            onUnmounted(() => {
                window.removeEventListener("scroll", scroll);
            });

            function scroll() {
                if (document.body.scrollHeight - (window.innerHeight + window.scrollY) < scrollDistanceBottom &&
                    limit.value < domains.value.length) {
                    limit.value += 18;
                }
            }

            return {
                OverviewType,
                type,
                list
            };
        }
    });
</script>
