<template>
    <Expand>
        <div class="chart">
            <div class="chart-container" ref="container">
                <canvas :class="{'statistics-canvas': true, 'cursor-pointer': !hourFilter && !mobile}"
                    ref="canvas"
                    width="100%"
                    height="100%"></canvas>
            </div>
        </div>
    </Expand>
</template>

<script lang="ts">
    import {defineComponent, watch, ref, computed, onMounted, onUnmounted, PropType} from "vue";
    import {formatAndShortenNumber, formatNumber, formatPercent} from "@/util/format";
    import {Chart, ChartTypeRegistry, Tooltip} from "chart.js";
    import Expand from "@/components/bits/Expand.vue";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useMobile} from "@/components/mobile";
    import {useThemeStore} from "@/store/ThemeStore";
    import {debounce} from "@/util/debounce";
    import {getBackgroundColor, getBorderColor, getLineColor, getTextColor} from "@/components/panels/chart";

    export default defineComponent({
        components: {
            Expand
        },
        props: {
            labels: {type: Array as PropType<Array<string>>, required: true},
            data: {type: Array as PropType<Array<number>>, required: true},
            previousLabels: Array as PropType<Array<string>>,
            previousData: Array as PropType<Array<number>>,
            type: {type: String, default: "line"},
            growth: Number,
            arrowUpGreen: {type: Boolean, default: true},
            time: {type: Boolean, default: false},
            loading: {type: Boolean, default: true},
            formatYAxis: {type: Function, default: formatAndShortenNumber},
            formatValue: {type: Function, default: formatNumber}
        },
        emits: ["select"],
        setup(props, {emit}) {
            const {filter, hourFilter} = storeToRefs(useFilterStore());
            const {theme, darkMode} = storeToRefs(useThemeStore());
            const canvas = ref(document.createElement("canvas"));
            const container = ref(document.createElement("container"));
            const color = computed(() => {
                if (props.growth === undefined) {
                    return "green";
                }

                if (props.growth === 0) {
                    return "yellow";
                }

                if (!props.arrowUpGreen) {
                    return props.growth < 0 ? "green" : "red";
                }

                return props.growth > 0 ? "green" : "red";
            });
            let chart: Chart | undefined;
            let timer: number;

            onMounted(() => createOrUpdateChart());
            onUnmounted(() => {
                chart?.destroy();
                chart = undefined;
            });
            watch([() => props.loading, () => props.data, () => props.previousData, color], () => createOrUpdateChart());
            watch(() => props.type, updateChartType);
            watch(darkMode, () => updateChart(false));

            const createOrUpdateChart = debounce(() => {
                if (!props.loading) {
                    if (!chart) {
                        createChart(false);
                    } else {
                        updateChart(true);
                    }
                }
            }, 100);

            function createChart(animate: boolean) {
                const datasets = [];

                if (props.previousData?.length) {
                    datasets.push({
                        data: props.previousData as number[],
                        backgroundColor: getBackgroundColor(192, darkMode.value, color.value, true, false, theme.value),
                        borderColor: getBorderColor(darkMode.value, color.value, true, theme.value),
                        fill: true
                    });
                }

                datasets.push({
                    data: props.data as number[],
                    backgroundColor: getBackgroundColor(192, darkMode.value, color.value, false, false, theme.value),
                    borderColor: getBorderColor(darkMode.value, color.value, false, theme.value),
                    fill: true
                });

                chart = new Chart(canvas.value, {
                    type: props.type as unknown as keyof ChartTypeRegistry,
                    data: {
                        labels: props.labels as string[],
                        datasets
                    },
                    plugins: [Tooltip],
                    options: {
                        onClick: e => {
                            if (chart && e.native) {
                                const points = chart.getElementsAtEventForMode(e.native, "nearest", {intersect: false}, true);

                                if (points.length && chart.data.labels) {
                                    emit("select", points[0].index);
                                }
                            }
                        },
                        onResize: function() {
                            updateChartOptions(animate);
                        },
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    font: {family: props.time ? "DM Mono, monospace" : "DM Sans, sans-serif"},
                                    color: getTextColor(darkMode.value, theme.value),
                                    maxTicksLimit: getMaxTicksLimit(),
                                    autoSkip: true,
                                    maxRotation: 0,
                                    minRotation: 0
                                }
                            },
                            y: {
                                display: true,
                                grid: {
                                    color: getLineColor(darkMode.value, theme.value)
                                },
                                beginAtZero: true,
                                max: getMaxValue(),
                                ticks: {
                                    font: {family: "DM Sans, sans-serif"},
                                    color: getTextColor(darkMode.value, theme.value),
                                    precision: 0,
                                    maxTicksLimit: 6,
                                    padding: 8,
                                    callback: value => {
                                        return props.formatYAxis ? props.formatYAxis(value) : value;
                                    }
                                },
                                border: {
                                    display: false
                                }
                            }
                        },
                        elements: {
                            line: {
                                borderWidth: 2,
                                tension: 0,
                                borderJoinStyle: "round",
                                borderCapStyle: "round"
                            },
                            bar: {
                                borderWidth: 2,
                                borderRadius: 2
                            },
                            point: {
                                borderWidth: 0,
                                radius: 0,
                                hoverBorderWidth: 0,
                                hoverRadius: 0
                            }
                        },
                        animation: {
                            duration: 200
                        },
                        plugins: {
                            tooltip: {
                                mode: "index",
                                position: "nearest",
                                intersect: false,
                                titleFont: {family: "DM Sans, sans-serif", size: 14, weight: "normal"},
                                titleColor: "#adadad",
                                bodyFont: {family: "DM Sans, sans-serif", size: 16, weight: "bold"},
                                bodyColor: "#fff",
                                boxWidth: 2,
                                boxHeight: 2,
                                padding: 8,
                                cornerRadius: 6,
                                callbacks: {
                                    title: tooltipItems => {
                                        let title = tooltipItems[0].label;

                                        if (tooltipItems.length > 1 && props.previousLabels && tooltipItems[1].dataIndex < props.previousLabels.length) {
                                            title = props.previousLabels[tooltipItems[1].dataIndex] + " → " + title;
                                        }

                                        return title;
                                    },
                                    label: tooltipItem => {
                                        return "⠀" + (props.formatValue ? props.formatValue(tooltipItem.raw) : tooltipItem.raw);
                                    }
                                }
                            }
                        }
                    }
                });
                updateChartOptions(false);
                chart.update();
            }

            function updateChart(animation: boolean) {
                clearTimeout(timer);
                timer = setTimeout(function() {
                    if (chart) {
                        updateChartData();
                        updateChartOptions(animation);
                        chart.update();
                    }
                }, 10) as unknown as number;
            }

            function updateChartData() {
                if (chart && chart.data && chart.data.datasets && chart.data.datasets.length) {
                    chart.data.labels = props.labels as string[];

                    if (props.previousData?.length) {
                        chart.data.datasets = [
                            {
                                data: props.previousData as number[],
                                backgroundColor: getBackgroundColor(192, darkMode.value, color.value, true, false, theme.value),
                                borderColor: getBorderColor(darkMode.value, color.value, true, theme.value),
                                fill: true
                            },
                            {
                                data: props.data as number[],
                                backgroundColor: getBackgroundColor(192, darkMode.value, color.value, false, false, theme.value),
                                borderColor: getBorderColor(darkMode.value, color.value, false, theme.value),
                                fill: true
                            }
                        ];
                    } else {
                        if (chart.data.datasets.length > 1) {
                            chart.data.datasets.splice(0, 1);
                        }

                        chart.data.datasets[0] = {
                            data: props.data as number[],
                            backgroundColor: getBackgroundColor(192, darkMode.value, color.value, false, false, theme.value),
                            borderColor: getBorderColor(darkMode.value, color.value, false, theme.value),
                            fill: true
                        };
                    }
                }
            }

            function updateChartOptions(animation: boolean) {
                if (chart &&
                    chart.options &&
                    chart.options.scales &&
                    chart.options.scales.x &&
                    chart.options.scales.y &&
                    chart.options.scales.x.ticks &&
                    chart.options.scales.y.ticks &&
                    chart.options.scales.y.grid &&
                    chart.options.animation &&
                    chart.options.plugins &&
                    chart.options.plugins.tooltip) {
                    const textColor = getTextColor(darkMode.value, theme.value);
                    chart.options.scales.x.ticks.maxTicksLimit = getMaxTicksLimit();
                    chart.options.animation.duration = animation ? 200 : 0;
                    chart.options.scales.x.ticks.color = textColor;
                    chart.options.scales.y.max = getMaxValue();
                    chart.options.scales.y.ticks.color = textColor;
                    chart.options.scales.y.grid.color = getLineColor(darkMode.value, theme.value);
                }
            }

            function updateChartType() {
                if (chart) {
                    chart.destroy();
                    createChart(true);
                }
            }

            function getMaxTicksLimit() {
                if (container.value && chart && chart.data && chart.data.labels && chart.data.labels.length) {
                    let m = 50;

                    switch (filter.value.scale) {
                        case "day":
                        case "month":
                            m = 13;
                            break;
                    }

                    const w = container.value.clientWidth/1000;
                    const n = chart.data.labels.length;
                    return Math.round(Math.min(m*w, n*w));
                }

                return 1;
            }

            function getMaxValue() {
                let max = 0;

                if (props.data) {
                    for (let i = 0; i < props.data.length; i++) {
                        if (props.data[i] > max) {
                            max = props.data[i];
                        }
                    }
                }

                if (props.previousData) {
                    for (let i = 0; i < props.previousData.length; i++) {
                        if (props.previousData[i] > max) {
                            max = props.previousData[i];
                        }
                    }
                }

                return max > 0 ? max : undefined;
            }

            return {
                ...useMobile(),
                formatPercent,
                hourFilter,
                canvas,
                container
            };
        }
    });
</script>
