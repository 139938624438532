<template>
    <CreateEditTheme :open="createThemeModal"
        v-on:close="createThemeModal = false"
        v-on:action="addTheme" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/theme" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <ThemeCard v-for="theme in themes"
                        :key="theme.id"
                        :theme="theme"
                        :can-edit="user.free_trial || user.subscription_plus"
                        v-on:add="addTheme"
                        v-on:update="updateTheme"
                        v-on:remove="removeTheme" />
                </div>
            </Expand>
            <button v-on:click="createThemeModal = true" v-if="user.free_trial || user.subscription_plus">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
            <PirschPlus :show="!user.free_trial && !user.subscription_plus">
                <strong>{{t("plus_0")}}</strong>
                <i18n-t keypath="plus_1" tag="strong">
                    <router-link to="/settings/theme">{{t("plus_link")}}</router-link>
                </i18n-t>
            </PirschPlus>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {Theme} from "@/model/Theme";
    import {ThemeRepo} from "@/repositories/ThemeRepo";
    import ThemeCard from "@/components/cards/ThemeCard.vue";
    import CreateEditTheme from "@/components/modal/CreateEditTheme.vue";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            PirschPlus,
            Loading,
            Expand,
            ThemeCard,
            CreateEditTheme
        },
        setup() {
            const {user} = storeToRefs(useUserStore());
            const {removeEntryByID, updateEntryByID} = useList();
            const createThemeModal = ref(false);
            const loading = ref(true);
            const themes = ref<Theme[]>([]);

            watchEffect(async () => {
                loading.value = true;
                themes.value = await ThemeRepo.list("", false) as Theme[];
                loading.value = false;
            });

            function addTheme(theme: Theme) {
                createThemeModal.value = false;
                themes.value.push(theme);
            }

            function updateTheme(theme: Theme) {
                updateEntryByID(themes.value, theme);
            }

            function removeTheme(theme: Theme) {
                removeEntryByID(themes.value, theme);
            }

            return {
                ...useI18n(),
                user,
                createThemeModal,
                loading,
                themes,
                addTheme,
                updateTheme,
                removeTheme
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Saved Themes",
            "text": "Customize your dashboards with colors, logos, and more.",
            "link": "Read the Docs",
            "add": "Add Theme",
            "plus_0": "Using themes requires a Pirsch Plus subscription.",
            "plus_1": "You can temporarily test themes on the {0}.",
            "plus_link": "Dashboard Settings Page"
        },
        "de": {
            "title": "Gespeicherte Themen",
            "text": "Passe deine Dashboards mit Farben, Logos und mehr an.",
            "link": "Dokumentation lesen",
            "add": "Thema hinzufügen",
            "plus_0": "Die Verwendung von Themen erfordert ein Pirsch Plus-Abonnement.",
            "plus_1": "Du kannst Themen vorübergehend auf der {0} testen.",
            "plus_link": "Dashboard-Einstellungsseite"
        },
        "es": {
            "title": "Temas Guardados",
            "text": "Personaliza tus tableros con colores, logotipos y más.",
            "link": "Leer la documentación",
            "add": "Agregar tema",
            "plus_0": "El uso de temas requiere una suscripción a Pirsch Plus.",
            "plus_1": "Puedes probar temas temporalmente en la {0}.",
            "plus_link": "Página de Configuración del Tablero"
        },
        "fr": {
            "title": "Thèmes Enregistrés",
            "text": "Personnalisez vos tableaux de bord avec des couleurs, des logos et plus encore.",
            "link": "Lire la documentation",
            "add": "Ajouter un thème",
            "plus_0": "L'utilisation des thèmes nécessite un abonnement Pirsch Plus.",
            "plus_1": "Vous pouvez tester temporairement des thèmes sur la {0}.",
            "plus_link": "Page des Paramètres du Tableau de Bord"
        },
        "nl": {
            "title": "Opgeslagen Thema's",
            "text": "Pas je dashboards aan met kleuren, logo's en meer.",
            "link": "Lees de documentatie",
            "add": "Thema toevoegen",
            "plus_0": "Het gebruik van thema's vereist een Pirsch Plus-abonnement.",
            "plus_1": "Je kunt thema's tijdelijk testen op de {0}.",
            "plus_link": "Dashboard-instellingenpagina"
        },
        "it": {
            "title": "Temi Salvati",
            "text": "Personalizza i tuoi dashboard con colori, loghi e altro.",
            "link": "Leggi la documentazione",
            "add": "Aggiungi tema",
            "plus_0": "L'utilizzo dei temi richiede un abbonamento a Pirsch Plus.",
            "plus_1": "Puoi testare temporaneamente i temi sulla {0}.",
            "plus_link": "Pagina delle Impostazioni del Dashboard"
        },
        "pt": {
            "title": "Temas Salvos",
            "text": "Personalize seus painéis com cores, logotipos e mais.",
            "link": "Leia a documentação",
            "add": "Adicionar tema",
            "plus_0": "Usar temas requer uma assinatura do Pirsch Plus.",
            "plus_1": "Você pode testar temas temporariamente na {0}.",
            "plus_link": "Página de Configurações do Painel"
        },
        "ja": {
            "title": "保存されたテーマ",
            "text": "色、ロゴなどでダッシュボードをカスタマイズします。",
            "link": "ドキュメントを読む",
            "add": "テーマを追加",
            "plus_0": "テーマの使用にはPirsch Plusのサブスクリプションが必要です。",
            "plus_1": "{0}でテーマを一時的にテストできます。",
            "plus_link": "ダッシュボード設定ページ"
        }
    }
</i18n>
