<template>
    <section class="form">
        <h1>{{t("title")}}</h1>
        <p>{{t("text")}}</p>
        <form v-on:submit.stop.prevent="update">
            <FormInput :label="t('form.password')" name="password" autocomplete="new-password" type="password" v-model="password" :err="validationError('password')" />
            <FormInput :label="t('form.confirm')" autocomplete="new-password" name="passwordConfirm" type="password" v-model="passwordConfirm" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
        <AppFooterSmall />
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {useUser} from "@/views/user";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useRouter} from "vue-router";
    import AppFooterSmall from "@/components/bits/AppFooterSmall.vue";
    import {useI18n} from "vue-i18n";
    import {APIError} from "@/repositories/APIError";

    export default defineComponent({
        components: {AppFooterSmall, FormSubmit, FormInput},
        setup() {
            useUser();
            const router = useRouter();
            const {validationError, setError, resetError} = useError();
            const password = ref("");
            const passwordConfirm = ref("");
            const loading = ref(false);

            async function update() {
                try {
                    loading.value = true;
                    resetError();
                    await UserRepo.updatePassword(password.value, passwordConfirm.value);
                    loading.value = false;
                    await router.push("/");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                ...useI18n(),
                validationError,
                password,
                passwordConfirm,
                loading,
                update
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Reset Password",
            "text": "Change your password before continuing to Pirsch.",
            "form.password": "New Password",
            "form.confirm": "Confirm New Password",
            "form.submit": "Save"
        },
        "de": {
            "title": "Passwort zurücksetzen",
            "text": "Ändere dein Passwort, bevor du mit Pirsch fortfährst.",
            "form.password": "Neues Passwort",
            "form.confirm": "Neues Passwort bestätigen",
            "form.submit": "Speichern"
        },
        "es": {
            "title": "Restablecer contraseña",
            "text": "Cambia tu contraseña antes de continuar a Pirsch.",
            "form.password": "Nueva contraseña",
            "form.confirm": "Confirmar nueva contraseña",
            "form.submit": "Guardar"
        },
        "fr": {
            "title": "Réinitialiser le mot de passe",
            "text": "Changez votre mot de passe avant de continuer sur Pirsch.",
            "form.password": "Nouveau mot de passe",
            "form.confirm": "Confirmer le nouveau mot de passe",
            "form.submit": "Enregistrer"
        },
        "nl": {
            "title": "Wachtwoord resetten",
            "text": "Wijzig je wachtwoord voordat je doorgaat naar Pirsch.",
            "form.password": "Nieuw wachtwoord",
            "form.confirm": "Bevestig nieuw wachtwoord",
            "form.submit": "Opslaan"
        },
        "it": {
            "title": "Reimposta password",
            "text": "Cambia la tua password prima di continuare su Pirsch.",
            "form.password": "Nuova password",
            "form.confirm": "Conferma nuova password",
            "form.submit": "Salva"
        },
        "pt": {
            "title": "Redefinir senha",
            "text": "Altere sua senha antes de continuar para o Pirsch.",
            "form.password": "Nova senha",
            "form.confirm": "Confirmar nova senha",
            "form.submit": "Salvar"
        },
        "ja": {
            "title": "パスワードのリセット",
            "text": "Pirschを続行する前にパスワードを変更してください。",
            "form.password": "新しいパスワード",
            "form.confirm": "新しいパスワードを確認",
            "form.submit": "保存"
        }
    }
</i18n>
