<template>
    <section class="form">
        <h1>{{t("title")}}</h1>
        <p v-if="codeNotFound">{{t("not_found")}}</p>
        <template v-else>
            <p>{{t("text")}}</p>
            <form v-on:submit.prevent="complete">
                <FormInput :label="t('form.name')" name="name" autocomplete="name" v-model="fullName" :err="validationError('full_name')" />
                <FormInput :label="t('form.password')" name="name" autocomplete="new-password" type="password" v-model="password" :err="validationError('password')" />
                <FormInput :label="t('form.confirm')" name="name" autocomplete="new-password" type="password" v-model="confirmPassword" />
                <FormCheckbox v-if="!selfHosted" :label="formTerms" name="terms" v-model="terms" :err="validationError('accept_terms_of_service')" />
                <FormCheckbox v-if="!selfHosted" :label="t('form.marketing')" name="marketing" v-model="marketing" />
                <FormSubmit :value="t('form.submit')" :loading="loading" />
            </form>
        </template>
        <AppFooterSmall />
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import {RefreshTokenResponse, UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import {useRoute, useRouter} from "vue-router";
    import {useUser} from "@/views/user";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useLogin} from "@/views/login";
    import {useInit} from "@/init";
    import AppFooterSmall from "@/components/bits/AppFooterSmall.vue";
    import {isSelfHosted} from "@/util/domain";
    import {useI18n} from "vue-i18n";
    import {APIError} from "@/repositories/APIError";

    export default defineComponent({
        components: {
            AppFooterSmall,
            FormCheckbox,
            FormSubmit,
            FormInput
        },
        setup() {
            useUser();
            const selfHosted = isSelfHosted();
            const route = useRoute();
            const router = useRouter();
            const {validationError, setError, resetError} = useError();
            const {login} = useLogin();
            const {init} = useInit();
            const {t} = useI18n();
            const loading = ref(false);
            const codeNotFound = ref(false);
            const fullName = ref("");
            const password = ref("");
            const confirmPassword = ref("");
            const terms = ref(selfHosted);
            const marketing = ref(false);

            onMounted(async () => {
                try {
                    const code = route.query.code || "";
                    await UserRepo.lookupRegistrationCode(code as string);
                } catch (e) {
                    codeNotFound.value = true;
                }
            });

            async function complete() {
                try {
                    loading.value = true;
                    resetError();
                    const code = route.query.code || "";
                    const data = await UserRepo.completeRegistration(code as string,
                        fullName.value,
                        password.value,
                        confirmPassword.value,
                        marketing.value,
                        terms.value) as RefreshTokenResponse;
                    await login(data.access_token, data.refresh_token, false);
                    await init();
                    loading.value = false;
                    await router.push("/");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                formTerms: `${t('form.terms_0')} <a href='${import.meta.env.VITE_APP_WEBSITE_HOST}/terms' target='_blank'>${t('form.terms_1')}</a> ${t('form.terms_2')} <a href='${import.meta.env.VITE_APP_WEBSITE_HOST}/privacy' target='_blank'>${t('form.terms_3')}</a>.`,
                selfHosted,
                validationError,
                loading,
                codeNotFound,
                fullName,
                password,
                confirmPassword,
                terms,
                marketing,
                complete
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Final Step",
            "not_found": "The registration was not found.",
            "text": "Select your name and a strong password to finish your registration.",
            "form.name": "Your name",
            "form.password": "Password",
            "form.confirm": "Confirm Password",
            "form.terms_0": "I confirm that I have read and agree to the",
            "form.terms_1": "Terms and Conditions",
            "form.terms_2": "and",
            "form.terms_3": "Privacy Policy",
            "form.marketing": "I would like to receive regular news on product updates and offers. The newsletter can be cancelled at any time.",
            "form.submit": "Create Account"
        },
        "de": {
            "title": "Letzter Schritt",
            "not_found": "Die Registrierung wurde nicht gefunden.",
            "text": "Wähle deinen Namen und ein starkes Passwort, um deine Registrierung abzuschließen.",
            "form.name": "Dein Name",
            "form.password": "Passwort",
            "form.confirm": "Passwort bestätigen",
            "form.terms_0": "Ich bestätige, dass ich die",
            "form.terms_1": "Allgemeinen Geschäftsbedingungen",
            "form.terms_2": "und die",
            "form.terms_3": "Datenschutzrichtlinie",
            "form.marketing": "Ich möchte regelmäßig Neuigkeiten über Produktupdates und Angebote erhalten. Der Newsletter kann jederzeit abbestellt werden.",
            "form.submit": "Konto erstellen"
        },
        "es": {
            "title": "Paso final",
            "not_found": "No se encontró el registro.",
            "text": "Selecciona tu nombre y una contraseña segura para finalizar tu registro.",
            "form.name": "Tu nombre",
            "form.password": "Contraseña",
            "form.confirm": "Confirmar contraseña",
            "form.terms_0": "Confirmo que he leído y acepto los",
            "form.terms_1": "Términos y Condiciones",
            "form.terms_2": "y la",
            "form.terms_3": "Política de Privacidad",
            "form.marketing": "Me gustaría recibir noticias regulares sobre actualizaciones de productos y ofertas. El boletín puede cancelarse en cualquier momento.",
            "form.submit": "Crear cuenta"
        },
        "fr": {
            "title": "Dernière étape",
            "not_found": "L'inscription n'a pas été trouvée.",
            "text": "Sélectionnez votre nom et un mot de passe fort pour terminer votre inscription.",
            "form.name": "Votre nom",
            "form.password": "Mot de passe",
            "form.confirm": "Confirmer le mot de passe",
            "form.terms_0": "Je confirme avoir lu et accepter les",
            "form.terms_1": "Conditions Générales",
            "form.terms_2": "et la",
            "form.terms_3": "Politique de Confidentialité",
            "form.marketing": "Je souhaite recevoir régulièrement des nouvelles sur les mises à jour de produits et les offres. La newsletter peut être annulée à tout moment.",
            "form.submit": "Créer un compte"
        },
        "nl": {
            "title": "Laatste stap",
            "not_found": "De registratie is niet gevonden.",
            "text": "Selecteer je naam en een sterk wachtwoord om je registratie te voltooien.",
            "form.name": "Je naam",
            "form.password": "Wachtwoord",
            "form.confirm": "Bevestig wachtwoord",
            "form.terms_0": "Ik bevestig dat ik de",
            "form.terms_1": "Algemene voorwaarden",
            "form.terms_2": "en het",
            "form.terms_3": "Privacybeleid",
            "form.marketing": "Ik wil graag regelmatig nieuws ontvangen over productupdates en aanbiedingen. De nieuwsbrief kan op elk moment worden geannuleerd.",
            "form.submit": "Account aanmaken"
        },
        "it": {
            "title": "Passaggio finale",
            "not_found": "La registrazione non è stata trovata.",
            "text": "Seleziona il tuo nome e una password forte per completare la tua registrazione.",
            "form.name": "Il tuo nome",
            "form.password": "Password",
            "form.confirm": "Conferma password",
            "form.terms_0": "Confermo di aver letto e accettato i",
            "form.terms_1": "Termini e condizioni",
            "form.terms_2": "e la",
            "form.terms_3": "Informativa sulla privacy",
            "form.marketing": "Vorrei ricevere notizie regolari sugli aggiornamenti dei prodotti e sulle offerte. La newsletter può essere annullata in qualsiasi momento.",
            "form.submit": "Crea account"
        },
        "pt": {
            "title": "Etapa final",
            "not_found": "O registro não foi encontrado.",
            "text": "Selecione seu nome e uma senha forte para finalizar seu registro.",
            "form.name": "Seu nome",
            "form.password": "Senha",
            "form.confirm": "Confirmar senha",
            "form.terms_0": "Confirmo que li e concordo com os",
            "form.terms_1": "Termos e Condições",
            "form.terms_2": "e a",
            "form.terms_3": "Política de Privacidade",
            "form.marketing": "Gostaria de receber notícias regulares sobre atualizações de produtos e ofertas. A newsletter pode ser cancelada a qualquer momento.",
            "form.submit": "Criar conta"
        },
        "ja": {
            "title": "最終ステップ",
            "not_found": "登録が見つかりませんでした。",
            "text": "名前と強力なパスワードを選択して登録を完了してください。",
            "form.name": "あなたの名前",
            "form.password": "パスワード",
            "form.confirm": "パスワードを認証する",
            "form.terms_0": "私は",
            "form.terms_1": "利用規約",
            "form.terms_2": "および",
            "form.terms_3": "プライバシーポリシー",
            "form.marketing": "製品のアップデートやオファーに関する定期的なニュースを受け取りたいです。ニュースレターはいつでもキャンセルできます。",
            "form.submit": "アカウントを作成"
        }
    }
</i18n>
