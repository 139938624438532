const fieldLabels = new Map;
fieldLabels.set("hostname", "Hostname");
fieldLabels.set("path", "Path");
fieldLabels.set("entry_path", "Entry Page");
fieldLabels.set("exit_path", "Exit Page");
fieldLabels.set("pattern", "Pattern");
fieldLabels.set("event", "Event");
fieldLabels.set("referrer", "Referrer");
fieldLabels.set("referrer_name", "Referrer");
fieldLabels.set("utm_source", "UTM Source");
fieldLabels.set("utm_medium", "UTM Medium");
fieldLabels.set("utm_campaign", "UTM Campaign");
fieldLabels.set("utm_content", "UTM Content");
fieldLabels.set("utm_term", "UTM Term");
fieldLabels.set("language", "Language");
fieldLabels.set("country", "Country");
fieldLabels.set("region", "Region");
fieldLabels.set("city", "City");
fieldLabels.set("os", "OS");
fieldLabels.set("browser", "Browser");
fieldLabels.set("platform", "Platform");
fieldLabels.set("screen_class", "Screen Class");
fieldLabels.set("custom_metric_type", "Custom Metric Type");
fieldLabels.set("custom_metric_key", "Custom Metric Key");
fieldLabels.set("tag", "Tag");
export const filterFields = fieldLabels;

export const filterOptions = [
    {field: "hostname", label: "Hostname"},
    {field: "path", label: "Page"},
    {field: "pattern", label: "Pattern (Regex)"},
    {field: "entry_path", label: "Entry Page"},
    {field: "exit_path", label: "Exit Page"},
    {field: "referrer_name", label: "Referrer Name"},
    {field: "referrer", label: "Referrer"},
    {field: "utm_source", label: "UTM Source"},
    {field: "utm_medium", label: "UTM Medium"},
    {field: "utm_campaign", label: "UTM Campaign"},
    {field: "utm_content", label: "UTM Content"},
    {field: "utm_term", label: "UTM Term"},
    {field: "event", label: "Event"},
    {field: "country", label: "Country"},
    {field: "region", label: "Region"},
    {field: "city", label: "City"},
    {field: "language", label: "Language"},
    {field: "os", label: "OS"},
    {field: "browser", label: "Browser"},
    {field: "platform", label: "Platform"},
    {field: "screen_class", label: "Screen"},
    {field: "tag", label: "Tag"},
];

export const filterSkipContains = [
    "path_pattern",
    "pattern",
    "path_regex",
    "country",
    "language",
    "os",
    "browser",
    "platform",
    "screen_class",
    "tag"
];

export const filterSkipOptions = [
    "pattern",
    "custom_metric_type"
];
