import {AxiosError, AxiosInstance} from "axios";
import {APIError} from "@/repositories/APIError";
import axios from "@/repositories/axios";

export class Repository {
    protected axios: AxiosInstance = axios;
    private controller: Map<string, AbortController> = new Map;

    protected async performGet<T>(endpoint: string, params: unknown, defaultValue: T, disableCancellation?: boolean, cancellationName?: string): Promise<T | APIError> {
        try {
            let controller = null;

            if (!disableCancellation) {
                controller = this.cancel(cancellationName+endpoint);
            }

            const resp = await this.axios.get<T>(endpoint, {
                params,
                signal: controller?.signal
            });
            return Promise.resolve<T>(resp.data || defaultValue);
        } catch (e) {
            return this.handleException<T>(e, defaultValue);
        } finally {
            if (!disableCancellation) {
                this.clearCancel(cancellationName+endpoint);
            }
        }
    }

    protected async performPost<T>(endpoint: string, data: unknown): Promise<T | APIError> {
        try {
            const resp = await this.axios.post<T>(endpoint, data);
            return Promise.resolve<T>(resp.data);
        } catch (e) {
            const err = e as AxiosError;
            return Promise.reject<APIError>(err);
        }
    }

    protected async performPut<T>(endpoint: string, data: unknown): Promise<T | APIError> {
        try {
            const resp = await this.axios.put<T>(endpoint, data);
            return Promise.resolve<T>(resp.data);
        } catch (e) {
            const err = e as AxiosError;
            return Promise.reject<APIError>(err);
        }
    }

    protected async performDelete(endpoint: string, params: unknown): Promise<APIError | null> {
        try {
            await this.axios.delete<null>(endpoint, {params});
            return Promise.resolve<null>(null);
        } catch (e) {
            return Promise.reject<APIError>(e);
        }
    }

    protected handleException<T>(e: unknown, defaultValue: T): Promise<T | APIError> {
        const err = e as AxiosError;

        if (err.code && (err.code === "ERR_CANCELED" || err.code === "ECONNABORTED")) {
            return Promise.resolve<T>(defaultValue);
        }

        return Promise.reject<APIError>(err);
    }

    private cancel(name: string): AbortController {
        const controller = this.controller.get(name);

        if (controller) {
            controller.abort();
        }

        const newController = new AbortController();
        this.controller.set(name, newController);
        return newController;
    }

    private clearCancel(name: string) {
        this.controller.delete(name);
    }
}
