<template>
    <ResetIdentificationCode :open="resetIdentificationCodeModal"
        v-on:close="resetIdentificationCodeModal = false"
        v-on:action="updateIdentificationCode" />
    <section class="row">
        <div>
            <h3>{{t("snippet.title")}}</h3>
            <small>
                <i18n-t keypath="snippet.text_0">
                    <strong>&lt;head&gt;</strong>
                </i18n-t>
                <br />
                <a href="https://docs.pirsch.io/get-started/frontend-integration" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("snippet.link")}}</a>
            </small>
        </div>
        <div>
            <p style="margin-bottom: 12px;">{{t("snippet.text_1")}}</p>
            <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
                <pre style="max-width: calc(100% - 36px); margin: 0;">
                    <code v-html="snippet"></code>
                </pre>
                <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copySnippet(snippet)"></i>
            </div>
            <Snippet v-on:update="updateSnippet" />
        </div>
    </section>
    <hr />
    <section class="row">
        <div>
            <h3>{{t("code.title")}}</h3>
            <small>
                {{t("code.text")}}
                <br />
                <a href="https://docs.pirsch.io/get-started/frontend-integration#resetting-the-identification-code" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("code.link")}}</a>
            </small>
        </div>
        <div>
            <div class="input-with-icon">
                <fieldset style="width: 320px;">
                    <input id="code" type="text" :value="domain.identification_code" placeholder=" " readonly />
                    <label for="code">{{t("code")}}</label>
                </fieldset>
                <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyIdentificationCode"></i>
            </div>
            <button v-on:click="resetIdentificationCodeModal = true" style="margin-top: 12px;">{{t("reset")}}</button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import copy from "copy-to-clipboard";
    import {IdentificationCode} from "@/model/IdentificationCode";
    import {useSnippet} from "@/components/settings/snippet";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import ResetIdentificationCode from "@/components/modal/ResetIdentificationCode.vue";
    import Snippet from "@/components/settings/Snippet.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Snippet,
            ResetIdentificationCode
        },
        setup() {
            const {t} = useI18n();
            const {domain} = storeToRefs(useDomainStore());
            const {success} = useToastStore();
            const {copySnippet} = useSnippet();
            const resetIdentificationCodeModal = ref(false);
            const snippet = ref("");

            function updateIdentificationCode(identificationCode: IdentificationCode) {
                domain.value.identification_code = identificationCode.identification_code;
                resetIdentificationCodeModal.value = false;
            }

            function copyIdentificationCode() {
                copy(domain.value.identification_code);
                success(t("toast.clipboard"));
            }

            function updateSnippet(s: string) {
                snippet.value = s;
            }

            return {
                t,
                resetIdentificationCodeModal,
                domain,
                snippet,
                updateIdentificationCode,
                copyIdentificationCode,
                copySnippet,
                updateSnippet
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "snippet.title": "Snippet",
            "snippet.text_0": "Copy the code snippet into the {0} section of your website.",
            "snippet.link": "Read the Docs",
            "snippet.text_1": "Track page views from your website. Includes automatic outbound link tracking, file download tracking, and events using JavaScript, CSS classes, and HTML attributes.",
            "code.title": "Identification Code",
            "code.text": "Create a new identification code for the JavaScript integration. You have to replace it on your website.",
            "code.link": "Read the Docs",
            "code": "Identification code",
            "reset": "Reset Identification Code",
            "copy_to_clipboard": "Copy to Clipboard",
            "toast.clipboard": "Copied identification code to clipboard."
        },
        "de": {
            "snippet.title": "Snippet",
            "snippet.text_0": "Kopiere den Code-Snippet in den {0}-Abschnitt deiner Webseite.",
            "snippet.link": "Dokumentation lesen",
            "snippet.text_1": "Verfolge Seitenaufrufe von deiner Webseite. Beinhaltet automatisches Tracking von ausgehenden Links, Dateidownloads und Events mit JavaScript, CSS-Klassen und HTML-Attributen.",
            "code.title": "Identifikationscode",
            "code.text": "Erstelle einen neuen Identifikationscode für die JavaScript-Integration. Du musst ihn auf deiner Webseite ersetzen.",
            "code.link": "Dokumentation lesen",
            "code": "Identifikationscode",
            "reset": "Identifikationscode zurücksetzen",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "toast.clipboard": "Identifikationscode in die Zwischenablage kopiert."
        },
        "es": {
            "snippet.title": "Fragmento",
            "snippet.text_0": "Copia el fragmento de código en la sección {0} de tu sitio web.",
            "snippet.link": "Leer la documentación",
            "snippet.text_1": "Rastrea las vistas de página desde tu sitio web. Incluye seguimiento automático de enlaces salientes, descargas de archivos y eventos usando JavaScript, clases CSS y atributos HTML.",
            "code.title": "Código de identificación",
            "code.text": "Crea un nuevo código de identificación para la integración de JavaScript. Debes reemplazarlo en tu sitio web.",
            "code.link": "Leer la documentación",
            "code": "Código de identificación",
            "reset": "Restablecer código de identificación",
            "copy_to_clipboard": "Copiar al portapapeles",
            "toast.clipboard": "Código de identificación copiado al portapapeles."
        },
        "fr": {
            "snippet.title": "Extrait de code",
            "snippet.text_0": "Copiez l'extrait de code dans la section {0} de votre site Web.",
            "snippet.link": "Lire la documentation",
            "snippet.text_1": "Suivez les vues de page depuis votre site Web. Inclut le suivi automatique des liens sortants, le suivi des téléchargements de fichiers et les événements utilisant JavaScript, les classes CSS et les attributs HTML.",
            "code.title": "Code d'identification",
            "code.text": "Créez un nouveau code d'identification pour l'intégration JavaScript. Vous devez le remplacer sur votre site Web.",
            "code.link": "Lire la documentation",
            "code": "Code d'identification",
            "reset": "Réinitialiser le code d'identification",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "toast.clipboard": "Code d'identification copié dans le presse-papiers."
        },
        "nl": {
            "snippet.title": "Snippet",
            "snippet.text_0": "Kopieer de code snippet in het {0}-gedeelte van je website.",
            "snippet.link": "Lees de documentatie",
            "snippet.text_1": "Volg paginabezoeken vanaf je website. Inclusief automatische tracking van uitgaande links, bestanddownloads en gebeurtenissen met JavaScript, CSS-klassen en HTML-attributen.",
            "code.title": "Identificatiecode",
            "code.text": "Maak een nieuwe identificatiecode voor de JavaScript-integratie. Je moet deze vervangen op je website.",
            "code.link": "Lees de documentatie",
            "code": "Identificatiecode",
            "reset": "Identificatiecode resetten",
            "copy_to_clipboard": "Kopieer naar klembord",
            "toast.clipboard": "Identificatiecode naar klembord gekopieerd."
        },
        "it": {
            "snippet.title": "Snippet",
            "snippet.text_0": "Copia il frammento di codice nella sezione {0} del tuo sito web.",
            "snippet.link": "Leggi la documentazione",
            "snippet.text_1": "Traccia le visualizzazioni di pagina dal tuo sito web. Include il tracciamento automatico dei link in uscita, dei download di file e degli eventi utilizzando JavaScript, classi CSS e attributi HTML.",
            "code.title": "Codice di identificazione",
            "code.text": "Crea un nuovo codice di identificazione per l'integrazione JavaScript. Devi sostituirlo sul tuo sito web.",
            "code.link": "Leggi la documentazione",
            "code": "Codice di identificazione",
            "reset": "Reimposta codice di identificazione",
            "copy_to_clipboard": "Copia negli appunti",
            "toast.clipboard": "Codice di identificazione copiato negli appunti."
        },
        "pt": {
            "snippet.title": "Trecho de código",
            "snippet.text_0": "Copie o trecho de código na seção {0} do seu site.",
            "snippet.link": "Leia a documentação",
            "snippet.text_1": "Acompanhe as visualizações de página do seu site. Inclui rastreamento automático de links de saída, downloads de arquivos e eventos usando JavaScript, classes CSS e atributos HTML.",
            "code.title": "Código de identificação",
            "code.text": "Crie um novo código de identificação para a integração do JavaScript. Você deve substituí-lo em seu site.",
            "code.link": "Leia a documentação",
            "code": "Código de identificação",
            "reset": "Redefinir código de identificação",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "toast.clipboard": "Código de identificação copiado para a área de transferência."
        },
        "ja": {
            "snippet.title": "スニペット",
            "snippet.text_0": "コードスニペットをウェブサイトの{0}セクションにコピーします。",
            "snippet.link": "ドキュメントを読む",
            "snippet.text_1": "ウェブサイトからのページビューを追跡します。JavaScript、CSSクラス、およびHTML属性を使用して、外部リンクの自動追跡、ファイルのダウンロード追跡、およびイベントを含みます。",
            "code.title": "識別コード",
            "code.text": "JavaScript統合用の新しい識別コードを作成します。ウェブサイトに置き換える必要があります。",
            "code.link": "ドキュメントを読む",
            "code": "識別コード",
            "reset": "識別コードをリセット",
            "copy_to_clipboard": "クリップボードにコピー",
            "toast.clipboard": "識別コードがクリップボードにコピーされました。"
        }
    }
</i18n>
