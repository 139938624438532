<template>
    <SendEvents :open="sendEventsModal" v-on:close="sendEventsModal = false" />
    <SendTags :open="sendTagsModal" v-on:close="sendTagsModal = false" />
    <div class="list">
        <ListTabs :tabs="tabs" v-on:enlarge="enlarge" v-on:open="openTab">
            <template v-slot:icons>
                <i class="icon icon-add clickable"
                   :title="t('open.events')"
                   v-on:click="sendEventsModal = true"
                   v-if="loggedIn && domain.user_role && open === 0" />
                <i class="icon icon-add clickable"
                   :title="t('open.tags')"
                   v-on:click="sendTagsModal = true"
                   v-if="loggedIn && domain.user_role && open === 1" />
            </template>
        </ListTabs>
        <Events :visible="open === 0" v-show="open === 0" v-on:open="sendEventsModal = true" />
        <Tags :visible="open === 1" v-show="open === 1" v-on:open="sendTagsModal = true" />
        <EventPage :visible="open === 2" v-show="open === 2" />
        <EventsDetails :open="details === 0" v-on:close="details = -1" />
        <TagDetails :open="details === 1" v-on:close="details = -1" />
        <EventPageDetails :open="details === 2" v-on:close="details = -1" />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import Events from "@/components/statistics/Events.vue";
    import EventsDetails from "@/components/statistics/EventsDetails.vue";
    import Tags from "@/components/statistics/Tags.vue";
    import TagDetails from "@/components/statistics/TagDetails.vue";
    import SendEvents from "@/components/modal/SendEvents.vue";
    import SendTags from "@/components/modal/SendTags.vue";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";
    import {useFilterStore} from "@/store/FilterStore";
    import EventPageDetails from "@/components/statistics/EventPageDetails.vue";
    import EventPage from "@/components/statistics/EventPage.vue";

    export default defineComponent({
        components: {
            EventPage,
            EventPageDetails,
            SendTags,
            SendEvents,
            TagDetails,
            Tags,
            EventsDetails,
            Events,
            ListTabs
        },
        setup() {
            const {t} = useI18n();
            const open = ref(0);
            const details = ref(-1);
            const sendEventsModal = ref(false);
            const sendTagsModal = ref(false);
            const {hasField} = useFilterStore();
            const eventFilter = computed(() => hasField("event"));
            const tabs = computed(() => {
                const tabs = [
                    {
                        title: t("tabs.events"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.tags"),
                        showEnlarge: true
                    }
                ];

                if (eventFilter.value) {
                    tabs.push({
                        title: t("tabs.event_pages"),
                        showEnlarge: true
                    });
                }

                return tabs;
            });

            watch(eventFilter, () => {
                if (!eventFilter.value && open.value > 1) {
                    open.value = 0;
                }
            });

            function enlarge(index: number) {
                details.value = index;
            }

            function openTab(index: number) {
                open.value = index;
            }

            return {
                ...useI18n(),
                ...storeToRefs(useUserStore()),
                ...storeToRefs(useDomainStore()),
                tabs,
                open,
                details,
                sendEventsModal,
                sendTagsModal,
                enlarge,
                openTab
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "open.events": "Track Events",
            "open.tags": "Track Tags",
            "tabs.events": "Events",
            "tabs.tags": "Tags",
            "tabs.event_pages": "Event Pages"
        },
        "de": {
            "open.events": "Events verfolgen",
            "open.tags": "Tags verfolgen",
            "tabs.events": "Events",
            "tabs.tags": "Tags",
            "tabs.event_pages": "Eventseiten"
        },
        "es": {
            "open.events": "Rastrear Eventos",
            "open.tags": "Rastrear Etiquetas",
            "tabs.events": "Eventos",
            "tabs.tags": "Etiquetas",
            "tabs.event_pages": "Páginas de Eventos"
        },
        "fr": {
            "open.events": "Suivre les Événements",
            "open.tags": "Suivre les Tags",
            "tabs.events": "Événements",
            "tabs.tags": "Tags",
            "tabs.event_pages": "Pages d'Événements"
        },
        "nl": {
            "open.events": "Evenementen volgen",
            "open.tags": "Tags volgen",
            "tabs.events": "Evenementen",
            "tabs.tags": "Tags",
            "tabs.event_pages": "Evenementpagina's"
        },
        "it": {
            "open.events": "Traccia Eventi",
            "open.tags": "Traccia Tag",
            "tabs.events": "Eventi",
            "tabs.tags": "Tag",
            "tabs.event_pages": "Pagine di Eventi"
        },
        "pt": {
            "open.events": "Acompanhar Eventos",
            "open.tags": "Acompanhar Tags",
            "tabs.events": "Eventos",
            "tabs.tags": "Tags",
            "tabs.event_pages": "Páginas de Eventos"
        },
        "ja": {
            "open.events": "イベントを追跡",
            "open.tags": "タグを追跡",
            "tabs.events": "イベント",
            "tabs.tags": "タグ",
            "tabs.event_pages": "イベントページ"
        }
    }
</i18n>
