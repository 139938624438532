<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text_0", {name: theme.name})}}</p>
        <p>{{t("text_1")}}</p>
        <form v-on:submit.prevent="save">
            <FormCheckbox :label="t('form.settings')" name="keepSettings" v-model="keepSettings" />
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {ThemeRepo} from "@/repositories/ThemeRepo";
    import {Theme} from "@/model/Theme";
    import {useDomainStore} from "@/store/DomainStore";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormCheckbox,
            Modal,
            FormSubmit
        },
        props: {
            theme: {type: Object as PropType<Theme>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const domainStore = useDomainStore();
            const {setTheme} = useThemeStore();
            const loading = ref(false);
            const keepSettings = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await ThemeRepo.delete(props.theme.id, keepSettings.value);
                    loading.value = false;
                    keepSettings.value = false;
                    success(t("toast.success"));

                    if (domainStore.domain.theme_id === props.theme.id) {
                        await domainStore.load();
                        setTheme(domainStore.domain);
                    }

                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                keepSettings,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text_0": "Are you sure you want to delete the theme \"{name}\"?",
            "text_1": "You can automatically update all dashboards using this theme if you would like to keep the settings.",
            "form.settings": "Keep Settings",
            "form.submit": "Delete",
            "toast.success": "The theme has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text_0": "Bist du sicher, dass du das Thema \"{name}\" löschen möchtest?",
            "text_1": "Du kannst alle Dashboards, die dieses Thema verwenden, automatisch aktualisieren, wenn du die Einstellungen beibehalten möchtest.",
            "form.settings": "Einstellungen beibehalten",
            "form.submit": "Löschen",
            "toast.success": "Das Thema wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text_0": "¿Estás seguro de que deseas eliminar el tema \"{name}\"?",
            "text_1": "Puedes actualizar automáticamente todos los paneles que utilizan este tema si deseas conservar la configuración.",
            "form.settings": "Mantener configuración",
            "form.submit": "Eliminar",
            "toast.success": "El tema ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text_0": "Êtes-vous sûr de vouloir supprimer le thème \"{name}\" ?",
            "text_1": "Vous pouvez mettre à jour automatiquement tous les tableaux de bord utilisant ce thème si vous souhaitez conserver les paramètres.",
            "form.settings": "Conserver les paramètres",
            "form.submit": "Supprimer",
            "toast.success": "Le thème a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text_0": "Weet je zeker dat je het thema \"{name}\" wilt verwijderen?",
            "text_1": "Je kunt automatisch alle dashboards die dit thema gebruiken bijwerken als je de instellingen wilt behouden.",
            "form.settings": "Instellingen behouden",
            "form.submit": "Verwijderen",
            "toast.success": "Het thema is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text_0": "Sei sicuro di voler eliminare il tema \"{name}\"?",
            "text_1": "Puoi aggiornare automaticamente tutti i dashboard che utilizzano questo tema se desideri mantenere le impostazioni.",
            "form.settings": "Mantieni impostazioni",
            "form.submit": "Elimina",
            "toast.success": "Il tema è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text_0": "Tem certeza de que deseja excluir o tema \"{name}\"?",
            "text_1": "Você pode atualizar automaticamente todos os painéis que utilizam este tema se desejar manter as configurações.",
            "form.settings": "Manter configurações",
            "form.submit": "Excluir",
            "toast.success": "O tema foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text_0": "テーマ \"{name}\" を削除してもよろしいですか？",
            "text_1": "設定を保持する場合は、このテーマを使用しているすべてのダッシュボードを自動的に更新できます。",
            "form.settings": "設定を保持",
            "form.submit": "削除",
            "toast.success": "テーマが削除されました。"
        }
    }
</i18n>
