import copy from "copy-to-clipboard";
import {Snippet} from "@/model/Snippet";
import {useDomainStore} from "@/store/DomainStore";
import {storeToRefs} from "pinia";
import {useToastStore} from "@/store/ToastStore";

interface EventSnippetData {
    getSnippetString(snippet?: Snippet): string
    copySnippet(snippet: string): void
}

export function useSnippet(): EventSnippetData {
    const {domain} = storeToRefs(useDomainStore());
    const {success} = useToastStore();

    function getSnippetString(snippet?: Snippet) {
        const customDomain = domain.value.custom_domain;
        const useCustomDomain = !!customDomain;
        const endpointConfig = useCustomDomain ? `https://${customDomain}/hit` : import.meta.env.VITE_APP_PAGE_VIEW_ENDPOINT || "";
        const eventEndpointConfig = useCustomDomain ? `https://${customDomain}/event` : import.meta.env.VITE_APP_EVENT_ENDPOINT || "";
        const sessionEndpointConfig = useCustomDomain ? `https://${customDomain}/session` : import.meta.env.VITE_APP_SESSION_ENDPOINT || "";
        let script = `&lt;script defer src="${useCustomDomain ? `https://${customDomain}` : import.meta.env.VITE_APP_BACKEND_HOST}/pa.js"
    id="pianjs"
    data-code="${domain.value.identification_code}"`;

        if (snippet && (snippet.dev || snippet.rewrite)) {
            if (snippet.rewrite) {
                script += `\n    data-dev="${snippet.rewrite}"`;
            } else {
                script += "\n    data-dev";
            }
        }

        if (snippet && snippet.endpoint || endpointConfig) {
            script += `\n    data-hit-endpoint="${snippet && snippet.endpoint ? snippet.endpoint : endpointConfig}"`;
        }

        if (snippet && snippet.event_endpoint || eventEndpointConfig) {
            script += `\n    data-event-endpoint="${snippet && snippet.event_endpoint ? snippet.event_endpoint : eventEndpointConfig}"`;
        }

        if (snippet && snippet.session_endpoint || sessionEndpointConfig) {
            script += `\n    data-session-endpoint="${snippet && snippet.session_endpoint ? snippet.session_endpoint : sessionEndpointConfig}"`;
        }

        if (snippet) {
            if (snippet.path_prefix) {
                script += `\n    data-path-prefix="${snippet.path_prefix}"`;
            }

            if (snippet.disable_page_views) {
                script += "\n    data-disable-page-views";
            }

            if (snippet.disable_query_params) {
                script += "\n    data-disable-query";
            }

            if (snippet.disable_referrer) {
                script += "\n    data-disable-referrer";
            }

            if (snippet.disable_resolution) {
                script += "\n    data-disable-resolution";
            }

            if (snippet.disable_outbound_links) {
                script += "\n    data-disable-outbound-links";
            }

            if (snippet.disable_downloads) {
                script += "\n    data-disable-downloads";
            }

            if (snippet.enable_session) {
                script += "\n    data-enable-session";
            }

            if (snippet.outbound_link_event) {
                script += `\n    data-outbound-link-event-name="${snippet.outbound_link_event}"`;
            }

            if (snippet.download_event) {
                script += `\n    data-download-event-name="${snippet.download_event}"`;
            }

            if (snippet.not_found_event) {
                script += `\n    data-not-found-event-name="${snippet.not_found_event}"`;
            }

            if (snippet.include) {
                script += `\n    data-include="${snippet.include}"`;
            }

            if (snippet.exclude) {
                script += `\n    data-exclude="${snippet.exclude}"`;
            }

            if (snippet.domains) {
                script += `\n    data-domain="${snippet.domains}"`;
            }
        }

        return script + "&gt;&lt;/script&gt;";
    }

    function copySnippet(snippet: string) {
        copy(snippet.replaceAll("&gt;", ">").replaceAll("&lt;", "<"));
        success("Copied snippet to clipboard.");
    }

    return {
        getSnippetString,
        copySnippet
    };
}
