<template>
    <div class="list">
        <ListTabs :tabs="tabs" v-on:enlarge="enlarge" v-on:open="openTab" />
        <Page :visible="open === 0" v-show="open === 0" />
        <EntryPage :visible="open === 1" v-show="open === 1" />
        <ExitPage :visible="open === 2" v-show="open === 2" />
        <Hostname :visible="open === 3" v-show="open === 3" />
        <PageDetails :open="details === 0" v-on:close="details = -1" />
        <EntryPageDetails :open="details === 1" v-on:close="details = -1" />
        <ExitPageDetails :open="details === 2" v-on:close="details = -1" />
        <HostnameDetails :open="details === 3" v-on:close="details = -1" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import Page from "@/components/statistics/Page.vue";
    import EntryPage from "@/components/statistics/EntryPage.vue";
    import ExitPage from "@/components/statistics/ExitPage.vue";
    import PageDetails from "@/components/statistics/PageDetails.vue";
    import EntryPageDetails from "@/components/statistics/EntryPageDetails.vue";
    import ExitPageDetails from "@/components/statistics/ExitPageDetails.vue";
    import {useI18n} from "vue-i18n";
    import HostnameDetails from "@/components/statistics/HostnameDetails.vue";
    import Hostname from "@/components/statistics/Hostname.vue";

    export default defineComponent({
        components: {
            ListTabs,
            Page,
            PageDetails,
            EntryPage,
            EntryPageDetails,
            ExitPage,
            ExitPageDetails,
            Hostname,
            HostnameDetails
        },
        setup() {
            const {t} = useI18n();
            const open = ref(0);
            const details = ref(-1);

            function enlarge(index: number) {
                details.value = index;
            }

            function openTab(index: number) {
                open.value = index;
            }

            return {
                tabs: [
                    {
                        title: t("tabs.pages"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.entry_pages"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.exit_pages"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.hostname"),
                        showEnlarge: true
                    }
                ],
                open,
                details,
                enlarge,
                openTab
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "tabs.pages": "Pages",
            "tabs.entry_pages": "Entry Pages",
            "tabs.exit_pages": "Exit Pages",
            "tabs.hostname": "Hostname"
        },
        "de": {
            "tabs.pages": "Seiten",
            "tabs.entry_pages": "Einstiegsseiten",
            "tabs.exit_pages": "Ausstiegsseiten",
            "tabs.hostname": "Hostname"
        },
        "es": {
            "tabs.pages": "Páginas",
            "tabs.entry_pages": "Páginas de Entrada",
            "tabs.exit_pages": "Páginas de Salida",
            "tabs.hostname": "Nombre de host"
        },
        "fr": {
            "tabs.pages": "Pages",
            "tabs.entry_pages": "Pages d'Entrée",
            "tabs.exit_pages": "Pages de Sortie",
            "tabs.hostname": "Nom d'hôte"
        },
        "nl": {
            "tabs.pages": "Pagina's",
            "tabs.entry_pages": "Ingangspagina's",
            "tabs.exit_pages": "Uitgangspagina's",
            "tabs.hostname": "Hostnaam"
        },
        "it": {
            "tabs.pages": "Pagine",
            "tabs.entry_pages": "Pagine di Ingresso",
            "tabs.exit_pages": "Pagine di Uscita",
            "tabs.hostname": "Nome host"
        },
        "pt": {
            "tabs.pages": "Páginas",
            "tabs.entry_pages": "Páginas de Entrada",
            "tabs.exit_pages": "Páginas de Saída",
            "tabs.hostname": "Nome do anfitrião"
        },
        "ja": {
            "tabs.pages": "ページ",
            "tabs.entry_pages": "エントリーページ",
            "tabs.exit_pages": "退出ページ",
            "tabs.hostname": "ホスト名"
        }
    }
</i18n>
