<template>
    <Modal :title="t('title')" v-on:close="$emit('close')">
        <form v-on:submit.prevent="action">
            <FormInput :label="t('form.start')" name="start" type="date" v-model="startDate" />
            <FormInput :label="t('form.end')" name="end" type="date" v-model="endDate" />
            <FormCheckbox :label="t('form.hostname')" name="hostname" v-model="hostname" autofocus />
            <FormCheckbox :label="t('form.visitors')" name="visitors" v-model="visitors" />
            <FormCheckbox :label="t('form.pages')" name="pages" v-model="pages" />
            <FormCheckbox :label="t('form.sessions')" name="sessions" v-model="sessions" />
            <FormCheckbox :label="t('form.growth')" name="growth" v-model="growth" />
            <FormCheckbox :label="t('form.languages')" name="languages" v-model="languages" />
            <FormCheckbox :label="t('form.referrer')" name="referrer" v-model="referrer" />
            <FormCheckbox :label="t('form.os')" name="os" v-model="os" />
            <FormCheckbox :label="t('form.browser')" name="browser" v-model="browser" />
            <FormCheckbox :label="t('form.country')" name="country" v-model="country" />
            <FormCheckbox :label="t('form.region')" name="region" v-model="region" />
            <FormCheckbox :label="t('form.city')" name="city" v-model="city" />
            <FormCheckbox :label="t('form.platform')" name="platform" v-model="platform" />
            <FormCheckbox :label="t('form.screen')" name="screen" v-model="screen" />
            <FormCheckbox :label="t('form.utm')" name="utm" v-model="utm" />
            <FormCheckbox :label="t('form.goals')" name="page_goals" v-model="page_goals" />
            <FormCheckbox :label="t('form.events')" name="events" v-model="events" />
            <FormCheckbox :label="t('form.tags')" name="tags" v-model="tags" />
            <FormSubmit :value="t('form.submit')" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {dateParam} from "@/util/format";
    import dayjs from "dayjs";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput,
            FormCheckbox,
            FormSubmit
        },
        props: {
            link: Object
        },
        emits: ["close"],
        setup() {
            const {domain} = storeToRefs(useDomainStore());
            const startDate = ref(dateParam(dayjs().subtract(7, "day").toDate()));
            const endDate = ref(dateParam(new Date));
            const hostname = ref(true);
            const visitors = ref(true);
            const pages = ref(true);
            const sessions = ref(true);
            const growth = ref(true);
            const languages = ref(true);
            const referrer = ref(true);
            const os = ref(true);
            const browser = ref(true);
            const country = ref(true);
            const region = ref(true);
            const city = ref(true);
            const platform = ref(true);
            const screen = ref(true);
            const utm = ref(true);
            const page_goals = ref(true);
            const events = ref(true);
            const tags = ref(true);

            function action() {
                let url = `${import.meta.env.VITE_APP_BACKEND_HOST}/api/v1/statistics`;
                url += `?id=${domain.value.id}`;
                url += `&from=${startDate.value}`;
                url += `&to=${endDate.value}`;
                url += `&hostname=${hostname.value.toString()}`;
                url += `&visitors=${visitors.value.toString()}`;
                url += `&pages=${pages.value.toString()}`;
                url += `&session_duration=${sessions.value.toString()}`;
                url += `&growth=${growth.value.toString()}`;
                url += `&languages=${languages.value.toString()}`;
                url += `&referrer=${referrer.value.toString()}`;
                url += `&os=${os.value.toString()}`;
                url += `&browser=${browser.value.toString()}`;
                url += `&country=${country.value.toString()}`;
                url += `&region=${region.value.toString()}`;
                url += `&city=${city.value.toString()}`;
                url += `&platform=${platform.value.toString()}`;
                url += `&screen=${screen.value.toString()}`;
                url += `&utm=${utm.value.toString()}`;
                url += `&conversion_goals=${page_goals.value.toString()}`;
                url += `&events=${events.value.toString()}`;
                url += `&tags=${tags.value.toString()}`;
                window.open(url);
            }

            return {
                ...useI18n(),
                startDate,
                endDate,
                hostname,
                visitors,
                pages,
                sessions,
                growth,
                languages,
                referrer,
                os,
                browser,
                country,
                region,
                city,
                platform,
                screen,
                utm,
                page_goals,
                events,
                tags,
                action
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Export Data to CSV",
            "form.start": "Start Date",
            "form.end": "End Date",
            "form.hostname": "Hostname",
            "form.visitors": "Visitors",
            "form.pages": "Pages",
            "form.sessions": "Session Duration",
            "form.growth": "Growth",
            "form.languages": "Languages",
            "form.referrer": "Referrer",
            "form.os": "OS",
            "form.browser": "Browser",
            "form.country": "Country",
            "form.region": "Region",
            "form.city": "City",
            "form.platform": "Platform",
            "form.screen": "Screen Sizes",
            "form.utm": "UTM parameters",
            "form.goals": "Conversion Goals",
            "form.events": "Events",
            "form.tags": "Tags",
            "form.submit": "Create CSV File"
        },
        "de": {
            "title": "Daten in CSV exportieren",
            "form.start": "Startdatum",
            "form.end": "Enddatum",
            "form.hostname": "Hostname",
            "form.visitors": "Besucher",
            "form.pages": "Seiten",
            "form.sessions": "Sitzungsdauer",
            "form.growth": "Wachstum",
            "form.languages": "Sprachen",
            "form.referrer": "Referrer",
            "form.os": "Betriebssystem",
            "form.browser": "Browser",
            "form.country": "Land",
            "form.region": "Region",
            "form.city": "Stadt",
            "form.platform": "Plattform",
            "form.screen": "Bildschirmgrößen",
            "form.utm": "UTM-Parameter",
            "form.goals": "Konversionsziele",
            "form.events": "Events",
            "form.tags": "Tags",
            "form.submit": "CSV-Datei erstellen"
        },
        "es": {
            "title": "Exportar datos a CSV",
            "form.start": "Fecha de inicio",
            "form.end": "Fecha de finalización",
            "form.hostname": "Nombre de host",
            "form.visitors": "Visitantes",
            "form.pages": "Páginas",
            "form.sessions": "Duración de la sesión",
            "form.growth": "Crecimiento",
            "form.languages": "Idiomas",
            "form.referrer": "Remitente",
            "form.os": "Sistema operativo",
            "form.browser": "Navegador",
            "form.country": "País",
            "form.region": "Región",
            "form.city": "Ciudad",
            "form.platform": "Plataforma",
            "form.screen": "Tamaños de pantalla",
            "form.utm": "Parámetros UTM",
            "form.goals": "Objetivos de conversión",
            "form.events": "Eventos",
            "form.tags": "Etiquetas",
            "form.submit": "Crear archivo CSV"
        },
        "fr": {
            "title": "Exporter les données en CSV",
            "form.start": "Date de début",
            "form.end": "Date de fin",
            "form.hostname": "Nom d'hôte",
            "form.visitors": "Visiteurs",
            "form.pages": "Pages",
            "form.sessions": "Durée de la session",
            "form.growth": "Croissance",
            "form.languages": "Langues",
            "form.referrer": "Référent",
            "form.os": "Système d'exploitation",
            "form.browser": "Navigateur",
            "form.country": "Pays",
            "form.region": "Région",
            "form.city": "Ville",
            "form.platform": "Plateforme",
            "form.screen": "Tailles d'écran",
            "form.utm": "Paramètres UTM",
            "form.goals": "Objectifs de conversion",
            "form.events": "Événements",
            "form.tags": "Tags",
            "form.submit": "Créer un fichier CSV"
        },
        "nl": {
            "title": "Gegevens exporteren naar CSV",
            "form.start": "Begindatum",
            "form.end": "Einddatum",
            "form.hostname": "Hostnaam",
            "form.visitors": "Bezoekers",
            "form.pages": "Pagina's",
            "form.sessions": "Sessie duur",
            "form.growth": "Groei",
            "form.languages": "Talen",
            "form.referrer": "Verwijzer",
            "form.os": "Besturingssysteem",
            "form.browser": "Browser",
            "form.country": "Land",
            "form.region": "Regio",
            "form.city": "Stad",
            "form.platform": "Platform",
            "form.screen": "Schermformaten",
            "form.utm": "UTM-parameters",
            "form.goals": "Conversiedoelen",
            "form.events": "Gebeurtenissen",
            "form.tags": "Tags",
            "form.submit": "Maak CSV-bestand"
        },
        "it": {
            "title": "Esporta dati in CSV",
            "form.start": "Data di inizio",
            "form.end": "Data di fine",
            "form.hostname": "Nome host",
            "form.visitors": "Visitatori",
            "form.pages": "Pagine",
            "form.sessions": "Durata della sessione",
            "form.growth": "Crescita",
            "form.languages": "Lingue",
            "form.referrer": "Referrer",
            "form.os": "Sistema operativo",
            "form.browser": "Browser",
            "form.country": "Paese",
            "form.region": "Regione",
            "form.city": "Città",
            "form.platform": "Piattaforma",
            "form.screen": "Dimensioni dello schermo",
            "form.utm": "Parametri UTM",
            "form.goals": "Obiettivi di conversione",
            "form.events": "Eventi",
            "form.tags": "Tag",
            "form.submit": "Crea file CSV"
        },
        "pt": {
            "title": "Exportar dados para CSV",
            "form.start": "Data de início",
            "form.end": "Data de término",
            "form.hostname": "Nome do anfitrião",
            "form.visitors": "Visitantes",
            "form.pages": "Páginas",
            "form.sessions": "Duração da sessão",
            "form.growth": "Crescimento",
            "form.languages": "Idiomas",
            "form.referrer": "Referenciador",
            "form.os": "Sistema operacional",
            "form.browser": "Navegador",
            "form.country": "País",
            "form.region": "Região",
            "form.city": "Cidade",
            "form.platform": "Plataforma",
            "form.screen": "Tamanhos de tela",
            "form.utm": "Parâmetros UTM",
            "form.goals": "Metas de conversão",
            "form.events": "Eventos",
            "form.tags": "Etiquetas",
            "form.submit": "Criar arquivo CSV"
        },
        "ja": {
            "title": "データをCSVにエクスポート",
            "form.start": "開始日",
            "form.end": "終了日",
            "form.hostname": "ホスト名",
            "form.visitors": "訪問者",
            "form.pages": "ページ",
            "form.sessions": "セッションの期間",
            "form.growth": "成長",
            "form.languages": "言語",
            "form.referrer": "リファラー",
            "form.os": "OS",
            "form.browser": "ブラウザー",
            "form.country": "国",
            "form.region": "地域",
            "form.city": "都市",
            "form.platform": "プラットフォーム",
            "form.screen": "画面サイズ",
            "form.utm": "UTMパラメータ",
            "form.goals": "コンバージョン目標",
            "form.events": "イベント",
            "form.tags": "タグ",
            "form.submit": "CSVファイルを作成"
        }
    }
</i18n>
