<template>
    <template v-if="logo">
        <img :src="logo" alt="Logo" class="theme-logo" v-on:click="$router.push('/')" />
    </template>
    <template v-else-if="loaded">
        <div class="logo cursor-pointer"
            title="Pirsch"
            v-html="defaultLogo"
            v-on:click="home"
            v-if="loggedIn"></div>
        <div :class="{logo: true, 'cursor-pointer': !hideUI}"
            title="Pirsch"
            v-html="defaultLogo"
            v-on:click="affiliate"
            v-else></div>
    </template>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {isCustomDomain} from "@/util/domain";
    import {useRouter} from "vue-router";
    import {useThemeStore} from "@/store/ThemeStore";
    import {getQueryParam} from "@/util/url";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        setup() {
            const router = useRouter();
            const {active, domain} = storeToRefs(useDomainStore());
            const {loggedIn} = storeToRefs(useUserStore());
            const {theme, darkMode, loaded} = storeToRefs(useThemeStore());
            const affiliateLink = computed(() => {
                if (import.meta.env.VITE_APP_AFFILIATE && active.value) {
                    return `${import.meta.env.VITE_APP_WEBSITE_HOST}/ref/${domain.value.user_id}`;
                }

                return import.meta.env.VITE_APP_WEBSITE_HOST;
            });
            const logo = computed(() => {
                const settings = theme.value ?? {};

                if (settings[ThemeSettings.logoDark] && darkMode.value) {
                    return `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${settings[ThemeSettings.logoDark]}`;
                } else if (settings[ThemeSettings.logoLight]) {
                    return `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${settings[ThemeSettings.logoLight]}`;
                }

                return undefined;
            });
            const hideUI = getQueryParam("ui").toLowerCase() === "hide";

            function home() {
                router.push(`/${hideUI ? "?ui=hide" : ""}`);
            }

            function affiliate() {
                if (!hideUI) {
                    if (isCustomDomain()) {
                        home();
                    } else {
                        location.href = affiliateLink.value;
                    }
                }
            }

            return {
                defaultLogo: import.meta.env.VITE_APP_LOGO,
                loaded,
                loggedIn,
                affiliateLink,
                logo,
                hideUI,
                home,
                affiliate
            };
        }
    });
</script>
