import {APIError} from "@/repositories/APIError";
import {Repository} from "@/repositories/Repository";
import {Webhook} from "@/model/Webhook";

export interface WebhookData {
    id: string
    domain_id: string
    description: string
    event: string
    endpoint: string
}

export const WebhookRepo = new class extends Repository {
    async list(domain_id: string): Promise<Webhook[] | APIError> {
        return this.performGet<Webhook[]>("/webhook", {domain_id}, []);
    }

    async save(view: WebhookData): Promise<Webhook | APIError> {
        return this.performPost<Webhook>("/webhook", {...view});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/webhook", {id});
    }
}
