import {nextTick, onMounted, onUnmounted, ref, Ref, watch} from "vue";
import {scrollIntoView} from "@/util/scroll";

interface SelectData {
    active: Ref<number>
    scrollArea: Ref<HTMLElement>
}

export function useSelect(dropdown: Ref<boolean>, options: Ref<object[]>, select: (active: number) => void, tag?: string): SelectData {
    if (!tag) {
        tag = "ul";
    }

    const active = ref(0);
    const scrollArea = ref(document.createElement(tag));

    onMounted(() => {
        document.addEventListener("keydown", keyboardEvents);
    });

    onUnmounted(() => {
        document.removeEventListener("keydown", keyboardEvents);
    });

    watch(active, async () => {
        await nextTick(() => {
            const entry = scrollArea.value.querySelector("[class~='selected']");

            if (entry) {
                scrollIntoView(scrollArea.value, entry, 6);
            }
        });
    });

    function keyboardEvents(e: KeyboardEvent) {
        if (dropdown.value && (!e.target || (e.target as HTMLElement).tagName !== "INPUT")) {
            if (e.key === "ArrowUp") {
                e.stopPropagation();
                e.preventDefault();
                active.value--;

                if (active.value < 0) {
                    active.value = options.value.length - 1;
                }
            } else if (e.key === "ArrowDown") {
                e.stopPropagation();
                e.preventDefault();
                active.value++;

                if (active.value > options.value.length - 1) {
                    active.value = 0;
                }
            } else if (e.key === "Enter") {
                e.stopPropagation();
                e.preventDefault();
                select(active.value);
            } else if (e.key === "Escape") {
                e.stopPropagation();
                e.preventDefault();
                dropdown.value = false;
            }
        }
    }

    return {
        active,
        scrollArea
    };
}
