<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text_0")}}
                <br /><br />
                <strong>{{t("text_1")}}</strong>
            </small>
        </div>
        <div>
            <div class="card">
                <div class="text">
                    <strong>{{t("active")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="active" :label="t('private')" toggle v-model="settings.active" v-on:change="updateSettings" />
                    <FormCheckbox name="active_public" :label="t('public')" toggle v-model="settings.active_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("funnels")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="funnels" :label="t('private')" toggle v-model="settings.funnels" v-on:change="updateSettings" />
                    <FormCheckbox name="funnels_public" :label="t('public')" toggle v-model="settings.funnels_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("sessions")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="sessions" :label="t('private')" toggle v-model="settings.sessions" v-on:change="updateSettings" />
                    <FormCheckbox name="sessions_public" :label="t('public')" toggle v-model="settings.sessions_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("graphs")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="graphs" :label="t('private')" toggle v-model="settings.graphs" v-on:change="updateSettings" />
                    <FormCheckbox name="graphs_public" :label="t('public')" toggle v-model="settings.graphs_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("pages")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="pages" :label="t('private')" toggle v-model="settings.pages" v-on:change="updateSettings" />
                    <FormCheckbox name="pages_public" :label="t('public')" toggle v-model="settings.pages_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("sources")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="sources" :label="t('private')" toggle v-model="settings.sources" v-on:change="updateSettings" />
                    <FormCheckbox name="sources_public" :label="t('public')" toggle v-model="settings.sources_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("goals")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="goals" :label="t('private')" toggle v-model="settings.goals" v-on:change="updateSettings" />
                    <FormCheckbox name="goals_public" :label="t('public')" toggle v-model="settings.goals_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("events")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="events" :label="t('private')" toggle v-model="settings.events" v-on:change="updateSettings" />
                    <FormCheckbox name="events_public" :label="t('public')" toggle v-model="settings.events_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("location")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="location" :label="t('private')" toggle v-model="settings.location" v-on:change="updateSettings" />
                    <FormCheckbox name="location_public" :label="t('public')" toggle v-model="settings.location_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("devices")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="devices" :label="t('private')" toggle v-model="settings.devices" v-on:change="updateSettings" />
                    <FormCheckbox name="devices_public" :label="t('public')" toggle v-model="settings.devices_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("weekdays")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="weekdays" :label="t('private')" toggle v-model="settings.weekdays" v-on:change="updateSettings" />
                    <FormCheckbox name="weekdays_public" :label="t('public')" toggle v-model="settings.weekdays_public" v-on:change="updateSettings" />
                </div>
            </div>
            <div class="card">
                <div class="text">
                    <strong>{{t("keywords")}}</strong>
                </div>
                <div class="row">
                    <FormCheckbox name="keywords" :label="t('private')" toggle v-model="settings.keywords" v-on:change="updateSettings" />
                    <FormCheckbox name="keywords_public" :label="t('public')" toggle v-model="settings.keywords_public" v-on:change="updateSettings" />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {DashboardSettings} from "@/model/DashboardSettings";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {KeyValue} from "@/model/KeyValue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {FormCheckbox},
        setup() {
            const {t} = useI18n();
            const domainStore = useDomainStore();
            const {success} = useToastStore();
            const {domain} = storeToRefs(domainStore);
            const {getSettings} = domainStore;
            const settings = ref({});

            watchEffect(() => {
                if (domain.value) {
                    settings.value = {
                        active: getSettings("active"),
                        active_public: getSettings("active_public"),
                        funnels: getSettings("funnels"),
                        funnels_public: getSettings("funnels_public"),
                        sessions: getSettings("sessions"),
                        sessions_public: getSettings("sessions_public"),
                        graphs: getSettings("graphs"),
                        graphs_public: getSettings("graphs_public"),
                        pages: getSettings("pages"),
                        pages_public: getSettings("pages_public"),
                        sources: getSettings("sources"),
                        sources_public: getSettings("sources_public"),
                        goals: getSettings("goals"),
                        goals_public: getSettings("goals_public"),
                        events: getSettings("events"),
                        events_public: getSettings("events_public"),
                        location: getSettings("location"),
                        location_public: getSettings("location_public"),
                        devices: getSettings("devices"),
                        devices_public: getSettings("devices_public"),
                        weekdays: getSettings("weekdays"),
                        weekdays_public: getSettings("weekdays_public"),
                        keywords: getSettings("keywords"),
                        keywords_public: getSettings("keywords_public")
                    };
                }
            });

            async function updateSettings() {
                if (domain.value) {
                    const kv: KeyValue = {};

                    for (const [key, value] of Object.entries(settings.value)) {
                        kv[key] = (value as boolean).toString();
                    }

                    const s = await DomainRepo.updateSettings(domain.value.id, kv) as DashboardSettings;
                    domain.value.settings = s.settings;
                    success(t("toast.success"));
                }
            }

            return {
                t,
                settings,
                updateSettings
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Customization",
            "text_0": "Set whether panels are displayed privately or publicly. Public only applies if you make your dashboard public. Users with an account or access link will see all private panels.",
            "text_1": "Note that this is not a security feature! The panels won't be visible on the dashboard, but the data can still be accessed using the API.",
            "private": "Private",
            "public": "Public",
            "active": "Active Visitors",
            "funnels": "Funnels",
            "sessions": "Sessions",
            "graphs": "Graphs",
            "pages": "Pages",
            "sources": "Sources",
            "goals": "Conversion Goals",
            "events": "Events",
            "location": "Location",
            "devices": "Devices",
            "weekdays": "Weekdays",
            "keywords": "Keywords",
            "toast.success": "Settings saved."
        },
        "de": {
            "title": "Anpassungen",
            "text_0": "Lege fest, ob Panels privat oder öffentlich angezeigt werden. Öffentlich gilt nur, wenn dein Dashboard öffentlich machen. Benutzer mit einem Konto oder Zugriffslink sehen alle privaten Panels.",
            "text_1": "Beachte, dass dies keine Sicherheitsfunktion ist! Die Panels sind auf dem Dashboard nicht sichtbar, aber die Daten können weiterhin über die API abgerufen werden.",
            "private": "Privat",
            "public": "Öffentlich",
            "active": "Aktive Besucher",
            "funnels": "Funnel",
            "sessions": "Sitzungen",
            "graphs": "Graphen",
            "pages": "Seiten",
            "sources": "Quellen",
            "goals": "Konversionsziele",
            "events": "Events",
            "location": "Standort",
            "devices": "Geräte",
            "weekdays": "Wochentage",
            "keywords": "Keywords",
            "toast.success": "Einstellungen gespeichert."
        },
        "es": {
            "title": "Personalización",
            "text_0": "Configura si los paneles se muestran de forma privada o pública. Público solo se aplica si haces público tu panel. Los usuarios con una cuenta o enlace de acceso verán todos los paneles privados.",
            "text_1": "¡Ten en cuenta que esto no es una función de seguridad! Los paneles no serán visibles en el panel, pero los datos aún se pueden acceder utilizando la API.",
            "private": "Privado",
            "public": "Público",
            "active": "Visitantes Activos",
            "funnels": "Embudos",
            "sessions": "Sesiones",
            "graphs": "Gráficos",
            "pages": "Páginas",
            "sources": "Fuentes",
            "goals": "Objetivos de conversión",
            "events": "Eventos",
            "location": "Ubicación",
            "devices": "Dispositivos",
            "weekdays": "Días laborables",
            "keywords": "Palabras clave",
            "toast.success": "Configuraciones guardadas."
        },
        "fr": {
            "title": "Personnalisation",
            "text_0": "Définissez si les panneaux sont affichés en privé ou en public. Public ne s'applique que si vous rendez votre tableau de bord public. Les utilisateurs avec un compte ou un lien d'accès verront tous les panneaux privés.",
            "text_1": "Notez que ce n'est pas une fonctionnalité de sécurité ! Les panneaux ne seront pas visibles sur le tableau de bord, mais les données peuvent toujours être consultées via l'API.",
            "private": "Privé",
            "public": "Public",
            "active": "Visiteurs actifs",
            "funnels": "Entonnoirs",
            "sessions": "Sessions",
            "graphs": "Graphiques",
            "pages": "Pages",
            "sources": "Sources",
            "goals": "Objectifs de conversion",
            "events": "Événements",
            "location": "Localisation",
            "devices": "Appareils",
            "weekdays": "En semaine",
            "keywords": "Mots clés",
            "toast.success": "Paramètres enregistrés."
        },
        "nl": {
            "title": "Aanpassing",
            "text_0": "Stel in of panelen privé of openbaar worden weergegeven. Openbaar is alleen van toepassing als je je dashboard openbaar maakt. Gebruikers met een account of toegangskoppeling zien alle privépanelen.",
            "text_1": "Houd er rekening mee dat dit geen beveiligingsfunctie is! De panelen zijn niet zichtbaar op het dashboard, maar de gegevens kunnen nog steeds worden benaderd met de API.",
            "private": "Privé",
            "public": "Openbaar",
            "active": "Actieve bezoekers",
            "funnels": "Trechters",
            "sessions": "Sessies",
            "graphs": "Grafieken",
            "pages": "Pagina's",
            "sources": "Bronnen",
            "goals": "Conversiedoelen",
            "events": "Evenementen",
            "location": "Locatie",
            "devices": "Apparaten",
            "weekdays": "Weekdagen",
            "keywords": "Trefwoorden",
            "toast.success": "Instellingen opgeslagen."
        },
        "it": {
            "title": "Personalizzazione",
            "text_0": "Imposta se i pannelli vengono visualizzati privatamente o pubblicamente. Pubblico si applica solo se rendi pubblica la tua dashboard. Gli utenti con un account o un link di accesso vedranno tutti i pannelli privati.",
            "text_1": "Nota che questa non è una funzione di sicurezza! I pannelli non saranno visibili sulla dashboard, ma i dati possono ancora essere accessibili utilizzando l'API.",
            "private": "Privato",
            "public": "Pubblico",
            "active": "Visitatori attivi",
            "funnels": "Imbuti",
            "sessions": "Sessioni",
            "graphs": "Grafici",
            "pages": "Pagine",
            "sources": "Fonti",
            "goals": "Obiettivi di conversione",
            "events": "Eventi",
            "location": "Posizione",
            "devices": "Dispositivi",
            "weekdays": "Giorni feriali",
            "keywords": "Parole chiave",
            "toast.success": "Impostazioni salvate."
        },
        "pt": {
            "title": "Personalização",
            "text_0": "Defina se os painéis são exibidos de forma privada ou pública. Público só se aplica se você tornar seu painel público. Usuários com uma conta ou link de acesso verão todos os painéis privados.",
            "text_1": "Observe que isso não é um recurso de segurança! Os painéis não serão visíveis no painel, mas os dados ainda podem ser acessados usando a API.",
            "private": "Privado",
            "public": "Público",
            "active": "Visitantes ativos",
            "funnels": "Funis",
            "sessions": "Sessões",
            "graphs": "Gráficos",
            "pages": "Páginas",
            "sources": "Fontes",
            "goals": "Metas de conversão",
            "events": "Eventos",
            "location": "Localização",
            "devices": "Dispositivos",
            "weekdays": "Dias úteis",
            "keywords": "Palavras-chave",
            "toast.success": "Configurações salvas."
        },
        "ja": {
            "title": "カスタマイズ",
            "text_0": "パネルがプライベートまたはパブリックに表示されるかどうかを設定します。パブリックはダッシュボードを公開する場合にのみ適用されます。アカウントを持っているユーザーやアクセスリンクを持っているユーザーは、すべてのプライベートパネルを見ることができます。",
            "text_1": "これはセキュリティ機能ではないことに注意してください！パネルはダッシュボードには表示されませんが、データはAPIを使用して引き続きアクセスできます。",
            "private": "プライベート",
            "public": "パブリック",
            "active": "アクティブな訪問者",
            "funnels": "ファンネル",
            "sessions": "セッション",
            "graphs": "グラフ",
            "pages": "ページ",
            "sources": "ソース",
            "goals": "コンバージョンゴール",
            "events": "イベント",
            "location": "位置",
            "devices": "デバイス",
            "weekdays": "平日",
            "keywords": "キーワード",
            "toast.success": "設定が保存されました。"
        }
    }
</i18n>
