<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text_0")}}</p>
        <p class="warning">{{t("text_1")}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.name')" name="name" v-model="organizationName" disabled />
            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyClipboard(organization.name)"></i>
        </div>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" :autofocus="true" autocomplete="off" v-model="name" :err="validationError('name')" />
            <FormInput :label="t('form.delete')" :hint="t('form.delete.hint')" name="deleteDomains" autocomplete="off" v-model="deleteDomains" :err="validationError('domains')" />
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {Organization} from "@/model/Organization";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import FormInput from "@/components/form/FormInput.vue";
    import copy from "copy-to-clipboard";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormInput,
            Modal,
            FormSubmit
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const domainStore = useDomainStore();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);
            const name = ref("");
            const deleteDomains = ref("");
            const organizationName = computed(() => props.organization.name);

            async function save() {
                try {
                    if (props.organization) {
                        resetError();
                        let includeDomains = false;

                        if (name.value !== props.organization.name) {
                            setError({
                                validation: {
                                    name: t("error.name")
                                },
                                error: []
                            });
                            return;
                        }

                        if (deleteDomains.value !== "" && deleteDomains.value !== "delete") {
                            setError({
                                validation: {
                                    domains: t("error.check")
                                },
                                error: []
                            });
                            return;
                        } else if (deleteDomains.value === "delete") {
                            includeDomains = true;
                        }

                        loading.value = true;
                        await OrganizationRepo.delete(props.organization.id, includeDomains);
                        name.value = "";
                        deleteDomains.value = "";
                        success(t("toast.success"));
                        loading.value = false;
                        await domainStore.load();
                        emit("action");
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            function copyClipboard(value: string) {
                copy(value);
                success(t("toast.clipboard"));
            }

            return {
                t,
                validationError,
                err,
                loading,
                name,
                deleteDomains,
                organizationName,
                save,
                copyClipboard
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text_0": "Please enter the name of your organization to confirm the action.",
            "text_1": "WARNING: Deleting organizations and websites is permanent!",
            "form.name": "Name",
            "form.delete": "Delete Websites",
            "form.delete.hint": "Type 'delete' to delete all websites belonging to this organization.",
            "form.submit": "Delete Organization",
            "copy_to_clipboard": "Copy to Clipboard",
            "toast.success": "The organization has been deleted.",
            "toast.clipboard": "Copied to clipboard.",
            "error.name": "The name is not correct.",
            "error.check": "Type 'delete' to delete all websites belonging to this organization."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text_0": "Bitte gib den Namen deiner Organisation ein, um die Aktion zu bestätigen.",
            "text_1": "WARNUNG: Das Löschen von Organisationen und Webseiten ist dauerhaft!",
            "form.name": "Name",
            "form.delete": "Webseiten löschen",
            "form.delete.hint": "Gib 'delete' ein, um alle Webseiten dieser Organisation zu löschen.",
            "form.submit": "Organisation löschen",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "toast.success": "Die Organisation wurde gelöscht.",
            "toast.clipboard": "In die Zwischenablage kopiert.",
            "error.name": "Der Name ist nicht korrekt.",
            "error.check": "Gib 'löschen' ein, um alle Webseiten dieser Organisation zu löschen."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text_0": "Por favor, introduce el nombre de tu organización para confirmar la acción.",
            "text_1": "ADVERTENCIA: ¡Eliminar organizaciones y sitios web es permanente!",
            "form.name": "Nombre",
            "form.delete": "Eliminar sitios web",
            "form.delete.hint": "Escribe 'delete' para eliminar todos los sitios web pertenecientes a esta organización.",
            "form.submit": "Eliminar organización",
            "copy_to_clipboard": "Copiar al portapapeles",
            "toast.success": "La organización ha sido eliminada.",
            "toast.clipboard": "Copiado al portapapeles.",
            "error.name": "El nombre no es correcto.",
            "error.check": "Escribe 'eliminar' para eliminar todos los sitios web pertenecientes a esta organización."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text_0": "Veuillez entrer le nom de votre organisation pour confirmer l'action.",
            "text_1": "ATTENTION : La suppression des organisations et des sites web est permanente !",
            "form.name": "Nom",
            "form.delete": "Supprimer les sites web",
            "form.delete.hint": "Tapez 'delete' pour supprimer tous les sites web appartenant à cette organisation.",
            "form.submit": "Supprimer l'organisation",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "toast.success": "L'organisation a été supprimée.",
            "toast.clipboard": "Copié dans le presse-papiers.",
            "error.name": "Le nom n'est pas correct.",
            "error.check": "Tapez 'supprimer' pour supprimer tous les sites web appartenant à cette organisation."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text_0": "Voer de naam van je organisatie in om de actie te bevestigen.",
            "text_1": "WAARSCHUWING: Het verwijderen van organisaties en websites is permanent!",
            "form.name": "Naam",
            "form.delete": "Websites verwijderen",
            "form.delete.hint": "Typ 'delete' om alle websites van deze organisatie te verwijderen.",
            "form.submit": "Organisatie verwijderen",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "toast.success": "De organisatie is verwijderd.",
            "toast.clipboard": "Gekopieerd naar klembord.",
            "error.name": "De naam is niet correct.",
            "error.check": "Typ 'verwijderen' om alle websites van deze organisatie te verwijderen."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text_0": "Inserisci il nome della tua organizzazione per confermare l'azione.",
            "text_1": "ATTENZIONE: L'eliminazione di organizzazioni e siti web è permanente!",
            "form.name": "Nome",
            "form.delete": "Elimina siti web",
            "form.delete.hint": "Digita 'delete' per eliminare tutti i siti web appartenenti a questa organizzazione.",
            "form.submit": "Elimina organizzazione",
            "copy_to_clipboard": "Copia negli appunti",
            "toast.success": "L'organizzazione è stata eliminata.",
            "toast.clipboard": "Copiato negli appunti.",
            "error.name": "Il nome non è corretto.",
            "error.check": "Digita 'elimina' per eliminare tutti i siti web appartenenti a questa organizzazione."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text_0": "Por favor, insira o nome da sua organização para confirmar a ação.",
            "text_1": "AVISO: Excluir organizações e sites é permanente!",
            "form.name": "Nome",
            "form.delete": "Excluir sites",
            "form.delete.hint": "Digite 'delete' para excluir todos os sites pertencentes a esta organização.",
            "form.submit": "Excluir organização",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "toast.success": "A organização foi excluída.",
            "toast.clipboard": "Copiado para a área de transferência.",
            "error.name": "O nome não está correto.",
            "error.check": "Digite 'excluir' para excluir todos os sites pertencentes a esta organização."
        },
        "ja": {
            "title": "削除の確認",
            "text_0": "アクションを確認するために、組織の名前を入力してください。",
            "text_1": "警告: 組織およびウェブサイトの削除は永久的なものです！",
            "form.name": "名前",
            "form.delete": "ウェブサイトを削除",
            "form.delete.hint": "この組織に属するすべてのウェブサイトを削除するには、「delete」と入力する。",
            "form.submit": "組織を削除",
            "copy_to_clipboard": "クリップボードにコピー",
            "toast.success": "組織が削除されました。",
            "toast.clipboard": "クリップボードにコピーしました。",
            "error.name": "名前が正しくありません。",
            "error.check": "この組織に属するすべてのウェブサイトを削除するには「削除」と入力してください。"
        }
    }
</i18n>
