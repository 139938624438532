import {defineStore} from "pinia";
import {ref, watch} from "vue";
import {ThemeRepo} from "@/repositories/ThemeRepo";
import {Theme} from "@/model/Theme";
import {KeyValue} from "@/model/KeyValue";
import {Domain} from "@/model/Domain";
import {isDashboard} from "@/util/domain";
import {isDarkMode, prefersDarkMode} from "@/util/style";
import {getQueryParam} from "@/util/url";
import {ThemeSettings} from "@/store/theme";

const themeMetaElements = document.querySelectorAll("[data-theme-hide]");

export const useThemeStore = defineStore("theme", () => {
    const themes = ref<Theme[]>([]);
    const theme = ref<KeyValue>();
    const darkMode = ref(isDarkMode());
    const loaded = ref(false);

    watch(theme, () => {
        updateTheme(theme.value ?? {});
    })

    async function load() {
        themes.value = await ThemeRepo.list() as Theme[];
    }

    function setTheme(domain?: Domain, settings?: KeyValue) {
        theme.value = (domain ? domain.theme_settings : settings) ?? {};

        if (!isDashboard() && localStorage.getItem("color_mode") === null) {
            setDarkMode(theme.value[ThemeSettings.mode] === "" ? "" : theme.value[ThemeSettings.mode] === "light" ? "false" : "true");
        }

        loaded.value = true;
    }

    function updateTheme(theme: KeyValue) {
        let style = "";

        for (const variable in ThemeSettings) {
            const v: string = ThemeSettings[variable as keyof typeof ThemeSettings];

            if (theme[v] && variable !== ThemeSettings.logoLight && variable !== ThemeSettings.logoDark &&
                variable !== ThemeSettings.favicon && variable !== ThemeSettings.title &&
                variable !== ThemeSettings.description && variable !== ThemeSettings.hideResources &&
                variable !== ThemeSettings.hideLogin && variable !== ThemeSettings.hideFooter &&
                variable !== ThemeSettings.hideCopyright && variable !== ThemeSettings.hideDarkModeToggle &&
                variable !== ThemeSettings.hideLanguageSwitch && variable !== ThemeSettings.mode) {
                style += `${v}: ${theme[v]};`;
            }
        }

        const html = document.getElementsByTagName("html")[0];
        html.setAttribute("style", style);
        const favicon = theme["favicon"] ? `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${theme["favicon"]}` : "";
        document.querySelectorAll("link[data-theme-favicon]").forEach(icon => {
            if (favicon) {
                icon.setAttribute("href", favicon);
            } else {
                icon.setAttribute("href", icon.getAttribute("data-theme-favicon") ?? "");
            }
        });
        document.querySelectorAll("meta[data-theme-meta-title]").forEach(meta => {
            if (theme[ThemeSettings.title]) {
                meta.setAttribute("content", theme[ThemeSettings.title]);
            } else {
                meta.setAttribute("content", meta.getAttribute("data-theme-meta-title") ?? "");
            }
        });
        document.querySelectorAll("meta[data-theme-meta-description]").forEach(meta => {
            if (theme[ThemeSettings.description]) {
                meta.setAttribute("content", theme[ThemeSettings.description]);
            } else {
                meta.setAttribute("content", meta.getAttribute("data-theme-meta-description") ?? "");
            }
        });
        document.querySelectorAll("meta[data-theme-color]").forEach(meta => {
            if (theme[ThemeSettings.darkColorBackgroundPrimary]) {
                meta.setAttribute("content", theme[ThemeSettings.darkColorBackgroundPrimary]);
            } else {
                meta.setAttribute("content", meta.getAttribute("data-theme-color") ?? "");
            }
        });
        const hideElements = document.querySelectorAll("[data-theme-hide]");

        if (Object.keys(theme).length) {
            hideElements.forEach(element => element.remove());
        } else {
            const head = document.getElementsByTagName("head");
            themeMetaElements.forEach(element => head[0].appendChild(element));
        }
    }

    function setDarkMode(useDarkMode: string): void {
        const html = document.getElementsByTagName("html");

        if (html.length === 1) {
            const darkModeParam = getQueryParam("mode");

            if (darkModeParam !== "") {
                useDarkMode = darkModeParam.toLowerCase() === "dark" ? "true" : "false";
            } else if (useDarkMode == "") {
                useDarkMode = prefersDarkMode().toString();
            }

            if (useDarkMode === "true") {
                html[0].classList.remove("theme-light");
                html[0].classList.add("theme-dark");
            } else {
                html[0].classList.remove("theme-dark");
                html[0].classList.add("theme-light");
            }

            darkMode.value = useDarkMode === "true";
        }

        const themeColor = document.querySelector("meta[name='theme-color']");

        if (themeColor) {
            themeColor.setAttribute("content", useDarkMode === "true" ? "#0a0a0a" : "#f8f5ed");
        }
    }

    return {
        themes,
        theme,
        darkMode,
        loaded,
        load,
        setTheme,
        setDarkMode
    };
});
