<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text_0", {name: domain?.display_name ?? domain?.hostname})}}</p>
        <p>{{t("text_1")}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {Domain} from "@/model/Domain";
    import {Organization} from "@/model/Organization";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true},
            domain: Object as PropType<Domain>
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    if (props.domain) {
                        loading.value = true;
                        resetError();
                        await OrganizationRepo.removeDomain(props.organization.id, props.domain.id);
                        loading.value = false;
                        success(t("toast.success"));
                        emit("action");
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Removal",
            "text_0": "Are you sure you want to remove the domain \"{name}\" from this organization?",
            "text_1": "Access to it will be removed for all members of this organization. If set, the organization theme will be removed.",
            "form.submit": "Remove",
            "toast.success": "The domain has been removed."
        },
        "de": {
            "title": "Entfernen bestätigen",
            "text_0": "Bist du sicher, dass du die Domain \"{name}\" aus dieser Organisation entfernen möchtest?",
            "text_1": "Der Zugriff darauf wird für alle Mitglieder dieser Organisation entfernt. Falls festgelegt, wird das Organisationsthema entfernt.",
            "form.submit": "Entfernen",
            "toast.success": "Die Domain wurde entfernt."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text_0": "¿Estás seguro de que deseas eliminar el dominio \"{name}\" de esta organización?",
            "text_1": "El acceso a él será eliminado para todos los miembros de esta organización. Si está configurado, se eliminará el tema de la organización.",
            "form.submit": "Eliminar",
            "toast.success": "El dominio ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text_0": "Êtes-vous sûr de vouloir supprimer le domaine \"{name}\" de cette organisation ?",
            "text_1": "L'accès à ce domaine sera supprimé pour tous les membres de cette organisation. Si défini, le thème de l'organisation sera supprimé.",
            "form.submit": "Supprimer",
            "toast.success": "Le domaine a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text_0": "Weet je zeker dat je het domein \"{name}\" uit deze organisatie wilt verwijderen?",
            "text_1": "De toegang daartoe wordt verwijderd voor alle leden van deze organisatie. Indien ingesteld, wordt het organisatiethema verwijderd.",
            "form.submit": "Verwijderen",
            "toast.success": "Het domein is verwijderd."
        },
        "it": {
            "title": "Conferma rimozione",
            "text_0": "Sei sicuro di voler rimuovere il dominio \"{name}\" da questa organizzazione?",
            "text_1": "L'accesso a esso verrà rimosso per tutti i membri di questa organizzazione. Se impostato, verrà rimosso il tema dell'organizzazione.",
            "form.submit": "Rimuovere",
            "toast.success": "Il dominio è stato rimosso."
        },
        "pt": {
            "title": "Confirmar remoção",
            "text_0": "Tem certeza de que deseja remover o domínio \"{name}\" desta organização?",
            "text_1": "O acesso a ele será removido para todos os membros desta organização. Se definido, o tema da organização será removido.",
            "form.submit": "Remover",
            "toast.success": "O domínio foi removido."
        },
        "ja": {
            "title": "削除の確認",
            "text_0": "この組織からドメイン \"{name}\" を削除してもよろしいですか？",
            "text_1": "この組織のすべてのメンバーへのアクセスが削除されます。設定されている場合、組織のテーマは削除されます。",
            "form.submit": "削除",
            "toast.success": "ドメインが削除されました。"
        }
    }
</i18n>
