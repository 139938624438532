<template>
    <CreateEditClient :open="editClientModal"
        :forDomain="forDomain"
        v-on:close="editClientModal = false"
        v-on:action="updateClient"
        :client="client" />
    <ConfirmDeleteClient :open="deleteClientModal"
        v-on:close="deleteClientModal = false"
        v-on:action="removeClient"
        :client="client" />
    <div class="card">
        <div class="text">
            <p>
                <strong>{{client.description}}</strong>
            </p>
            <small class="light" v-if="client.client_id">ID: {{client.client_id}}</small>
        </div>
        <div class="row">
            <Lock :forDomain="forDomain" :lock="`client_${client.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editClientModal = true"></i>
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteClientModal = true"></i>
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import CreateEditClient from "@/components/modal/CreateEditClient.vue";
    import ConfirmDeleteClient from "@/components/modal/ConfirmDeleteClient.vue";
    import {Client} from "@/model/Client";
    import Lock from "@/components/settings/Lock.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Lock,
            CreateEditClient,
            ConfirmDeleteClient
        },
        props: {
            forDomain: {type: Boolean, default: true},
            client: {type: Object as PropType<Client>, required: true}
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const editClientModal = ref(false);
            const deleteClientModal = ref(false);

            function updateClient(client: Client) {
                editClientModal.value = false;
                emit("update", client);
            }

            function removeClient() {
                deleteClientModal.value = false;
                emit("remove", props.client);
            }

            return {
                ...useI18n(),
                editClientModal,
                deleteClientModal,
                updateClient,
                removeClient
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "edit": "Edit",
            "delete": "Delete"
        },
        "de": {
            "edit": "Bearbeiten",
            "delete": "Löschen"
        },
        "es": {
            "edit": "Editar",
            "delete": "Eliminar"
        },
        "fr": {
            "edit": "Éditer",
            "delete": "Supprimer"
        },
        "nl": {
            "edit": "Bewerken",
            "delete": "Verwijderen"
        },
        "it": {
            "edit": "Modifica",
            "delete": "Elimina"
        },
        "pt": {
            "edit": "Editar",
            "delete": "Excluir"
        },
        "ja": {
            "edit": "編集",
            "delete": "削除"
        }
    }
</i18n>
