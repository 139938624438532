<template>
    <Banner v-on:dismiss="dismiss" v-if="!user.read_only && !dismissed && loggedIn && (trialExpiring || noSubscription || limitReached)">
        <template v-if="trialExpiring">
            {{t("trial_expiring")}}
        </template>
        <template v-else-if="noSubscription">
            {{t("no_subscription")}}
        </template>
        <template v-else>
            {{t("limit_reached", {startBlockingOn: startBlockingOn ? day(startBlockingOn) : ""})}}
        </template>
        <br />
        <router-link to="/billing">{{t("upgrade")}}</router-link>
    </Banner>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import Banner from "@/components/bits/Banner.vue";
    import {day} from "@/util/format";
    import dayjs from "dayjs";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Banner
        },
        setup() {
            const {user, loggedIn} = storeToRefs(useUserStore());
            const {domain, active} = storeToRefs(useDomainStore());
            const trialExpiring = computed(() => user.value.free_trial && user.value.free_trial_expiration_sent);
            const noSubscription = computed(() => !user.value.free_trial && !user.value.stripe_subscription_id && !user.value.subscription_active && active.value && domain.value.user_id === user.value.id);
            const limitReached = computed(() => !user.value.free_trial && !!user.value.start_blocking_hits && active.value && domain.value.user_id === user.value.id);
            const startBlockingOn = computed(() => user.value.start_blocking_hits);
            const dismissed = ref(true);

            onMounted(() => {
                const date = localStorage.getItem("dismiss_subscription_banner");

                if (date) {
                    const day = dayjs(date);

                    if (day.isBefore(dayjs())) {
                        localStorage.removeItem("dismiss_subscription_banner");
                    } else {
                        return;
                    }
                }

                dismissed.value = false;
            });

            function dismiss() {
                localStorage.setItem("dismiss_subscription_banner", dayjs().add(dayjs.duration(1, "day")).toISOString());
                dismissed.value = true;
            }

            return {
                ...useI18n(),
                user,
                day,
                loggedIn,
                trialExpiring,
                noSubscription,
                limitReached,
                startBlockingOn,
                dismissed,
                dismiss
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "trial_expiring": "Your free trial is about to expire.",
            "no_subscription": "You don't have an active subscription. The filter is limited to the last day of your previous subscription.",
            "limit_reached": "You have reached the page view limit of your subscription plan. The filter is limited to the day you have reached the limit. We will stop collecting data on {startBlockingOn} unless the limit is reset at the end of your current billing cycle or you upgrade the plan.",
            "upgrade": "Upgrade now"
        },
        "de": {
            "trial_expiring": "Deine kostenlose Testphase läuft bald ab.",
            "no_subscription": "Du hast kein aktives Abonnement. Der Filter ist auf den letzten Tag deines vorherigen Abonnements beschränkt.",
            "limit_reached": "Du hast das Seitenaufruf-Limit deines Abonnementplans erreicht. Der Filter ist auf den Tag beschränkt, an dem du das Limit erreicht hast. Wir werden ab dem {startBlockingOn} keine Daten mehr sammeln, es sei denn, das Limit wird am Ende deines aktuellen Abrechnungszyklus zurückgesetzt oder du erhöhst den Plan.",
            "upgrade": "Jetzt upgraden"
        },
        "es": {
            "trial_expiring": "Tu prueba gratuita está a punto de expirar.",
            "no_subscription": "No tienes una suscripción activa. El filtro está limitado al último día de tu suscripción anterior.",
            "limit_reached": "Has alcanzado el límite de vistas de página de tu plan de suscripción. El filtro está limitado al día en que alcanzaste el límite. Dejaremos de recopilar datos el {startBlockingOn} a menos que el límite se restablezca al final de tu ciclo de facturación actual o actualices el plan.",
            "upgrade": "Actualizar ahora"
        },
        "fr": {
            "trial_expiring": "Votre essai gratuit est sur le point d'expirer.",
            "no_subscription": "Vous n'avez pas d'abonnement actif. Le filtre est limité au dernier jour de votre abonnement précédent.",
            "limit_reached": "Vous avez atteint la limite de vues de page de votre plan d'abonnement. Le filtre est limité au jour où vous avez atteint la limite. Nous cesserons de collecter des données le {startBlockingOn} à moins que la limite ne soit réinitialisée à la fin de votre cycle de facturation actuel ou que vous ne mettiez à niveau le plan.",
            "upgrade": "Mettre à niveau maintenant"
        },
        "nl": {
            "trial_expiring": "Je gratis proefperiode verloopt binnenkort.",
            "no_subscription": "Je hebt geen actief abonnement. Het filter is beperkt tot de laatste dag van je vorige abonnement.",
            "limit_reached": "Je hebt de paginabezoeklimiet van je abonnementsplan bereikt. Het filter is beperkt tot de dag waarop je het limiet hebt bereikt. We zullen stoppen met het verzamelen van gegevens op {startBlockingOn}, tenzij het limiet wordt gereset aan het einde van je huidige factureringscyclus of je het plan upgradet.",
            "upgrade": "Nu upgraden"
        },
        "it": {
            "trial_expiring": "La tua prova gratuita sta per scadere.",
            "no_subscription": "Non hai un abbonamento attivo. Il filtro è limitato all'ultimo giorno del tuo precedente abbonamento.",
            "limit_reached": "Hai raggiunto il limite di visualizzazioni di pagina del tuo piano di abbonamento. Il filtro è limitato al giorno in cui hai raggiunto il limite. Smetteremo di raccogliere dati il {startBlockingOn} a meno che il limite non venga ripristinato alla fine del tuo ciclo di fatturazione corrente o tu esegua l'upgrade del piano.",
            "upgrade": "Aggiorna ora"
        },
        "pt": {
            "trial_expiring": "Seu teste gratuito está prestes a expirar.",
            "no_subscription": "Você não tem uma assinatura ativa. O filtro é limitado ao último dia de sua assinatura anterior.",
            "limit_reached": "Você atingiu o limite de visualizações de página do seu plano de assinatura. O filtro é limitado ao dia em que você atingiu o limite. Pararemos de coletar dados em {startBlockingOn} a menos que o limite seja redefinido no final do seu ciclo de faturamento atual ou você atualize o plano.",
            "upgrade": "Atualize agora"
        },
        "ja": {
            "trial_expiring": "無料トライアルがまもなく終了します。",
            "no_subscription": "アクティブなサブスクリプションがありません。フィルターは以前のサブスクリプションの最終日に制限されています。",
            "limit_reached": "サブスクリプションプランのページビューの制限に達しました。フィルターは制限に達した日に制限されています。現在の請求サイクルの終わりに制限がリセットされるか、プランをアップグレードしない限り、{startBlockingOn} にデータの収集を停止します。",
            "upgrade": "今すぐアップグレード"
        }
    }
</i18n>
