<template>
    <tr :class="{'cursor-pointer': selectable}" v-on:click="filter">
        <td v-for="(column, index) in columns" :key="column.key">
            <div :class="{'cell-content': true, left: column.align === 'left'}">
                <img :class="{round: entry.is_app}"
                    :src="entry.icon"
                    alt=""
                    v-if="index === 0 && entry.label && entry.icon" />
                <i :class="entry.icon_class"
                    v-else-if="index === 0 && entry.label && entry.icon_class" />
                <i class="icon icon-globe"
                    v-else-if="index === 0 && (entry.icon !== undefined || entry.icon_class !== undefined)" />
                <i :class="{'icon icon-expand': true, 'icon-rotate-180': showDetails}"
                    v-on:click.stop="toggleDetails"
                    v-else-if="index === 0 && $slots.default" />
                <span :title="index !== 0 ? formatNumber(entry[column.key]) : (rawTitle && entry.label_raw ? entry.label_raw : entry[column.key])" v-if="entry[column.key] || index !== 0">
                    {{getValue(column)}}
                </span>
                <span :title="unknownLabel" v-else>
                    {{unknownLabel}}
                </span>
                <i class="icon icon-follow clickable"
                    v-on:click.stop="$emit('open')"
                    v-if="index === 0 && newTab && entry.url" />
            </div>
        </td>
    </tr>
    <td :colspan="columns.length" v-show="showDetails" v-if="$slots.default">
        <slot></slot>
    </td>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {Column} from "@/components/panels/Column";
    import {ListEntry as Entry} from "@/model/ListEntry";
    import {formatNumber} from "@/util/format";

    export default defineComponent({
        props: {
            columns: {type: Array as PropType<Column[]>, required: true},
            entry: {type: Object as PropType<Entry>, required: true},
            entries: {type: Number, required: true},
            newTab: {type: Boolean, default: false},
            rawTitle: {type: Boolean, default: false},
            unknownLabel: {type: String, default: "Unknown"},
            selectable: {type: Boolean, default: false}
        },
        emits: ["click", "open", "details"],
        setup(props, {emit}) {
            const showDetails = ref(false);

            function filter() {
                if (props.selectable) {
                    emit("click");
                }
            }

            function getValue(column: Column) {
                return column.transform ? column.transform(props.entry[column.key]) : props.entry[column.key];
            }

            function toggleDetails() {
                showDetails.value = !showDetails.value;
                emit("details", showDetails.value);
            }

            return {
                formatNumber,
                showDetails,
                filter,
                getValue,
                toggleDetails
            };
        }
    });
</script>
