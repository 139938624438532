<template>
    <div :class="{action: true, active: dropdown}" v-on:click="toggleDropdown" ref="dropdownElement">
        <i class="icon icon-search" :title="t('add')"></i>
        <span>{{t("title")}}</span>
        <i :class="{'icon icon-expand': true, 'icon-rotate-180': dropdown}"></i>
        <transition name="fade-down">
            <ul class="dropdown" ref="scrollArea" v-show="dropdown">
                <li v-for="(option, index) in options"
                    :key="option.field"
                    :class="{selected: active === index}"
                    v-on:click.stop="add(option.field)">
                    {{option.label}}
                </li>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent, computed} from "vue";
    import {useDropdown} from "@/components/dropdown";
    import {useSelect} from "@/components/panels/select";
    import {useFilterParamsStore} from "@/store/FilterParamsStore";
    import {filterOptions} from "@/components/panels/filter_fields";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            const {t} = useI18n();
            const {add: addFilter} = useFilterParamsStore();
            const {dropdown, dropdownElement, toggleDropdown} = useDropdown();
            const options = computed(() => {
                const options = filterOptions.map(e => ({...e}));

                for (let i = 0; i < options.length; i++) {
                    options[i].label = t("options.filter."+options[i].label);
                }

                return options;
            });
            const {active, scrollArea} = useSelect(dropdown, options, select);

            function select(active: number) {
                add(options.value[active].field);
            }

            function add(option: string) {
                addFilter({field: option, value: ""});
                toggleDropdown();
            }

            return {
                t,
                dropdown,
                dropdownElement,
                toggleDropdown,
                active,
                scrollArea,
                options,
                add
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "add": "Add Filter",
            "title": "Filter",
            "options.filter.Hostname": "Hostname",
            "options.filter.Page": "Page",
            "options.filter.Pattern (Regex)": "Pattern (Regex)",
            "options.filter.Entry Page": "Entry Page",
            "options.filter.Exit Page": "Exit Page",
            "options.filter.Referrer Name": "Referrer Name",
            "options.filter.Referrer": "Referrer",
            "options.filter.UTM Source": "UTM Source",
            "options.filter.UTM Medium": "UTM Medium",
            "options.filter.UTM Campaign": "UTM Campaign",
            "options.filter.UTM Content": "UTM Content",
            "options.filter.UTM Term": "UTM Term",
            "options.filter.Event": "Event",
            "options.filter.Country": "Country",
            "options.filter.Region": "Region",
            "options.filter.City": "City",
            "options.filter.Language": "Language",
            "options.filter.OS": "OS",
            "options.filter.Browser": "Browser",
            "options.filter.Platform": "Platform",
            "options.filter.Screen": "Screen",
            "options.filter.Tag": "Tag"
        },
        "de": {
            "add": "Filter hinzufügen",
            "title": "Filter",
            "options.filter.Hostname": "Hostname",
            "options.filter.Page": "Seite",
            "options.filter.Pattern (Regex)": "Muster (Regex)",
            "options.filter.Entry Page": "Einstiegsseite",
            "options.filter.Exit Page": "Ausstiegsseite",
            "options.filter.Referrer Name": "Referrer-Name",
            "options.filter.Referrer": "Referrer",
            "options.filter.UTM Source": "UTM-Quelle",
            "options.filter.UTM Medium": "UTM-Medium",
            "options.filter.UTM Campaign": "UTM-Kampagne",
            "options.filter.UTM Content": "UTM-Inhalt",
            "options.filter.UTM Term": "UTM-Begriff",
            "options.filter.Event": "Event",
            "options.filter.Country": "Land",
            "options.filter.Region": "Region",
            "options.filter.City": "Stadt",
            "options.filter.Language": "Sprache",
            "options.filter.OS": "Betriebssystem",
            "options.filter.Browser": "Browser",
            "options.filter.Platform": "Plattform",
            "options.filter.Screen": "Bildschirm",
            "options.filter.Tag": "Tag"
        },
        "es": {
            "add": "Agregar Filtro",
            "title": "Filtro",
            "options.filter.Hostname": "Nombre de host",
            "options.filter.Page": "Página",
            "options.filter.Pattern (Regex)": "Patrón (Regex)",
            "options.filter.Entry Page": "Página de Entrada",
            "options.filter.Exit Page": "Página de Salida",
            "options.filter.Referrer Name": "Nombre del Referente",
            "options.filter.Referrer": "Referente",
            "options.filter.UTM Source": "Fuente UTM",
            "options.filter.UTM Medium": "Medio UTM",
            "options.filter.UTM Campaign": "Campaña UTM",
            "options.filter.UTM Content": "Contenido UTM",
            "options.filter.UTM Term": "Término UTM",
            "options.filter.Event": "Evento",
            "options.filter.Country": "País",
            "options.filter.Region": "Región",
            "options.filter.City": "Ciudad",
            "options.filter.Language": "Idioma",
            "options.filter.OS": "SO",
            "options.filter.Browser": "Navegador",
            "options.filter.Platform": "Plataforma",
            "options.filter.Screen": "Pantalla",
            "options.filter.Tag": "Etiqueta"
        },
        "fr": {
            "add": "Ajouter un Filtre",
            "title": "Filtre",
            "options.filter.Hostname": "Nom d'hôte",
            "options.filter.Page": "Page",
            "options.filter.Pattern (Regex)": "Motif (Regex)",
            "options.filter.Entry Page": "Page d'Entrée",
            "options.filter.Exit Page": "Page de Sortie",
            "options.filter.Referrer Name": "Nom du Référent",
            "options.filter.Referrer": "Référent",
            "options.filter.UTM Source": "Source UTM",
            "options.filter.UTM Medium": "Média UTM",
            "options.filter.UTM Campaign": "Campagne UTM",
            "options.filter.UTM Content": "Contenu UTM",
            "options.filter.UTM Term": "Terme UTM",
            "options.filter.Event": "Événement",
            "options.filter.Country": "Pays",
            "options.filter.Region": "Région",
            "options.filter.City": "Ville",
            "options.filter.Language": "Langue",
            "options.filter.OS": "OS",
            "options.filter.Browser": "Navigateur",
            "options.filter.Platform": "Plateforme",
            "options.filter.Screen": "Écran",
            "options.filter.Tag": "Tag"
        },
        "nl": {
            "add": "Filter toevoegen",
            "title": "Filter",
            "options.filter.Hostname": "Hostnaam",
            "options.filter.Page": "Pagina",
            "options.filter.Pattern (Regex)": "Patroon (Regex)",
            "options.filter.Entry Page": "Ingangspagina",
            "options.filter.Exit Page": "Uitgangspagina",
            "options.filter.Referrer Name": "Verwijzer Naam",
            "options.filter.Referrer": "Verwijzer",
            "options.filter.UTM Source": "UTM-bron",
            "options.filter.UTM Medium": "UTM-media",
            "options.filter.UTM Campaign": "UTM-campagne",
            "options.filter.UTM Content": "UTM-inhoud",
            "options.filter.UTM Term": "UTM-term",
            "options.filter.Event": "Evenement",
            "options.filter.Country": "Land",
            "options.filter.Region": "Regio",
            "options.filter.City": "Stad",
            "options.filter.Language": "Taal",
            "options.filter.OS": "OS",
            "options.filter.Browser": "Browser",
            "options.filter.Platform": "Platform",
            "options.filter.Screen": "Scherm",
            "options.filter.Tag": "Tag"
        },
        "it": {
            "add": "Aggiungi Filtro",
            "title": "Filtro",
            "options.filter.Hostname": "Nome host",
            "options.filter.Page": "Pagina",
            "options.filter.Pattern (Regex)": "Pattern (Regex)",
            "options.filter.Entry Page": "Pagina di Ingresso",
            "options.filter.Exit Page": "Pagina di Uscita",
            "options.filter.Referrer Name": "Nome Referrer",
            "options.filter.Referrer": "Referrer",
            "options.filter.UTM Source": "Fonte UTM",
            "options.filter.UTM Medium": "Media UTM",
            "options.filter.UTM Campaign": "Campagna UTM",
            "options.filter.UTM Content": "Contenuto UTM",
            "options.filter.UTM Term": "Termine UTM",
            "options.filter.Event": "Evento",
            "options.filter.Country": "Paese",
            "options.filter.Region": "Regione",
            "options.filter.City": "Città",
            "options.filter.Language": "Lingua",
            "options.filter.OS": "OS",
            "options.filter.Browser": "Browser",
            "options.filter.Platform": "Piattaforma",
            "options.filter.Screen": "Schermo",
            "options.filter.Tag": "Tag"
        },
        "pt": {
            "add": "Adicionar Filtro",
            "title": "Filtro",
            "options.filter.Hostname": "Nome do anfitrião",
            "options.filter.Page": "Página",
            "options.filter.Pattern (Regex)": "Padrão (Regex)",
            "options.filter.Entry Page": "Página de Entrada",
            "options.filter.Exit Page": "Página de Saída",
            "options.filter.Referrer Name": "Nome do Referenciador",
            "options.filter.Referrer": "Referenciador",
            "options.filter.UTM Source": "Fonte UTM",
            "options.filter.UTM Medium": "Mídia UTM",
            "options.filter.UTM Campaign": "Campanha UTM",
            "options.filter.UTM Content": "Conteúdo UTM",
            "options.filter.UTM Term": "Termo UTM",
            "options.filter.Event": "Evento",
            "options.filter.Country": "País",
            "options.filter.Region": "Região",
            "options.filter.City": "Cidade",
            "options.filter.Language": "Idioma",
            "options.filter.OS": "OS",
            "options.filter.Browser": "Navegador",
            "options.filter.Platform": "Plataforma",
            "options.filter.Screen": "Tela",
            "options.filter.Tag": "Tag"
        },
        "ja": {
            "add": "フィルタを追加",
            "title": "フィルター",
            "options.filter.Hostname": "ホスト名",
            "options.filter.Page": "ページ",
            "options.filter.Pattern (Regex)": "パターン（正規表現）",
            "options.filter.Entry Page": "エントリーページ",
            "options.filter.Exit Page": "退出ページ",
            "options.filter.Referrer Name": "リファラー名",
            "options.filter.Referrer": "リファラー",
            "options.filter.UTM Source": "UTMソース",
            "options.filter.UTM Medium": "UTMメディア",
            "options.filter.UTM Campaign": "UTMキャンペーン",
            "options.filter.UTM Content": "UTMコンテンツ",
            "options.filter.UTM Term": "UTM用語",
            "options.filter.Event": "イベント",
            "options.filter.Country": "国",
            "options.filter.Region": "地域",
            "options.filter.City": "都市",
            "options.filter.Language": "言語",
            "options.filter.OS": "OS",
            "options.filter.Browser": "ブラウザ",
            "options.filter.Platform": "プラットフォーム",
            "options.filter.Screen": "スクリーン",
            "options.filter.Tag": "タグ"
        }
    }
</i18n>
