<template>
    <div class="development-mode" v-if="dev">
        <p>Development Mode</p>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        setup() {
            return {
                dev: import.meta.env.VITE_APP_DEV
            };
        }
    });
</script>
