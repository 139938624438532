import {User} from "@/model/User";
import {APIError} from "@/repositories/APIError";
import {News} from "@/model/News";
import {Repository} from "@/repositories/Repository";

export const NewsRepo = new class extends Repository {
    async getNews(n: number): Promise<News[] | APIError> {
        return this.performGet<News[]>("/user/news", {n}, []);
    }

    async updateNews(news: number): Promise<User | APIError> {
        return this.performPost<User>("/user/news", {news});
    }
}
