<template>
    <component :is="component"
        panel="goals"
        :title="t('title')"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'goals' : 'top_goals')"
        :loadMore="loadMore(limitless ? 'goals' : 'top_goals')"
        :showBar="true"
        :selectable="true"
        :showDetails="showDetails"
        v-on:filter="filterByConversionGoal"
        v-on:search="searchAndSort">
        <template v-slot:icons>
            <CreateEditConversionGoal :open="createGoalModal"
                v-on:close="createGoalModal = false"
                v-on:action="loadGoals" />
            <i class="icon icon-add clickable"
                :title="t('add')"
                v-on:click="createGoalModal = true"
                v-if="loggedIn && domain.user_role && isAdmin" />
        </template>
        <template v-slot:default>
            <button v-on:click="createGoalModal = true" v-if="loggedIn && domain.user_role && isAdmin">{{t("add")}}</button>
        </template>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {ListEntry} from "@/model/ListEntry";
    import CreateEditConversionGoal from "@/components/modal/CreateEditConversionGoal.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {storeToRefs} from "pinia";
    import {useTable} from "@/components/panels/table";
    import {Row} from "@/components/panels/Row";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            CreateEditConversionGoal
        },
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadConversionGoals, conversionGoals} = useStatisticsStore();
            const {setField, removeField} = filterStore;
            const {filter} = storeToRefs(filterStore);
            const {searchAndSort} = useTable(loadConversionGoals);
            const rawData = computed(() => conversionGoals(props.limitless));
            const data = ref<Row[]>([]);
            const createGoalModal= ref(false);
            const columns = computed(() => [
                {key: "label", label: t("columns.name"), order_by: "name"},
                {key: "path_pattern", label: t("columns.pattern"), align: "left", order_by: "path_pattern"},
                {key: "views", label: t("columns.views"), transform: (v: number) => formatAndShortenNumber(v), order_by: "views"},
                {key: "visitor_target", label: t("columns.target"), transform: (v: number) => formatAndShortenNumber(v), order_by: "-"},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "cr_target", label: t("columns.cr"), transform: (v: number) => formatPercent(v)+"%", order_by: "-"},
                {key: "relative_value", label: t("columns.cr_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "cr"},
                {key: "custom_metric_avg", label: t("columns.custom_avg"), transform: (v: number) => formatAndShortenNumber(v), order_by: "custom_metric_avg"},
                {key: "custom_metric_total", label: t("columns.custom_total"), transform: (v: number) => formatAndShortenNumber(v), order_by: "custom_metric_total"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("goals_table");
                }

                data.value = rawData.value;
            });

            watch(rawData, () => {
                data.value = rawData.value;
            });

            function filterByConversionGoal(entry: ListEntry) {
                if (entry.path_pattern && entry.pattern) {
                    if (setField("pattern", `${entry.path_pattern || ""}|${entry.pattern || ""}`)) {
                        removeField("path", "");
                    }
                }

                if (entry.event_name) {
                    setField("event", entry.event_name);

                    if (entry.event_meta_key && entry.event_meta_value) {
                        setField(`meta_${entry.event_meta_key}`, entry.event_meta_value);
                    }

                    if (entry.custom_metric_key && (entry.custom_metric_type === "integer" || entry.custom_metric_type === "float")) {
                        setField("custom_metric_type", entry.custom_metric_type);
                        setField("custom_metric_key", entry.custom_metric_key);
                    }
                }
            }

            function loadGoals() {
                createGoalModal.value = false;
                loadConversionGoals(filter.value);
            }

            return {
                t,
                ...storeToRefs(useUserStore()),
                ...storeToRefs(useDomainStore()),
                columns,
                isLoading,
                loadMore,
                createGoalModal,
                data,
                filterByConversionGoal,
                loadGoals,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Conversion Goals",
            "add": "Add goal",
            "columns.name": "Name",
            "columns.pattern": "Pattern",
            "columns.views": "Views",
            "columns.target": "Visitors Target",
            "columns.visitors": "Visitors",
            "columns.cr": "CR Target %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Custom Avg",
            "columns.custom_total": "Custom Total"
        },
        "de": {
            "title": "Konversionsziele",
            "add": "Ziel hinzufügen",
            "columns.name": "Name",
            "columns.pattern": "Muster",
            "columns.views": "Ansichten",
            "columns.target": "Besucherziel",
            "columns.visitors": "Besucher",
            "columns.cr": "CR Ziel %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Benutzerdef. Durchschnitt",
            "columns.custom_total": "Benutzerdef. Gesamtmenge"
        },
        "es": {
            "title": "Objetivos de Conversión",
            "add": "Agregar objetivo",
            "columns.name": "Nombre",
            "columns.pattern": "Patrón",
            "columns.views": "Vistas",
            "columns.target": "Meta de Visitantes",
            "columns.visitors": "Visitantes",
            "columns.cr": "CR Objetivo %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Promedio Personalizado",
            "columns.custom_total": "Total Personalizado"
        },
        "fr": {
            "title": "Objectifs de Conversion",
            "add": "Ajouter un objectif",
            "columns.name": "Nom",
            "columns.pattern": "Modèle",
            "columns.views": "Vues",
            "columns.target": "Cible de Visiteurs",
            "columns.visitors": "Visiteurs",
            "columns.cr": "CR Cible %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Moyenne Personnalisée",
            "columns.custom_total": "Total Personnalisé"
        },
        "nl": {
            "title": "Conversiedoelen",
            "add": "Doel toevoegen",
            "columns.name": "Naam",
            "columns.pattern": "Patroon",
            "columns.views": "Weergaven",
            "columns.target": "Doel Bezoekers",
            "columns.visitors": "Bezoekers",
            "columns.cr": "CR Doel %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Aangepaste Gemiddelde",
            "columns.custom_total": "Aangepaste Totaal"
        },
        "it": {
            "title": "Obiettivi di Conversione",
            "add": "Aggiungi obiettivo",
            "columns.name": "Nome",
            "columns.pattern": "Modello",
            "columns.views": "Visualizzazioni",
            "columns.target": "Obiettivo Visitatori",
            "columns.visitors": "Visitatori",
            "columns.cr": "CR Obiettivo %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Media Personalizzata",
            "columns.custom_total": "Totale Personalizzato"
        },
        "pt": {
            "title": "Metas de Conversão",
            "add": "Adicionar meta",
            "columns.name": "Nome",
            "columns.pattern": "Padrão",
            "columns.views": "Visualizações",
            "columns.target": "Meta de Visitantes",
            "columns.visitors": "Visitantes",
            "columns.cr": "CR Meta %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "Média Personalizada",
            "columns.custom_total": "Total Personalizado"
        },
        "ja": {
            "title": "コンバージョン目標",
            "add": "目標を追加",
            "columns.name": "名前",
            "columns.pattern": "パターン",
            "columns.views": "ビュー",
            "columns.target": "訪問者目標",
            "columns.visitors": "訪問者",
            "columns.cr": "CR 目標 %",
            "columns.cr_relative": "CR %",
            "columns.custom_avg": "カスタム平均",
            "columns.custom_total": "カスタム合計"
        }
    }
</i18n>
