<template>
    <template v-if="loaded">
        <div class="tabs no-select" v-if="isAdmin">
            <h2 :class="{active: active === 'general'}" v-on:click="navigate('/settings')">{{t("general")}}</h2>
            <h2 :class="{active: active === 'access'}" v-on:click="navigate('/settings/access')">{{t("access")}}</h2>
            <h2 :class="{active: active === 'theme'}" v-on:click="navigate('/settings/theme')">{{t("theme")}}</h2>
            <h2 :class="{active: active === 'goals'}" v-on:click="navigate('/settings/goals')">{{t("goals")}}</h2>
            <h2 :class="{active: active === 'reports'}" v-on:click="navigate('/settings/reports')">{{t("reports")}}</h2>
            <h2 :class="{active: active === 'integration'}" v-on:click="navigate('/settings/integration')">{{t("integration")}}</h2>
            <h2 :class="{active: active === 'filter'}" v-on:click="navigate('/settings/filter')">{{t("filter")}}</h2>
            <h2 :class="{active: active === 'importExport'}" v-on:click="navigate('/settings/import-export')">{{t("import_export")}}</h2>
            <h2 :class="{active: active === 'dangerZone'}" v-on:click="navigate('/settings/danger-zone')">{{t("danger")}}</h2>
        </div>
        <template v-if="isAdmin">
            <slot></slot>
        </template>
        <section class="empty" v-else>
            <i class="icon icon-lock"></i>
            <p>{{t("permission")}}</p>
        </section>
    </template>
    <LoadingPage :loading="!loaded" />
</template>

<script lang="ts">
    import {defineComponent, onMounted, watch} from "vue";
    import {useRouter} from "vue-router";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useLockStore} from "@/store/LockStore";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            LoadingPage
        },
        props: {
            active: {type: String, required: true}
        },
        setup() {
            const router = useRouter();
            const {domain, loaded, isAdmin} = storeToRefs(useDomainStore());
            const lockStore = useLockStore();

            onMounted(() => {
                lockStore.load(domain.value.id);
            });

            watch(domain, () => {
                lockStore.load(domain.value.id);
            });

            function navigate(to: string) {
                router.push(to);
            }

            return {
                ...useI18n(),
                domain,
                loaded,
                isAdmin,
                navigate
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "general": "General",
            "access": "Access",
            "theme": "Theme",
            "goals": "Goals",
            "reports": "Reports",
            "integration": "Integration",
            "filter": "Filter",
            "import_export": "Import / Export",
            "danger": "Danger Zone",
            "permission": "You are not allowed to alter settings for this website. Please contact the dashboard owner, in case you consider this an error."
        },
        "de": {
            "general": "Allgemein",
            "access": "Zugriff",
            "theme": "Thema",
            "goals": "Ziele",
            "reports": "Berichte",
            "integration": "Integration",
            "filter": "Filter",
            "import_export": "Import / Export",
            "danger": "Gefahrenzone",
            "permission": "Du bist nicht berechtigt, die Einstellungen für diese Webseite zu ändern. Bitte kontaktiere den Dashboard-Besitzer, falls du dies für einen Fehler hältst."
        },
        "es": {
            "general": "General",
            "access": "Acceso",
            "theme": "Tema",
            "goals": "Objetivos",
            "reports": "Informes",
            "integration": "Integración",
            "filter": "Filtro",
            "import_export": "Importar / Exportar",
            "danger": "Zona de peligro",
            "permission": "No tienes permiso para alterar la configuración de este sitio web. Ponte en contacto con el propietario del tablero si consideras que esto es un error."
        },
        "fr": {
            "general": "Général",
            "access": "Accès",
            "theme": "Thème",
            "goals": "Objectifs",
            "reports": "Rapports",
            "integration": "Intégration",
            "filter": "Filtre",
            "import_export": "Importer / Exporter",
            "danger": "Zone de danger",
            "permission": "Vous n'êtes pas autorisé à modifier les paramètres de ce site Web. Veuillez contacter le propriétaire du tableau de bord si vous pensez qu'il s'agit d'une erreur."
        },
        "nl": {
            "general": "Algemeen",
            "access": "Toegang",
            "theme": "Thema",
            "goals": "Doelen",
            "reports": "Rapporten",
            "integration": "Integratie",
            "filter": "Filter",
            "import_export": "Importeren / Exporteren",
            "danger": "Gevaarzone",
            "permission": "Je hebt geen toestemming om de instellingen voor deze website te wijzigen. Neem contact op met de dashboard-eigenaar als je denkt dat dit een fout is."
        },
        "it": {
            "general": "Generale",
            "access": "Accesso",
            "theme": "Tema",
            "goals": "Obiettivi",
            "reports": "Rapporti",
            "integration": "Integrazione",
            "filter": "Filtro",
            "import_export": "Importa / Esporta",
            "danger": "Zona pericolosa",
            "permission": "Non sei autorizzato a modificare le impostazioni per questo sito web. Contatta il proprietario del cruscotto se pensi che questo sia un errore."
        },
        "pt": {
            "general": "Geral",
            "access": "Acesso",
            "theme": "Tema",
            "goals": "Metas",
            "reports": "Relatórios",
            "integration": "Integração",
            "filter": "Filtro",
            "import_export": "Importar / Exportar",
            "danger": "Zona de Perigo",
            "permission": "Você não tem permissão para alterar as configurações deste site. Por favor, entre em contato com o proprietário do painel se considerar que isto é um erro."
        },
        "ja": {
            "general": "一般",
            "access": "アクセス",
            "theme": "テーマ",
            "goals": "目標",
            "reports": "レポート",
            "integration": "統合",
            "filter": "フィルター",
            "import_export": "インポート / エクスポート",
            "danger": "危険ゾーン",
            "permission": "このウェブサイトの設定を変更する権限がありません。これがエラーだと思われる場合は、ダッシュボードの所有者に連絡してください。"
        }
    }
</i18n>
