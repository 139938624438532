<template>
    <div class="collapsible">
        <div class="collapsible-title clickable no-select" v-on:click="toggle">
            <template v-if="isOpen">{{title}}</template>
            <template v-else>{{titleCollapsed}}</template>
        </div>
        <Expand>
            <div class="collapsible-content" v-show="isOpen">
                <slot></slot>
            </div>
        </Expand>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Expand from "@/components/bits/Expand.vue";

    export default defineComponent({
        components: {
            Expand
        },
        props: {
            title: {type: String, required: true},
            titleCollapsed: {type: String, required: true},
            open: {Type: Boolean, default: false}
        },
        emits: ["toggle"],
        setup(props, {emit}) {
            const isOpen = ref(props.open);

            function toggle() {
                isOpen.value = !isOpen.value;
                emit("toggle", isOpen.value);
            }

            return {
                isOpen,
                toggle
            };
        }
    });
</script>
