<template>
    <div class="notice">
        <h2>{{t("title")}}</h2>
        <p>{{t("text_0")}}</p>
        <p>{{t("text_1")}}</p>
        <div class="actions">
            <router-link class="button orange" to="/billing">{{t("upgrade")}}</router-link>
            <router-link class="button secondary" to="/settings/danger-zone" v-if="isOwner">{{t("transfer")}}</router-link>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            return {
                ...useI18n(),
                ...storeToRefs(useDomainStore())
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "No Active Subscription",
            "text_0": "This dashboard is not associated with an account with an active subscription. No new data will be collected for it.",
            "text_1": "Please consider upgrading your plan or transferring ownership of the dashboard to another account to continue collecting data.",
            "upgrade": "Upgrade Now",
            "transfer": "Transfer Ownership"
        },
        "de": {
            "title": "Kein aktives Abonnement",
            "text_0": "Dieses Dashboard ist keinem Konto mit einem aktiven Abonnement zugeordnet. Es werden keine neuen Daten dafür gesammelt.",
            "text_1": "Bitte erwäge, dein Abonnement zu upgraden oder das Eigentum des Dashboards auf ein anderes Konto zu übertragen, um weiterhin Daten zu sammeln.",
            "upgrade": "Jetzt upgraden",
            "transfer": "Eigentum übertragen"
        },
        "es": {
            "title": "Sin suscripción activa",
            "text_0": "Este panel de control no está asociado a una cuenta con una suscripción activa. No se recopilarán nuevos datos para él.",
            "text_1": "Por favor, considera actualizar tu plan o transferir la propiedad del panel de control a otra cuenta para continuar recopilando datos.",
            "upgrade": "Actualizar ahora",
            "transfer": "Transferir propiedad"
        },
        "fr": {
            "title": "Pas d'abonnement actif",
            "text_0": "Ce tableau de bord n'est associé à aucun compte ayant un abonnement actif. Aucune nouvelle donnée ne sera collectée pour celui-ci.",
            "text_1": "Veuillez envisager de mettre à niveau votre plan ou de transférer la propriété du tableau de bord à un autre compte pour continuer à collecter des données.",
            "upgrade": "Mettre à niveau maintenant",
            "transfer": "Transférer la propriété"
        },
        "nl": {
            "title": "Geen actief abonnement",
            "text_0": "Dit dashboard is niet gekoppeld aan een account met een actief abonnement. Er worden geen nieuwe gegevens voor verzameld.",
            "text_1": "Overweeg je plan te upgraden of het eigendom van het dashboard over te dragen aan een ander account om gegevens te blijven verzamelen.",
            "upgrade": "Nu upgraden",
            "transfer": "Eigendom overdragen"
        },
        "it": {
            "title": "Nessun abbonamento attivo",
            "text_0": "Questa dashboard non è associata a un account con un abbonamento attivo. Non verranno raccolti nuovi dati per esso.",
            "text_1": "Si prega di considerare l'aggiornamento del piano o il trasferimento della proprietà della dashboard a un altro account per continuare a raccogliere dati.",
            "upgrade": "Aggiorna ora",
            "transfer": "Trasferisci la proprietà"
        },
        "pt": {
            "title": "Sem assinatura ativa",
            "text_0": "Este painel de controle não está associado a uma conta com uma assinatura ativa. Nenhum novo dado será coletado para ele.",
            "text_1": "Por favor, considere atualizar seu plano ou transferir a propriedade do painel de controle para outra conta para continuar coletando dados.",
            "upgrade": "Atualize agora",
            "transfer": "Transferir propriedade"
        },
        "ja": {
            "title": "アクティブなサブスクリプションはありません",
            "text_0": "このダッシュボードはアクティブなサブスクリプションを持つアカウントに関連付けられていません。新しいデータは収集されません。",
            "text_1": "データの収集を続けるには、プランのアップグレードを検討するか、ダッシュボードの所有権を別のアカウントに移転してください。",
            "upgrade": "今すぐアップグレード",
            "transfer": "所有権を移転"
        }
    }
</i18n>
