<template>
    <CreateOrganization :open="createModal"
        v-on:close="createModal = false"
        v-on:create="addOrganization" />
    <h1>{{t("title")}}</h1>
    <div :class="{overview: true, 'overview-list': type === OrganizationOverviewType.LIST}" v-if="!loading">
        <PirschPlus :show="!user.free_trial && !user.subscription_plus">
            <strong>{{t("plus")}}</strong>
        </PirschPlus>
        <OrganizationPanel v-for="entry of list"
            :key="entry.id"
            :organization="entry"
            :type="type" />
        <AddOrganizationPanel v-on:add="createModal = true" v-if="user.free_trial || user.subscription_plus" />
    </div>
    <LoadingPage :loading="loading" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import CreateOrganization from "@/components/modal/CreateOrganization.vue";
    import OrganizationPanel from "@/components/organizations/OrganizationPanel.vue";
    import AddOrganizationPanel from "@/components/organizations/AddOrganizationPanel.vue";
    import {storeToRefs} from "pinia";
    import {OrganizationOverviewType, useOrganizationStore} from "@/store/OrganizationStore";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import {useI18n} from "vue-i18n";
    import {useRoute} from "vue-router";

    export default defineComponent({
        components: {
            LoadingPage,
            PirschPlus,
            AddOrganizationPanel,
            OrganizationPanel,
            CreateOrganization
        },
        setup() {
            const route = useRoute();
            const organizationStore = useOrganizationStore();
            const {load} = organizationStore;
            const {type, organizations, search, loading} = storeToRefs(organizationStore);
            const {user} = storeToRefs(useUserStore());
            const createModal = ref(false);
            const list = computed(() => {
                const s = search.value.toLowerCase();

                if (!s) {
                    return organizations.value;
                }

                return organizations.value.filter(o => o.name.toLowerCase().includes(s));
            });

            onMounted(() => {
                createModal.value = !!route.query.open;
            });

            watch(route, r => {
                createModal.value = !!r.query.open;
            });

            async function addOrganization() {
                createModal.value = false;
                await load();
            }

            return {
                ...useI18n(),
                OrganizationOverviewType,
                type,
                list,
                loading,
                user,
                createModal,
                addOrganization
            }
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Organizations",
            "plus": "Using organizations requires a Pirsch Plus subscription."
        },
        "de": {
            "title": "Organisationen",
            "plus": "Die Nutzung von Organisationen erfordert ein Pirsch Plus-Abonnement."
        },
        "es": {
            "title": "Organizaciones",
            "plus": "El uso de organizaciones requiere una suscripción a Pirsch Plus."
        },
        "fr": {
            "title": "Organisations",
            "plus": "L'utilisation des organisations nécessite un abonnement Pirsch Plus."
        },
        "nl": {
            "title": "Organisaties",
            "plus": "Het gebruik van organisaties vereist een Pirsch Plus-abonnement."
        },
        "it": {
            "title": "Organizzazioni",
            "plus": "L'utilizzo delle organizzazioni richiede un abbonamento a Pirsch Plus."
        },
        "pt": {
            "title": "Organizações",
            "plus": "O uso de organizações requer uma assinatura Pirsch Plus."
        },
        "ja": {
            "title": "組織",
            "plus": "組織を利用するにはPirsch Plusのサブスクリプションが必要です。"
        }
    }
</i18n>
