import {APIError} from "@/repositories/APIError";
import {View} from "@/model/View";
import {Repository} from "@/repositories/Repository";

export interface ViewData {
    id?: string
    domain_id: string
    name: string
    from?: Date
    to?: Date
    from_time?: string
    to_time?: string
    period?: number
    compare?: string
    compare_from?: Date
    compare_to?: Date
    compare_weekday?: boolean
    path?: string[]
    entry_path?: string[]
    exit_path?: string[]
    path_pattern?: string[]
    pattern?: string[]
    language?: string[]
    country?: string[]
    region?: string[]
    city?: string[]
    referrer?: string[]
    referrer_name?: string[]
    os?: string[]
    browser?: string[]
    platform?: string
    screen_class?: string[]
    utm_source?: string[]
    utm_medium?: string[]
    utm_campaign?: string[]
    utm_content?: string[]
    utm_term?: string[]
    event?: string[]
    event_meta_key?: string[]
    event_meta_value?: string[]
    tag?: string[]
    tag_key?: string[]
    tag_value?: string[]
    visitor_id?: string
    session_id?: number
}

export const ViewRepo = new class extends Repository {
    async list(domain_id?: string): Promise<View[] | APIError> {
        return this.performGet<View[]>("/view", {domain_id}, []);
    }

    async save(view: ViewData, makePublic: boolean, overwrite: boolean): Promise<View | APIError> {
        return this.performPost<View>("/view", {...view, public: makePublic, overwrite});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/view", {id});
    }
}
