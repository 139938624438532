<template>
    <fieldset :class="{color: true, 'show-color-picker': modelValue}">
        <ColorPicker pickerType="chrome"
            format="hex"
            :disableAlpha="true"
            :disableHistory="true"
            lang="En"
            v-model:pureColor="pureColor"
            v-on:update:pure-color="update" />
        <label :for="name">{{label}}</label>
        <div class="default-color-preview" :style="`background-color: ${defaultValue};`" v-show="!modelValue"></div>
        <div class="default-color-label" v-show="!modelValue">{{defaultValue}}</div>
        <p v-show="modelValue">{{modelValue}}</p>
        <i class="icon icon-close clickable" v-on:click.stop.prevent="reset" v-show="modelValue"></i>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {ColorPicker} from "vue3-colorpicker";
    import {ColorInputWithoutInstance} from "tinycolor2";

    export default defineComponent({
        components: {
            ColorPicker
        },
        props: {
            label: String,
            name: {type: String, required: true},
            defaultValue: String,
            modelValue: String
        },
        emits: ["update:modelValue", "change"],
        setup(props, {emit}) {
            const pureColor = ref<ColorInputWithoutInstance>(props.defaultValue);

            watch(() => props.modelValue, color => {
                if (color) {
                    pureColor.value = color;
                }
            });

            function update(color: string) {
                emit("update:modelValue", color);
            }

            function reset() {
                pureColor.value = props.defaultValue;
                emit("update:modelValue", "");
            }

            return {
                pureColor,
                update,
                reset
            };
        }
    });
</script>
