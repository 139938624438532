import {APIError} from "@/repositories/APIError";
import {Lock} from "@/model/Lock";
import {Repository} from "@/repositories/Repository";

export const LockRepo = new class extends Repository {
    async list(domain_id?: string): Promise<Lock[] | APIError> {
        return this.performGet<Lock[]>("/lock", {domain_id}, []);
    }

    async toggle(domain_id: string | undefined, name: string): Promise<Lock | APIError> {
        return this.performPost<Lock>("/lock", {domain_id, name});
    }
}
