<template>
    <CreateEditConversionGoal :open="createGoalModal"
        v-on:close="createGoalModal = false"
        v-on:action="addGoal" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/conversion-goals" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <ConversionGoalCard v-for="goal in goals"
                        :key="goal.id"
                        :goal="goal"
                        v-on:update="updateGoal"
                        v-on:remove="removeGoal" />
                </div>
            </Expand>
            <button v-on:click="createGoalModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {useList} from "@/components/list";
    import {ConversionGoalRepo} from "@/repositories/ConversionGoalRepo";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {ConversionGoal} from "@/model/ConversionGoal";
    import ConversionGoalCard from "@/components/cards/ConversionGoalCard.vue";
    import CreateEditConversionGoal from "@/components/modal/CreateEditConversionGoal.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Loading,
            Expand,
            ConversionGoalCard,
            CreateEditConversionGoal
        },
        setup() {
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const createGoalModal = ref(false);
            const loading = ref(true);
            const goals = ref<ConversionGoal[]>([]);

            watchEffect(async () => {
                loading.value = true;
                goals.value = await ConversionGoalRepo.list(domain.value.id) as ConversionGoal[] || [];
                loading.value = false;
            });

            function addGoal(goal: ConversionGoal) {
                createGoalModal.value = false;
                goals.value.push(goal);
            }

            function updateGoal(goal: ConversionGoal) {
                updateEntryByID(goals.value, goal);
            }

            function removeGoal(goal: ConversionGoal) {
                removeEntryByID(goals.value, goal);
            }

            return {
                ...useI18n(),
                createGoalModal,
                loading,
                goals,
                addGoal,
                updateGoal,
                removeGoal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Conversion Goals",
            "text": "Set conversion targets for pages and be notified when they are reached.",
            "link": "Read the Docs",
            "add": "Add Goal"
        },
        "de": {
            "title": "Konversionsziele",
            "text": "Setze Konversionsziele für Seiten und werde benachrichtigt, wenn sie erreicht sind.",
            "link": "Dokumentation lesen",
            "add": "Ziel hinzufügen"
        },
        "es": {
            "title": "Objetivos de conversión",
            "text": "Establece objetivos de conversión para las páginas y recibe una notificación cuando se alcancen.",
            "link": "Leer la documentación",
            "add": "Añadir objetivo"
        },
        "fr": {
            "title": "Objectifs de conversion",
            "text": "Définissez des objectifs de conversion pour les pages et soyez averti lorsqu'ils sont atteints.",
            "link": "Lire la documentation",
            "add": "Ajouter un objectif"
        },
        "nl": {
            "title": "Conversiedoelen",
            "text": "Stel conversiedoelen in voor pagina's en ontvang een melding wanneer ze zijn bereikt.",
            "link": "Lees de documentatie",
            "add": "Doel toevoegen"
        },
        "it": {
            "title": "Obiettivi di conversione",
            "text": "Imposta obiettivi di conversione per le pagine e ricevi una notifica quando vengono raggiunti.",
            "link": "Leggi la documentazione",
            "add": "Aggiungi obiettivo"
        },
        "pt": {
            "title": "Metas de conversão",
            "text": "Defina metas de conversão para páginas e seja notificado quando elas forem alcançadas.",
            "link": "Leia a documentação",
            "add": "Adicionar meta"
        },
        "ja": {
            "title": "コンバージョンゴール",
            "text": "ページのコンバージョン目標を設定し、達成されたときに通知を受け取ります。",
            "link": "ドキュメントを読む",
            "add": "目標を追加"
        }
    }
</i18n>
