<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text_0")}}</p>
        <p class="warning">{{t("text_1")}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.hostname')" name="hostname" v-model="domain.hostname" disabled />
            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyClipboard(domain.hostname)"></i>
        </div>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.hostname')" name="hostname" :autofocus="true" autocomplete="off" v-model="hostname" :err="validationError('hostname')" />
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useRouter} from "vue-router";
    import {APIError} from "@/repositories/APIError";
    import copy from "copy-to-clipboard";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput,
            FormSubmit
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const router = useRouter();
            const domainStore = useDomainStore();
            const {success} = useToastStore();
            const {domain} = storeToRefs(domainStore);
            const {user} = storeToRefs(useUserStore());
            const {clear, clearComparison} = useFilterStore();
            const {resetError, setError, validationError, err} = useError();
            const loading = ref(false);
            const hostname = ref("");

            onMounted(() => {
                hostname.value = "";
            });

            async function save() {
                try {
                    resetError();

                    if (hostname.value !== domain.value.hostname) {
                        setError({
                            validation: {
                                hostname: t("error.hostname")
                            },
                            error: []
                        });
                        return;
                    }

                    loading.value = true;
                    await DomainRepo.delete(domain.value.id);
                    success(t("toast.success"));
                    const domains = await domainStore.load() || [];
                    hostname.value = "";
                    loading.value = false;
                    emit("close");

                    if (domains.length === 0) {
                        clear();
                        clearComparison();
                        user.value.read_only ? await router.push("/") : await router.push("/add");
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            function copyClipboard(value: string) {
                copy(value);
                success(t("toast.clipboard"));
            }

            return {
                t,
                validationError,
                err,
                loading,
                hostname,
                domain,
                save,
                copyClipboard
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text_0": "Please enter the hostname of your website to confirm the action.",
            "text_1": "WARNING: Deleting the website is permanent!",
            "form.hostname": "Hostname",
            "form.submit": "Delete Website",
            "copy_to_clipboard": "Copy to Clipboard",
            "toast.success": "The website has been deleted.",
            "toast.clipboard": "Copied to clipboard.",
            "error.hostname": "The hostname is not correct."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text_0": "Bitte gib den Hostnamen deiner Webseite ein, um die Aktion zu bestätigen.",
            "text_1": "WARNUNG: Das Löschen der Webseite ist dauerhaft!",
            "form.hostname": "Hostname",
            "form.submit": "Webseite löschen",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "toast.success": "Die Webseite wurde gelöscht.",
            "toast.clipboard": "In die Zwischenablage kopiert.",
            "error.hostname": "Der Hostname ist nicht korrekt."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text_0": "Por favor, introduce el nombre de host de tu sitio web para confirmar la acción.",
            "text_1": "ADVERTENCIA: ¡Eliminar el sitio web es permanente!",
            "form.hostname": "Nombre de host",
            "form.submit": "Eliminar sitio web",
            "copy_to_clipboard": "Copiar al portapapeles",
            "toast.success": "El sitio web ha sido eliminado.",
            "toast.clipboard": "Copiado al portapapeles.",
            "error.hostname": "El nombre de host no es correcto."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text_0": "Veuillez entrer le nom d'hôte de votre site web pour confirmer l'action.",
            "text_1": "ATTENTION : La suppression du site web est permanente !",
            "form.hostname": "Nom d'hôte",
            "form.submit": "Supprimer le site web",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "toast.success": "Le site web a été supprimé.",
            "toast.clipboard": "Copié dans le presse-papiers.",
            "error.hostname": "Le nom d'hôte n'est pas correct."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text_0": "Voer de hostnaam van je website in om de actie te bevestigen.",
            "text_1": "WAARSCHUWING: Het verwijderen van de website is permanent!",
            "form.hostname": "Hostnaam",
            "form.submit": "Website verwijderen",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "toast.success": "De website is verwijderd.",
            "toast.clipboard": "Gekopieerd naar klembord.",
            "error.hostname": "De hostnaam is niet correct."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text_0": "Inserisci il nome host del tuo sito web per confermare l'azione.",
            "text_1": "ATTENZIONE: L'eliminazione del sito web è permanente!",
            "form.hostname": "Nome host",
            "form.submit": "Elimina sito web",
            "copy_to_clipboard": "Copia negli appunti",
            "toast.success": "Il sito web è stato eliminato.",
            "toast.clipboard": "Copiato negli appunti.",
            "error.hostname": "Il nome host non è corretto."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text_0": "Por favor, insira o nome do host do seu site para confirmar a ação.",
            "text_1": "AVISO: Excluir o site é permanente!",
            "form.hostname": "Nome do host",
            "form.submit": "Excluir site",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "toast.success": "O site foi excluído.",
            "toast.clipboard": "Copiado para a área de transferência.",
            "error.hostname": "O nome do host não está correto."
        },
        "ja": {
            "title": "削除の確認",
            "text_0": "アクションを確認するために、ウェブサイトのホスト名を入力してください。",
            "text_1": "警告: ウェブサイトの削除は永久的なものです！",
            "form.hostname": "ホスト名",
            "form.submit": "ウェブサイトを削除",
            "copy_to_clipboard": "クリップボードにコピー",
            "toast.success": "ウェブサイトが削除されました。",
            "toast.clipboard": "クリップボードにコピーしました。",
            "error.hostname": "ホスト名が正しくありません。"
        }
    }
</i18n>
