<template>
    <Modal size="xl" :title="t('title')" :open="open">
        <EntryPage component="TableEntries"
            :showTitle="false"
            :showEnlarge="false"
            :limitless="true"
            :showDetails="true"
            :visible="true" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import EntryPage from "@/components/statistics/EntryPage.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {entries_per_page, useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            EntryPage
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const statisticsStore = useStatisticsStore();
            const {filter} = storeToRefs(filterStore);
            const {loadEntryPages} = statisticsStore;

            watch(() => props.open, open => {
                if (open) {
                    const f = filterStore.copy(filter.value);
                    f.limit = entries_per_page;
                    f.include_avg_time_on_page = true;
                    loadEntryPages(f, false);
                }
            });

            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Entry Pages"
        },
        "de": {
            "title": "Einstiegsseiten"
        },
        "es": {
            "title": "Páginas de Entrada"
        },
        "fr": {
            "title": "Pages d'Entrée"
        },
        "nl": {
            "title": "Ingangspagina's"
        },
        "it": {
            "title": "Pagine di Ingresso"
        },
        "pt": {
            "title": "Páginas de Entrada"
        },
        "ja": {
            "title": "エントリーページ"
        }
    }
</i18n>
