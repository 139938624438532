<template>
    <div :class="{'domain-selection': true, 'hover cursor-pointer': domains.length > 1 && domainSelection.length !== 0, active: dropdown && domains.length > 1}"
        v-on:click="toggleDropdown"
        ref="dropdownElement">
        <h1>{{domain.display_name ?? domain.hostname}}</h1>
        <i class="icon icon-follow clickable" :title="t('open')" v-on:click.stop="openPage(domain.hostname)" v-if="domains.length === 1 && !mobile" style="font-size: 20px;" />
        <i :class="{'icon icon-expand': true, 'icon-rotate-180': dropdown}" v-if="domains.length > 1"></i>
        <transition name="fade-down">
            <ul class="dropdown" ref="scrollArea" v-show="dropdown && domains.length > 1">
                <input type="text"
                    class="search"
                    name="search"
                    :placeholder="t('placeholder')"
                    autocomplete="off"
                    v-model="search"
                    v-on:click.stop />
                <hr />
                <li v-for="(d, index) in domainSelection"
                    :key="d.id"
                    :class="{selected: active === index}"
                    v-on:click.stop.prevent="switchDomain(d)">
                    <span>{{d.display_name ?? d.hostname}}</span>
                    <div style="display: flex; gap: 6px;">
                        <FavoriteDomain :domain="d" v-on:favorite="favorite" />
                        <PinnedDomain :domain="d" v-on:pin="pin" />
                        <i class="icon icon-follow clickable" :title="t('open')" v-on:click.stop="openPage(d.hostname)" />
                    </div>
                </li>
                <div class="no-data" v-if="domainSelection.length === 0">
                    <small>{{t("not_found")}}</small>
                </div>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent, computed, ref, watch} from "vue";
    import {useDropdown} from "@/components/dropdown";
    import {Domain} from "@/model/Domain";
    import {APIError} from "@/repositories/APIError";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useSelect} from "@/components/panels/select";
    import FavoriteDomain from "@/components/domain/FavoriteDomain.vue";
    import PinnedDomain from "@/components/domain/PinnedDomain.vue";
    import {useI18n} from "vue-i18n";
    import {useMobile} from "@/components/mobile";

    export default defineComponent({
        components: {
            PinnedDomain,
            FavoriteDomain
        },
        setup() {
            const domainStore = useDomainStore();
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);
            const {domain, domains} = storeToRefs(domainStore);
            const {clear, clearComparison} = useFilterStore();
            const {error} = useToastStore();
            const {dropdown, dropdownElement, toggleDropdown} = useDropdown();
            const domainSelection = computed(() => {
                if (!search.value) {
                    return domains.value;
                }

                const d = domains.value;
                const filtered = [];

                for (let i = 0; i < d.length; i++) {
                    if (d[i].hostname.includes(search.value)) {
                        filtered.push(d[i]);
                    }
                }

                return filtered;
            });
            const {active, scrollArea} = useSelect(dropdown, domainSelection, selectDomain);
            const search = ref("");

            watch(domain, domain => {
                active.value = domainSelection.value.findIndex(d => d.id === domain.id);
            });

            function selectDomain(active: number) {
                switchDomain(domainSelection.value[active]);
            }

            function switchDomain(d: Domain) {
                if (d.id !== domain.value.id) {
                    domainStore.set(d);
                    clear();
                    clearComparison();
                    toggleDropdown();
                }
            }

            async function favorite(id: string) {
                try {
                    if (id === user.value.favorite_domain_id) {
                        id = "";
                    }

                    await UserRepo.setFavoriteDomain(id);
                    await userStore.load();
                } catch (e) {
                    error(e as APIError);
                }
            }

            async function pin(id: string) {
                try {
                    await UserRepo.pinDomain(id);
                    await domainStore.load();
                } catch (e) {
                    error(e as APIError);
                }
            }

            function openPage(url: string): void {
                window.open("https://" + url, "_blank");
            }

            return {
                ...useI18n(),
                ...useMobile(),
                dropdown,
                dropdownElement,
                toggleDropdown,
                active,
                scrollArea,
                user,
                domain,
                domains,
                domainSelection,
                search,
                switchDomain,
                favorite,
                pin,
                openPage
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "placeholder": "Search...",
            "not_found": "No Website Found",
            "open": "Open Website"
        },
        "de": {
            "placeholder": "Suchen...",
            "not_found": "Keine Webseite gefunden",
            "open": "Webseite öffnen"
        },
        "es": {
            "placeholder": "Buscar...",
            "not_found": "No se encontró ningún sitio web",
            "open": "Abrir sitio web"
        },
        "fr": {
            "placeholder": "Rechercher...",
            "not_found": "Aucun site web trouvé",
            "open": "Ouvrir le site web"
        },
        "nl": {
            "placeholder": "Zoeken...",
            "not_found": "Geen website gevonden",
            "open": "Website openen"
        },
        "it": {
            "placeholder": "Cerca...",
            "not_found": "Nessun sito web trovato",
            "open": "Apri sito web"
        },
        "pt": {
            "placeholder": "Pesquisar...",
            "not_found": "Nenhum site encontrado",
            "open": "Abrir site"
        },
        "ja": {
            "placeholder": "検索...",
            "not_found": "ウェブサイトが見つかりません",
            "open": "ウェブサイトを開く"
        }
    }
</i18n>
