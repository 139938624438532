<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text_0")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/theme#custom-domains" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <template v-if="hasPlus">
                <form v-on:submit.prevent="save">
                    <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
                        <FormInput :label="t('form.hostname')" name="hostname" v-model="hostname" :err="validationError('hostname')" />
                        <i class="icon icon-follow clickable" :title="t('form.hostname.open')" v-on:click.stop="openPage" v-show="hostname" />
                    </div>
                    <FormSubmit :value="t('form.submit')" :loading="loading" />
                </form>
                <div v-if="domain.custom_domain">
                    <p style="margin: 12px 0;">{{t("text_1")}}</p>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <td style="width: auto;">
                                        <h5 style="text-align: left;">{{t("table.type")}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="left">{{t("table.name")}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="left">{{t("table.value")}}</h5>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="width: auto;">
                                        <div class="cell-content left">A</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">{{domain.custom_domain}}</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">
                                            {{ipv4_0}}
                                            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyValue(ipv4_0)" />
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="ipv4_1">
                                    <td style="width: auto;">
                                        <div class="cell-content left">A</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">{{domain.custom_domain}}</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">
                                            {{ipv4_1}}
                                            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyValue(ipv4_1)" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: auto;">
                                        <div class="cell-content left">AAAA</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">{{domain.custom_domain}}</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">
                                            {{ipv6_0}}
                                            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyValue(ipv6_0)" />
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="ipv6_1">
                                    <td>
                                        <div class="cell-content left">AAAA</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">{{domain.custom_domain}}</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">
                                            {{ipv6_1}}
                                            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyValue(ipv6_1)" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p style="margin-top: 12px;">{{t("text_2")}}</p>
                </div>
                <div v-else>
                    <p style="margin: 12px 0;">{{t("text_3")}}</p>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <td style="width: auto;">
                                        <h5 style="text-align: left;">{{t("table.type")}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="left">{{t("table.name")}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="left">{{t("table.value")}}</h5>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="width: auto;">
                                        <div class="cell-content left">TXT</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">{{hostname}}</div>
                                    </td>
                                    <td>
                                        <div class="cell-content left">
                                            <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyValue('pirsch-verify-domain='+domain.identification_code)" />
                                            pirsch-verify-domain={{domain.identification_code}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p style="margin-top: 12px;">{{t("text_4")}}</p>
                </div>
            </template>
            <PirschPlus>
                <strong>{{t("plus")}}</strong>
            </PirschPlus>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import copy from "copy-to-clipboard";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            PirschPlus,
            FormSubmit,
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const {hasPlus} = storeToRefs(useUserStore());
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const hostname = ref("");

            watchEffect(() => {
                hostname.value = domain.value.custom_domain ?? "";
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await DomainRepo.updateCustomDomain(domain.value.id, hostname.value);
                    domain.value.custom_domain = hostname.value;
                    loading.value = false;
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            function copyValue(value: string) {
                copy(value);
                success(t("toast.clipboard"));
            }

            function openPage(): void {
                window.open("https://" + hostname.value, "_blank");
            }

            return {
                t,
                ipv4_0: import.meta.env.VITE_APP_LB_IPV4_0 as string,
                ipv4_1: import.meta.env.VITE_APP_LB_IPV4_1 as string,
                ipv6_0: import.meta.env.VITE_APP_LB_IPV6_0 as string,
                ipv6_1: import.meta.env.VITE_APP_LB_IPV6_1 as string,
                hasPlus,
                validationError,
                domain,
                loading,
                hostname,
                save,
                copyValue,
                openPage
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Custom Domain",
            "text_0": "Use your own domain for this dashboard and the script. Each dashboard must have its own (sub)domain.",
            "link": "Read the Docs",
            "form.hostname": "Domain",
            "form.hostname.open": "Open Website",
            "form.submit": "Save",
            "text_1": "Create the following DNS records to make the dashboard available on your domain:",
            "text_2": "It may take a while for the settings to take effect. We will request an SSL certificate for your domain the first time you visit.",
            "text_3": "Create the following DNS TXT record to verify you're the owner of the domain:",
            "text_4": "It may take a while for the settings to take effect. Once the TXT record can be verified, we'll store your custom domain and the A/AAAA records can be configured. We'll then display the required records here. If it doesn't work the first time, please give it a minute to propagate the DNS change and try again.",
            "table.type": "Type",
            "table.name": "Name",
            "table.value": "Value",
            "copy_to_clipboard": "Copy to Clipboard",
            "plus": "Custom domains require a Pirsch Plus subscription.",
            "toast.success": "Custom domain saved.",
            "toast.clipboard": "Value copied to clipboard."
        },
        "de": {
            "title": "Benutzerdefinierte Domain",
            "text_0": "Verwende deine eigene Domain für dieses Dashboard und das Skript. Jedes Dashboard muss seine eigene (Sub-)Domain haben.",
            "link": "Dokumentation lesen",
            "form.hostname": "Domain",
            "form.hostname.open": "Webseite öffnen",
            "form.submit": "Speichern",
            "text_1": "Erstelle die folgenden DNS-Einträge, um das Dashboard auf deiner Domain verfügbar zu machen:",
            "text_2": "Es kann eine Weile dauern, bis die Einstellungen wirksam werden. Wir werden beim ersten Besuch ein SSL-Zertifikat für deine Domain anfordern.",
            "text_3": "Erstelle den folgenden DNS-TXT-Eintrag, um zu überprüfen, dass du der Besitzer der Domain bist:",
            "text_4": "Es kann eine Weile dauern, bis die Einstellungen wirksam werden. Sobald der TXT-Eintrag überprüft werden kann, speichern wir deine benutzerdefinierte Domain und die A/AAAA-Einträge können konfiguriert werden. Wir zeigen dann die erforderlichen Einträge hier an. Wenn es beim ersten Mal nicht funktioniert, gib dem DNS-Änderungsvorgang bitte eine Minute Zeit und versuche es erneut.",
            "table.type": "Typ",
            "table.name": "Name",
            "table.value": "Wert",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "plus": "Benutzerdefinierte Domains erfordern ein Pirsch Plus-Abonnement.",
            "toast.success": "Benutzerdefinierte Domain gespeichert.",
            "toast.clipboard": "Wert in die Zwischenablage kopiert."
        },
        "es": {
            "title": "Dominio personalizado",
            "text_0": "Usa tu propio dominio para este panel y el script. Cada panel debe tener su propio (sub)dominio.",
            "link": "Leer la documentación",
            "form.hostname": "Dominio",
            "form.hostname.open": "Abrir sitio web",
            "form.submit": "Guardar",
            "text_1": "Crea los siguientes registros DNS para hacer que el panel esté disponible en tu dominio:",
            "text_2": "Puede tardar un tiempo en que los ajustes surtan efecto. Solicitaremos un certificado SSL para tu dominio la primera vez que lo visites.",
            "text_3": "Crea el siguiente registro DNS TXT para verificar que eres el propietario del dominio:",
            "text_4": "Puede tardar un tiempo en que los ajustes surtan efecto. Una vez que se pueda verificar el registro TXT, almacenaremos tu dominio personalizado y se podrán configurar los registros A/AAAA. Luego mostraremos los registros requeridos aquí. Si no funciona la primera vez, espera un minuto para que se propague el cambio de DNS y vuelve a intentarlo.",
            "table.type": "Tipo",
            "table.name": "Nombre",
            "table.value": "Valor",
            "copy_to_clipboard": "Copiar al portapapeles",
            "plus": "Los dominios personalizados requieren una suscripción a Pirsch Plus.",
            "toast.success": "Dominio personalizado guardado.",
            "toast.clipboard": "Valor copiado al portapapeles."
        },
        "fr": {
            "title": "Domaine personnalisé",
            "text_0": "Utilisez votre propre domaine pour ce tableau de bord et le script. Chaque tableau de bord doit avoir son propre (sous-)domaine.",
            "link": "Lire la documentation",
            "form.hostname": "Domaine",
            "form.hostname.open": "Ouvrir le site web",
            "form.submit": "Enregistrer",
            "text_1": "Créez les enregistrements DNS suivants pour rendre le tableau de bord disponible sur votre domaine :",
            "text_2": "Il peut falloir un certain temps pour que les paramètres prennent effet. Nous demanderons un certificat SSL pour votre domaine la première fois que vous le visiterez.",
            "text_3": "Créez l'enregistrement DNS TXT suivant pour vérifier que vous êtes le propriétaire du domaine :",
            "text_4": "Il peut falloir un certain temps pour que les paramètres prennent effet. Une fois que l'enregistrement TXT peut être vérifié, nous stockerons votre domaine personnalisé et les enregistrements A/AAAA pourront être configurés. Nous afficherons ensuite les enregistrements requis ici. Si cela ne fonctionne pas la première fois, veuillez attendre une minute pour propager la modification DNS et réessayer.",
            "table.type": "Type",
            "table.name": "Nom",
            "table.value": "Valeur",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "plus": "Les domaines personnalisés nécessitent un abonnement à Pirsch Plus.",
            "toast.success": "Domaine personnalisé enregistré.",
            "toast.clipboard": "Valeur copiée dans le presse-papiers."
        },
        "nl": {
            "title": "Aangepast domein",
            "text_0": "Gebruik je eigen domein voor dit dashboard en het script. Elk dashboard moet zijn eigen (sub)domein hebben.",
            "link": "Lees de documentatie",
            "form.hostname": "Domein",
            "form.hostname.open": "Website openen",
            "form.submit": "Opslaan",
            "text_1": "Maak de volgende DNS-records om het dashboard beschikbaar te maken op je domein:",
            "text_2": "Het kan even duren voordat de instellingen van kracht worden. We zullen een SSL-certificaat voor je domein aanvragen de eerste keer dat je het bezoekt.",
            "text_3": "Maak het volgende DNS TXT-record om te verifiëren dat je de eigenaar van het domein bent:",
            "text_4": "Het kan even duren voordat de instellingen van kracht worden. Zodra het TXT-record kan worden geverifieerd, slaan we je aangepaste domein op en kunnen de A/AAAA-records worden geconfigureerd. We zullen dan de vereiste records hier weergeven. Als het de eerste keer niet werkt, geef de DNS-wijziging dan een minuut om te propagateren en probeer het opnieuw.",
            "table.type": "Type",
            "table.name": "Naam",
            "table.value": "Waarde",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "plus": "Aangepaste domeinen vereisen een Pirsch Plus-abonnement.",
            "toast.success": "Aangepast domein opgeslagen.",
            "toast.clipboard": "Waarde gekopieerd naar klembord."
        },
        "it": {
            "title": "Dominio personalizzato",
            "text_0": "Usa il tuo dominio per questa dashboard e lo script. Ogni dashboard deve avere il proprio (sotto)dominio.",
            "link": "Leggi la documentazione",
            "form.hostname": "Dominio",
            "form.hostname.open": "Apri sito web",
            "form.submit": "Salva",
            "text_1": "Crea i seguenti record DNS per rendere disponibile la dashboard sul tuo dominio:",
            "text_2": "Potrebbe volerci del tempo prima che le impostazioni abbiano effetto. Richiederemo un certificato SSL per il tuo dominio la prima volta che lo visiti.",
            "text_3": "Crea il seguente record DNS TXT per verificare che tu sia il proprietario del dominio:",
            "text_4": "Potrebbe volerci del tempo prima che le impostazioni abbiano effetto. Una volta che il record TXT può essere verificato, memorizzeremo il tuo dominio personalizzato e i record A/AAAA possono essere configurati. Quindi mostreremo i record richiesti qui. Se non funziona la prima volta, attendi un minuto affinché la modifica DNS si propaghi e riprova.",
            "table.type": "Tipo",
            "table.name": "Nome",
            "table.value": "Valore",
            "copy_to_clipboard": "Copia negli appunti",
            "plus": "I domini personalizzati richiedono un abbonamento a Pirsch Plus.",
            "toast.success": "Dominio personalizzato salvato.",
            "toast.clipboard": "Valore copiato negli appunti."
        },
        "pt": {
            "title": "Domínio personalizado",
            "text_0": "Use seu próprio domínio para este painel e o script. Cada painel deve ter seu próprio (sub)domínio.",
            "link": "Leia a documentação",
            "form.hostname": "Domínio",
            "form.hostname.open": "Abrir site",
            "form.submit": "Salvar",
            "text_1": "Crie os seguintes registros DNS para tornar o painel disponível em seu domínio:",
            "text_2": "Pode levar algum tempo para que as configurações entrem em vigor. Solicitaremos um certificado SSL para seu domínio na primeira vez que você o visitar.",
            "text_3": "Crie o seguinte registro DNS TXT para verificar se você é o proprietário do domínio:",
            "text_4": "Pode levar algum tempo para que as configurações entrem em vigor. Assim que o registro TXT puder ser verificado, armazenaremos seu domínio personalizado e os registros A/AAAA poderão ser configurados. Em seguida, exibiremos os registros necessários aqui. Se não funcionar na primeira vez, aguarde um minuto para propagar a alteração de DNS e tente novamente.",
            "table.type": "Tipo",
            "table.name": "Nome",
            "table.value": "Valor",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "plus": "Domínios personalizados requerem uma assinatura do Pirsch Plus.",
            "toast.success": "Domínio personalizado salvo.",
            "toast.clipboard": "Valor copiado para a área de transferência."
        },
        "ja": {
            "title": "カスタムドメイン",
            "text_0": "このダッシュボードとスクリプトに自分のドメインを使用します。各ダッシュボードには独自の（サブ）ドメインが必要です。",
            "link": "ドキュメントを読む",
            "form.hostname": "ドメイン",
            "form.hostname.open": "ウェブサイトを開く",
            "form.submit": "保存",
            "text_1": "ダッシュボードをあなたのドメインで利用できるようにするために、次のDNSレコードを作成します:",
            "text_2": "設定が反映されるまで時間がかかることがあります。初めて訪問する際にドメイン用のSSL証明書をリクエストします。",
            "text_3": "あなたがドメインの所有者であることを確認するために、次のDNS TXTレコードを作成します:",
            "text_4": "設定が反映されるまで時間がかかることがあります。TXTレコードが確認できるようになったら、カスタムドメインを保存し、A/AAAAレコードを設定できるようになります。その後、必要なレコードをここに表示します。最初にうまくいかない場合は、DNSの変更が反映されるのを待ってから再試行してください。",
            "table.type": "タイプ",
            "table.name": "名前",
            "table.value": "値",
            "copy_to_clipboard": "クリップボードにコピー",
            "plus": "カスタムドメインにはPirsch Plusサブスクリプションが必要です。",
            "toast.success": "カスタムドメインが保存されました。",
            "toast.clipboard": "値がクリップボードにコピーされました。"
        }
    }
</i18n>
