import {BaseEntity} from "@/model/BaseEntity";

export interface User extends BaseEntity {
    email: string
    full_name: string
    picture: string
    accept_marketing: boolean
    dark_mode: boolean | null
    default_time_range: string
    delete_on: Date | null
    stripe_customer_id: string | null
    stripe_subscription_id: string | null
    stripe_currency: string | null
    subscription_plan: number
    subscription_plus: boolean
    subscription_cycle: Date
    subscription_active: boolean
    subscription_downgrade_to?: number
    subscription_downgrade_plus: boolean
    free_trial: boolean
    free_trial_expiration_sent: boolean
    hits_warning: number
    start_blocking_hits: Date | null
    hit_limit: number | null
    news: number
    favorite_domain_id: string
    active_visitors_title: boolean
    read_only: boolean
    dashboard_full_width: boolean
    last_payment_failed: boolean
    language: string | null
}

export function defaultUser(): User {
    return {
        id: "",
        mod_time: new Date(),
        def_time: new Date(),
        email: "",
        full_name: "",
        picture: "",
        accept_marketing: false,
        dark_mode: null,
        default_time_range: "",
        delete_on: null,
        stripe_customer_id: null,
        stripe_subscription_id: null,
        stripe_currency: null,
        subscription_plan: 0,
        subscription_plus: false,
        subscription_cycle: new Date(),
        subscription_active: false,
        subscription_downgrade_plus: false,
        free_trial: false,
        free_trial_expiration_sent: false,
        hits_warning: 0,
        start_blocking_hits: null,
        hit_limit: null,
        news: 0,
        favorite_domain_id: "",
        active_visitors_title: false,
        read_only: false,
        dashboard_full_width: false,
        last_payment_failed: false,
        language: null
    };
}
