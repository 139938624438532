<template>
    <section class="row" v-if="isAdmin">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <form v-on:submit.prevent>
                <FormSelect :label="t('form.time')" name="time" v-model="time" :options="options" v-on:change="save" />
            </form>
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watchEffect} from "vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect
        },
        setup() {
            const {t} = useI18n();
            const {domain, isAdmin} = storeToRefs(useDomainStore());
            const {success} = useToastStore();
            const time = ref(600);
            const options = computed(() => [
                {label: t("options.time.minutes", {count: 1}), value: 60},
                {label: t("options.time.minutes", {count: 2}), value: 120},
                {label: t("options.time.minutes", {count: 5}), value: 300},
                {label: t("options.time.minutes", {count: 10}), value: 600},
                {label: t("options.time.minutes", {count: 15}), value: 900}
            ]);

            watchEffect(() => {
                if (domain.value) {
                    time.value = domain.value.active_visitors_seconds || 600;
                }
            });

            async function save() {
                await DomainRepo.updateActiveVisitors(domain.value.id, Number.parseInt(time.value as unknown as string));
                domain.value.active_visitors_seconds = time.value;
                success(t("toast.success"));
            }

            return {
                t,
                options,
                isAdmin,
                time,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Active Visitors",
            "text": "Configure how long a visitor is considered active after their last page view.",
            "form.time": "Time Since Last Visit",
            "options.time.minutes": "{count} Minute | {count} Minutes",
            "toast.success": "Settings saved."
        },
        "de": {
            "title": "Aktive Besucher",
            "text": "Konfiguriere, wie lange ein Besucher nach seinem letzten Seitenaufruf als aktiv gilt.",
            "form.time": "Zeit seit dem letzten Besuch",
            "options.time.minutes": "{count} Minute | {count} Minuten",
            "toast.success": "Einstellungen gespeichert."
        },
        "es": {
            "title": "Visitantes activos",
            "text": "Configura cuánto tiempo se considera activo a un visitante después de su última visita a la página.",
            "form.time": "Tiempo desde la última visita",
            "options.time.minutes": "{count} Minuto | {count} Minutos",
            "toast.success": "Configuraciones guardadas."
        },
        "fr": {
            "title": "Visiteurs actifs",
            "text": "Configurez la durée pendant laquelle un visiteur est considéré comme actif après sa dernière visite de page.",
            "form.time": "Temps depuis la dernière visite",
            "options.time.minutes": "{count} Minute | {count} Minutes",
            "toast.success": "Paramètres enregistrés."
        },
        "nl": {
            "title": "Actieve bezoekers",
            "text": "Configureer hoe lang een bezoeker na zijn laatste paginaweergave als actief wordt beschouwd.",
            "form.time": "Tijd sinds laatste bezoek",
            "options.time.minutes": "{count} Minuut | {count} Minuten",
            "toast.success": "Instellingen opgeslagen."
        },
        "it": {
            "title": "Visitatori attivi",
            "text": "Configura per quanto tempo un visitatore è considerato attivo dopo la sua ultima visualizzazione della pagina.",
            "form.time": "Tempo dall'ultima visita",
            "options.time.minutes": "{count} Minuto | {count} Minuti",
            "toast.success": "Impostazioni salvate."
        },
        "pt": {
            "title": "Visitantes Ativos",
            "text": "Configure quanto tempo um visitante é considerado ativo após sua última visualização de página.",
            "form.time": "Tempo desde a última visita",
            "options.time.minutes": "{count} Minuto | {count} Minutos",
            "toast.success": "Configurações salvas."
        },
        "ja": {
            "title": "アクティブな訪問者",
            "text": "最後のページビュー後、訪問者がアクティブと見なされる時間を設定します。",
            "form.time": "最後の訪問からの時間",
            "options.time.minutes": "{count} 分 | {count} 分",
            "toast.success": "設定が保存されました。"
        }
    }
</i18n>
