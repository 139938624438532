<template>
    <div class="card">
        <div class="text">
            <p>
                <strong>{{domain.display_name ?? domain.hostname}}</strong>
            </p>
        </div>
        <div class="row">
            <i class="icon icon-stats clickable" :title="t('dashboard')" v-on:click="toDashboard" />
            <i class="icon icon-settings clickable" :title="t('settings')" v-on:click="toSettings" />
            <i class="icon icon-trash clickable" :title="t('remove')" v-on:click="$emit('remove', domain)" />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from "vue";
    import {Domain} from "@/model/Domain";
    import {useRouter} from "vue-router";
    import {useDomainStore} from "@/store/DomainStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            domain: {type: Object as PropType<Domain>, required: true}
        },
        emits: ["remove"],
        setup(props) {
            const router = useRouter();
            const domainStore = useDomainStore();
            const {clear, clearComparison} = useFilterStore();

            function toDashboard() {
                if (props.domain.id !== domainStore.domain.id) {
                    switchDomain(props.domain.id);
                }

                router.push("/");
            }

            function toSettings() {
                if (props.domain.id !== domainStore.domain.id) {
                    switchDomain(props.domain.id);
                }

                router.push("/settings");
            }

            function switchDomain(id: string) {
                const domain = domainStore.domains.find(d => d.id === id);
                domainStore.set(domain);
                clear();
                clearComparison();
            }

            return {
                ...useI18n(),
                toDashboard,
                toSettings
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "dashboard": "Go to Dashboard",
            "settings": "Go to Domain Settings",
            "remove": "Remove From Organization"
        },
        "de": {
            "dashboard": "Zum Dashboard gehen",
            "settings": "Zu den Domain-Einstellungen gehen",
            "remove": "Aus der Organisation entfernen"
        },
        "es": {
            "dashboard": "Ir al panel de control",
            "settings": "Ir a la configuración del dominio",
            "remove": "Eliminar de la organización"
        },
        "fr": {
            "dashboard": "Aller au tableau de bord",
            "settings": "Aller aux paramètres du domaine",
            "remove": "Retirer de l'organisation"
        },
        "nl": {
            "dashboard": "Ga naar Dashboard",
            "settings": "Ga naar Domeininstellingen",
            "remove": "Verwijderen uit organisatie"
        },
        "it": {
            "dashboard": "Vai al Dashboard",
            "settings": "Vai alle Impostazioni del Dominio",
            "remove": "Rimuovi dall'Organizzazione"
        },
        "pt": {
            "dashboard": "Ir para o Painel de Controle",
            "settings": "Ir para Configurações de Domínio",
            "remove": "Remover da Organização"
        },
        "ja": {
            "dashboard": "ダッシュボードに行く",
            "settings": "ドメイン設定に行く",
            "remove": "組織から削除"
        }
    }
</i18n>
