import {onMounted, onUnmounted, ref, Ref} from "vue";

interface Mobile {
    mobile: Ref<boolean>
}

export function useMobile(): Mobile {
    const mobile = ref(isMobile());

    onMounted(() => {
        window.addEventListener("resize", updateIsMobile);
    });

    onUnmounted(() => {
        window.removeEventListener("resize", updateIsMobile);
    });

    function updateIsMobile() {
        mobile.value = isMobile();
    }

    function isMobile(): boolean {
        return window.matchMedia("only screen and (max-width: 768px)").matches;
    }

    return {
        mobile
    };
}
