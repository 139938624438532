<template>
    <component :is="component"
        panel="tags"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'tags' : 'top_tags')"
        :loadMore="loadMore(limitless ? 'tags' : 'top_tags')"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :visible="visible"
        :showDetails="showDetails"
        v-on:filter="filterByTag"
        v-on:search="searchAndSort">
        <template v-slot:default>
            <button v-on:click="$emit('open')" v-if="loggedIn && domain.user_role && isAdmin">{{t("open")}}</button>
        </template>
    </component>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {ListEntry} from "@/model/ListEntry";
    import {useTable} from "@/components/panels/table";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {Row} from "@/components/panels/Row";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, default: false}
        },
        emits: ["open"],
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadTags, tags} = useStatisticsStore();
            const {setField} = filterStore;
            const {searchAndSort} = useTable(loadTags);
            const rawData = computed(() => tags(props.limitless));
            const data = ref<Row[]>([]);
            const columns = computed(() => [
                {key: "label", label: t("columns.tag"), order_by: "key"},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.visitors_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_visitors"},
                {key: "views", label: t("columns.views"), transform: (v: number) => formatAndShortenNumber(v), order_by: "views"},
                {key: "relative_views", label: t("columns.views_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_views"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("tags_table");
                }
            });

            watch(rawData, () => {
                data.value = rawData.value;
            });

            function filterByTag(entry: ListEntry) {
                setField("tag", entry.label);
            }

            return {
                t,
                ...storeToRefs(useUserStore()),
                ...storeToRefs(useDomainStore()),
                columns,
                isLoading,
                loadMore,
                data,
                filterByTag,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "open": "Track Tags",
            "columns.tag": "Tag",
            "columns.visitors": "Visitors",
            "columns.visitors_relative": "Visitors %",
            "columns.views": "Views",
            "columns.views_relative": "Views %"
        },
        "de": {
            "open": "Tags verfolgen",
            "columns.tag": "Tag",
            "columns.visitors": "Besucher",
            "columns.visitors_relative": "Besucher %",
            "columns.views": "Ansichten",
            "columns.views_relative": "Ansichten %"
        },
        "es": {
            "open": "Rastrear Etiquetas",
            "columns.tag": "Etiqueta",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.views": "Vistas",
            "columns.views_relative": "Vistas %"
        },
        "fr": {
            "open": "Suivre les Tags",
            "columns.tag": "Tag",
            "columns.visitors": "Visiteurs",
            "columns.visitors_relative": "Visiteurs %",
            "columns.views": "Vues",
            "columns.views_relative": "Vues %"
        },
        "nl": {
            "open": "Tags volgen",
            "columns.tag": "Tag",
            "columns.visitors": "Bezoekers",
            "columns.visitors_relative": "Bezoekers %",
            "columns.views": "Weergaven",
            "columns.views_relative": "Weergaven %"
        },
        "it": {
            "open": "Traccia Tag",
            "columns.tag": "Tag",
            "columns.visitors": "Visitatori",
            "columns.visitors_relative": "Visitatori %",
            "columns.views": "Visualizzazioni",
            "columns.views_relative": "Visualizzazioni %"
        },
        "pt": {
            "open": "Acompanhar Tags",
            "columns.tag": "Tag",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.views": "Visualizações",
            "columns.views_relative": "Visualizações %"
        },
        "ja": {
            "open": "タグを追跡",
            "columns.tag": "タグ",
            "columns.visitors": "訪問者",
            "columns.visitors_relative": "訪問者 %",
            "columns.views": "ビュー",
            "columns.views_relative": "ビュー %"
        }
    }
</i18n>
