<template>
    <component :is="component"
        panel="referrer"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'referrer' : 'top_referrer')"
        :loadMore="loadMore(limitless ? 'referrer' : 'top_referrer')"
        :newTab="true"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :rawTitle="true"
        :showDetails="showDetails"
        :selectable="true"
        :unknownLabel="t('unknown')"
        v-on:filter="filterByReferrer"
        v-on:open="open"
        v-on:search="searchAndSort" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {ListEntry} from "@/model/ListEntry";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {Row} from "@/components/panels/Row";
    import {useFilterStore} from "@/store/FilterStore";
    import {trimProtocol, useStatisticsStore} from "@/store/StatisticsStore";
    import {useTable} from "@/components/panels/table";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadReferrer, referrer} = useStatisticsStore();
            const {setField, hasField} = filterStore;
            const {searchAndSort} = useTable(loadReferrer);
            const rawData = computed(() => referrer(props.limitless));
            const data = ref<Row[]>([]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("referrer_table");
                }

                data.value = rawData.value;
            });

            watch([rawData, () => props.visible], () => {
                if (props.visible) {
                    data.value = rawData.value;
                }
            });

            function open(entry: ListEntry) {
                if (entry.url) {
                    window.open(entry.url, "_blank");
                }
            }

            function filterByReferrer(entry: ListEntry) {
                if (hasField("referrer_name")) {
                    setField("referrer", entry.filter || "null");
                } else {
                    setField("referrer_name", entry.filter || "null");
                }
            }

            return {
                columns: [
                    {key: "label", label: t("columns.referrer"), transform: trimProtocol, order_by: hasField("referrer_name") ? "referrer" : "referrer_name"},
                    {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                    {key: "relative_value", label: t("columns.visitors_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_visitors"},
                    {key: "bounce_rate", label: t("columns.bounce_rate"), transform: (v: number) => formatPercent(v)+"%", order_by: "bounce_rate"}
                ],
                t,
                isLoading,
                loadMore,
                data,
                open,
                filterByReferrer,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "unknown": "Direct / None",
            "columns.referrer": "Referrer",
            "columns.visitors": "Visitors",
            "columns.visitors_relative": "Visitors %",
            "columns.bounce_rate": "Bounce Rate"
        },
        "de": {
            "unknown": "Direkt / Keine",
            "columns.referrer": "Referrer",
            "columns.visitors": "Besucher",
            "columns.visitors_relative": "Besucher %",
            "columns.bounce_rate": "Absprungrate"
        },
        "es": {
            "unknown": "Directo / Ninguno",
            "columns.referrer": "Referente",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.bounce_rate": "Tasa de Rebote"
        },
        "fr": {
            "unknown": "Direct / Aucun",
            "columns.referrer": "Référent",
            "columns.visitors": "Visiteurs",
            "columns.visitors_relative": "Visiteurs %",
            "columns.bounce_rate": "Taux de Rebond"
        },
        "nl": {
            "unknown": "Direct / Geen",
            "columns.referrer": "Verwijzer",
            "columns.visitors": "Bezoekers",
            "columns.visitors_relative": "Bezoekers %",
            "columns.bounce_rate": "Bouncepercentage"
        },
        "it": {
            "unknown": "Diretto / Nessuno",
            "columns.referrer": "Referrer",
            "columns.visitors": "Visitatori",
            "columns.visitors_relative": "Visitatori %",
            "columns.bounce_rate": "Frequenza di Rimbalzo"
        },
        "pt": {
            "unknown": "Direto / Nenhum",
            "columns.referrer": "Referenciador",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.bounce_rate": "Taxa de Rejeição"
        },
        "ja": {
            "unknown": "直接 / なし",
            "columns.referrer": "リファラー",
            "columns.visitors": "訪問者",
            "columns.visitors_relative": "訪問者 %",
            "columns.bounce_rate": "直帰率"
        }
    }
</i18n>
