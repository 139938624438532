<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p class="warning">{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        emits: ["action", "close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const identificationCode = await DomainRepo.resetIdentificationCode(domain.value.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action", identificationCode);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Reset Identification Code",
            "text": "You are about to reset the identification code. This action will make the current code invalid and you will have to replace it in the JavaScript integration.",
            "form.submit": "Reset",
            "toast.success": "The identification code has been reset."
        },
        "de": {
            "title": "Identifikationscode zurücksetzen",
            "text": "Du bist dabei, den Identifikationscode zurückzusetzen. Diese Aktion macht den aktuellen Code ungültig und du musst ihn in der JavaScript-Integration ersetzen.",
            "form.submit": "Zurücksetzen",
            "toast.success": "Der Identifikationscode wurde zurückgesetzt."
        },
        "es": {
            "title": "Restablecer código de identificación",
            "text": "Estás a punto de restablecer el código de identificación. Esta acción hará que el código actual sea inválido y tendrás que reemplazarlo en la integración de JavaScript.",
            "form.submit": "Restablecer",
            "toast.success": "El código de identificación ha sido restablecido."
        },
        "fr": {
            "title": "Réinitialiser le code d'identification",
            "text": "Vous êtes sur le point de réinitialiser le code d'identification. Cette action rendra le code actuel invalide et vous devrez le remplacer dans l'intégration JavaScript.",
            "form.submit": "Réinitialiser",
            "toast.success": "Le code d'identification a été réinitialisé."
        },
        "nl": {
            "title": "Identificatiecode resetten",
            "text": "Je staat op het punt de identificatiecode te resetten. Deze actie maakt de huidige code ongeldig en je moet deze vervangen in de JavaScript-integratie.",
            "form.submit": "Resetten",
            "toast.success": "De identificatiecode is gereset."
        },
        "it": {
            "title": "Reimposta codice di identificazione",
            "text": "Stai per reimpostare il codice di identificazione. Questa azione renderà il codice attuale non valido e dovrai sostituirlo nell'integrazione JavaScript.",
            "form.submit": "Reimposta",
            "toast.success": "Il codice di identificazione è stato reimpostato."
        },
        "pt": {
            "title": "Redefinir código de identificação",
            "text": "Você está prestes a redefinir o código de identificação. Esta ação tornará o código atual inválido e você terá que substituí-lo na integração JavaScript.",
            "form.submit": "Redefinir",
            "toast.success": "O código de identificação foi redefinido."
        },
        "ja": {
            "title": "識別コードのリセット",
            "text": "識別コードをリセットしようとしています。この操作により、現在のコードは無効になり、JavaScript統合でそれを置き換える必要があります。",
            "form.submit": "リセット",
            "toast.success": "識別コードがリセットされました。"
        }
    }
</i18n>
