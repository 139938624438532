<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" v-model="name" :err="validationError('name')" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {PropType} from "vue/dist/vue";
    import {Organization} from "@/model/Organization";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {storeToRefs} from "pinia";
    import {useOrganizationStore} from "@/store/OrganizationStore";
    import {useList} from "@/components/list";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSubmit,
            FormInput
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const {organizations} = storeToRefs(useOrganizationStore());
            const {updateEntryByID} = useList();
            const loading = ref(false);
            const name = ref(props.organization.name);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const organization = await OrganizationRepo.update(props.organization.id, name.value) as Organization;
                    updateEntryByID(organizations.value, organization);
                    loading.value = false;
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                validationError,
                loading,
                name,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Name",
            "text": "Change the name of the organization.",
            "form.name": "Name",
            "form.submit": "Update Name",
            "toast.success": "The organization has been updated."
        },
        "de": {
            "title": "Name",
            "text": "Ändere den Namen der Organisation.",
            "form.name": "Name",
            "form.submit": "Name aktualisieren",
            "toast.success": "Die Organisation wurde aktualisiert."
        },
        "es": {
            "title": "Nombre",
            "text": "Cambiar el nombre de la organización.",
            "form.name": "Nombre",
            "form.submit": "Actualizar nombre",
            "toast.success": "La organización ha sido actualizada."
        },
        "fr": {
            "title": "Nom",
            "text": "Changer le nom de l'organisation.",
            "form.name": "Nom",
            "form.submit": "Mettre à jour le nom",
            "toast.success": "L'organisation a été mise à jour."
        },
        "nl": {
            "title": "Naam",
            "text": "Wijzig de naam van de organisatie.",
            "form.name": "Naam",
            "form.submit": "Naam bijwerken",
            "toast.success": "De organisatie is bijgewerkt."
        },
        "it": {
            "title": "Nome",
            "text": "Cambia il nome dell'organizzazione.",
            "form.name": "Nome",
            "form.submit": "Aggiorna nome",
            "toast.success": "L'organizzazione è stata aggiornata."
        },
        "pt": {
            "title": "Nome",
            "text": "Mude o nome da organização.",
            "form.name": "Nome",
            "form.submit": "Atualizar nome",
            "toast.success": "A organização foi atualizada."
        },
        "ja": {
            "title": "名前",
            "text": "組織の名前を変更します。",
            "form.name": "名前",
            "form.submit": "名前を更新",
            "toast.success": "組織が更新されました。"
        }
    }
</i18n>
