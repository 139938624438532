<template>
    <Modal size="l" :title="t('title')" :open="open">
        <UTMCampaign component="TableEntries"
            :limitless="true"
            :showDetails="true"
            :visible="true" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import UTMCampaign from "@/components/statistics/UTMCampaign.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {entries_per_page, useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            UTMCampaign
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const statisticsStore = useStatisticsStore();
            const {filter} = storeToRefs(filterStore);
            const {loadUTMCampaign} = statisticsStore;

            watch(() => props.open, open => {
                if (open) {
                    const f = filterStore.copy(filter.value);
                    f.limit = entries_per_page;
                    loadUTMCampaign(f, false);
                }
            });

            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "UTM Campaigns"
        },
        "de": {
            "title": "UTM-Kampagnen"
        },
        "es": {
            "title": "Campañas UTM"
        },
        "fr": {
            "title": "Campagnes UTM"
        },
        "nl": {
            "title": "UTM-campagnes"
        },
        "it": {
            "title": "Campagne UTM"
        },
        "pt": {
            "title": "Campanhas UTM"
        },
        "ja": {
            "title": "UTMキャンペーン"
        }
    }
</i18n>
