<template>
    <Modal :title="t('title')" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="action">
            <FormSelect :label="t('form.kind')" name="kind" :options="deleteOptions" v-model="kind" />
            <FormInput :label="t('form.start')" name="start" type="date" v-model="startDate" />
            <FormInput :label="t('form.end')" name="end" type="date" v-model="endDate" />
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {dateParam} from "@/util/format";
    import dayjs from "dayjs";
    import {useError} from "@/components/error";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSelect,
            FormInput,
            FormSubmit
        },
        props: {
            link: Object
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {domain} = storeToRefs(useDomainStore());
            const {success} = useToastStore();
            const {resetError, setError} = useError();
            const loading = ref(false);
            const kind = ref("");
            const startDate = ref(dateParam(dayjs().subtract(7, "day").toDate()));
            const endDate = ref(dateParam(new Date));
            const deleteOptions = computed(() => [
                {label: t("options.delete.all"), value: ""},
                {label: t("options.delete.pirsch"), value: "all"},
                {label: t("options.delete.events"), value: "events"},
                {label: t("options.delete.imported"), value: "imported"}
            ]);

            async function action() {
                try {
                    loading.value = true;
                    resetError();
                    await StatisticsRepo.deleteData(domain.value.id, kind.value, dayjs(startDate.value).toDate(), dayjs(endDate.value).toDate());
                    loading.value = false;
                    success(t("toast.success"));
                    emit("close");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                deleteOptions,
                loading,
                kind,
                startDate,
                endDate,
                action
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Select Data to Delete",
            "text": "It may take a few minutes for all data to be deleted.",
            "form.kind": "Select data to delete",
            "form.start": "Start Date",
            "form.end": "End Date",
            "form.submit": "Delete Data",
            "options.delete.all": "All data",
            "options.delete.pirsch": "Data collected by Pirsch (excluding imported)",
            "options.delete.events": "Events only",
            "options.delete.imported": "Imported only",
            "toast.success": "The data has been deleted."
        },
        "de": {
            "title": "Zu löschende Daten auswählen",
            "text": "Es kann einige Minuten dauern, bis alle Daten gelöscht sind.",
            "form.kind": "Zu löschende Daten auswählen",
            "form.start": "Startdatum",
            "form.end": "Enddatum",
            "form.submit": "Daten löschen",
            "options.delete.all": "Alle Daten",
            "options.delete.pirsch": "Von Pirsch gesammelte Daten (außer importierte)",
            "options.delete.events": "Nur Events",
            "options.delete.imported": "Nur importierte Daten",
            "toast.success": "Die Daten wurden gelöscht."
        },
        "es": {
            "title": "Seleccionar datos a eliminar",
            "text": "Puede tardar unos minutos en eliminarse todos los datos.",
            "form.kind": "Seleccionar datos a eliminar",
            "form.start": "Fecha de inicio",
            "form.end": "Fecha de finalización",
            "form.submit": "Eliminar datos",
            "options.delete.all": "Todos los datos",
            "options.delete.pirsch": "Datos recopilados por Pirsch (excluyendo importados)",
            "options.delete.events": "Solo eventos",
            "options.delete.imported": "Solo importados",
            "toast.success": "Los datos han sido eliminados."
        },
        "fr": {
            "title": "Sélectionner les données à supprimer",
            "text": "La suppression de toutes les données peut prendre quelques minutes.",
            "form.kind": "Sélectionner les données à supprimer",
            "form.start": "Date de début",
            "form.end": "Date de fin",
            "form.submit": "Supprimer les données",
            "options.delete.all": "Toutes les données",
            "options.delete.pirsch": "Données collectées par Pirsch (à l'exclusion des importées)",
            "options.delete.events": "Événements uniquement",
            "options.delete.imported": "Importées uniquement",
            "toast.success": "Les données ont été supprimées."
        },
        "nl": {
            "title": "Selecteer gegevens om te verwijderen",
            "text": "Het kan enkele minuten duren voordat alle gegevens zijn verwijderd.",
            "form.kind": "Selecteer gegevens om te verwijderen",
            "form.start": "Begindatum",
            "form.end": "Einddatum",
            "form.submit": "Gegevens verwijderen",
            "options.delete.all": "Alle gegevens",
            "options.delete.pirsch": "Door Pirsch verzamelde gegevens (exclusief geïmporteerd)",
            "options.delete.events": "Alleen gebeurtenissen",
            "options.delete.imported": "Alleen geïmporteerd",
            "toast.success": "De gegevens zijn verwijderd."
        },
        "it": {
            "title": "Seleziona i dati da eliminare",
            "text": "Potrebbe volerci qualche minuto per eliminare tutti i dati.",
            "form.kind": "Seleziona i dati da eliminare",
            "form.start": "Data di inizio",
            "form.end": "Data di fine",
            "form.submit": "Elimina dati",
            "options.delete.all": "Tutti i dati",
            "options.delete.pirsch": "Dati raccolti da Pirsch (esclusi importati)",
            "options.delete.events": "Solo eventi",
            "options.delete.imported": "Solo importati",
            "toast.success": "I dati sono stati eliminati."
        },
        "pt": {
            "title": "Selecionar dados para excluir",
            "text": "Pode levar alguns minutos para que todos os dados sejam excluídos.",
            "form.kind": "Selecionar dados para excluir",
            "form.start": "Data de início",
            "form.end": "Data de término",
            "form.submit": "Excluir dados",
            "options.delete.all": "Todos os dados",
            "options.delete.pirsch": "Dados coletados por Pirsch (excluindo importados)",
            "options.delete.events": "Somente eventos",
            "options.delete.imported": "Somente importados",
            "toast.success": "Os dados foram excluídos."
        },
        "ja": {
            "title": "削除するデータを選択",
            "text": "すべてのデータを削除するには数分かかることがあります。",
            "form.kind": "削除するデータを選択",
            "form.start": "開始日",
            "form.end": "終了日",
            "form.submit": "データを削除",
            "options.delete.all": "すべてのデータ",
            "options.delete.pirsch": "Pirschが収集したデータ（インポートを除く）",
            "options.delete.events": "イベントのみ",
            "options.delete.imported": "インポートのみ",
            "toast.success": "データが削除されました。"
        }
    }
</i18n>
