import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {deleteAllCookies} from "@/util/cookie";
import Cookies from "js-cookie";
import Dashboard from "@/views/Dashboard.vue";
import Overview from "@/views/Overview.vue";
import AddWebsite from "@/views/AddWebsite.vue";
import Account from "@/views/Account.vue";
import NotFound from "@/views/NotFound.vue";
import Billing from "@/views/Billing.vue";
import DangerZoneSettings from "@/views/DangerZoneSettings.vue";
import GeneralSettings from "@/views/GeneralSettings.vue";
import AccessSettings from "@/views/AccessSettings.vue";
import GoalsEventsSettings from "@/views/GoalsSettings.vue";
import DeveloperSettings from "@/views/IntegrationSettings.vue";
import ReportSettings from "@/views/ReportSettings.vue";
import ImportExportSettings from "@/views/ImportExportSettings.vue";
import Affiliate from "@/views/Affiliate.vue";
import Themes from "@/views/Themes.vue";
import ThemeSettings from "@/views/ThemeSettings.vue";
import Login from "@/views/Login.vue";
import Password from "@/views/Password.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ConfirmAccount from "@/views/ConfirmAccount.vue";
import ConfirmEmail from "@/views/ConfirmEmail.vue";
import Organizations from "@/views/Organizations.vue";
import Organization from "@/views/Organization.vue";
import Sessions from "@/views/Sessions.vue";
import FilterSettings from "@/views/FilterSettings.vue";
import Funnel from "@/views/Funnel.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            loadStatistics: true,
            loadPanels: true,
            loadSessions: false
        }
    },
    {
        path: "/funnels",
        name: "Funnel",
        component: Funnel,
        meta: {
            loadStatistics: true,
            loadPanels: false,
            loadSessions: false
        }
    },
    {
        path: "/sessions",
        name: "Sessions",
        component: Sessions,
        meta: {
            loadStatistics: true,
            loadPanels: false,
            loadSessions: true
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            noRedirect: true
        }
    },
    {
        path: "/password",
        name: "Password",
        component: Password,
        meta: {
            noRedirect: true
        }
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        component: ResetPassword,
        meta: {
            noRedirect: true
        }
    },
    {
        path: "/confirm",
        name: "Confirm Account",
        component: ConfirmAccount,
        meta: {
            noRedirect: true
        }
    },
    {
        path: "/confirm-email",
        name: "Confirm Email",
        component: ConfirmEmail,
        meta: {
            noRedirect: true
        }
    },
    {
        path: "/overview",
        name: "Overview",
        component: Overview,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/add",
        name: "Add Website",
        component: AddWebsite,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings",
        name: "General Settings",
        component: GeneralSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/access",
        name: "Access Settings",
        component: AccessSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/reports",
        name: "Reports Settings",
        component: ReportSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/goals",
        name: "Goals Settings",
        component: GoalsEventsSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/integration",
        name: "Integration Settings",
        component: DeveloperSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/filter",
        name: "Filter Settings",
        component: FilterSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/import-export",
        name: "Import / Export Settings",
        component: ImportExportSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/theme",
        name: "Theme Settings",
        component: ThemeSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/settings/danger-zone",
        name: "Danger Zone Settings",
        component: DangerZoneSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/account",
        name: "Account",
        component: Account,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/organizations",
        name: "Organizations",
        component: Organizations,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/organization/:id",
        name: "Organization",
        component: Organization,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/themes",
        name: "Themes",
        component: Themes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/billing",
        name: "Billing",
        component: Billing,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/affiliate",
        name: "Affiliate",
        component: Affiliate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Not-Found",
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !Cookies.get("refresh_token")) {
        deleteAllCookies();
        localStorage.clear();
        location.href = "/login";
        return;
    }

    next();
});

export default router;
