import {User} from "@/model/User";
import {APIError} from "@/repositories/APIError";
import {Usage} from "@/model/Usage";
import {Repository} from "@/repositories/Repository";
import {Reward} from "@/model/Reward";

export interface RefreshTokenResponse {
    access_token: string
    refresh_token: string
    expires_at: Date
    reset_password: boolean
}

export interface CheckoutSessionResponse {
    session_id: string
}

export interface PortalSessionResponse {
    url: string
}

export interface Rewards {
    sum: number
    count: number
    rewards: Reward[]
}

export const UserRepo = new class extends Repository {
    async login(email: string, password: string, stay_logged_in: boolean): Promise<RefreshTokenResponse | APIError> {
        return this.performPost<RefreshTokenResponse>("/login", {email, password, stay_logged_in});
    }

    async resetPassword(email: string): Promise<APIError | null> {
        return this.performPost<null>("/password", {email});
    }

    async updatePassword(password: string, password_confirmation: string): Promise<APIError | null> {
        return this.performPut<null>("/password", {password, password_confirmation});
    }

    async confirmEmail(code: string): Promise<APIError | null> {
        return this.performPut<null>("/user/email", {code});
    }

    async lookupRegistrationCode(code: string): Promise<APIError | null> {
        return this.performGet<null>("/signup/code", {code}, null);
    }

    async completeRegistration(code: string, full_name: string, password: string, password_confirmation: string, accept_marketing: boolean, accept_terms_of_service: boolean): Promise<RefreshTokenResponse | APIError> {
        return this.performPut<RefreshTokenResponse>("/signup", {
            code,
            full_name,
            password,
            password_confirmation,
            accept_marketing,
            accept_terms_of_service
        });
    }

    async get(): Promise<User | null | APIError> {
        return this.performGet<User | null>("/user", null, null);
    }

    async toggleMarketing(): Promise<APIError | null> {
        return this.performPost<null>("/user/marketing", null);
    }

    async setDarkMode(dark_mode: string): Promise<APIError | null> {
        return this.performPost<null>("/user/darkmode", {dark_mode});
    }

    async setFilter(default_time_range: string): Promise<APIError | null> {
        return this.performPost<null>("/user/filter", {default_time_range});
    }

    async changeFullName(full_name: string): Promise<APIError | null> {
        return this.performPost<null>("/user/name", {full_name});
    }

    async changePassword(current_password: string, password: string, confirm_password: string): Promise<APIError | null> {
        return this.performPost<null>("/user/password", {current_password, password, confirm_password});
    }

    async changeEmail(email: string, password: string): Promise<APIError | null> {
        return this.performPost<null>("/user/email", {email, password});
    }

    async uploadPicture(file: File): Promise<APIError | null> {
        const form = new FormData();
        form.append("file", file);
        return this.performPost<null>("/user/picture", form);
    }

    async deletePicture(): Promise<APIError | null> {
        return this.performDelete("/user/picture", null);
    }

    async setLanguage(language: string): Promise<APIError | null> {
        return this.performPost<null>("/user/language", {language});
    }

    async deleteAccount(password: string, message: string): Promise<APIError | null> {
        return this.performPost<null>("/user/account", {password, message});
    }

    async cancelAccountDeletion(): Promise<APIError | null> {
        return this.performDelete("/user/account", null);
    }

    async setLimitWarning(percent: number): Promise<APIError | null> {
        return this.performPost<null>("/billing", {percent});
    }

    async getUsage(): Promise<Usage[] | APIError> {
        return this.performGet<Usage[]>("/billing", null, []);
    }

    async createCheckoutSession(plus: boolean, currency: string, billing_cycle: string, price_index: number): Promise<CheckoutSessionResponse | APIError> {
        return this.performPost<CheckoutSessionResponse>("/billing/checkout", {plus, currency, billing_cycle, price_index});
    }

    async createPortalSession(plus: boolean): Promise<PortalSessionResponse | APIError> {
        return this.performGet<PortalSessionResponse>("/billing/portal", {plus}, {
            url: ""
        });
    }

    async setFavoriteDomain(id: string): Promise<APIError | null> {
        return this.performPost<null>("/user/favorite", {id});
    }

    async pinDomain(id: string): Promise<APIError | null> {
        return this.performPost<null>("/user/pin", {id});
    }

    async toggleActiveVisitorsTitle(): Promise<APIError | null> {
        return this.performPost<null>("/user/active", null);
    }

    async toggleFullWidth(): Promise<APIError | null> {
        return this.performPost<null>("/user/fullwidth", null);
    }

    async logout(refresh_token?: string): Promise<APIError | null> {
        return this.performDelete("/logout", {refresh_token});
    }

    async getRewards(): Promise<Rewards | APIError> {
        return this.performGet<Rewards>("/user/rewards", null, {sum: 0, count: 0, rewards: []});
    }
}
