<template>
    <CreateEditConversionGoal :open="editGoalModal"
        v-on:close="editGoalModal = false"
        v-on:action="updateGoal"
        :goal="goal" />
    <ConfirmDeleteConversionGoal :open="deleteGoalModal"
        v-on:close="deleteGoalModal = false"
        v-on:action="removeGoal"
        :goal="goal" />
    <div class="card">
        <div class="text">
            <p>
                <strong>{{goal.name}}</strong>
            </p>
            <small>
                {{t("stats", {visitors: formatNumber(goal.visitor_goal || 0), cr: goal.cr_goal || 0})}}
                <template v-if="goal.email_reached">, {{t("email")}}</template>
                <template v-if="goal.delete_reached">, {{t("delete_reached")}}</template>
            </small>
        </div>
        <div class="row">
            <Lock :lock="`goal_${goal.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editGoalModal = true"></i>
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteGoalModal = true"></i>
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {ConversionGoal} from "@/model/ConversionGoal";
    import ConfirmDeleteConversionGoal from "@/components/modal/ConfirmDeleteConversionGoal.vue";
    import CreateEditConversionGoal from "@/components/modal/CreateEditConversionGoal.vue";
    import Lock from "@/components/settings/Lock.vue";
    import {formatAndShortenNumber} from "@/util/format";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmDeleteConversionGoal,
            CreateEditConversionGoal,
            Lock
        },
        props: {
            goal: {type: Object as PropType<ConversionGoal>, required: true}
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const editGoalModal = ref(false);
            const deleteGoalModal = ref(false);

            function updateGoal(goal: ConversionGoal) {
                editGoalModal.value = false;
                emit("update", goal);
            }

            function removeGoal() {
                deleteGoalModal.value = false;
                emit("remove", props.goal);
            }

            return {
                ...useI18n(),
                formatNumber: formatAndShortenNumber,
                editGoalModal,
                deleteGoalModal,
                updateGoal,
                removeGoal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "stats": "{visitors} Visitors, {cr}% CR",
            "email": "Email",
            "delete_reached": "Delete when reached",
            "edit": "Edit",
            "delete": "Delete"
        },
        "de": {
            "stats": "{visitors} Besucher, {cr}% CR",
            "email": "E-Mail",
            "delete_reached": "Löschen, wenn erreicht",
            "edit": "Bearbeiten",
            "delete": "Löschen"
        },
        "es": {
            "stats": "{visitors} Visitantes, {cr}% CR",
            "email": "Correo electrónico",
            "delete_reached": "Eliminar al alcanzar",
            "edit": "Editar",
            "delete": "Eliminar"
        },
        "fr": {
            "stats": "{visitors} Visiteurs, {cr}% CR",
            "email": "E-mail",
            "delete_reached": "Supprimer lorsque atteint",
            "edit": "Éditer",
            "delete": "Supprimer"
        },
        "nl": {
            "stats": "{visitors} Bezoekers, {cr}% CR",
            "email": "E-mail",
            "delete_reached": "Verwijderen bij bereiken",
            "edit": "Bewerken",
            "delete": "Verwijderen"
        },
        "it": {
            "stats": "{visitors} Visitatori, {cr}% CR",
            "email": "E-mail",
            "delete_reached": "Elimina al raggiungimento",
            "edit": "Modifica",
            "delete": "Elimina"
        },
        "pt": {
            "stats": "{visitors} Visitantes, {cr}% CR",
            "email": "E-mail",
            "delete_reached": "Excluir quando alcançado",
            "edit": "Editar",
            "delete": "Excluir"
        },
        "ja": {
            "stats": "{visitors} 訪問者, {cr}% CR",
            "email": "メール",
            "delete_reached": "到達時に削除",
            "edit": "編集",
            "delete": "削除"
        }
    }
</i18n>
