<template>
    <div :class="{'banner': true, 'green': color === 'green', 'orange': color === 'orange'}">
        <i class="icon icon-error"></i>
        <p><slot></slot></p>
        <i class="icon icon-close" v-on:click="$emit('dismiss')"></i>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        props: {
            color: {type: String, default: "orange"}
        },
        emits: ["dismiss"]
    });
</script>
