<template>
    <fieldset class="range">
        <input type="range"
            :id="name"
            :name="name"
            :value="modelValue"
            v-on:input="update"
            v-on:change.stop.prevent="change"
            :min="min"
            :max="max"
            :step="step"
            ref="input" />
        <label :for="name">{{label}}</label>
        <p v-show="modelValue">{{modelValue}}{{suffix}}</p>
        <p class="default" v-show="!modelValue">{{defaultValue}}{{suffix}}</p>
        <i class="icon icon-close clickable" v-on:click.stop.prevent="reset" v-show="modelValue"></i>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";

    export default defineComponent({
        props: {
            label: String,
            name: {type: String, required: true},
            min: {type: Number, required: true},
            max: {type: Number, required: true},
            step: {type: Number, required: true},
            suffix: String,
            defaultValue: String,
            modelValue: String
        },
        emits: ["update:modelValue", "change"],
        setup(props, {emit}) {
            const input = ref(document.createElement("input"));
            const isDefault = ref(true);

            onMounted(() => isDefault.value = !!props.modelValue);

            function update(e: Event) {
                if (e && e.target) {
                    emit("update:modelValue", (e.target as HTMLInputElement).value);
                }
            }

            function change(e: Event) {
                if (e && e.target) {
                    emit("change", (e.target as HTMLInputElement).value);
                }
            }

            function reset() {
                emit("update:modelValue", "");
                isDefault.value = true;
            }

            return {
                input,
                isDefault,
                update,
                change,
                reset
            };
        }
    });
</script>
