<template>
    <fieldset>
        <select :id="name"
            :name="name"
            :value="modelValue"
            v-on:input="update"
            :disabled="disabled"
            ref="select">
            <option v-for="option in options" :key="option.value" :value="option.value">{{option.label}}</option>
        </select>
        <label for="name">{{label}}</label>
        <small v-if="hint" v-html="hint"></small>
        <small class="error" v-if="err">{{err}}</small>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent, onMounted, PropType, ref} from "vue";
    import {SelectOption} from "@/components/form/SelectOption";

    export default defineComponent({
        props: {
            label: String,
            options: {type: Array as PropType<SelectOption[]>, required: true},
            name: {type: String, required: true},
            disabled: {type: Boolean, default: false},
            autofocus: {type: Boolean, default: false},
            modelValue: [String, Number],
            hint: String,
            err: String
        },
        emits: ["update:modelValue"],
        setup(props, {emit}) {
            const select = ref(document.createElement("select"));

            onMounted(() => {
                if (props.autofocus) {
                    select.value.focus();
                }
            });

            function update(e: Event) {
                if (e && e.target) {
                    emit("update:modelValue", (e.target as HTMLInputElement).value);
                }
            }

            return {
                select,
                update
            };
        }
    });
</script>
