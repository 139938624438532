<template>
    <div class="list">
        <ListTabs :tabs="tabs" v-on:enlarge="enlarge" v-on:open="openTab" />
        <Referrer :visible="open === 0" v-show="open === 0" />
        <UTMSource :visible="open === 1" v-show="open === 1" />
        <UTMMedium :visible="open === 2" v-show="open === 2" />
        <UTMCampaign :visible="open === 3" v-show="open === 3" />
        <UTMContent :visible="open === 4" v-show="open === 4" />
        <UTMTerm :visible="open === 5" v-show="open === 5" />
        <ReferrerDetails :open="details === 0" v-on:close="details = -1" />
        <UTMSourceDetails :open="details === 1" v-on:close="details = -1" />
        <UTMMediumDetails :open="details === 2" v-on:close="details = -1" />
        <UTMCampaignDetails :open="details === 3" v-on:close="details = -1" />
        <UTMContentDetails :open="details === 4" v-on:close="details = -1" />
        <UTMTermDetails :open="details === 5" v-on:close="details = -1" />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import Referrer from "@/components/statistics/Referrer.vue"
    import ReferrerDetails from "@/components/statistics/ReferrerDetails.vue"
    import UTMSource from "@/components/statistics/UTMSource.vue";
    import UTMMedium from "@/components/statistics/UTMMedium.vue";
    import UTMCampaign from "@/components/statistics/UTMCampaign.vue";
    import UTMSourceDetails from "@/components/statistics/UTMSourceDetails.vue";
    import UTMMediumDetails from "@/components/statistics/UTMMediumDetails.vue";
    import UTMCampaignDetails from "@/components/statistics/UTMCampaignDetails.vue";
    import UTMContent from "@/components/statistics/UTMContent.vue";
    import UTMTerm from "@/components/statistics/UTMTerm.vue";
    import UTMContentDetails from "@/components/statistics/UTMContentDetails.vue";
    import UTMTermDetails from "@/components/statistics/UTMTermDetails.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ListTabs,
            Referrer,
            ReferrerDetails,
            UTMTermDetails,
            UTMContentDetails,
            UTMTerm,
            UTMContent,
            UTMSource,
            UTMMedium,
            UTMCampaign,
            UTMSourceDetails,
            UTMMediumDetails,
            UTMCampaignDetails
        },
        setup() {
            const {t} = useI18n();
            const open = ref(0);
            const details = ref(-1);
            const {hasField} = useFilterStore();
            const utmFilter = computed(() => hasField("utm_source") ||
                hasField("utm_medium") ||
                hasField("utm_campaign") ||
                hasField("utm_content") ||
                hasField("utm_term"));
            const tabs = computed(() => {
                const tabs = [
                    {
                        title: t("tabs.referrers"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.sources"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.media"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.campaigns"),
                        showEnlarge: true
                    }
                ]

                if (utmFilter.value) {
                    tabs.push({
                        title: t("tabs.content"),
                        showEnlarge: true
                    }, {
                        title: t("tabs.terms"),
                        showEnlarge: true
                    });
                }

                return tabs;
            });

            watch(utmFilter, () => {
                if (!utmFilter.value && open.value > 3) {
                    open.value = 0;
                }
            });

            function enlarge(index: number) {
                details.value = index;
            }

            function openTab(index: number) {
                open.value = index;
            }

            return {
                tabs,
                open,
                details,
                utmFilter,
                enlarge,
                openTab
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "tabs.referrers": "Referrers",
            "tabs.sources": "Sources",
            "tabs.media": "Media",
            "tabs.campaigns": "Campaigns",
            "tabs.content": "Content",
            "tabs.terms": "Terms"
        },
        "de": {
            "tabs.referrers": "Referrer",
            "tabs.sources": "Quellen",
            "tabs.media": "Medien",
            "tabs.campaigns": "Kampagnen",
            "tabs.content": "Inhalte",
            "tabs.terms": "Begriffe"
        },
        "es": {
            "tabs.referrers": "Referentes",
            "tabs.sources": "Fuentes",
            "tabs.media": "Medios",
            "tabs.campaigns": "Campañas",
            "tabs.content": "Contenido",
            "tabs.terms": "Términos"
        },
        "fr": {
            "tabs.referrers": "Référents",
            "tabs.sources": "Sources",
            "tabs.media": "Médias",
            "tabs.campaigns": "Campagnes",
            "tabs.content": "Contenu",
            "tabs.terms": "Termes"
        },
        "nl": {
            "tabs.referrers": "Verwijzers",
            "tabs.sources": "Bronnen",
            "tabs.media": "Media",
            "tabs.campaigns": "Campagnes",
            "tabs.content": "Inhoud",
            "tabs.terms": "Termen"
        },
        "it": {
            "tabs.referrers": "Referrer",
            "tabs.sources": "Fonti",
            "tabs.media": "Media",
            "tabs.campaigns": "Campagne",
            "tabs.content": "Contenuti",
            "tabs.terms": "Termini"
        },
        "pt": {
            "tabs.referrers": "Referenciadores",
            "tabs.sources": "Fontes",
            "tabs.media": "Mídia",
            "tabs.campaigns": "Campanhas",
            "tabs.content": "Conteúdo",
            "tabs.terms": "Termos"
        },
        "ja": {
            "tabs.referrers": "リファラー",
            "tabs.sources": "ソース",
            "tabs.media": "メディア",
            "tabs.campaigns": "キャンペーン",
            "tabs.content": "コンテンツ",
            "tabs.terms": "用語"
        }
    }
</i18n>
