<template>
    <Banner v-on:dismiss="dismiss" v-if="!dismissed && paymentFailed">
        {{t("text")}} <router-link to="/billing">{{t("link")}}</router-link>.
    </Banner>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import Banner from "@/components/bits/Banner.vue";
    import dayjs from "dayjs";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Banner
        },
        setup() {
            const {user} = storeToRefs(useUserStore());
            const paymentFailed = computed(() => user.value && user.value.last_payment_failed);
            const dismissed = ref(true);

            onMounted(() => {
                const date = localStorage.getItem("dismiss_payment_failed");

                if (date) {
                    const day = dayjs(date);

                    if (day.isBefore(dayjs())) {
                        localStorage.removeItem("dismiss_payment_failed");
                    } else {
                        return;
                    }
                }

                dismissed.value = false;
            });

            function dismiss() {
                localStorage.setItem("dismiss_payment_failed", dayjs().add(dayjs.duration(1, "day")).toISOString());
                dismissed.value = true;
            }

            return {
                ...useI18n(),
                paymentFailed,
                dismissed,
                dismiss
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "text": "Your last payment failed. Please verify your payment information on the",
            "link": "billing page"
        },
        "de": {
            "text": "Deine letzte Zahlung ist fehlgeschlagen. Bitte überprüfe deine Zahlungsinformationen auf der",
            "link": "Abrechnungsseite"
        },
        "es": {
            "text": "Tu último pago falló. Por favor, verifica tu información de pago en la",
            "link": "página de facturación"
        },
        "fr": {
            "text": "Votre dernier paiement a échoué. Veuillez vérifier vos informations de paiement sur la",
            "link": "page de facturation"
        },
        "nl": {
            "text": "Je laatste betaling is mislukt. Controleer je betalingsgegevens op de",
            "link": "facturatiepagina"
        },
        "it": {
            "text": "Il tuo ultimo pagamento non è andato a buon fine. Verifica le tue informazioni di pagamento sulla",
            "link": "pagina di fatturazione"
        },
        "pt": {
            "text": "Seu último pagamento falhou. Por favor, verifique suas informações de pagamento na",
            "link": "página de faturamento"
        },
        "ja": {
            "text": "最後の支払いに失敗しました。支払い情報を確認してください",
            "link": "請求ページ"
        }
    }
</i18n>
