import {APIError} from "@/repositories/APIError";
import {Invitation} from "@/model/Invitation";
import {Repository} from "@/repositories/Repository";

export const InvitationRepo = new class extends Repository {
    async list(domain_id?: string, organization_id?: string): Promise<Invitation[] | APIError> {
        return this.performGet<Invitation[]>("/invitation", {domain_id, organization_id}, []);
    }

    async accept(id: string): Promise<APIError | null> {
        return this.performPost<null>("/invitation", {id});
    }

    async delete(id: string, domain_id?: string, organization_id?: string): Promise<APIError | null> {
        return this.performDelete("/invitation", {id, domain_id, organization_id});
    }
}
