<template>
    <div :class="{growth: true, green: color === 'green', yellow: color === 'yellow', red: color === 'red', reversed: !arrowUpGreen}"
         :title="formatNumber(growth)"
         v-if="growth !== undefined && !liveFilter">
        <div class="arrow up" v-if="growth > 0"></div>
        <div class="arrow right" v-if="growth === 0"></div>
        <div class="arrow down" v-if="growth < 0"></div>
        <div class="percentage">{{formatPercent(growth)}}%</div>
    </div>
</template>

<script>
    import {computed, defineComponent} from "vue";
    import {formatNumber, formatPercent} from "@/util/format";

    export default defineComponent({
        props: {
            growth: Number,
            arrowUpGreen: {type: Boolean, default: true},
            liveFilter: {type: Boolean, default: false}
        },
        setup(props) {
            const color = computed(() => {
                if (props.growth === undefined) {
                    return "green";
                }

                if (props.growth === 0) {
                    return "yellow";
                }

                if (!props.arrowUpGreen) {
                    return props.growth < 0 ? "green" : "red";
                }

                return props.growth > 0 ? "green" : "red";
            });

            return {
                formatNumber,
                formatPercent,
                color
            };
        }
    });
</script>
