<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {UserRepo} from "@/repositories/UserRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {load} = useUserStore();
            const {success} = useToastStore();
            const {resetError, setError, validationError, err} = useError();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await UserRepo.cancelAccountDeletion();
                    await load();
                    loading.value = false;
                    success(t("toast.success"));
                    emit("close");
                } catch(e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Cancel Account Deletion",
            "text": "Would you like to cancel your account deletion? You need to manually recreate your subscription afterwards.",
            "form.submit": "Cancel Deletion",
            "toast.success": "Your account is no longer marked for deletion."
        },
        de": {
            "title": "Kontolöschung abbrechen",
            "text": "Möchtest du deine Kontolöschung abbrechen? Du musst danach dein Abonnement manuell neu erstellen.",
            "form.submit": "Löschung abbrechen",
            "toast.success": "Dein Konto ist nicht mehr zur Löschung markiert."
        },
        "es": {
            "title": "Cancelar eliminación de cuenta",
            "text": "¿Deseas cancelar la eliminación de tu cuenta? Debes recrear manualmente tu suscripción después.",
            "form.submit": "Cancelar eliminación",
            "toast.success": "Tu cuenta ya no está marcada para eliminación."
        },
        "fr": {
            "title": "Annuler la suppression du compte",
            "text": "Souhaitez-vous annuler la suppression de votre compte ? Vous devrez recréer manuellement votre abonnement par la suite.",
            "form.submit": "Annuler la suppression",
            "toast.success": "Votre compte n'est plus marqué pour suppression."
        },
        "nl": {
            "title": "Accountverwijdering annuleren",
            "text": "Wil je de verwijdering van je account annuleren? Je moet daarna je abonnement handmatig opnieuw aanmaken.",
            "form.submit": "Verwijdering annuleren",
            "toast.success": "Je account is niet langer gemarkeerd voor verwijdering."
        },
        "it": {
            "title": "Annulla eliminazione account",
            "text": "Vuoi annullare l'eliminazione del tuo account? Dovrai ricreare manualmente il tuo abbonamento successivamente.",
            "form.submit": "Annulla eliminazione",
            "toast.success": "Il tuo account non è più contrassegnato per l'eliminazione."
        },
        "pt": {
            "title": "Cancelar a exclusão da conta",
            "text": "Deseja cancelar a exclusão da sua conta? Você precisará recriar manualmente sua assinatura depois.",
            "form.submit": "Cancelar exclusão",
            "toast.success": "Sua conta não está mais marcada para exclusão."
        },
        "ja": {
            "title": "アカウント削除をキャンセル",
            "text": "アカウント削除をキャンセルしますか？その後、手動でサブスクリプションを再作成する必要があります。",
            "form.submit": "削除をキャンセル",
            "toast.success": "あなたのアカウントは削除対象ではなくなりました。"
        }
    }
</i18n>
