<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
        </div>
        <div>
            <p>{{t("text")}}</p>
            <button class="red" v-on:click="logout" style="margin-top: 12px;">{{t("sign_out")}}</button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            return {
                ...useI18n(),
                ...useUserStore()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Log Out Everywhere",
            "text": "This will log you out of all devices you have logged into in the past.",
            "sign_out": "Log Out Everywhere"
        },
        "de": {
            "title": "Überall abmelden",
            "text": "Dadurch wirst du auf allen Geräten abgemeldet, auf denen du dich in der Vergangenheit angemeldet hast.",
            "sign_out": "Überall abmelden"
        },
        "es": {
            "title": "Cerrar sesión en todas partes",
            "text": "Esto cerrará tu sesión en todos los dispositivos en los que has iniciado sesión en el pasado.",
            "sign_out": "Cerrar sesión en todas partes"
        },
        "fr": {
            "title": "Se déconnecter de partout",
            "text": "Cela vous déconnectera de tous les appareils sur lesquels vous vous êtes connecté dans le passé.",
            "sign_out": "Se déconnecter de partout"
        },
        "nl": {
            "title": "Overal uitloggen",
            "text": "Hierdoor wordt je afgemeld van alle apparaten waarop je in het verleden bent ingelogd.",
            "sign_out": "Overal uitloggen"
        },
        "it": {
            "title": "Disconnetti ovunque",
            "text": "Questo ti disconnetterà da tutti i dispositivi su cui hai effettuato l'accesso in passato.",
            "sign_out": "Disconnetti ovunque"
        },
        "pt": {
            "title": "Sair de todos os lugares",
            "text": "Isso fará com que você saia de todos os dispositivos em que fez login no passado.",
            "sign_out": "Sair de todos os lugares"
        },
        "ja": {
            "title": "すべての場所からログアウト",
            "text": "これにより、過去にログインしたすべてのデバイスからログアウトされます。",
            "sign_out": "すべての場所からログアウト"
        }
    }
</i18n>
