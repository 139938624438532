<template>
    <div class="tabs" style="margin-top: 4px; margin-bottom: 0;">
        <h2 :class="{active: activeTab === 'light'}" v-on:click="activeTab = 'light'">
            {{t("tab.light")}}
        </h2>
        <h2 :class="{active: activeTab === 'dark'}" v-on:click="activeTab = 'dark'">
            {{t("tab.dark")}}
        </h2>
    </div>
    <div v-show="activeTab === 'light'" style="display: flex; flex-direction: column; gap: 12px;">
        <h5>{{t("title.main_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('light.primary')" name="lightColorPrimary" v-model="lightColorPrimary" :default-value="defaults[ThemeSettings.lightColorPrimary]" />
            <FormColor :label="t('light.secondary')" name="lightColorSecondary" v-model="lightColorSecondary" :default-value="defaults[ThemeSettings.lightColorSecondary]" />
        </div>
        <h5>{{t("title.background_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('light.primary_bg')" name="lightColorBackgroundPrimary" v-model="lightColorBackgroundPrimary" :default-value="defaults[ThemeSettings.lightColorBackgroundPrimary]" />
            <FormColor :label="t('light.secondary_bg')" name="lightColorBackgroundSecondary" v-model="lightColorBackgroundSecondary" :default-value="defaults[ThemeSettings.lightColorBackgroundSecondary]" />
        </div>
        <h5>{{t("title.button_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('light.button')" name="lightColorButton" v-model="lightColorButton" :default-value="defaults[ThemeSettings.lightColorButton]" />
            <FormColor :label="t('light.button_bg')" name="lightColorBackgroundButton" v-model="lightColorBackgroundButton" :default-value="defaults[ThemeSettings.lightColorBackgroundButton]" />
        </div>
        <h5>{{t("title.additional_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('light.accent')" name="lightColorAccent" v-model="lightColorAccent" :default-value="defaults[ThemeSettings.lightColorAccent]" />
            <FormColor :label="t('light.green')" name="lightColorGreen" v-model="lightColorGreen" :default-value="defaults[ThemeSettings.lightColorGreen]" />
            <FormColor :label="t('light.yellow')" name="lightColorYellow" v-model="lightColorYellow" :default-value="defaults[ThemeSettings.lightColorYellow]" />
            <FormColor :label="t('light.orange')" name="lightColorOrange" v-model="lightColorOrange" :default-value="defaults[ThemeSettings.lightColorOrange]" />
            <FormColor :label="t('light.red')" name="lightColorRed" v-model="lightColorRed" :default-value="defaults[ThemeSettings.lightColorRed]" />
        </div>
        <h5>{{t("title.box_shadows")}}</h5>
        <div class="grid">
            <FormInput :label="t('light.primary_shadow')" name="lightBoxShadowPrimary" v-model="lightBoxShadowPrimary" :placeholder="defaults[ThemeSettings.lightBoxShadowPrimary]" />
            <FormInput :label="t('light.secondary_shadow')" name="lightBoxShadowSecondary" v-model="lightBoxShadowSecondary" :placeholder="defaults[ThemeSettings.lightBoxShadowSecondary]" />
        </div>
    </div>
    <div v-show="activeTab === 'dark'" style="display: flex; flex-direction: column; gap: 12px;">
        <h5>{{t("title.main_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('dark.primary')" name="darkColorPrimary" v-model="darkColorPrimary" :default-value="defaults[ThemeSettings.darkColorPrimary]" />
            <FormColor :label="t('dark.secondary')" name="darkColorSecondary" v-model="darkColorSecondary" :default-value="defaults[ThemeSettings.darkColorSecondary]" />
        </div>
        <h5>{{t("title.background_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('dark.primary_bg')" name="darkColorBackgroundPrimary" v-model="darkColorBackgroundPrimary" :default-value="defaults[ThemeSettings.darkColorBackgroundPrimary]" />
            <FormColor :label="t('dark.secondary_bg')" name="darkColorBackgroundSecondary" v-model="darkColorBackgroundSecondary" :default-value="defaults[ThemeSettings.darkColorBackgroundSecondary]" />
        </div>
        <h5>{{t("title.button_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('dark.button')" name="darkColorButton" v-model="darkColorButton" :default-value="defaults[ThemeSettings.darkColorButton]" />
            <FormColor :label="t('dark.button_bg')" name="darkColorBackgroundButton" v-model="darkColorBackgroundButton" :default-value="defaults[ThemeSettings.darkColorBackgroundButton]" />
        </div>
        <h5>{{t("title.additional_colors")}}</h5>
        <div class="grid">
            <FormColor :label="t('dark.accent')" name="darkColorAccent" v-model="darkColorAccent" :default-value="defaults[ThemeSettings.darkColorAccent]" />
            <FormColor :label="t('dark.green')" name="darkColorGreen" v-model="darkColorGreen" :default-value="defaults[ThemeSettings.darkColorGreen]" />
            <FormColor :label="t('dark.yellow')" name="darkColorYellow" v-model="darkColorYellow" :default-value="defaults[ThemeSettings.darkColorYellow]" />
            <FormColor :label="t('dark.orange')" name="darkColorOrange" v-model="darkColorOrange" :default-value="defaults[ThemeSettings.darkColorOrange]" />
            <FormColor :label="t('dark.red')" name="darkColorRed" v-model="darkColorRed" :default-value="defaults[ThemeSettings.darkColorRed]" />
        </div>
        <h5>{{t("title.box_shadows")}}</h5>
        <div class="grid">
            <FormInput :label="t('dark.primary_shadow')" name="darkBoxShadowPrimary" v-model="darkBoxShadowPrimary" :placeholder="defaults[ThemeSettings.darkBoxShadowPrimary]" />
            <FormInput :label="t('dark.secondary_shadow')" name="darkBoxShadowSecondary" v-model="darkBoxShadowSecondary" :placeholder="defaults[ThemeSettings.darkBoxShadowSecondary]" />
        </div>
    </div>
    <template v-if="!disableFiles">
        <h5>{{t("title.logos")}}</h5>
        <ThemeImg :label="t('logo.light')"
            name="logo_light_file"
            :settings="modelValue"
            :settings-variable="logoLightVar"
            v-model="logoLight"
            :err="err"
            v-on:delete-img="name => $emit('deleteImg', name)" />
        <ThemeImg :label="t('logo.dark')"
            name="logo_dark_file"
            :settings="modelValue"
            :settings-variable="logoDarkVar"
            v-model="logoDark"
            :err="err"
            v-on:delete-img="name => $emit('deleteImg', name)" />
        <ThemeImg :label="t('logo.favicon')"
            name="favicon_file"
            :settings="modelValue"
            :settings-variable="faviconVar"
            v-model="favicon"
            :err="err"
            v-on:delete-img="name => $emit('deleteImg', name)" />
    </template>
    <h5>{{t("title.border_radius")}}</h5>
    <div class="grid">
        <FormRange :label="t('border.radius')" name="borderRadiusPrimary" :max="24" :min="0" :step="4" suffix="px" defaultValue="12" v-model="borderRadiusPrimary" />
        <small>{{t("border.hint")}}</small>
    </div>
    <h5>{{t("title.page")}}</h5>
    <div class="grid">
        <FormInput :label="t('page.title')" name="title" v-model="title" />
        <FormInput :label="t('page.meta_description')" name="description" v-model="description" />
        <FormSelect :label="t('page.resources')" name="hideResources" :options="hideResourcesOptions" v-model="hideResources" />
        <FormSelect :label="t('page.mode')" name="mode" :options="modeOptions" v-model="mode" />
        <div style="display: flex; flex-direction: column; gap: 12px; margin: 12px 0;">
            <FormCheckbox :label="t('page.login')" name="hideLogin" v-model="hideLogin" />
            <FormCheckbox :label="t('page.footer')" name="hideFooter" v-model="hideFooter" />
            <FormCheckbox :label="t('page.copyright')" name="hideCopyright" v-model="hideCopyright" />
            <FormCheckbox :label="t('page.toggle_mode')" name="hideDarkModeToggle" v-model="hideDarkModeToggle" />
            <FormCheckbox :label="t('page.language_switch')" name="hideLanguageSwitch" v-model="hideLanguageSwitch" />
        </div>
    </div>
    <h5>{{t("title.theme_code")}}</h5>
    <div class="input-with-icon">
        <FormInput :label="t('theme.code')" name="theme" v-model="themeString" v-on:keyup="pasteTheme" />
        <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyTheme"></i>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, PropType, ref, watch} from "vue";
    import FormColor from "@/components/form/FormColor.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import ThemeImg from "@/components/themes/ThemeImg.vue";
    import {KeyValue} from "@/model/KeyValue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormRange from "@/components/form/FormRange.vue";
    import copy from "copy-to-clipboard";
    import {useToastStore} from "@/store/ToastStore";
    import FormSelect from "@/components/form/FormSelect.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useI18n} from "vue-i18n";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        components: {
            FormCheckbox,
            FormSelect,
            ThemeImg,
            FormColor,
            FormInput,
            FormRange
        },
        props: {
            modelValue: {type: Object as PropType<KeyValue>, required: true},
            err: Object as PropType<APIError>,
            defaults: {type: Object as PropType<KeyValue>, required: true},
            disableFiles: {type: Boolean, defaults: false}
        },
        emits: ["update:modelValue", "logoLight", "logoDark", "favicon", "deleteImg"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const activeTab = ref("light");
            const logoLight = ref();
            const logoDark = ref();
            const favicon = ref();
            const lightColorPrimary = ref("");
            const darkColorPrimary = ref("");
            const lightColorSecondary = ref("");
            const darkColorSecondary = ref("");
            const lightColorBackgroundPrimary = ref("");
            const darkColorBackgroundPrimary = ref("");
            const lightColorBackgroundSecondary = ref("");
            const darkColorBackgroundSecondary = ref("");
            const lightColorButton = ref("");
            const darkColorButton = ref("");
            const lightColorBackgroundButton = ref("");
            const darkColorBackgroundButton = ref("");
            const lightColorAccent = ref("");
            const darkColorAccent = ref("");
            const lightColorGreen = ref("");
            const darkColorGreen = ref("");
            const lightColorYellow = ref("");
            const darkColorYellow = ref("");
            const lightColorOrange = ref("");
            const darkColorOrange = ref("");
            const lightColorRed = ref("");
            const darkColorRed = ref("");
            const lightBoxShadowPrimary = ref("");
            const lightBoxShadowSecondary = ref("");
            const darkBoxShadowPrimary = ref("");
            const darkBoxShadowSecondary = ref("");
            const borderRadiusPrimary = ref("");
            const title = ref("");
            const description = ref("");
            const hideResources = ref("false");
            const hideLogin = ref(false);
            const hideFooter = ref(false);
            const hideCopyright = ref(false);
            const hideDarkModeToggle = ref(false);
            const hideLanguageSwitch = ref(false);
            const mode = ref("");
            const themeString = computed(() => {
                const out = [];

                if (props.modelValue) {
                    for (const [key, value] of Object.entries(props.modelValue)) {
                        if (value) {
                            const k = Object.entries(ThemeSettings).find(e => e[1] === key);
                            out.push(`${k ? k[0] : ""}:${value}`);
                        }
                    }
                }

                return out.join(";");
            });
            const hideResourcesOptions = computed(() => [
                {label: t("options.resources.settings"), value: ""},
                {label: t("options.resources.hide"), value: "true"},
                {label: t("options.resources.show"), value: "false"}
            ]);
            const modeOptions = computed(() => [
                {label: t("options.mode.system"), value: ""},
                {label: t("options.mode.light"), value: "light"},
                {label: t("options.mode.dark"), value: "dark"}
            ]);

            onMounted(updateSettings);
            watch(() => props.modelValue, updateSettings);

            function updateSettings() {
                if (props.modelValue && Object.keys(props.modelValue).length) {
                    setSettings(props.modelValue ?? {});
                }
            }

            watch(() => props.err, err => {
                if (err) {
                    setError(err);
                } else {
                    resetError();
                }
            });

            watch([
                lightColorPrimary,
                darkColorPrimary,
                lightColorSecondary,
                darkColorSecondary,
                lightColorBackgroundPrimary,
                darkColorBackgroundPrimary,
                lightColorBackgroundSecondary,
                darkColorBackgroundSecondary,
                lightColorButton,
                darkColorButton,
                lightColorBackgroundButton,
                darkColorBackgroundButton,
                lightColorAccent,
                darkColorAccent,
                lightColorGreen,
                darkColorGreen,
                lightColorYellow,
                darkColorYellow,
                lightColorOrange,
                darkColorOrange,
                lightColorRed,
                darkColorRed,
                borderRadiusPrimary,
                lightBoxShadowPrimary,
                lightBoxShadowSecondary,
                darkBoxShadowPrimary,
                darkBoxShadowSecondary,
                borderRadiusPrimary,
                title,
                description,
                hideResources,
                hideLogin,
                hideFooter,
                hideCopyright,
                hideDarkModeToggle,
                hideLanguageSwitch,
                mode
            ], () => emit("update:modelValue", getSettings(props.modelValue ?? {})));

            watch(logoLight, () => emit("logoLight", logoLight.value));
            watch(logoDark, () => emit("logoDark", logoDark.value));
            watch(favicon, () => emit("favicon", favicon.value));

            function copyTheme() {
                copy(themeString.value);
                success(t("toast.clipboard"));
            }

            function pasteTheme(value: string) {
                try {
                    value = value.trim();

                    if (value) {
                        const settings = {};
                        const parts = value.split(";");

                        for (const part of parts) {
                            const kv = part.split(":");

                            if (kv.length !== 2) {
                                continue;
                            }

                            const v = Object.entries(ThemeSettings).find(e => e[0] === kv[0]);

                            if (v) {
                                settings[v[1]] = kv[1];
                            }
                        }

                        setSettings(settings);
                    }
                } catch {
                    error(t("toast.error"));
                }
            }

            function setSettings(settings: KeyValue) {
                lightColorPrimary.value = settings[ThemeSettings.lightColorPrimary];
                darkColorPrimary.value = settings[ThemeSettings.darkColorPrimary];
                lightColorSecondary.value = settings[ThemeSettings.lightColorSecondary];
                darkColorSecondary.value = settings[ThemeSettings.darkColorSecondary];
                lightColorBackgroundPrimary.value = settings[ThemeSettings.lightColorBackgroundPrimary];
                darkColorBackgroundPrimary.value = settings[ThemeSettings.darkColorBackgroundPrimary];
                lightColorBackgroundSecondary.value = settings[ThemeSettings.lightColorBackgroundSecondary];
                darkColorBackgroundSecondary.value = settings[ThemeSettings.darkColorBackgroundSecondary];
                lightColorButton.value = settings[ThemeSettings.lightColorButton];
                darkColorButton.value = settings[ThemeSettings.darkColorButton];
                lightColorBackgroundButton.value = settings[ThemeSettings.lightColorBackgroundButton];
                darkColorBackgroundButton.value = settings[ThemeSettings.darkColorBackgroundButton];
                lightColorAccent.value = settings[ThemeSettings.lightColorAccent];
                darkColorAccent.value = settings[ThemeSettings.darkColorAccent];
                lightColorGreen.value = settings[ThemeSettings.lightColorGreen];
                darkColorGreen.value = settings[ThemeSettings.darkColorGreen];
                lightColorYellow.value = settings[ThemeSettings.lightColorYellow];
                darkColorYellow.value = settings[ThemeSettings.darkColorYellow];
                lightColorOrange.value = settings[ThemeSettings.lightColorOrange];
                darkColorOrange.value = settings[ThemeSettings.darkColorOrange];
                lightColorRed.value = settings[ThemeSettings.lightColorRed];
                darkColorRed.value = settings[ThemeSettings.darkColorRed];
                lightBoxShadowPrimary.value = settings[ThemeSettings.lightBoxShadowPrimary];
                lightBoxShadowSecondary.value = settings[ThemeSettings.lightBoxShadowSecondary];
                darkBoxShadowPrimary.value = settings[ThemeSettings.darkBoxShadowPrimary];
                darkBoxShadowSecondary.value = settings[ThemeSettings.darkBoxShadowSecondary];
                borderRadiusPrimary.value = settings[ThemeSettings.borderRadiusPrimary] && settings[ThemeSettings.borderRadiusPrimary].length > 2 ? settings[ThemeSettings.borderRadiusPrimary].slice(0, -2) : "";
                title.value = settings[ThemeSettings.title];
                description.value = settings[ThemeSettings.description];
                hideResources.value = settings[ThemeSettings.hideResources];
                hideLogin.value = settings[ThemeSettings.hideLogin] !== undefined && settings[ThemeSettings.hideLogin].toLowerCase() === "true";
                hideFooter.value = settings[ThemeSettings.hideFooter] !== undefined && settings[ThemeSettings.hideFooter].toLowerCase() === "true";
                hideCopyright.value = settings[ThemeSettings.hideCopyright] !== undefined && settings[ThemeSettings.hideCopyright].toLowerCase() === "true";
                hideDarkModeToggle.value = settings[ThemeSettings.hideDarkModeToggle] !== undefined && settings[ThemeSettings.hideDarkModeToggle].toLowerCase() === "true";
                hideLanguageSwitch.value = settings[ThemeSettings.hideLanguageSwitch] !== undefined && settings[ThemeSettings.hideLanguageSwitch].toLowerCase() === "true";
                mode.value = settings[ThemeSettings.mode];
            }

            function getSettings(settings: KeyValue) {
                settings[ThemeSettings.lightColorPrimary] = lightColorPrimary.value;
                settings[ThemeSettings.darkColorPrimary] = darkColorPrimary.value;
                settings[ThemeSettings.lightColorSecondary] = lightColorSecondary.value;
                settings[ThemeSettings.darkColorSecondary] = darkColorSecondary.value;
                settings[ThemeSettings.lightColorBackgroundPrimary] = lightColorBackgroundPrimary.value;
                settings[ThemeSettings.darkColorBackgroundPrimary] = darkColorBackgroundPrimary.value;
                settings[ThemeSettings.lightColorBackgroundSecondary] = lightColorBackgroundSecondary.value;
                settings[ThemeSettings.darkColorBackgroundSecondary] = darkColorBackgroundSecondary.value;
                settings[ThemeSettings.lightColorButton] = lightColorButton.value;
                settings[ThemeSettings.darkColorButton] = darkColorButton.value;
                settings[ThemeSettings.lightColorBackgroundButton] = lightColorBackgroundButton.value;
                settings[ThemeSettings.darkColorBackgroundButton] = darkColorBackgroundButton.value;
                settings[ThemeSettings.lightColorAccent] = lightColorAccent.value;
                settings[ThemeSettings.darkColorAccent] = darkColorAccent.value;
                settings[ThemeSettings.lightColorGreen] = lightColorGreen.value;
                settings[ThemeSettings.darkColorGreen] = darkColorGreen.value;
                settings[ThemeSettings.lightColorYellow] = lightColorYellow.value;
                settings[ThemeSettings.darkColorYellow] = darkColorYellow.value;
                settings[ThemeSettings.lightColorOrange] = lightColorOrange.value;
                settings[ThemeSettings.darkColorOrange] = darkColorOrange.value;
                settings[ThemeSettings.lightColorRed] = lightColorRed.value;
                settings[ThemeSettings.darkColorRed] = darkColorRed.value;
                settings[ThemeSettings.lightBoxShadowPrimary] = lightBoxShadowPrimary.value;
                settings[ThemeSettings.lightBoxShadowSecondary] = lightBoxShadowSecondary.value;
                settings[ThemeSettings.darkBoxShadowPrimary] = darkBoxShadowPrimary.value;
                settings[ThemeSettings.darkBoxShadowSecondary] = darkBoxShadowSecondary.value;
                settings[ThemeSettings.borderRadiusPrimary] = borderRadiusPrimary.value ? `${borderRadiusPrimary.value}px` : "";
                settings[ThemeSettings.title] = title.value;
                settings[ThemeSettings.description] = description.value;
                settings[ThemeSettings.hideResources] = hideResources.value;
                settings[ThemeSettings.hideLogin] = hideLogin.value ? "true" : "";
                settings[ThemeSettings.hideFooter] = hideFooter.value ? "true" : "";
                settings[ThemeSettings.hideCopyright] = hideCopyright.value ? "true" : "";
                settings[ThemeSettings.hideDarkModeToggle] = hideDarkModeToggle.value ? "true" : "";
                settings[ThemeSettings.hideLanguageSwitch] = hideLanguageSwitch.value ? "true" : "";
                settings[ThemeSettings.mode] = mode.value;
                return settings;
            }

            return {
                t,
                ThemeSettings,
                logoLightVar: ThemeSettings.logoLight,
                logoDarkVar: ThemeSettings.logoDark,
                faviconVar: ThemeSettings.favicon,
                hideResourcesOptions,
                modeOptions,
                validationError,
                activeTab,
                logoLight,
                logoDark,
                favicon,
                lightColorPrimary,
                darkColorPrimary,
                lightColorSecondary,
                darkColorSecondary,
                lightColorBackgroundPrimary,
                darkColorBackgroundPrimary,
                lightColorBackgroundSecondary,
                darkColorBackgroundSecondary,
                lightColorButton,
                darkColorButton,
                lightColorBackgroundButton,
                darkColorBackgroundButton,
                lightColorAccent,
                darkColorAccent,
                lightColorGreen,
                darkColorGreen,
                lightColorYellow,
                darkColorYellow,
                lightColorOrange,
                darkColorOrange,
                lightColorRed,
                darkColorRed,
                lightBoxShadowPrimary,
                lightBoxShadowSecondary,
                darkBoxShadowPrimary,
                darkBoxShadowSecondary,
                borderRadiusPrimary,
                title,
                description,
                hideResources,
                hideLogin,
                hideFooter,
                hideCopyright,
                hideDarkModeToggle,
                hideLanguageSwitch,
                mode,
                themeString,
                copyTheme,
                pasteTheme
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "tab.light": "Light Theme",
            "tab.dark": "Dark Theme",
            "title.main_colors": "Main Colors",
            "title.background_colors": "Background Colors",
            "title.button_colors": "Button Colors",
            "title.additional_colors": "Additional Colors",
            "title.box_shadows": "Box Shadows",
            "title.logos": "Logos",
            "title.border_radius": "Border Radius",
            "title.page": "Page",
            "title.theme_code": "Theme Code",
            "light.primary": "Primary - Light",
            "light.secondary": "Secondary - Light",
            "light.primary_bg": "Primary Background - Light",
            "light.secondary_bg": "Secondary Background - Light",
            "light.button": "Button - Light",
            "light.button_bg": "Button Background - Light",
            "light.accent": "Accent - Light",
            "light.green": "Green - Light",
            "light.yellow": "Yellow - Light",
            "light.orange": "Orange - Light",
            "light.red": "Red - Light",
            "light.primary_shadow": "Primary Box Shadow - Light",
            "light.secondary_shadow": "Secondary Box Shadow - Light",
            "dark.primary": "Primary - Dark",
            "dark.secondary": "Secondary - Dark",
            "dark.primary_bg": "Primary Background - Dark",
            "dark.secondary_bg": "Secondary Background - Dark",
            "dark.button": "Button - Dark",
            "dark.button_bg": "Button Background - Dark",
            "dark.accent": "Accent - Dark",
            "dark.green": "Green - Dark",
            "dark.yellow": "Yellow - Dark",
            "dark.orange": "Orange - Dark",
            "dark.red": "Red - Dark",
            "dark.primary_shadow": "Primary Box Shadow - Dark",
            "dark.secondary_shadow": "Secondary Box Shadow - Dark",
            "logo.light": "Logo - Light",
            "logo.dark": "Logo - Dark",
            "logo.favicon": "Favicon",
            "border.radius": "Primary Border Radius",
            "border.hint": "The secondary border radius is half the primary border radius.",
            "page.title": "Title",
            "page.meta_description": "Meta Description",
            "page.resources": "Resources Menu",
            "page.mode": "Color Mode",
            "page.login": "Hide Login Button",
            "page.footer": "Hide Footer",
            "page.copyright": "Hide Copyright",
            "page.toggle_mode": "Hide Dark Mode Toggle",
            "page.language_switch": "Hide Language Switch",
            "theme.code": "Theme Code",
            "copy_to_clipboard": "Copy to Clipboard",
            "options.resources.settings": "Use Base Theme Setting",
            "options.resources.show": "Show Menu",
            "options.resources.hide": "Hide Menu",
            "options.mode.system": "System Settings",
            "options.mode.dark": "Dark Mode",
            "options.mode.light": "Light Mode",
            "toast.clipboard": "Copied theme code to clipboard.",
            "toast.error": "Please enter a valid theme string."
        },
        "de": {
            "tab.light": "Helles Thema",
            "tab.dark": "Dunkles Thema",
            "title.main_colors": "Hauptfarben",
            "title.background_colors": "Hintergrundfarben",
            "title.button_colors": "Schaltflächenfarben",
            "title.additional_colors": "Zusätzliche Farben",
            "title.box_shadows": "Box-Schatten",
            "title.logos": "Logos",
            "title.border_radius": "Randradius",
            "title.page": "Seite",
            "title.theme_code": "Themen-Code",
            "light.primary": "Primär - Hell",
            "light.secondary": "Sekundär - Hell",
            "light.primary_bg": "Primärhintergrund - Hell",
            "light.secondary_bg": "Sekundärhintergrund - Hell",
            "light.button": "Schaltfläche - Hell",
            "light.button_bg": "Schaltflächenhintergrund - Hell",
            "light.accent": "Akzent - Hell",
            "light.green": "Grün - Hell",
            "light.yellow": "Gelb - Hell",
            "light.orange": "Orange - Hell",
            "light.red": "Rot - Hell",
            "light.primary_shadow": "Primärer Box-Schatten - Hell",
            "light.secondary_shadow": "Sekundärer Box-Schatten - Hell",
            "dark.primary": "Primär - Dunkel",
            "dark.secondary": "Sekundär - Dunkel",
            "dark.primary_bg": "Primärhintergrund - Dunkel",
            "dark.secondary_bg": "Sekundärhintergrund - Dunkel",
            "dark.button": "Schaltfläche - Dunkel",
            "dark.button_bg": "Schaltflächenhintergrund - Dunkel",
            "dark.accent": "Akzent - Dunkel",
            "dark.green": "Grün - Dunkel",
            "dark.yellow": "Gelb - Dunkel",
            "dark.orange": "Orange - Dunkel",
            "dark.red": "Rot - Dunkel",
            "dark.primary_shadow": "Primärer Box-Schatten - Dunkel",
            "dark.secondary_shadow": "Sekundärer Box-Schatten - Dunkel",
            "logo.light": "Logo - Hell",
            "logo.dark": "Logo - Dunkel",
            "logo.favicon": "Favicon",
            "border.radius": "Primärer Randradius",
            "border.hint": "Der sekundäre Randradius beträgt die Hälfte des primären Randradius.",
            "page.title": "Titel",
            "page.meta_description": "Meta-Beschreibung",
            "page.resources": "Ressourcenmenü",
            "page.mode": "Farbmodus",
            "page.login": "Login-Schaltfläche ausblenden",
            "page.footer": "Footer ausblenden",
            "page.copyright": "Copyright ausblenden",
            "page.toggle_mode": "Dunkelmodus-Umschalter ausblenden",
            "page.language_switch": "Sprachauswahl ausblenden",
            "theme.code": "Themen-Code",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "options.resources.settings": "Basistheme-Einstellung verwenden",
            "options.resources.show": "Menü anzeigen",
            "options.resources.hide": "Menü ausblenden",
            "options.mode.system": "Systemeinstellungen",
            "options.mode.dark": "Dunkelmodus",
            "options.mode.light": "Hellmodus",
            "toast.clipboard": "Themen-Code in die Zwischenablage kopiert.",
            "toast.error": "Bitte gib einen gültigen Themen-String ein."
        },
        "es": {
            "tab.light": "Tema Claro",
            "tab.dark": "Tema Oscuro",
            "title.main_colors": "Colores Principales",
            "title.background_colors": "Colores de Fondo",
            "title.button_colors": "Colores de Botones",
            "title.additional_colors": "Colores Adicionales",
            "title.box_shadows": "Sombras de Caja",
            "title.logos": "Logos",
            "title.border_radius": "Radio del Borde",
            "title.page": "Página",
            "title.theme_code": "Código del Tema",
            "light.primary": "Primario - Claro",
            "light.secondary": "Secundario - Claro",
            "light.primary_bg": "Fondo Primario - Claro",
            "light.secondary_bg": "Fondo Secundario - Claro",
            "light.button": "Botón - Claro",
            "light.button_bg": "Fondo del Botón - Claro",
            "light.accent": "Acento - Claro",
            "light.green": "Verde - Claro",
            "light.yellow": "Amarillo - Claro",
            "light.orange": "Naranja - Claro",
            "light.red": "Rojo - Claro",
            "light.primary_shadow": "Sombra de Caja Primaria - Claro",
            "light.secondary_shadow": "Sombra de Caja Secundaria - Claro",
            "dark.primary": "Primario - Oscuro",
            "dark.secondary": "Secundario - Oscuro",
            "dark.primary_bg": "Fondo Primario - Oscuro",
            "dark.secondary_bg": "Fondo Secundario - Oscuro",
            "dark.button": "Botón - Oscuro",
            "dark.button_bg": "Fondo del Botón - Oscuro",
            "dark.accent": "Acento - Oscuro",
            "dark.green": "Verde - Oscuro",
            "dark.yellow": "Amarillo - Oscuro",
            "dark.orange": "Naranja - Oscuro",
            "dark.red": "Rojo - Oscuro",
            "dark.primary_shadow": "Sombra de Caja Primaria - Oscuro",
            "dark.secondary_shadow": "Sombra de Caja Secundaria - Oscuro",
            "logo.light": "Logo - Claro",
            "logo.dark": "Logo - Oscuro",
            "logo.favicon": "Favicon",
            "border.radius": "Radio del Borde Primario",
            "border.hint": "El radio del borde secundario es la mitad del radio del borde primario.",
            "page.title": "Título",
            "page.meta_description": "Meta Descripción",
            "page.resources": "Menú de Recursos",
            "page.mode": "Modo de Color",
            "page.login": "Ocultar Botón de Inicio de Sesión",
            "page.footer": "Ocultar Pie de Página",
            "page.copyright": "Ocultar Copyright",
            "page.toggle_mode": "Ocultar Alternar Modo Oscuro",
            "page.language_switch": "Ocultar cambio de idioma",
            "theme.code": "Código del Tema",
            "copy_to_clipboard": "Copiar al portapapeles",
            "options.resources.settings": "Usar Configuración de Tema Base",
            "options.resources.show": "Mostrar Menú",
            "options.resources.hide": "Ocultar Menú",
            "options.mode.system": "Configuración del Sistema",
            "options.mode.dark": "Modo Oscuro",
            "options.mode.light": "Modo Claro",
            "toast.clipboard": "Código del tema copiado al portapapeles.",
            "toast.error": "Por favor ingresa un string de tema válido."
        },
        "fr": {
            "tab.light": "Thème Clair",
            "tab.dark": "Thème Sombre",
            "title.main_colors": "Couleurs Principales",
            "title.background_colors": "Couleurs de Fond",
            "title.button_colors": "Couleurs des Boutons",
            "title.additional_colors": "Couleurs Supplémentaires",
            "title.box_shadows": "Ombres de Boîte",
            "title.logos": "Logos",
            "title.border_radius": "Rayon de Bordure",
            "title.page": "Page",
            "title.theme_code": "Code du Thème",
            "light.primary": "Primaire - Clair",
            "light.secondary": "Secondaire - Clair",
            "light.primary_bg": "Fond Primaire - Clair",
            "light.secondary_bg": "Fond Secondaire - Clair",
            "light.button": "Bouton - Clair",
            "light.button_bg": "Fond du Bouton - Clair",
            "light.accent": "Accent - Clair",
            "light.green": "Vert - Clair",
            "light.yellow": "Jaune - Clair",
            "light.orange": "Orange - Clair",
            "light.red": "Rouge - Clair",
            "light.primary_shadow": "Ombre de Boîte Primaire - Clair",
            "light.secondary_shadow": "Ombre de Boîte Secondaire - Clair",
            "dark.primary": "Primaire - Sombre",
            "dark.secondary": "Secondaire - Sombre",
            "dark.primary_bg": "Fond Primaire - Sombre",
            "dark.secondary_bg": "Fond Secondaire - Sombre",
            "dark.button": "Bouton - Sombre",
            "dark.button_bg": "Fond du Bouton - Sombre",
            "dark.accent": "Accent - Sombre",
            "dark.green": "Vert - Sombre",
            "dark.yellow": "Jaune - Sombre",
            "dark.orange": "Orange - Sombre",
            "dark.red": "Rouge - Sombre",
            "dark.primary_shadow": "Ombre de Boîte Primaire - Sombre",
            "dark.secondary_shadow": "Ombre de Boîte Secondaire - Sombre",
            "logo.light": "Logo - Clair",
            "logo.dark": "Logo - Sombre",
            "logo.favicon": "Favicon",
            "border.radius": "Rayon de Bordure Primaire",
            "border.hint": "Le rayon de bordure secondaire est la moitié du rayon de bordure primaire.",
            "page.title": "Titre",
            "page.meta_description": "Meta Description",
            "page.resources": "Menu des Ressources",
            "page.mode": "Mode Couleur",
            "page.login": "Masquer le Bouton de Connexion",
            "page.footer": "Masquer le Pied de Page",
            "page.copyright": "Masquer le Copyright",
            "page.toggle_mode": "Masquer le Bouton de Mode Sombre",
            "page.language_switch": "Masquer le commutateur de langue",
            "theme.code": "Code du Thème",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "options.resources.settings": "Utiliser le Paramètre de Thème de Base",
            "options.resources.show": "Afficher le Menu",
            "options.resources.hide": "Masquer le Menu",
            "options.mode.system": "Paramètres du Système",
            "options.mode.dark": "Mode Sombre",
            "options.mode.light": "Mode Clair",
            "toast.clipboard": "Code du thème copié dans le presse-papiers.",
            "toast.error": "Veuillez entrer un code de thème valide."
        },
        "nl": {
            "tab.light": "Licht Thema",
            "tab.dark": "Donker Thema",
            "title.main_colors": "Hoofdkleuren",
            "title.background_colors": "Achtergrondkleuren",
            "title.button_colors": "Knopkleuren",
            "title.additional_colors": "Aanvullende Kleuren",
            "title.box_shadows": "Doosschaduwen",
            "title.logos": "Logo's",
            "title.border_radius": "Randradius",
            "title.page": "Pagina",
            "title.theme_code": "Themacode",
            "light.primary": "Primair - Licht",
            "light.secondary": "Secundair - Licht",
            "light.primary_bg": "Primaire Achtergrond - Licht",
            "light.secondary_bg": "Secundaire Achtergrond - Licht",
            "light.button": "Knop - Licht",
            "light.button_bg": "Knopachtergrond - Licht",
            "light.accent": "Accent - Licht",
            "light.green": "Groen - Licht",
            "light.yellow": "Geel - Licht",
            "light.orange": "Oranje - Licht",
            "light.red": "Rood - Licht",
            "light.primary_shadow": "Primaire Doosschaduw - Licht",
            "light.secondary_shadow": "Secundaire Doosschaduw - Licht",
            "dark.primary": "Primair - Donker",
            "dark.secondary": "Secundair - Donker",
            "dark.primary_bg": "Primaire Achtergrond - Donker",
            "dark.secondary_bg": "Secundaire Achtergrond - Donker",
            "dark.button": "Knop - Donker",
            "dark.button_bg": "Knopachtergrond - Donker",
            "dark.accent": "Accent - Donker",
            "dark.green": "Groen - Donker",
            "dark.yellow": "Geel - Donker",
            "dark.orange": "Oranje - Donker",
            "dark.red": "Rood - Donker",
            "dark.primary_shadow": "Primaire Doosschaduw - Donker",
            "dark.secondary_shadow": "Secundaire Doosschaduw - Donker",
            "logo.light": "Logo - Licht",
            "logo.dark": "Logo - Donker",
            "logo.favicon": "Favicon",
            "border.radius": "Primaire Randradius",
            "border.hint": "De secundaire randradius is de helft van de primaire randradius.",
            "page.title": "Titel",
            "page.meta_description": "Meta Beschrijving",
            "page.resources": "Hulpmenu",
            "page.mode": "Kleurmodus",
            "page.login": "Login-knop verbergen",
            "page.footer": "Voettekst verbergen",
            "page.copyright": "Copyright verbergen",
            "page.toggle_mode": "Donkere modus-toggle verbergen",
            "page.language_switch": "Verberg taalschakelaar",
            "theme.code": "Themacode",
            "copy_to_clipboard": "Kopieer naar klembord",
            "options.resources.settings": "Gebruik Basis Thema Instelling",
            "options.resources.show": "Menu Tonen",
            "options.resources.hide": "Menu Verbergen",
            "options.mode.system": "Systeeminstellingen",
            "options.mode.dark": "Donkere Modus",
            "options.mode.light": "Lichte Modus",
            "toast.clipboard": "Themacode naar klembord gekopieerd.",
            "toast.error": "Voer een geldige themastring in."
        },
        "it": {
            "tab.light": "Tema Chiaro",
            "tab.dark": "Tema Scuro",
            "title.main_colors": "Colori Principali",
            "title.background_colors": "Colori di Sfondo",
            "title.button_colors": "Colori dei Pulsanti",
            "title.additional_colors": "Colori Aggiuntivi",
            "title.box_shadows": "Ombre delle Scatole",
            "title.logos": "Loghi",
            "title.border_radius": "Raggio del Bordo",
            "title.page": "Pagina",
            "title.theme_code": "Codice del Tema",
            "light.primary": "Primario - Chiaro",
            "light.secondary": "Secondario - Chiaro",
            "light.primary_bg": "Sfondo Primario - Chiaro",
            "light.secondary_bg": "Sfondo Secondario - Chiaro",
            "light.button": "Pulsante - Chiaro",
            "light.button_bg": "Sfondo del Pulsante - Chiaro",
            "light.accent": "Accento - Chiaro",
            "light.green": "Verde - Chiaro",
            "light.yellow": "Giallo - Chiaro",
            "light.orange": "Arancione - Chiaro",
            "light.red": "Rosso - Chiaro",
            "light.primary_shadow": "Ombra della Scatola Primaria - Chiaro",
            "light.secondary_shadow": "Ombra della Scatola Secondaria - Chiaro",
            "dark.primary": "Primario - Scuro",
            "dark.secondary": "Secondario - Scuro",
            "dark.primary_bg": "Sfondo Primario - Scuro",
            "dark.secondary_bg": "Sfondo Secondario - Scuro",
            "dark.button": "Pulsante - Scuro",
            "dark.button_bg": "Sfondo del Pulsante - Scuro",
            "dark.accent": "Accento - Scuro",
            "dark.green": "Verde - Scuro",
            "dark.yellow": "Giallo - Scuro",
            "dark.orange": "Arancione - Scuro",
            "dark.red": "Rosso - Scuro",
            "dark.primary_shadow": "Ombra della Scatola Primaria - Scuro",
            "dark.secondary_shadow": "Ombra della Scatola Secondaria - Scuro",
            "logo.light": "Logo - Chiaro",
            "logo.dark": "Logo - Scuro",
            "logo.favicon": "Favicon",
            "border.radius": "Raggio del Bordo Primario",
            "border.hint": "Il raggio del bordo secondario è la metà del raggio del bordo primario.",
            "page.title": "Titolo",
            "page.meta_description": "Meta Descrizione",
            "page.resources": "Menu Risorse",
            "page.mode": "Modalità Colore",
            "page.login": "Nascondi Pulsante di Accesso",
            "page.footer": "Nascondi Piè di Pagina",
            "page.copyright": "Nascondi Copyright",
            "page.toggle_mode": "Nascondi Alternanza Modalità Scura",
            "page.language_switch": "Nascondere l'interruttore della lingua",
            "theme.code": "Codice del Tema",
            "copy_to_clipboard": "Copia negli Appunti",
            "options.resources.settings": "Usa Impostazione Tema di Base",
            "options.resources.show": "Mostra Menu",
            "options.resources.hide": "Nascondi Menu",
            "options.mode.system": "Impostazioni di Sistema",
            "options.mode.dark": "Modalità Scura",
            "options.mode.light": "Modalità Chiara",
            "toast.clipboard": "Codice del tema copiato negli appunti.",
            "toast.error": "Inserisci un tema valido."
        },
        "pt": {
            "tab.light": "Tema Claro",
            "tab.dark": "Tema Escuro",
            "title.main_colors": "Cores Principais",
            "title.background_colors": "Cores de Fundo",
            "title.button_colors": "Cores do Botão",
            "title.additional_colors": "Cores Adicionais",
            "title.box_shadows": "Sombras da Caixa",
            "title.logos": "Logotipos",
            "title.border_radius": "Raio da Borda",
            "title.page": "Página",
            "title.theme_code": "Código do Tema",
            "light.primary": "Primário - Claro",
            "light.secondary": "Secundário - Claro",
            "light.primary_bg": "Fundo Primário - Claro",
            "light.secondary_bg": "Fundo Secundário - Claro",
            "light.button": "Botão - Claro",
            "light.button_bg": "Fundo do Botão - Claro",
            "light.accent": "Acento - Claro",
            "light.green": "Verde - Claro",
            "light.yellow": "Amarelo - Claro",
            "light.orange": "Laranja - Claro",
            "light.red": "Vermelho - Claro",
            "light.primary_shadow": "Sombra da Caixa Primária - Claro",
            "light.secondary_shadow": "Sombra da Caixa Secundária - Claro",
            "dark.primary": "Primário - Escuro",
            "dark.secondary": "Secundário - Escuro",
            "dark.primary_bg": "Fundo Primário - Escuro",
            "dark.secondary_bg": "Fundo Secundário - Escuro",
            "dark.button": "Botão - Escuro",
            "dark.button_bg": "Fundo do Botão - Escuro",
            "dark.accent": "Acento - Escuro",
            "dark.green": "Verde - Escuro",
            "dark.yellow": "Amarelo - Escuro",
            "dark.orange": "Laranja - Escuro",
            "dark.red": "Vermelho - Escuro",
            "dark.primary_shadow": "Sombra da Caixa Primária - Escuro",
            "dark.secondary_shadow": "Sombra da Caixa Secundária - Escuro",
            "logo.light": "Logotipo - Claro",
            "logo.dark": "Logotipo - Escuro",
            "logo.favicon": "Favicon",
            "border.radius": "Raio da Borda Primária",
            "border.hint": "O raio da borda secundária é metade do raio da borda primária.",
            "page.title": "Título",
            "page.meta_description": "Meta Descrição",
            "page.resources": "Menu de Recursos",
            "page.mode": "Modo de Cor",
            "page.login": "Ocultar Botão de Login",
            "page.footer": "Ocultar Rodapé",
            "page.copyright": "Ocultar Copyright",
            "page.toggle_mode": "Ocultar Alternância de Modo Escuro",
            "page.language_switch": "Ocultar interrutor de idioma",
            "theme.code": "Código do Tema",
            "copy_to_clipboard": "Copiar para a Área de Transferência",
            "options.resources.settings": "Usar Configuração de Tema Base",
            "options.resources.show": "Mostrar Menu",
            "options.resources.hide": "Ocultar Menu",
            "options.mode.system": "Configurações do Sistema",
            "options.mode.dark": "Modo Escuro",
            "options.mode.light": "Modo Claro",
            "toast.clipboard": "Código do tema copiado para a área de transferência.",
            "toast.error": "Por favor, insira um código de tema válido."
        },
        "ja": {
            "tab.light": "ライトテーマ",
            "tab.dark": "ダークテーマ",
            "title.main_colors": "メインカラー",
            "title.background_colors": "背景色",
            "title.button_colors": "ボタンカラー",
            "title.additional_colors": "追加の色",
            "title.box_shadows": "ボックスシャドウ",
            "title.logos": "ロゴ",
            "title.border_radius": "ボーダー半径",
            "title.page": "ページ",
            "title.theme_code": "テーマコード",
            "light.primary": "プライマリ - ライト",
            "light.secondary": "セカンダリ - ライト",
            "light.primary_bg": "プライマリ背景 - ライト",
            "light.secondary_bg": "セカンダリ背景 - ライト",
            "light.button": "ボタン - ライト",
            "light.button_bg": "ボタン背景 - ライト",
            "light.accent": "アクセント - ライト",
            "light.green": "グリーン - ライト",
            "light.yellow": "イエロー - ライト",
            "light.orange": "オレンジ - ライト",
            "light.red": "レッド - ライト",
            "light.primary_shadow": "プライマリボックスシャドウ - ライト",
            "light.secondary_shadow": "セカンダリボックスシャドウ - ライト",
            "dark.primary": "プライマリ - ダーク",
            "dark.secondary": "セカンダリ - ダーク",
            "dark.primary_bg": "プライマリ背景 - ダーク",
            "dark.secondary_bg": "セカンダリ背景 - ダーク",
            "dark.button": "ボタン - ダーク",
            "dark.button_bg": "ボタン背景 - ダーク",
            "dark.accent": "アクセント - ダーク",
            "dark.green": "グリーン - ダーク",
            "dark.yellow": "イエロー - ダーク",
            "dark.orange": "オレンジ - ダーク",
            "dark.red": "レッド - ダーク",
            "dark.primary_shadow": "プライマリボックスシャドウ - ダーク",
            "dark.secondary_shadow": "セカンダリボックスシャドウ - ダーク",
            "logo.light": "ロゴ - ライト",
            "logo.dark": "ロゴ - ダーク",
            "logo.favicon": "ファビコン",
            "border.radius": "プライマリボーダー半径",
            "border.hint": "セカンダリボーダー半径はプライマリボーダー半径の半分です。",
            "page.title": "タイトル",
            "page.meta_description": "メタディスクリプション",
            "page.resources": "リソースメニュー",
            "page.mode": "カラーモード",
            "page.login": "ログインボタンを隠す",
            "page.footer": "フッターを隠す",
            "page.copyright": "著作権を隠す",
            "page.toggle_mode": "ダークモードトグルを隠す",
            "page.language_switch": "非表示言語スイッチ",
            "theme.code": "テーマコード",
            "copy_to_clipboard": "クリップボードにコピー",
            "options.resources.settings": "ベーステーマ設定を使用する",
            "options.resources.show": "メニューを表示",
            "options.resources.hide": "メニューを隠す",
            "options.mode.system": "システム設定",
            "options.mode.dark": "ダークモード",
            "options.mode.light": "ライトモード",
            "toast.clipboard": "テーマコードをクリップボードにコピーしました。",
            "toast.error": "有効なテーマ文字列を入力してください。"
        }
    }
</i18n>
