<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/affiliate" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="link !== ''">
                    <div v-if="user.subscription_active">
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <pre style="max-width: calc(100% - 36px); margin: 0;">
                                <code v-html="link"></code>
                            </pre>
                            <i class="icon icon-copy clickable" :title="t('copy')" v-on:click="copyLink"></i>
                        </div>
                    </div>
                    <div v-else>
                        <p class="warning">{{t("warning.text")}}</p>
                        <router-link to="/billing" class="button" style="margin-top: 12px;">{{t("warning.button")}}</router-link>
                    </div>
                </div>
            </Expand>
            <Loading :loading="link === ''" />
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import copy from "copy-to-clipboard";
    import Expand from "@/components/bits/Expand.vue";
    import Loading from "@/components/bits/Loading.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Expand,
            Loading
        },
        setup() {
            const {t} = useI18n();
            const {user} = storeToRefs(useUserStore());
            const {success} = useToastStore();
            const link = computed(() => user.value && user.value.id ? `${import.meta.env.VITE_APP_WEBSITE_HOST}/ref/${user.value.id}` : "");

            function copyLink() {
                copy(link.value);
                success(t("toast.success"));
            }

            return {
                t,
                user,
                link,
                copyLink
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Link",
            "text": "Receive discounts when someone signs up using your link.",
            "link": "Read the Docs",
            "copy": "Copy to Clipboard",
            "warning.text": "You need an active subscription to participate in the affiliate program.",
            "warning.button": "Manage Subscription",
            "toast.success": "Link copied!"
        },
        "de": {
            "title": "Link",
            "text": "Erhalte Rabatte, wenn sich jemand über deinen Link anmeldet.",
            "link": "Dokumentation lesen",
            "copy": "In die Zwischenablage kopieren",
            "warning.text": "Du benötigst ein aktives Abonnement, um am Affiliate-Programm teilzunehmen.",
            "warning.button": "Abonnement verwalten",
            "toast.success": "Link kopiert!"
        },
        "es": {
            "title": "Enlace",
            "text": "Recibe descuentos cuando alguien se registre usando tu enlace.",
            "link": "Leer la documentación",
            "copy": "Copiar al portapapeles",
            "warning.text": "Necesitas una suscripción activa para participar en el programa de afiliados.",
            "warning.button": "Gestionar suscripción",
            "toast.success": "¡Enlace copiado!"
        },
        "fr": {
            "title": "Lien",
            "text": "Recevez des réductions lorsque quelqu'un s'inscrit en utilisant votre lien.",
            "link": "Lire la documentation",
            "copy": "Copier dans le presse-papier",
            "warning.text": "Vous avez besoin d'un abonnement actif pour participer au programme d'affiliation.",
            "warning.button": "Gérer l'abonnement",
            "toast.success": "Lien copié!"
        },
        "nl": {
            "title": "Link",
            "text": "Ontvang kortingen wanneer iemand zich aanmeldt via jouw link.",
            "link": "Lees de documentatie",
            "copy": "Kopiëren naar klembord",
            "warning.text": "Je hebt een actief abonnement nodig om deel te nemen aan het affiliate-programma.",
            "warning.button": "Abonnement beheren",
            "toast.success": "Link gekopieerd!"
        },
        "it": {
            "title": "Link",
            "text": "Ricevi sconti quando qualcuno si iscrive utilizzando il tuo link.",
            "link": "Leggi la documentazione",
            "copy": "Copia negli appunti",
            "warning.text": "Hai bisogno di un abbonamento attivo per partecipare al programma di affiliazione.",
            "warning.button": "Gestisci abbonamento",
            "toast.success": "Link copiato!"
        },
        "pt": {
            "title": "Link",
            "text": "Receba descontos quando alguém se inscrever usando seu link.",
            "link": "Leia a documentação",
            "copy": "Copiar para a área de transferência",
            "warning.text": "Você precisa de uma assinatura ativa para participar do programa de afiliados.",
            "warning.button": "Gerenciar assinatura",
            "toast.success": "Link copiado!"
        },
        "ja": {
            "title": "リンク",
            "text": "リンクを使用して誰かがサインアップすると割引を受け取ることができます。",
            "link": "ドキュメントを読む",
            "copy": "クリップボードにコピー",
            "warning.text": "アフィリエイトプログラムに参加するには、アクティブなサブスクリプションが必要です。",
            "warning.button": "サブスクリプションを管理",
            "toast.success": "リンクがコピーされました！"
        }
    }
</i18n>
