<template>
    <transition name="toast">
        <div :class="toastClass" v-if="message">
            <i class="icon icon-info"></i>
            <p>
                <strong>{{message}}</strong>
            </p>
        </div>
    </transition>
</template>

<script lang="ts">
    import {defineComponent, computed} from "vue";
    import {useToastStore} from "@/store/ToastStore";
    import {storeToRefs} from "pinia";

    export default defineComponent({
        setup() {
            const {color, message} = storeToRefs(useToastStore());
            const toastClass = computed(() => {
                return {
                    "toast no-select": true,
                    "green": color.value === "green",
                    "red": color.value === "red"
                };
            });

            return {
                color,
                message,
                toastClass
            };
        }
    });
</script>
