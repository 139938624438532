<template>
    <h1 class="mw-768">{{t("title")}}</h1>
    <UI />
    <hr />
    <UserPicture />
    <hr />
    <Name />
    <hr />
    <Email />
    <hr />
    <Password />
    <template v-if="showMarketing">
        <hr />
        <Marketing />
    </template>
    <template v-if="!user.read_only">
        <hr />
        <Clients :forDomain="false" />
    </template>
    <hr />
    <Logout />
    <template v-if="!user.read_only && !selfHosted">
        <hr />
        <DeleteAccount />
    </template>
</template>

<script lang="ts">
    import {defineComponent, onMounted} from "vue";
    import UI from "@/components/account/UI.vue";
    import UserPicture from "@/components/account/UserPicture.vue";
    import Name from "@/components/account/Name.vue";
    import Marketing from "@/components/account/Marketing.vue";
    import Password from "@/components/account/Password.vue";
    import Email from "@/components/account/Email.vue";
    import Clients from "@/components/settings/Clients.vue";
    import Logout from "@/components/account/Logout.vue";
    import DeleteAccount from "@/components/account/DeleteAccount.vue";
    import {useLockStore} from "@/store/LockStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {isSelfHosted} from "@/util/domain";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            UI,
            UserPicture,
            Name,
            Marketing,
            Password,
            Email,
            Clients,
            Logout,
            DeleteAccount
        },
        setup() {
            const {load} = useLockStore();
            const {user} = storeToRefs(useUserStore());

            onMounted(() => {
                load();
            });

            return {
                ...useI18n(),
                user,
                selfHosted: isSelfHosted(),
                showMarketing: import.meta.env.VITE_APP_SHOW_MARKETING
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Account"
        },
        "de": {
            "title": "Konto"
        },
        "es": {
            "title": "Cuenta"
        },
        "fr": {
            "title": "Compte"
        },
        "nl": {
            "title": "Account"
        },
        "it": {
            "title": "Account"
        },
        "pt": {
            "title": "Conta"
        },
        "ja": {
            "title": "アカウント"
        }
    }
</i18n>
