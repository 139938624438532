import {ComputedRef, Ref, ref, watch, onMounted} from "vue";
import {day, formatHour, formatMinute, month, week, year} from "@/util/format";
import {useFilterStore} from "@/store/FilterStore";
import {storeToRefs} from "pinia";

interface Data {
    labels: Ref<string[]>
    data: Ref<number[]>
}

export function useData(sourceData: ComputedRef, sourceField: ComputedRef, formatTime: ComputedRef): Data {
    const {filter} = storeToRefs(useFilterStore());
    const labels = ref<string[]>([]);
    const data = ref<number[]>([]);

    // used to cache results, DO NOT REMOVE!
    let currentField: string;
    let currentData: number[];

    onMounted(() => {
        if (sourceData.value) {
            setData();
        }
    });

    watch([sourceData, sourceField], () => {
        if (sourceData.value) {
            setData();
        }
    });

    function setData() {
        if (sourceField.value === currentField && sourceData.value === currentData) {
            return;
        }

        currentField = sourceField.value;
        currentData = sourceData.value;
        const tmpLabels = [];
        const tmpData = [];
        let tmpMax = 0;
        const includeYear = (filter.value.timeRange?.from?.getFullYear() ?? 9999) - (filter.value.timeRange?.to?.getFullYear() ?? 9999) !== 0;

        for (let i = 0; i < sourceData.value.length; i++) {
            if (sourceData.value[i].minute !== undefined && sourceData.value[i].minute !== null) {
                tmpLabels.push(formatMinute(sourceData.value[i].minute));
            } else if (formatTime.value) {
                tmpLabels.push(formatHour(sourceData.value[i].hour));
            } else if (sourceData.value[i].day) {
                tmpLabels.push(day(sourceData.value[i].day, includeYear));
            } else if (sourceData.value[i].week) {
                tmpLabels.push(week(sourceData.value[i].week, includeYear));
            } else if (sourceData.value[i].month) {
                tmpLabels.push(month(sourceData.value[i].month, includeYear));
            } else if (sourceData.value[i].year) {
                tmpLabels.push(year(sourceData.value[i].year));
            }

            tmpData.push(sourceData.value[i][sourceField.value]);

            if (sourceData.value[i][sourceField.value] > tmpMax) {
                tmpMax = sourceData.value[i][sourceField.value] as number;
            }
        }

        labels.value = tmpLabels;
        data.value = tmpData;
    }

    return {
        labels,
        data
    };
}
