<template>
    <div class="table" style="margin: 3px 0 -2px; padding-left: 52px;">
        <table>
            <thead>
                <tr>
                    <td style="text-align: left;">
                        <h5>{{t("table.key")}}</h5>
                    </td>
                    <td style="text-align: right;">
                        <h5>{{t("table.value")}}</h5>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="entry in meta">
                    <td>
                        <div class="cell-content">
                            {{entry.key}}
                        </div>
                    </td>
                    <td style="min-width: 64px; text-align: right;">
                        <div class="cell-content">
                            {{entry.value}}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType} from "vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            keys: {Type: Array as PropType<Array<String>>, required: true},
            values: {Type: Array as PropType<Array<String>>, required: true}
        },
        setup(props) {
            const meta = computed(() => {
                const meta = [];

                for (let i = 0; i < props.keys.length; i++) {
                    meta.push({
                        key: props.keys[i],
                        value: props.values[i]
                    });
                }

                return meta;
            });

            return {
                ...useI18n(),
                meta
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "table.key": "Key",
            "table.value": "Value"
        },
        "de": {
            "table.key": "Schlüssel",
            "table.value": "Wert"
        },
        "es": {
            "table.key": "Clave",
            "table.value": "Valor"
        },
        "fr": {
            "table.key": "Clé",
            "table.value": "Valeur"
        },
        "nl": {
            "table.key": "Sleutel",
            "table.value": "Waarde"
        },
        "it": {
            "table.key": "Chiave",
            "table.value": "Valore"
        },
        "pt": {
            "table.key": "Chave",
            "table.value": "Valor"
        },
        "ja": {
            "table.key": "キー",
            "table.value": "値"
        }
    }
</i18n>
