import {Funnel} from "@/model/Funnel";
import {FunnelStepData} from "@/model/FunnelStepData";

export interface FunnelData {
    definition: Funnel
    data: FunnelStepData[]
}

export function defaultFunnelData(): FunnelData {
    return {
        definition: {
            id: "",
            mod_time: new Date,
            def_time: new Date,
            domain_id: "",
            name: "",
            steps: []
        },
        data: []
    };
}
