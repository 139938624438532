import {APIError} from "@/repositories/APIError";
import {Client} from "@/model/Client";
import {Repository} from "@/repositories/Repository";

export const ClientRepo = new class extends Repository {
    async list(id: string): Promise<Client[] | APIError> {
        return this.performGet<Client[]>("/client", {id}, []);
    }

    async createUpdate(id: string, domain_id: string, type: string, description: string, scope_hit: string, scope_event: string, scope_session: string, scope_statistics: string, scope_domains: string, scope_organizations: string, scope_themes: string, scope_billing: string): Promise<Client | APIError> {
        return this.performPost<Client>("/client", {
            id,
            domain_id,
            type,
            description,
            scope_hit,
            scope_event,
            scope_session,
            scope_statistics,
            scope_domains,
            scope_organizations,
            scope_themes,
            scope_billing
        })
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/client", {id});
    }
}
