import dayjs from "dayjs";

export function getToday(): Date {
    const d = new Date();
    return getDate(d.getFullYear(), d.getMonth(), d.getDate());
}

/**
 * getDate returns a new date at UTC for given year, month, and date.
 *
 * @param year the full year
 * @param month 0-based month
 * @param day the date
 * @param localTime add time zone or use UTC instead
 */
export function getDate(year: number, month: number, day: number, localTime: boolean = false): Date {
    month++;

    if (day === 0) {
        const d = new Date(year, month, 0);
        day = d.getDate();
    }

    return new Date(`${year}-${month < 10 ? "0"+month : month}-${day < 10 ? "0"+day : day}T00:00:00.000${localTime ? "Z" : ""}`);
}

export function getTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || "";
}

export function getPreviousPeriod(start: Date, end: Date, compare: "previous" | "year", sameWeekday: boolean): {previousStart: Date, previousEnd: Date} {
    if (compare !== "year" && start.getTime() === end.getTime()) {
        if (sameWeekday) {
            start = dayjs(start).add(-7, "days").toDate();
            end = dayjs(end).add(-7, "days").toDate();
        } else {
            start = dayjs(start).add(-1, "days").toDate();
            end = dayjs(end).add(-1, "days").toDate();
        }
    }

    const diffDays = getDiffDays(start, end);
    let previousStart, previousEnd: dayjs.Dayjs;

    if (compare === "year") {
        previousStart = dayjs(start).add(-1, "year");
        previousEnd = previousStart.add(diffDays, "days");
    } else if (diffDays !== 0) {
        previousStart = dayjs(start).add(-diffDays - 1, "days");
        previousEnd = dayjs(start).add(-1, "days");
    } else {
        previousStart = dayjs(start);
        previousEnd = dayjs(end);
    }

    if (sameWeekday) {
        while (previousEnd.day() !== end.getDay()) {
            previousStart = previousStart.add(1, "day");
            previousEnd = previousEnd.add(1, "day");
        }
    }

    return {
        previousStart: previousStart.toDate(),
        previousEnd: previousEnd.toDate()
    };
}

export function getDiffDays(start: Date, end: Date) {
    const diffTime = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
