<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small v-if="!hasSubscription">{{t("text.no_subscription")}}</small>
            <small v-else>{{t("text.subscription")}}</small>
            <small style="margin-top: 12px;">{{t("text.limit")}}</small>
        </div>
        <div>
            <template v-if="!loading">
                <p class="warning" style="margin-bottom: 12px;" v-if="user.subscription_downgrade_to || user.subscription_downgrade_plus">
                    <i18n-t keypath="downgrade">
                        <strong>{{day(user.subscription_cycle)}}</strong>
                    </i18n-t>
                </p>
                <template v-if="!user.subscription_active">
                    <div class="pricing-slider">
                        <div class="pricing-slider-headline">
                            <div>
                                <h2>{{pageViews[priceIndex]}}</h2>
                                <small>{{t("limit")}}</small>
                            </div>
                            <button class="billing-switch" tabindex="-1">
                                <fieldset>
                                    <input type="radio" name="billingCycle" value="monthly" id="monthly" tabindex="-1" v-model="billingCycle" />
                                    <label for="monthly">{{t("monthly")}}</label>
                                    <input type="radio" name="billingCycle" value="annually" id="annually" tabindex="-1" v-model="billingCycle" />
                                    <label for="annually">{{t("annually")}}</label>
                                </fieldset>
                            </button>
                        </div>
                        <input type="range" min="0" max="9" v-model="priceIndex" />
                    </div>
                    <div class="currency-switch">
                        <button class="billing-switch" tabindex="-1">
                            <fieldset>
                                <input type="radio" name="currency" value="dollar" id="usd" tabindex="-1" v-model="currency" />
                                <label for="usd">USD</label>
                                <input type="radio" name="currency" value="euro" id="eur" tabindex="-1" v-model="currency" />
                                <label for="eur">EUR</label>
                            </fieldset>
                        </button>
                        <span v-if="currency === 'dollar'">{{t("payment.usd")}}</span>
                        <span v-else>{{t("payment.eur")}}</span>
                    </div>
                </template>
                <i18n-t tag="p" keypath="text.manage" v-else>
                    <strong>{{t("text.manage.strong")}}</strong>
                </i18n-t>
                <div :class="{'billing-card yellow': true, active: user.subscription_active && user.subscription_plus}">
                    <div class="headline">
                        <span class="title">Plus</span>
                        <div class="price" v-if="!user.subscription_active">
                            <span class="amount" v-if="billingCycle === 'annually'">
                                <template v-if="currency === 'dollar'">$</template>{{plusPricesAnnuallyDollarOptions[priceIndex]}}<template v-if="currency === 'euro'">€</template>
                            </span>
                            <span class="amount" v-else>
                                <template v-if="currency === 'dollar'">$</template>{{plusPricesMonthlyDollarOptions[priceIndex]}}<template v-if="currency === 'euro'">€</template>
                            </span>
                            <span class="after" v-if="billingCycle === 'annually'">/{{t("year")}}</span>
                            <span class="after" v-else>/{{t("month")}}</span>
                        </div>
                    </div>
                    <hr />
                    <ul class="features">
                        <li>
                            <i class="icon icon-check"></i>
                            <strong>{{t("features.standard")}}</strong>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.custom_domains")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.unlimited_websites")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.custom_themes")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.organizations")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.white_labeling")}}</span>
                        </li>
                    </ul>
                    <div class="row">
                        <button class="yellow" v-if="user.stripe_subscription_id && user.subscription_active && user.subscription_plus" v-on:click="manageSubscription(true)">{{t("checkout.manage")}}</button>
                        <button class="yellow" v-else-if="user.stripe_subscription_id && user.subscription_active && !user.subscription_plus" v-on:click="manageSubscription(true)">{{t("checkout.upgrade")}}</button>
                        <a href="mailto:support@pirsch.io" class="button yellow" v-else-if="!user.stripe_subscription_id && user.subscription_active">{{t("checkout.contact")}}</a>
                        <button class="yellow" v-else v-on:click="checkout(true)">{{t("checkout.subscribe")}}</button>
                    </div>
                </div>
                <div :class="{'billing-card': true, active: user.subscription_active && !user.subscription_plus}">
                    <div class="headline">
                        <span class="title">Standard</span>
                        <div class="price" v-if="!user.subscription_active">
                            <span class="amount" v-if="billingCycle === 'annually'">
                                <template v-if="currency === 'dollar'">$</template>{{pricesAnnuallyDollarOptions[priceIndex]}}<template v-if="currency === 'euro'">€</template>
                            </span>
                            <span class="amount" v-else>
                                <template v-if="currency === 'dollar'">$</template>{{pricesMonthlyDollarOptions[priceIndex]}}<template v-if="currency === 'euro'">€</template>
                            </span>
                            <span class="after" v-if="billingCycle === 'annually'">/{{t("year")}}</span>
                            <span class="after" v-else>/{{t("month")}}</span>
                        </div>
                    </div>
                    <hr />
                    <ul class="features">
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.websites")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.api_sdks")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.member")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.custom_events")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.data_retention")}}</span>
                        </li>
                        <li>
                            <i class="icon icon-check"></i>
                            <span>{{t("features.import")}}</span>
                        </li>
                    </ul>
                    <div class="row">
                        <button v-if="user.stripe_subscription_id && user.subscription_active && !user.subscription_plus" v-on:click="manageSubscription(false)">{{t("checkout.manage")}}</button>
                        <button v-else-if="user.stripe_subscription_id && user.subscription_active && user.subscription_plus" v-on:click="manageSubscription(false)">{{t("checkout.downgrade")}}</button>
                        <a href="mailto:support@pirsch.io" class="button" v-else-if="!user.stripe_subscription_id && user.subscription_active">{{t("checkout.contact")}}</a>
                        <button v-else v-on:click="checkout(false)">{{t("checkout.subscribe")}}</button>
                    </div>
                </div>
                <a :href="featureTable" target="_blank" class="button secondary">{{t("compare")}}</a>
                <i18n-t tag="p" keypath="custom" style="margin-top: 24px;">
                    <a href="mailto:support@pirsch.io">{{t("get_in_touch")}}</a>
                </i18n-t>
            </template>
            <Loading :loading="loading" />
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import {CheckoutSessionResponse, PortalSessionResponse, UserRepo} from "@/repositories/UserRepo";
    import {loadStripe} from "@stripe/stripe-js/pure";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {day} from "@/util/format";
    import Loading from "@/components/bits/Loading.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {Loading},
        setup() {
            const {user} = storeToRefs(useUserStore());
            const currency = ref("dollar");
            const billingCycle = ref("annually");
            const priceIndex = ref(0);
            const loading = ref(false);
            const hasSubscription = computed(() => !!user.value.stripe_subscription_id);

            async function checkout(plus: boolean) {
                loading.value = true;
                const session = await UserRepo.createCheckoutSession(plus, currency.value, billingCycle.value, parseInt(priceIndex.value as unknown as string)) as CheckoutSessionResponse;
                const stripe = await loadStripe(import.meta.env.VITE_APP_STRIPE_KEY as string);

                if (stripe) {
                    await stripe.redirectToCheckout({
                        sessionId: session.session_id
                    });
                }

                loading.value = false;
            }

            async function manageSubscription(plus: boolean) {
                const session = await UserRepo.createPortalSession(plus) as PortalSessionResponse;
                location.href = session.url;
            }

            return {
                ...useI18n(),
                featureTable: `${import.meta.env.VITE_APP_WEBSITE_HOST}/pricing#tier-comparison`,
                day,
                pageViews: [
                    "10,000",
                    "100,000",
                    "200,000",
                    "500,000",
                    "1 Million",
                    "5 Million",
                    "10 Million",
                    "20 Million",
                    "50 Million",
                    "100 Million"
                ],
                pricesMonthlyDollarOptions: [
                    "6", "12", "18", "36", "54", "99", "159", "249", "499", "799"
                ],
                pricesAnnuallyDollarOptions: [
                    "60", "120", "180", "360", "540", "990", "1,590", "2,490", "4,990", "7,990"
                ],
                plusPricesMonthlyDollarOptions: [
                    "12", "27", "45", "99", "159", "299", "479", "749", "1,499", "2,399"
                ],
                plusPricesAnnuallyDollarOptions: [
                    "120", "270", "450", "990", "1,590", "2,990", "4,790", "7,490", "14,990", "23,990"
                ],
                user,
                currency,
                billingCycle,
                priceIndex,
                loading,
                hasSubscription,
                checkout,
                manageSubscription
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Subscription",
            "text.no_subscription": "Select your subscription plan. The remaining free trial period will be added to your subscription.",
            "text.subscription": "Change or cancel your current subscription.",
            "text.limit": "Page views and events are counted towards the limit of the subscription of the owner of a domain. Members don't require a subscription.",
            "downgrade": "Your subscription will be downgraded on {0}.",
            "limit": "Monthly Page Views",
            "monthly": "Monthly",
            "annually": "Annually",
            "payment.usd": "Credit Card, PayPal",
            "payment.eur": "Credit Card, PayPal, SEPA",
            "text.manage": "Click {0} below to change your plan and to view invoices.",
            "text.manage.strong": "Manage Subscription",
            "year": "year",
            "month": "month",
            "features.standard": "Everything in Standard",
            "features.custom_domains": "Custom Domains",
            "features.unlimited_websites": "Unlimited Websites",
            "features.custom_themes": "Custom Themes",
            "features.organizations": "Organizations",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 Websites",
            "features.api_sdks": "RESTful API & SDKs",
            "features.member": "Unlimited Members",
            "features.custom_events": "Custom Events",
            "features.data_retention": "Unlimited Data Retention",
            "features.import": "Google Analytics Import",
            "compare": "Compare All Features",
            "custom": "Do you have more than 100 million page views per month or want to host yourself? {0} for a custom quote!",
            "get_in_touch": "Get in touch",
            "checkout.subscribe": "Subscribe",
            "checkout.contact": "Contact us",
            "checkout.upgrade": "Upgrade to Plus",
            "checkout.downgrade": "Downgrade to Standard",
            "checkout.manage": "Manage Subscription"
        },
        "de": {
            "title": "Abonnement",
            "text.no_subscription": "Wähle deinen Abonnementplan. Die verbleibende kostenlose Testzeit wird deinem Abonnement hinzugefügt.",
            "text.subscription": "Ändere oder kündige dein aktuelles Abonnement.",
            "text.limit": "Seitenaufrufe und Events werden auf das Limit des Abonnements des Domaininhabers angerechnet. Mitglieder benötigen kein Abonnement.",
            "downgrade": "Dein Abonnement wird am {0} herabgestuft.",
            "limit": "Monatliche Seitenaufrufe",
            "monthly": "Monatlich",
            "annually": "Jährlich",
            "payment.usd": "Kreditkarte, PayPal",
            "payment.eur": "Kreditkarte, PayPal, SEPA",
            "text.manage": "Klicke unten auf {0}, um deinen Plan zu ändern und Rechnungen anzusehen.",
            "text.manage.strong": "Abonnement verwalten",
            "year": "Jahr",
            "month": "Monat",
            "features.standard": "Alles in Standard",
            "features.custom_domains": "Benutzerdefinierte Domains",
            "features.unlimited_websites": "Unbegrenzte Webseiten",
            "features.custom_themes": "Benutzerdefinierte Themen",
            "features.organizations": "Organisationen",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 Webseiten",
            "features.api_sdks": "RESTful API & SDKs",
            "features.member": "Unbegrenzte Mitglieder",
            "features.custom_events": "Benutzerdefinierte Events",
            "features.data_retention": "Unbegrenzte Datenspeicherung",
            "features.import": "Google Analytics Import",
            "compare": "Alle Funktionen vergleichen",
            "custom": "Hast du mehr als 100 Millionen Seitenaufrufe pro Monat oder möchtest du selbst hosten? {0} für ein individuelles Angebot!",
            "get_in_touch": "Kontakt aufnehmen",
            "checkout.subscribe": "Abonnieren",
            "checkout.contact": "Kontaktiere uns",
            "checkout.upgrade": "Upgrade auf Plus",
            "checkout.downgrade": "Downgrade auf Standard",
            "checkout.manage": "Abonnement verwalten"
        },
        "es": {
            "title": "Suscripción",
            "text.no_subscription": "Selecciona tu plan de suscripción. El período de prueba gratuito restante se añadirá a tu suscripción.",
            "text.subscription": "Cambia o cancela tu suscripción actual.",
            "text.limit": "Las vistas de página y los eventos se cuentan hacia el límite de la suscripción del propietario de un dominio. Los miembros no requieren una suscripción.",
            "downgrade": "Tu suscripción se degradará el {0}.",
            "limit": "Vistas de página mensuales",
            "monthly": "Mensual",
            "annually": "Anual",
            "payment.usd": "Tarjeta de crédito, PayPal",
            "payment.eur": "Tarjeta de crédito, PayPal, SEPA",
            "text.manage": "Haz clic en {0} a continuación para cambiar tu plan y ver las facturas.",
            "text.manage.strong": "Gestionar suscripción",
            "year": "año",
            "month": "mes",
            "features.standard": "Todo en Estándar",
            "features.custom_domains": "Dominios personalizados",
            "features.unlimited_websites": "Sitios web ilimitados",
            "features.custom_themes": "Temas personalizados",
            "features.organizations": "Organizaciones",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 sitios web",
            "features.api_sdks": "API RESTful & SDKs",
            "features.member": "Miembros ilimitados",
            "features.custom_events": "Eventos personalizados",
            "features.data_retention": "Retención de datos ilimitada",
            "features.import": "Importación de Google Analytics",
            "compare": "Comparar todas las características",
            "custom": "¿Tienes más de 100 millones de vistas de página por mes o quieres alojar tú mismo? {0} para una cotización personalizada!",
            "get_in_touch": "Ponerse en contacto",
            "checkout.subscribe": "Suscribirse",
            "checkout.contact": "Contáctanos",
            "checkout.upgrade": "Actualizar a Plus",
            "checkout.downgrade": "Degradar a Estándar",
            "checkout.manage": "Gestionar suscripción"
        },
        "fr": {
            "title": "Abonnement",
            "text.no_subscription": "Sélectionnez votre plan d'abonnement. La période d'essai gratuite restante sera ajoutée à votre abonnement.",
            "text.subscription": "Changez ou annulez votre abonnement actuel.",
            "text.limit": "Les vues de page et les événements sont comptabilisés dans la limite de l'abonnement du propriétaire d'un domaine. Les membres n'ont pas besoin d'abonnement.",
            "downgrade": "Votre abonnement sera rétrogradé le {0}.",
            "limit": "Vues de page mensuelles",
            "monthly": "Mensuel",
            "annually": "Annuel",
            "payment.usd": "Carte de crédit, PayPal",
            "payment.eur": "Carte de crédit, PayPal, SEPA",
            "text.manage": "Cliquez sur {0} ci-dessous pour changer votre plan et voir les factures.",
            "text.manage.strong": "Gérer l'abonnement",
            "year": "an",
            "month": "mois",
            "features.standard": "Tout dans Standard",
            "features.custom_domains": "Domaines personnalisés",
            "features.unlimited_websites": "Sites web illimités",
            "features.custom_themes": "Thèmes personnalisés",
            "features.organizations": "Organisations",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 sites web",
            "features.api_sdks": "API RESTful & SDKs",
            "features.member": "Membres illimités",
            "features.custom_events": "Événements personnalisés",
            "features.data_retention": "Rétention de données illimitée",
            "features.import": "Importation Google Analytics",
            "compare": "Comparer toutes les fonctionnalités",
            "custom": "Avez-vous plus de 100 millions de vues de page par mois ou souhaitez-vous héberger vous-même? {0} pour un devis personnalisé!",
            "get_in_touch": "Entrer en contact",
            "checkout.subscribe": "S'abonner",
            "checkout.contact": "Contactez-nous",
            "checkout.upgrade": "Passer à Plus",
            "checkout.downgrade": "Rétrograder à Standard",
            "checkout.manage": "Gérer l'abonnement"
        },
        "nl": {
            "title": "Abonnement",
            "text.no_subscription": "Selecteer je abonnement. De resterende gratis proefperiode wordt toegevoegd aan je abonnement.",
            "text.subscription": "Wijzig of annuleer je huidige abonnement.",
            "text.limit": "Paginabezoeken en evenementen tellen mee voor de limiet van het abonnement van de eigenaar van een domein. Leden hebben geen abonnement nodig.",
            "downgrade": "Je abonnement wordt op {0} verlaagd.",
            "limit": "Maandelijkse paginabezoeken",
            "monthly": "Maandelijks",
            "annually": "Jaarlijks",
            "payment.usd": "Creditcard, PayPal",
            "payment.eur": "Creditcard, PayPal, SEPA",
            "text.manage": "Klik hieronder op {0} om je plan te wijzigen en facturen te bekijken.",
            "text.manage.strong": "Abonnement beheren",
            "year": "jaar",
            "month": "maand",
            "features.standard": "Alles in Standaard",
            "features.custom_domains": "Aangepaste domeinen",
            "features.unlimited_websites": "Onbeperkte websites",
            "features.custom_themes": "Aangepaste thema's",
            "features.organizations": "Organisaties",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 websites",
            "features.api_sdks": "RESTful API & SDKs",
            "features.member": "Onbeperkte leden",
            "features.custom_events": "Aangepaste evenementen",
            "features.data_retention": "Onbeperkte gegevensbewaring",
            "features.import": "Google Analytics importeren",
            "compare": "Vergelijk alle functies",
            "custom": "Heb je meer dan 100 miljoen paginabezoeken per maand of wil je zelf hosten? {0} voor een aangepaste offerte!",
            "get_in_touch": "Neem contact op",
            "checkout.subscribe": "Abonneren",
            "checkout.contact": "Neem contact op",
            "checkout.upgrade": "Upgrade naar Plus",
            "checkout.downgrade": "Downgrade naar Standaard",
            "checkout.manage": "Abonnement beheren"
        },
        "it": {
            "title": "Abbonamento",
            "text.no_subscription": "Seleziona il tuo piano di abbonamento. Il periodo di prova gratuito rimanente verrà aggiunto al tuo abbonamento.",
            "text.subscription": "Cambia o annulla il tuo abbonamento attuale.",
            "text.limit": "Le visualizzazioni di pagina e gli eventi vengono conteggiati nel limite dell'abbonamento del proprietario di un dominio. I membri non richiedono un abbonamento.",
            "downgrade": "Il tuo abbonamento verrà declassato il {0}.",
            "limit": "Visualizzazioni di pagina mensili",
            "monthly": "Mensile",
            "annually": "Annuale",
            "payment.usd": "Carta di credito, PayPal",
            "payment.eur": "Carta di credito, PayPal, SEPA",
            "text.manage": "Clicca {0} qui sotto per cambiare il tuo piano e visualizzare le fatture.",
            "text.manage.strong": "Gestisci abbonamento",
            "year": "anno",
            "month": "mese",
            "features.standard": "Tutto in Standard",
            "features.custom_domains": "Domini personalizzati",
            "features.unlimited_websites": "Siti web illimitati",
            "features.custom_themes": "Temi personalizzati",
            "features.organizations": "Organizzazioni",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 siti web",
            "features.api_sdks": "API RESTful & SDKs",
            "features.member": "Membri illimitati",
            "features.custom_events": "Eventi personalizzati",
            "features.data_retention": "Conservazione dati illimitata",
            "features.import": "Importazione Google Analytics",
            "compare": "Confronta tutte le funzionalità",
            "custom": "Hai più di 100 milioni di visualizzazioni di pagina al mese o vuoi ospitare tu stesso? {0} per un preventivo personalizzato!",
            "get_in_touch": "Mettersi in contatto",
            "checkout.subscribe": "Abbonati",
            "checkout.contact": "Contattaci",
            "checkout.upgrade": "Passa a Plus",
            "checkout.downgrade": "Declassa a Standard",
            "checkout.manage": "Gestisci abbonamento"
        },
        "pt": {
            "title": "Assinatura",
            "text.no_subscription": "Selecione seu plano de assinatura. O período de teste gratuito restante será adicionado à sua assinatura.",
            "text.subscription": "Altere ou cancele sua assinatura atual.",
            "text.limit": "As visualizações de páginas e eventos são contadas para o limite da assinatura do proprietário de um domínio. Os membros não precisam de uma assinatura.",
            "downgrade": "Sua assinatura será rebaixada em {0}.",
            "limit": "Visualizações de página mensais",
            "monthly": "Mensal",
            "annually": "Anual",
            "payment.usd": "Cartão de crédito, PayPal",
            "payment.eur": "Cartão de crédito, PayPal, SEPA",
            "text.manage": "Clique em {0} abaixo para alterar seu plano e visualizar faturas.",
            "text.manage.strong": "Gerenciar assinatura",
            "year": "ano",
            "month": "mês",
            "features.standard": "Tudo em Standard",
            "features.custom_domains": "Domínios personalizados",
            "features.unlimited_websites": "Sites ilimitados",
            "features.custom_themes": "Temas personalizados",
            "features.organizations": "Organizações",
            "features.white_labeling": "White Labeling",
            "features.websites": "50 sites",
            "features.api_sdks": "API RESTful & SDKs",
            "features.member": "Membros ilimitados",
            "features.custom_events": "Eventos personalizados",
            "features.data_retention": "Retenção de dados ilimitada",
            "features.import": "Importação do Google Analytics",
            "compare": "Comparar todas as funcionalidades",
            "custom": "Você tem mais de 100 milhões de visualizações de página por mês ou quer hospedar você mesmo? {0} para uma cotação personalizada!",
            "get_in_touch": "Entre em contato",
            "checkout.subscribe": "Assine",
            "checkout.contact": "Contate-nos",
            "checkout.upgrade": "Atualize para Plus",
            "checkout.downgrade": "Rebaixe para Standard",
            "checkout.manage": "Gerenciar assinatura"
        },
        "ja": {
            "title": "サブスクリプション",
            "text.no_subscription": "サブスクリプションプランを選択してください。残りの無料試用期間はサブスクリプションに追加されます。",
            "text.subscription": "現在のサブスクリプションを変更またはキャンセルします。",
            "text.limit": "ページビューとイベントはドメイン所有者のサブスクリプションの制限にカウントされます。メンバーはサブスクリプションを必要としません。",
            "downgrade": "サブスクリプションは{0}にダウングレードされます。",
            "limit": "月間ページビュー",
            "monthly": "月間",
            "annually": "年間",
            "payment.usd": "クレジットカード、PayPal",
            "payment.eur": "クレジットカード、PayPal、SEPA",
            "text.manage": "以下の{0}をクリックして、プランを変更し、請求書を表示します。",
            "text.manage.strong": "サブスクリプションを管理する",
            "year": "年",
            "month": "月",
            "features.standard": "標準のすべて",
            "features.custom_domains": "カスタムドメイン",
            "features.unlimited_websites": "無制限のウェブサイト",
            "features.custom_themes": "カスタムテーマ",
            "features.organizations": "組織",
            "features.white_labeling": "ホワイトラベリング",
            "features.websites": "50ウェブサイト",
            "features.api_sdks": "RESTful API & SDKs",
            "features.member": "無制限のメンバー",
            "features.custom_events": "カスタムイベント",
            "features.data_retention": "無制限のデータ保持",
            "features.import": "Google Analyticsインポート",
            "compare": "すべての機能を比較",
            "custom": "月間ページビューが1億を超える場合や、自分でホストしたい場合は、カスタム見積もりのために{0}してください!",
            "get_in_touch": "お問い合わせ",
            "checkout.subscribe": "サブスクリプションに登録",
            "checkout.contact": "お問い合わせ",
            "checkout.upgrade": "Plusにアップグレード",
            "checkout.downgrade": "Standardにダウングレード",
            "checkout.manage": "サブスクリプションを管理"
        }
    }
</i18n>
