<template>
    <SettingsPage active="dangerZone">
        <Hostname />
        <hr />
        <TransferOwnership />
        <hr />
        <DeleteData />
        <hr />
        <DeleteDomain />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import TransferOwnership from "@/components/settings/TransferOwnership.vue";
    import DeleteData from "@/components/settings/DeleteData.vue";
    import DeleteDomain from "@/components/settings/DeleteDomain.vue";
    import Hostname from "@/components/settings/Hostname.vue";

    export default defineComponent({
        components: {
            Hostname,
            SettingsPage,
            TransferOwnership,
            DeleteData,
            DeleteDomain
        }
    });
</script>
