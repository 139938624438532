export interface TimeRange {
    from: Date
    to: Date
    fromTime?: Time
    toTime?: Time
    start: number

    // used to store time range as JSON in localStorage
    fromDate?: string
    toDate?: string
}

export class Time {
    public hour: number = 0;
    public minute: number = 0;

    public constructor(hour: number, minute: number) {
        this.hour = hour;
        this.minute = minute;
    }

    public seconds(): number {
        return this.hour*3600+this.minute*60;
    }
}
