<template>
    <InviteMember :open="inviteUserModal"
        :organization="organization"
        v-on:action="loadInvitations"
        v-on:close="inviteUserModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/access#organizations" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <MemberCard v-for="m in member"
                        :key="m.id"
                        :member="m"
                        :organization="true"
                        v-on:remove="removeUser" />
                </div>
            </Expand>
            <button v-on:click="inviteUserModal = true" v-if="organization.can_edit">
                <Loading :loading="loading" />
                <span>{{t("invite")}}</span>
            </button>
        </div>
    </section>
    <section class="row" v-if="invitations.length">
        <div>
            <h3>{{t("invitations.title")}}</h3>
        </div>
        <div>
            <OpenInvitationCard v-for="invitation of invitations"
                :key="invitation.id"
                :invitation="invitation"
                v-on:remove="removeInvitation" />
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, PropType, ref} from "vue";
    import {Member} from "@/model/Member";
    import {Invitation} from "@/model/Invitation";
    import MemberCard from "@/components/cards/MemberCard.vue";
    import InviteMember from "@/components/modal/InviteMember.vue";
    import OpenInvitationCard from "@/components/cards/OpenInvitationCard.vue";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {Organization} from "@/model/Organization";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {InvitationRepo} from "@/repositories/InvitationRepo";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            InviteMember,
            MemberCard,
            OpenInvitationCard,
            Loading,
            Expand
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        setup(props) {
            const {removeEntryByID} = useList();
            const inviteUserModal = ref(false);
            const removeUserModal = ref(false);
            const loading = ref(true);
            const member = ref<Member[]>([]);
            const invitations = ref<Invitation[]>([]);

            onMounted(async () => {
                loading.value = true;
                member.value = await MemberRepo.list("", props.organization.id) as Member[] || [];
                loading.value = false;
                await loadInvitations();
            });

            async function loadInvitations() {
                inviteUserModal.value = false;
                invitations.value = await InvitationRepo.list("", props.organization.id) as Invitation[] || [];
            }

            function removeUser(m: Member) {
                removeEntryByID(member.value, m);
            }

            function removeInvitation(invitation: Invitation) {
                removeEntryByID(invitations.value, invitation);
            }

            return {
                ...useI18n(),
                inviteUserModal,
                removeUserModal,
                loading,
                member,
                invitations,
                loadInvitations,
                removeUser,
                removeInvitation
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Members",
            "text": "Manage members and roles for this organization.",
            "link": "Read the Docs",
            "invite": "Invite Member",
            "invitations.title": "Open Invitations"
        },
        "de": {
            "title": "Mitglieder",
            "text": "Verwalte Mitglieder und Rollen für diese Organisation.",
            "link": "Dokumentation lesen",
            "invite": "Mitglied einladen",
            "invitations.title": "Offene Einladungen"
        },
        "es": {
            "title": "Miembros",
            "text": "Gestionar miembros y roles para esta organización.",
            "link": "Leer la documentación",
            "invite": "Invitar a miembro",
            "invitations.title": "Invitaciones abiertas"
        },
        "fr": {
            "title": "Membres",
            "text": "Gérer les membres et les rôles de cette organisation.",
            "link": "Lire la documentation",
            "invite": "Inviter un membre",
            "invitations.title": "Invitations en cours"
        },
        "nl": {
            "title": "Leden",
            "text": "Beheer leden en rollen voor deze organisatie.",
            "link": "Lees de documentatie",
            "invite": "Lid uitnodigen",
            "invitations.title": "Open uitnodigingen"
        },
        "it": {
            "title": "Membri",
            "text": "Gestisci membri e ruoli per questa organizzazione.",
            "link": "Leggi la documentazione",
            "invite": "Invita membro",
            "invitations.title": "Inviti aperti"
        },
        "pt": {
            "title": "Membros",
            "text": "Gerenciar membros e funções para esta organização.",
            "link": "Leia a documentação",
            "invite": "Convidar membro",
            "invitations.title": "Convites abertos"
        },
        "ja": {
            "title": "メンバー",
            "text": "この組織のメンバーと役割を管理します。",
            "link": "ドキュメントを読む",
            "invite": "メンバーを招待",
            "invitations.title": "未処理の招待"
        }
    }
</i18n>
