<template>
    <CountryMapModal :open="countryMapModal" v-on:close="countryMapModal = false" />
    <div class="list">
        <ListTabs :tabs="tabs" v-on:enlarge="enlarge" v-on:open="openTab">
            <template v-slot:icons v-if="open === 0">
                <i class="icon icon-map clickable"
                    :title="t('map')"
                    v-on:click="countryMapModal = true" />
            </template>
        </ListTabs>
        <Country :visible="open === 0" v-show="open === 0" />
        <Region :visible="open === 1" v-show="open === 1" />
        <City :visible="open === 2" v-show="open === 2" />
        <Language :visible="open === 3" v-show="open === 3" />
        <CountryDetails :open="details === 0" v-on:close="details = -1" />
        <RegionDetails :open="details === 1" v-on:close="details = -1" />
        <CityDetails :open="details === 2" v-on:close="details = -1" />
        <LanguageDetails :open="details === 3" v-on:close="details = -1" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import Country from "@/components/statistics/Country.vue";
    import Language from "@/components/statistics/Language.vue";
    import City from "@/components/statistics/City.vue";
    import CountryDetails from "@/components/statistics/CountryDetails.vue";
    import LanguageDetails from "@/components/statistics/LanguageDetails.vue";
    import CityDetails from "@/components/statistics/CityDetails.vue";
    import Region from "@/components/statistics/Region.vue";
    import RegionDetails from "@/components/statistics/RegionDetails.vue";
    import CountryMapModal from "@/components/modal/CountryMapModal.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            RegionDetails,
            Region,
            ListTabs,
            Country,
            Language,
            City,
            CountryDetails,
            LanguageDetails,
            CityDetails,
            CountryMapModal
        },
        setup() {
            const {t} = useI18n();
            const open = ref(0);
            const details = ref(-1);
            const countryMapModal = ref(false);

            function enlarge(index: number) {
                details.value = index;
            }

            function openTab(index: number) {
                open.value = index;
            }

            return {
                t,
                tabs: [
                    {
                        title: t("tabs.countries"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.regions"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.cities"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.languages"),
                        showEnlarge: true
                    }
                ],
                open,
                details,
                countryMapModal,
                enlarge,
                openTab
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "map": "Show Map",
            "tabs.countries": "Countries",
            "tabs.regions": "Regions",
            "tabs.cities": "Cities",
            "tabs.languages": "Languages"
        },
        "de": {
            "map": "Karte anzeigen",
            "tabs.countries": "Länder",
            "tabs.regions": "Regionen",
            "tabs.cities": "Städte",
            "tabs.languages": "Sprachen"
        },
        "es": {
            "map": "Mostrar mapa",
            "tabs.countries": "Países",
            "tabs.regions": "Regiones",
            "tabs.cities": "Ciudades",
            "tabs.languages": "Idiomas"
        },
        "fr": {
            "map": "Afficher la carte",
            "tabs.countries": "Pays",
            "tabs.regions": "Régions",
            "tabs.cities": "Villes",
            "tabs.languages": "Langues"
        },
        "nl": {
            "map": "Kaart weergeven",
            "tabs.countries": "Landen",
            "tabs.regions": "Regio's",
            "tabs.cities": "Steden",
            "tabs.languages": "Talen"
        },
        "it": {
            "map": "Mostra mappa",
            "tabs.countries": "Paesi",
            "tabs.regions": "Regioni",
            "tabs.cities": "Città",
            "tabs.languages": "Lingue"
        },
        "pt": {
            "map": "Mostrar mapa",
            "tabs.countries": "Países",
            "tabs.regions": "Regiões",
            "tabs.cities": "Cidades",
            "tabs.languages": "Idiomas"
        },
        "ja": {
            "map": "地図を表示",
            "tabs.countries": "国",
            "tabs.regions": "地域",
            "tabs.cities": "都市",
            "tabs.languages": "言語"
        }
    }
</i18n>
