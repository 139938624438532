<template>
    <SettingsPage active="access">
        <Access />
        <hr />
        <AccessLinks />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Access from "@/components/settings/Access.vue";
    import AccessLinks from "@/components/settings/AccessLinks.vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";

    export default defineComponent({
        components: {
            SettingsPage,
            Access,
            AccessLinks
        }
    });
</script>
