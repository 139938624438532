<template>
    <template v-if="loggedIn && isDashboard && (show || !hasPlus)">
        <div class="billing-card" style="max-width: 100%; margin-top: 8px;" v-if="!small">
            <slot></slot>
            <div class="row">
                <router-link to="/billing" class="button yellow">{{t("link.upgrade")}}</router-link>
                <a :href="pricing" target="_blank" class="button secondary">{{t("link.compare")}}</a>
            </div>
        </div>
        <small v-else>
            <slot></slot> <router-link to="/billing" class="button yellow small" style="margin-left: 6px;">{{t("link.upgrade_short")}}</router-link>
        </small>
    </template>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";
    import {isCustomDomain, isDashboard} from "@/util/domain";

    export default defineComponent({
        props: {
            show: Boolean,
            small: Boolean
        },
        setup() {
            const {loggedIn, hasPlus} = storeToRefs(useUserStore());

            return {
                ...useI18n(),
                pricing: `${import.meta.env.VITE_APP_WEBSITE_HOST}/pricing#tier-comparison`,
                isDashboard: isDashboard() || isCustomDomain(),
                loggedIn,
                hasPlus
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "link.upgrade": "Upgrade to Pirsch Plus",
            "link.compare": "Compare Plans",
            "link.upgrade_short": "Upgrade"
        },
        "de": {
            "link.upgrade": "Upgrade auf Pirsch Plus",
            "link.compare": "Pläne vergleichen",
            "link.upgrade_short": "Upgrade"
        },
        "es": {
            "link.upgrade": "Actualizar a Pirsch Plus",
            "link.compare": "Comparar planes",
            "link.upgrade_short": "Actualizar"
        },
        "fr": {
            "link.upgrade": "Passer à Pirsch Plus",
            "link.compare": "Comparer les plans",
            "link.upgrade_short": "Mise à niveau"
        },
        "nl": {
            "link.upgrade": "Upgrade naar Pirsch Plus",
            "link.compare": "Vergelijk plannen",
            "link.upgrade_short": "Upgrade"
        },
        "it": {
            "link.upgrade": "Aggiorna a Pirsch Plus",
            "link.compare": "Confronta piani",
            "link.upgrade_short": "Aggiorna"
        },
        "pt": {
            "link.upgrade": "Atualizar para Pirsch Plus",
            "link.compare": "Comparar planos",
            "link.upgrade_short": "Atualizar"
        },
        "ja": {
            "link.upgrade": "Pirsch Plusにアップグレード",
            "link.compare": "プランを比較",
            "link.upgrade_short": "アップグレード"
        }
    }
</i18n>
