import {APIError} from "@/repositories/APIError";
import {ConversionGoal} from "@/model/ConversionGoal";
import {Repository} from "@/repositories/Repository";
import {CustomMetricType} from "@/model/Filter";

export interface RegexValidationResponse {
    match: boolean
}

export const ConversionGoalRepo = new class extends Repository {
    async list(id: string): Promise<ConversionGoal[] | APIError> {
        return this.performGet<ConversionGoal[]>("/goal", {id}, []);
    }

    async createUpdate(id: string,
        domain_id: string,
        name: string,
        path_pattern: string,
        regex: string,
        sample: string,
        event_name: string,
        event_meta_key: string,
        event_meta_value: string,
        custom_metric_key: string,
        custom_metric_type: CustomMetricType,
        visitors: number,
        cr: number,
        custom_metric_total: number,
        custom_metric_avg: number,
        delete_reached: boolean,
        email_reached: boolean): Promise<ConversionGoal | APIError> {
         return this.performPost<ConversionGoal>("/goal", {
            id,
            domain_id,
            name,
            path_pattern,
            regex,
            sample,
            event_name,
            event_meta_key,
            event_meta_value,
            custom_metric_key,
            custom_metric_type,
            visitors,
            cr,
            custom_metric_total,
            custom_metric_avg,
            delete_reached,
            email_reached
        });
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/goal", {id});
    }

    async testRegex(regex: string, sample: string): Promise<RegexValidationResponse | APIError> {
        return this.performPost<RegexValidationResponse>("/goal/regex", {regex, sample});
    }
}
