<template>
    <Modal size="l" :title="t('title')" :open="open">
        <Keywords component="TableEntries"
            :showTitle="false"
            :showEnlarge="false"
            :limitless="true"
            :showDetails="true"
            :visible="true" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import Keywords from "@/components/statistics/Keywords.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {entries_per_page, useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            Keywords
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const {filter} = storeToRefs(filterStore);
            const {loadKeywords} = useStatisticsStore();

            watch(() => props.open, open => {
                if (open) {
                    const f = filterStore.copy(filter.value);
                    f.limit = entries_per_page;
                    loadKeywords(f, false);
                }
            });

            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Keywords"
        },
        "de": {
            "title": "Keywords"
        },
        "es": {
            "title": "Palabras Clave"
        },
        "fr": {
            "title": "Mots-Clés"
        },
        "nl": {
            "title": "Trefwoorden"
        },
        "it": {
            "title": "Parole Chiave"
        },
        "pt": {
            "title": "Palavras-Chave"
        },
        "ja": {
            "title": "キーワード"
        }
    }
</i18n>