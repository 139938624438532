import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {APIError} from "@/repositories/APIError";
import {useI18n} from "vue-i18n";
import {errors} from "@/store/errors";

export const useToastStore = defineStore("toast", () => {
    const {t} = useI18n({
        messages: errors
    });
    const color = ref("");
    const message = ref("");
    const timeout = ref(0);

    function setToastTimeout() {
        if (timeout.value !== 0) {
            clearTimeout(timeout.value);
            timeout.value = 0;
        }

        timeout.value = setTimeout(() => {
            message.value = "";
            timeout.value = 0;
        }, 4000) as unknown as number;
    }

    function success(m: string) {
        color.value = "green";
        message.value = m;
        setToastTimeout();
    }

    function error(error: string | APIError) {
        if (typeof error === "string" || error.error.length > 0) {
            color.value = "red";

            if (typeof error === "string") {
                message.value = t(error);
            } else {
                message.value = error.error && error.error.length ? t(error.error[0]) : t("error");
            }

            setToastTimeout();
        }
    }

    return {
        color: computed(() => color.value || "green"),
        message,
        timeout,
        success,
        error
    };
});
