<template>
    <i :class="{'icon icon-pin clickable': true, 'color-accent': domain.pinned}"
        :title="domain.pinned ? t('unpin') : t('pin')"
        v-on:click.stop="$emit('pin', domain.id)"></i>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {Domain} from "@/model/Domain";
    import {PropType} from "vue/dist/vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        emits: ["pin"],
        props: {
            domain: {type: Object as PropType<Domain>, required: true}
        },
        setup() {
            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "pin": "Pin Dashboard",
            "unpin": "Unpin Dashboard"
        },
        "de": {
            "pin": "Dashboard anheften",
            "unpin": "Dashboard lösen"
        },
        "es": {
            "pin": "Fijar panel",
            "unpin": "Desfijar panel"
        },
        "fr": {
            "pin": "Épingler le tableau de bord",
            "unpin": "Désépingler le tableau de bord"
        },
        "nl": {
            "pin": "Dashboard vastmaken",
            "unpin": "Dashboard losmaken"
        },
        "it": {
            "pin": "Fissa dashboard",
            "unpin": "Rimuovi dashboard"
        },
        "pt": {
            "pin": "Fixar painel",
            "unpin": "Desafixar painel"
        },
        "ja": {
            "pin": "ダッシュボードを固定",
            "unpin": "ダッシュボードの固定を解除"
        }
    }
</i18n>
