<template>
    <ConfirmDeleteDomain :open="deleteDomainModal" v-on:close="deleteDomainModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <button class="red" v-on:click="deleteDomainModal = true">{{t("delete")}}</button>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import ConfirmDeleteDomain from "@/components/modal/ConfirmDeleteDomain.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmDeleteDomain
        },
        setup() {
            const deleteDomainModal = ref(false);

            return {
                ...useI18n(),
                deleteDomainModal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Delete Website",
            "text": "Remove this website from your dashboard and delete all data.",
            "delete": "Delete Website"
        },
        "de": {
            "title": "Webseite löschen",
            "text": "Entferne diese Webseite von deinem Dashboard und lösche alle Daten.",
            "delete": "Webseite löschen"
        },
        "es": {
            "title": "Eliminar sitio web",
            "text": "Elimina este sitio web de tu panel y borra todos los datos.",
            "delete": "Eliminar sitio web"
        },
        "fr": {
            "title": "Supprimer le site Web",
            "text": "Supprimez ce site Web de votre tableau de bord et supprimez toutes les données.",
            "delete": "Supprimer le site Web"
        },
        "nl": {
            "title": "Website verwijderen",
            "text": "Verwijder deze website van je dashboard en verwijder alle gegevens.",
            "delete": "Website verwijderen"
        },
        "it": {
            "title": "Elimina sito web",
            "text": "Rimuovi questo sito web dalla tua dashboard ed elimina tutti i dati.",
            "delete": "Elimina sito web"
        },
        "pt": {
            "title": "Excluir site",
            "text": "Remova este site do seu painel e exclua todos os dados.",
            "delete": "Excluir site"
        },
        "ja": {
            "title": "ウェブサイトを削除",
            "text": "このウェブサイトをダッシュボードから削除し、すべてのデータを削除します。",
            "delete": "ウェブサイトを削除"
        }
    }
</i18n>
