<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {description: filter.description, filter: filter.filter})}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {TrafficFilter} from "@/model/TrafficFilter";
    import {TrafficFilterRepo} from "@/repositories/TrafficFilterRepo";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            filter: {type: Object as PropType<TrafficFilter>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await TrafficFilterRepo.delete(props.filter.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text": "You are about to delete the \"{description}\" filter \"{filter}\". Are you sure you would like to continue?",
            "form.submit": "Delete",
            "toast.success": "The filter has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text": "Du bist dabei, den Filter \"{filter}\" mit der Beschreibung \"{description}\" zu löschen. Bist du sicher, dass du fortfahren möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Der Filter wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text": "Estás a punto de eliminar el filtro \"{filter}\" con la descripción \"{description}\". ¿Estás seguro de que deseas continuar?",
            "form.submit": "Eliminar",
            "toast.success": "El filtro ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text": "Vous êtes sur le point de supprimer le filtre \"{filter}\" avec la description \"{description}\". Êtes-vous sûr de vouloir continuer ?",
            "form.submit": "Supprimer",
            "toast.success": "Le filtre a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text": "Je staat op het punt het filter \"{filter}\" met de beschrijving \"{description}\" te verwijderen. Weet je zeker dat je wilt doorgaan?",
            "form.submit": "Verwijderen",
            "toast.success": "Het filter is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text": "Stai per eliminare il filtro \"{filter}\" con la descrizione \"{description}\". Sei sicuro di voler continuare?",
            "form.submit": "Elimina",
            "toast.success": "Il filtro è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text": "Você está prestes a excluir o filtro \"{filter}\" com a descrição \"{description}\". Tem certeza de que deseja continuar?",
            "form.submit": "Excluir",
            "toast.success": "O filtro foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text": "説明 \"{description}\" のフィルター \"{filter}\" を削除しようとしています。続行してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "フィルターが削除されました。"
        }
    }
</i18n>
