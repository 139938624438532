<template>
    <SettingsPage active="importExport">
        <ExportCSV />
        <hr />
        <GAImport />
        <hr />
        <PlausibleImport />
        <hr />
        <FathomImport />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import ExportCSV from "@/components/settings/ExportCSV.vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import GAImport from "@/components/settings/GAImport.vue";
    import FathomImport from "@/components/settings/FathomImport.vue";
    import PlausibleImport from "@/components/settings/PlausibleImport.vue";

    export default defineComponent({
        components: {
            PlausibleImport,
            FathomImport,
            GAImport,
            SettingsPage,
            ExportCSV
        }
    });
</script>
