import Cookies from "js-cookie";
import {getCookieDomain} from "@/util/domain";

export function useLogin() {
    async function login(accessToken: string, refreshToken: string, stayLoggedIn: boolean) {
        if (stayLoggedIn) {
            localStorage.setItem("stay_logged_in", "true");
        } else {
            localStorage.removeItem("stay_logged_in");
        }

        const domain = getCookieDomain();
        Cookies.set("access_token", accessToken, {
            expires: 1,
            secure: import.meta.env.VITE_APP_SECURE_COOKIES === "true",
            domain,
            path: "/",
            sameSite: "strict"
        });
        Cookies.set("refresh_token", refreshToken, {
            expires: stayLoggedIn ? 365 : 1,
            secure: import.meta.env.VITE_APP_SECURE_COOKIES === "true",
            domain,
            path: "/",
            sameSite: "strict"
        });
    }

    return {
        login
    };
}
