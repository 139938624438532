<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <Expand>
            <div v-if="!loading">
                <p style="font-size: 32px; font-weight: 500;">{{sum}}</p>
                <small style="margin-bottom: 8px;" v-if="count > 0">{{t("rewards", {count})}}</small>
                <div class="table">
                    <Expand>
                        <table v-show="showDetails">
                            <thead>
                                <tr>
                                    <th style="text-align: left;">
                                        <h5>{{t("table.date")}}</h5>
                                    </th>
                                    <th style="text-align: right;">
                                        <h5>{{t("table.reward")}}</h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="reward in rewards" :key="reward.id">
                                    <td>
                                        <div class="cell-content">
                                            {{day(reward.def_time)}}
                                        </div>
                                    </td>
                                    <td style="text-align: right;">
                                        <div class="cell-content">
                                            {{formatCents(reward.amount)}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Expand>
                </div>
                <button v-on:click="showDetails = !showDetails" v-if="rewards.length">
                    {{showDetails ? t("details.hide") : t("details.show")}}
                </button>
            </div>
        </Expand>
        <div style="display: flex; justify-content: center; align-items: center;">
            <Loading :loading="loading" />
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import {day, formatCents} from "@/util/format";
    import {Reward} from "@/model/Reward";
    import {Rewards, UserRepo} from "@/repositories/UserRepo";
    import Expand from "@/components/bits/Expand.vue";
    import Loading from "@/components/bits/Loading.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Expand,
            Loading
        },
        setup() {
            const loading = ref(true);
            const sum = ref(formatCents(0));
            const count = ref(0);
            const rewards = ref<Reward[]>([]);
            const showDetails = ref(false);

            onMounted(async () => {
                const r = await UserRepo.getRewards() as Rewards;
                sum.value = formatCents(r.sum);
                count.value = r.count;
                rewards.value = r.rewards;
                loading.value = false;
            });

            return {
                ...useI18n(),
                day,
                formatCents,
                loading,
                sum,
                count,
                rewards,
                showDetails
            }
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Rewards",
            "text": "See how much you have earned through your affiliate link.",
            "rewards": "from {count} rewards",
            "table.date": "Date",
            "table.reward": "Reward",
            "details.show": "Show Details",
            "details.hide": "Hide Details"
        },
        "de": {
            "title": "Belohnungen",
            "text": "Sieh, wie viel du über deinen Affiliate-Link verdient hast.",
            "rewards": "von {count} Belohnungen",
            "table.date": "Datum",
            "table.reward": "Belohnung",
            "details.show": "Details anzeigen",
            "details.hide": "Details verbergen"
        },
        "es": {
            "title": "Recompensas",
            "text": "Mira cuánto has ganado a través de tu enlace de afiliado.",
            "rewards": "de {count} recompensas",
            "table.date": "Fecha",
            "table.reward": "Recompensa",
            "details.show": "Mostrar detalles",
            "details.hide": "Ocultar detalles"
        },
        "fr": {
            "title": "Récompenses",
            "text": "Voyez combien vous avez gagné grâce à votre lien d'affiliation.",
            "rewards": "de {count} récompenses",
            "table.date": "Date",
            "table.reward": "Récompense",
            "details.show": "Afficher les détails",
            "details.hide": "Masquer les détails"
        },
        "nl": {
            "title": "Beloningen",
            "text": "Zie hoeveel je hebt verdiend via je affiliate-link.",
            "rewards": "van {count} beloningen",
            "table.date": "Datum",
            "table.reward": "Beloning",
            "details.show": "Details tonen",
            "details.hide": "Details verbergen"
        },
        "it": {
            "title": "Ricompense",
            "text": "Guarda quanto hai guadagnato tramite il tuo link di affiliazione.",
            "rewards": "da {count} ricompense",
            "table.date": "Data",
            "table.reward": "Ricompensa",
            "details.show": "Mostra dettagli",
            "details.hide": "Nascondi dettagli"
        },
        "pt": {
            "title": "Recompensas",
            "text": "Veja quanto você ganhou através do seu link de afiliado.",
            "rewards": "de {count} recompensas",
            "table.date": "Data",
            "table.reward": "Recompensa",
            "details.show": "Mostrar detalhes",
            "details.hide": "Ocultar detalhes"
        },
        "ja": {
            "title": "報酬",
            "text": "アフィリエイトリンクを通じて獲得した金額を確認してください。",
            "rewards": "{count} 件の報酬から",
            "table.date": "日付",
            "table.reward": "報酬",
            "details.show": "詳細を表示",
            "details.hide": "詳細を非表示"
        }
    }
</i18n>
