<template>
    <div class="sessions" v-if="!loading && !sessionsLimit">
        <SessionCard v-for="session in sessions" :session="session" />
    </div>
    <div v-else-if="!loading">
        <p style="max-width: 512px; margin: 24px auto; text-align: center; color: var(--color-secondary);">{{t("filter")}}</p>
    </div>
    <LoadingPage :loading="loading" />
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import SessionCard from "@/components/cards/SessionCard.vue";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            LoadingPage,
            SessionCard
        },
        setup() {
            const statisticsStore = useStatisticsStore();
            const {sessions, sessionsLimit} = storeToRefs(statisticsStore);
            const loading = computed(() => statisticsStore.isLoading("sessions"));

            return {
                ...useI18n(),
                sessions,
                sessionsLimit,
                loading
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "filter": "Please apply more filters or select a shorter time period to reduce the number of sessions. A maximum of 200 sessions can be loaded."
        },
        "de": {
            "filter": "Bitte wende weitere Filter an oder wähle einen kürzeren Zeitraum, um die Anzahl der Sitzungen zu reduzieren. Es können maximal 200 Sitzungen geladen werden."
        },
        "es": {
            "filter": "Por favor, aplica más filtros o selecciona un período de tiempo más corto para reducir el número de sesiones. Se pueden cargar un máximo de 200 sesiones."
        },
        "fr": {
            "filter": "Veuillez appliquer plus de filtres ou sélectionner une période plus courte pour réduire le nombre de sessions. Un maximum de 200 sessions peut être chargé."
        },
        "nl": {
            "filter": "Pas alstublieft meer filters toe of selecteer een kortere periode om het aantal sessies te verminderen. Er kunnen maximaal 200 sessies worden geladen."
        },
        "it": {
            "filter": "Si prega di applicare più filtri o selezionare un periodo di tempo più breve per ridurre il numero di sessioni. È possibile caricare un massimo di 200 sessioni."
        },
        "pt": {
            "filter": "Por favor, aplique mais filtros ou selecione um período de tempo mais curto para reduzir o número de sessões. Um máximo de 200 sessões pode ser carregado."
        },
        "ja": {
            "filter": "フィルタを追加で適用するか、期間を短くしてセッションの数を減らしてください。最大200セッションを読み込むことができます。"
        }
    }
</i18n>
