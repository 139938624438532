<template>
    <Toast />
    <div class="banners">
        <DomainCreatedBanner />
        <SubscriptionBanner />
        <PaymentFailedBanner />
    </div>
    <DevelopmentMode />
    <AppHeader />
    <AppNavigation />
    <DomainNoSubscription v-if="loggedIn && (isDashboard || isCustomDomain) && !subscriptionActive" />
    <FilterParams v-if="$route.name === 'Dashboard' || $route.name === 'Funnel' || $route.name === 'Sessions'" />
    <Invitations />
    <OwnershipTransfers />
    <router-view />
    <AppFooter v-if="!hideUI && !$route.meta.noRedirect" />
    <div class="switches">
        <LanguageSwitch />
        <ColorSwitch />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, onUnmounted, watch} from "vue";
    import Toast from "@/components/bits/Toast.vue";
    import SubscriptionBanner from "@/components/billing/SubscriptionBanner.vue";
    import AppHeader from "@/components/bits/AppHeader.vue";
    import Invitations from "@/components/bits/Invitations.vue";
    import OwnershipTransfers from "@/components/bits/OwnershipTransfers.vue";
    import AppNavigation from "@/components/bits/AppNavigation.vue";
    import FilterParams from "@/components/panels/FilterParams.vue";
    import AppFooter from "@/components/bits/AppFooter.vue";
    import DevelopmentMode from "@/components/bits/DevelopmentMode.vue";
    import ColorSwitch from "@/components/bits/ColorSwitch.vue";
    import {getQueryParam} from "@/util/url";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useDomainStore} from "@/store/DomainStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {useToastStore} from "@/store/ToastStore";
    import {useInit} from "@/init";
    import {useThemeStore} from "@/store/ThemeStore";
    import DomainCreatedBanner from "@/components/domain/DomainCreatedBanner.vue";
    import PaymentFailedBanner from "@/components/billing/PaymentFailedBanner.vue";
    import DomainNoSubscription from "@/components/domain/DomainNoSubscription.vue";
    import {isCustomDomain, isDashboard} from "@/util/domain";
    import LanguageSwitch from "@/components/bits/LanguageSwitch.vue";
    import {ThemeSettings} from "@/store/theme";

    export default defineComponent({
        components: {
            DomainNoSubscription,
            PaymentFailedBanner,
            DomainCreatedBanner,
            ColorSwitch,
            LanguageSwitch,
            DevelopmentMode,
            Toast,
            SubscriptionBanner,
            AppHeader,
            Invitations,
            OwnershipTransfers,
            AppNavigation,
            FilterParams,
            AppFooter
        },
        setup() {
            const {init, clear} = useInit();
            const userStore = useUserStore();
            const domainStore = useDomainStore();
            const statisticsStore = useStatisticsStore();
            const filterStore = useFilterStore();
            const {user, loggedIn} = storeToRefs(userStore);
            const {domain} = storeToRefs(domainStore);
            const {activeVisitors} = storeToRefs(statisticsStore);
            const {disableFilter} = storeToRefs(filterStore);
            const {success} = useToastStore();
            const {setTheme} = useThemeStore();
            const subscriptionActive = computed(() => !domain.value || domain.value.subscription_active);

            onMounted(init);
            onUnmounted(clear);

            watch(domain, domain => {
                if (domain) {
                    updatePageTitle();
                    setTheme(domain);
                    filterStore.loadViews();
                    statisticsStore.loadActiveVisitors();
                }
            });

            watch(disableFilter, disabled => {
                if (disabled) {
                    success("The current period contains imported statistics.");
                }
            });

            watch(user, user => {
                if (!user.id) {
                    clear();
                }
            });

            watch([user, activeVisitors, domain], updatePageTitle);

            function updatePageTitle() {
                if (domain.value && domain.value.hostname) {
                    const themeTitle = (domain.value.theme_settings ?? {})[ThemeSettings.title] ?? "";

                    if (user.value && user.value.active_visitors_title) {
                        setTitle(`🟢 ${activeVisitors.value.visitors} - ${domain.value.display_name ?? domain.value.hostname}`, themeTitle);
                    } else {
                        setTitle(domain.value.display_name ?? domain.value.hostname, themeTitle);
                    }
                } else {
                    setTitle();
                }
            }

            function setTitle(title?: string, themeTitle?: string): void {
                if (title) {
                    document.title = `${title} - Dashboard - ${themeTitle || import.meta.env.VITE_APP_PAGE_TITLE}`;
                } else {
                    document.title = `Dashboard - ${themeTitle ?? import.meta.env.VITE_APP_PAGE_TITLE}`;
                }
            }

            return {
                isDashboard: isDashboard(),
                isCustomDomain: isCustomDomain(),
                hideUI: getQueryParam("ui").toLowerCase() === "hide",
                loggedIn,
                subscriptionActive
            };
        }
    });
</script>

<style lang="scss">
    @import "vue3-colorpicker/style.css";
    @import "country-flag-icons/3x2/flags.css";
    @import "@/style/main.scss";
</style>
