<template>
    <CreateEditDomainAlternative :open="editDomainModal"
        v-on:close="editDomainModal = false"
        v-on:action="updateDomain"
        :domain="domain" />
    <ConfirmDeleteDomainAlternative :open="deleteDomainModal"
        v-on:close="deleteDomainModal = false"
        v-on:action="removeDomain"
        :domain="domain" />
    <div class="card">
        <div class="text">
            <p>
                <strong>{{domain.display_name ?? domain.hostname}}</strong>
            </p>
        </div>
        <div class="row">
            <i class="icon icon-follow clickable" :title="t('open')" v-on:click.stop="openPage" v-if="!domain.hostname.includes('*')" />
            <Lock :lock="`additional_domain_${domain.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editDomainModal = true"></i>
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteDomainModal = true"></i>
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {DomainAlternative} from "@/model/DomainAlternative";
    import CreateEditDomainAlternative from "@/components/modal/CreateEditDomainAlternative.vue";
    import ConfirmDeleteDomainAlternative from "@/components/modal/ConfirmDeleteDomainAlternative.vue";
    import {Domain} from "@/model/Domain";
    import Lock from "@/components/settings/Lock.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Lock,
            CreateEditDomainAlternative,
            ConfirmDeleteDomainAlternative
        },
        props: {
            domain: {type: Object as PropType<Domain>, required: true}
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const editDomainModal = ref(false);
            const deleteDomainModal = ref(false);

            function updateDomain(domain: DomainAlternative) {
                editDomainModal.value = false;
                emit("update", domain);
            }

            function removeDomain() {
                deleteDomainModal.value = false;
                emit("remove", props.domain);
            }

            function openPage(): void {
                window.open("https://" + props.domain.hostname, "_blank");
            }

            return {
                ...useI18n(),
                editDomainModal,
                deleteDomainModal,
                updateDomain,
                removeDomain,
                openPage
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "open": "Open Website",
            "edit": "Edit",
            "delete": "Delete"
        },
        "de": {
            "open": "Webseite öffnen",
            "edit": "Bearbeiten",
            "delete": "Löschen"
        },
        "es": {
            "open": "Abrir sitio web",
            "edit": "Editar",
            "delete": "Eliminar"
        },
        "fr": {
            "open": "Ouvrir le site web",
            "edit": "Éditer",
            "delete": "Supprimer"
        },
        "nl": {
            "open": "Website openen",
            "edit": "Bewerken",
            "delete": "Verwijderen"
        },
        "it": {
            "open": "Apri sito web",
            "edit": "Modifica",
            "delete": "Elimina"
        },
        "pt": {
            "open": "Abrir site",
            "edit": "Editar",
            "delete": "Excluir"
        },
        "ja": {
            "open": "ウェブサイトを開く",
            "edit": "編集",
            "delete": "削除"
        }
    }
</i18n>
