import axios from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import {APIError} from "@/repositories/APIError";
import {getCookieDomain} from "@/util/domain";
import {deleteAllCookies} from "@/util/cookie";

let refresh: Promise<void> | undefined;

const instance = axios.create({
    baseURL: `${import.meta.env.VITE_APP_BACKEND_HOST}/api/v1`,
    timeout: 30000,
    paramsSerializer: {
        serialize: params => qs.stringify(params, {arrayFormat: "repeat"})
    }
});

instance.interceptors.request.use(config => {
    const accessToken = Cookies.get("access_token");

    if (accessToken && config && config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return Promise.resolve(config);
}, err => {
    return Promise.reject(err);
});

instance.interceptors.response.use(resp => resp, async err => {
    if (err.code && (err.code === "ERR_CANCELED" || err.code === "ECONNABORTED")) {
        return Promise.reject(err);
    }

    if ((!err.response || err.response.status === 401) && err.config.url !== "/refresh") {
        return refreshToken().then(() => {
            return instance.request(err.config);
        }).catch(() => {
            return Promise.reject();
        });
    }

    return Promise.reject(err.response.data as APIError);
});

async function refreshToken() {
    if (refresh) {
        return refresh;
    }

    const refresh_token = Cookies.get("refresh_token");
    refresh = instance.get("/refresh", {params: {refresh_token}}).then(resp => {
        const {data: {access_token}} = resp;
        Cookies.set("access_token", access_token, {
            expires: localStorage.getItem("stay_logged_in") === "true" ? 365 : 1,
            secure: import.meta.env.VITE_APP_SECURE_COOKIES === "true",
            domain: getCookieDomain(),
            path: "/",
            sameSite: "strict"
        });
        refresh = undefined;
        return Promise.resolve();
    }).catch(() => {
        deleteAllCookies();
        localStorage.clear();

        if (location.pathname !== "/login") {
            location.href = "/login";
        }

        return Promise.reject();
    });
    return refresh;
}

export default instance;
