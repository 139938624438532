import {onMounted, onUnmounted, ref, Ref} from "vue";

interface Sticky {
    narrow: Ref<boolean>
}

export function useSticky(elementID: string): Sticky {
    const narrow = ref(false);

    onMounted(() => {
        setNarrowClass();
        window.addEventListener("scroll", setNarrowClass);
    });

    onUnmounted(() => {
        window.removeEventListener("scroll", setNarrowClass);
    });

    function setNarrowClass() {
        const element = document.getElementById(elementID);

        if (element) {
            narrow.value = window.scrollY >= element.getBoundingClientRect().top + window.scrollY &&
                window.scrollY !== 0;
        }
    }

    return {
        narrow
    };
}
