<template>
    <fieldset class="file">
        <input type="file"
            :id="name"
            :name="name"
            v-on:input="update"
            v-on:change.stop.prevent="change"
            ref="input" />
        <label :for="name">{{label}}</label>
        <input type="text" v-model="filename" :placeholder="t('placeholder')" readonly />
        <small v-if="hint" v-html="hint"></small>
        <small class="error" v-if="err">{{err}}</small>
    </fieldset>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            label: {type: String, required: true},
            name: {type: String, required: true},
            modelValue: Object as PropType<File | null>,
            err: String,
            hint: String,
        },
        emits: ["update:modelValue", "change"],
        setup(_, {emit}) {
            const input = ref(document.createElement("input"));
            const filename = ref("");

            function update(e: Event) {
                if (e && e.target) {
                    const files = input.value.files || [];

                    if (files.length) {
                        emit("update:modelValue", files[0]);
                        filename.value = files[0].name;
                    }
                }
            }

            function change(e: Event) {
                if (e && e.target) {
                    const files = input.value.files || [];

                    if (files.length) {
                        emit("change", files[0]);
                        filename.value = files[0].name;
                    }
                }
            }

            return {
                ...useI18n(),
                input,
                filename,
                update,
                change
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "placeholder": "No file selected"
        },
        "de": {
            "placeholder": "Keine Datei ausgewählt"
        },
        "es": {
            "placeholder": "Ningún archivo seleccionado"
        },
        "fr": {
            "placeholder": "Aucun fichier sélectionné"
        },
        "nl": {
            "placeholder": "Geen bestand geselecteerd"
        },
        "it": {
            "placeholder": "Nessun file selezionato"
        },
        "pt": {
            "placeholder": "Nenhum arquivo selecionado"
        },
        "ja": {
            "placeholder": "ファイルが選択されていません"
        }
    }
</i18n>
