<template>
    <transition :name="disabled ? 'replace' : 'expand'" v-on:enter="enter" v-on:after-enter="afterEnter" v-on:leave="leave">
        <slot></slot>
    </transition>
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        props: {
            disabled: {type: Boolean, default: false}
        },
        setup(props) {
            function afterEnter(element: HTMLElement) {
                if (!props.disabled) {
                    element.style.height = 'auto';
                }

                return;
            }

            function enter(element: HTMLElement) {
                element.style.height = "auto";
                
                if (!props.disabled) {
                    element.style.width = getComputedStyle(element).width;
                    element.style.position = "absolute";
                    element.style.visibility = "hidden";

                    const height = getComputedStyle(element).height;
                    element.style.width = "";
                    element.style.position = "";
                    element.style.visibility = "";
                    element.style.height = "0";

                    // force repaint to make sure the animation is triggered correctly
                    getComputedStyle(element).height;

                    setTimeout(() => {
                        element.style.height = height;
                    });
                }
            }

            function leave(element: HTMLElement) {
                if (!props.disabled) {
                    element.style.height = getComputedStyle(element).height;

                    // force repaint to make sure the animation is triggered correctly
                    getComputedStyle(element).height;

                    setTimeout(() => {
                        element.style.height = "0";
                    });
                }
            }

            return {
                afterEnter,
                enter,
                leave
            };
        }
    });
</script>

<style lang="scss" scoped>
    * {
        will-change: height;
        backface-visibility: hidden;
    }

    .expand-enter-active, .expand-leave-active {
        scrollbar-color: transparent transparent;

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
</style>
