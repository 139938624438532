<template>
    <template v-if="news.headline">
        <h3>{{news.headline}}</h3>
        <small style="margin: -6px 0 12px;">
            {{day(news.date)}}
            <span v-if="news.version">‒ {{t("version")}} {{news.version}}</span>
        </small>
    </template>
    <h3 v-else>
        {{day(news.date)}}
        <span v-if="news.version">‒ {{t("version")}} {{news.version}}</span>
    </h3>
    <div :class="{'news': true, 'collapsed': !toggle}" v-html="news.content"></div>
    <a href="#" class="clickable" v-on:click.stop.prevent="toggle = !toggle">
        {{toggle ? t("less") : t("more")}}
    </a>
    <hr v-if="index !== length-1" />
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {day} from "@/util/format";
    import {News} from "@/model/News";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            news: {type: Object as PropType<News>, required: true},
            index: {type: Number, required: true},
            length: {type: Number, required: true}
        },
        setup(props) {
            const toggle = ref(props.index === 0);

            return {
                ...useI18n(),
                day,
                toggle
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "version": "Version",
            "less": "Less",
            "more": "More"
        },
        "de": {
            "version": "Version",
            "less": "Weniger",
            "more": "Mehr"
        },
        "es": {
            "version": "Versión",
            "less": "Menos",
            "more": "Más"
        },
        "fr": {
            "version": "Version",
            "less": "Moins",
            "more": "Plus"
        },
        "nl": {
            "version": "Versie",
            "less": "Minder",
            "more": "Meer"
        },
        "it": {
            "version": "Versione",
            "less": "Meno",
            "more": "Di più"
        },
        "pt": {
            "version": "Versão",
            "less": "Menos",
            "more": "Mais"
        },
        "ja": {
            "version": "バージョン",
            "less": "少なく",
            "more": "もっと"
        }
    }
</i18n>
