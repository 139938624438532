
// Changes here also need to be made in dashboard/main.go.
import {KeyValue} from "@/model/KeyValue";

export enum ThemeSettings {
    // meta
    logoLight = "logo_light",
    logoDark = "logo_dark",
    favicon = "favicon",
    title = "title",
    description = "description",
    hideResources = "hide_resources",
    hideLogin = "hide_login",
    hideFooter = "hide_footer",
    hideCopyright = "hide_copyright",
    hideDarkModeToggle = "hide_dark_mode_toggle",
    hideLanguageSwitch = "hide_language_switch",
    mode = "mode",

    // style
    lightColorPrimary = "--theme-light-color-primary",
    darkColorPrimary = "--theme-dark-color-primary",
    lightColorSecondary = "--theme-light-color-secondary",
    darkColorSecondary = "--theme-dark-color-secondary",
    lightColorBackgroundPrimary = "--theme-light-color-background-primary",
    darkColorBackgroundPrimary = "--theme-dark-color-background-primary",
    lightColorBackgroundSecondary = "--theme-light-color-background-secondary",
    darkColorBackgroundSecondary = "--theme-dark-color-background-secondary",
    lightColorButton = "--theme-light-color-button",
    darkColorButton = "--theme-dark-color-button",
    lightColorBackgroundButton = "--theme-light-color-background-button",
    darkColorBackgroundButton = "--theme-dark-color-background-button",
    lightColorAccent = "--theme-light-color-accent",
    darkColorAccent = "--theme-dark-color-accent",
    lightColorGreen = "--theme-light-color-green",
    darkColorGreen = "--theme-dark-color-green",
    lightColorYellow = "--theme-light-color-yellow",
    darkColorYellow = "--theme-dark-color-yellow",
    lightColorOrange = "--theme-light-color-orange",
    darkColorOrange = "--theme-dark-color-orange",
    lightColorRed = "--theme-light-color-red",
    darkColorRed = "--theme-dark-color-red",
    lightBoxShadowPrimary = "--theme-light-box-shadow-primary",
    lightBoxShadowSecondary = "--theme-light-box-shadow-secondary",
    darkBoxShadowPrimary = "--theme-dark-box-shadow-primary",
    darkBoxShadowSecondary = "--theme-dark-box-shadow-secondary",
    borderRadiusPrimary = "--theme-border-radius-primary"
}

const defaultSettings: KeyValue = {};
defaultSettings[ThemeSettings.lightColorPrimary] = "#000000";
defaultSettings[ThemeSettings.lightColorSecondary] = "#707070";
defaultSettings[ThemeSettings.lightColorBackgroundPrimary] = "#f8f5ed";
defaultSettings[ThemeSettings.lightColorBackgroundSecondary] = "#ffffff";
defaultSettings[ThemeSettings.lightColorButton] = "#000000";
defaultSettings[ThemeSettings.lightColorBackgroundButton] = "#6ece9d";
defaultSettings[ThemeSettings.lightColorAccent] = "#6ece9d";
defaultSettings[ThemeSettings.lightColorGreen] = "#6ece9d";
defaultSettings[ThemeSettings.lightColorYellow] = "#ffda6e";
defaultSettings[ThemeSettings.lightColorOrange] = "#f7a66b";
defaultSettings[ThemeSettings.lightColorRed] = "#e87b7b";
defaultSettings[ThemeSettings.darkColorPrimary] = "#ffffff";
defaultSettings[ThemeSettings.darkColorSecondary] = "#adadad";
defaultSettings[ThemeSettings.darkColorBackgroundPrimary] = "#0a0a0a";
defaultSettings[ThemeSettings.darkColorBackgroundSecondary] = "#141414";
defaultSettings[ThemeSettings.darkColorButton] = "#000000";
defaultSettings[ThemeSettings.darkColorBackgroundButton] = "#6ece9d";
defaultSettings[ThemeSettings.darkColorAccent] = "#6ece9d";
defaultSettings[ThemeSettings.darkColorGreen] = "#6ece9d";
defaultSettings[ThemeSettings.darkColorYellow] = "#ffda6e";
defaultSettings[ThemeSettings.darkColorOrange] = "#f7a66b";
defaultSettings[ThemeSettings.darkColorRed] = "#e87b7b";
defaultSettings[ThemeSettings.lightBoxShadowPrimary] = "0px 4px 16px rgba(0,0,0,.1)";
defaultSettings[ThemeSettings.lightBoxShadowSecondary] = "None";
defaultSettings[ThemeSettings.darkBoxShadowPrimary] = "0px 4px 16px rgba(0,0,0,.8)";
defaultSettings[ThemeSettings.darkBoxShadowSecondary] = "None";
export const themeDefaults = defaultSettings;
