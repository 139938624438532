<template>
    <Modal :title="filter ? t('title.edit') : t('title.add')" :err="err" v-on:close="$emit('close')">
        <p>{{help}}</p>
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.description')" name="description" v-model="description" :autofocus="true" :err="validationError('description')" />
            <FormInput :label="t('form.filter')" name="filter" v-model="filterValue" :hint="example" :err="validationError('filter')" v-if="!countryList" />
            <FormSelect :label="t('form.country')" name="country" v-model="filterValue" :options="countries" :hint="example" :err="validationError('filter')" v-else />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, onMounted, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {TrafficFilterRepo} from "@/repositories/TrafficFilterRepo";
    import {all} from "iso-3166-1";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {SelectOption} from "@/components/form/SelectOption";
    import {TrafficFilter} from "@/model/TrafficFilter";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput,
            FormSelect
        },
        props: {
            filter: Object as PropType<TrafficFilter>,
            type: {type: String, required: true},
            help: {type: String, required: true},
            example: String,
            countryList: Boolean
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const countries = ref<SelectOption[]>([]);
            const loading = ref(false);
            const description = ref("");
            const filterValue = ref("");

            onMounted(() => {
                if (props.countryList) {
                    const list = [];

                    for (const country of all()) {
                        list.push({
                            label: country.country,
                            value: country.alpha2
                        });
                    }

                    countries.value = list;
                }

                if (props.filter) {
                    description.value = props.filter.description;
                    filterValue.value = props.filter.filter;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const f = await TrafficFilterRepo.save({
                        id: props.filter ? props.filter.id : undefined,
                        domain_id: domain.value.id,
                        type: props.type,
                        description: description.value,
                        filter: filterValue.value
                    });
                    success(t("toast.success"));
                    description.value = "";
                    filterValue.value = "";
                    loading.value = false;
                    emit("action", f);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                countries,
                validationError,
                err,
                loading,
                description,
                filterValue,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title.add": "Add Filter",
            "title.edit": "Edit Filter",
            "text": "New filters are activated after a few minutes.",
            "form.description": "Description",
            "form.filter": "Filter",
            "form.country": "Country",
            "form.submit": "Add Filter",
            "toast.success": "The filter has been added."
        },
        "de": {
            "title.add": "Filter hinzufügen",
            "title.edit": "Filter bearbeiten",
            "text": "Neue Filter werden nach wenigen Minuten aktiviert.",
            "form.description": "Beschreibung",
            "form.filter": "Filter",
            "form.country": "Land",
            "form.submit": "Filter hinzufügen",
            "toast.success": "Der Filter wurde hinzugefügt."
        },
        "es": {
            "title.add": "Agregar filtro",
            "title.edit": "Editar filtro",
            "text": "Los nuevos filtros se activan después de unos minutos.",
            "form.description": "Descripción",
            "form.filter": "Filtro",
            "form.country": "País",
            "form.submit": "Agregar filtro",
            "toast.success": "El filtro ha sido agregado."
        },
        "fr": {
            "title.add": "Ajouter un filtre",
            "title.edit": "Edit Filter",
            "text": "Les nouveaux filtres sont activés après quelques minutes.",
            "form.description": "Description",
            "form.filter": "Filtre",
            "form.country": "Pays",
            "form.submit": "Ajouter un filtre",
            "toast.success": "Le filtre a été ajouté."
        },
        "nl": {
            "title.add": "Filter toevoegen",
            "title.edit": "Filter bewerken",
            "text": "Nieuwe filters worden na enkele minuten geactiveerd.",
            "form.description": "Beschrijving",
            "form.filter": "Filter",
            "form.country": "Land",
            "form.submit": "Filter toevoegen",
            "toast.success": "De filter is toegevoegd."
        },
        "it": {
            "title.add": "Aggiungi filtro",
            "title.edit": "Modifica del filtro",
            "text": "I nuovi filtri vengono attivati dopo alcuni minuti.",
            "form.description": "Descrizione",
            "form.filter": "Filtro",
            "form.country": "Paese",
            "form.submit": "Aggiungi filtro",
            "toast.success": "Il filtro è stato aggiunto."
        },
        "pt": {
            "title.add": "Adicionar filtro",
            "title.edit": "Editar filtro",
            "text": "Novos filtros são ativados após alguns minutos.",
            "form.description": "Descrição",
            "form.filter": "Filtro",
            "form.country": "País",
            "form.submit": "Adicionar filtro",
            "toast.success": "O filtro foi adicionado."
        },
        "ja": {
            "title.add": "フィルターを追加",
            "title.edit": "編集フィルター",
            "text": "新しいフィルターは数分後に有効になります。",
            "form.description": "説明",
            "form.filter": "フィルター",
            "form.country": "国",
            "form.submit": "フィルターを追加",
            "toast.success": "フィルターが追加されました。"
        }
    }
</i18n>
