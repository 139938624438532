<template>
    <Modal size="xl" :title="t('title')" :open="open">
        <EventPage component="TableEntries"
            :showTitle="false"
            :showEnlarge="false"
            :limitless="true"
            :showDetails="true"
            :visible="true" />
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import EventPage from "@/components/statistics/EventPage.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {entries_per_page, useStatisticsStore} from "@/store/StatisticsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            EventPage
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup(props) {
            const filterStore = useFilterStore();
            const statisticsStore = useStatisticsStore();
            const {filter} = storeToRefs(filterStore);
            const {loadEventPages} = statisticsStore;

            watch(() => props.open, open => {
                if (open) {
                    const f = filterStore.copy(filter.value);
                    f.limit = entries_per_page;
                    f.include_avg_time_on_page = true;
                    loadEventPages(f, false);
                }
            });

            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Event Pages"
        },
        "de": {
            "title": "Eventseiten"
        },
        "es": {
            "title": "Páginas de Eventos"
        },
        "fr": {
            "title": "Pages d'Événements"
        },
        "nl": {
            "title": "Evenementpagina's"
        },
        "it": {
            "title": "Pagine di Eventi"
        },
        "pt": {
            "title": "Páginas de Eventos"
        },
        "ja": {
            "title": "イベントページ"
        }
    }
</i18n>
