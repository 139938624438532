<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <form v-on:submit.prevent="save">
                <FormSelect :label="t('form.percentage')"
                    name="percent"
                    :options="percentOptions"
                    v-model="percent"
                    :err="validationError('percent')"
                    v-on:change="save" />
            </form>
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect
        },
        setup() {
            const {t} = useI18n();
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);
            const {resetError, setError, validationError} = useError();
            const {success, error} = useToastStore();
            const percent = ref(user.value.hits_warning ? user.value.hits_warning.toString() : "0");
            const percentOptions = computed(() => [
                {label: t("options.percent.disabled"), value: "0"},
                {label: "10%", value: "10"},
                {label: "20%", value: "20"},
                {label: "30%", value: "30"},
                {label: "40%", value: "40"},
                {label: "50%", value: "50"},
                {label: "60%", value: "60"},
                {label: "70%", value: "70"},
                {label: "80%", value: "80"},
                {label: "90%", value: "90"}
            ]);

            watch(user, value => {
                percent.value = value.hits_warning ? value.hits_warning.toString() : "0";
            });

            async function save() {
                try {
                    resetError();
                    await UserRepo.setLimitWarning(parseInt(percent.value));
                    await userStore.load();
                    success(t("toast.success"));
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                percentOptions,
                validationError,
                percent,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Limit Warning",
            "text": "Receive an email when a certain percentage of your monthly page view limit is reached.",
            "form.percentage": "Percentage",
            "options.percent.disabled": "Disabled",
            "toast.success": "The warning limit has been updated."
        },
        "de": {
            "title": "Limit-Warnung",
            "text": "Erhalte eine E-Mail, wenn ein bestimmter Prozentsatz deines monatlichen Seitenaufruflimits erreicht ist.",
            "form.percentage": "Prozentsatz",
            "options.percent.disabled": "Deaktiviert",
            "toast.success": "Das Warnlimit wurde aktualisiert."
        },
        "es": {
            "title": "Advertencia de límite",
            "text": "Recibe un correo electrónico cuando se alcance un cierto porcentaje de tu límite mensual de vistas de página.",
            "form.percentage": "Porcentaje",
            "options.percent.disabled": "Desactivado",
            "toast.success": "El límite de advertencia se ha actualizado."
        },
        "fr": {
            "title": "Avertissement de limite",
            "text": "Recevez un e-mail lorsqu'un certain pourcentage de votre limite mensuelle de vues de page est atteint.",
            "form.percentage": "Pourcentage",
            "options.percent.disabled": "Désactivé",
            "toast.success": "La limite d'avertissement a été mise à jour."
        },
        "nl": {
            "title": "Limietwaarschuwing",
            "text": "Ontvang een e-mail wanneer een bepaald percentage van je maandelijkse paginabezoeklimiet is bereikt.",
            "form.percentage": "Percentage",
            "options.percent.disabled": "Uitgeschakeld",
            "toast.success": "De waarschuwingslimiet is bijgewerkt."
        },
        "it": {
            "title": "Avviso di limite",
            "text": "Ricevi un'email quando viene raggiunta una certa percentuale del limite mensile di visualizzazioni di pagina.",
            "form.percentage": "Percentuale",
            "options.percent.disabled": "Disabilitato",
            "toast.success": "Il limite di avviso è stato aggiornato."
        },
        "pt": {
            "title": "Aviso de limite",
            "text": "Receba um e-mail quando uma certa porcentagem do seu limite mensal de visualizações de página for alcançada.",
            "form.percentage": "Porcentagem",
            "options.percent.disabled": "Desativado",
            "toast.success": "O limite de aviso foi atualizado."
        },
        "ja": {
            "title": "制限警告",
            "text": "月間ページビュー制限の一定割合に達した場合にメールを受信します。",
            "form.percentage": "割合",
            "options.percent.disabled": "無効",
            "toast.success": "警告制限が更新されました。"
        }
    }
</i18n>
