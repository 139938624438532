import {APIError} from "@/repositories/APIError";
import {EmailReport} from "@/model/EmailReport";
import {Repository} from "@/repositories/Repository";

export const ReportRepo = new class extends Repository {
    async list(id: string): Promise<EmailReport[] | APIError> {
        return this.performGet<EmailReport[]>("/report", {id}, []);
    }

    async add(domain_id: string, emails: string[], interval: number, link_to: string): Promise<EmailReport[] | APIError> {
        return this.performPost<EmailReport[]>("/report", {domain_id, emails, interval, link_to});
    }

    async update(id: string, interval: number, link_to: string): Promise<EmailReport | APIError> {
        return this.performPut<EmailReport>("/report", {id, interval, link_to});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/report", {id});
    }
}
