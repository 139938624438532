<template>
    <RemoveMember :open="removeMemberModal"
        v-on:close="removeMemberModal = false"
        v-on:action="removeMember"
        :member="member" />
    <div class="card no-select">
        <div class="row">
            <img :src="picture" alt="" v-if="picture" />
            <i class="icon icon-user" v-else></i>
            <div class="text">
                <p>
                    <strong>{{member.user?.full_name}}</strong>
                </p>
                <small>{{member.user?.email}}</small>
            </div>
        </div>
        <div class="row" v-if="member.user_id !== user.id && member.role !== 'Owner' && (organization || !member.organization_id)">
            <FormSelect name="role" :label="t('form.role')" v-model="role" :options="roleOptions" />
            <i class="icon icon-trash clickable" :title="t('remove')" v-on:click="removeMemberModal = true"></i>
        </div>
        <div class="row" v-else>
            <router-link :to="`/organization/${member.organization_id}`" v-if="!organization && member.organization_id">
                <i class="icon icon-edit clickable" :title="t('edit')" style="margin-right: 6px;"></i>
            </router-link>
            <p >
                <strong>{{t("options.roles."+member.role)}}</strong>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref, watch} from "vue";
    import FormSelect from "@/components/form/FormSelect.vue";
    import RemoveMember from "@/components/modal/RemoveMember.vue";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {Role} from "@/model/Role";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useToastStore} from "@/store/ToastStore";
    import {Member} from "@/model/Member";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect,
            RemoveMember
        },
        props: {
            member: {type: Object as PropType<Member>, required: true},
            organization: Boolean
        },
        emits: ["remove"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {user} = storeToRefs(useUserStore());
            const {success} = useToastStore();
            const removeMemberModal = ref(false);
            const picture = computed(() => props.member.user?.picture ? `${import.meta.env.VITE_APP_BACKEND_HOST}/user/picture/${props.member.user.picture}` : "");
            const role = ref(props.member.role);
            const roleOptions = computed(() => {
                const options = [];

                for (const r of Object.values(Role)) {
                    if (r !== Role.Owner) {
                        options.push({
                            value: r,
                            label: t("options.roles."+r)
                        });
                    }
                }

                return options;
            });

            watch(role, async (value: Role) => {
                await MemberRepo.updateRole(props.member.id, value);
                success(t("toast.success"));
            });

            function removeMember() {
                removeMemberModal.value = false;
                emit("remove", props.member);
            }

            return {
                t,
                removeMemberModal,
                user,
                picture,
                role,
                roleOptions,
                removeMember
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "form.role": "Role",
            "remove": "Remove",
            "edit": "Edit",
            "options.roles.Owner": "Owner",
            "options.roles.Admin": "Admin",
            "options.roles.Viewer": "Viewer",
            "toast.success": "Role updated."
        },
        "de": {
            "form.role": "Rolle",
            "remove": "Entfernen",
            "edit": "Bearbeiten",
            "options.roles.Owner": "Eigentümer",
            "options.roles.Admin": "Admin",
            "options.roles.Viewer": "Betrachter",
            "toast.success": "Rolle aktualisiert."
        },
        "es": {
            "form.role": "Rol",
            "remove": "Eliminar",
            "edit": "Editar",
            "options.roles.Owner": "Propietario",
            "options.roles.Admin": "Admin",
            "options.roles.Viewer": "Espectador",
            "toast.success": "Rol actualizado."
        },
        "fr": {
            "form.role": "Rôle",
            "remove": "Supprimer",
            "edit": "Éditer",
            "options.roles.Owner": "Propriétaire",
            "options.roles.Admin": "Admin",
            "options.roles.Viewer": "Visionneuse",
            "toast.success": "Rôle mis à jour."
        },
        "nl": {
            "form.role": "Rol",
            "remove": "Verwijderen",
            "edit": "Bewerken",
            "options.roles.Owner": "Eigenaar",
            "options.roles.Admin": "Beheerder",
            "options.roles.Viewer": "Kijker",
            "toast.success": "Rol bijgewerkt."
        },
        "it": {
            "form.role": "Ruolo",
            "remove": "Rimuovi",
            "edit": "Modifica",
            "options.roles.Owner": "Proprietario",
            "options.roles.Admin": "Amministratore",
            "options.roles.Viewer": "Spettatore",
            "toast.success": "Ruolo aggiornato."
        },
        "pt": {
            "form.role": "Função",
            "remove": "Remover",
            "edit": "Editar",
            "options.roles.Owner": "Proprietário",
            "options.roles.Admin": "Admin",
            "options.roles.Viewer": "Visualizador",
            "toast.success": "Função atualizada."
        },
        "ja": {
            "form.role": "役割",
            "remove": "削除",
            "edit": "編集",
            "options.roles.Owner": "オーナー",
            "options.roles.Admin": "管理者",
            "options.roles.Viewer": "閲覧者",
            "toast.success": "役割が更新されました。"
        }
    }
</i18n>
