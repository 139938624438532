<template>
    <CreateEditClient :open="createClientModal"
        :forDomain="forDomain"
        v-on:close="createClientModal = false"
        v-on:action="addClient" />
    <ClientSecret :open="clientSecretModal"
        v-on:close="clientSecretModal = false"
        :client="newClient" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text_0")}}
                <br />
                <i18n-t keypath="text_1">
                    <strong>{{domainID}}</strong>
                    <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" style="font-size: 14px" v-on:click="copyDomainID"></i>
                </i18n-t>
                <br />
                <a href="https://docs.pirsch.io/api-sdks/api#creating-a-client" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <ClientCard v-for="client in clients"
                        :key="client.id"
                        :forDomain="forDomain"
                        :client="client"
                        v-on:update="updateClient"
                        v-on:remove="removeClient" />
                </div>
            </Expand>
            <button v-on:click="createClientModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watchEffect} from "vue";
    import {Client} from "@/model/Client";
    import CreateEditClient from "@/components/modal/CreateEditClient.vue";
    import ClientSecret from "@/components/modal/ClientSecret.vue";
    import ClientCard from "@/components/cards/ClientCard.vue";
    import {ClientRepo} from "@/repositories/ClientRepo";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import copy from "copy-to-clipboard";
    import {useToastStore} from "@/store/ToastStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            CreateEditClient,
            ClientSecret,
            ClientCard,
            Loading,
            Expand
        },
        props: {
            forDomain: {type: Boolean, default: true}
        },
        setup(props) {
            const {t} = useI18n();
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const {success} = useToastStore();
            const createClientModal = ref(false);
            const clientSecretModal = ref(false);
            const loading = ref(true);
            const clients = ref<Client[]>([]);
            const newClient = ref<Client>();
            const domainID = computed(() => domain.value.id);

            watchEffect(async () => {
                loading.value = true;
                clients.value = await ClientRepo.list(props.forDomain ? domain.value.id : "") as Client[] || [];
                loading.value = false;
            });

            function addClient(client: Client) {
                createClientModal.value = false;
                clients.value.push(client);
                newClient.value = client;
                clientSecretModal.value = true;
            }

            function updateClient(client: Client) {
                updateEntryByID(clients.value, client);
            }

            function removeClient(client: Client) {
                removeEntryByID(clients.value, client);
            }

            function copyDomainID() {
                copy(domainID.value);
                success(t("toast.clipboard"));
            }

            return {
                t,
                createClientModal,
                clientSecretModal,
                loading,
                clients,
                newClient,
                domainID,
                addClient,
                updateClient,
                removeClient,
                copyDomainID
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Clients",
            "text_0": "Create clients to generate an access key or oAuth credentials to access the API.",
            "text_1": "Your dashboard ID is {0} {1}",
            "link": "Read the Docs",
            "add": "Add Client",
            "copy_to_clipboard": "Copy to Clipboard",
            "toast.clipboard": "Copied to clipboard."
        },
            "de": {
            "title": "Clients",
            "text_0": "Erstelle Clients, um einen Zugangsschlüssel oder oAuth-Anmeldedaten zu generieren, um auf die API zuzugreifen.",
            "text_1": "Deine Dashboard-ID ist {0} {1}",
            "link": "Dokumentation lesen",
            "add": "Client hinzufügen",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "toast.clipboard": "In die Zwischenablage kopiert."
        },
        "es": {
            "title": "Clientes",
            "text_0": "Crea clientes para generar una clave de acceso o credenciales oAuth para acceder a la API.",
            "text_1": "Tu ID de panel es {0} {1}",
            "link": "Leer la documentación",
            "add": "Añadir cliente",
            "copy_to_clipboard": "Copiar al portapapeles",
            "toast.clipboard": "Copiado al portapapeles."
        },
        "fr": {
            "title": "Clients",
            "text_0": "Créez des clients pour générer une clé d'accès ou des identifiants oAuth pour accéder à l'API.",
            "text_1": "Votre ID de tableau de bord est {0} {1}",
            "link": "Lire la documentation",
            "add": "Ajouter un client",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "toast.clipboard": "Copié dans le presse-papiers."
        },
        "nl": {
            "title": "Clients",
            "text_0": "Maak clients om een toegangssleutel of oAuth-gegevens te genereren om toegang te krijgen tot de API.",
            "text_1": "Je dashboard-ID is {0} {1}",
            "link": "Lees de documentatie",
            "add": "Client toevoegen",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "toast.clipboard": "Gekopieerd naar klembord."
        },
        "it": {
            "title": "Clienti",
            "text_0": "Crea client per generare una chiave di accesso o credenziali oAuth per accedere all'API.",
            "text_1": "Il tuo ID dashboard è {0} {1}",
            "link": "Leggi la documentazione",
            "add": "Aggiungi cliente",
            "copy_to_clipboard": "Copia negli appunti",
            "toast.clipboard": "Copiato negli appunti."
        },
        "pt": {
            "title": "Clientes",
            "text_0": "Crie clientes para gerar uma chave de acesso ou credenciais oAuth para acessar a API.",
            "text_1": "Seu ID do painel é {0} {1}",
            "link": "Leia a documentação",
            "add": "Adicionar cliente",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "toast.clipboard": "Copiado para a área de transferência."
        },
        "ja": {
            "title": "クライアント",
            "text_0": "クライアントを作成して、APIにアクセスするためのアクセスキーまたはoAuth認証情報を生成します。",
            "text_1": "あなたのダッシュボードIDは {0} {1} です",
            "link": "ドキュメントを読む",
            "add": "クライアントを追加",
            "copy_to_clipboard": "クリップボードにコピー",
            "toast.clipboard": "クリップボードにコピーされました。"
        }
    }
</i18n>
