<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.hostname')" name="hostname" v-model="hostname" :err="validationError('hostname')" />
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSubmit,
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const hostname = ref("");

            watchEffect(() => {
                if (domain.value) {
                    hostname.value = domain.value.hostname;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await DomainRepo.updateHostname(domain.value.id, hostname.value);
                    domain.value.subdomain = hostname.value;
                    loading.value = false;
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                validationError,
                loading,
                hostname,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Hostname",
            "text": "Change the hostname for your domain. Note that this breaks existing integrations if the new hostname does not match the domain requests are sent from! It might take a few minutes before traffic is picked up again.",
            "form.hostname": "Hostname",
            "form.submit": "Update Hostname",
            "toast.success": "Hostname saved."
        },
        "de": {
            "title": "Hostname",
            "text": "Ändere den Hostnamen für deine Domain. Beachte, dass dies bestehende Integrationen unterbricht, wenn der neue Hostname nicht mit der Domain übereinstimmt, von der die Anfragen gesendet werden! Es kann einige Minuten dauern, bis der Traffic wieder erfasst wird.",
            "form.hostname": "Hostname",
            "form.submit": "Hostname aktualisieren",
            "toast.success": "Hostname gespeichert."
        },
        "es": {
            "title": "Nombre de host",
            "text": "Cambia el nombre de host para tu dominio. ¡Ten en cuenta que esto rompe las integraciones existentes si el nuevo nombre de host no coincide con el dominio desde el que se envían las solicitudes! Puede tardar unos minutos antes de que se vuelva a recoger el tráfico.",
            "form.hostname": "Nombre de host",
            "form.submit": "Actualizar nombre de host",
            "toast.success": "Nombre de host guardado."
        },
        "fr": {
            "title": "Nom d'hôte",
            "text": "Changez le nom d'hôte de votre domaine. Notez que cela rompt les intégrations existantes si le nouveau nom d'hôte ne correspond pas au domaine à partir duquel les demandes sont envoyées ! Cela peut prendre quelques minutes avant que le trafic ne soit de nouveau pris en compte.",
            "form.hostname": "Nom d'hôte",
            "form.submit": "Mettre à jour le nom d'hôte",
            "toast.success": "Nom d'hôte enregistré."
        },
        "nl": {
            "title": "Hostnaam",
            "text": "Wijzig de hostnaam voor je domein. Houd er rekening mee dat dit bestaande integraties verbreekt als de nieuwe hostnaam niet overeenkomt met het domein waarvandaan de verzoeken worden verzonden! Het kan enkele minuten duren voordat het verkeer weer wordt opgepikt.",
            "form.hostname": "Hostnaam",
            "form.submit": "Hostnaam bijwerken",
            "toast.success": "Hostnaam opgeslagen."
        },
        "it": {
            "title": "Nome host",
            "text": "Cambia il nome host per il tuo dominio. Nota che questo interrompe le integrazioni esistenti se il nuovo nome host non corrisponde al dominio da cui vengono inviate le richieste! Potrebbero volerci alcuni minuti prima che il traffico venga nuovamente rilevato.",
            "form.hostname": "Nome host",
            "form.submit": "Aggiorna nome host",
            "toast.success": "Nome host salvato."
        },
        "pt": {
            "title": "Nome do host",
            "text": "Altere o nome do host para o seu domínio. Observe que isso interrompe as integrações existentes se o novo nome do host não corresponder ao domínio de onde as solicitações são enviadas! Pode levar alguns minutos até que o tráfego seja capturado novamente.",
            "form.hostname": "Nome do host",
            "form.submit": "Atualizar nome do host",
            "toast.success": "Nome do host salvo."
        },
        "ja": {
            "title": "ホスト名",
            "text": "ドメインのホスト名を変更します。新しいホスト名がリクエストが送信されるドメインと一致しない場合、既存の統合が破損することに注意してください。トラフィックが再び検出されるまでに数分かかることがあります。",
            "form.hostname": "ホスト名",
            "form.submit": "ホスト名を更新",
            "toast.success": "ホスト名が保存されました。"
        }
    }
</i18n>
