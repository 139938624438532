import {defineStore, storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {defaultUser, User} from "@/model/User";
import {UserRepo} from "@/repositories/UserRepo";
import Cookies from "js-cookie";
import {useDomainStore} from "@/store/DomainStore";
import {useRouter} from "vue-router";
import {isCustomDomain} from "@/util/domain";
import {deleteAllCookies} from "@/util/cookie";
import {useFilterStore} from "@/store/FilterStore";
import {getAccessCode} from "@/util/url";

export const useUserStore = defineStore("user", () => {
    const router = useRouter();
    const domainStore = useDomainStore();
    const filterStore = useFilterStore();
    const {domain} = storeToRefs(domainStore);
    const user = ref<User>(defaultUser());
    const loggedIn = computed(() => !!(user.value && user.value.id));
    const picture = computed(() => user.value && user.value.picture ? `${import.meta.env.VITE_APP_BACKEND_HOST}/user/picture/${user.value.picture}` : "");
    const hasPlus = computed(() => domain.value && domain.value.subscription_plus || user.value && (user.value.free_trial || user.value.subscription_plus));

    async function load() {
        try {
            if (Cookies.get("refresh_token")) {
                const u = await UserRepo.get() as User;
                user.value = u;
                return Promise.resolve(u);
            }

            return Promise.resolve(null);
        } catch {
            await logout();
            return Promise.reject();
        }
    }

    async function login() {
        if (isCustomDomain()) {
            await router.push("/login");
        } else {
            location.href = `${import.meta.env.VITE_APP_DASHBOARD_HOST}/login`;
        }
    }

    async function logout() {
        try {
            if (getAccessCode() !== "") {
                return;
            }

            const refreshToken = Cookies.get("refresh_token");

            if (refreshToken) {
                await UserRepo.logout(refreshToken || undefined);
            }

            deleteAllCookies();
            user.value = defaultUser();
            domainStore.set();
            filterStore.clear();
            filterStore.clearComparison();
            localStorage.clear();

            if (!router.currentRoute.value.meta.noRedirect) {
                await router.push("/login");
            }

            return Promise.resolve();
        } catch {
            return Promise.reject();
        }
    }

    function signup() {
        if (import.meta.env.VITE_APP_AFFILIATE && domainStore.domain && domainStore.domain.id) {
            location.href = `${import.meta.env.VITE_APP_WEBSITE_HOST}/ref/${domainStore.domain.user_id}?redirect=signup`;
            return;
        }

        location.href = `${import.meta.env.VITE_APP_WEBSITE_HOST}/signup`;
    }

    return {
        user,
        loggedIn,
        picture,
        hasPlus,
        load,
        login,
        logout,
        signup
    };
});
