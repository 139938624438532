<template>
    <div class="session-step">
        <template v-if="step.page_view">
            <div class="session-step-content">
                <div class="number">{{no}}</div>
                <div class="info">
                    <strong><template v-if="step.page_view.title">{{step.page_view.title}} – </template>{{step.page_view.path}}</strong>
                    <small>{{dayTimeShort(step.page_view.time)}}</small>
                </div>
            </div>
            <SessionMetaData :keys="step.page_view.tag_keys"
                :values="step.page_view.tag_values"
                v-if="step.page_view.tag_keys.length" />
            <small class="duration">{{formatSeconds(step.page_view.duration_seconds)}}<template v-if="step.page_view.duration_seconds === 0">s</template></small>
        </template>
        <template v-else-if="step.event">
            <div class="session-step-content">
                <div class="number" style="color: var(--color-accent);">{{no}}</div>
                <div class="info">
                    <strong>{{step.event.name}} – <template v-if="step.event.title">{{step.event.title}} – </template>{{step.event.path}}</strong>
                    <small>{{dayTimeShort(step.event.time)}}</small>
                </div>
            </div>
            <SessionMetaData :keys="step.event.meta_keys"
                :values="step.event.meta_values"
                v-if="step.event.meta_keys.length" />
            <small class="duration">{{formatSeconds(step.event.duration_seconds)}}<template v-if="step.event.duration_seconds === 0">s</template></small>
        </template>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from "vue";
    import {SessionStep} from "@/model/SessionStep";
    import {dayTimeShort, formatSeconds} from "@/util/format";
    import SessionMetaData from "@/components/sessions/SessionMetaData.vue";

    export default defineComponent({
        components: {
            SessionMetaData
        },
        props: {
            step: {type: Object as PropType<SessionStep>, required: true},
            no: {type: Object as PropType<String | Number>, required: true}
        },
        setup() {
            return {
                dayTimeShort,
                formatSeconds
            };
        }
    });
</script>

