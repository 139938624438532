<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p v-if="selfHosted">{{t("text_0")}}</p>
        <p v-else>{{t("text_1")}}</p>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.password')" name="password" type="password" :autofocus="true" v-model="password" :err="validationError('password')" />
            <FormInput :label="t('form.message')" name="message" v-model="message" />
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {UserRepo} from "@/repositories/UserRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {isSelfHosted} from "@/util/domain";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput,
            FormSubmit
        },
        emits: ["close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {load} = useUserStore();
            const {success} = useToastStore();
            const {resetError, setError, validationError, err} = useError();
            const loading = ref(false);
            const password = ref("");
            const message = ref("");

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await UserRepo.deleteAccount(password.value, message.value);
                    await load();
                    success(t("toast.success"));
                    password.value = "";
                    message.value = "";
                    loading.value = false;
                    emit("close");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                selfHosted: isSelfHosted(),
                validationError,
                err,
                loading,
                password,
                message,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Account Deletion",
            "text_0": "Please enter your password to confirm the action.",
            "text_1": "Please enter your password to confirm the action and leave us a message about why you are leaving Pirsch (optional).",
            "form.password": "Password",
            "form.message": "Message (optional)",
            "form.submit": "Mark for Deletion",
            "toast.success": "Your account has been marked for deletion."
        },
        "de": {
            "title": "Kontolöschung bestätigen",
            "text_0": "Bitte gib dein Passwort ein, um die Aktion zu bestätigen.",
            "text_1": "Bitte gib dein Passwort ein, um die Aktion zu bestätigen und hinterlasse uns eine Nachricht, warum du Pirsch verlässt (optional).",
            "form.password": "Passwort",
            "form.message": "Nachricht (optional)",
            "form.submit": "Zur Löschung markieren",
            "toast.success": "Dein Konto wurde zur Löschung markiert."
        },
        "es": {
            "title": "Confirmar eliminación de cuenta",
            "text_0": "Por favor, introduce tu contraseña para confirmar la acción.",
            "text_1": "Por favor, introduce tu contraseña para confirmar la acción y déjanos un mensaje sobre por qué estás dejando Pirsch (opcional).",
            "form.password": "Contraseña",
            "form.message": "Mensaje (opcional)",
            "form.submit": "Marcar para eliminación",
            "toast.success": "Tu cuenta ha sido marcada para eliminación."
        },
        "fr": {
            "title": "Confirmer la suppression du compte",
            "text_0": "Veuillez entrer votre mot de passe pour confirmer l'action.",
            "text_1": "Veuillez entrer votre mot de passe pour confirmer l'action et laissez-nous un message pour expliquer pourquoi vous quittez Pirsch (optionnel).",
            "form.password": "Mot de passe",
            "form.message": "Message (optionnel)",
            "form.submit": "Marquer pour suppression",
            "toast.success": "Votre compte a été marqué pour suppression."
        },
        "nl": {
            "title": "Accountverwijdering bevestigen",
            "text_0": "Voer je wachtwoord in om de actie te bevestigen.",
            "text_1": "Voer je wachtwoord in om de actie te bevestigen en laat ons een bericht achter waarom je Pirsch verlaat (optioneel).",
            "form.password": "Wachtwoord",
            "form.message": "Bericht (optioneel)",
            "form.submit": "Markeren voor verwijdering",
            "toast.success": "Je account is gemarkeerd voor verwijdering."
        },
        "it": {
            "title": "Conferma eliminazione account",
            "text_0": "Inserisci la tua password per confermare l'azione.",
            "text_1": "Inserisci la tua password per confermare l'azione e lasciaci un messaggio sul motivo per cui stai lasciando Pirsch (opzionale).",
            "form.password": "Password",
            "form.message": "Messaggio (opzionale)",
            "form.submit": "Segna per eliminazione",
            "toast.success": "Il tuo account è stato contrassegnato per l'eliminazione."
        },
        "pt": {
            "title": "Confirmar exclusão de conta",
            "text_0": "Por favor, insira sua senha para confirmar a ação.",
            "text_1": "Por favor, insira sua senha para confirmar a ação e deixe-nos uma mensagem sobre por que você está deixando o Pirsch (opcional).",
            "form.password": "Senha",
            "form.message": "Mensagem (opcional)",
            "form.submit": "Marcar para exclusão",
            "toast.success": "Sua conta foi marcada para exclusão."
        },
        "ja": {
            "title": "アカウント削除の確認",
            "text_0": "アクションを確認するためにパスワードを入力してください。",
            "text_1": "アクションを確認するためにパスワードを入力し、Pirschを離れる理由についてのメッセージを残してください（任意）。",
            "form.password": "パスワード",
            "form.message": "メッセージ（任意）",
            "form.submit": "削除対象としてマーク",
            "toast.success": "あなたのアカウントは削除対象としてマークされました。"
        }
    }
</i18n>
