<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                <i18n-t keypath="text">
                    <a :href="dashboardURL" target="_blank">{{subdomain}}.{{domain}}</a>
                </i18n-t>
                <br />
                <a href="https://docs.pirsch.io/advanced/access#public-access" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <form v-on:submit.prevent="save">
            <div style="display: flex;">
                <FormInput :label="t('form.subdomain')" name="subdomain" v-model="subdomain" :err="validationError('subdomain')" />
                <p style="margin: 12px 0 0 8px; font-size: 16px; font-weight: 500;">.{{domain}}</p>
            </div>
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watchEffect} from "vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSubmit,
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const {setError, resetError, validationError} = useError();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const dashboardURL = computed(() => `https://${domain.value.subdomain}.${import.meta.env.VITE_APP_DASHBOARD_DOMAIN}`);
            const subdomain = ref("");

            watchEffect(() => {
                if (domain.value) {
                    subdomain.value = domain.value.subdomain;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await DomainRepo.updateSubdomain(domain.value.id, subdomain.value);
                    domain.value.subdomain = subdomain.value;
                    loading.value = false;
                    success(t("toast.success"));
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                domain: import.meta.env.VITE_APP_DASHBOARD_DOMAIN,
                validationError,
                loading,
                dashboardURL,
                subdomain,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Subdomain",
            "text": "Choose the subdomain the dashboard is available at, currently {0}.",
            "link": "Read the Docs",
            "form.subdomain": "Subdomain",
            "form.submit": "Save",
            "toast.success": "Subdomain saved."
        },
        "de": {
            "title": "Subdomain",
            "text": "Wähle die Subdomain, unter der das Dashboard verfügbar ist, derzeit {0}.",
            "link": "Dokumentation lesen",
            "form.subdomain": "Subdomain",
            "form.submit": "Speichern",
            "toast.success": "Subdomain gespeichert."
        },
        "es": {
            "title": "Subdominio",
            "text": "Elige el subdominio donde está disponible el tablero, actualmente {0}.",
            "link": "Leer la documentación",
            "form.subdomain": "Subdominio",
            "form.submit": "Guardar",
            "toast.success": "Subdominio guardado."
        },
        "fr": {
            "title": "Sous-domaine",
            "text": "Choisissez le sous-domaine où le tableau de bord est disponible, actuellement {0}.",
            "link": "Lire la documentation",
            "form.subdomain": "Sous-domaine",
            "form.submit": "Enregistrer",
            "toast.success": "Sous-domaine enregistré."
        },
        "nl": {
            "title": "Subdomein",
            "text": "Kies het subdomein waarop het dashboard beschikbaar is, momenteel {0}.",
            "link": "Lees de documentatie",
            "form.subdomain": "Subdomein",
            "form.submit": "Opslaan",
            "toast.success": "Subdomein opgeslagen."
        },
        "it": {
            "title": "Sottodominio",
            "text": "Scegli il sottodominio in cui è disponibile il cruscotto, attualmente {0}.",
            "link": "Leggi la documentazione",
            "form.subdomain": "Sottodominio",
            "form.submit": "Salva",
            "toast.success": "Sottodominio salvato."
        },
        "pt": {
            "title": "Subdomínio",
            "text": "Escolha o subdomínio em que o painel está disponível, atualmente {0}.",
            "link": "Leia a documentação",
            "form.subdomain": "Subdomínio",
            "form.submit": "Salvar",
            "toast.success": "Subdomínio salvo."
        },
        "ja": {
            "title": "サブドメイン",
            "text": "現在 {0} にダッシュボードが利用できるサブドメインを選択します。",
            "link": "ドキュメントを読む",
            "form.subdomain": "サブドメイン",
            "form.submit": "保存",
            "toast.success": "サブドメインが保存されました。"
        }
    }
</i18n>
