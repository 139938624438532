import {defineStore} from "pinia";
import {ref} from "vue";
import {Lock} from "@/model/Lock";
import {LockRepo} from "@/repositories/LockRepo";

export const useLockStore = defineStore("lock", () => {
    const locks = ref<Lock[]>([]);
    let loadedDomain = false;
    let loadedAccount = false;

    async function load(domainID?: string) {
        try {
            if (domainID && !loadedDomain) {
                loadedDomain = true;
                const l = await LockRepo.list(domainID);
                locks.value = l as Lock[];
            } else if (!domainID && !loadedAccount) {
                loadedAccount = true;
                const l = await LockRepo.list();
                locks.value = l as Lock[];
            }

            return Promise.resolve<Lock[]>(locks.value);
        } catch {
            return Promise.reject();
        }
    }

    function set(lock: Lock) {
        locks.value = [...locks.value, lock];
    }

    function release(name: string) {
        locks.value = [...locks.value].filter(lock => lock.name !== name);
    }

    return {
        locks,
        load,
        set,
        release
    };
});
