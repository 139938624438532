<template>
    <CreateEditDomainAlternative :open="createDomainModal"
        v-on:close="createDomainModal = false"
        v-on:action="addDomain" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/domains-rollup" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <DomainCard v-for="domain in domains"
                        :key="domain.id"
                        :domain="domain"
                        v-on:update="updateDomain"
                        v-on:remove="removeDomain" />
                </div>
            </Expand>
            <button v-on:click="createDomainModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {DomainAlternative} from "@/model/DomainAlternative";
    import DomainCard from "@/components/cards/DomainCard.vue";
    import CreateEditDomainAlternative from "@/components/modal/CreateEditDomainAlternative.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            DomainCard,
            Loading,
            Expand,
            CreateEditDomainAlternative
        },
        setup() {
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const createDomainModal = ref(false);
            const loading = ref(true);
            const domains = ref<DomainAlternative[]>([]);

            watchEffect(async () => {
                loading.value = true;
                domains.value = await DomainRepo.listDomainAlternatives(domain.value.id) as DomainAlternative[] || [];
                loading.value = false;
            });

            function addDomain(domain: DomainAlternative) {
                createDomainModal.value = false;
                domains.value.push(domain);
            }

            function updateDomain(domain: DomainAlternative) {
                updateEntryByID(domains.value, domain);
            }

            function removeDomain(domain: DomainAlternative) {
                removeEntryByID(domains.value, domain);
            }

            return {
                ...useI18n(),
                createDomainModal,
                loading,
                domains,
                addDomain,
                updateDomain,
                removeDomain
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Additional Domains",
            "text": "Create additional domains to combine statistics from different websites or to send data to other dashboards.",
            "link": "Read the Docs",
            "add": "Add Domain"
        },
        "de": {
            "title": "Zusätzliche Domains",
            "text": "Erstelle zusätzliche Domains, um Statistiken von verschiedenen Webseiten zu kombinieren oder um Daten an andere Dashboards zu senden.",
            "link": "Dokumentation lesen",
            "add": "Domain hinzufügen"
        },
        "es": {
            "title": "Dominios adicionales",
            "text": "Crea dominios adicionales para combinar estadísticas de diferentes sitios web o para enviar datos a otros paneles.",
            "link": "Leer la documentación",
            "add": "Añadir dominio"
        },
        "fr": {
            "title": "Domaines supplémentaires",
            "text": "Créez des domaines supplémentaires pour combiner les statistiques de différents sites Web ou pour envoyer des données à d'autres tableaux de bord.",
            "link": "Lire la documentation",
            "add": "Ajouter un domaine"
        },
        "nl": {
            "title": "Extra domeinen",
            "text": "Maak extra domeinen om statistieken van verschillende websites te combineren of om gegevens naar andere dashboards te sturen.",
            "link": "Lees de documentatie",
            "add": "Domein toevoegen"
        },
        "it": {
            "title": "Domini aggiuntivi",
            "text": "Crea domini aggiuntivi per combinare le statistiche di diversi siti web o per inviare dati ad altre dashboard.",
            "link": "Leggi la documentazione",
            "add": "Aggiungi dominio"
        },
        "pt": {
            "title": "Domínios adicionais",
            "text": "Crie domínios adicionais para combinar estatísticas de diferentes sites ou para enviar dados para outros painéis.",
            "link": "Leia a documentação",
            "add": "Adicionar domínio"
        },
        "ja": {
            "title": "追加ドメイン",
            "text": "追加ドメインを作成して、異なるウェブサイトの統計情報を統合したり、他のダッシュボードにデータを送信したりします。",
            "link": "ドキュメントを読む",
            "add": "ドメインを追加"
        }
    }
</i18n>
