<template>
    <template v-if="organization">
        <h1>
            <router-link to="/organizations">
                <i class="icon icon-chevron icon-rotate-90 clickable"></i>
            </router-link>
            <span>{{organization.name}}</span>
        </h1>
        <PirschPlus :show="!user.free_trial && !user.subscription_plus && isOwner">
            <p>{{t("plus")}}</p>
        </PirschPlus>
        <OrganizationMember :organization="organization" />
        <hr />
        <OrganizationThemes :organization="organization" />
        <hr />
        <OrganizationDomains :organization="organization" />
        <template v-if="isOwner">
            <hr />
            <OrganizationName :organization="organization" />
            <hr />
            <OrganizationAutoJoin :organization="organization" />
            <hr />
            <DeleteOrganization :organization="organization" />
        </template>
    </template>
    <section class="empty" v-else-if="!loading">
        <i class="icon icon-group"></i>
        <p>{{t("not_found")}}</p>
    </section>
    <LoadingPage :loading="loading" />
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import {useRoute} from "vue-router";
    import {storeToRefs} from "pinia";
    import {useOrganizationStore} from "@/store/OrganizationStore";
    import OrganizationMember from "@/components/organizations/OrganizationMember.vue";
    import OrganizationThemes from "@/components/themes/OrganizationThemes.vue";
    import OrganizationDomains from "@/components/organizations/OrganizationDomains.vue";
    import OrganizationName from "@/components/organizations/OrganizationName.vue";
    import DeleteOrganization from "@/components/organizations/DeleteOrganization.vue";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import LoadingPage from "@/components/bits/LoadingPage.vue";
    import OrganizationAutoJoin from "@/components/organizations/OrganizationAutoJoin.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            OrganizationAutoJoin,
            LoadingPage,
            DeleteOrganization,
            OrganizationName,
            OrganizationDomains,
            OrganizationThemes,
            OrganizationMember,
            PirschPlus
        },
        setup() {
            const {user} = storeToRefs(useUserStore());
            const organizationStore = useOrganizationStore();
            const route = useRoute();
            const {organizations, loading} = storeToRefs(organizationStore);
            const id = computed(() => route.params.id);
            const organization = computed(() => organizations.value.find(orga => orga.id === id.value));
            const isOwner = computed(() => organization.value && organization.value.user_id === user.value.id);

            return {
                ...useI18n(),
                user,
                loading,
                organization,
                isOwner
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "plus": "Managing organizations requires a Pirsch Plus subscription.",
            "not_found": "Organization was not found."
        },
        "de": {
            "plus": "Das Verwalten von Organisationen erfordert ein Pirsch Plus-Abonnement.",
            "not_found": "Organisation wurde nicht gefunden."
        },
        "es": {
            "plus": "La gestión de organizaciones requiere una suscripción a Pirsch Plus.",
            "not_found": "No se encontró la organización."
        },
        "fr": {
            "plus": "La gestion des organisations nécessite un abonnement Pirsch Plus.",
            "not_found": "Organisation non trouvée."
        },
        "nl": {
            "plus": "Het beheren van organisaties vereist een Pirsch Plus-abonnement.",
            "not_found": "Organisatie niet gevonden."
        },
        "it": {
            "plus": "La gestione delle organizzazioni richiede un abbonamento a Pirsch Plus.",
            "not_found": "Organizzazione non trovata."
        },
        "pt": {
            "plus": "Gerenciar organizações requer uma assinatura Pirsch Plus.",
            "not_found": "Organização não encontrada."
        },
        "ja": {
            "plus": "組織の管理にはPirsch Plusのサブスクリプションが必要です。",
            "not_found": "組織が見つかりませんでした。"
        }
    }
</i18n>
