<template>
    <div class="card funnel-card" style="max-height: 2000px;">
        <div class="card-content cursor-pointer no-select" v-on:click="toggle">
            <div class="card-info">
                <div class="left">
                    <h4>{{funnel.name}}</h4>
                </div>
                <div class="right">
                    <p>{{t("steps", {count: funnel.steps.length})}}</p>
                </div>
            </div>
            <div class="card-actions">
                <template v-if="loggedIn && domain.user_role && isAdmin">
                    <Lock :lock="`funnel_${funnel.id}`">
                        <i class="icon icon-edit clickable" :title="t('edit')" v-on:click.stop="$emit('edit', funnel)"></i>
                        <i class="icon icon-trash clickable" :title="t('delete')" v-on:click.stop="$emit('delete', funnel)"></i>
                    </Lock>
                    <i class="icon icon-copy clickable" :title="t('copy')" v-on:click.stop="$emit('copy', funnel)"></i>
                </template>
                <i :class="{'icon icon-chevron clickable': true, 'icon-rotate-180': details}"></i>
            </div>
        </div>
        <Expand>
            <div class="card-details" v-show="details">
                <div v-show="loaded" style="width: 100%; overflow-x: auto;">
                    <div style="min-width: fit-content;">
                        <div class="metrics">
                            <div class="metric" v-for="(step, i) in steps">
                                <div>
                                    <h5>{{t("step_name")}}</h5>
                                    <p><strong>{{funnel.steps[i].name}}</strong></p>
                                </div>
                                <div>
                                    <h5>{{t("conversion_rate")}}</h5>
                                    <p style="display: flex; gap: 16px;">
                                        <strong :title="formatNumber(step.relative_visitors)">{{formatPercent(step.relative_visitors)}}%</strong>
                                        <span class="previous" :title="formatNumber(previousSteps[i].relative_visitors)" v-if="previousSteps.length">
                                            {{formatPercent(previousSteps[i].relative_visitors)}}%
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <h5>{{t("visitors")}}</h5>
                                    <p style="display: flex; gap: 16px;">
                                        <strong :title="formatNumber(step.visitors)">{{formatAndShortenNumber(step.visitors)}}</strong>
                                        <span class="previous" :title="formatNumber(previousSteps[i].visitors)" v-if="previousSteps.length">
                                            {{formatAndShortenNumber(previousSteps[i].visitors)}}
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <h5>{{t("dropped")}}</h5>
                                    <p style="display: flex; gap: 16px;">
                                        <strong>
                                            <span :title="formatNumber(step.drop_off)">{{formatPercent(step.drop_off)}}%</span> <span :title="formatNumber(step.dropped)">({{formatAndShortenNumber(step.dropped)}})</span>
                                        </strong>
                                        <span class="previous" v-if="previousSteps.length">
                                            <span :title="formatNumber(previousSteps[i].drop_off)">{{formatPercent(previousSteps[i].drop_off)}}%</span> <span :title="formatNumber(previousSteps[i].dropped)">({{formatAndShortenNumber(previousSteps[i].dropped)}})</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="funnel-chart">
                            <div class="chart-container">
                                <canvas class="statistics-canvas"
                                    ref="canvas"
                                    width="100%"
                                    height="100%"
                                    style="transform: scaleY(-1);"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="min-height: 288px; display: flex; justify-content: center; align-items: center;" v-if="!loaded">
                    <Loading :loading="!loaded" />
                </div>
            </div>
        </Expand>
    </div>
</template>

<script lang="ts">
    import {defineComponent, onUnmounted, PropType, ref, watch} from "vue";
    import {Funnel} from "@/model/Funnel";
    import {useI18n} from "vue-i18n";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {FunnelData} from "@/model/FunnelData";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {Chart} from "chart.js";
    import {getBackgroundColor, getBorderColor} from "@/components/panels/chart";
    import {useThemeStore} from "@/store/ThemeStore";
    import {formatAndShortenNumber, formatNumber, formatPercent} from "@/util/format";
    import {FunnelStepData} from "@/model/FunnelStepData";
    import Expand from "@/components/bits/Expand.vue";
    import Loading from "@/components/bits/Loading.vue";
    import Lock from "@/components/settings/Lock.vue";
    import {useStatisticsStore} from "@/store/StatisticsStore";

    export default defineComponent({
        components: {
            Loading,
            Expand,
            Lock
        },
        props: {
            funnel: {type: Object as PropType<Funnel>, required: true}
        },
        emits: ["edit", "copy", "delete"],
        setup(props) {
            const {t} = useI18n();
            const {comparisonSet, getPreviousFilter} = useStatisticsStore();
            const {filter, comparison} = storeToRefs(useFilterStore());
            const {theme, darkMode} = storeToRefs(useThemeStore());
            const details = ref(false);
            const loaded = ref(false);
            const canvas = ref(document.createElement("canvas"));
            const steps = ref<FunnelStepData[]>([]);
            const previousSteps = ref<FunnelStepData[]>([]);
            let loading = true;
            let labels: string[] = [];
            let data: number[] = [];
            let previousData: number[] = [];
            let chart: Chart | undefined;

            onUnmounted(() => {
                chart?.destroy();
                chart = undefined;
            });

            watch([filter, comparison, () => props.funnel], () => {
                loading = true;
                createOrUpdateChart();
            });

            watch(darkMode, () => {
                createOrUpdateChart();
            });

            function toggle() {
                details.value = !details.value;
                createOrUpdateChart();
            }

            async function createOrUpdateChart() {
                if (details.value) {
                    if (loading) {
                        const f = await StatisticsRepo.funnel(props.funnel.id, filter.value) as FunnelData;
                        labels = f.definition.steps.map(step => step.name);
                        steps.value = f.data;
                        data = f.data.map(step => Math.round(step.relative_visitors * 1000) / 1000);

                        if (comparisonSet()) {
                            const previousFilter = getPreviousFilter();

                            if (previousFilter) {
                                const previousFunnel = await StatisticsRepo.funnel(props.funnel.id, previousFilter) as FunnelData;
                                previousSteps.value = previousFunnel.data;
                                previousData = previousFunnel.data.map(step => Math.round(step.relative_visitors * 1000) / 1000);
                            } else {
                                resetPreviousData();
                            }
                        } else {
                            resetPreviousData();
                        }
                    }

                    if (chart) {
                        updateChart();
                    } else {
                        createChart();
                    }

                    loaded.value = true;
                    loading = false;
                }
            }

            function resetPreviousData() {
                previousSteps.value = [];
                previousData = [];
            }

            function createChart() {
                chart?.destroy();
                const datasets = [
                    {
                        data,
                        backgroundColor: getBackgroundColor(192, darkMode.value, "green", false, true, theme.value),
                        borderColor: getBorderColor(darkMode.value, "green", false, theme.value),
                        borderWidth: 2,
                        fill: true,
                        align: "right"
                    }
                ];

                if (previousData.length) {
                    datasets.push({
                        data: previousData,
                        backgroundColor: getBackgroundColor(192, darkMode.value, "green", true, true, theme.value),
                        borderColor: getBorderColor(darkMode.value, "green", true, theme.value),
                        borderWidth: 2,
                        fill: true,
                        align: "right"
                    });
                }

                chart = new Chart(canvas.value, {
                    type: "funnel",
                    data: {
                        labels,
                        datasets
                    },
                    options: {
                        scales: {
                            x: {
                                stacked: true,
                            }
                        },
                        maintainAspectRatio: false,
                        animation: {
                            duration: 200
                        },
                        plugins: {
                            tooltip: {
                                enabled: false
                            }
                        }
                    }
                });
            }

            function updateChart() {
                if (chart &&
                    chart.data.datasets.length > 0 &&
                    chart.options.plugins) {
                    chart.data.labels = labels;
                    chart.data.datasets[0].data = data;
                    chart.data.datasets[0].backgroundColor = getBackgroundColor(192, darkMode.value, "green", false, true, theme.value);

                    if (chart.data.datasets.length > 1 && previousData.length) {
                        chart.data.datasets[1].data = previousData;
                        chart.data.datasets[1].backgroundColor = getBackgroundColor(192, darkMode.value, "green", true, true, theme.value);
                    } else if (chart.data.datasets.length > 1 && !previousData.length) {
                        chart.data.datasets.splice(1, 1);
                    } else if (previousData.length) {
                        chart.data.datasets.push({
                            data: previousData,
                            backgroundColor: getBackgroundColor(192, darkMode.value, "green", true, true, theme.value),
                            borderColor: getBorderColor(darkMode.value, "green", true, theme.value),
                            borderWidth: 2,
                            fill: true,
                            align: "right"
                        });
                    }

                    chart.update();
                }
            }

            return {
                formatAndShortenNumber,
                formatPercent,
                formatNumber,
                t,
                ...useUserStore(),
                ...useDomainStore(),
                details,
                loaded,
                canvas,
                steps,
                previousSteps,
                toggle
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "steps": "{count} Steps",
            "edit": "Edit Funnel",
            "copy": "Copy Funnel",
            "delete": "Delete Funnel",
            "step_name": "Step Name",
            "conversion_rate": "Conversion Rate",
            "visitors": "Visitors",
            "dropped": "Dropped"
        },
        "de": {
            "steps": "{count} Schritte",
            "edit": "Filter bearbeiten",
            "copy": "Filter kopieren",
            "delete": "Filter löschen",
            "step_name": "Schrittname",
            "conversion_rate": "Konversionsrate",
            "visitors": "Besucher",
            "dropped": "Abgebrochen"
        },
        "es": {
            "steps": "{count} Pasos",
            "edit": "Editar embudo",
            "copy": "Copiar embudo",
            "delete": "Eliminar embudo",
            "step_name": "Nombre del paso",
            "conversion_rate": "Tasa de conversión",
            "visitors": "Visitantes",
            "dropped": "Caídos"
        },
        "fr": {
            "steps": "{count} Étapes",
            "edit": "Modifier l'entonnoir",
            "copy": "Copier l'entonnoir",
            "delete": "Supprimer l'entonnoir",
            "step_name": "Nom de l'étape",
            "conversion_rate": "Taux de conversion",
            "visitors": "Visiteurs",
            "dropped": "Abandonné"
        },
        "nl": {
            "steps": "{count} Stappen",
            "edit": "Trechter bewerken",
            "copy": "Trechter kopiëren",
            "delete": "Trechter verwijderen",
            "step_name": "Stapnaam",
            "conversion_rate": "Conversieratio",
            "visitors": "Bezoekers",
            "dropped": "Afgehaakt"
        },
        "it": {
            "steps": "{count} Passaggi",
            "edit": "Modifica imbuto",
            "copy": "Copia imbuto",
            "delete": "Elimina imbuto",
            "step_name": "Nome del passaggio",
            "conversion_rate": "Tasso di conversione",
            "visitors": "Visitatori",
            "dropped": "Abbandonati"
        },
        "pt": {
            "steps": "{count} Passos",
            "edit": "Editar funil",
            "copy": "Copiar funil",
            "delete": "Excluir funil",
            "step_name": "Nome do passo",
            "conversion_rate": "Taxa de conversão",
            "visitors": "Visitantes",
            "dropped": "Desistiram"
        },
        "ja": {
            "steps": "{count} ステップ",
            "edit": "ファネルを編集",
            "copy": "ファネルをコピー",
            "delete": "ファネルを削除",
            "step_name": "ステップ名",
            "conversion_rate": "コンバージョン率",
            "visitors": "訪問者",
            "dropped": "離脱"
        }
    }
</i18n>
