import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekOfYear from "dayjs/plugin/weekOfYear";
import {getDate} from "@/util/date";
import {Time} from "@/model/TimeRange";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);

const matchDateParam = new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);

export function dateParam(date: Date): string {
    return dayjs(date).format("YYYY-MM-DD");
}

export function dateFromParam(date?: string): Date | null {
    if (!date || !matchDateParam.test(date)) {
        return null;
    }

    const [yearPart, monthPart, dayPart] = date.split("-");
    const year = parseInt(yearPart);
    const month = parseInt(monthPart)-1;
    const day = parseInt(dayPart);
    return getDate(year, month, day, false);
}

export function timeParam(time: Time): string {
    return `${time.hour < 10 ? '0'+time.hour : time.hour}:${time.minute < 10 ? '0'+time.minute : time.minute}`;
}

export function timeFromParam(time?: string): Time | null {
    if (!time) {
        return null;
    }

    const parts = time.split(":");

    if (parts.length !== 2) {
        return null;
    }

    const hour = parseInt(parts[0]);
    const minute = parseInt(parts[1]);

    if (hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
        return new Time(hour, minute);
    }

    return null;
}

export function day(date: Date | string, includeYear?: boolean): string {
    return dayjs(date).utc().format(includeYear ? "dd, MMM DD, YYYY" : "dd, MMM DD");
}

export function dayShort(date: Date | string): string {
    return dayjs(date).format("MMM DD");
}

export function dayTimeShort(date: Date | string): string {
    return dayjs(date).format("MMM DD HH:mm:ss");
}

export function week(date: Date | string, includeYear?: boolean): string {
    if (includeYear) {
        const d = dayjs(date).utc();
        return `${d.week().toString()}, ${d.year().toString()}`;
    }

    return dayjs(date).utc().week().toString();
}

export function month(date: Date | string, includeYear?: boolean): string {
    return dayjs(date).utc().format(includeYear ? "MMMM, YYYY" : "MMMM");
}

export function year(date: Date | string): string {
    return dayjs(date).utc().year().toString();
}

export function formatPercent(n: number, locale?: string): string {
    if (Math.round(n*1000)/1000 > 0 && n < 0.001) {
        return "< "+(0.1).toLocaleString(locale);
    }

    return (Math.round(n*1000)/10).toLocaleString(locale);
}

export function formatAndShortenNumber(x: number, locale?: string): string {
    if (x < 1000) {
        return (Math.floor(x*100)/100).toLocaleString(locale);
    }

    if (x < 1000*1000) {
        return (Math.floor(x/1000*100)/100).toLocaleString(locale, {minimumFractionDigits: 1, maximumFractionDigits: 1})+"k";
    }

    return (Math.floor(x/1000000*1000)/1000).toLocaleString(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2})+"M";
}

export function formatNumber(x: number, locale?: string): string {
    return (Math.floor(x*100)/100).toLocaleString(locale);
}

export function formatHour(hour: number, locales?: string[]): string {
    return new Date(0, 0, 0, hour).toLocaleTimeString(locales, {hour: "2-digit", minute: "2-digit"});
}

export function formatMinute(minute: number): string {
    return minute < 10 ? `00:0${minute}` : `00:${minute}`;
}

export function formatSeconds(seconds: number): string {
    if (seconds === 0) {
        return "0"
    }

    if (seconds < 60) {
        return dayjs.duration(seconds, "seconds").format("s[s]");
    }

    if (seconds < 3600) {
        return dayjs.duration(seconds, "seconds").format("m[m] s[s]");
    }

    return dayjs.duration(seconds, "seconds").format("H[h] m[m] s[s]");
}

export function formatCents(n: number, locale?: string): string {
    return (n/100).toLocaleString(locale, {
        style: "currency",
        currency: "USD"
    });
}
