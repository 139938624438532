<template>
    <div class="list">
        <ListTabs :tabs="tabs" v-on:enlarge="enlarge" v-on:open="openTab" />
        <OS :visible="open === 0" v-show="open === 0" />
        <Browser :visible="open === 1" v-show="open === 1" />
        <Platform :visible="open === 2" v-show="open === 2" />
        <Screen :visible="open === 3" v-show="open === 3" />
        <OSDetails :open="details === 0" v-on:close="details = -1" />
        <BrowserDetails :open="details === 1" v-on:close="details = -1" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import OS from "@/components/statistics/OS.vue";
    import Browser from "@/components/statistics/Browser.vue";
    import Platform from "@/components/statistics/Platform.vue";
    import Screen from "@/components/statistics/Screen.vue";
    import OSDetails from "@/components/statistics/OSDetails.vue";
    import BrowserDetails from "@/components/statistics/BrowserDetails.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ListTabs,
            OS,
            Browser,
            Platform,
            Screen,
            OSDetails,
            BrowserDetails
        },
        setup() {
            const {t} = useI18n();
            const open = ref(0);
            const details = ref(-1);

            function enlarge(index: number) {
                details.value = index;
            }

            function openTab(index: number) {
                open.value = index;
            }

            return {
                tabs: [
                    {
                        title: t("tabs.os"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.browsers"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.platforms")
                    },
                    {
                        title: t("tabs.screens")
                    }
                ],
                open,
                details,
                enlarge,
                openTab
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "tabs.os": "OS",
            "tabs.browsers": "Browsers",
            "tabs.platforms": "Platforms",
            "tabs.screens": "Screens"
        },
        "de": {
            "tabs.os": "OS",
            "tabs.browsers": "Browser",
            "tabs.platforms": "Plattformen",
            "tabs.screens": "Bildschirme"
        },
        "es": {
            "tabs.os": "SO",
            "tabs.browsers": "Navegadores",
            "tabs.platforms": "Plataformas",
            "tabs.screens": "Pantallas"
        },
        "fr": {
            "tabs.os": "OS",
            "tabs.browsers": "Navigateurs",
            "tabs.platforms": "Plateformes",
            "tabs.screens": "Écrans"
        },
        "nl": {
            "tabs.os": "OS",
            "tabs.browsers": "Browsers",
            "tabs.platforms": "Platforms",
            "tabs.screens": "Schermen"
        },
        "it": {
            "tabs.os": "OS",
            "tabs.browsers": "Browser",
            "tabs.platforms": "Piattaforme",
            "tabs.screens": "Schermi"
        },
        "pt": {
            "tabs.os": "OS",
            "tabs.browsers": "Navegadores",
            "tabs.platforms": "Plataformas",
            "tabs.screens": "Telas"
        },
        "ja": {
            "tabs.os": "OS",
            "tabs.browsers": "ブラウザ",
            "tabs.platforms": "プラットフォーム",
            "tabs.screens": "スクリーン"
        }
    }
</i18n>
