<template>
    <div class="active-visitors" v-on:click="activePagesModal = true" v-if="active && getSettings(isPrivate ? 'active' : 'active_public')">
        <div class="dot"></div>
        <span v-if="mobile">{{formatNumber(activeVisitors.visitors)}}</span>
        <span v-else>{{t("active", {count: activeVisitors.visitors, visitors: formatNumber(activeVisitors.visitors)})}}</span>
    </div>
    <ActiveVisitorsDetails :open="activePagesModal" v-on:close="activePagesModal = false" />
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {formatAndShortenNumber} from "@/util/format";
    import ActiveVisitorsDetails from "@/components/statistics/ActiveVisitorsDetails.vue";
    import {storeToRefs} from "pinia";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {isDashboard} from "@/util/domain";
    import {useI18n} from "vue-i18n";
    import {useMobile} from "@/components/mobile";

    export default defineComponent({
        components: {
            ActiveVisitorsDetails
        },
        setup() {
            const domainStore = useDomainStore();
            const {active} = storeToRefs(domainStore);
            const {getSettings} = domainStore;
            const {activeVisitors} = storeToRefs(useStatisticsStore());
            const activePagesModal = ref(false);

            return {
                ...useI18n(),
                ...useMobile(),
                isPrivate: isDashboard(),
                formatNumber: formatAndShortenNumber,
                active,
                getSettings,
                activeVisitors,
                activePagesModal
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "active": "0 active visitors | 1 active visitor | {visitors} active visitors"
        },
        "de": {
            "active": "0 aktive Besucher | 1 aktiver Besucher | {visitors} aktive Besucher"
        },
        "es": {
            "active": "0 visitantes activos | 1 visitante activo | {visitors} visitantes activos"
        },
        "fr": {
            "active": "0 visiteur actif | 1 visiteur actif | {visitors} visiteurs actifs"
        },
        "nl": {
            "active": "0 actieve bezoekers | 1 actieve bezoeker | {visitors} actieve bezoekers"
        },
        "it": {
            "active": "0 visitatori attivi | 1 visitatore attivo | {visitors} visitatori attivi"
        },
        "pt": {
            "active": "0 visitantes ativos | 1 visitante ativo | {visitors} visitantes ativos"
        },
        "ja": {
            "active": "アクティブ訪問者は0人 | アクティブ訪問者は1人 | アクティブ訪問者は{visitors}人"
        }
    }
</i18n>
