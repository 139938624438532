<template>
    <div :class="{'action': true, active: dropdown || active}" :title="tooltip" v-on:click="open" ref="dropdownElement">
        <i :class="`icon icon-${icon}`"></i>
        <span v-if="title">{{title}}</span>
        <i :class="{'icon icon-expand': true, 'icon-rotate-180': dropdown}" v-if="hasDropdown"></i>
        <transition name="fade-down">
            <ul class="dropdown right" v-if="hasDropdown" v-show="dropdown">
                <slot></slot>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {useDropdown} from "@/components/dropdown";
    import {useRouter} from "vue-router";

    export default defineComponent({
        props: {
            icon: String,
            title: String,
            tooltip: String,
            path: String,
            url: String,
            active: {type: Boolean, default: false},
            force: {type: Boolean, default: false}
        },
        setup(props, {slots}) {
            const {dropdownElement, toggleDropdown, dropdown} = useDropdown();
            const router = useRouter();

            function open() {
                if (props.path) {
                    router.push({path: props.path, force: props.force, query: router.currentRoute.value.query});
                } else if (props.url) {
                    location.href = props.url;
                } else {
                    toggleDropdown();
                }
            }

            return {
                dropdownElement,
                toggleDropdown,
                dropdown,
                hasDropdown: !!slots.default,
                open
            };
        }
    });
</script>
