<template>
    <section class="row">
        <h3>{{t("title")}}</h3>
        <FormCheckbox :label="t('form.marketing')" name="acceptMarketing" v-model="acceptMarketing" v-on:change="toggle" />
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormCheckbox
        },
        setup() {
            const {t} = useI18n();
            const {success} = useToastStore();
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);
            const acceptMarketing = ref(user.value.accept_marketing);

            watch(user, value => {
                acceptMarketing.value = value.accept_marketing;
            });

            async function toggle() {
                if (user) {
                    await UserRepo.toggleMarketing();
                    await userStore.load();
                    success(t("toast.success"));
                }
            }

            return {
                t,
                acceptMarketing,
                toggle
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Newsletter",
            "form.marketing": "I would like to receive regular updates on Pirsch via email.",
            "toast.success": "Settings saved."
        },
        "de": {
            "title": "Newsletter",
            "form.marketing": "Ich möchte regelmäßige Updates zu Pirsch per E-Mail erhalten.",
            "toast.success": "Einstellungen gespeichert."
        },
        "es": {
            "title": "Boletín informativo",
            "form.marketing": "Me gustaría recibir actualizaciones regulares sobre Pirsch por correo electrónico.",
            "toast.success": "Configuraciones guardadas."
        },
        "fr": {
            "title": "Newsletter",
            "form.marketing": "Je souhaite recevoir des mises à jour régulières sur Pirsch par e-mail.",
            "toast.success": "Paramètres enregistrés."
        },
        "nl": {
            "title": "Nieuwsbrief",
            "form.marketing": "Ik wil graag regelmatige updates over Pirsch via e-mail ontvangen.",
            "toast.success": "Instellingen opgeslagen."
        },
        "it": {
            "title": "Newsletter",
            "form.marketing": "Vorrei ricevere aggiornamenti regolari su Pirsch via email.",
            "toast.success": "Impostazioni salvate."
        },
        "pt": {
            "title": "Newsletter",
            "form.marketing": "Gostaria de receber atualizações regulares sobre o Pirsch por e-mail.",
            "toast.success": "Configurações salvas."
        },
        "ja": {
            "title": "ニュースレター",
            "form.marketing": "Pirschに関する定期的な更新情報をメールで受け取りたいです。",
            "toast.success": "設定が保存されました。"
        }
    }
</i18n>
