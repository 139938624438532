<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.email')" name="email" type="email" :autofocus="true" v-model="email" v-on:keydown.enter="addEmail" />
            <i class="icon icon-add clickable" :title="t('form.add')" v-on:click="addEmail"></i>
        </div>
        <div style="min-height: 12px;">
            <div class="item" v-for="e in emails" :key="e">
                <p>
                    <strong>{{e}}</strong>
                </p>
                <i class="icon icon-close clickable" v-on:click="removeEmail(e)"></i>
            </div>
        </div>
        <form v-on:submit.prevent="save">
            <FormSelect :label="t('form.link_to')"
                name="link_to"
                :hint="t('form.link_to.hint')"
                v-model="linkTo"
                :options="reportOptions" />
            <FormSubmit :value="t('form.submit')" :disabled="emails.length === 0" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {emailReportLinkToOptions} from "@/model/EmailReport";
    import {TrafficSpikeRepo} from "@/repositories/TrafficSpikeRepo";
    import FormInput from "@/components/form/FormInput.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput,
            FormSubmit,
            FormSelect
        },
        emits: ["action", "close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const email = ref("");
            const emails = ref<string[]>([]);
            const linkTo = ref("");
            const reportOptions = computed(() => {
                const options = [];

                for (let i = 0; i < emailReportLinkToOptions.length; i++) {
                    options.push({
                        label: t("options.report."+emailReportLinkToOptions[i].label),
                        value: emailReportLinkToOptions[i].value
                    });
                }

                return options;
            });

            function addEmail() {
                email.value = email.value.trim().toLowerCase();

                if (email.value) {
                    for (let i = 0; i < emails.value.length; i++) {
                        if (emails.value[i] === email.value) {
                            email.value = "";
                            return;
                        }
                    }

                    emails.value.push(email.value);
                    email.value = "";
                }
            }

            function removeEmail(email: string) {
                for (let i = 0; i < emails.value.length; i++) {
                    if (emails.value[i] === email) {
                        emails.value.splice(i, 1);
                        break;
                    }
                }
            }

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const notifications = await TrafficSpikeRepo.add(domain.value.id, emails.value, linkTo.value);
                    success(t("toast.success"));
                    email.value = "";
                    emails.value = [];
                    linkTo.value = "";
                    loading.value = false;
                    emit("action", notifications);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                reportOptions,
                err,
                loading,
                email,
                emails,
                linkTo,
                addEmail,
                removeEmail,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Add Traffic Spike Notifications",
            "text": "Added emails to receive traffic spike notifications when your website receives an unusual amount of traffic. Click '+' or press ENTER after each one to add them to the list of addresses.",
            "form.email": "Email Address",
            "form.add": "Add Email",
            "form.link_to": "Link To",
            "form.link_to.hint": "Public dashboards and custom domains must be configured. Otherwise, the notification will fall back to the private dashboard.",
            "form.submit": "Add Notifications",
            "options.report.Private Dashboard (Login Required)": "Private Dashboard (Login Required)",
            "options.report.Public Dashboard": "Public Dashboard",
            "options.report.Custom Domain": "Custom Domain",
            "toast.success": "The notifications have been added."
        },
        "de": {
            "title": "Benachrichtigungen über Traffic-Spikes hinzufügen",
            "text": "E-Mails hinzugefügt, um Benachrichtigungen über Traffic-Spikes zu erhalten, wenn deine Webseite eine ungewöhnlich hohe Menge an Traffic erhält. Klicke auf '+' oder drücke ENTER nach jeder, um sie der Liste der Adressen hinzuzufügen.",
            "form.email": "E-Mail-Adresse",
            "form.add": "E-Mail hinzufügen",
            "form.link_to": "Verknüpfen mit",
            "form.link_to.hint": "Öffentliche Dashboards und benutzerdefinierte Domains müssen konfiguriert sein. Andernfalls wird die Benachrichtigung auf das private Dashboard zurückgreifen.",
            "form.submit": "Benachrichtigungen hinzufügen",
            "options.report.Private Dashboard (Login Required)": "Privates Dashboard (Anmeldung erforderlich)",
            "options.report.Public Dashboard": "Öffentliches Dashboard",
            "options.report.Custom Domain": "Benutzerdefinierte Domain",
            "toast.success": "Die Benachrichtigungen wurden hinzugefügt."
        },
        "es": {
            "title": "Agregar notificaciones de picos de tráfico",
            "text": "Se añadieron correos electrónicos para recibir notificaciones de picos de tráfico cuando tu sitio web reciba una cantidad inusual de tráfico. Haz clic en '+' o presiona ENTER después de cada uno para agregarlos a la lista de direcciones.",
            "form.email": "Dirección de correo electrónico",
            "form.add": "Agregar correo electrónico",
            "form.link_to": "Enlazar a",
            "form.link_to.hint": "Los paneles públicos y los dominios personalizados deben estar configurados. De lo contrario, la notificación recurrirá al panel privado.",
            "form.submit": "Agregar notificaciones",
            "options.report.Private Dashboard (Login Required)": "Panel privado (Inicio de sesión requerido)",
            "options.report.Public Dashboard": "Panel público",
            "options.report.Custom Domain": "Dominio personalizado",
            "toast.success": "Las notificaciones se han agregado."
        },
        "fr": {
            "title": "Ajouter des notifications de pics de trafic",
            "text": "Des e-mails ont été ajoutés pour recevoir des notifications de pics de trafic lorsque votre site web reçoit une quantité inhabituelle de trafic. Cliquez sur '+' ou appuyez sur ENTER après chaque pour les ajouter à la liste des adresses.",
            "form.email": "Adresse e-mail",
            "form.add": "Ajouter un e-mail",
            "form.link_to": "Lien vers",
            "form.link_to.hint": "Les tableaux de bord publics et les domaines personnalisés doivent être configurés. Sinon, la notification reviendra au tableau de bord privé.",
            "form.submit": "Ajouter des notifications",
            "options.report.Private Dashboard (Login Required)": "Tableau de bord privé (Connexion requise)",
            "options.report.Public Dashboard": "Tableau de bord public",
            "options.report.Custom Domain": "Domaine personnalisé",
            "toast.success": "Les notifications ont été ajoutées."
        },
        "nl": {
            "title": "Verkeerspiekmeldingen toevoegen",
            "text": "E-mails toegevoegd om verkeerspiekmeldingen te ontvangen wanneer je website een ongebruikelijke hoeveelheid verkeer ontvangt. Klik op '+' of druk op ENTER na elke om ze toe te voegen aan de lijst met adressen.",
            "form.email": "E-mailadres",
            "form.add": "E-mail toevoegen",
            "form.link_to": "Koppelen aan",
            "form.link_to.hint": "Openbare dashboards en aangepaste domeinen moeten worden geconfigureerd. Anders zal de melding terugvallen op het privé-dashboard.",
            "form.submit": "Meldingen toevoegen",
            "options.report.Private Dashboard (Login Required)": "Privé-dashboard (inloggen vereist)",
            "options.report.Public Dashboard": "Openbaar dashboard",
            "options.report.Custom Domain": "Aangepast domein",
            "toast.success": "De meldingen zijn toegevoegd."
        },
        "it": {
            "title": "Aggiungi notifiche di picchi di traffico",
            "text": "E-mail aggiunte per ricevere notifiche di picchi di traffico quando il tuo sito web riceve una quantità insolita di traffico. Fai clic su '+' o premi INVIO dopo ciascuno per aggiungerli all'elenco degli indirizzi.",
            "form.email": "Indirizzo email",
            "form.add": "Aggiungi email",
            "form.link_to": "Collega a",
            "form.link_to.hint": "I dashboard pubblici e i domini personalizzati devono essere configurati. In caso contrario, la notifica tornerà al dashboard privato.",
            "form.submit": "Aggiungi notifiche",
            "options.report.Private Dashboard (Login Required)": "Dashboard privato (accesso richiesto)",
            "options.report.Public Dashboard": "Dashboard pubblico",
            "options.report.Custom Domain": "Dominio personalizzato",
            "toast.success": "Le notifiche sono state aggiunte."
        },
        "pt": {
            "title": "Adicionar notificações de picos de tráfego",
            "text": "E-mails adicionados para receber notificações de picos de tráfego quando seu site receber uma quantidade incomum de tráfego. Clique em '+' ou pressione ENTER após cada um para adicioná-los à lista de endereços.",
            "form.email": "Endereço de e-mail",
            "form.add": "Adicionar e-mail",
            "form.link_to": "Vincular a",
            "form.link_to.hint": "Painéis públicos e domínios personalizados devem ser configurados. Caso contrário, a notificação voltará ao painel privado.",
            "form.submit": "Adicionar notificações",
            "options.report.Private Dashboard (Login Required)": "Painel privado (login obrigatório)",
            "options.report.Public Dashboard": "Painel público",
            "options.report.Custom Domain": "Domínio personalizado",
            "toast.success": "As notificações foram adicionadas."
        },
        "ja": {
            "title": "トラフィックスパイク通知を追加",
            "text": "Webサイトに異常な量のトラフィックが発生したときにトラフィックスパイク通知を受信するためのメールアドレスを追加しました。各メールアドレスの後に「+」をクリックするか、ENTERキーを押して、アドレスリストに追加します。",
            "form.email": "メールアドレス",
            "form.add": "メールを追加",
            "form.link_to": "リンク先",
            "form.link_to.hint": "公開ダッシュボードとカスタムドメインを設定する必要があります。それ以外の場合、通知はプライベートダッシュボードにフォールバックします。",
            "form.submit": "通知を追加",
            "options.report.Private Dashboard (Login Required)": "プライベートダッシュボード（ログイン必須）",
            "options.report.Public Dashboard": "公開ダッシュボード",
            "options.report.Custom Domain": "カスタムドメイン",
            "toast.success": "通知が追加されました。"
        }
    }
</i18n>
