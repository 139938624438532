<template>
    <Modal :title="t('title')" size="m" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <HiddenArea :title="t('website.title')">
            <template v-slot:description>{{t("website.text_0")}}</template>
            <template v-slot:default>
                <i18n-t keypath="website.text_1" tag="p">
                    <a href="https://github.com/pirsch-analytics/demo" target="_blank">GitHub</a>
                </i18n-t>
                <pre style="max-width: calc(100vw - 32px);">
                    <code v-html="websiteExample" />
                </pre>
                <a href="https://docs.pirsch.io/advanced/segmentation-tags" target="_blank" class="button">{{t("read_docs")}}</a>
            </template>
        </HiddenArea>
        <HiddenArea :title="t('backend.title')">
            <template v-slot:description>{{t("backend.text_0")}}</template>
            <template v-slot:default>
                <i18n-t keypath="backend.text_1" tag="p">
                    <a href="https://docs.pirsch.io/api-sdks/api" target="_blank">API</a>
                    <router-link to="/settings/integration">{{t("backend.text_1.link")}}</router-link>
                </i18n-t>
                <i18n-t keypath="backend.text_2" tag="p">
                    <a href="https://github.com/pirsch-analytics/demo" target="_blank">GitHub</a>
                </i18n-t>
                <pre style="max-width: calc(100vw - 32px);">
                    <code v-html="backendExample" />
                </pre>
                <a href="https://docs.pirsch.io/advanced/segmentation-tags" target="_blank" class="button">{{t("read_docs")}}</a>
            </template>
        </HiddenArea>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import HiddenArea from "@/components/bits/HiddenArea.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            HiddenArea,
            Modal
        },
        emits: ["close"],
        setup() {
            const websiteExample = ref(`&lt;!-- Attach data-tag-(key)="value" or data-tag="key1,key2,...". --&gt;
&lt;script defer type="text/javascript" src="https://api.pirsch.io/pa.js"
    id="pianjs"
    data-code="your_identification_code"
    data-tag-author="John"
    data-tag="food,vegetarian"&gt;&lt;/script&gt;`);
            const backendExample = ref(`POST https://api.pirsch.io/api/v1/hit

{
    "url":                          "https://example.com/path",
    "ip":                           "123.456.789.0",
    "user_agent":                   "User-Agent header",
    "accept_language":              "Accept-Language header (optional)",
    "sec_ch_ua":                    "Sec-CH-UA header (optional)",
    "sec_ch_ua_mobile":             "Sec-CH-UA-Mobile header (optional)",
    "sec_ch_ua_platform":           "Sec-CH-UA-Platform header (optional)",
    "sec_ch_ua_platform_version":   "Sec-CH-UA-Platform-Version header (optional)",
    "sec_ch_width":                 "Sec-CH-Width header (optional)",
    "sec_ch_viewport_width":        "Sec-CH-Viewport-Width header (optional)",
    "title":                        "Page title (optional)",
    "referrer" :                    "Referer header (optional)",
    "screen_width":                 1920,
    "screen_height":                1080,
    "tags": {
        "author": "John",
        "food": "1",
        "vegetarian": "1"
    }
}`);

            return {
                ...useI18n(),
                websiteExample,
                backendExample
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Send Tags",
            "text": "Tags are attached to page views and events and allow you to segment your audience based on data that we do not automatically collect.",
            "website.title": "Website Integration",
            "website.text_0": "Track tags from your website using the JavaScript snippet.",
            "website.text_1": "Here is an example on how you can track tags from your website. More examples can be found on {0}.",
            "backend.title": "Server-side Integration",
            "backend.text_0": "Track tags from your server without the need for JavaScript on your website.",
            "backend.text_1": "The server-side integration utilizes the {0}. {1} on the integration settings page to start using it.",
            "backend.text_1.link": "Create a Client",
            "backend.text_2": "Here is a simple example using the API. More examples can be found on {0}.",
            "read_docs": "Read the Documentation"
        },
        "de": {
            "title": "Tags senden",
            "text": "Tags werden an Seitenaufrufe und Events angehängt und ermöglichen es dir, dein Publikum basierend auf Daten zu segmentieren, die wir nicht automatisch erfassen.",
            "website.title": "Webseiten-Integration",
            "website.text_0": "Erfasse Tags auf deiner Webseite mit dem JavaScript-Snippet.",
            "website.text_1": "Hier ist ein Beispiel, wie du Tags auf deiner Webseite erfassen kannst. Weitere Beispiele findest du auf {0}.",
            "backend.title": "Server-seitige Integration",
            "backend.text_0": "Erfasse Tags auf deinem Server, ohne JavaScript auf deiner Webseite zu benötigen.",
            "backend.text_1": "Die serverseitige Integration nutzt die {0}. {1} auf der Integrationsseite, um sie zu verwenden.",
            "backend.text_1.link": "Erstelle einen Client",
            "backend.text_2": "Hier ist ein einfaches Beispiel mit der API. Weitere Beispiele findest du auf {0}.",
            "read_docs": "Dokumentation lesen"
        },
        "es": {
            "title": "Enviar etiquetas",
            "text": "Las etiquetas se adjuntan a las visitas de página y eventos y te permiten segmentar tu audiencia en función de datos que no recopilamos automáticamente.",
            "website.title": "Integración del sitio web",
            "website.text_0": "Rastrea etiquetas desde tu sitio web usando el fragmento de JavaScript.",
            "website.text_1": "Aquí tienes un ejemplo de cómo puedes rastrear etiquetas desde tu sitio web. Puedes encontrar más ejemplos en {0}.",
            "backend.title": "Integración del lado del servidor",
            "backend.text_0": "Rastrea etiquetas desde tu servidor sin necesidad de JavaScript en tu sitio web.",
            "backend.text_1": "La integración del lado del servidor utiliza la {0}. {1} en la página de configuración de la integración para comenzar a usarla.",
            "backend.text_1.link": "Crear un cliente",
            "backend.text_2": "Aquí tienes un ejemplo simple usando la API. Puedes encontrar más ejemplos en {0}.",
            "read_docs": "Leer la documentación"
        },
        "fr": {
            "title": "Envoyer des tags",
            "text": "Les tags sont attachés aux pages vues et aux événements et vous permettent de segmenter votre audience en fonction de données que nous ne collectons pas automatiquement.",
            "website.title": "Intégration du site Web",
            "website.text_0": "Suivez les tags de votre site Web à l'aide du snippet JavaScript.",
            "website.text_1": "Voici un exemple de la façon dont vous pouvez suivre les tags de votre site Web. Vous pouvez trouver plus d'exemples sur {0}.",
            "backend.title": "Intégration côté serveur",
            "backend.text_0": "Suivez les tags de votre serveur sans avoir besoin de JavaScript sur votre site Web.",
            "backend.text_1": "L'intégration côté serveur utilise la {0}. {1} sur la page des paramètres d'intégration pour commencer à l'utiliser.",
            "backend.text_1.link": "Créer un client",
            "backend.text_2": "Voici un exemple simple utilisant l'API. Vous pouvez trouver plus d'exemples sur {0}.",
            "read_docs": "Lire la documentation"
        },
        "nl": {
            "title": "Tags versturen",
            "text": "Tags worden gekoppeld aan paginaweergaven en gebeurtenissen en stellen je in staat om je publiek te segmenteren op basis van gegevens die we niet automatisch verzamelen.",
            "website.title": "Website-integratie",
            "website.text_0": "Volg tags van je website met behulp van de JavaScript-snippet.",
            "website.text_1": "Hier is een voorbeeld van hoe je tags van je website kunt volgen. Meer voorbeelden vind je op {0}.",
            "backend.title": "Server-side integratie",
            "backend.text_0": "Volg tags van je server zonder dat JavaScript op je website nodig is.",
            "backend.text_1": "De server-side integratie maakt gebruik van de {0}. {1} op de integratie-instellingenpagina om te beginnen met gebruiken.",
            "backend.text_1.link": "Maak een client",
            "backend.text_2": "Hier is een eenvoudig voorbeeld met behulp van de API. Meer voorbeelden vind je op {0}.",
            "read_docs": "Lees de documentatie"
        },
        "it": {
            "title": "Invia tag",
            "text": "I tag sono allegati alle visualizzazioni di pagina e agli eventi e ti consentono di segmentare il tuo pubblico in base a dati che non raccogliamo automaticamente.",
            "website.title": "Integrazione del sito web",
            "website.text_0": "Traccia i tag dal tuo sito web utilizzando il frammento di codice JavaScript.",
            "website.text_1": "Ecco un esempio di come puoi tracciare i tag dal tuo sito web. Puoi trovare più esempi su {0}.",
            "backend.title": "Integrazione lato server",
            "backend.text_0": "Traccia i tag dal tuo server senza bisogno di JavaScript sul tuo sito web.",
            "backend.text_1": "L'integrazione lato server utilizza il {0}. {1} sulla pagina delle impostazioni di integrazione per iniziare a utilizzarlo.",
            "backend.text_1.link": "Crea un client",
            "backend.text_2": "Ecco un esempio semplice utilizzando l'API. Puoi trovare più esempi su {0}.",
            "read_docs": "Leggi la documentazione"
        },
        "pt": {
            "title": "Enviar tags",
            "text": "As tags são anexadas a visualizações de página e eventos e permitem segmentar seu público com base em dados que não coletamos automaticamente.",
            "website.title": "Integração do site",
            "website.text_0": "Rastreie tags do seu site usando o snippet JavaScript.",
            "website.text_1": "Aqui está um exemplo de como você pode rastrear tags do seu site. Mais exemplos podem ser encontrados em {0}.",
            "backend.title": "Integração do lado do servidor",
            "backend.text_0": "Rastreie tags do seu servidor sem a necessidade de JavaScript no seu site.",
            "backend.text_1": "A integração do lado do servidor utiliza a {0}. {1} na página de configurações de integração para começar a usá-la.",
            "backend.text_1.link": "Criar um cliente",
            "backend.text_2": "Aqui está um exemplo simples usando a API. Mais exemplos podem ser encontrados em {0}.",
            "read_docs": "Leia a documentação"
        },
        "ja": {
            "title": "タグを送信",
            "text": "タグはページビューやイベントに付随し、自動的に収集しないデータに基づいてオーディエンスをセグメント化することができます。",
            "website.title": "ウェブサイト統合",
            "website.text_0": "JavaScriptスニペットを使用してウェブサイトからタグをトラッキングします。",
            "website.text_1": "ウェブサイトからタグをトラッキングする方法の例を以下に示します。詳細な例は{0}にあります。",
            "backend.title": "サーバーサイド統合",
            "backend.text_0": "ウェブサイト上でJavaScriptを必要とせずにサーバーからタグをトラッキングします。",
            "backend.text_1": "サーバーサイドの統合は{0}を利用します。{1}の統合設定ページで使用を開始します。",
            "backend.text_1.link": "クライアントを作成",
            "backend.text_2": "APIを使用したシンプルな例を以下に示します。詳細な例は{0}にあります。",
            "read_docs": "ドキュメントを読む"
        }
    }
</i18n>
