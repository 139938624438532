<template>
    <Modal size="l" :title="t('title')" :open="open">
        <div class="column">
            <CountryMap :load="true" :live="true" v-on:select="filterByCountry" />
            <TableEntries :title="t('title')"
                :columns="columns"
                :entries="activePages"
                :loading="loading"
                :newTab="true"
                :selectable="true"
                :showEnlarge="false"
                :sortable="true"
                v-on:filter="filterByPath"
                v-on:open="openPage" />
        </div>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import {formatAndShortenNumber} from "@/util/format";
    import {openPage} from "@/components/statistics/page";
    import {ListEntry} from "@/model/ListEntry";
    import {storeToRefs} from "pinia";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useFilterStore} from "@/store/FilterStore";
    import CountryMap from "@/components/statistics/CountryMap.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            CountryMap
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup() {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const statisticsStore = useStatisticsStore();
            const {setField} = filterStore;
            const {filter} = storeToRefs(filterStore);
            const {loadActiveVisitors} = statisticsStore;
            const {activePages} = storeToRefs(statisticsStore);
            const loading = ref(true);
            const columns = computed(() => [
                {key: "label", label: t("columns.path")},
                {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v)}
            ]);

            watch(activePages, () => loading.value = false);
            watch(filter, loadActiveVisitors);

            function filterByPath(entry: ListEntry) {
                setField("path", entry.label);
            }

            function filterByCountry(code: string) {
                setField("country", code);
            }

            return {
                t,
                columns,
                loading,
                activePages,
                filterByPath,
                filterByCountry,
                openPage
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Active Pages",
            "columns.path": "Path",
            "columns.visitors": "Visitors"
        },
        "de": {
            "title": "Aktive Seiten",
            "columns.path": "Pfad",
            "columns.visitors": "Besucher"
        },
        "es": {
            "title": "Páginas Activas",
            "columns.path": "Ruta",
            "columns.visitors": "Visitantes"
        },
        "fr": {
            "title": "Pages Actives",
            "columns.path": "Chemin",
            "columns.visitors": "Visiteurs"
        },
        "nl": {
            "title": "Actieve Pagina's",
            "columns.path": "Pad",
            "columns.visitors": "Bezoekers"
        },
        "it": {
            "title": "Pagine Attive",
            "columns.path": "Percorso",
            "columns.visitors": "Visitatori"
        },
        "pt": {
            "title": "Páginas Ativas",
            "columns.path": "Caminho",
            "columns.visitors": "Visitantes"
        },
        "ja": {
            "title": "アクティブページ",
            "columns.path": "パス",
            "columns.visitors": "訪問者"
        }
    }
</i18n>
