<template>
    <h2>{{t("title")}}</h2>
    <p>{{t("text")}}</p>
    <router-link to="/" class="button" style="margin-top: 16px;">{{t("back")}}</router-link>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            return {
                ...useI18n()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Page Not Found",
            "text": "The requested page does not exist.",
            "back": "Back to Start"
        },
        "de": {
            "title": "Seite nicht gefunden",
            "text": "Die angeforderte Seite existiert nicht.",
            "back": "Zurück zum Start"
        },
        "es": {
            "title": "Página no encontrada",
            "text": "La página solicitada no existe.",
            "back": "Volver al inicio"
        },
        "fr": {
            "title": "Page non trouvée",
            "text": "La page demandée n'existe pas.",
            "back": "Retour à l'accueil"
        },
        "nl": {
            "title": "Pagina niet gevonden",
            "text": "De opgevraagde pagina bestaat niet.",
            "back": "Terug naar Start"
        },
        "it": {
            "title": "Pagina non trovata",
            "text": "La pagina richiesta non esiste.",
            "back": "Torna all'inizio"
        },
        "pt": {
            "title": "Página não encontrada",
            "text": "A página solicitada não existe.",
            "back": "Voltar ao início"
        },
        "ja": {
            "title": "ページが見つかりません",
            "text": "リクエストされたページは存在しません。",
            "back": "スタートに戻る"
        }
    }
</i18n>
