<template>
    <ConfirmDeleteTrafficSpikeNotification :open="deleteNotificationModal"
        v-on:close="deleteNotificationModal = false"
        v-on:action="removeReport"
        :notification="notification" />
    <div class="card no-select">
        <div class="text">
            <p>
                <strong>{{notification.email}}</strong>
            </p>
            <small class="light">{{t("last", {date: notification.last_sent ? day(notification.last_sent) : "never"})}}</small>
        </div>
        <div class="row">
            <Lock :lock="`traffic_spike_${notification.id}`">
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteNotificationModal = true" />
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {day} from "@/util/format";
    import {TrafficSpike} from "@/model/TrafficSpike";
    import ConfirmDeleteTrafficSpikeNotification from "@/components/modal/ConfirmDeleteTrafficSpikeNotification.vue";
    import Lock from "@/components/settings/Lock.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Lock,
            ConfirmDeleteTrafficSpikeNotification
        },
        props: {
            notification: {type: Object as PropType<TrafficSpike>, required: true}
        },
        emits: ["remove"],
        setup(props, {emit}) {
            const deleteNotificationModal = ref(false);

            function removeReport() {
                deleteNotificationModal.value = false;
                emit("remove", props.notification);
            }

            return {
                ...useI18n(),
                day,
                deleteNotificationModal,
                removeReport
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "last": "Last sent: {date}",
            "delete": "Delete"
        },
        "de": {
            "last": "Zuletzt gesendet: {date}",
            "delete": "Löschen"
        },
        "es": {
            "last": "Último envío: {date}",
            "delete": "Eliminar"
        },
        "fr": {
            "last": "Dernier envoi : {date}",
            "delete": "Supprimer"
        },
        "nl": {
            "last": "Laatst verzonden: {date}",
            "delete": "Verwijderen"
        },
        "it": {
            "last": "Ultimo invio: {date}",
            "delete": "Elimina"
        },
        "pt": {
            "last": "Último envio: {date}",
            "delete": "Excluir"
        },
        "ja": {
            "last": "最終送信: {date}",
            "delete": "削除"
        }
    }
</i18n>
