import {APIError} from "@/repositories/APIError";
import {Member} from "@/model/Member";
import {Role} from "@/model/Role";
import {Domain} from "@/model/Domain";
import {Repository} from "@/repositories/Repository";

export const MemberRepo = new class extends Repository {
    async list(id: string, organization_id: string): Promise<Member[] | APIError> {
        return this.performGet<Member[]>("/member", {id, organization_id}, []);
    }

    async invite(id: string, organization_id: string, emails: string[]): Promise<APIError | null> {
        return this.performPost<null>("/member", {id, organization_id, emails});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/member", {id});
    }

    async updateRole(id: string, role: Role): Promise<APIError | null> {
        return this.performPut<null>("/member", {id, role});
    }

    async transferOwnership(domain_id: string, email: string): Promise<APIError | null> {
        return this.performPost<null>("/owner", {domain_id, email});
    }

    async listOwnershipTransfers(): Promise<Domain[] | APIError> {
        return this.performGet<Domain[]>("/owner", null, []);
    }

    async deleteOwnershipTransfer(domain_id: string): Promise<APIError | null> {
        return this.performDelete("/owner", {domain_id});
    }

    async acceptOwnershipTransfer(domain_id: string): Promise<APIError | null> {
        return this.performPut<null>("/owner", {domain_id});
    }
}
