<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/integrations/search-console" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <button class="white" v-on:click="signIn" v-if="!activeDomain.google_user_id">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" fill-rule="evenodd"><path d="M17.64 9.205c0-.638-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.258h2.909c1.702-1.567 2.684-3.874 2.684-6.615z" fill="#4285f4"/><path d="M9 18c2.43 0 4.467-.806 5.956-2.181l-2.909-2.258c-.806.54-1.837.859-3.048.859-2.344 0-4.328-1.583-5.036-3.71H.957v2.332C2.438 15.983 5.482 18 9 18z" fill="#34a853"/><path d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9a5.41 5.41 0 0 1 .282-1.71V4.958H.957C.348 6.173 0 7.548 0 9s.348 2.827.957 4.042l3.007-2.332z" fill="#fbbc05"/><path d="M9 3.58c1.321 0 2.508.454 3.441 1.346l2.581-2.581C13.463.892 11.426 0 9 0 5.482 0 2.438 2.017.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#ea4335"/></svg>
                <span>{{t("google.continue")}}</span>
            </button>
            <template v-else>
                <form v-on:submit.prevent>
                    <FormSelect :label="t('form.website')" name="website" v-model="domain" :options="domains" v-on:change="setDomain" />
                </form>
                <h5 style="margin-top: 16px;">{{t("google.linked")}}</h5>
                <p style="margin-top: 4px;">
                    <strong>{{activeDomain.google_user_email}}</strong>
                </p>
                <button class="red" style="margin-top: 12px;" v-on:click="unlink">{{t("google.unlink")}}</button>
            </template>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref, watch} from "vue";
    import {getQueryParam, setQueryParam} from "@/util/url";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {useError} from "@/components/error";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {SelectOption} from "@/components/form/SelectOption";
    import Cookies from "js-cookie";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect
        },
        setup() {
            const {t} = useI18n();
            const domainStore = useDomainStore();
            const {success, error} = useToastStore();
            const {domain: activeDomain} = storeToRefs(domainStore);
            const {setError, resetError} = useError();
            const domains = ref<SelectOption[]>([]);
            const domain = ref("");

            onMounted(() => {
                showConnectResult();

                if (activeDomain.value.google_user_id) {
                    loadDomains();
                }
            });

            watch(activeDomain, () => {
                loadDomains();
            });

            function showConnectResult() {
                const showSuccess = getQueryParam("gsc_success");
                const err = getQueryParam("gsc_error");
                setQueryParam("gsc_success");
                setQueryParam("gsc_error");

                if (showSuccess) {
                    success(t("toast.connected"));
                } else if (err) {
                    error({validation: {}, error: [err]});
                }
            }

            async function loadDomains() {
                try {
                    domain.value = "";

                    if (activeDomain.value.gsc_domain) {
                        domain.value = activeDomain.value.gsc_domain;
                    }

                    resetError();
                    domains.value = [];

                    if (activeDomain.value.google_user_id) {
                        const list = await DomainRepo.listGSCDomains(activeDomain.value.id) as string[];

                        for (let i = 0; i < list.length; i++) {
                            domains.value.push({
                                label: trimDomain(list[i]),
                                value: list[i]
                            });
                        }
                    }
                } catch (e) {
                    setError(e as APIError);
                }
            }

            function trimDomain(domain: string) {
                if (domain.startsWith("sc-domain:")) {
                    return domain.substring("sc-domain:".length);
                }

                return domain;
            }

            function signIn() {
                const accessToken = Cookies.get("access_token");
                location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${import.meta.env.VITE_APP_GOOGLE_REDIRECT_URI}&response_type=code&access_type=offline&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/webmasters.readonly&state=${accessToken},${activeDomain.value.id}`;
            }

            async function unlink() {
                try {
                    resetError();
                    await DomainRepo.unlinkGoogleAccount(activeDomain.value.id);
                    await domainStore.load();
                    success(t("toast.unlinked"));
                } catch (e) {
                    setError(e as APIError);
                }
            }

            async function setDomain() {
                try {
                    resetError();
                    await DomainRepo.setGSCDomain(activeDomain.value.id, domain.value);
                    await domainStore.load();
                    success(t("toast.saved"));
                } catch (e) {
                    setError(e as APIError);
                }
            }

            return {
                t,
                activeDomain,
                domains,
                domain,
                signIn,
                unlink,
                setDomain
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Search Console",
            "text": "Connect your website to Google Search Console to view keyword results.",
            "link": "Read the Docs",
            "form.website": "Website",
            "google.continue": "Continue with Google",
            "google.linked": "Linked Google Account",
            "google.unlink": "Unlink Account",
            "toast.connected": "Your account has been connected.",
            "toast.unlinked": "The account has been unlinked.",
            "toast.saved": "The domain has been saved."
        },
        "de": {
            "title": "Search Console",
            "text": "Verbinde deine Webseite mit der Google Search Console, um Keyword-Ergebnisse anzuzeigen.",
            "link": "Dokumentation lesen",
            "form.website": "Webseite",
            "google.continue": "Mit Google fortfahren",
            "google.linked": "Verknüpftes Google-Konto",
            "google.unlink": "Konto trennen",
            "toast.connected": "Dein Konto wurde verbunden.",
            "toast.unlinked": "Das Konto wurde getrennt.",
            "toast.saved": "Die Domain wurde gespeichert."
        },
        "es": {
            "title": "Search Console",
            "text": "Conecta tu sitio web a Google Search Console para ver los resultados de palabras clave.",
            "link": "Leer la documentación",
            "form.website": "Sitio web",
            "google.continue": "Continuar con Google",
            "google.linked": "Cuenta de Google vinculada",
            "google.unlink": "Desvincular cuenta",
            "toast.connected": "Tu cuenta ha sido conectada.",
            "toast.unlinked": "La cuenta ha sido desvinculada.",
            "toast.saved": "El dominio ha sido guardado."
        },
        "fr": {
            "title": "Search Console",
            "text": "Connectez votre site Web à Google Search Console pour afficher les résultats des mots-clés.",
            "link": "Lire la documentation",
            "form.website": "Site web",
            "google.continue": "Continuer avec Google",
            "google.linked": "Compte Google lié",
            "google.unlink": "Dissocier le compte",
            "toast.connected": "Votre compte a été connecté.",
            "toast.unlinked": "Le compte a été dissocié.",
            "toast.saved": "Le domaine a été enregistré."
        },
        "nl": {
            "title": "Search Console",
            "text": "Verbind je website met Google Search Console om zoekwoordresultaten te bekijken.",
            "link": "Lees de documentatie",
            "form.website": "Website",
            "google.continue": "Doorgaan met Google",
            "google.linked": "Gekoppeld Google-account",
            "google.unlink": "Koppel account los",
            "toast.connected": "Je account is verbonden.",
            "toast.unlinked": "Het account is losgekoppeld.",
            "toast.saved": "Het domein is opgeslagen."
        },
        "it": {
            "title": "Search Console",
            "text": "Collega il tuo sito web a Google Search Console per visualizzare i risultati delle parole chiave.",
            "link": "Leggi la documentazione",
            "form.website": "Sito web",
            "google.continue": "Continua con Google",
            "google.linked": "Account Google collegato",
            "google.unlink": "Scollega account",
            "toast.connected": "Il tuo account è stato collegato.",
            "toast.unlinked": "L'account è stato scollegato.",
            "toast.saved": "Il dominio è stato salvato."
        },
        "pt": {
            "title": "Search Console",
            "text": "Conecte seu site ao Google Search Console para visualizar os resultados das palavras-chave.",
            "link": "Leia a documentação",
            "form.website": "Site",
            "google.continue": "Continuar com Google",
            "google.linked": "Conta do Google vinculada",
            "google.unlink": "Desvincular conta",
            "toast.connected": "Sua conta foi conectada.",
            "toast.unlinked": "A conta foi desvinculada.",
            "toast.saved": "O domínio foi salvo."
        },
        "ja": {
            "title": "サーチコンソール",
            "text": "Google サーチコンソールにウェブサイトを接続して、キーワードの結果を表示します。",
            "link": "ドキュメントを読む",
            "form.website": "ウェブサイト",
            "google.continue": "Googleで続行",
            "google.linked": "リンクされた Google アカウント",
            "google.unlink": "アカウントのリンクを解除",
            "toast.connected": "アカウントが接続されました。",
            "toast.unlinked": "アカウントのリンクが解除されました。",
            "toast.saved": "ドメインが保存されました。"
        }
    }
</i18n>
