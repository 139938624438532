<template>
    <SettingsPage active="theme">
        <ThemeBase />
        <hr />
        <ThemeOverwrites />
    </SettingsPage>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import SettingsPage from "@/components/settings/SettingsPage.vue";
    import ThemeBase from "@/components/settings/ThemeBase.vue";
    import ThemeOverwrites from "@/components/settings/ThemeOverwrites.vue";

    export default defineComponent({
        components: {
            ThemeOverwrites,
            ThemeBase,
            SettingsPage
        }
    });
</script>
