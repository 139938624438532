<template>
    <section class="empty" v-if="isPrivate">
        <i class="icon icon-stats"></i>
        <p>{{t("not_found")}}</p>
        <router-link class="button" to="/add" v-if="!user.read_only">{{t("add")}}</router-link>
    </section>
    <section class="empty" v-else>
        <i class="icon icon-lock"></i>
        <p>{{t("permission")}}</p>
    </section>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {isDashboard} from "@/util/domain";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            return {
                ...useI18n(),
                ...storeToRefs(useUserStore()),
                isPrivate: isDashboard()
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "not_found": "There is nothing here yet.",
            "add": "Add Your First Website",
            "permission": "The dashboard you are trying to access was not found or is set to private access only."
        },
        "de": {
            "not_found": "Hier gibt es noch nichts.",
            "add": "Füge deine erste Webseite hinzu",
            "permission": "Das Dashboard, auf das du zugreifen möchtest, wurde nicht gefunden oder ist nur für privaten Zugriff eingestellt."
        },
        "es": {
            "not_found": "Aún no hay nada aquí.",
            "add": "Añade tu primer sitio web",
            "permission": "El panel de control al que intentas acceder no se encontró o está configurado para acceso privado únicamente."
        },
        "fr": {
            "not_found": "Il n'y a encore rien ici.",
            "add": "Ajoutez votre premier site web",
            "permission": "Le tableau de bord auquel vous essayez d'accéder n'a pas été trouvé ou est réglé sur un accès privé uniquement."
        },
        "nl": {
            "not_found": "Er is hier nog niets.",
            "add": "Voeg je eerste website toe",
            "permission": "Het dashboard dat je probeert te openen is niet gevonden of is ingesteld op alleen privétoegang."
        },
        "it": {
            "not_found": "Non c'è ancora niente qui.",
            "add": "Aggiungi il tuo primo sito web",
            "permission": "La dashboard a cui stai tentando di accedere non è stata trovata o è impostata solo per l'accesso privato."
        },
        "pt": {
            "not_found": "Ainda não há nada aqui.",
            "add": "Adicione seu primeiro site",
            "permission": "O painel de controle que você está tentando acessar não foi encontrado ou está configurado para acesso privado apenas."
        },
        "ja": {
            "not_found": "まだ何もありません。",
            "add": "最初のウェブサイトを追加",
            "permission": "アクセスしようとしているダッシュボードが見つからないか、プライベートアクセス専用に設定されています。"
        }
    }
</i18n>
