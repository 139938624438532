<template>
    <div class="card">
        <div class="text">
            <p>
                <strong>{{transfer.hostname}}</strong>
            </p>
        </div>
        <div class="row">
            <i class="icon icon-check clickable" :title="t('accept')" v-on:click="accept"></i>
            <i class="icon icon-close clickable" :title="t('decline')" v-on:click="decline"></i>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {useError} from "@/components/error";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            transfer: {type: Object, required: true}
        },
        emits: ["remove"],
        setup(props, {emit}) {
            const {load} = useDomainStore();
            const {setError} = useError();
            const {error} = useToastStore();

            async function accept() {
                try {
                    await MemberRepo.acceptOwnershipTransfer(props.transfer.id);
                    emit("remove");
                    await load();
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            async function decline() {
                try {
                    await MemberRepo.deleteOwnershipTransfer(props.transfer.id);
                    emit("remove");
                } catch (e) {
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                ...useI18n(),
                accept,
                decline
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "accept": "Accept Transfer",
            "decline": "Decline Transfer"
        },
        "de": {
            "accept": "Übertragung annehmen",
            "decline": "Übertragung ablehnen"
        },
        "es": {
            "accept": "Aceptar transferencia",
            "decline": "Rechazar transferencia"
        },
        "fr": {
            "accept": "Accepter le transfert",
            "decline": "Refuser le transfert"
        },
        "nl": {
            "accept": "Overdracht accepteren",
            "decline": "Overdracht weigeren"
        },
        "it": {
            "accept": "Accetta il trasferimento",
            "decline": "Rifiuta il trasferimento"
        },
        "pt": {
            "accept": "Aceitar transferência",
            "decline": "Recusar transferência"
        },
        "ja": {
            "accept": "転送を受け入れる",
            "decline": "転送を拒否"
        }
    }
</i18n>
