<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>{{t("text")}}</small>
        </div>
        <div>
            <form v-on:submit.prevent="save">
                <FormInput :label="t('form.current')" name="currentPassword" autocomplete="current-password" type="password" v-model="currentPassword" :err="validationError('current_password')" />
                <FormInput :label="t('form.new')" name="password" autocomplete="new-password" type="password" v-model="password" :err="validationError('password')" />
                <FormInput :label="t('form.confirm')" name="passwordRepeat" autocomplete="new-password" type="password" v-model="passwordRepeat" />
                <FormSubmit :value="t('form.submit')" :loading="loading" />
            </form>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSubmit,
            FormInput
        },
        setup() {
            const {t} = useI18n();
            const {logout} = useUserStore();
            const {resetError, setError, validationError} = useError();
            const {success, error} = useToastStore();
            const loading = ref(false);
            const currentPassword = ref("");
            const password = ref("");
            const passwordRepeat = ref("");

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await UserRepo.changePassword(currentPassword.value, password.value, passwordRepeat.value);
                    currentPassword.value = "";
                    password.value = "";
                    passwordRepeat.value = "";
                    loading.value = false;
                    success(t("toast.success"));
                    setTimeout(() => logout(), 3000);
                } catch(e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                validationError,
                loading,
                currentPassword,
                password,
                passwordRepeat,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Password",
            "text": "You will be logged out from all devices after changing your password.",
            "form.current": "Current Password",
            "form.new": "New Password",
            "form.confirm": "Repeat New Password",
            "form.submit": "Change Password",
            "toast.success": "Your password has been updated. You'll be logged out."
        },
        "de": {
            "title": "Passwort",
            "text": "Du wirst nach dem Ändern deines Passworts auf allen Geräten abgemeldet.",
            "form.current": "Aktuelles Passwort",
            "form.new": "Neues Passwort",
            "form.confirm": "Neues Passwort wiederholen",
            "form.submit": "Passwort ändern",
            "toast.success": "Dein Passwort wurde aktualisiert. Du wirst abgemeldet."
        },
        "es": {
            "title": "Contraseña",
            "text": "Se cerrará tu sesión en todos los dispositivos después de cambiar tu contraseña.",
            "form.current": "Contraseña actual",
            "form.new": "Nueva contraseña",
            "form.confirm": "Repetir nueva contraseña",
            "form.submit": "Cambiar contraseña",
            "toast.success": "Tu contraseña ha sido actualizada. Se cerrará tu sesión."
        },
        "fr": {
            "title": "Mot de passe",
            "text": "Vous serez déconnecté de tous les appareils après avoir changé votre mot de passe.",
            "form.current": "Mot de passe actuel",
            "form.new": "Nouveau mot de passe",
            "form.confirm": "Répéter le nouveau mot de passe",
            "form.submit": "Changer le mot de passe",
            "toast.success": "Votre mot de passe a été mis à jour. Vous serez déconnecté."
        },
        "nl": {
            "title": "Wachtwoord",
            "text": "Je wordt uitgelogd op alle apparaten na het wijzigen van je wachtwoord.",
            "form.current": "Huidig wachtwoord",
            "form.new": "Nieuw wachtwoord",
            "form.confirm": "Herhaal nieuw wachtwoord",
            "form.submit": "Wachtwoord wijzigen",
            "toast.success": "Je wachtwoord is bijgewerkt. Je wordt uitgelogd."
        },
        "it": {
            "title": "Password",
            "text": "Verrai disconnesso da tutti i dispositivi dopo aver cambiato la password.",
            "form.current": "Password attuale",
            "form.new": "Nuova password",
            "form.confirm": "Ripeti nuova password",
            "form.submit": "Cambia password",
            "toast.success": "La tua password è stata aggiornata. Verrai disconnesso."
        },
        "pt": {
            "title": "Senha",
            "text": "Você será desconectado de todos os dispositivos após alterar sua senha.",
            "form.current": "Senha atual",
            "form.new": "Nova senha",
            "form.confirm": "Repetir nova senha",
            "form.submit": "Alterar senha",
            "toast.success": "Sua senha foi atualizada. Você será desconectado."
        },
        "ja": {
            "title": "パスワード",
            "text": "パスワードを変更すると、すべてのデバイスからログアウトされます。",
            "form.current": "現在のパスワード",
            "form.new": "新しいパスワード",
            "form.confirm": "新しいパスワードを再入力",
            "form.submit": "パスワードを変更",
            "toast.success": "パスワードが更新されました。ログアウトされます。"
        }
    }
</i18n>
