<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <i18n-t keypath="text" tag="p">
                <strong>{{report.email}}</strong>
            </i18n-t>
            <FormSelect :label="t('form.interval')"
                name="interval"
                v-model="interval"
                :options="intervalOptions" />
            <FormSelect :label="t('form.link_to')"
                name="link_to"
                :hint="t('form.link_to.hint')"
                v-model="linkTo"
                :options="reportOptions" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {ReportRepo} from "@/repositories/ReportRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {EmailReport, emailReportIntervalOptions, emailReportLinkToOptions} from "@/model/EmailReport";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormSelect
        },
        props: {
            report: {type: Object as PropType<EmailReport>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);
            const interval = ref(props.report.interval.toString());
            const linkTo = ref(props.report.link_to ?? "");
            const intervalOptions = computed(() => {
                const options = [];

                for (let i = 0; i < emailReportIntervalOptions.length; i++) {
                    options.push({
                        label: t("options.interval."+emailReportIntervalOptions[i].label),
                        value: emailReportIntervalOptions[i].value
                    });
                }

                return options;
            });
            const reportOptions = computed(() => {
                const options = [];

                for (let i = 0; i < emailReportLinkToOptions.length; i++) {
                    options.push({
                        label: t("options.report."+emailReportLinkToOptions[i].label),
                        value: emailReportLinkToOptions[i].value
                    });
                }

                return options;
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const r = await ReportRepo.update(props.report.id, parseInt(interval.value), linkTo.value);
                    success(t("toast.success"));
                    loading.value = false;
                    emit("action", r);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                intervalOptions,
                reportOptions,
                err,
                loading,
                interval,
                linkTo,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Edit Report",
            "text": "Change the interval and link for {0}.",
            "form.interval": "Interval",
            "form.link_to": "Link To",
            "form.link_to.hint": "Public dashboards and custom domains must be configured. Otherwise, the report will fall back to the private dashboard.",
            "form.submit": "Save",
            "options.interval.Daily": "Daily",
            "options.interval.Weekly": "Weekly",
            "options.interval.Every two weeks": "Every two weeks",
            "options.interval.Every three weeks": "Every three weeks",
            "options.interval.Monthly": "Monthly",
            "options.report.Private Dashboard (Login Required)": "Private Dashboard (Login Required)",
            "options.report.Public Dashboard": "Public Dashboard",
            "options.report.Custom Domain": "Custom Domain",
            "toast.success": "Report updated."
        },
        "de": {
            "title": "Bericht bearbeiten",
            "text": "Ändere das Intervall und den Link für {0}.",
            "form.interval": "Intervall",
            "form.link_to": "Verlinken zu",
            "form.link_to.hint": "Öffentliche Dashboards und benutzerdefinierte Domains müssen konfiguriert werden. Andernfalls wird der Bericht auf das private Dashboard zurückfallen.",
            "form.submit": "Speichern",
            "options.interval.Daily": "Täglich",
            "options.interval.Weekly": "Wöchentlich",
            "options.interval.Every two weeks": "Alle zwei Wochen",
            "options.interval.Every three weeks": "Alle drei Wochen",
            "options.interval.Monthly": "Monatlich",
            "options.report.Private Dashboard (Login Required)": "Privates Dashboard (Anmeldung erforderlich)",
            "options.report.Public Dashboard": "Öffentliches Dashboard",
            "options.report.Custom Domain": "Benutzerdefinierte Domain",
            "toast.success": "Bericht aktualisiert."
        },
        "es": {
            "title": "Editar informe",
            "text": "Cambiar el intervalo y el enlace para {0}.",
            "form.interval": "Intervalo",
            "form.link_to": "Enlace a",
            "form.link_to.hint": "Los paneles públicos y los dominios personalizados deben configurarse. De lo contrario, el informe volverá al panel privado.",
            "form.submit": "Guardar",
            "options.interval.Daily": "Diario",
            "options.interval.Weekly": "Semanal",
            "options.interval.Every two weeks": "Cada dos semanas",
            "options.interval.Every three weeks": "Cada tres semanas",
            "options.interval.Monthly": "Mensual",
            "options.report.Private Dashboard (Login Required)": "Panel privado (Inicio de sesión requerido)",
            "options.report.Public Dashboard": "Panel público",
            "options.report.Custom Domain": "Dominio personalizado",
            "toast.success": "Informe actualizado."
        },
        "fr": {
            "title": "Modifier le rapport",
            "text": "Modifier l'intervalle et le lien pour {0}.",
            "form.interval": "Intervalle",
            "form.link_to": "Lien vers",
            "form.link_to.hint": "Les tableaux de bord publics et les domaines personnalisés doivent être configurés. Sinon, le rapport reviendra au tableau de bord privé.",
            "form.submit": "Enregistrer",
            "options.interval.Daily": "Quotidien",
            "options.interval.Weekly": "Hebdomadaire",
            "options.interval.Every two weeks": "Toutes les deux semaines",
            "options.interval.Every three weeks": "Toutes les trois semaines",
            "options.interval.Monthly": "Mensuel",
            "options.report.Private Dashboard (Login Required)": "Tableau de bord privé (Connexion requise)",
            "options.report.Public Dashboard": "Tableau de bord public",
            "options.report.Custom Domain": "Domaine personnalisé",
            "toast.success": "Rapport mis à jour."
        },
        "nl": {
            "title": "Rapport bewerken",
            "text": "Wijzig het interval en de link voor {0}.",
            "form.interval": "Interval",
            "form.link_to": "Link naar",
            "form.link_to.hint": "Openbare dashboards en aangepaste domeinen moeten worden geconfigureerd. Anders valt het rapport terug op het privé-dashboard.",
            "form.submit": "Opslaan",
            "options.interval.Daily": "Dagelijks",
            "options.interval.Weekly": "Wekelijks",
            "options.interval.Every two weeks": "Elke twee weken",
            "options.interval.Every three weeks": "Elke drie weken",
            "options.interval.Monthly": "Maandelijks",
            "options.report.Private Dashboard (Login Required)": "Privé Dashboard (Inloggen vereist)",
            "options.report.Public Dashboard": "Openbaar Dashboard",
            "options.report.Custom Domain": "Aangepast Domein",
            "toast.success": "Rapport bijgewerkt."
        },
        "it": {
            "title": "Modifica rapporto",
            "text": "Modifica l'intervallo e il collegamento per {0}.",
            "form.interval": "Intervallo",
            "form.link_to": "Collega a",
            "form.link_to.hint": "I dashboard pubblici e i domini personalizzati devono essere configurati. In caso contrario, il rapporto tornerà al dashboard privato.",
            "form.submit": "Salva",
            "options.interval.Daily": "Giornaliero",
            "options.interval.Weekly": "Settimanale",
            "options.interval.Every two weeks": "Ogni due settimane",
            "options.interval.Every three weeks": "Ogni tre settimane",
            "options.interval.Monthly": "Mensile",
            "options.report.Private Dashboard (Login Required)": "Dashboard privato (Accesso richiesto)",
            "options.report.Public Dashboard": "Dashboard pubblico",
            "options.report.Custom Domain": "Dominio personalizzato",
            "toast.success": "Rapporto aggiornato."
        },
        "pt": {
            "title": "Editar Relatório",
            "text": "Alterar o intervalo e o link para {0}.",
            "form.interval": "Intervalo",
            "form.link_to": "Link para",
            "form.link_to.hint": "Os dashboards públicos e os domínios personalizados devem ser configurados. Caso contrário, o relatório retornará ao dashboard privado.",
            "form.submit": "Salvar",
            "options.interval.Daily": "Diário",
            "options.interval.Weekly": "Semanal",
            "options.interval.Every two weeks": "A cada duas semanas",
            "options.interval.Every three weeks": "A cada três semanas",
            "options.interval.Monthly": "Mensal",
            "options.report.Private Dashboard (Login Required)": "Dashboard privado (Login necessário)",
            "options.report.Public Dashboard": "Dashboard público",
            "options.report.Custom Domain": "Domínio personalizado",
            "toast.success": "Relatório atualizado."
        },
        "ja": {
            "title": "レポートの編集",
            "text": "{0} の間隔とリンクを変更します。",
            "form.interval": "間隔",
            "form.link_to": "リンク先",
            "form.link_to.hint": "パブリックダッシュボードとカスタムドメインを設定する必要があります。そうしないと、レポートはプライベートダッシュボードに戻ります。",
            "form.submit": "保存",
            "options.interval.Daily": "毎日",
            "options.interval.Weekly": "毎週",
            "options.interval.Every two weeks": "2週間ごと",
            "options.interval.Every three weeks": "3週間ごと",
            "options.interval.Monthly": "毎月",
            "options.report.Private Dashboard (Login Required)": "プライベートダッシュボード（ログイン必要）",
            "options.report.Public Dashboard": "パブリックダッシュボード",
            "options.report.Custom Domain": "カスタムドメイン",
            "toast.success": "レポートが更新されました。"
        }
    }
</i18n>
