<template>
    <input class="search" type="text" name="search" v-model="search" :placeholder="t('placeholder')" />
    <div class="switch">
        <i :class="{'icon icon-overview': true, active: type === OrganizationOverviewType.PANEL}" :title="t('panels')" v-on:click="switchType(OrganizationOverviewType.PANEL)" />
        <i :class="{'icon icon-list': true, active: type === OrganizationOverviewType.LIST}" :title="t('list')" v-on:click="switchType(OrganizationOverviewType.LIST)" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {storeToRefs} from "pinia";
    import {OrganizationOverviewType, useOrganizationStore} from "@/store/OrganizationStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        emits: ["switch"],
        setup(_, {emit}) {
            const organizationStore = useOrganizationStore();
            const {type} = storeToRefs(organizationStore);
            const search = ref("");

            watch(search, value => {
                organizationStore.setSearch(value);
            });

            function switchType(type: OrganizationOverviewType) {
                organizationStore.set(type);
                emit("switch", type);
            }

            return {
                ...useI18n(),
                OrganizationOverviewType,
                type,
                search,
                switchType
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "placeholder": "Search...",
            "panels": "Panels",
            "list": "List"
        },
        "de": {
            "placeholder": "Suchen...",
            "panels": "Panels",
            "list": "Liste"
        },
        "es": {
            "placeholder": "Buscar...",
            "panels": "Paneles",
            "list": "Lista"
        },
        "fr": {
            "placeholder": "Rechercher...",
            "panels": "Panneaux",
            "list": "Liste"
        },
        "nl": {
            "placeholder": "Zoeken...",
            "panels": "Panelen",
            "list": "Lijst"
        },
        "it": {
            "placeholder": "Cerca...",
            "panels": "Pannelli",
            "list": "Lista"
        },
        "pt": {
            "placeholder": "Buscar...",
            "panels": "Painéis",
            "list": "Lista"
        },
        "ja": {
            "placeholder": "検索...",
            "panels": "パネル",
            "list": "リスト"
        }
    }
</i18n>
