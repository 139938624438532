import {Filter} from "@/model/Filter";

export function updateCache(old: Filter, f: Filter) {
    return f.timeRangeIndex !== old.timeRangeIndex ||
        (f.timeRange === undefined && old.timeRange !== undefined) ||
        (f.timeRange !== undefined && old.timeRange === undefined) ||
        (
            f.timeRange !== undefined &&
            old.timeRange !== undefined && (
                f.timeRange.from.getTime() !== old.timeRange.from.getTime() ||
                f.timeRange.to.getTime() !== old.timeRange.to.getTime() ||
                f.timeRange.start !== old.timeRange.start
            )
        );
}
