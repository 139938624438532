<template>
    <template v-if="active">
        <div class="navbar-tabs no-select">
            <HeaderEntry icon="overview" :title="t('overview')" path="/overview" :active="$route.name === 'Overview'" v-if="loggedIn && isDashboard && !isCustomDomain" />
            <HeaderEntry icon="stats" :title="t('dashboard')" path="/" :active="$route.name === 'Dashboard'" v-if="loggedIn || !$route.meta.noRedirect" />
            <HeaderEntry icon="filter" :title="t('funnel')" path="/funnels" :active="$route.name === 'Funnel'" v-if="(loggedIn || !$route.meta.noRedirect) && getSettings(isPrivate ? 'funnels' : 'funnels_public')" />
            <HeaderEntry icon="compass" :title="t('sessions')" path="/sessions" :active="$route.name === 'Sessions'" v-if="(loggedIn || !$route.meta.noRedirect) && getSettings(isPrivate ? 'sessions' : 'sessions_public')" />
            <HeaderEntry icon="settings" :title="t('settings')" path="/settings" :active="$route.path.startsWith('/settings')" v-if="isAdmin" />
        </div>
        <div id="nav" :class="{'navbar sticky no-select': true, narrow}" v-if="$route.name === 'Dashboard' || $route.name === 'Funnel' || $route.name === 'Sessions' || ($route.name === 'Overview' && !mobile) || ($route.name === 'Organizations' && !mobile)">
            <div class="navbar-side">
                <div class="navbar-menu" v-if="$route.name === 'Dashboard' || $route.name === 'Funnel' || $route.name === 'Sessions'">
                    <Refresh v-if="!mobile" />
                    <FilterSelection />
                    <ViewSelection />
                </div>
            </div>
            <div class="navbar-side">
                <div class="navbar-menu" v-if="$route.name === 'Dashboard' || $route.name === 'Funnel' || $route.name === 'Sessions'">
                    <TimeRangeFilter />
                    <ComparisonFilter />
                </div>
                <div class="navbar-menu" v-else-if="$route.name === 'Overview' && !mobile">
                    <OverviewType />
                </div>
                <div class="navbar-menu" v-else-if="$route.name === 'Organizations' && !mobile">
                    <OrganizationOverviewType />
                </div>
            </div>
        </div>
    </template>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {useSticky} from "@/components/sticky";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import FilterSelection from "@/components/panels/FilterSelection.vue";
    import ViewSelection from "@/components/panels/ViewSelection.vue";
    import TimeRangeFilter from "@/components/panels/TimeRangeFilter.vue";
    import HeaderEntry from "@/components/bits/HeaderEntry.vue";
    import Refresh from "@/components/bits/Refresh.vue";
    import {useUserStore} from "@/store/UserStore";
    import OverviewType from "@/components/domain/OverviewType.vue";
    import {useMobile} from "@/components/mobile";
    import OrganizationOverviewType from "@/components/organizations/OrganizationOverviewType.vue";
    import ComparisonFilter from "@/components/panels/ComparisonFilter.vue";
    import {useI18n} from "vue-i18n";
    import {isCustomDomain, isDashboard} from "@/util/domain";

    export default defineComponent({
        components: {
            ComparisonFilter,
            OrganizationOverviewType,
            OverviewType,
            FilterSelection,
            ViewSelection,
            TimeRangeFilter,
            HeaderEntry,
            Refresh
        },
        setup() {
            const {loggedIn} = storeToRefs(useUserStore());
            const domainStore = useDomainStore();
            const {active, isAdmin} = storeToRefs(domainStore);
            const {getSettings} = domainStore;

            return {
                isCustomDomain: isCustomDomain(),
                isDashboard: isDashboard(),
                isPrivate: isDashboard(),
                ...useI18n(),
                ...useMobile(),
                ...useSticky("nav"),
                getSettings,
                loggedIn,
                active,
                isAdmin
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "overview": "Overview",
            "dashboard": "Dashboard",
            "funnel": "Funnels",
            "sessions": "Sessions",
            "settings": "Settings"
        },
        "de": {
            "overview": "Übersicht",
            "dashboard": "Dashboard",
            "funnel": "Funnel",
            "sessions": "Sitzungen",
            "settings": "Einstellungen"
        },
        "es": {
            "overview": "Visión general",
            "dashboard": "Panel de control",
            "funnel": "Embudos",
            "sessions": "Sesiones",
            "settings": "Configuraciones"
        },
        "fr": {
            "overview": "Aperçu",
            "dashboard": "Tableau de bord",
            "funnel": "Entonnoirs",
            "sessions": "Sessions",
            "settings": "Paramètres"
        },
        "nl": {
            "overview": "Overzicht",
            "dashboard": "Dashboard",
            "funnel": "Trechters",
            "sessions": "Sessies",
            "settings": "Instellingen"
        },
        "it": {
            "overview": "Panoramica",
            "dashboard": "Cruscotto",
            "funnel": "Imbuti",
            "sessions": "Sessioni",
            "settings": "Impostazioni"
        },
        "pt": {
            "overview": "Visão geral",
            "dashboard": "Painel de controle",
            "funnel": "Funis",
            "sessions": "Sessões",
            "settings": "Configurações"
        },
        "ja": {
            "overview": "概要",
            "dashboard": "ダッシュボード",
            "funnel": "ファネル",
            "sessions": "セッション",
            "settings": "設定"
        }
    }
</i18n>
