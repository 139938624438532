<template>
    <section class="invitations" v-if="isDashboard && transfers && transfers.length">
        <h2>{{t("title")}}</h2>
        <p>{{t("text")}}</p>
        <OwnershipTransferCard v-for="transfer in transfers"
            :key="transfer.id"
            :transfer="transfer"
            v-on:remove="removeTransfer(transfer)" />
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {useList} from "@/components/list";
    import {isDashboard} from "@/util/domain";
    import {Domain} from "@/model/Domain";
    import {MemberRepo} from "@/repositories/MemberRepo";
    import OwnershipTransferCard from "@/components/cards/OwnershipTransferCard.vue";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            OwnershipTransferCard
        },
        setup() {
            const {removeEntryByID} = useList();
            const {loggedIn} = storeToRefs(useUserStore());
            const transfers = ref<Domain[]>([]);

            watchEffect(async () => {
                if (loggedIn.value) {
                    transfers.value = await MemberRepo.listOwnershipTransfers() as Domain[];
                }
            });

            function removeTransfer(transfer: Domain) {
                removeEntryByID(transfers.value, transfer);
            }

            return {
                ...useI18n(),
                isDashboard: isDashboard(),
                transfers,
                removeTransfer
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Ownership Transfer",
            "text": "You have received a request as the new owner of a domain."
        },
        "de": {
            "title": "Eigentumsübertragung",
            "text": "Du hast eine Anfrage als neuer Eigentümer einer Domain erhalten."
        },
        "es": {
            "title": "Transferencia de propiedad",
            "text": "Has recibido una solicitud como el nuevo propietario de un dominio."
        },
        "fr": {
            "title": "Transfert de propriété",
            "text": "Vous avez reçu une demande en tant que nouveau propriétaire d'un domaine."
        },
        "nl": {
            "title": "Eigendomsoverdracht",
            "text": "Je hebt een verzoek ontvangen als nieuwe eigenaar van een domein."
        },
        "it": {
            "title": "Trasferimento di proprietà",
            "text": "Hai ricevuto una richiesta come nuovo proprietario di un dominio."
        },
        "pt": {
            "title": "Transferência de propriedade",
            "text": "Você recebeu uma solicitação como o novo proprietário de um domínio."
        },
        "ja": {
            "title": "所有権の移転",
            "text": "ドメインの新しい所有者としてリクエストを受け取りました。"
        }
    }
</i18n>
