export function prefersDarkMode(): boolean {
    return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
}

export function isDarkMode(): boolean {
    const html = document.getElementsByTagName("html");

    if (html.length === 1) {
        return html[0].classList.contains("theme-dark");
    }

    return true;
}

export function setFullWidth(set: boolean) {
    const html = document.getElementsByTagName("html");

    if (set) {
        html[0].classList.add("wide");
    } else {
        html[0].classList.remove("wide");
    }
}
