<template>
    <Modal :title="client ? t('title.edit') : t('title.add')" :err="err" v-on:close="$emit('close')">
        <form v-on:submit.prevent="save">
            <FormSelect :label="t('form.type')" name="type" v-model="type" :options="clientTypeOptions" :disabled="!!client" :err="validationError('type')" />
            <FormInput :label="t('form.description')" name="description" :autofocus="true" v-model="description" :err="validationError('description')" />
            <div class="table" style="margin-bottom: 12px;">
                <table>
                    <thead>
                        <tr>
                            <td style="text-align: left;">
                                <h5>{{t("table.scope")}}</h5>
                            </td>
                            <td style="text-align: right;">
                                <h5>{{t("table.read")}}</h5>
                            </td>
                            <td style="text-align: right;">
                                <h5>{{t("table.write")}}</h5>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="cell-content">
                                    {{t("scope.page_views")}}
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <FormCheckbox name="scopeHits" v-model="scopeHit" :disabled="type === 'token'" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="cell-content">
                                    {{t("scope.events")}}
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <FormCheckbox name="scopeEvent" v-model="scopeEvent" :disabled="type === 'token'" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="cell-content">
                                    {{t("scope.sessions")}}
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <FormCheckbox name="scopeSession" v-model="scopeSession" :disabled="type === 'token'" />
                            </td>
                        </tr>
                        <tr v-show="type === 'oauth'">
                            <td>
                                <div class="cell-content">
                                    {{t("scope.statistics")}}
                                </div>
                            </td>
                            <td>
                                <FormCheckbox name="scopeStatistics" v-model="scopeStatistics" />
                            </td>
                            <td></td>
                        </tr>
                        <tr v-show="type === 'oauth'">
                            <td>
                                <div class="cell-content">
                                    {{forDomain ? t('scope.domain.settings') : t('scope.domain.domains')}}
                                </div>
                            </td>
                            <td>
                                <FormCheckbox name="scopeReadDomains" v-model="scopeReadDomains" />
                            </td>
                            <td>
                                <FormCheckbox name="scopeWriteDomains" v-model="scopeWriteDomains" />
                            </td>
                        </tr>
                        <tr v-show="type === 'oauth' && !forDomain">
                            <td>
                                <div class="cell-content">
                                    {{t("scope.organizations")}}
                                </div>
                            </td>
                            <td>
                                <FormCheckbox name="scopeReadOrganizations" v-model="scopeReadOrganizations" />
                            </td>
                            <td>
                                <FormCheckbox name="scopeWriteOrganizations" v-model="scopeWriteOrganizations" />
                            </td>
                        </tr>
                        <tr v-show="type === 'oauth' && !forDomain">
                            <td>
                                <div class="cell-content">
                                    {{t("scope.themes")}}
                                </div>
                            </td>
                            <td>
                                <FormCheckbox name="scopeReadThemes" v-model="scopeReadThemes" />
                            </td>
                            <td>
                                <FormCheckbox name="scopeWriteThemes" v-model="scopeWriteThemes" />
                            </td>
                        </tr>
                        <tr v-show="type === 'oauth' && !forDomain">
                            <td>
                                <div class="cell-content">
                                    {{t("scope.billing")}}
                                </div>
                            </td>
                            <td>
                                <FormCheckbox name="scopeBilling" v-model="scopeBilling" />
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <FormSubmit :value="client ? t('form.submit.save') : t('form.submit.create')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref, watch, watchEffect} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSelect from "@/components/form/FormSelect.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {useError} from "@/components/error";
    import {ClientRepo} from "@/repositories/ClientRepo";
    import {Client} from "@/model/Client";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSelect,
            FormSubmit,
            FormInput,
            FormCheckbox
        },
        props: {
            forDomain: {type: Boolean, default: true},
            client: Object as PropType<Client>
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, validationError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const clientTypeOptions = computed(() => [
                {label: t("options.type.token"), value: "token"},
                {label: t("options.type.oauth"), value: "oauth"}
            ]);
            const loading = ref(false);
            const type = ref("oauth");
            const description = ref("");
            const scopeHit = ref(true);
            const scopeEvent = ref(true);
            const scopeSession = ref(true);
            const scopeStatistics = ref(true);
            const scopeReadDomains = ref(true);
            const scopeWriteDomains = ref(true);
            const scopeReadOrganizations = ref(true);
            const scopeWriteOrganizations = ref(true);
            const scopeReadThemes = ref(true);
            const scopeWriteThemes = ref(true);
            const scopeBilling = ref(true);

            watchEffect(() => {
                if (props.client) {
                    if (!props.client.client_id) {
                        type.value = "token";
                    }

                    description.value = props.client.description;
                    scopeHit.value = props.client.scope_hit === "w";
                    scopeEvent.value = props.client.scope_event === "w";
                    scopeSession.value = props.client.scope_session === "w";
                    scopeStatistics.value = props.client.scope_statistics === "r";
                    scopeReadDomains.value = props.client.scope_domains === "r" || props.client.scope_domains === "w";
                    scopeWriteDomains.value = props.client.scope_domains === "w";
                    scopeReadOrganizations.value = props.client.scope_organizations === "r" || props.client.scope_organizations === "w";
                    scopeWriteOrganizations.value = props.client.scope_organizations === "w";
                    scopeReadThemes.value = props.client.scope_themes === "r" || props.client.scope_themes === "w";
                    scopeWriteThemes.value = props.client.scope_themes === "w";
                    scopeBilling.value = props.client.scope_billing === "r";
                }
            });

            watch(type, type => {
                if (type === 'token') {
                    scopeHit.value = true;
                    scopeEvent.value = true;
                    scopeSession.value = true;
                }
            });

            watch(scopeReadDomains, read => {
                if (!read) {
                    scopeWriteDomains.value = false;
                }
            });

            watch(scopeWriteDomains, write => {
                if (write) {
                    scopeReadDomains.value = true;
                }
            });

            watch(scopeReadOrganizations, read => {
                if (!read) {
                    scopeWriteOrganizations.value = false;
                }
            });

            watch(scopeWriteOrganizations, write => {
                if (write) {
                    scopeReadOrganizations.value = true;
                }
            });

            watch(scopeReadThemes, read => {
                if (!read) {
                    scopeWriteThemes.value = false;
                }
            });

            watch(scopeWriteThemes, write => {
                if (write) {
                    scopeReadThemes.value = true;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const client = await ClientRepo.createUpdate(props.client ? props.client.id : "",
                        props.forDomain ? domain.value.id : "",
                        type.value,
                        description.value,
                        scopeHit.value ? "w" : "",
                        scopeEvent.value ? "w" : "",
                        scopeSession.value ? "w" : "",
                        scopeStatistics.value ? "r" : "",
                        scopeWriteDomains.value ? "w" : scopeReadDomains.value ? "r" : "",
                        props.forDomain ? "" : scopeWriteOrganizations.value ? "w" : scopeReadOrganizations.value ? "r" : "",
                        props.forDomain ? "" : scopeWriteThemes.value ? "w" : scopeReadThemes.value ? "r" : "",
                        props.forDomain ? "" : scopeBilling.value ? "r" : "") as Client;

                    if (!props.client) {
                        success(t("toast.created"));
                    } else {
                        success(t("toast.updated"));
                    }

                    type.value = "oauth";
                    description.value = "";
                    scopeHit.value = true;
                    scopeEvent.value = true;
                    scopeSession.value = true;
                    scopeStatistics.value = true;
                    scopeReadDomains.value = true;
                    scopeWriteDomains.value = true;
                    scopeReadOrganizations.value = true;
                    scopeWriteOrganizations.value = true;
                    scopeReadThemes.value = true;
                    scopeWriteThemes.value = true;
                    scopeBilling.value = true;
                    loading.value = false;
                    emit("action", client);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                clientTypeOptions,
                validationError,
                err,
                loading,
                type,
                description,
                scopeHit,
                scopeEvent,
                scopeSession,
                scopeStatistics,
                scopeReadDomains,
                scopeWriteDomains,
                scopeReadOrganizations,
                scopeWriteOrganizations,
                scopeReadThemes,
                scopeWriteThemes,
                scopeBilling,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title.edit": "Edit Client",
            "title.add": "Add Client",
            "form.type": "Type",
            "form.description": "Description",
            "table.scope": "Scope",
            "table.read": "Read",
            "table.write": "Write",
            "scope.page_views": "Send Page Views",
            "scope.events": "Send Events",
            "scope.sessions": "Keep Sessions Alive",
            "scope.statistics": "Statistics",
            "scope.domain.settings": "Manage Domain Settings",
            "scope.domain.domains": "Manage Domains",
            "scope.organizations": "Manage Organizations",
            "scope.themes": "Manage Themes",
            "scope.billing": "Billing",
            "form.submit.save": "Save",
            "form.submit.create": "Create Client",
            "options.type.token": "Access Key (Write-only)",
            "options.type.oauth": "oAuth (ID + Secret)",
            "toast.created": "The client has been created.",
            "toast.updated": "The client has been updated."
        },
        "de": {
            "title.edit": "Client bearbeiten",
            "title.add": "Client hinzufügen",
            "form.type": "Typ",
            "form.description": "Beschreibung",
            "table.scope": "Bereich",
            "table.read": "Lesen",
            "table.write": "Schreiben",
            "scope.page_views": "Seitenaufrufe senden",
            "scope.events": "Events senden",
            "scope.sessions": "Sitzungen am Leben halten",
            "scope.statistics": "Statistiken",
            "scope.domain.settings": "Domain-Einstellungen verwalten",
            "scope.domain.domains": "Domains verwalten",
            "scope.organizations": "Organisationen verwalten",
            "scope.themes": "Themen verwalten",
            "scope.billing": "Abrechnung",
            "form.submit.save": "Speichern",
            "form.submit.create": "Client erstellen",
            "options.type.token": "Zugangsschlüssel (nur Schreiben)",
            "options.type.oauth": "oAuth (ID + Geheimnis)",
            "toast.created": "Der Client wurde erstellt.",
            "toast.updated": "Der Client wurde aktualisiert."
        },
        "es": {
            "title.edit": "Editar cliente",
            "title.add": "Agregar cliente",
            "form.type": "Tipo",
            "form.description": "Descripción",
            "table.scope": "Alcance",
            "table.read": "Leer",
            "table.write": "Escribir",
            "scope.page_views": "Enviar vistas de página",
            "scope.events": "Enviar eventos",
            "scope.sessions": "Mantener sesiones vivas",
            "scope.statistics": "Estadísticas",
            "scope.domain.settings": "Gestionar configuración de dominio",
            "scope.domain.domains": "Gestionar dominios",
            "scope.organizations": "Gestionar organizaciones",
            "scope.themes": "Gestionar temas",
            "scope.billing": "Facturación",
            "form.submit.save": "Guardar",
            "form.submit.create": "Crear cliente",
            "options.type.token": "Clave de acceso (solo escritura)",
            "options.type.oauth": "oAuth (ID + Secreto)",
            "toast.created": "El cliente ha sido creado.",
            "toast.updated": "El cliente ha sido actualizado."
        },
        "fr": {
            "title.edit": "Modifier le client",
            "title.add": "Ajouter un client",
            "form.type": "Type",
            "form.description": "Description",
            "table.scope": "Portée",
            "table.read": "Lire",
            "table.write": "Écrire",
            "scope.page_views": "Envoyer les pages vues",
            "scope.events": "Envoyer des événements",
            "scope.sessions": "Garder les sessions actives",
            "scope.statistics": "Statistiques",
            "scope.domain.settings": "Gérer les paramètres de domaine",
            "scope.domain.domains": "Gérer les domaines",
            "scope.organizations": "Gérer les organisations",
            "scope.themes": "Gérer les thèmes",
            "scope.billing": "Facturation",
            "form.submit.save": "Enregistrer",
            "form.submit.create": "Créer un client",
            "options.type.token": "Clé d'accès (écriture uniquement)",
            "options.type.oauth": "oAuth (ID + Secret)",
            "toast.created": "Le client a été créé.",
            "toast.updated": "Le client a été mis à jour."
        },
        "nl": {
            "title.edit": "Client bewerken",
            "title.add": "Client toevoegen",
            "form.type": "Type",
            "form.description": "Beschrijving",
            "table.scope": "Reikwijdte",
            "table.read": "Lezen",
            "table.write": "Schrijven",
            "scope.page_views": "Paginaweergaven verzenden",
            "scope.events": "Gebeurtenissen verzenden",
            "scope.sessions": "Sessies in leven houden",
            "scope.statistics": "Statistieken",
            "scope.domain.settings": "Domeininstellingen beheren",
            "scope.domain.domains": "Domeinen beheren",
            "scope.organizations": "Organisaties beheren",
            "scope.themes": "Thema's beheren",
            "scope.billing": "Facturering",
            "form.submit.save": "Opslaan",
            "form.submit.create": "Client maken",
            "options.type.token": "Toegangssleutel (alleen schrijven)",
            "options.type.oauth": "oAuth (ID + Geheim)",
            "toast.created": "De client is aangemaakt.",
            "toast.updated": "De client is bijgewerkt."
        },
        "it": {
            "title.edit": "Modifica cliente",
            "title.add": "Aggiungi cliente",
            "form.type": "Tipo",
            "form.description": "Descrizione",
            "table.scope": "Ambito",
            "table.read": "Leggi",
            "table.write": "Scrivi",
            "scope.page_views": "Invia visualizzazioni di pagina",
            "scope.events": "Invia eventi",
            "scope.sessions": "Mantieni attive le sessioni",
            "scope.statistics": "Statistiche",
            "scope.domain.settings": "Gestisci impostazioni del dominio",
            "scope.domain.domains": "Gestisci domini",
            "scope.organizations": "Gestisci organizzazioni",
            "scope.themes": "Gestisci temi",
            "scope.billing": "Fatturazione",
            "form.submit.save": "Salva",
            "form.submit.create": "Crea cliente",
            "options.type.token": "Chiave di accesso (solo scrittura)",
            "options.type.oauth": "oAuth (ID + Segreto)",
            "toast.created": "Il cliente è stato creato.",
            "toast.updated": "Il cliente è stato aggiornato."
        },
        "pt": {
            "title.edit": "Editar cliente",
            "title.add": "Adicionar cliente",
            "form.type": "Tipo",
            "form.description": "Descrição",
            "table.scope": "Escopo",
            "table.read": "Ler",
            "table.write": "Escrever",
            "scope.page_views": "Enviar visualizações de página",
            "scope.events": "Enviar eventos",
            "scope.sessions": "Manter sessões ativas",
            "scope.statistics": "Estatísticas",
            "scope.domain.settings": "Gerenciar configurações de domínio",
            "scope.domain.domains": "Gerenciar domínios",
            "scope.organizations": "Gerenciar organizações",
            "scope.themes": "Gerenciar temas",
            "scope.billing": "Faturamento",
            "form.submit.save": "Salvar",
            "form.submit.create": "Criar cliente",
            "options.type.token": "Chave de acesso (somente escrita)",
            "options.type.oauth": "oAuth (ID + Secreto)",
            "toast.created": "O cliente foi criado.",
            "toast.updated": "O cliente foi atualizado."
        },
        "ja": {
            "title.edit": "クライアントを編集",
            "title.add": "クライアントを追加",
            "form.type": "タイプ",
            "form.description": "説明",
            "table.scope": "範囲",
            "table.read": "読み取り",
            "table.write": "書き込み",
            "scope.page_views": "ページビューを送信",
            "scope.events": "イベントを送信",
            "scope.sessions": "セッションを維持",
            "scope.statistics": "統計",
            "scope.domain.settings": "ドメイン設定を管理",
            "scope.domain.domains": "ドメインを管理",
            "scope.organizations": "組織を管理",
            "scope.themes": "テーマを管理",
            "scope.billing": "請求",
            "form.submit.save": "保存",
            "form.submit.create": "クライアントを作成",
            "options.type.token": "アクセスキー（書き込み専用）",
            "options.type.oauth": "oAuth（ID + シークレット）",
            "toast.created": "クライアントが作成されました。",
            "toast.updated": "クライアントが更新されました。"
        }
    }
</i18n>
