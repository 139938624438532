<template>
    <TryTheme :open="tryThemeModal" v-on:close="tryThemeModal = false" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/theme" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <form v-on:submit.prevent="save" style="width: 100%;" v-if="hasPlus">
            <ThemeOptions :err="err"
                :defaults="defaultSettings"
                v-model="settings"
                v-on:logo-light="updateLogoLight"
                v-on:logo-dark="updateLogoDark"
                v-on:favicon="updateFavicon"
                v-on:delete-img="deleteImg" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
        <PirschPlus>
            <strong>{{t("plus_0")}}</strong>
            <strong>{{t("plus_1")}}</strong>
            <button v-on:click="tryThemeModal = true" style="margin-bottom: -4px;">{{t("temporary_theme")}}</button>
        </PirschPlus>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, toRaw, watch} from "vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import ThemeOptions from "@/components/themes/ThemeOptions.vue";
    import {ThemeRepo} from "@/repositories/ThemeRepo";
    import {KeyValue} from "@/model/KeyValue";
    import {Domain} from "@/model/Domain";
    import {useThemeStore} from "@/store/ThemeStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import PirschPlus from "@/components/bits/PirschPlus.vue";
    import TryTheme from "@/components/modal/TryTheme.vue";
    import {useI18n} from "vue-i18n";
    import {themeDefaults, ThemeSettings} from "@/store/theme";

    export default defineComponent({
        components: {
            TryTheme,
            PirschPlus,
            ThemeOptions,
            FormSubmit
        },
        setup() {
            const {t} = useI18n();
            const themeStore = useThemeStore();
            const domainStore = useDomainStore();
            const {hasPlus} = storeToRefs(useUserStore());
            const {setError, resetError, validationError, err} = useError();
            const {success, error} = useToastStore();
            const {themes} = storeToRefs(themeStore);
            const tryThemeModal = ref(false);
            const loading = ref(false);
            const settings = ref<KeyValue>({});
            const logoLight = ref<File | undefined>(undefined);
            const logoDark = ref<File | undefined>(undefined);
            const favicon = ref<File | undefined>(undefined);
            const defaultSettings = computed(() => {
                if (domainStore.domain.theme_id) {
                    const theme = themes.value.find(theme => theme.id === domainStore.domain.theme_id);

                    if (theme) {
                        const settings = themeDefaults;

                        for (const [key, value] of Object.entries(theme.settings || {})) {
                            if (value) {
                                settings[key] = value as string;
                            }
                        }

                        return settings;
                    }
                }

                return themeDefaults;
            });

            onMounted(set);
            watch(() => domainStore.domain, set);

            function set() {
                settings.value = structuredClone(toRaw(domainStore.domain.theme_settings));
            }

            async function save() {
                try {
                    loading.value = true;
                    const logoLightFile = logoLight.value ? logoLight.value : undefined;
                    const logoDarkFile = logoDark.value ? logoDark.value : undefined;
                    const faviconFile = favicon.value ? favicon.value : undefined;
                    resetError();
                    const d = await DomainRepo.updateTheme(domainStore.domain.id, settings.value ?? {}, logoLightFile, logoDarkFile, faviconFile) as Domain;
                    loading.value = false;
                    settings.value = d.theme;
                    themeStore.setTheme(d);
                    await domainStore.load();
                    success(t("toast.saved"));
                } catch(e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            function updateLogoLight(file: File) {
                logoLight.value = file;
            }

            function updateLogoDark(file: File) {
                logoDark.value = file;
            }

            function updateFavicon(file: File) {
                favicon.value = file;
            }

            async function deleteImg(name: string) {
                try {
                    let file = "";

                    if (name === "logo_light_file") {
                        file = ThemeSettings.logoLight;
                    } else if (name === "logo_dark_file") {
                        file = ThemeSettings.logoDark;
                    } else if (name === "favicon_file") {
                        file = ThemeSettings.favicon;
                    }

                    await ThemeRepo.deleteFile(domainStore.domain.id, "", file);
                    await domainStore.load();
                    settings.value = domainStore.domain.theme_settings;
                    themeStore.setTheme(domainStore.domain);
                    success(t("toast.deleted"));
                } catch(e) {
                    error(e as APIError);
                }
            }

            return {
                t,
                hasPlus,
                defaultSettings,
                validationError,
                err,
                tryThemeModal,
                loading,
                settings,
                save,
                updateLogoLight,
                updateLogoDark,
                updateFavicon,
                deleteImg
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Theme Overwrites",
            "text": "Customize your dashboard. The values set here will overwrite the base theme.",
            "link": "Read the Docs",
            "form.submit": "Apply",
            "plus_0": "Managing themes requires a Pirsch Plus subscription.",
            "plus_1": "You can give them a quick spin by temporarily applying a theme. It will be used until you reload the page.",
            "temporary_theme": "Create Temporary Theme",
            "toast.saved": "Theme saved.",
            "toast.deleted": "Image deleted."
        },
        "de": {
            "title": "Thema-Überschreibungen",
            "text": "Passe dein Dashboard an. Die hier festgelegten Werte überschreiben das Basis-Thema.",
            "link": "Dokumentation lesen",
            "form.submit": "Anwenden",
            "plus_0": "Die Verwaltung von Themen erfordert ein Pirsch Plus-Abonnement.",
            "plus_1": "Du kannst sie kurz testen, indem du ein Thema vorübergehend anwendest. Es wird verwendet, bis du die Seite neu lädst.",
            "temporary_theme": "Vorübergehendes Thema erstellen",
            "toast.saved": "Thema gespeichert.",
            "toast.deleted": "Bild gelöscht."
        },
        "es": {
            "title": "Sobrescribir Tema",
            "text": "Personaliza tu tablero. Los valores establecidos aquí sobrescribirán el tema base.",
            "link": "Leer la documentación",
            "form.submit": "Aplicar",
            "plus_0": "La gestión de temas requiere una suscripción a Pirsch Plus.",
            "plus_1": "Puedes probarlos rápidamente aplicando un tema temporalmente. Se usará hasta que recargues la página.",
            "temporary_theme": "Crear Tema Temporal",
            "toast.saved": "Tema guardado.",
            "toast.deleted": "Imagen eliminada."
        },
        "fr": {
            "title": "Surcharges de thème",
            "text": "Personnalisez votre tableau de bord. Les valeurs définies ici remplaceront le thème de base.",
            "link": "Lire la documentation",
            "form.submit": "Appliquer",
            "plus_0": "La gestion des thèmes nécessite un abonnement Pirsch Plus.",
            "plus_1": "Vous pouvez les essayer rapidement en appliquant temporairement un thème. Il sera utilisé jusqu'à ce que vous rechargiez la page.",
            "temporary_theme": "Créer un thème temporaire",
            "toast.saved": "Thème enregistré.",
            "toast.deleted": "Image supprimée."
        },
        "nl": {
            "title": "Thema-overschrijvingen",
            "text": "Pas je dashboard aan. De hier ingestelde waarden overschrijven het basisthema.",
            "link": "Lees de documentatie",
            "form.submit": "Toepassen",
            "plus_0": "Het beheren van thema's vereist een Pirsch Plus-abonnement.",
            "plus_1": "Je kunt ze snel proberen door tijdelijk een thema toe te passen. Het wordt gebruikt totdat je de pagina opnieuw laadt.",
            "temporary_theme": "Tijdelijk thema maken",
            "toast.saved": "Thema opgeslagen.",
            "toast.deleted": "Afbeelding verwijderd."
        },
        "it": {
            "title": "Sovrascrittura del Tema",
            "text": "Personalizza il tuo cruscotto. I valori impostati qui sovrascriveranno il tema di base.",
            "link": "Leggi la documentazione",
            "form.submit": "Applica",
            "plus_0": "La gestione dei temi richiede un abbonamento a Pirsch Plus.",
            "plus_1": "Puoi provarli rapidamente applicando temporaneamente un tema. Sarà utilizzato fino a quando non ricarichi la pagina.",
            "temporary_theme": "Crea Tema Temporaneo",
            "toast.saved": "Tema salvato.",
            "toast.deleted": "Immagine eliminata."
        },
        "pt": {
            "title": "Sobrescrever Tema",
            "text": "Personalize seu painel. Os valores definidos aqui substituirão o tema base.",
            "link": "Leia a documentação",
            "form.submit": "Aplicar",
            "plus_0": "Gerenciar temas requer uma assinatura Pirsch Plus.",
            "plus_1": "Você pode experimentá-los rapidamente aplicando um tema temporariamente. Ele será usado até que você recarregue a página.",
            "temporary_theme": "Criar Tema Temporário",
            "toast.saved": "Tema salvo.",
            "toast.deleted": "Imagem excluída."
        },
        "ja": {
            "title": "テーマ上書き",
            "text": "ダッシュボードをカスタマイズします。ここで設定された値は、ベーステーマを上書きします。",
            "link": "ドキュメントを読む",
            "form.submit": "適用",
            "plus_0": "テーマの管理には Pirsch Plus サブスクリプションが必要です。",
            "plus_1": "テーマを一時的に適用して、すぐに試すことができます。ページをリロードするまで使用されます。",
            "temporary_theme": "一時的なテーマを作成",
            "toast.saved": "テーマが保存されました。",
            "toast.deleted": "画像が削除されました。"
        }
    }
</i18n>
