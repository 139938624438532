<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.hostname')"
                name="hostname"
                :hint="t('form.add.hint', {address: '@company.com'})"
                :autofocus="true"
                v-model="hostname"
                v-on:keydown.enter="addHostname" />
            <i class="icon icon-add clickable" :title="t('form.add')" v-on:click="addHostname"></i>
        </div>
        <div style="min-height: 12px;">
            <div class="item" v-for="e in hostnames" :key="e">
                <p>
                    <strong>{{e}}</strong>
                </p>
                <i class="icon icon-close clickable" v-on:click="removeHostname(e)"></i>
            </div>
        </div>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" :disabled="hostnames.length === 0" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {Organization} from "@/model/Organization";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);
            const hostname = ref("");
            const hostnames = ref<string[]>([]);

            function addHostname() {
                hostname.value = hostname.value.trim().toLowerCase();

                if (hostname.value) {
                    for (let i = 0; i < hostnames.value.length; i++) {
                        if (hostnames.value[i] === hostname.value) {
                            hostname.value = "";
                            return;
                        }
                    }

                    hostnames.value.push(hostname.value);
                    hostname.value = "";
                }
            }

            function removeHostname(hostname: string) {
                for (let i = 0; i < hostnames.value.length; i++) {
                    if (hostnames.value[i] === hostname) {
                        hostnames.value.splice(i, 1);
                        break;
                    }
                }
            }

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await OrganizationRepo.addAutoJoin(props.organization.id, hostnames.value);
                    success(t("toast.success"));
                    emit("action", hostnames.value);
                    hostname.value = "";
                    hostnames.value = [];
                    loading.value = false;
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                hostname,
                hostnames,
                addHostname,
                removeHostname,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Add Hostnames",
            "text": "Automatically allow users to join this organization based on their email address on sign up. Click '+' or press ENTER after each one to add them to the list of hostnames.",
            "form.hostname": "Hostname",
            "form.add": "Add Hostname",
            "form.add.hint": "Enter company.com to automatically join anyone with an {address} email address.",
            "form.submit": "Add Hostnames",
            "toast.success": "The hostnames have been added."
        },
        "de": {
            "title": "Hostnamen hinzufügen",
            "text": "Erlaube Nutzern automatisch, dieser Organisation basierend auf ihrer E-Mail-Adresse bei der Anmeldung beizutreten. Klicke auf '+' oder drücke ENTER nach jedem, um sie der Liste der Hostnamen hinzuzufügen.",
            "form.hostname": "Hostname",
            "form.add": "Hostname hinzufügen",
            "form.add.hint": "Gib company.com ein, um automatisch jeden mit einer {address} E-Mail-Adresse hinzuzufügen.",
            "form.submit": "Hostnamen hinzufügen",
            "toast.success": "Die Hostnamen wurden hinzugefügt."
        },
        "es": {
            "title": "Agregar nombres de host",
            "text": "Permita automáticamente que los usuarios se unan a esta organización en función de su dirección de correo electrónico al registrarse. Haga clic en '+' o presione ENTER después de cada uno para agregarlos a la lista de nombres de host.",
            "form.hostname": "Nombre de host",
            "form.add": "Agregar nombre de host",
            "form.add.hint": "Ingrese company.com para unirse automáticamente a cualquier persona con una dirección de correo electrónico {address}.",
            "form.submit": "Agregar nombres de host",
            "toast.success": "Los nombres de host se han agregado."
        },
        "fr": {
            "title": "Ajouter des noms d'hôte",
            "text": "Autorisez automatiquement les utilisateurs à rejoindre cette organisation en fonction de leur adresse e-mail lors de leur inscription. Cliquez sur '+' ou appuyez sur ENTER après chacun pour les ajouter à la liste des noms d'hôte.",
            "form.hostname": "Nom d'hôte",
            "form.add": "Ajouter un nom d'hôte",
            "form.add.hint": "Entrez company.com pour rejoindre automatiquement toute personne ayant une adresse e-mail {address}.",
            "form.submit": "Ajouter des noms d'hôte",
            "toast.success": "Les noms d'hôte ont été ajoutés."
        },
        "nl": {
            "title": "Hostnamen toevoegen",
            "text": "Sta gebruikers automatisch toe om lid te worden van deze organisatie op basis van hun e-mailadres bij aanmelding. Klik op '+' of druk op ENTER na elke om ze toe te voegen aan de lijst met hostnamen.",
            "form.hostname": "Hostnaam",
            "form.add": "Hostnaam toevoegen",
            "form.add.hint": "Voer company.com in om automatisch iedereen met een {address} e-mailadres toe te voegen.",
            "form.submit": "Hostnamen toevoegen",
            "toast.success": "De hostnamen zijn toegevoegd."
        },
        "it": {
            "title": "Aggiungi nomi host",
            "text": "Consenti automaticamente agli utenti di unirsi a questa organizzazione in base al loro indirizzo email al momento dell'iscrizione. Fai clic su '+' o premi ENTER dopo ciascuno per aggiungerli all'elenco dei nomi host.",
            "form.hostname": "Nome host",
            "form.add": "Aggiungi nome host",
            "form.add.hint": "Inserisci company.com per aggiungere automaticamente chiunque abbia un indirizzo email {address}.",
            "form.submit": "Aggiungi nomi host",
            "toast.success": "I nomi host sono stati aggiunti."
        },
        "pt": {
            "title": "Adicionar nomes de host",
            "text": "Permita automaticamente que os usuários se juntem a esta organização com base no endereço de e-mail ao se inscrever. Clique em '+' ou pressione ENTER após cada um para adicioná-los à lista de nomes de host.",
            "form.hostname": "Nome do host",
            "form.add": "Adicionar nome do host",
            "form.add.hint": "Digite company.com para adicionar automaticamente qualquer pessoa com um endereço de e-mail {address}.",
            "form.submit": "Adicionar nomes de host",
            "toast.success": "Os nomes de host foram adicionados."
        },
        "ja": {
            "title": "ホスト名を追加",
            "text": "登録時にメールアドレスに基づいてユーザーが自動的にこの組織に参加できるようにします。それぞれの後に '+' をクリックするか、ENTERを押して、ホスト名のリストに追加します。",
            "form.hostname": "ホスト名",
            "form.add": "ホスト名を追加",
            "form.add.hint": "company.com を入力して、{address} メールアドレスを持つ人を自動的に追加します。",
            "form.submit": "ホスト名を追加",
            "toast.success": "ホスト名が追加されました。"
        }
    }
</i18n>
