<template>
    <CreateEditTheme :open="createThemeModal"
        :organization="organization.id"
        v-on:close="createThemeModal = false"
        v-on:action="addTheme" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/theme" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading && themes.length">
                    <ThemeCard v-for="theme in themes"
                        :key="theme.id"
                        :theme="theme"
                        :organization="organization"
                        :can-edit="organization.can_edit"
                        v-on:add="addTheme"
                        v-on:update="updateTheme"
                        v-on:remove="removeTheme"
                        v-on:default="setDefault" />
                </div>
                <p v-if="!loading && !themes.length" style="margin-bottom: 12px;">{{t("no_themes")}}</p>
            </Expand>
            <button v-on:click="createThemeModal = true" v-if="organization.can_edit">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, PropType, ref} from "vue";
    import {useList} from "@/components/list";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {Theme} from "@/model/Theme";
    import {ThemeRepo} from "@/repositories/ThemeRepo";
    import ThemeCard from "@/components/cards/ThemeCard.vue";
    import CreateEditTheme from "@/components/modal/CreateEditTheme.vue";
    import {Organization} from "@/model/Organization";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Loading,
            Expand,
            ThemeCard,
            CreateEditTheme
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        emits: ["default"],
        setup(props) {
            const {user} = storeToRefs(useUserStore());
            const {removeEntryByID, updateEntryByID} = useList();
            const createThemeModal = ref(false);
            const loading = ref(true);
            const themes = ref<Theme[]>([]);
            const isOwner = computed(() => props.organization.user_id === user.value.id);

            onMounted(async () => {
                loading.value = true;
                themes.value = await ThemeRepo.list(props.organization.id) as Theme[];
                loading.value = false;
            });

            function addTheme(theme: Theme) {
                createThemeModal.value = false;
                themes.value.push(theme);
            }

            function updateTheme(theme: Theme) {
                updateEntryByID(themes.value, theme);
            }

            function removeTheme(theme: Theme) {
                removeEntryByID(themes.value, theme);
            }

            function setDefault(id: string) {
                for (let i = 0; i < themes.value.length; i++) {
                    themes.value[i].is_default = themes.value[i].id === id;
                }
            }

            return {
                ...useI18n(),
                createThemeModal,
                loading,
                themes,
                isOwner,
                addTheme,
                updateTheme,
                removeTheme,
                setDefault
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Saved Themes",
            "text": "Customize your dashboards with colors, logos and more. The themes defined here will be available for all sites belonging to this organization.",
            "link": "Read the Docs",
            "no_themes": "No Themes Found",
            "add": "Add Theme"
        },
        "de": {
            "title": "Gespeicherte Themen",
            "text": "Passe deine Dashboards mit Farben, Logos und mehr an. Die hier definierten Themen werden für alle Websites dieser Organisation verfügbar sein.",
            "link": "Dokumentation lesen",
            "no_themes": "Keine Themen gefunden",
            "add": "Thema hinzufügen"
        },
        "es": {
            "title": "Temas Guardados",
            "text": "Personaliza tus tableros con colores, logotipos y más. Los temas definidos aquí estarán disponibles para todos los sitios pertenecientes a esta organización.",
            "link": "Leer la documentación",
            "no_themes": "No se encontraron temas",
            "add": "Agregar tema"
        },
        "fr": {
            "title": "Thèmes Enregistrés",
            "text": "Personnalisez vos tableaux de bord avec des couleurs, des logos et plus encore. Les thèmes définis ici seront disponibles pour tous les sites appartenant à cette organisation.",
            "link": "Lire la documentation",
            "no_themes": "Aucun thème trouvé",
            "add": "Ajouter un thème"
        },
        "nl": {
            "title": "Opgeslagen Thema's",
            "text": "Pas je dashboards aan met kleuren, logo's en meer. De hier gedefinieerde thema's zijn beschikbaar voor alle sites die bij deze organisatie horen.",
            "link": "Lees de documentatie",
            "no_themes": "Geen thema's gevonden",
            "add": "Thema toevoegen"
        },
        "it": {
            "title": "Temi Salvati",
            "text": "Personalizza i tuoi dashboard con colori, loghi e altro. I temi definiti qui saranno disponibili per tutti i siti appartenenti a questa organizzazione.",
            "link": "Leggi la documentazione",
            "no_themes": "Nessun tema trovato",
            "add": "Aggiungi tema"
        },
        "pt": {
            "title": "Temas Salvos",
            "text": "Personalize seus painéis com cores, logotipos e mais. Os temas definidos aqui estarão disponíveis para todos os sites pertencentes a esta organização.",
            "link": "Leia a documentação",
            "no_themes": "Nenhum tema encontrado",
            "add": "Adicionar tema"
        },
        "ja": {
            "title": "保存されたテーマ",
            "text": "色、ロゴなどでダッシュボードをカスタマイズします。ここで定義されたテーマは、この組織に属するすべてのサイトで利用できます。",
            "link": "ドキュメントを読む",
            "no_themes": "テーマが見つかりません",
            "add": "テーマを追加"
        }
    }
</i18n>
