import {APIError} from "@/repositories/APIError";
import {Repository} from "@/repositories/Repository";
import {TrafficFilter} from "@/model/TrafficFilter";

export interface TrafficFilterData {
    id?: string
    domain_id: string
    type: string
    description: string
    filter: string
}

export const TrafficFilterRepo = new class extends Repository {
    async list(domain_id?: string): Promise<TrafficFilter[] | APIError> {
        return this.performGet<TrafficFilter[]>("/filter", {domain_id}, []);
    }

    async save(data: TrafficFilterData): Promise<TrafficFilter | APIError> {
        return this.performPost<TrafficFilter>("/filter", {...data});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/filter", {id});
    }
}
