<template>
    <component :is="component"
        panel="pages"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'pages' : 'top_pages')"
        :loadMore="loadMore(limitless ? 'pages' : 'top_pages')"
        :newTab="true"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :showDetails="showDetails"
        v-on:filter="filterByPage"
        v-on:open="openPage"
        v-on:search="searchAndSort" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent, formatSeconds} from "@/util/format";
    import {openPage} from "@/components/statistics/page";
    import {ListEntry} from "@/model/ListEntry";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useTable} from "@/components/panels/table";
    import {Row} from "@/components/panels/Row";
    import {Filter} from "@/model/Filter";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadPages, pages} = useStatisticsStore();
            const {setField, removeField} = filterStore;
            const {searchAndSort} = useTable(loadPages, (f: Filter) => f.include_avg_time_on_page = true);
            const rawData = computed(() => pages(props.limitless));
            const data = ref<Row[]>([]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("pages_table");
                }

                data.value = rawData.value;
            });

            watch([rawData, () => props.visible], () => {
                if (props.visible) {
                    data.value = rawData.value;
                }
            });

            function filterByPage(entry: ListEntry) {
                if (setField("path", entry.label || "null")) {
                    removeField("pattern");
                }
            }

            return {
                columns: [
                    {key: "label", label: t("columns.path"), order_by: "path"},
                    {key: "value", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                    {key: "relative_value", label: t("columns.visitors_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_visitors"},
                    {key: "views", label: t("columns.views"), transform: (v: number) => formatAndShortenNumber(v), order_by: "views"},
                    {key: "relative_views", label: t("columns.views_relative"), transform: (v: number) => formatPercent(v)+"%", order_by: "relative_views"},
                    {key: "bounce_rate", label: t("columns.bounce_rate"), transform: (v: number) => formatPercent(v)+"%", order_by: "bounce_rate"},
                    {key: "average_time_on_page_seconds", label: t("columns.time"), transform: (v: number) => formatSeconds(v), order_by: "-"}
                ],
                isLoading,
                loadMore,
                data,
                filterByPage,
                openPage,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "columns.path": "Path",
            "columns.visitors": "Visitors",
            "columns.visitors_relative": "Visitors %",
            "columns.views": "Views",
            "columns.views_relative": "Views %",
            "columns.bounce_rate": "Bounce Rate",
            "columns.time": "Time on Page"
        },
        "de": {
            "columns.path": "Pfad",
            "columns.visitors": "Besucher",
            "columns.visitors_relative": "Besucher %",
            "columns.views": "Ansichten",
            "columns.views_relative": "Ansichten %",
            "columns.bounce_rate": "Absprungrate",
            "columns.time": "Zeit auf der Seite"
        },
        "es": {
            "columns.path": "Ruta",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.views": "Vistas",
            "columns.views_relative": "Vistas %",
            "columns.bounce_rate": "Tasa de Rebote",
            "columns.time": "Tiempo en la Página"
        },
        "fr": {
            "columns.path": "Chemin",
            "columns.visitors": "Visiteurs",
            "columns.visitors_relative": "Visiteurs %",
            "columns.views": "Vues",
            "columns.views_relative": "Vues %",
            "columns.bounce_rate": "Taux de Rebond",
            "columns.time": "Temps sur la Page"
        },
        "nl": {
            "columns.path": "Pad",
            "columns.visitors": "Bezoekers",
            "columns.visitors_relative": "Bezoekers %",
            "columns.views": "Weergaven",
            "columns.views_relative": "Weergaven %",
            "columns.bounce_rate": "Bouncepercentage",
            "columns.time": "Tijd op Pagina"
        },
        "it": {
            "columns.path": "Percorso",
            "columns.visitors": "Visitatori",
            "columns.visitors_relative": "Visitatori %",
            "columns.views": "Visualizzazioni",
            "columns.views_relative": "Visualizzazioni %",
            "columns.bounce_rate": "Frequenza di Rimbalzo",
            "columns.time": "Tempo sulla Pagina"
        },
        "pt": {
            "columns.path": "Caminho",
            "columns.visitors": "Visitantes",
            "columns.visitors_relative": "Visitantes %",
            "columns.views": "Visualizações",
            "columns.views_relative": "Visualizações %",
            "columns.bounce_rate": "Taxa de Rejeição",
            "columns.time": "Tempo na Página"
        },
        "ja": {
            "columns.path": "パス",
            "columns.visitors": "訪問者",
            "columns.visitors_relative": "訪問者 %",
            "columns.views": "ビュー",
            "columns.views_relative": "ビュー %",
            "columns.bounce_rate": "直帰率",
            "columns.time": "ページの滞在時間"
        }
    }
</i18n>
