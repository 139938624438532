<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <template v-if="domainOptions.length">
            <p>{{t("text_0")}}</p>
            <p class="warning" v-show="transfer">{{t("text_1")}}</p>
            <form v-on:submit.prevent="save">
                <FormSelect :label="t('form.website')" name="website" v-model="domain" :options="domainOptions" />
                <FormSubmit :value="t('form.submit')" :loading="loading" />
            </form>
        </template>
        <p v-else>{{t("text_2")}}</p>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {OrganizationRepo} from "@/repositories/OrganizationRepo";
    import {Organization} from "@/model/Organization";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormSelect
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const {user} = storeToRefs(useUserStore());
            const {domains} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const domain = ref("");
            const domainOptions = computed(() => {
                const options = [];

                for (const domain of domains.value) {
                    if (domain.user_id === user.value.id && domain.organization_id !== props.organization.id) {
                        options.push({
                            label: domain.display_name ?? domain.hostname,
                            value: domain.id
                        });
                    }
                }

                return options;
            });
            const transfer = computed(() => {
                if (domain.value) {
                    const d = domains.value.find(d => d.id === domain.value);
                    return d && d.organization_id;
                }

                return false;
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const d = await OrganizationRepo.addDomain(props.organization.id, domain.value);
                    success(t("toast.success"));
                    domain.value = "";
                    loading.value = false;
                    emit("action", d);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                domainOptions,
                domain,
                transfer,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Add Website",
            "text_0": "Select the website you would like to add to this organization.",
            "text_1": "This website belongs to a different organization and will be transferred.",
            "form.website": "Website",
            "form.submit": "Add Website",
            "text_2": "You don't have any websites to add to this organisation.",
            "toast.success": "The website has been added."
        },
        "de": {
            "title": "Webseite hinzufügen",
            "text_0": "Wähle die Webseite aus, die du dieser Organisation hinzufügen möchtest.",
            "text_1": "Diese Webseite gehört zu einer anderen Organisation und wird übertragen.",
            "form.website": "Webseite",
            "form.submit": "Webseite hinzufügen",
            "text_2": "Du hast keine Webseiten, die du dieser Organisation hinzufügen kannst.",
            "toast.success": "Die Webseite wurde hinzugefügt."
        },
        "es": {
            "title": "Agregar sitio web",
            "text_0": "Selecciona el sitio web que deseas agregar a esta organización.",
            "text_1": "Este sitio web pertenece a una organización diferente y será transferido.",
            "form.website": "Sitio web",
            "form.submit": "Agregar sitio web",
            "text_2": "No tienes sitios web para agregar a esta organización.",
            "toast.success": "El sitio web ha sido agregado."
        },
        "fr": {
            "title": "Ajouter un site web",
            "text_0": "Sélectionnez le site web que vous souhaitez ajouter à cette organisation.",
            "text_1": "Ce site web appartient à une autre organisation et sera transféré.",
            "form.website": "Site web",
            "form.submit": "Ajouter le site web",
            "text_2": "Vous n'avez aucun site web à ajouter à cette organisation.",
            "toast.success": "Le site web a été ajouté."
        },
        "nl": {
            "title": "Website toevoegen",
            "text_0": "Selecteer de website die je aan deze organisatie wilt toevoegen.",
            "text_1": "Deze website behoort tot een andere organisatie en wordt overgedragen.",
            "form.website": "Website",
            "form.submit": "Website toevoegen",
            "text_2": "Je hebt geen websites om aan deze organisatie toe te voegen.",
            "toast.success": "De website is toegevoegd."
        },
        "it": {
            "title": "Aggiungi sito web",
            "text_0": "Seleziona il sito web che desideri aggiungere a questa organizzazione.",
            "text_1": "Questo sito web appartiene a un'altra organizzazione e sarà trasferito.",
            "form.website": "Sito web",
            "form.submit": "Aggiungi sito web",
            "text_2": "Non hai siti web da aggiungere a questa organizzazione.",
            "toast.success": "Il sito web è stato aggiunto."
        },
        "pt": {
            "title": "Adicionar site",
            "text_0": "Selecione o site que você gostaria de adicionar a esta organização.",
            "text_1": "Este site pertence a uma organização diferente e será transferido.",
            "form.website": "Site",
            "form.submit": "Adicionar site",
            "text_2": "Você não tem nenhum site para adicionar a esta organização.",
            "toast.success": "O site foi adicionado."
        },
        "ja": {
            "title": "ウェブサイトを追加",
            "text_0": "この組織に追加するウェブサイトを選択してください。",
            "text_1": "このウェブサイトは別の組織に属しており、移動されます。",
            "form.website": "ウェブサイト",
            "form.submit": "ウェブサイトを追加",
            "text_2": "この組織に追加できるウェブサイトがありません。",
            "toast.success": "ウェブサイトが追加されました。"
        }
    }
</i18n>
