<template>
    <SaveFilter :open="editFilterModal"
        v-on:close="editFilterModal = false"
        v-on:action="r => {editFilterModal = false;$emit('update', r)}"
        :filter="filter"
        :type="type"
        :help="help"
        :example="example"
        :countryList="countryList" />
    <ConfirmDeleteTrafficFilter :open="deleteFilterModal"
        v-on:close="deleteFilterModal = false"
        v-on:action="removeFilter"
        :filter="filter" />
    <div class="card no-select">
        <div class="text">
            <p>
                <strong>{{filter.description}}</strong>
            </p>
            <small class="light">{{filter.filter}}</small>
        </div>
        <div class="row">
            <i class="icon icon-follow clickable" :title="t('open')" v-on:click.stop="openPage" v-if="type === 'hostname' && !filter.filter.includes('*')" />
            <Lock :lock="`traffic_filter_${filter.id}`">
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editFilterModal = true"></i>
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteFilterModal = true" />
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import {TrafficFilter} from "@/model/TrafficFilter";
    import ConfirmDeleteTrafficFilter from "@/components/modal/ConfirmDeleteTrafficFilter.vue";
    import SaveFilter from "@/components/modal/SaveFilter.vue";
    import Lock from "@/components/settings/Lock.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Lock,
            SaveFilter,
            ConfirmDeleteTrafficFilter
        },
        props: {
            filter: {type: Object as PropType<TrafficFilter>, required: true},
            type: {type: String, required: true},
            title: {type: String, required: true},
            description: {type: String, required: true},
            help: {type: String, required: true},
            example: String,
            countryList: Boolean
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const editFilterModal = ref(false);
            const deleteFilterModal = ref(false);

            function removeFilter() {
                deleteFilterModal.value = false;
                emit("remove", props.filter);
            }

            function openPage(): void {
                window.open("https://" + props.filter.filter, "_blank");
            }

            return {
                ...useI18n(),
                editFilterModal,
                deleteFilterModal,
                removeFilter,
                openPage
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "open": "Open Website",
            "edit": "Edit",
            "delete": "Delete"
        },
        "de": {
            "open": "Webseite öffnen",
            "edit": "Bearbeiten",
            "delete": "Löschen"
        },
        "es": {
            "open": "Abrir sitio web",
            "edit": "Editar",
            "delete": "Eliminar"
        },
        "fr": {
            "open": "Ouvrir le site web",
            "edit": "Éditer",
            "delete": "Supprimer"
        },
        "nl": {
            "open": "Website openen",
            "edit": "Bewerken",
            "delete": "Verwijderen"
        },
        "it": {
            "open": "Apri sito web",
            "edit": "Modifica",
            "delete": "Elimina"
        },
        "pt": {
            "open": "Abrir site",
            "edit": "Editar",
            "delete": "Excluir"
        },
        "ja": {
            "open": "ウェブサイトを開く",
            "edit": "編集",
            "delete": "削除"
        }
    }
</i18n>
