<template>
    <section class="row">
        <h3>{{t("title")}}</h3>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="fullName" v-model="fullName" :err="validationError('full_name')" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormInput,
            FormSubmit
        },
        setup() {
            const {t} = useI18n();
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);
            const {resetError, setError, validationError} = useError();
            const {success, error} = useToastStore();
            const loading = ref(false);
            const fullName = ref(user.value.full_name);

            watch(user, value => {
                fullName.value = value.full_name;
            });

            async function save() {
                if (user) {
                    try {
                        loading.value = true;
                        resetError();
                        await UserRepo.changeFullName(fullName.value);
                        await userStore.load();
                        loading.value = false;
                        success(t("toast.success"));
                    } catch (e) {
                        loading.value = false;
                        setError(e as APIError);
                        error(e as APIError);
                    }
                }
            }

            return {
                t,
                validationError,
                loading,
                fullName,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Your Name",
            "form.name": "Full Name",
            "form.submit": "Update Name",
            "toast.success": "Your name has been updated."
        },
        "de": {
            "title": "Dein Name",
            "form.name": "Vollständiger Name",
            "form.submit": "Namen aktualisieren",
            "toast.success": "Dein Name wurde aktualisiert."
        },
        "es": {
            "title": "Tu nombre",
            "form.name": "Nombre completo",
            "form.submit": "Actualizar nombre",
            "toast.success": "Tu nombre ha sido actualizado."
        },
        "fr": {
            "title": "Votre nom",
            "form.name": "Nom complet",
            "form.submit": "Mettre à jour le nom",
            "toast.success": "Votre nom a été mis à jour."
        },
        "nl": {
            "title": "Je naam",
            "form.name": "Volledige naam",
            "form.submit": "Naam bijwerken",
            "toast.success": "Je naam is bijgewerkt."
        },
        "it": {
            "title": "Il tuo nome",
            "form.name": "Nome completo",
            "form.submit": "Aggiorna nome",
            "toast.success": "Il tuo nome è stato aggiornato."
        },
        "pt": {
            "title": "Seu nome",
            "form.name": "Nome completo",
            "form.submit": "Atualizar nome",
            "toast.success": "Seu nome foi atualizado."
        },
        "ja": {
            "title": "あなたの名前",
            "form.name": "フルネーム",
            "form.submit": "名前を更新",
            "toast.success": "名前が更新されました。"
        }
    }
</i18n>
