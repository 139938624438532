import {APIError} from "@/repositories/APIError";
import {Snippet, SnippetParams} from "@/model/Snippet";
import {Repository} from "@/repositories/Repository";

export const SnippetRepo = new class extends Repository {
    async get(domain_id: string, type: string): Promise<Snippet | null | APIError> {
        return this.performGet<Snippet | null>("/domain/snippet", {domain_id, type}, null, true);
    }

    async update(snippet: SnippetParams): Promise<APIError | null> {
        return this.performPost<null>("/domain/snippet", snippet);
    }
}
