import Cookies from "js-cookie";
import {getCookieDomain} from "@/util/domain";

export function deleteAllCookies() {
    Cookies.remove("access_token", {
        expires: -1,
        secure: import.meta.env.VITE_APP_SECURE_COOKIES === "true",
        domain: getCookieDomain(),
        path: "/",
        sameSite: "strict"
    });
    Cookies.remove("refresh_token", {
        expires: -1,
        secure: import.meta.env.VITE_APP_SECURE_COOKIES === "true",
        domain: getCookieDomain(),
        path: "/",
        sameSite: "strict"
    });

    // ensure to delete all other cookies
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
