<template>
    <section class="add" v-if="!domain">
        <h1>{{t("title")}}</h1>
        <form v-on:submit.prevent="save" v-if="!user.read_only">
            <FormInput :label="t('form.hostname')" name="hostname" :autofocus="true" v-model="hostname" :err="validationError('hostname')" :hint="t('form.hostname.hint')" />
            <div style="width: 100%; display: flex; margin: -12px 0;">
                <FormInput :label="t('form.subdomain')" name="subdomain" v-model="subdomain" :err="validationError('subdomain')" :hint="t('form.subdomain.hint')" v-on:keyup="modifiedSubdomain = true" />
                <p style="margin: 12px 0 0 8px; font-size: 16px; font-weight: 500;">.{{dashboardHostname}}</p>
            </div>
            <FormSelect :label="t('form.timezone')" name="timezone" :options="timezones" v-model="timezone" :err="validationError('timezone')" />
            <FormSelect :label="t('form.organization')" name="organization" :options="organizationOptions" v-model="organization" :err="validationError('organization')" v-show="organizationOptions.length > 1" />
            <FormSelect :label="t('form.theme')" name="theme" :options="themeOptions" v-model="theme" v-show="themeOptions.length > 1" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
        <p v-else>{{t("permission")}}</p>
    </section>
    <Integration :domain="domain" v-if="domain" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import dayjs from "dayjs";
    import {useRoute} from "vue-router";
    import {useError} from "@/components/error";
    import {useTimezone} from "@/components/timezone";
    import {Domain} from "@/model/Domain";
    import {APIError} from "@/repositories/APIError";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import Integration from "@/components/domain/Integration.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useFilterStore} from "@/store/FilterStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useOrganizationStore} from "@/store/OrganizationStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Integration,
            FormSubmit,
            FormInput,
            FormSelect
        },
        setup() {
            const {t} = useI18n();
            const route = useRoute();
            const domainStore = useDomainStore();
            const themeStore = useThemeStore();
            const {clear, clearComparison} = useFilterStore();
            const {error} = useToastStore();
            const {resetError, setError, validationError} = useError();
            const {user} = storeToRefs(useUserStore());
            const {timezones, tz} = useTimezone();
            const {organizations} = storeToRefs(useOrganizationStore());
            const {themes} = storeToRefs(themeStore);
            const loading = ref(false);
            const hostname = ref("");
            const subdomain = ref("");
            const domain = ref<Domain | null>(null);
            const timezone = ref("");
            const modifiedSubdomain = ref(false);
            const organization = ref("");
            const theme = ref("");
            const organizationOptions = computed(() => {
                const options = [
                    {
                        label: t("options.organizations.none"),
                        value: ""
                    }
                ];

                for (const organization of organizations.value) {
                    if (organization.can_edit) {
                        options.push({
                            label: organization.name,
                            value: organization.id
                        });
                    }
                }

                return options;
            });
            const themeOptions = computed(() => {
                const options = [
                    {
                        label: t("options.themes.default"),
                        value: "",
                        default: false
                    }
                ];

                const themeList = themes.value.filter(t => {
                    if (organization.value) {
                        return t.organization_id && t.organization_id === organization.value;
                    }

                    return (user.value.free_trial || user.value.subscription_plus) && t.user_id && t.user_id === user.value.id;
                });

                for (const theme of themeList) {
                    options.push({
                        label: theme.name,
                        value: theme.id,
                        default: theme.is_default
                    });
                }

                return options;
            });

            onMounted(async () => {
                timezone.value = tz.value;
            });

            watch(route, () => {
                hostname.value = "";
                subdomain.value = "";
                domain.value = null;
            });

            watch(hostname, hostname => {
                if (!modifiedSubdomain.value) {
                    subdomain.value = hostname.replaceAll(".", "");
                }
            });

            watch([organization, themeOptions], () => {
                if (!organization.value) {
                    theme.value = "";
                } else {
                    const option = themeOptions.value.find(option => option.default);
                    theme.value = option ? option.value : "";
                }
            });

            async function save() {
                try {
                    if (loading.value) {
                        return;
                    }

                    loading.value = true;
                    resetError();
                    const d = await DomainRepo.create(hostname.value, subdomain.value, timezone.value, organization.value, theme.value) as Domain;
                    await domainStore.load();
                    domainStore.set(d);
                    clear();
                    clearComparison();
                    domain.value = d;
                    localStorage.setItem("domain_created", dayjs().add(30, "seconds").toISOString());
                    domainStore.created = true;
                    themeStore.setTheme(d);
                    loading.value = false;
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                ...storeToRefs(useUserStore()),
                dashboardHostname: import.meta.env.VITE_APP_DASHBOARD_DOMAIN,
                resetError,
                validationError,
                user,
                timezones,
                loading,
                hostname,
                subdomain,
                domain,
                timezone,
                modifiedSubdomain,
                organization,
                organizationOptions,
                theme,
                themeOptions,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Add Website",
            "form.hostname": "Hostname",
            "form.hostname.hint": "Example: my-domain.com (include www if used)",
            "form.subdomain": "Subdomain",
            "form.subdomain.hint": "Example: my-domain",
            "form.timezone": "Timezone",
            "form.organization": "Organization",
            "form.theme": "Theme",
            "form.submit": "Create Dashboard",
            "permission": "You don't have permission to add a websites.",
            "options.organizations.none": "None",
            "options.themes.default": "Default"
        },
        "de": {
            "title": "Webseite hinzufügen",
            "form.hostname": "Hostname",
            "form.hostname.hint": "Beispiel: meine-domain.com (einschließlich www, falls verwendet)",
            "form.subdomain": "Subdomain",
            "form.subdomain.hint": "Beispiel: meine-domain",
            "form.timezone": "Zeitzone",
            "form.organization": "Organisation",
            "form.theme": "Thema",
            "form.submit": "Dashboard erstellen",
            "permission": "Du hast keine Berechtigung, Webseiten hinzuzufügen.",
            "options.organizations.none": "Keine",
            "options.themes.default": "Standard"
        },
        "es": {
            "title": "Añadir sitio web",
            "form.hostname": "Nombre del host",
            "form.hostname.hint": "Ejemplo: mi-dominio.com (incluir www si se usa)",
            "form.subdomain": "Subdominio",
            "form.subdomain.hint": "Ejemplo: mi-dominio",
            "form.timezone": "Zona horaria",
            "form.organization": "Organización",
            "form.theme": "Tema",
            "form.submit": "Crear panel",
            "permission": "No tienes permiso para añadir sitios web.",
            "options.organizations.none": "Ninguno",
            "options.themes.default": "Por defecto"
        },
        "fr": {
            "title": "Ajouter un site web",
            "form.hostname": "Nom d'hôte",
            "form.hostname.hint": "Exemple : mon-domaine.com (inclure www si utilisé)",
            "form.subdomain": "Sous-domaine",
            "form.subdomain.hint": "Exemple : mon-domaine",
            "form.timezone": "Fuseau horaire",
            "form.organization": "Organisation",
            "form.theme": "Thème",
            "form.submit": "Créer le tableau de bord",
            "permission": "Vous n'avez pas la permission d'ajouter des sites web.",
            "options.organizations.none": "Aucun",
            "options.themes.default": "Défaut"
        },
        "nl": {
            "title": "Website toevoegen",
            "form.hostname": "Hostnaam",
            "form.hostname.hint": "Voorbeeld: mijn-domein.com (inclusief www indien gebruikt)",
            "form.subdomain": "Subdomein",
            "form.subdomain.hint": "Voorbeeld: mijn-domein",
            "form.timezone": "Tijdzone",
            "form.organization": "Organisatie",
            "form.theme": "Thema",
            "form.submit": "Dashboard maken",
            "permission": "Je hebt geen toestemming om websites toe te voegen.",
            "options.organizations.none": "Geen",
            "options.themes.default": "Standaard"
        },
        "it": {
            "title": "Aggiungi sito web",
            "form.hostname": "Nome host",
            "form.hostname.hint": "Esempio: mio-dominio.com (includere www se usato)",
            "form.subdomain": "Sottodominio",
            "form.subdomain.hint": "Esempio: mio-dominio",
            "form.timezone": "Fuso orario",
            "form.organization": "Organizzazione",
            "form.theme": "Tema",
            "form.submit": "Crea dashboard",
            "permission": "Non hai il permesso di aggiungere siti web.",
            "options.organizations.none": "Nessuno",
            "options.themes.default": "Predefinito"
        },
        "pt": {
            "title": "Adicionar site",
            "form.hostname": "Nome do host",
            "form.hostname.hint": "Exemplo: meu-dominio.com (incluir www se usado)",
            "form.subdomain": "Subdomínio",
            "form.subdomain.hint": "Exemplo: meu-dominio",
            "form.timezone": "Fuso horário",
            "form.organization": "Organização",
            "form.theme": "Tema",
            "form.submit": "Criar painel",
            "permission": "Você não tem permissão para adicionar sites.",
            "options.organizations.none": "Nenhum",
            "options.themes.default": "Predefinição"
        },
        "ja": {
            "title": "ウェブサイトを追加",
            "form.hostname": "ホスト名",
            "form.hostname.hint": "例: my-domain.com（wwwを使用する場合は含めてください）",
            "form.subdomain": "サブドメイン",
            "form.subdomain.hint": "例: my-domain",
            "form.timezone": "タイムゾーン",
            "form.organization": "組織",
            "form.theme": "テーマ",
            "form.submit": "ダッシュボードを作成",
            "permission": "ウェブサイトを追加する権限がありません。",
            "options.organizations.none": "なし",
            "options.themes.default": "デフォルト"
        }
    }
</i18n>
