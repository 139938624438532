<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p v-if="client.client_id">
            <i18n-t keypath="text_0">
                <strong>{{client.client_id}}</strong>
                <span>"{{client.description}}"</span>
            </i18n-t>
        </p>
        <p v-else>
            {{t("text_1", {description: client.description})}}
        </p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {ClientRepo} from "@/repositories/ClientRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            client: {type: Object, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await ClientRepo.delete(props.client.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text_0": "You are about to delete the client {0} with the description {1}. This action will break all applications using this client.",
            "text_1": "You are about to delete the client \"{description}\". This action will break all applications using this client.",
            "form.submit": "Delete",
            "toast.success": "The client has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text_0": "Du bist dabei, den Client {0} mit der Beschreibung {1} zu löschen. Diese Aktion wird alle Anwendungen, die diesen Client verwenden, beeinträchtigen.",
            "text_1": "Du bist dabei, den Client \"{description}\" zu löschen. Diese Aktion wird alle Anwendungen, die diesen Client verwenden, beeinträchtigen.",
            "form.submit": "Löschen",
            "toast.success": "Der Client wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text_0": "Estás a punto de eliminar el cliente {0} con la descripción {1}. Esta acción romperá todas las aplicaciones que utilicen este cliente.",
            "text_1": "Estás a punto de eliminar el cliente \"{description}\". Esta acción romperá todas las aplicaciones que utilicen este cliente.",
            "form.submit": "Eliminar",
            "toast.success": "El cliente ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text_0": "Vous êtes sur le point de supprimer le client {0} avec la description {1}. Cette action va interrompre toutes les applications utilisant ce client.",
            "text_1": "Vous êtes sur le point de supprimer le client \"{description}\". Cette action va interrompre toutes les applications utilisant ce client.",
            "form.submit": "Supprimer",
            "toast.success": "Le client a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text_0": "Je staat op het punt de client {0} met de beschrijving {1} te verwijderen. Deze actie zal alle toepassingen die deze client gebruiken, verstoren.",
            "text_1": "Je staat op het punt de client \"{description}\" te verwijderen. Deze actie zal alle toepassingen die deze client gebruiken, verstoren.",
            "form.submit": "Verwijderen",
            "toast.success": "De client is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text_0": "Stai per eliminare il client {0} con la descrizione {1}. Questa azione interromperà tutte le applicazioni che utilizzano questo client.",
            "text_1": "Stai per eliminare il client \"{description}\". Questa azione interromperà tutte le applicazioni che utilizzano questo client.",
            "form.submit": "Elimina",
            "toast.success": "Il client è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text_0": "Você está prestes a excluir o cliente {0} com a descrição {1}. Esta ação interromperá todas as aplicações que utilizam este cliente.",
            "text_1": "Você está prestes a excluir o cliente \"{description}\". Esta ação interromperá todas as aplicações que utilizam este cliente.",
            "form.submit": "Excluir",
            "toast.success": "O cliente foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text_0": "クライアント {0} を説明 {1} で削除しようとしています。このアクションにより、このクライアントを使用するすべてのアプリケーションが停止します。",
            "text_1": "説明 \"{description}\" でクライアントを削除しようとしています。このアクションにより、このクライアントを使用するすべてのアプリケーションが停止します。",
            "form.submit": "削除",
            "toast.success": "クライアントが削除されました。"
        }
    }
</i18n>
