interface List {
    removeEntryByID<T extends IDType>(list: T[], entry: T): boolean
    updateEntryByID<T extends IDType>(list: T[], entry: T): boolean
}

interface IDType {
    id: string
}

export function useList(): List {
    function removeEntryByID<T extends IDType>(list: T[], entry: T): boolean {
        if (!list || !list.length || !entry) {
            return false;
        }

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === entry.id) {
                list.splice(i, 1);
                return true;
            }
        }

        return false;
    }

    function updateEntryByID<T extends IDType>(list: T[], entry: T): boolean {
        if (!list || !list.length || !entry) {
            return false;
        }

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === entry.id) {
                list[i] = entry;
                return true;
            }
        }

        return false;
    }

    return {
        removeEntryByID,
        updateEntryByID
    };
}
