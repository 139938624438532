<template>
    <SaveView :view="editSelectedView"
        :open="saveViewModal === true || editSelectedView !== undefined"
        v-on:close="saveViewModal = false; editSelectedView = undefined" />
    <DeleteView :view="deleteSelectedView"
        :open="deleteSelectedView !== undefined"
        v-on:close="deleteSelectedView = undefined" />
    <div :class="{action: true, active: dropdown}" v-on:click="toggleDropdown" ref="dropdownElement">
        <i class="icon icon-bookmark" :title="t('views')"></i>
        <span>{{t("views")}}</span>
        <i :class="{'icon icon-expand': true, 'icon-rotate-180': dropdown}"></i>
        <transition name="fade-down">
            <ul class="dropdown" ref="scrollArea" v-show="dropdown">
                <li v-on:click="saveViewModal = true" v-if="loggedIn">{{t("create")}}</li>
                <hr v-if="loggedIn" />
                <li v-for="(view, index) in views"
                    :key="view.id"
                    :class="{selected: active === index}"
                    v-on:click="select(view)">
                    <span>
                        {{view.name}}
                        <span class="tag" v-if="!view.user_id && loggedIn">{{t("public")}}</span>
                    </span>
                    <div class="row" v-if="loggedIn">
                        <i class="icon icon-edit clickable" :title="t('edit')" v-on:click.stop="editView(view)"></i>
                        <i class="icon icon-trash clickable" :title="t('delete')" v-on:click.stop="deleteView(view)"></i>
                    </div>
                </li>
                <div class="no-data" v-if="!views.length">
                    <small>{{t("not_found")}}</small>
                </div>
            </ul>
        </transition>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {View} from "@/model/View";
    import {useDropdown} from "@/components/dropdown";
    import SaveView from "@/components/modal/SaveView.vue";
    import DeleteView from "@/components/modal/DeleteView.vue";
    import {Filter} from "@/model/Filter";
    import {useSelect} from "@/components/panels/select";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {CompareRange} from "@/model/CompareRange";
    import {timeRangeOptions} from "@/components/panels/time_range_options";
    import {getToday} from "@/util/date";
    import {useDomainStore} from "@/store/DomainStore";
    import {timeFromParam} from "@/util/format";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            SaveView,
            DeleteView
        },
        props: {
            open: {type: Boolean, default: false}
        },
        setup() {
            const filterStore = useFilterStore();
            const {set, copy, setComparison} = filterStore;
            const {filter, views} = storeToRefs(filterStore);
            const {loggedIn} = storeToRefs(useUserStore());
            const {domain} = storeToRefs(useDomainStore());
            const {dropdown, dropdownElement, toggleDropdown} = useDropdown();
            const {active, scrollArea} = useSelect(dropdown, views, selectView);
            const saveViewModal = ref(false);
            const editSelectedView = ref<View | undefined>();
            const deleteSelectedView = ref<View | undefined>();

            function selectView(active: number) {
                select(views.value[active]);
            }

            function select(view: View) {
                const f = copy(filter.value);
                const fromTime = timeFromParam(view.from_time) ?? undefined;
                const toTime = timeFromParam(view.to_time) ?? undefined;

                if ((view.period || -1) > -1) {
                    f.timeRangeIndex = view.period;
                    const timeRange = timeRangeOptions.find(o => o.index === view.period);

                    if (timeRange) {
                        const today = getToday();
                        const from = timeRange.from(today, 0, domain.value);
                        const to = timeRange.to(today, 0);
                        f.timeRange = {
                            from,
                            to,
                            fromTime,
                            toTime,
                            start: 0
                        };
                    }
                } else if (view.from && view.to) {
                    f.timeRangeIndex = -1;
                    f.timeRange = {
                        from: new Date(view.from),
                        to: new Date(view.to),
                        fromTime,
                        toTime,
                        start: 0
                    }
                }

                f.fields = [];
                setFields(f, "path", view.path);
                setFields(f, "entry_path", view.entry_path);
                setFields(f, "exit_path", view.exit_path);
                setFields(f, "pattern", view.pattern);
                setFields(f, "language", view.language);
                setFields(f, "country", view.country);
                setFields(f, "region", view.region);
                setFields(f, "city", view.city);
                setFields(f, "referrer", view.referrer);
                setFields(f, "referrer_name", view.referrer_name);
                setFields(f, "os", view.os);
                setFields(f, "browser", view.browser);
                setFields(f, "platform", view.platform ? [view.platform] : undefined);
                setFields(f, "screen_class", view.screen_class);
                setFields(f, "utm_source", view.utm_source);
                setFields(f, "utm_medium", view.utm_medium);
                setFields(f, "utm_campaign", view.utm_campaign);
                setFields(f, "utm_content", view.utm_content);
                setFields(f, "utm_term", view.utm_term);
                setFields(f, "event", view.event);
                setFields(f, "tag", view.tag);
                setFieldsWithPrefix(f, "meta_", view.event_meta_key, view.event_meta_value);
                setFieldsWithPrefix(f, "tag_", view.tag_key, view.tag_value);
                f.visitor_id = view.visitor_id;
                f.session_id = view.session_id;
                set(f);
                let compareRange: CompareRange | undefined;

                if (view.compare_from && view.compare_to) {
                    compareRange = {
                        from: view.compare_from,
                        to: view.compare_to
                    }
                }

                setComparison({
                    compare: view.compare ?? undefined,
                    compareRange,
                    compareWeekday: view.compare_weekday ?? undefined
                });
                toggleDropdown();
            }

            function setFields(f: Filter, field: string, values: string[] | undefined) {
                if (values && f.fields) {
                    for (let i = 0; i < values.length; i++) {
                        f.fields.push({
                            field,
                            value: values[i]
                        });
                    }
                }
            }

            function setFieldsWithPrefix(f: Filter, prefix: string, keys: string[] | undefined, values: string[] | undefined) {
                if (keys && values && f.fields) {
                    for (let i = 0; i < values.length; i++) {
                        f.fields.push({
                            field: `${prefix}${keys[i]}`,
                            value: values[i]
                        });
                    }
                }
            }

            function editView(view: View) {
                editSelectedView.value = view;
            }

            function deleteView(view: View) {
                deleteSelectedView.value = view;
            }

            return {
                ...useI18n(),
                loggedIn,
                dropdown,
                dropdownElement,
                toggleDropdown,
                views,
                saveViewModal,
                editSelectedView,
                deleteSelectedView,
                active,
                scrollArea,
                select,
                editView,
                deleteView
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "views": "Views",
            "create": "Create View",
            "public": "Public",
            "edit": "Edit",
            "delete": "Delete",
            "not_found": "No View Found"
        },
        "de": {
            "views": "Ansichten",
            "create": "Ansicht erstellen",
            "public": "Öffentlich",
            "edit": "Bearbeiten",
            "delete": "Löschen",
            "not_found": "Keine Ansicht gefunden"
        },
        "es": {
            "views": "Vistas",
            "create": "Crear vista",
            "public": "Público",
            "edit": "Editar",
            "delete": "Eliminar",
            "not_found": "No se encontró ninguna vista"
        },
        "fr": {
            "views": "Vues",
            "create": "Créer une vue",
            "public": "Public",
            "edit": "Éditer",
            "delete": "Supprimer",
            "not_found": "Aucune vue trouvée"
        },
        "nl": {
            "views": "Weergaven",
            "create": "Weergave maken",
            "public": "Openbaar",
            "edit": "Bewerken",
            "delete": "Verwijderen",
            "not_found": "Geen weergave gevonden"
        },
        "it": {
            "views": "Visualizzazioni",
            "create": "Crea visualizzazione",
            "public": "Pubblico",
            "edit": "Modifica",
            "delete": "Elimina",
            "not_found": "Nessuna visualizzazione trovata"
        },
        "pt": {
            "views": "Visualizações",
            "create": "Criar visualização",
            "public": "Público",
            "edit": "Editar",
            "delete": "Excluir",
            "not_found": "Nenhuma visualização encontrada"
        },
        "ja": {
            "views": "ビュー",
            "create": "ビューを作成",
            "public": "公開",
            "edit": "編集",
            "delete": "削除",
            "not_found": "ビューが見つかりません"
        }
    }
</i18n>
