<template>
    <i class="tooltip icon icon-help" v-on:mouseenter="hover = true" v-on:mouseleave="mouseLeave" v-on:click.stop.prevent>
        <transition name="fade-down">
            <div class="pop-over" v-if="hover">{{content}}</div>
        </transition>
    </i>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";

    export default defineComponent({
        props: {
            content: String,
        },
        setup() {
            const hover = ref(false);

            function mouseLeave() {
                setTimeout(() => {hover.value = false}, 200);
            }

            return {
                hover,
                mouseLeave
            };
        }
    });
</script>
