<template>
    <Modal :title="view ? t('title.edit') : t('title.create')" :err="err" v-on:close="close">
        <p>{{t("text")}}</p>
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" autofocus autocomplete="off" :hint="overwrite" v-model="name" :err="validationError('name')" />
            <FormCheckbox :label="t('form.period')" name="includePeriod" v-model="includePeriod" />
            <FormCheckbox :label="t('form.comparison')" name="includeComparisonPeriod" v-model="includeComparisonPeriod" />
            <FormCheckbox :label="t('form.share')" name="share" v-model="share" v-if="isAdmin" />
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import {APIError} from "@/repositories/APIError";
    import {useError} from "@/components/error";
    import Modal from "@/components/modal/Modal.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormCheckbox from "@/components/form/FormCheckbox.vue";
    import {ViewRepo} from "@/repositories/ViewRepo";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {timeParam} from "@/util/format";
    import {Time} from "@/model/TimeRange";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormInput,
            FormSubmit,
            FormCheckbox
        },
        props: {
            view: Object
        },
        emits: ["close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {success} = useToastStore();
            const {isAdmin, domain} = storeToRefs(useDomainStore());
            const {filter, comparison, views} = storeToRefs(filterStore);
            const {resetError, setError, err, validationError} = useError();
            const loading = ref(false);
            const name = ref("");
            const includePeriod = ref(false);
            const includeComparisonPeriod = ref(false);
            const share = ref(false);
            const overwrite = computed(() => {
                const n = name.value.toLowerCase();

                if (props.view !== undefined || n === "") {
                    return "";
                }

                for (let i = 0; i < views.value.length; i++) {
                    if (n === views.value[i].name.toLowerCase() &&
                        (share.value && !views.value[i].user_id || !share.value && views.value[i].user_id)) {
                        return t("overwrite");
                    }
                }

                return "";
            });

            watch(() => props.view, () => {
                if (props.view) {
                    name.value = props.view.name;
                    share.value = props.view.user_id === null;
                }
            });

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const metadata = getFieldsWithPrefix("meta_");
                    const tags = getFieldsWithPrefix("tag_");
                    await ViewRepo.save({
                        id: props.view ? props.view.id : "",
                        domain_id: domain.value.id,
                        name: name.value,
                        from: includePeriod.value ? filter.value.timeRange?.from : undefined,
                        to: includePeriod.value ? filter.value.timeRange?.to : undefined,
                        from_time: includePeriod.value && filter.value.timeRange?.fromTime ? timeParam(filter.value.timeRange.fromTime as Time) : undefined,
                        to_time: includePeriod.value && filter.value.timeRange?.toTime ? timeParam(filter.value.timeRange.toTime as Time) : undefined,
                        period: filter.value.timeRangeIndex,
                        compare: comparison.value.compare !== "custom" || includeComparisonPeriod.value ? comparison.value.compare : undefined,
                        compare_from: includeComparisonPeriod.value ? comparison.value.compareRange?.from : undefined,
                        compare_to: includeComparisonPeriod.value ? comparison.value.compareRange?.to : undefined,
                        compare_weekday: comparison.value.compareWeekday,
                        path: getFields("path"),
                        entry_path: getFields("entry_path"),
                        exit_path: getFields("exit_path"),
                        path_pattern: getFields("path_pattern"),
                        pattern: getFields("pattern"),
                        language: getFields("language"),
                        country: getFields("country"),
                        region: getFields("region"),
                        city: getFields("city"),
                        referrer: getFields("referrer"),
                        referrer_name: getFields("referrer_name"),
                        os: getFields("os"),
                        browser: getFields("browser"),
                        platform: getField("platform"),
                        screen_class: getFields("screen_class"),
                        utm_source: getFields("utm_source"),
                        utm_medium: getFields("utm_medium"),
                        utm_campaign: getFields("utm_campaign"),
                        utm_content: getFields("utm_content"),
                        utm_term: getFields("utm_term"),
                        event: getFields("event"),
                        event_meta_key: metadata.keys,
                        event_meta_value: metadata.values,
                        tag: getFields("tag"),
                        tag_key: tags.keys,
                        tag_value: tags.values,
                        visitor_id: filter.value.visitor_id,
                        session_id: filter.value.session_id
                    }, share.value, props.view === undefined);
                    await filterStore.loadViews();
                    loading.value = false;
                    success(t("toast.success"));
                    close();
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            function getFields(field: string) {
                const values = [];

                if (filter.value.fields) {
                    for (let i = 0; i < filter.value.fields.length; i++) {
                        if (filter.value.fields[i].field === field) {
                            values.push(filter.value.fields[i].value);
                        }
                    }
                }

                return values;
            }

            function getFieldsWithPrefix(prefix: string) {
                const keys = [];
                const values = [];

                if (filter.value.fields) {
                    for (let i = 0; i < filter.value.fields.length; i++) {
                        if (filter.value.fields[i].field.startsWith(prefix)) {
                            keys.push(filter.value.fields[i].field.substring(prefix.length));
                            values.push(filter.value.fields[i].value);
                        }
                    }
                }

                return {keys, values};
            }

            function getField(field: string) {
                if (filter.value.fields) {
                    for (let i = 0; i < filter.value.fields.length; i++) {
                        if (filter.value.fields[i].field === field) {
                            return filter.value.fields[i].value;
                        }
                    }
                }

                return undefined;
            }

            function close() {
                name.value = "";
                includePeriod.value = false;
                includeComparisonPeriod.value = false;
                share.value = false;
                emit("close");
            }

            return {
                t,
                err,
                validationError,
                isAdmin,
                loading,
                name,
                includePeriod,
                includeComparisonPeriod,
                share,
                overwrite,
                save,
                close
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title.edit": "Edit View",
            "title.create": "Create View",
            "text": "A view is a bookmark of a range of filters you can share with other team members. Only admins can edit shared views.",
            "form.name": "Name",
            "form.period": "Include Time Period",
            "form.comparison": "Include Comparison Time Period",
            "form.share": "Share View With Your Team",
            "form.submit": "Save",
            "overwrite": "This will overwrite an existing view with the same name.",
            "toast.success": "View saved."
        },
        "de": {
            "title.edit": "Ansicht bearbeiten",
            "title.create": "Ansicht erstellen",
            "text": "Eine Ansicht ist ein Lesezeichen für eine Reihe von Filtern, die du mit anderen Teammitgliedern teilen kannst. Nur Admins können geteilte Ansichten bearbeiten.",
            "form.name": "Name",
            "form.period": "Zeitraum einschließen",
            "form.comparison": "Vergleichszeitraum einschließen",
            "form.share": "Ansicht mit deinem Team teilen",
            "form.submit": "Speichern",
            "overwrite": "Dies wird eine vorhandene Ansicht mit demselben Namen überschreiben.",
            "toast.success": "Ansicht gespeichert."
        },
        "es": {
            "title.edit": "Editar vista",
            "title.create": "Crear vista",
            "text": "Una vista es un marcador de un rango de filtros que puedes compartir con otros miembros del equipo. Solo los administradores pueden editar vistas compartidas.",
            "form.name": "Nombre",
            "form.period": "Incluir período de tiempo",
            "form.comparison": "Incluir período de tiempo de comparación",
            "form.share": "Compartir vista con tu equipo",
            "form.submit": "Guardar",
            "overwrite": "Esto sobrescribirá una vista existente con el mismo nombre.",
            "toast.success": "Vista guardada."
        },
        "fr": {
            "title.edit": "Modifier la vue",
            "title.create": "Créer une vue",
            "text": "Une vue est un signet d'une gamme de filtres que vous pouvez partager avec d'autres membres de l'équipe. Seuls les administrateurs peuvent modifier les vues partagées.",
            "form.name": "Nom",
            "form.period": "Inclure la période",
            "form.comparison": "Inclure la période de comparaison",
            "form.share": "Partager la vue avec votre équipe",
            "form.submit": "Enregistrer",
            "overwrite": "Cela écrasera une vue existante portant le même nom.",
            "toast.success": "Vue enregistrée."
        },
        "nl": {
            "title.edit": "Weergave bewerken",
            "title.create": "Weergave maken",
            "text": "Een weergave is een bladwijzer van een reeks filters die je kunt delen met andere teamleden. Alleen beheerders kunnen gedeelde weergaven bewerken.",
            "form.name": "Naam",
            "form.period": "Tijdperiode opnemen",
            "form.comparison": "Vergelijkingstijdperiode opnemen",
            "form.share": "Deel weergave met je team",
            "form.submit": "Opslaan",
            "overwrite": "Hiermee wordt een bestaande weergave met dezelfde naam overschreven.",
            "toast.success": "Weergave opgeslagen."
        },
        "it": {
            "title.edit": "Modifica visualizzazione",
            "title.create": "Crea visualizzazione",
            "text": "Una visualizzazione è un segnalibro di una gamma di filtri che puoi condividere con altri membri del team. Solo gli amministratori possono modificare le visualizzazioni condivise.",
            "form.name": "Nome",
            "form.period": "Includi periodo di tempo",
            "form.comparison": "Includi periodo di tempo di confronto",
            "form.share": "Condividi visualizzazione con il tuo team",
            "form.submit": "Salva",
            "overwrite": "Questo sovrascriverà una visualizzazione esistente con lo stesso nome.",
            "toast.success": "Visualizzazione salvata."
        },
        "pt": {
            "title.edit": "Editar visualização",
            "title.create": "Criar visualização",
            "text": "Uma visualização é um marcador de um intervalo de filtros que você pode compartilhar com outros membros da equipe. Apenas administradores podem editar visualizações compartilhadas.",
            "form.name": "Nome",
            "form.period": "Incluir período de tempo",
            "form.comparison": "Incluir período de comparação",
            "form.share": "Compartilhar visualização com sua equipe",
            "form.submit": "Salvar",
            "overwrite": "Isso substituirá uma visualização existente com o mesmo nome.",
            "toast.success": "Visualização salva."
        },
        "ja": {
            "title.edit": "ビューを編集",
            "title.create": "ビューを作成",
            "text": "ビューは、他のチームメンバーと共有できるフィルター範囲のブックマークです。共有ビューを編集できるのは管理者のみです。",
            "form.name": "名前",
            "form.period": "期間を含める",
            "form.comparison": "比較期間を含める",
            "form.share": "ビューをチームと共有",
            "form.submit": "保存",
            "overwrite": "これは同じ名前の既存のビューを上書きします。",
            "toast.success": "ビューが保存されました。"
        }
    }
</i18n>
