<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text", {hostname: domain.hostname})}}</p>
        <form v-on:submit.prevent="save">
            <FormSubmit :value="t('form.submit')" color="red" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useError} from "@/components/error";
    import {DomainRepo} from "@/repositories/DomainRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit
        },
        props: {
            domain: {type: Object, required: true}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const loading = ref(false);

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    await DomainRepo.deleteDomainAlternative(props.domain.id);
                    loading.value = false;
                    success(t("toast.success"));
                    emit("action");
                } catch(e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                err,
                loading,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Confirm Deletion",
            "text": "Are you sure you want to delete the additional domain \"{hostname}\"?",
            "form.submit": "Delete",
            "toast.success": "The additional domain has been deleted."
        },
        "de": {
            "title": "Löschen bestätigen",
            "text": "Bist du sicher, dass du die zusätzliche Domain \"{hostname}\" löschen möchtest?",
            "form.submit": "Löschen",
            "toast.success": "Die zusätzliche Domain wurde gelöscht."
        },
        "es": {
            "title": "Confirmar eliminación",
            "text": "¿Estás seguro de que deseas eliminar el dominio adicional \"{hostname}\"?",
            "form.submit": "Eliminar",
            "toast.success": "El dominio adicional ha sido eliminado."
        },
        "fr": {
            "title": "Confirmer la suppression",
            "text": "Êtes-vous sûr de vouloir supprimer le domaine supplémentaire \"{hostname}\" ?",
            "form.submit": "Supprimer",
            "toast.success": "Le domaine supplémentaire a été supprimé."
        },
        "nl": {
            "title": "Verwijdering bevestigen",
            "text": "Weet je zeker dat je het extra domein \"{hostname}\" wilt verwijderen?",
            "form.submit": "Verwijderen",
            "toast.success": "Het extra domein is verwijderd."
        },
        "it": {
            "title": "Conferma eliminazione",
            "text": "Sei sicuro di voler eliminare il dominio aggiuntivo \"{hostname}\"?",
            "form.submit": "Elimina",
            "toast.success": "Il dominio aggiuntivo è stato eliminato."
        },
        "pt": {
            "title": "Confirmar exclusão",
            "text": "Tem certeza de que deseja excluir o domínio adicional \"{hostname}\"?",
            "form.submit": "Excluir",
            "toast.success": "O domínio adicional foi excluído."
        },
        "ja": {
            "title": "削除の確認",
            "text": "追加ドメイン \"{hostname}\" を削除してもよろしいですか？",
            "form.submit": "削除",
            "toast.success": "追加ドメインが削除されました。"
        }
    }
</i18n>
