<template>
    <section class="empty">
        <i class="icon icon-filter"></i>
        <p>{{t("not_found")}}</p>
        <button v-on:click="$emit('create')" v-if="loggedIn && domain.user_role && isAdmin && domain.subscription_plus">
            {{t("add")}}
        </button>
    </section>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useI18n} from "vue-i18n";
    import {useDomainStore} from "@/store/DomainStore";

    export default defineComponent({
        emits: ["create"],
        setup() {
            return {
                ...useI18n(),
                ...storeToRefs(useUserStore()),
                ...storeToRefs(useDomainStore())
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "not_found": "There is nothing here yet.",
            "add": "Add Your First Funnel"
        },
        "de": {
            "not_found": "Hier gibt es noch nichts.",
            "add": "Füge deinen ersten Funnel hinzu"
        },
        "es": {
            "not_found": "No hay nada aquí todavía.",
            "add": "Añade tu primer embudo"
        },
        "fr": {
            "not_found": "Il n'y a encore rien ici.",
            "add": "Ajoutez votre premier entonnoir"
        },
        "nl": {
            "not_found": "Er is hier nog niets.",
            "add": "Voeg je eerste trechter toe"
        },
        "it": {
            "not_found": "Non c'è ancora niente qui.",
            "add": "Aggiungi il tuo primo imbuto"
        },
        "pt": {
            "not_found": "Ainda não há nada aqui.",
            "add": "Adicione seu primeiro funil"
        },
        "ja": {
            "not_found": "まだ何もありません。",
            "add": "最初のファネルを追加"
        }
    }
</i18n>
