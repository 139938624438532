export function debounce<T extends Function>(func: T, wait: number, immediate?: boolean) { // eslint-disable-line
    let timeout: number | undefined;

    return function(this: void) {
        const context = this; // eslint-disable-line
        const args = arguments; // eslint-disable-line

        const later = function() {
            timeout = undefined;
            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait) as unknown as number;

        if (callNow) {
            func.apply(context, args);
        }
    };
}
