<template>
    <section class="row">
        <h3>{{t("title")}}</h3>
        <div>
            <div class="user-image no-select">
                <img :src="picture" alt="" v-if="picture" />
                <i class="icon icon-user" v-else></i>
            </div>
            <form v-on:submit.prevent="uploadPicture">
                <FormFile :label="t('form.file')" name="file" :hint="t('form.file.hint')" v-model="file" :err="validationError('file')" />
                <FormSubmit :value="t('form.submit')" :loading="loading" />
            </form>
            <button class="red" style="margin-top: 12px;" v-on:click="deletePicture">{{t("delete")}}</button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {UserRepo} from "@/repositories/UserRepo";
    import {useError} from "@/components/error";
    import FormFile from "@/components/form/FormFile.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormFile,
            FormSubmit
        },
        setup() {
            const {t} = useI18n();
            const userStore = useUserStore();
            const {resetError, setError, validationError} = useError();
            const {success, error} = useToastStore();
            const {user, picture} = storeToRefs(userStore);
            const loading = ref(false);
            const file = ref(null);

            async function uploadPicture() {
                const picture = file.value;

                if (user && picture) {
                    try {
                        if (picture) {
                            loading.value = true;
                            resetError();
                            await UserRepo.uploadPicture(picture);
                            await userStore.load();
                            loading.value = false;
                            success(t("toast.updated"));
                        } else {
                            setError({
                                validation: {
                                    file: t("error.file")
                                },
                                error: []
                            });
                        }
                    } catch(e) {
                        loading.value = false;
                        setError(e as APIError);
                        error(e as APIError);
                    }
                }
            }

            async function deletePicture() {
                if (user) {
                    try {
                        resetError();
                        await UserRepo.deletePicture();
                        await userStore.load();
                        success(t("toast.deleted"));
                    } catch(e) {
                        setError(e as APIError);
                        error(e as APIError);
                    }
                }
            }

            return {
                t,
                picture,
                validationError,
                loading,
                file,
                uploadPicture,
                deletePicture
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Picture",
            "form.file": "Picture",
            "form.file.hint": "Max. 1 MB",
            "form.submit": "Upload Picture",
            "delete": "Delete Picture",
            "toast.updated": "Your picture has been updated.",
            "toast.deleted": "The picture has been deleted.",
            "error.file": "Please select an image."
        },
        "de": {
            "title": "Bild",
            "form.file": "Bild",
            "form.file.hint": "Max. 1 MB",
            "form.submit": "Bild hochladen",
            "delete": "Bild löschen",
            "toast.updated": "Dein Bild wurde aktualisiert.",
            "toast.deleted": "Das Bild wurde gelöscht.",
            "error.file": "Bitte wähle ein Bild aus."
        },
        "es": {
            "title": "Imagen",
            "form.file": "Imagen",
            "form.file.hint": "Máx. 1 MB",
            "form.submit": "Subir imagen",
            "delete": "Eliminar imagen",
            "toast.updated": "Tu imagen ha sido actualizada.",
            "toast.deleted": "La imagen ha sido eliminada.",
            "error.file": "Por favor, selecciona una imagen."
        },
        "fr": {
            "title": "Image",
            "form.file": "Image",
            "form.file.hint": "Max. 1 MB",
            "form.submit": "Télécharger l'image",
            "delete": "Supprimer l'image",
            "toast.updated": "Votre image a été mise à jour.",
            "toast.deleted": "L'image a été supprimée.",
            "error.file": "Veuillez sélectionner une image."
        },
        "nl": {
            "title": "Afbeelding",
            "form.file": "Afbeelding",
            "form.file.hint": "Max. 1 MB",
            "form.submit": "Afbeelding uploaden",
            "delete": "Afbeelding verwijderen",
            "toast.updated": "Je afbeelding is bijgewerkt.",
            "toast.deleted": "De afbeelding is verwijderd.",
            "error.file": "Selecteer een afbeelding."
        },
        "it": {
            "title": "Immagine",
            "form.file": "Immagine",
            "form.file.hint": "Max. 1 MB",
            "form.submit": "Carica immagine",
            "delete": "Elimina immagine",
            "toast.updated": "La tua immagine è stata aggiornata.",
            "toast.deleted": "L'immagine è stata eliminata.",
            "error.file": "Seleziona un'immagine."
        },
        "pt": {
            "title": "Imagem",
            "form.file": "Imagem",
            "form.file.hint": "Máx. 1 MB",
            "form.submit": "Carregar imagem",
            "delete": "Excluir imagem",
            "toast.updated": "Sua imagem foi atualizada.",
            "toast.deleted": "A imagem foi excluída.",
            "error.file": "Por favor, selecione uma imagem."
        },
        "ja": {
            "title": "写真",
            "form.file": "写真",
            "form.file.hint": "最大1MB",
            "form.submit": "写真をアップロード",
            "delete": "写真を削除",
            "toast.updated": "写真が更新されました。",
            "toast.deleted": "写真が削除されました。",
            "error.file": "画像を選択してください。"
        }
    }
</i18n>
