<template>
    <template v-if="loaded">
        <template v-if="loggedIn && (isDashboard || isCustomDomain)">
            <NewsEntries :news="news" :open="showNewsEntry && showNews" v-on:close="closeNews" />
            <div :class="{action: true, active: dropdown}" :title="t('title')" v-on:click="toggleDropdown" ref="dropdownElement">
                <img :src="picture" :alt="t('picture.alt')" v-if="picture" />
                <i class="icon icon-user" v-else></i>
                <i :class="{'icon icon-expand': true, 'icon-rotate-180': dropdown}"></i>
                <transition name="fade-down">
                    <div class="dropdown right" v-show="dropdown">
                        <small>{{t("logged_in_as")}}</small>
                        <strong>{{user.full_name}}</strong>
                        <template v-if="!selfHosted && !user.read_only">
                            <small v-if="user.free_trial">{{t("plan.free")}}</small>
                            <small class="plus" v-else-if="user.subscription_plus">{{t("plan.plus")}}</small>
                            <small class="standard" v-else-if="user.subscription_active">{{t("plan.standard")}}</small>
                            <small v-else>{{t("plan.none")}}</small>
                        </template>
                        <hr />
                        <router-link to="/account">{{t("link.account")}}</router-link>
                        <template v-if="!user.read_only">
                            <router-link to="/organizations">{{t("link.organizations")}}</router-link>
                            <router-link to="/themes">{{t("link.themes")}}</router-link>
                        </template>
                        <template v-if="showBilling">
                            <router-link to="/billing">{{t("link.billing")}}</router-link>
                            <router-link to="/affiliate">{{t("link.affiliate")}}</router-link>
                        </template>
                        <a href="#" v-on:click.stop.prevent="showNews = true" v-if="showNewsEntry">{{t("link.news")}}</a>
                        <a href="#" v-on:click.stop.prevent="logout">{{t("link.logout")}}</a>
                    </div>
                </transition>
            </div>
        </template>
        <template v-else-if="loggedIn && !isDashboard && !isCustomDomain && !hideUI">
            <a :href="dashboardURL" class="button">{{t("link.dashboard")}}</a>
        </template>
        <div style="display: flex; align-items: center; gap: 12px;" v-else-if="!loggedIn && !hideUI && !hideLogin">
            <button class="secondary" v-on:click="login" v-if="$route.name !== 'Login'">{{t("login")}}</button>
            <template v-if="!isCustomDomain && showGetStarted">
                <small v-if="$route.name === 'Login'">{{t("no_account")}}</small>
                <button v-on:click="signup">{{t("sign_up")}}</button>
            </template>
        </div>
    </template>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {useDropdown} from "@/components/dropdown";
    import NewsEntries from "@/components/news/NewsEntries.vue";
    import {NewsRepo} from "@/repositories/NewsRepo";
    import {News} from "@/model/News";
    import {isCustomDomain, isDashboard, isSelfHosted} from "@/util/domain";
    import {useUserStore} from "@/store/UserStore";
    import {storeToRefs} from "pinia";
    import {User} from "@/model/User";
    import {getQueryParam} from "@/util/url";
    import {useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";
    import {ThemeSettings} from "@/store/theme";

    const loadNewsLimit = 9999;

    export default defineComponent({
        components: {
            NewsEntries
        },
        setup() {
            const userStore = useUserStore();
            const {user, picture, loggedIn} = storeToRefs(userStore);
            const {login, logout, signup} = userStore;
            const {theme, loaded} = storeToRefs(useThemeStore());
            const hideLogin = computed(() => {
                const themeSettings = theme.value ?? {};
                return themeSettings[ThemeSettings.hideLogin] === "true";
            });
            const showNews = ref(false);
            const news = ref<News[]>([]);

            onMounted(loadNews);
            watch(user, loadNews);

            async function loadNews() {
                if (import.meta.env.VITE_APP_MENU_NEWS && news.value.length === 0 && loggedIn.value) {
                    news.value = await NewsRepo.getNews(0) as News[] || [];

                    if (news.value.length > 0) {
                        showNews.value = true;
                        const user = await NewsRepo.updateNews(news.value[0].news);
                        userStore.user = user as User;
                    } else {
                        news.value = await NewsRepo.getNews(loadNewsLimit) as News[] || [];
                    }
                }
            }

            async function closeNews() {
                showNews.value = false;

                if (news.value.length < 3) {
                    news.value = await NewsRepo.getNews(loadNewsLimit) as News[] || [];
                }
            }

            return {
                ...useI18n(),
                selfHosted: isSelfHosted(),
                showGetStarted: import.meta.env.VITE_APP_MENU_GET_STARTED,
                showNewsEntry: import.meta.env.VITE_APP_MENU_NEWS,
                isDashboard: isDashboard(),
                isCustomDomain: isCustomDomain(),
                dashboardURL: import.meta.env.VITE_APP_DASHBOARD_HOST,
                showBilling: !!import.meta.env.VITE_APP_STRIPE_KEY,
                hideUI: getQueryParam("ui").toLowerCase() === "hide",
                ...useDropdown(),
                user,
                picture,
                loggedIn,
                login,
                logout,
                signup,
                loaded,
                hideLogin,
                showNews,
                news,
                closeNews
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Account, Organizations, Themes, Billing, ...",
            "picture.alt": "Profile Picture",
            "logged_in_as": "Logged in as",
            "plan.free": "Free Trial",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standard",
            "plan.none": "No Active Subscription",
            "link.account": "Account",
            "link.organizations": "Organizations",
            "link.themes": "Themes",
            "link.billing": "Billing",
            "link.affiliate": "Affiliate Program",
            "link.news": "News",
            "link.logout": "Log out",
            "link.dashboard": "Dashboard",
            "login": "Log In",
            "no_account": "No account yet?",
            "sign_up": "Sign Up"
        },
        "de": {
            "title": "Konto, Organisationen, Themen, Abrechnung, ...",
            "picture.alt": "Profilbild",
            "logged_in_as": "Angemeldet als",
            "plan.free": "Kostenlose Testversion",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standard",
            "plan.none": "Kein aktives Abonnement",
            "link.account": "Konto",
            "link.organizations": "Organisationen",
            "link.themes": "Themen",
            "link.billing": "Abrechnung",
            "link.affiliate": "Affiliate-Programm",
            "link.news": "Neuigkeiten",
            "link.logout": "Abmelden",
            "link.dashboard": "Dashboard",
            "login": "Einloggen",
            "no_account": "Noch kein Konto?",
            "sign_up": "Registrieren"
        },
        "es": {
            "title": "Cuenta, Organizaciones, Temas, Facturación, ...",
            "picture.alt": "Foto de perfil",
            "logged_in_as": "Conectado como",
            "plan.free": "Prueba gratuita",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Estándar",
            "plan.none": "Sin suscripción activa",
            "link.account": "Cuenta",
            "link.organizations": "Organizaciones",
            "link.themes": "Temas",
            "link.billing": "Facturación",
            "link.affiliate": "Programa de afiliados",
            "link.news": "Noticias",
            "link.logout": "Cerrar sesión",
            "link.dashboard": "Panel de control",
            "login": "Iniciar sesión",
            "no_account": "¿Aún no tienes cuenta?",
            "sign_up": "Registrarse"
        },
        "fr": {
            "title": "Compte, Organisations, Thèmes, Facturation, ...",
            "picture.alt": "Photo de profil",
            "logged_in_as": "Connecté en tant que",
            "plan.free": "Essai gratuit",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standard",
            "plan.none": "Aucun abonnement actif",
            "link.account": "Compte",
            "link.organizations": "Organisations",
            "link.themes": "Thèmes",
            "link.billing": "Facturation",
            "link.affiliate": "Programme d'affiliation",
            "link.news": "Actualités",
            "link.logout": "Déconnexion",
            "link.dashboard": "Tableau de bord",
            "login": "Se connecter",
            "no_account": "Pas encore de compte?",
            "sign_up": "S'inscrire"
        },
        "nl": {
            "title": "Account, Organisaties, Thema's, Facturatie, ...",
            "picture.alt": "Profielfoto",
            "logged_in_as": "Ingelogd als",
            "plan.free": "Gratis proefversie",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standaard",
            "plan.none": "Geen actief abonnement",
            "link.account": "Account",
            "link.organizations": "Organisaties",
            "link.themes": "Thema's",
            "link.billing": "Facturatie",
            "link.affiliate": "Affiliate-programma",
            "link.news": "Nieuws",
            "link.logout": "Uitloggen",
            "link.dashboard": "Dashboard",
            "login": "Inloggen",
            "no_account": "Nog geen account?",
            "sign_up": "Aanmelden"
        },
        "it": {
            "title": "Account, Organizzazioni, Temi, Fatturazione, ...",
            "picture.alt": "Foto profilo",
            "logged_in_as": "Connesso come",
            "plan.free": "Prova gratuita",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standard",
            "plan.none": "Nessun abbonamento attivo",
            "link.account": "Account",
            "link.organizations": "Organizzazioni",
            "link.themes": "Temi",
            "link.billing": "Fatturazione",
            "link.affiliate": "Programma di affiliazione",
            "link.news": "Notizie",
            "link.logout": "Disconnettersi",
            "link.dashboard": "Dashboard",
            "login": "Accedi",
            "no_account": "Non hai ancora un account?",
            "sign_up": "Iscriviti"
        },
        "pt": {
            "title": "Conta, Organizações, Temas, Faturamento, ...",
            "picture.alt": "Foto de perfil",
            "logged_in_as": "Conectado como",
            "plan.free": "Teste gratuito",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standard",
            "plan.none": "Sem assinatura ativa",
            "link.account": "Conta",
            "link.organizations": "Organizações",
            "link.themes": "Temas",
            "link.billing": "Faturamento",
            "link.affiliate": "Programa de afiliados",
            "link.news": "Notícias",
            "link.logout": "Sair",
            "link.dashboard": "Painel de controle",
            "login": "Entrar",
            "no_account": "Ainda não tem uma conta?",
            "sign_up": "Inscrever-se"
        },
        "ja": {
            "title": "アカウント、組織、テーマ、請求など",
            "picture.alt": "プロフィール写真",
            "logged_in_as": "ログイン中：",
            "plan.free": "無料試用",
            "plan.plus": "Pirsch Plus",
            "plan.standard": "Pirsch Standard",
            "plan.none": "アクティブなサブスクリプションはありません",
            "link.account": "アカウント",
            "link.organizations": "組織",
            "link.themes": "テーマ",
            "link.billing": "請求",
            "link.affiliate": "アフィリエイトプログラム",
            "link.news": "ニュース",
            "link.logout": "ログアウト",
            "link.dashboard": "ダッシュボード",
            "login": "ログイン",
            "no_account": "まだアカウントがありませんか？",
            "sign_up": "サインアップ"
        }
    }
</i18n>
