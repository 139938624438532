<template>
    <ConfirmDeleteAutoJoin :open="deleteHostnameModal"
        v-on:close="deleteHostnameModal = false"
        v-on:action="removeHostname"
        :organization="organization"
        :hostname="hostname" />
    <div class="card no-select">
        <div class="text">
            <p>
                <strong>{{hostname}}</strong>
            </p>
        </div>
        <div class="row">
            <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteHostnameModal = true" />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, ref} from "vue";
    import FormSelect from "@/components/form/FormSelect.vue";
    import ConfirmDeleteAutoJoin from "@/components/modal/ConfirmDeleteAutoJoin.vue";
    import {Organization} from "@/model/Organization";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmDeleteAutoJoin,
            FormSelect
        },
        props: {
            organization: {type: Object as PropType<Organization>, required: true},
            hostname: {type: String, required: true}
        },
        emits: ["remove"],
        setup(props, {emit}) {
            const deleteHostnameModal = ref(false);

            function removeHostname() {
                deleteHostnameModal.value = false;
                emit("remove", props.hostname);
            }

            return {
                ...useI18n(),
                deleteHostnameModal,
                removeHostname
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "delete": "Delete"
        },
        "de": {
            "delete": "Löschen"
        },
        "es": {
            "delete": "Eliminar"
        },
        "fr": {
            "delete": "Supprimer"
        },
        "nl": {
            "delete": "Verwijderen"
        },
        "it": {
            "delete": "Elimina"
        },
        "pt": {
            "delete": "Excluir"
        },
        "ja": {
            "delete": "削除"
        }
    }
</i18n>
