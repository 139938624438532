<template>
    <AddReports :open="createReportModal"
        v-on:close="createReportModal = false"
        v-on:action="addReport" />
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text")}}
                <br />
                <a href="https://docs.pirsch.io/advanced/email-reports" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <Expand>
                <div v-if="!loading">
                    <EmailReportCard v-for="report in reports"
                        :key="report.id"
                        :report="report"
                        v-on:update="updateReport"
                        v-on:remove="removeReport" />
                </div>
            </Expand>
            <button v-on:click="createReportModal = true">
                <Loading :loading="loading" />
                <span>{{t("add")}}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref, watchEffect} from "vue";
    import {useList} from "@/components/list";
    import EmailReportCard from "@/components/cards/EmailReportCard.vue";
    import {EmailReport} from "@/model/EmailReport";
    import {ReportRepo} from "@/repositories/ReportRepo";
    import AddReports from "@/components/modal/AddReports.vue";
    import Loading from "@/components/bits/Loading.vue";
    import Expand from "@/components/bits/Expand.vue";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            EmailReportCard,
            AddReports,
            Loading,
            Expand
        },
        setup() {
            const {removeEntryByID, updateEntryByID} = useList();
            const {domain} = storeToRefs(useDomainStore());
            const createReportModal = ref(false);
            const loading = ref(true);
            const reports = ref<EmailReport[]>([]);

            watchEffect(async () => {
                loading.value = true;
                reports.value = await ReportRepo.list(domain.value.id) as EmailReport[] || [];
                loading.value = false;
            });

            function addReport(newReports: EmailReport[]) {
                createReportModal.value = false;

                for (let i = 0; i < newReports.length; i++) {
                    reports.value.push(newReports[i]);
                }
            }

            function updateReport(report: EmailReport) {
                updateEntryByID(reports.value, report);
            }

            function removeReport(report: EmailReport) {
                removeEntryByID(reports.value, report);
            }

            return {
                ...useI18n(),
                createReportModal,
                loading,
                reports,
                addReport,
                updateReport,
                removeReport
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Email Reports",
            "text": "Send weekly or monthly email reports.",
            "link": "Read the Docs",
            "add": "Add Reports"
        },
        "de": {
            "title": "E-Mail-Berichte",
            "text": "Wöchentliche oder monatliche E-Mail-Berichte senden.",
            "link": "Dokumentation lesen",
            "add": "Berichte hinzufügen"
        },
        "es": {
            "title": "Informes por correo electrónico",
            "text": "Enviar informes por correo electrónico semanales o mensuales.",
            "link": "Leer la documentación",
            "add": "Añadir informes"
        },
        "fr": {
            "title": "Rapports par email",
            "text": "Envoyer des rapports par email hebdomadaires ou mensuels.",
            "link": "Lire la documentation",
            "add": "Ajouter des rapports"
        },
        "nl": {
            "title": "E-mailrapporten",
            "text": "Wekelijkse of maandelijkse e-mailrapporten verzenden.",
            "link": "Lees de documentatie",
            "add": "Rapporten toevoegen"
        },
        "it": {
            "title": "Report via email",
            "text": "Invia report via email settimanali o mensili.",
            "link": "Leggi la documentazione",
            "add": "Aggiungi report"
        },
        "pt": {
            "title": "Relatórios por e-mail",
            "text": "Enviar relatórios por e-mail semanais ou mensais.",
            "link": "Leia a documentação",
            "add": "Adicionar relatórios"
        },
        "ja": {
            "title": "メールレポート",
            "text": "毎週または毎月のメールレポートを送信します。",
            "link": "ドキュメントを読む",
            "add": "レポートを追加"
        }
    }
</i18n>
