import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {Filter} from "@/model/Filter";
import {View} from "@/model/View";
import {ViewRepo} from "@/repositories/ViewRepo";
import {getComparisonParams, getFilterParams, updateQueryParams} from "@/util/url";
import {defaultTimeRangeOptionIndex, timeRangeOptions} from "@/components/panels/time_range_options";
import {useDomainStore} from "@/store/DomainStore";
import {useUserStore} from "@/store/UserStore";
import {getToday} from "@/util/date";
import {dateFromParam, dateParam} from "@/util/format";
import {useRouter} from "vue-router";
import {Time, TimeRange} from "@/model/TimeRange";
import {Comparison} from "@/model/Comparison";

export interface Search {
    offset: number
    column: string
    direction: string
    query: string
}

export const useFilterStore = defineStore("filter", () => {
    const router = useRouter();
    const userStore = useUserStore();
    const domainStore = useDomainStore();
    const filter = ref<Filter>({});
    const comparison = ref<Comparison>({});
    const views = ref<View[]>([]);
    const disableFilter = computed(() => domainStore.domain.imported_statistics && filter.value.timeRange && filter.value.timeRange.from < new Date(domainStore.domain.def_time));
    const dayFilter = computed(() => isDailyFilter(filter.value));
    const hourFilter = computed(() => isHourlyFilter(filter.value));
    let initialized = false;

    watch(() => router.currentRoute.value.meta, meta => {
        if (meta.loadStatistics) {
            if (!initialized) {
                init();
            } else {
                set(filter.value);
            }

            const c = localStorage.getItem("comparison");

            if (c !== null) {
                setComparison(JSON.parse(c));
            }

            setComparison(getComparisonParams());
        }
    });

    watch(disableFilter, disabled => {
        if (disabled && filter.value.fields?.length) {
            clear();
        }
    });

    function init() {
        if (domainStore.domain && domainStore.domain.id && router.currentRoute.value.meta.loadStatistics) {
            initialized = true;
            const f = setFilterParams(getCachedFilter());

            if (f !== null) {
                set(f);
            } else {
                initDefaultFilter();
            }
        }
    }

    function initDefaultFilter() {
        set(setFilterParams({
            timeRange: getTimeRange(),
            timeRangeIndex: defaultTimeRangeOptionIndex,
            scale: timeRangeOptions[defaultTimeRangeOptionIndex].scale || "day"
        }));
    }

    function setFilterParams(f: Filter | null | undefined): Filter {
        const params = getFilterParams();

        if (f === null || f === undefined) {
            return params;
        }

        if (f.timeRange === undefined) {
            f.timeRange = params.timeRange;
        } else {
            f.timeRange.from = params.timeRange?.from ?? f.timeRange.from;
            f.timeRange.to = params.timeRange?.to ?? f.timeRange.to;
            f.timeRange.fromTime = params.timeRange?.fromTime ?? f.timeRange.fromTime;
            f.timeRange.toTime = params.timeRange?.toTime ?? f.timeRange.toTime;
            f.timeRange.start = params.timeRange?.start ?? f.timeRange.start;
        }

        f.timeRangeIndex = params.timeRangeIndex ?? f.timeRangeIndex;
        f.scale = params.scale ?? f.scale;
        f.fields = params.fields ?? f.fields;
        return f;
    }

    function getTimeRange(index: number = defaultTimeRangeOptionIndex, range?: TimeRange): TimeRange {
        if (index > timeRangeOptions.length-1) {
            index = defaultTimeRangeOptionIndex;
        }

        const timeRange = timeRangeOptions[index];
        const today = getToday();
        const from = timeRange.from(today, 0, domainStore.domain);
        const to = timeRange.to(today, 0);
        return {
            from,
            to,
            fromTime: range?.fromTime,
            toTime: range?.toTime,
            start: range?.start ?? 0
        };
    }

    async function loadViews() {
        if (domainStore.domain.id) {
            const v = await ViewRepo.list(domainStore.domain.id);
            views.value = v as View[];
        } else {
            views.value = [];
        }

        return Promise.resolve(null);
    }

    function set(f: Filter) {
        if (domainStore.domain && domainStore.domain.id) {
            f.client_id = domainStore.domain.id;
            f.limit = f.limit ? f.limit : 10;
            f.timeRangeIndex = f.timeRangeIndex === undefined ? filter.value.timeRangeIndex : f.timeRangeIndex;
            f.fields = f.fields === undefined ? filter.value.fields : f.fields;
            f.scale = f.scale === undefined ? filter.value.scale : f.scale;
            f.limit = f.limit === undefined ? filter.value.limit : f.limit;
            f.include_avg_time_on_page = f.include_avg_time_on_page === undefined ? filter.value.include_avg_time_on_page : f.include_avg_time_on_page;

            if (f.timeRangeIndex !== undefined && f.timeRangeIndex > -1) {
                f.timeRange = getTimeRange(f.timeRangeIndex, f.timeRange);
            } else {
                f.timeRange = f.timeRange === undefined ? filter.value.timeRange : f.timeRange;
            }

            filter.value = f;
            updateQueryParams(userStore.user, domainStore.domain, f, comparison.value);
            setCachedFilter(f);
        }
    }

    function clear() {
        const reset = {
            fields: [],
            limit: 0,
            include_avg_time_on_page: false
        };
        set(reset);
        filter.value = reset;
    }

    function hasFilterField(field: string): boolean {
        return hasField(filter.value, field);
    }

    function setFilterField(field: string, value: string, oldValue?: string) {
        const f = copy(filter.value);

        if (setField(f, field, value, oldValue)) {
            set(f);
            return true;
        }

        return false;
    }

    function removeField(field: string, value?: string) {
        const f = copy(filter.value);

        if (f.fields) {
            for (let i = 0; i < f.fields.length; i++) {
                if (f.fields[i].field === field && (value === undefined || f.fields[i].value === value)) {
                    f.fields.splice(i, 1);
                    break;
                }
            }

            set(f);
        }
    }

    function copy(filter: Filter): Filter {
        const f = Object.assign({}, filter);
        f.timeRange = filter.timeRange ? {
            from: new Date(filter.timeRange.from.getTime()),
            to: new Date(filter.timeRange.to.getTime()),
            fromTime: filter.timeRange.fromTime,
            toTime: filter.timeRange.toTime,
            start: filter.timeRange.start
        } : undefined;

        if (filter.fields) {
            f.fields = [];

            for (let i = 0; i < filter.fields.length; i++) {
                f.fields.push({
                    field: filter.fields[i].field,
                    value: filter.fields[i].value
                });
            }
        }

        return f;
    }

    function getCachedFilter() {
        try {
            const f = localStorage.getItem("filter");

            if (f) {
                const filterFromJSON = JSON.parse(f);

                if (filterFromJSON.client_id === domainStore.domain.id) {
                    filterFromJSON.timeRange.from = dateFromParam(filterFromJSON.timeRange.fromDate);
                    filterFromJSON.timeRange.to = dateFromParam(filterFromJSON.timeRange.toDate);
                    return filterFromJSON;
                }
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    function setCachedFilter(f: Filter) {
        if (f.timeRange) {
            f.timeRange.fromDate = dateParam(f.timeRange.from);
            f.timeRange.toDate = dateParam(f.timeRange.to);
        }

        localStorage.setItem("filter", JSON.stringify(f));
    }

    function setComparison(c: Comparison) {
        c.compare = c.compare === undefined ? comparison.value.compare : c.compare;
        c.compareRange = c.compareRange === undefined ? comparison.value.compareRange : c.compareRange;
        c.compareWeekday = c.compareWeekday === undefined ? comparison.value.compareWeekday : c.compareWeekday;
        comparison.value = c;
        updateQueryParams(userStore.user, domainStore.domain, setFilterParams(filter.value), comparison.value);
        localStorage.setItem("comparison", JSON.stringify(c));
    }

    function clearComparison() {
        set({});
        comparison.value = {};
    }

    return {
        filter,
        comparison,
        views,
        disableFilter,
        dayFilter,
        hourFilter,
        init,
        loadViews,
        set,
        clear,
        hasField: hasFilterField,
        setField: setFilterField,
        removeField,
        copy,
        setComparison,
        clearComparison
    };
});

export function getField(filter: Filter, field: string): string {
    if (filter.fields) {
        return filter.fields.find(entry => entry.field === field && entry.value !== "")?.value ?? "";
    }

    return "";
}

export function hasField(filter: Filter, field: string): boolean {
    if (filter.fields) {
        for (let i = 0; i < filter.fields.length; i++) {
            if (filter.fields[i].field === field) {
                return true;
            }
        }
    }

    return false;
}

export function setField(filter: Filter, field: string, value: string, oldValue?: string) {
    if (oldValue) {
        const f = filter.fields?.find(entry => entry.field === field && entry.value === oldValue);

        if (f) {
            f.value = value;
            return true;
        }
    }

    if (!filter.fields?.find(entry => entry.field === field && entry.value === value)) {
        filter.fields?.push({field, value});
        return true;
    }

    return false;
}

export function removeField(filter: Filter, field: string) {
    filter.fields = filter.fields?.filter(f => f.field !== field);
}

export function isDailyFilter(filter: Filter) {
    return filter.timeRange && filter.timeRange.from.getTime() === filter.timeRange.to.getTime();
}

export function isHourlyFilter(filter: Filter) {
    if (filter.timeRange?.fromTime && filter.timeRange?.toTime) {
        const from = new Time(filter.timeRange?.fromTime.hour, filter.timeRange?.fromTime.minute);
        const to = new Time(filter.timeRange?.toTime.hour, filter.timeRange?.toTime.minute);
        return to.seconds() - from.seconds() <= 3600;
    }

    return false;
}
